module.exports={
	"title": "Cycling",
	"text": "2015 was my best cycling year yet.\n\
	\n\
	In the year prior, I managed three 50+&nbsp;km rides, while in 2015 that number was eight, including my longest ride to date at 190&nbsp;km.\n\
	\n\
	2016 will see me try and double that, while attempting a 320&nbsp;km ride &ndash;to Niagara Falls and back.",
	"sources": ["Strava"],
	"stats": [
		{
			"title": "Total distance",
			"value": 2966.2,
			"value_type": "km"
		},
		{
			"title": "Total cycling time",
			"value": 144.8166666667,
			"value_type": "hr"
		},
		{
			"title": "Total elevation gain",
			"value": 7236,
			"value_type": "m"
		},
		{
			"title": "Total recorded rides",
			"value": 597
		},
		{
			"title": "Est. total calories burned",
			"value": "56646"
		},
		{
			"title": "Top speed",
			"value": 71.6,
			"value_type": "km/h",
			"secondary_value": "2015-04-18T15:44:00.000Z",
			"date_link": "http://www.strava.com/activities/288041570"
		},
		{
			"title": "Fastest commute",
			"value": 3.2666666667,
			"value_type": "min",
			"secondary_value": "2015-04-15T11:06:00.000Z"
		},
		{
			"title": "Longest ride",
			"value": 191.4,
			"value_type": "km",
			"secondary_value": "2015-07-11T16:11:00.000Z"
		},
		{
			"title": "\"Unscheduled dismounts\"",
			"value": 5
		}
	]
}