module.exports={
	"title": "Walking",
	"text": "After a <span title=\"Broke my wrist on my way to WayHome\">certain cycling mishap</span> in late July, I was required to walk everywhere for five weeks. While the downsides included extending my commute time by six, I did manage to double my daily step count.",
	"sources": ["Apple Health", "Moves"],
	"stats": [
		{
			"title": "Total distance",
			"value": 1874.826,
			"value_type": "km"
		},
		{
			"title": "Total steps taken",
			"value": 2575173
		},
		{
			"title": "Total walking time",
			"value": 395.55,
			"value_type": "hr"	
		},
		{
			"title": "Est. total calories burned",
			"value": 90091
		},
		{
			"title": "Most steps in one day",
			"value": 25216,
			"secondary_value": "2015-05-18T04:00:00.000Z"
		},
		{
			"title": "Top daily avg. speed",
			"value": 5.1671814672,
			"value_type": "km/h",
			"secondary_value": "2015-01-30T05:00:00.000Z"
		}
	]
}