module.exports=[
	{
		"id": 235312585,
		"name": "Lunch Ride",
		"distance": 1273.9,
		"moving_time": 262,
		"elapsed_time": 531,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-01T12:08:45Z",
		"average_speed": 4.862,
		"max_speed": 10.7,
		"calories": 25.4,
		"points": [
			[
				-79.42322,
				43.65331
			],
			[
				-79.42327,
				43.65333
			],
			[
				-79.42316,
				43.65342
			],
			[
				-79.42283,
				43.65351
			],
			[
				-79.42271,
				43.65343
			],
			[
				-79.42247,
				43.65348
			],
			[
				-79.42131,
				43.65061
			],
			[
				-79.42074,
				43.64887
			],
			[
				-79.42044,
				43.64826
			],
			[
				-79.41955,
				43.64581
			],
			[
				-79.41932,
				43.64539
			],
			[
				-79.41899,
				43.64427
			],
			[
				-79.41898,
				43.64436
			],
			[
				-79.41909,
				43.64423
			],
			[
				-79.41944,
				43.64416
			],
			[
				-79.4194,
				43.64401
			],
			[
				-79.4194,
				43.64409
			],
			[
				-79.41928,
				43.64405
			],
			[
				-79.41947,
				43.64401
			]
		]
	},
	{
		"id": 235313939,
		"name": "Lunch Ride",
		"distance": 1036.4,
		"moving_time": 206,
		"elapsed_time": 206,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-01T12:17:43Z",
		"average_speed": 5.031,
		"max_speed": 8.2,
		"calories": 22,
		"points": [
			[
				-79.41949,
				43.64401
			],
			[
				-79.4199,
				43.6439
			],
			[
				-79.4203,
				43.64398
			],
			[
				-79.42705,
				43.64263
			],
			[
				-79.42723,
				43.64276
			],
			[
				-79.42774,
				43.64401
			],
			[
				-79.42793,
				43.64404
			],
			[
				-79.4292,
				43.64384
			],
			[
				-79.42945,
				43.64448
			],
			[
				-79.42951,
				43.64432
			]
		],
		"dupe": false
	},
	{
		"id": 236008310,
		"name": "Morning Ride",
		"distance": 1609.8,
		"moving_time": 317,
		"elapsed_time": 317,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-03T05:35:10Z",
		"average_speed": 5.078,
		"max_speed": 8.2,
		"calories": 33,
		"points": [
			[
				-79.42933,
				43.64426
			],
			[
				-79.42953,
				43.64427
			],
			[
				-79.42941,
				43.64437
			],
			[
				-79.42947,
				43.64463
			],
			[
				-79.42813,
				43.64491
			],
			[
				-79.42804,
				43.64499
			],
			[
				-79.42804,
				43.64526
			],
			[
				-79.42635,
				43.64566
			],
			[
				-79.42352,
				43.64617
			],
			[
				-79.42349,
				43.64626
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.4234,
				43.65329
			],
			[
				-79.42352,
				43.65343
			],
			[
				-79.42349,
				43.65352
			]
		],
		"dupe": false
	},
	{
		"id": 241583857,
		"name": "Lunch Ride",
		"distance": 1450.9,
		"moving_time": 261,
		"elapsed_time": 261,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-15T11:27:30Z",
		"average_speed": 5.559,
		"max_speed": 8.6,
		"calories": 18.4,
		"points": [
			[
				-79.42403,
				43.65318
			],
			[
				-79.42603,
				43.65272
			],
			[
				-79.42349,
				43.64625
			],
			[
				-79.42357,
				43.64616
			],
			[
				-79.42806,
				43.64523
			],
			[
				-79.42813,
				43.64517
			],
			[
				-79.42805,
				43.64494
			],
			[
				-79.42817,
				43.64488
			],
			[
				-79.42932,
				43.64465
			]
		],
		"dupe": false
	},
	{
		"id": 241610006,
		"name": "Evening Ride",
		"distance": 1745,
		"moving_time": 333,
		"elapsed_time": 348,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-15T19:45:19Z",
		"average_speed": 5.24,
		"max_speed": 10.5,
		"calories": 34.8,
		"points": [
			[
				-79.42956,
				43.64434
			],
			[
				-79.42942,
				43.64458
			],
			[
				-79.42952,
				43.64469
			],
			[
				-79.43135,
				43.64955
			],
			[
				-79.43107,
				43.64962
			],
			[
				-79.42917,
				43.6496
			],
			[
				-79.42483,
				43.64938
			],
			[
				-79.4247,
				43.64944
			],
			[
				-79.42604,
				43.6527
			],
			[
				-79.42368,
				43.65323
			]
		],
		"dupe": false
	},
	{
		"id": 242502504,
		"name": "Night Ride",
		"distance": 3024.3,
		"moving_time": 644,
		"elapsed_time": 645,
		"total_elevation_gain": 11.3,
		"start_date_local": "2015-01-17T22:01:23Z",
		"average_speed": 4.696,
		"max_speed": 8.8,
		"calories": 58,
		"points": [
			[
				-79.39614,
				43.64787
			],
			[
				-79.39619,
				43.64781
			],
			[
				-79.39601,
				43.64783
			],
			[
				-79.39645,
				43.64795
			],
			[
				-79.39966,
				43.64724
			],
			[
				-79.40371,
				43.64647
			],
			[
				-79.40641,
				43.64608
			],
			[
				-79.40935,
				43.64539
			],
			[
				-79.40959,
				43.64542
			],
			[
				-79.40993,
				43.64602
			],
			[
				-79.41151,
				43.64572
			],
			[
				-79.41163,
				43.64591
			],
			[
				-79.41194,
				43.64586
			],
			[
				-79.41436,
				43.64672
			],
			[
				-79.41465,
				43.64752
			],
			[
				-79.41468,
				43.64784
			],
			[
				-79.41492,
				43.64807
			],
			[
				-79.41515,
				43.64864
			],
			[
				-79.4162,
				43.64908
			],
			[
				-79.41662,
				43.64917
			],
			[
				-79.41674,
				43.64945
			],
			[
				-79.41686,
				43.64947
			],
			[
				-79.41708,
				43.65003
			],
			[
				-79.41825,
				43.64991
			],
			[
				-79.41817,
				43.64992
			],
			[
				-79.41828,
				43.64981
			],
			[
				-79.41851,
				43.65058
			],
			[
				-79.41889,
				43.65138
			],
			[
				-79.4195,
				43.65134
			],
			[
				-79.42127,
				43.65098
			],
			[
				-79.42155,
				43.65169
			],
			[
				-79.4219,
				43.65228
			],
			[
				-79.4222,
				43.65315
			],
			[
				-79.42217,
				43.65324
			],
			[
				-79.42236,
				43.65347
			],
			[
				-79.42306,
				43.65336
			],
			[
				-79.4235,
				43.65338
			]
		],
		"dupe": false
	},
	{
		"id": 242911951,
		"name": "Afternoon Ride",
		"distance": 3794.8,
		"moving_time": 776,
		"elapsed_time": 808,
		"total_elevation_gain": 13.3,
		"start_date_local": "2015-01-18T14:48:53Z",
		"average_speed": 4.89,
		"max_speed": 10,
		"calories": 85.5,
		"points": [
			[
				-79.4235,
				43.65403
			],
			[
				-79.42341,
				43.65379
			],
			[
				-79.42335,
				43.65379
			],
			[
				-79.42341,
				43.65378
			],
			[
				-79.42332,
				43.65375
			],
			[
				-79.42255,
				43.65393
			],
			[
				-79.42249,
				43.6541
			],
			[
				-79.42264,
				43.65429
			],
			[
				-79.42292,
				43.65517
			],
			[
				-79.42416,
				43.65825
			],
			[
				-79.42418,
				43.65847
			],
			[
				-79.42394,
				43.65861
			],
			[
				-79.4219,
				43.65902
			],
			[
				-79.42221,
				43.66007
			],
			[
				-79.42308,
				43.66211
			],
			[
				-79.42313,
				43.66252
			],
			[
				-79.41859,
				43.66345
			],
			[
				-79.41803,
				43.66365
			],
			[
				-79.41261,
				43.66482
			],
			[
				-79.41183,
				43.66492
			],
			[
				-79.40834,
				43.66571
			],
			[
				-79.40769,
				43.66579
			],
			[
				-79.40601,
				43.66626
			],
			[
				-79.4039,
				43.66663
			],
			[
				-79.40378,
				43.66676
			],
			[
				-79.4046,
				43.66862
			],
			[
				-79.40462,
				43.66885
			],
			[
				-79.40487,
				43.66926
			],
			[
				-79.4052,
				43.67032
			],
			[
				-79.40557,
				43.67087
			],
			[
				-79.40575,
				43.67135
			],
			[
				-79.40585,
				43.6719
			],
			[
				-79.40644,
				43.67343
			],
			[
				-79.40646,
				43.67357
			],
			[
				-79.40618,
				43.67373
			],
			[
				-79.40615,
				43.67368
			],
			[
				-79.4062,
				43.67373
			],
			[
				-79.40653,
				43.67366
			]
		],
		"dupe": false
	},
	{
		"id": 243011640,
		"name": "Evening Ride",
		"distance": 3493.7,
		"moving_time": 734,
		"elapsed_time": 771,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-18T18:49:39Z",
		"average_speed": 4.76,
		"max_speed": 10.4,
		"calories": 48.1,
		"points": [
			[
				-79.40669,
				43.67384
			],
			[
				-79.40637,
				43.67352
			],
			[
				-79.40635,
				43.67341
			],
			[
				-79.40646,
				43.67327
			],
			[
				-79.40628,
				43.67287
			],
			[
				-79.40611,
				43.67209
			],
			[
				-79.40549,
				43.67043
			],
			[
				-79.40538,
				43.67028
			],
			[
				-79.40528,
				43.67031
			],
			[
				-79.40532,
				43.66985
			],
			[
				-79.40516,
				43.66975
			],
			[
				-79.40506,
				43.66931
			],
			[
				-79.40458,
				43.6681
			],
			[
				-79.40432,
				43.66767
			],
			[
				-79.40407,
				43.66686
			],
			[
				-79.40332,
				43.66568
			],
			[
				-79.40235,
				43.66321
			],
			[
				-79.40225,
				43.66321
			],
			[
				-79.40175,
				43.66181
			],
			[
				-79.40135,
				43.66096
			],
			[
				-79.40119,
				43.66049
			],
			[
				-79.4012,
				43.6603
			],
			[
				-79.40154,
				43.65999
			],
			[
				-79.4016,
				43.65977
			],
			[
				-79.40156,
				43.65954
			],
			[
				-79.40131,
				43.65926
			],
			[
				-79.40055,
				43.65893
			],
			[
				-79.40056,
				43.65878
			],
			[
				-79.39886,
				43.65461
			],
			[
				-79.39801,
				43.65224
			],
			[
				-79.39753,
				43.65123
			],
			[
				-79.39625,
				43.64799
			],
			[
				-79.39858,
				43.64747
			],
			[
				-79.39862,
				43.64738
			],
			[
				-79.39796,
				43.64562
			]
		],
		"dupe": false
	},
	{
		"id": 243062943,
		"name": "Night Ride",
		"distance": 3369.4,
		"moving_time": 665,
		"elapsed_time": 665,
		"total_elevation_gain": 32.7,
		"start_date_local": "2015-01-18T23:51:21Z",
		"average_speed": 5.067,
		"max_speed": 9.7,
		"calories": 79.9,
		"points": [
			[
				-79.39694,
				43.6465
			],
			[
				-79.3965,
				43.64653
			],
			[
				-79.39641,
				43.64662
			],
			[
				-79.39576,
				43.6467
			],
			[
				-79.39548,
				43.64682
			],
			[
				-79.3958,
				43.64743
			],
			[
				-79.39572,
				43.64764
			],
			[
				-79.39606,
				43.6482
			],
			[
				-79.39626,
				43.64887
			],
			[
				-79.39691,
				43.65022
			],
			[
				-79.39726,
				43.6513
			],
			[
				-79.39752,
				43.65161
			],
			[
				-79.39749,
				43.65181
			],
			[
				-79.39789,
				43.65266
			],
			[
				-79.39864,
				43.65484
			],
			[
				-79.39882,
				43.65512
			],
			[
				-79.39894,
				43.65568
			],
			[
				-79.39904,
				43.65571
			],
			[
				-79.39925,
				43.65613
			],
			[
				-79.39932,
				43.65648
			],
			[
				-79.40027,
				43.65864
			],
			[
				-79.40031,
				43.65909
			],
			[
				-79.39994,
				43.65949
			],
			[
				-79.39992,
				43.65983
			],
			[
				-79.40017,
				43.66013
			],
			[
				-79.40088,
				43.66037
			],
			[
				-79.40118,
				43.66082
			],
			[
				-79.40122,
				43.66117
			],
			[
				-79.40152,
				43.66196
			],
			[
				-79.40288,
				43.66522
			],
			[
				-79.40339,
				43.66618
			],
			[
				-79.40368,
				43.66647
			],
			[
				-79.40401,
				43.66704
			],
			[
				-79.40446,
				43.66806
			],
			[
				-79.40446,
				43.66837
			],
			[
				-79.40468,
				43.66875
			],
			[
				-79.40469,
				43.66898
			],
			[
				-79.40654,
				43.67351
			]
		],
		"dupe": false
	},
	{
		"id": 243245299,
		"name": "Lunch Ride",
		"distance": 3812.4,
		"moving_time": 750,
		"elapsed_time": 754,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-19T12:08:06Z",
		"average_speed": 5.083,
		"max_speed": 9.1,
		"calories": 49.8,
		"points": [
			[
				-79.40667,
				43.67357
			],
			[
				-79.40671,
				43.6733
			],
			[
				-79.40659,
				43.67362
			],
			[
				-79.40665,
				43.67341
			],
			[
				-79.40643,
				43.67309
			],
			[
				-79.40612,
				43.67227
			],
			[
				-79.40617,
				43.67219
			],
			[
				-79.40744,
				43.67197
			],
			[
				-79.40747,
				43.67187
			],
			[
				-79.40834,
				43.67169
			],
			[
				-79.40816,
				43.67122
			],
			[
				-79.40842,
				43.67112
			],
			[
				-79.41319,
				43.67009
			],
			[
				-79.41306,
				43.66958
			],
			[
				-79.41252,
				43.66827
			],
			[
				-79.41251,
				43.66818
			],
			[
				-79.41271,
				43.66804
			],
			[
				-79.4196,
				43.66656
			],
			[
				-79.42151,
				43.6661
			],
			[
				-79.42201,
				43.66589
			],
			[
				-79.42444,
				43.66535
			],
			[
				-79.42191,
				43.65907
			],
			[
				-79.42431,
				43.65853
			],
			[
				-79.42398,
				43.65738
			],
			[
				-79.42356,
				43.65669
			],
			[
				-79.4228,
				43.65436
			],
			[
				-79.42246,
				43.65358
			],
			[
				-79.42265,
				43.65345
			],
			[
				-79.42348,
				43.65328
			],
			[
				-79.42349,
				43.65355
			],
			[
				-79.42358,
				43.65355
			],
			[
				-79.42361,
				43.65368
			],
			[
				-79.42351,
				43.65369
			],
			[
				-79.4235,
				43.65363
			]
		],
		"dupe": false
	},
	{
		"id": 244596801,
		"name": "Morning Ride",
		"distance": 2674.1,
		"moving_time": 1330,
		"elapsed_time": 10138,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-22T10:53:49Z",
		"average_speed": 2.011,
		"max_speed": 9.2,
		"calories": 38.5,
		"points": [
			[
				-79.42339,
				43.65336
			],
			[
				-79.42452,
				43.65303
			],
			[
				-79.42604,
				43.65272
			],
			[
				-79.42479,
				43.64954
			],
			[
				-79.42743,
				43.64953
			],
			[
				-79.42983,
				43.64965
			],
			[
				-79.42806,
				43.64497
			],
			[
				-79.42829,
				43.64486
			],
			[
				-79.42955,
				43.6446
			],
			[
				-79.42951,
				43.64435
			],
			[
				-79.42968,
				43.64434
			],
			[
				-79.42955,
				43.64422
			],
			[
				-79.4296,
				43.64428
			],
			[
				-79.4296,
				43.64423
			],
			[
				-79.42951,
				43.64429
			],
			[
				-79.42954,
				43.64445
			],
			[
				-79.42953,
				43.64436
			],
			[
				-79.42941,
				43.64454
			],
			[
				-79.42957,
				43.64433
			],
			[
				-79.42952,
				43.64435
			],
			[
				-79.42967,
				43.64438
			],
			[
				-79.42953,
				43.6443
			],
			[
				-79.42934,
				43.64393
			],
			[
				-79.42899,
				43.64389
			],
			[
				-79.42845,
				43.64404
			],
			[
				-79.42793,
				43.64404
			],
			[
				-79.42766,
				43.64413
			],
			[
				-79.42738,
				43.64337
			],
			[
				-79.42732,
				43.64308
			],
			[
				-79.4274,
				43.643
			],
			[
				-79.42726,
				43.64273
			],
			[
				-79.4273,
				43.6426
			],
			[
				-79.42718,
				43.6425
			],
			[
				-79.42676,
				43.64251
			],
			[
				-79.42636,
				43.64271
			],
			[
				-79.42587,
				43.6428
			],
			[
				-79.42528,
				43.64277
			],
			[
				-79.42468,
				43.64295
			],
			[
				-79.4246,
				43.64305
			],
			[
				-79.4234,
				43.64329
			],
			[
				-79.42324,
				43.64329
			],
			[
				-79.42321,
				43.64309
			],
			[
				-79.42324,
				43.64325
			]
		],
		"dupe": false
	},
	{
		"id": 245100373,
		"name": "Evening Ride",
		"distance": 1465.7,
		"moving_time": 309,
		"elapsed_time": 309,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-23T18:44:27Z",
		"average_speed": 4.743,
		"max_speed": 11.2,
		"calories": 34.4,
		"points": [
			[
				-79.42365,
				43.65362
			],
			[
				-79.42362,
				43.6541
			],
			[
				-79.42396,
				43.65421
			],
			[
				-79.42675,
				43.65358
			],
			[
				-79.42764,
				43.65345
			],
			[
				-79.42805,
				43.6533
			],
			[
				-79.42813,
				43.65335
			],
			[
				-79.4283,
				43.65321
			],
			[
				-79.42843,
				43.65405
			],
			[
				-79.42914,
				43.65582
			],
			[
				-79.42942,
				43.65582
			],
			[
				-79.43042,
				43.65553
			],
			[
				-79.4324,
				43.66045
			]
		],
		"dupe": false
	},
	{
		"id": 245139999,
		"name": "Night Ride",
		"distance": 1481.5,
		"moving_time": 263,
		"elapsed_time": 263,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-23T21:14:18Z",
		"average_speed": 5.633,
		"max_speed": 9.8,
		"calories": 19.9,
		"points": [
			[
				-79.43282,
				43.66051
			],
			[
				-79.43236,
				43.66043
			],
			[
				-79.4294,
				43.66107
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.42345,
				43.65328
			]
		],
		"dupe": false
	},
	{
		"id": 245434590,
		"name": "Lunch Ride",
		"distance": 3965,
		"moving_time": 740,
		"elapsed_time": 756,
		"total_elevation_gain": 35.6,
		"start_date_local": "2015-01-24T12:21:12Z",
		"average_speed": 5.358,
		"max_speed": 11.2,
		"calories": 102.8,
		"points": [
			[
				-79.42362,
				43.65397
			],
			[
				-79.42356,
				43.65423
			],
			[
				-79.42353,
				43.65417
			],
			[
				-79.42382,
				43.65417
			],
			[
				-79.42376,
				43.65414
			],
			[
				-79.42376,
				43.65426
			],
			[
				-79.4239,
				43.65428
			],
			[
				-79.42441,
				43.65407
			],
			[
				-79.4262,
				43.65371
			],
			[
				-79.42635,
				43.65373
			],
			[
				-79.42654,
				43.65394
			],
			[
				-79.42932,
				43.66095
			],
			[
				-79.42963,
				43.66156
			],
			[
				-79.42965,
				43.66218
			],
			[
				-79.43465,
				43.67425
			],
			[
				-79.43719,
				43.67402
			],
			[
				-79.4392,
				43.67351
			],
			[
				-79.43989,
				43.67321
			],
			[
				-79.44097,
				43.67305
			],
			[
				-79.44088,
				43.67305
			],
			[
				-79.44105,
				43.67324
			],
			[
				-79.44132,
				43.67393
			],
			[
				-79.44125,
				43.67403
			],
			[
				-79.43932,
				43.67447
			],
			[
				-79.43928,
				43.67458
			],
			[
				-79.44084,
				43.67828
			]
		],
		"dupe": false
	},
	{
		"id": 245982072,
		"name": "Lunch Ride",
		"distance": 3732.1,
		"moving_time": 672,
		"elapsed_time": 684,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-25T11:40:38Z",
		"average_speed": 5.554,
		"max_speed": 11.4,
		"calories": 60.6,
		"points": [
			[
				-79.40662,
				43.67357
			],
			[
				-79.40665,
				43.67341
			],
			[
				-79.40642,
				43.67293
			],
			[
				-79.40639,
				43.67259
			],
			[
				-79.40613,
				43.67218
			],
			[
				-79.40576,
				43.67105
			],
			[
				-79.40525,
				43.67002
			],
			[
				-79.40527,
				43.66987
			],
			[
				-79.40485,
				43.66903
			],
			[
				-79.40478,
				43.66866
			],
			[
				-79.4045,
				43.66807
			],
			[
				-79.40435,
				43.66753
			],
			[
				-79.40422,
				43.6674
			],
			[
				-79.40403,
				43.66676
			],
			[
				-79.40505,
				43.66646
			],
			[
				-79.40728,
				43.66597
			],
			[
				-79.40848,
				43.66581
			],
			[
				-79.41029,
				43.66532
			],
			[
				-79.41162,
				43.66513
			],
			[
				-79.41243,
				43.6649
			],
			[
				-79.41346,
				43.66473
			],
			[
				-79.4141,
				43.66451
			],
			[
				-79.41847,
				43.66362
			],
			[
				-79.42234,
				43.66267
			],
			[
				-79.42278,
				43.66263
			],
			[
				-79.42322,
				43.66247
			],
			[
				-79.42192,
				43.65907
			],
			[
				-79.42427,
				43.65853
			],
			[
				-79.42425,
				43.6582
			],
			[
				-79.4241,
				43.65776
			],
			[
				-79.42271,
				43.65438
			],
			[
				-79.42249,
				43.65354
			],
			[
				-79.42258,
				43.65346
			],
			[
				-79.42348,
				43.65328
			],
			[
				-79.42343,
				43.6533
			]
		],
		"dupe": false
	},
	{
		"id": 245482303,
		"name": "Afternoon Ride",
		"distance": 4032.7,
		"moving_time": 820,
		"elapsed_time": 943,
		"total_elevation_gain": 13.4,
		"start_date_local": "2015-01-24T14:15:19Z",
		"average_speed": 4.918,
		"max_speed": 10.3,
		"calories": 73.7,
		"points": [
			[
				-79.4389,
				43.67887
			],
			[
				-79.43597,
				43.67938
			],
			[
				-79.43317,
				43.68006
			],
			[
				-79.43213,
				43.68023
			],
			[
				-79.43106,
				43.68052
			],
			[
				-79.4265,
				43.68148
			],
			[
				-79.41972,
				43.68276
			],
			[
				-79.41837,
				43.68309
			],
			[
				-79.41722,
				43.68325
			],
			[
				-79.41112,
				43.68454
			],
			[
				-79.40878,
				43.67891
			],
			[
				-79.40899,
				43.67876
			],
			[
				-79.4108,
				43.67829
			],
			[
				-79.41086,
				43.67803
			],
			[
				-79.41049,
				43.67706
			],
			[
				-79.41037,
				43.67685
			],
			[
				-79.40954,
				43.67703
			],
			[
				-79.40938,
				43.67698
			],
			[
				-79.4081,
				43.67733
			],
			[
				-79.40822,
				43.67735
			],
			[
				-79.40803,
				43.67724
			],
			[
				-79.40792,
				43.67706
			],
			[
				-79.40737,
				43.6753
			],
			[
				-79.40705,
				43.67467
			],
			[
				-79.40675,
				43.67381
			],
			[
				-79.40652,
				43.67366
			],
			[
				-79.40617,
				43.67374
			]
		],
		"dupe": false
	},
	{
		"id": 246221006,
		"name": "Evening Ride",
		"distance": 3669,
		"moving_time": 714,
		"elapsed_time": 849,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-25T19:04:33Z",
		"average_speed": 5.139,
		"max_speed": 10.1,
		"calories": 57.1,
		"points": [
			[
				-79.42294,
				43.65342
			],
			[
				-79.42326,
				43.65334
			],
			[
				-79.42319,
				43.65334
			],
			[
				-79.42326,
				43.65326
			],
			[
				-79.4232,
				43.65337
			],
			[
				-79.42319,
				43.65319
			],
			[
				-79.42321,
				43.6533
			],
			[
				-79.42274,
				43.65337
			],
			[
				-79.42245,
				43.65351
			],
			[
				-79.42228,
				43.6534
			],
			[
				-79.42216,
				43.65288
			],
			[
				-79.42151,
				43.65111
			],
			[
				-79.42122,
				43.65038
			],
			[
				-79.42089,
				43.64981
			],
			[
				-79.42076,
				43.64936
			],
			[
				-79.42023,
				43.64937
			],
			[
				-79.4191,
				43.64967
			],
			[
				-79.41682,
				43.65009
			],
			[
				-79.41592,
				43.65036
			],
			[
				-79.41354,
				43.65072
			],
			[
				-79.41155,
				43.64576
			],
			[
				-79.41135,
				43.64568
			],
			[
				-79.40991,
				43.64599
			],
			[
				-79.40991,
				43.64585
			],
			[
				-79.40961,
				43.64529
			],
			[
				-79.40377,
				43.64646
			],
			[
				-79.40327,
				43.64522
			],
			[
				-79.3982,
				43.64621
			],
			[
				-79.39797,
				43.64564
			],
			[
				-79.39781,
				43.64588
			],
			[
				-79.39786,
				43.64574
			],
			[
				-79.39776,
				43.6457
			],
			[
				-79.39771,
				43.64553
			],
			[
				-79.39786,
				43.64547
			],
			[
				-79.39782,
				43.64541
			],
			[
				-79.39779,
				43.64556
			],
			[
				-79.39701,
				43.64587
			],
			[
				-79.39698,
				43.64582
			],
			[
				-79.39778,
				43.64576
			],
			[
				-79.39767,
				43.6459
			],
			[
				-79.39711,
				43.64608
			],
			[
				-79.39682,
				43.64582
			],
			[
				-79.39761,
				43.64584
			],
			[
				-79.39734,
				43.64582
			],
			[
				-79.39761,
				43.64568
			]
		],
		"dupe": false
	},
	{
		"id": 246274703,
		"name": "Night Ride",
		"distance": 2922.6,
		"moving_time": 738,
		"elapsed_time": 780,
		"total_elevation_gain": 11.8,
		"start_date_local": "2015-01-25T22:30:34Z",
		"average_speed": 3.96,
		"max_speed": 8.8,
		"calories": 51,
		"points": [
			[
				-79.39946,
				43.64628
			],
			[
				-79.39957,
				43.64631
			],
			[
				-79.39968,
				43.64649
			],
			[
				-79.39995,
				43.6471
			],
			[
				-79.40015,
				43.64724
			],
			[
				-79.40038,
				43.64727
			],
			[
				-79.40058,
				43.64719
			],
			[
				-79.40061,
				43.64708
			],
			[
				-79.40606,
				43.64608
			],
			[
				-79.40736,
				43.64574
			],
			[
				-79.40917,
				43.64542
			],
			[
				-79.40934,
				43.64532
			],
			[
				-79.40968,
				43.64541
			],
			[
				-79.40997,
				43.64598
			],
			[
				-79.41128,
				43.64575
			],
			[
				-79.41154,
				43.64578
			],
			[
				-79.41169,
				43.64596
			],
			[
				-79.412,
				43.64589
			],
			[
				-79.41271,
				43.64609
			],
			[
				-79.41439,
				43.64674
			],
			[
				-79.41465,
				43.64744
			],
			[
				-79.41479,
				43.64752
			],
			[
				-79.41489,
				43.64781
			],
			[
				-79.41505,
				43.64798
			],
			[
				-79.41504,
				43.64811
			],
			[
				-79.41528,
				43.64867
			],
			[
				-79.41624,
				43.64904
			],
			[
				-79.41672,
				43.64913
			],
			[
				-79.41709,
				43.65006
			],
			[
				-79.4173,
				43.6501
			],
			[
				-79.41828,
				43.64983
			],
			[
				-79.41839,
				43.65042
			],
			[
				-79.41883,
				43.65141
			],
			[
				-79.41897,
				43.65147
			],
			[
				-79.42131,
				43.65098
			],
			[
				-79.4223,
				43.65343
			],
			[
				-79.42242,
				43.65349
			],
			[
				-79.42328,
				43.65332
			],
			[
				-79.42366,
				43.65333
			],
			[
				-79.42331,
				43.65371
			],
			[
				-79.42347,
				43.65357
			],
			[
				-79.42349,
				43.65351
			],
			[
				-79.4234,
				43.65349
			]
		],
		"dupe": false
	},
	{
		"id": 248753175,
		"name": "Evening Ride",
		"distance": 2928.9,
		"moving_time": 631,
		"elapsed_time": 672,
		"total_elevation_gain": 17.7,
		"start_date_local": "2015-01-31T18:12:56Z",
		"average_speed": 4.642,
		"max_speed": 9.5,
		"calories": 68.5,
		"points": [
			[
				-79.42376,
				43.6541
			],
			[
				-79.42378,
				43.6543
			],
			[
				-79.42628,
				43.65371
			],
			[
				-79.42653,
				43.6539
			],
			[
				-79.42798,
				43.65764
			],
			[
				-79.42934,
				43.661
			],
			[
				-79.42963,
				43.66156
			],
			[
				-79.42964,
				43.66216
			],
			[
				-79.43276,
				43.66968
			],
			[
				-79.433,
				43.66973
			],
			[
				-79.43877,
				43.66856
			],
			[
				-79.43994,
				43.66858
			],
			[
				-79.44097,
				43.6684
			],
			[
				-79.44059,
				43.6683
			],
			[
				-79.44051,
				43.66805
			],
			[
				-79.44081,
				43.66806
			],
			[
				-79.44054,
				43.66811
			],
			[
				-79.44062,
				43.66812
			],
			[
				-79.44061,
				43.66805
			],
			[
				-79.44079,
				43.668
			]
		],
		"dupe": false
	},
	{
		"id": 248758315,
		"name": "Evening Ride",
		"distance": 1254.5,
		"moving_time": 283,
		"elapsed_time": 320,
		"total_elevation_gain": 0,
		"start_date_local": "2015-01-31T18:33:34Z",
		"average_speed": 4.433,
		"max_speed": 11.9,
		"calories": 35.1,
		"points": [
			[
				-79.44033,
				43.66788
			],
			[
				-79.44071,
				43.66815
			],
			[
				-79.44046,
				43.66811
			],
			[
				-79.44072,
				43.66816
			],
			[
				-79.44063,
				43.66804
			],
			[
				-79.44049,
				43.6682
			],
			[
				-79.44051,
				43.66844
			],
			[
				-79.44066,
				43.6685
			],
			[
				-79.44331,
				43.66792
			],
			[
				-79.44393,
				43.66755
			],
			[
				-79.4442,
				43.66729
			],
			[
				-79.44638,
				43.66683
			],
			[
				-79.44658,
				43.66714
			],
			[
				-79.44737,
				43.66926
			],
			[
				-79.44772,
				43.66994
			],
			[
				-79.44898,
				43.66972
			],
			[
				-79.44918,
				43.6701
			],
			[
				-79.44935,
				43.67089
			]
		],
		"dupe": false
	},
	{
		"id": 248846445,
		"name": "Night Ride",
		"distance": 4190.2,
		"moving_time": 788,
		"elapsed_time": 876,
		"total_elevation_gain": 0,
		"start_date_local": "2015-02-01T01:36:02Z",
		"average_speed": 5.318,
		"max_speed": 12.7,
		"calories": 62.8,
		"points": [
			[
				-79.45014,
				43.671
			],
			[
				-79.44937,
				43.67064
			],
			[
				-79.4493,
				43.6703
			],
			[
				-79.44902,
				43.66973
			],
			[
				-79.4479,
				43.66995
			],
			[
				-79.44671,
				43.66729
			],
			[
				-79.44651,
				43.66679
			],
			[
				-79.44658,
				43.6668
			],
			[
				-79.44657,
				43.66673
			],
			[
				-79.44645,
				43.6666
			],
			[
				-79.44518,
				43.66699
			],
			[
				-79.44419,
				43.66718
			],
			[
				-79.44345,
				43.66777
			],
			[
				-79.44298,
				43.66791
			],
			[
				-79.44028,
				43.66842
			],
			[
				-79.43963,
				43.66847
			],
			[
				-79.43928,
				43.66838
			],
			[
				-79.43879,
				43.66842
			],
			[
				-79.43318,
				43.66961
			],
			[
				-79.4329,
				43.66963
			],
			[
				-79.43295,
				43.66963
			],
			[
				-79.43269,
				43.6695
			],
			[
				-79.42968,
				43.66224
			],
			[
				-79.42964,
				43.66159
			],
			[
				-79.42931,
				43.66094
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.42348,
				43.65328
			],
			[
				-79.42354,
				43.65326
			],
			[
				-79.42354,
				43.65336
			],
			[
				-79.4235,
				43.65327
			],
			[
				-79.42374,
				43.65371
			],
			[
				-79.42353,
				43.65377
			],
			[
				-79.42327,
				43.6537
			],
			[
				-79.4234,
				43.65363
			],
			[
				-79.42368,
				43.65364
			]
		],
		"dupe": false
	},
	{
		"id": 250715086,
		"name": "Morning Ride",
		"distance": 3848.3,
		"moving_time": 939,
		"elapsed_time": 1122,
		"total_elevation_gain": 0,
		"start_date_local": "2015-02-05T08:55:25Z",
		"average_speed": 4.098,
		"max_speed": 8.8,
		"calories": 60.7,
		"points": [
			[
				-79.4065,
				43.67353
			],
			[
				-79.40674,
				43.67349
			],
			[
				-79.40659,
				43.67355
			],
			[
				-79.4067,
				43.67348
			],
			[
				-79.40662,
				43.67345
			],
			[
				-79.4066,
				43.67354
			],
			[
				-79.40666,
				43.67344
			],
			[
				-79.40653,
				43.67307
			],
			[
				-79.40472,
				43.66845
			],
			[
				-79.40396,
				43.66663
			],
			[
				-79.40331,
				43.66562
			],
			[
				-79.4023,
				43.6631
			],
			[
				-79.40307,
				43.66287
			],
			[
				-79.42427,
				43.65854
			],
			[
				-79.42423,
				43.65821
			],
			[
				-79.42388,
				43.6574
			],
			[
				-79.42353,
				43.6562
			],
			[
				-79.42321,
				43.6556
			],
			[
				-79.42307,
				43.65499
			],
			[
				-79.42277,
				43.65443
			],
			[
				-79.42248,
				43.65356
			],
			[
				-79.42249,
				43.65348
			],
			[
				-79.42322,
				43.65333
			],
			[
				-79.42358,
				43.65349
			],
			[
				-79.4235,
				43.65354
			]
		],
		"dupe": false
	},
	{
		"id": 250594158,
		"name": "Night Ride",
		"distance": 3960,
		"moving_time": 1084,
		"elapsed_time": 1717,
		"total_elevation_gain": 24.6,
		"start_date_local": "2015-02-04T23:27:36Z",
		"average_speed": 3.653,
		"max_speed": 8,
		"calories": 72,
		"points": [
			[
				-79.42256,
				43.65399
			],
			[
				-79.42239,
				43.65395
			],
			[
				-79.42273,
				43.654
			],
			[
				-79.42257,
				43.65399
			],
			[
				-79.42247,
				43.65414
			],
			[
				-79.42361,
				43.65686
			],
			[
				-79.4242,
				43.65855
			],
			[
				-79.42488,
				43.66009
			],
			[
				-79.42555,
				43.66201
			],
			[
				-79.42726,
				43.66586
			],
			[
				-79.42,
				43.66736
			],
			[
				-79.41976,
				43.66695
			],
			[
				-79.41982,
				43.66686
			],
			[
				-79.41975,
				43.66667
			],
			[
				-79.4196,
				43.66664
			],
			[
				-79.4139,
				43.66783
			],
			[
				-79.41147,
				43.66844
			],
			[
				-79.41103,
				43.66838
			],
			[
				-79.40998,
				43.66857
			],
			[
				-79.40982,
				43.66878
			],
			[
				-79.40884,
				43.66897
			],
			[
				-79.40875,
				43.66912
			],
			[
				-79.4094,
				43.67096
			],
			[
				-79.40829,
				43.67125
			],
			[
				-79.40823,
				43.67137
			],
			[
				-79.40838,
				43.67175
			],
			[
				-79.40824,
				43.67185
			],
			[
				-79.40608,
				43.67223
			],
			[
				-79.40605,
				43.67235
			],
			[
				-79.40655,
				43.67342
			],
			[
				-79.40653,
				43.67366
			],
			[
				-79.4062,
				43.6737
			],
			[
				-79.40651,
				43.67367
			],
			[
				-79.40642,
				43.67372
			],
			[
				-79.40645,
				43.67378
			],
			[
				-79.40659,
				43.67371
			]
		],
		"dupe": false
	},
	{
		"id": 251126238,
		"name": "Morning Ride",
		"distance": 2109.4,
		"moving_time": 487,
		"elapsed_time": 488,
		"total_elevation_gain": 0,
		"start_date_local": "2015-02-06T10:42:44Z",
		"average_speed": 4.331,
		"max_speed": 9.6,
		"calories": 32.9,
		"points": [
			[
				-79.42336,
				43.65338
			],
			[
				-79.42601,
				43.65278
			],
			[
				-79.42605,
				43.65263
			],
			[
				-79.42567,
				43.65175
			],
			[
				-79.42578,
				43.65167
			],
			[
				-79.42896,
				43.651
			],
			[
				-79.42891,
				43.65067
			],
			[
				-79.42881,
				43.65052
			],
			[
				-79.4283,
				43.6504
			],
			[
				-79.42815,
				43.65029
			],
			[
				-79.42811,
				43.64966
			],
			[
				-79.43116,
				43.64968
			],
			[
				-79.43146,
				43.64973
			],
			[
				-79.43157,
				43.64985
			],
			[
				-79.43149,
				43.64987
			],
			[
				-79.43154,
				43.64979
			],
			[
				-79.43127,
				43.64905
			],
			[
				-79.43042,
				43.64701
			],
			[
				-79.43034,
				43.64652
			],
			[
				-79.42992,
				43.64562
			],
			[
				-79.4285,
				43.64184
			],
			[
				-79.42863,
				43.64198
			],
			[
				-79.42863,
				43.64209
			],
			[
				-79.42885,
				43.6421
			]
		],
		"dupe": false
	},
	{
		"id": 252638382,
		"name": "Lunch Ride",
		"distance": 5047.5,
		"moving_time": 1166,
		"elapsed_time": 7162,
		"total_elevation_gain": 0,
		"start_date_local": "2015-02-09T11:00:14Z",
		"average_speed": 4.329,
		"max_speed": 10.3,
		"calories": 97.2,
		"points": [
			[
				-79.42364,
				43.65352
			],
			[
				-79.42361,
				43.65338
			],
			[
				-79.42453,
				43.65314
			],
			[
				-79.42457,
				43.65304
			],
			[
				-79.42604,
				43.65272
			],
			[
				-79.42348,
				43.64624
			],
			[
				-79.4237,
				43.64613
			],
			[
				-79.42815,
				43.64521
			],
			[
				-79.42806,
				43.64496
			],
			[
				-79.42826,
				43.64486
			],
			[
				-79.42953,
				43.64461
			],
			[
				-79.42939,
				43.64435
			],
			[
				-79.42951,
				43.64427
			],
			[
				-79.42968,
				43.64429
			],
			[
				-79.42967,
				43.6442
			],
			[
				-79.42976,
				43.64433
			],
			[
				-79.42951,
				43.6443
			],
			[
				-79.42968,
				43.64429
			],
			[
				-79.42967,
				43.64424
			],
			[
				-79.4296,
				43.64432
			],
			[
				-79.42972,
				43.64448
			],
			[
				-79.42956,
				43.64429
			],
			[
				-79.42942,
				43.64453
			],
			[
				-79.42958,
				43.64477
			],
			[
				-79.43074,
				43.64802
			],
			[
				-79.4309,
				43.64828
			],
			[
				-79.4313,
				43.64956
			],
			[
				-79.43132,
				43.64951
			],
			[
				-79.43111,
				43.64962
			],
			[
				-79.42842,
				43.64951
			],
			[
				-79.42765,
				43.6496
			],
			[
				-79.42738,
				43.64952
			],
			[
				-79.42592,
				43.6495
			],
			[
				-79.4253,
				43.64939
			],
			[
				-79.42283,
				43.6494
			],
			[
				-79.42079,
				43.6493
			],
			[
				-79.42084,
				43.64933
			],
			[
				-79.42039,
				43.64937
			],
			[
				-79.41966,
				43.64958
			],
			[
				-79.41504,
				43.65042
			],
			[
				-79.41343,
				43.65082
			],
			[
				-79.41217,
				43.65099
			],
			[
				-79.40641,
				43.65222
			],
			[
				-79.40541,
				43.65235
			],
			[
				-79.40431,
				43.65188
			],
			[
				-79.40377,
				43.65176
			],
			[
				-79.3999,
				43.65252
			],
			[
				-79.39989,
				43.6527
			],
			[
				-79.39998,
				43.65285
			]
		],
		"dupe": false
	},
	{
		"id": 252656008,
		"name": "Afternoon Ride",
		"distance": 2754.8,
		"moving_time": 554,
		"elapsed_time": 557,
		"total_elevation_gain": 0,
		"start_date_local": "2015-02-09T13:29:58Z",
		"average_speed": 4.973,
		"max_speed": 8.9,
		"calories": 59,
		"points": [
			[
				-79.39912,
				43.65242
			],
			[
				-79.39986,
				43.65252
			],
			[
				-79.40013,
				43.6524
			],
			[
				-79.40035,
				43.65249
			],
			[
				-79.40059,
				43.65244
			],
			[
				-79.40068,
				43.65249
			],
			[
				-79.40132,
				43.65224
			],
			[
				-79.40166,
				43.65223
			],
			[
				-79.40369,
				43.65181
			],
			[
				-79.40424,
				43.65189
			],
			[
				-79.40517,
				43.65238
			],
			[
				-79.40568,
				43.65246
			],
			[
				-79.40722,
				43.65213
			],
			[
				-79.40759,
				43.65196
			],
			[
				-79.40875,
				43.65177
			],
			[
				-79.40901,
				43.65179
			],
			[
				-79.40933,
				43.65165
			],
			[
				-79.40977,
				43.65164
			],
			[
				-79.41342,
				43.65083
			],
			[
				-79.41698,
				43.65018
			],
			[
				-79.41883,
				43.64972
			],
			[
				-79.42081,
				43.64942
			],
			[
				-79.4236,
				43.64948
			],
			[
				-79.42395,
				43.64942
			],
			[
				-79.42454,
				43.64951
			],
			[
				-79.42449,
				43.64952
			],
			[
				-79.43132,
				43.64969
			]
		],
		"dupe": false
	},
	{
		"id": 256645954,
		"name": "Morning Ride",
		"distance": 1270.6,
		"moving_time": 222,
		"elapsed_time": 237,
		"total_elevation_gain": 0,
		"start_date_local": "2015-02-18T10:31:47Z",
		"average_speed": 5.723,
		"max_speed": 10.7,
		"calories": 18.2,
		"points": [
			[
				-79.42337,
				43.65333
			],
			[
				-79.42417,
				43.65304
			],
			[
				-79.42428,
				43.6531
			],
			[
				-79.42585,
				43.65276
			],
			[
				-79.42595,
				43.65247
			],
			[
				-79.42247,
				43.64367
			]
		],
		"dupe": false
	},
	{
		"id": 258688242,
		"name": "Evening Ride",
		"distance": 3736.5,
		"moving_time": 892,
		"elapsed_time": 961,
		"total_elevation_gain": 0,
		"start_date_local": "2015-02-22T19:03:12Z",
		"average_speed": 4.189,
		"max_speed": 12.1,
		"calories": 55.6,
		"points": [
			[
				-79.42952,
				43.64434
			],
			[
				-79.42915,
				43.64386
			],
			[
				-79.42775,
				43.64402
			],
			[
				-79.42711,
				43.64246
			],
			[
				-79.42692,
				43.64246
			],
			[
				-79.42623,
				43.64272
			],
			[
				-79.4251,
				43.64286
			],
			[
				-79.4242,
				43.64312
			],
			[
				-79.42188,
				43.64353
			],
			[
				-79.42046,
				43.64387
			],
			[
				-79.41907,
				43.64404
			],
			[
				-79.41609,
				43.64478
			],
			[
				-79.41517,
				43.64487
			],
			[
				-79.41211,
				43.6456
			],
			[
				-79.40996,
				43.64594
			],
			[
				-79.40962,
				43.64531
			],
			[
				-79.40379,
				43.64645
			],
			[
				-79.40348,
				43.64577
			],
			[
				-79.40342,
				43.64555
			],
			[
				-79.40347,
				43.64548
			],
			[
				-79.40328,
				43.64529
			],
			[
				-79.40326,
				43.64509
			],
			[
				-79.40283,
				43.6453
			],
			[
				-79.39818,
				43.64622
			],
			[
				-79.398,
				43.64572
			],
			[
				-79.39779,
				43.64572
			],
			[
				-79.39774,
				43.64562
			],
			[
				-79.39796,
				43.6454
			],
			[
				-79.39752,
				43.64541
			],
			[
				-79.39778,
				43.64544
			],
			[
				-79.39687,
				43.64585
			],
			[
				-79.39557,
				43.64593
			],
			[
				-79.39735,
				43.64595
			],
			[
				-79.39772,
				43.64585
			],
			[
				-79.39723,
				43.64586
			],
			[
				-79.39708,
				43.64596
			],
			[
				-79.39738,
				43.64575
			],
			[
				-79.39765,
				43.64568
			],
			[
				-79.39738,
				43.64586
			],
			[
				-79.39756,
				43.64577
			]
		],
		"dupe": false
	},
	{
		"id": 265232823,
		"name": "Evening Ride",
		"distance": 3322.5,
		"moving_time": 592,
		"elapsed_time": 883,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-08T18:50:24Z",
		"average_speed": 5.612,
		"max_speed": 9.3,
		"calories": 57.2,
		"points": [
			[
				-79.42952,
				43.64428
			],
			[
				-79.42939,
				43.64437
			],
			[
				-79.42977,
				43.6453
			],
			[
				-79.42975,
				43.64547
			],
			[
				-79.42836,
				43.64576
			],
			[
				-79.42818,
				43.6453
			],
			[
				-79.42785,
				43.64528
			],
			[
				-79.42661,
				43.64557
			],
			[
				-79.42616,
				43.64576
			],
			[
				-79.42431,
				43.646
			],
			[
				-79.42,
				43.64688
			],
			[
				-79.41986,
				43.64712
			],
			[
				-79.4199,
				43.64685
			],
			[
				-79.41938,
				43.64556
			],
			[
				-79.419,
				43.64433
			],
			[
				-79.41889,
				43.64417
			],
			[
				-79.41875,
				43.64415
			],
			[
				-79.41593,
				43.6448
			],
			[
				-79.41475,
				43.64504
			],
			[
				-79.41372,
				43.64515
			],
			[
				-79.41296,
				43.64539
			],
			[
				-79.41021,
				43.6459
			],
			[
				-79.40996,
				43.64591
			],
			[
				-79.41005,
				43.64591
			],
			[
				-79.40989,
				43.64581
			],
			[
				-79.40962,
				43.6453
			],
			[
				-79.40385,
				43.64644
			],
			[
				-79.40364,
				43.64619
			],
			[
				-79.40321,
				43.6452
			],
			[
				-79.3982,
				43.64621
			],
			[
				-79.39796,
				43.64562
			]
		],
		"dupe": false
	},
	{
		"id": 265477025,
		"name": "Morning Ride",
		"distance": 1855.8,
		"moving_time": 418,
		"elapsed_time": 440,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-09T10:52:24Z",
		"average_speed": 4.44,
		"max_speed": 12,
		"calories": 26.4,
		"points": [
			[
				-79.42374,
				43.65361
			],
			[
				-79.4235,
				43.65366
			],
			[
				-79.42353,
				43.65356
			],
			[
				-79.42342,
				43.65369
			],
			[
				-79.42358,
				43.65364
			],
			[
				-79.4234,
				43.65362
			],
			[
				-79.42342,
				43.65344
			],
			[
				-79.42357,
				43.65342
			],
			[
				-79.42358,
				43.65351
			],
			[
				-79.42346,
				43.65348
			],
			[
				-79.42348,
				43.65332
			],
			[
				-79.4259,
				43.65274
			],
			[
				-79.42594,
				43.65243
			],
			[
				-79.42569,
				43.65179
			],
			[
				-79.42592,
				43.65162
			],
			[
				-79.42649,
				43.65152
			],
			[
				-79.42652,
				43.65133
			],
			[
				-79.42585,
				43.64955
			],
			[
				-79.42959,
				43.64967
			],
			[
				-79.42981,
				43.64961
			],
			[
				-79.42806,
				43.64498
			],
			[
				-79.4282,
				43.64487
			],
			[
				-79.4293,
				43.64466
			],
			[
				-79.42942,
				43.64442
			],
			[
				-79.42931,
				43.64426
			],
			[
				-79.42944,
				43.6443
			]
		],
		"dupe": false
	},
	{
		"id": 268753201,
		"name": "Afternoon Ride",
		"distance": 3767.2,
		"moving_time": 726,
		"elapsed_time": 745,
		"total_elevation_gain": 16,
		"start_date_local": "2015-03-15T15:07:13Z",
		"average_speed": 5.189,
		"max_speed": 11.7,
		"calories": 83.6,
		"points": [
			[
				-79.42256,
				43.63271
			],
			[
				-79.4225,
				43.63262
			],
			[
				-79.42216,
				43.63259
			],
			[
				-79.42164,
				43.63231
			],
			[
				-79.42008,
				43.6326
			],
			[
				-79.41981,
				43.63259
			],
			[
				-79.4196,
				43.63225
			],
			[
				-79.41925,
				43.63134
			],
			[
				-79.41845,
				43.63143
			],
			[
				-79.41785,
				43.63115
			],
			[
				-79.41638,
				43.63137
			],
			[
				-79.41613,
				43.63157
			],
			[
				-79.41609,
				43.63182
			],
			[
				-79.41593,
				43.63209
			],
			[
				-79.41558,
				43.63224
			],
			[
				-79.41528,
				43.63219
			],
			[
				-79.415,
				43.63185
			],
			[
				-79.41535,
				43.63226
			],
			[
				-79.41551,
				43.63227
			],
			[
				-79.41587,
				43.63211
			],
			[
				-79.41597,
				43.63215
			],
			[
				-79.41596,
				43.63222
			],
			[
				-79.41495,
				43.63251
			],
			[
				-79.41435,
				43.63252
			],
			[
				-79.41408,
				43.63266
			],
			[
				-79.41418,
				43.63276
			],
			[
				-79.41436,
				43.63276
			],
			[
				-79.41485,
				43.63256
			],
			[
				-79.41516,
				43.63325
			],
			[
				-79.41551,
				43.63357
			],
			[
				-79.4156,
				43.63378
			],
			[
				-79.41568,
				43.63368
			],
			[
				-79.41549,
				43.63327
			],
			[
				-79.41568,
				43.63315
			],
			[
				-79.41709,
				43.63276
			],
			[
				-79.41784,
				43.63243
			],
			[
				-79.4194,
				43.63212
			],
			[
				-79.41965,
				43.63225
			],
			[
				-79.41982,
				43.63277
			],
			[
				-79.42147,
				43.6323
			],
			[
				-79.42193,
				43.63241
			],
			[
				-79.42216,
				43.63258
			],
			[
				-79.42324,
				43.63296
			],
			[
				-79.42425,
				43.63274
			],
			[
				-79.42483,
				43.63275
			],
			[
				-79.42498,
				43.63283
			],
			[
				-79.42737,
				43.63889
			],
			[
				-79.42731,
				43.63907
			],
			[
				-79.42826,
				43.64156
			],
			[
				-79.42843,
				43.64199
			],
			[
				-79.42868,
				43.64228
			],
			[
				-79.42878,
				43.64295
			],
			[
				-79.42894,
				43.64337
			],
			[
				-79.42926,
				43.64383
			],
			[
				-79.42947,
				43.64446
			],
			[
				-79.42946,
				43.64462
			],
			[
				-79.42922,
				43.64469
			],
			[
				-79.42949,
				43.64462
			],
			[
				-79.42954,
				43.64451
			],
			[
				-79.42948,
				43.64424
			]
		],
		"dupe": false
	},
	{
		"id": 271401578,
		"name": "Evening Ride",
		"distance": 3139.5,
		"moving_time": 526,
		"elapsed_time": 526,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-20T19:18:31Z",
		"average_speed": 5.969,
		"max_speed": 11.6,
		"calories": 64.5,
		"points": [
			[
				-79.42951,
				43.64424
			],
			[
				-79.42929,
				43.64414
			],
			[
				-79.42916,
				43.64383
			],
			[
				-79.42779,
				43.64404
			],
			[
				-79.42768,
				43.64391
			],
			[
				-79.42709,
				43.64239
			],
			[
				-79.42682,
				43.64252
			],
			[
				-79.423,
				43.64326
			],
			[
				-79.42178,
				43.6436
			],
			[
				-79.41895,
				43.64406
			],
			[
				-79.41656,
				43.64463
			],
			[
				-79.41429,
				43.64502
			],
			[
				-79.41213,
				43.64555
			],
			[
				-79.40996,
				43.64593
			],
			[
				-79.41004,
				43.64593
			],
			[
				-79.40991,
				43.64586
			],
			[
				-79.40961,
				43.64529
			],
			[
				-79.40376,
				43.64646
			],
			[
				-79.40341,
				43.64577
			],
			[
				-79.40328,
				43.64531
			],
			[
				-79.40296,
				43.64524
			],
			[
				-79.3982,
				43.64621
			],
			[
				-79.39792,
				43.6455
			],
			[
				-79.39767,
				43.64564
			],
			[
				-79.39785,
				43.64548
			],
			[
				-79.39786,
				43.64534
			]
		],
		"dupe": false
	},
	{
		"id": 273875990,
		"name": "Morning Ride",
		"distance": 2252.6,
		"moving_time": 426,
		"elapsed_time": 476,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-25T10:28:59Z",
		"average_speed": 5.288,
		"max_speed": 10,
		"calories": 48.1,
		"points": [
			[
				-79.39798,
				43.64562
			],
			[
				-79.39789,
				43.64558
			],
			[
				-79.39793,
				43.64584
			],
			[
				-79.39824,
				43.64634
			],
			[
				-79.39865,
				43.64745
			],
			[
				-79.40441,
				43.64638
			],
			[
				-79.40921,
				43.64532
			],
			[
				-79.40955,
				43.64535
			],
			[
				-79.40984,
				43.64591
			],
			[
				-79.41001,
				43.64601
			],
			[
				-79.4123,
				43.64548
			],
			[
				-79.41319,
				43.64541
			],
			[
				-79.41599,
				43.64476
			],
			[
				-79.41622,
				43.64479
			],
			[
				-79.42121,
				43.64373
			],
			[
				-79.42158,
				43.64373
			],
			[
				-79.42173,
				43.64361
			],
			[
				-79.42176,
				43.64367
			],
			[
				-79.42172,
				43.64362
			]
		],
		"dupe": false
	},
	{
		"id": 271415396,
		"name": "Evening Ride",
		"distance": 2748,
		"moving_time": 580,
		"elapsed_time": 634,
		"total_elevation_gain": 17.8,
		"start_date_local": "2015-03-20T19:55:50Z",
		"average_speed": 4.738,
		"max_speed": 12.1,
		"calories": 68.6,
		"points": [
			[
				-79.39757,
				43.64572
			],
			[
				-79.39789,
				43.64604
			],
			[
				-79.39814,
				43.64644
			],
			[
				-79.39825,
				43.64648
			],
			[
				-79.39866,
				43.64745
			],
			[
				-79.40357,
				43.6465
			],
			[
				-79.40373,
				43.64671
			],
			[
				-79.40454,
				43.64866
			],
			[
				-79.40452,
				43.64883
			],
			[
				-79.40506,
				43.65018
			],
			[
				-79.40614,
				43.65269
			],
			[
				-79.40625,
				43.65321
			],
			[
				-79.40663,
				43.65394
			],
			[
				-79.40668,
				43.6542
			],
			[
				-79.40771,
				43.65648
			],
			[
				-79.40955,
				43.66093
			],
			[
				-79.41039,
				43.66329
			],
			[
				-79.41055,
				43.6633
			],
			[
				-79.4105,
				43.66295
			],
			[
				-79.41063,
				43.66316
			],
			[
				-79.41078,
				43.66313
			],
			[
				-79.41056,
				43.66332
			]
		],
		"dupe": false
	},
	{
		"id": 274400876,
		"name": "Lunch Ride",
		"distance": 3674.7,
		"moving_time": 1230,
		"elapsed_time": 5763,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-26T11:13:28Z",
		"average_speed": 2.988,
		"max_speed": 10.5,
		"calories": 74.6,
		"points": [
			[
				-79.39862,
				43.64746
			],
			[
				-79.39872,
				43.64758
			],
			[
				-79.39937,
				43.64749
			],
			[
				-79.39957,
				43.64742
			],
			[
				-79.39965,
				43.64724
			],
			[
				-79.40375,
				43.64646
			],
			[
				-79.40427,
				43.64647
			],
			[
				-79.40548,
				43.64616
			],
			[
				-79.40738,
				43.64588
			],
			[
				-79.40929,
				43.64535
			],
			[
				-79.40951,
				43.64536
			],
			[
				-79.40961,
				43.64563
			],
			[
				-79.4098,
				43.64562
			],
			[
				-79.40998,
				43.64599
			],
			[
				-79.41061,
				43.64596
			],
			[
				-79.41108,
				43.64576
			],
			[
				-79.41485,
				43.64498
			],
			[
				-79.4188,
				43.64428
			],
			[
				-79.42141,
				43.64369
			],
			[
				-79.4235,
				43.64334
			],
			[
				-79.42457,
				43.64304
			],
			[
				-79.42694,
				43.64256
			],
			[
				-79.42723,
				43.64276
			],
			[
				-79.42776,
				43.64403
			],
			[
				-79.4292,
				43.64384
			],
			[
				-79.42944,
				43.64437
			],
			[
				-79.42964,
				43.6443
			],
			[
				-79.42961,
				43.64426
			],
			[
				-79.42966,
				43.64436
			],
			[
				-79.42955,
				43.64437
			],
			[
				-79.42964,
				43.64437
			],
			[
				-79.42972,
				43.64432
			],
			[
				-79.42959,
				43.64437
			],
			[
				-79.42962,
				43.64428
			],
			[
				-79.42958,
				43.64435
			],
			[
				-79.42952,
				43.64431
			],
			[
				-79.42922,
				43.64344
			],
			[
				-79.42896,
				43.64301
			],
			[
				-79.429,
				43.64275
			],
			[
				-79.42851,
				43.64196
			],
			[
				-79.42848,
				43.64179
			],
			[
				-79.42855,
				43.64175
			],
			[
				-79.42894,
				43.64221
			],
			[
				-79.42945,
				43.64221
			],
			[
				-79.42999,
				43.64202
			],
			[
				-79.43007,
				43.64213
			],
			[
				-79.42979,
				43.64208
			],
			[
				-79.42967,
				43.64194
			],
			[
				-79.42974,
				43.6419
			],
			[
				-79.42954,
				43.64199
			],
			[
				-79.42985,
				43.64194
			],
			[
				-79.42974,
				43.64199
			],
			[
				-79.4299,
				43.64205
			],
			[
				-79.42978,
				43.64213
			]
		],
		"dupe": false
	},
	{
		"id": 274818066,
		"name": "Morning Ride",
		"distance": 2223.3,
		"moving_time": 375,
		"elapsed_time": 398,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-27T10:45:09Z",
		"average_speed": 5.929,
		"max_speed": 10.2,
		"calories": 42.8,
		"points": [
			[
				-79.40562,
				43.64621
			],
			[
				-79.40584,
				43.64602
			],
			[
				-79.40643,
				43.64609
			],
			[
				-79.40927,
				43.64536
			],
			[
				-79.40965,
				43.64538
			],
			[
				-79.40964,
				43.64548
			],
			[
				-79.40977,
				43.64557
			],
			[
				-79.40998,
				43.64598
			],
			[
				-79.41027,
				43.64601
			],
			[
				-79.4114,
				43.6457
			],
			[
				-79.41309,
				43.64543
			],
			[
				-79.41481,
				43.64501
			],
			[
				-79.41681,
				43.64466
			],
			[
				-79.42003,
				43.64395
			],
			[
				-79.42167,
				43.64372
			],
			[
				-79.42222,
				43.64354
			],
			[
				-79.42345,
				43.64336
			],
			[
				-79.42696,
				43.64257
			],
			[
				-79.42723,
				43.64276
			],
			[
				-79.42777,
				43.64403
			],
			[
				-79.42915,
				43.64383
			],
			[
				-79.42943,
				43.6442
			]
		],
		"dupe": false
	},
	{
		"id": 275065521,
		"name": "Night Ride",
		"distance": 3307.7,
		"moving_time": 626,
		"elapsed_time": 673,
		"total_elevation_gain": 10.8,
		"start_date_local": "2015-03-27T21:25:29Z",
		"average_speed": 5.284,
		"max_speed": 10,
		"calories": 64.5,
		"points": [
			[
				-79.3979,
				43.64542
			],
			[
				-79.39797,
				43.64555
			],
			[
				-79.39788,
				43.64559
			],
			[
				-79.3979,
				43.64588
			],
			[
				-79.39802,
				43.64605
			],
			[
				-79.39803,
				43.64623
			],
			[
				-79.39828,
				43.64656
			],
			[
				-79.39862,
				43.64736
			],
			[
				-79.39876,
				43.64743
			],
			[
				-79.40653,
				43.64597
			],
			[
				-79.40712,
				43.64579
			],
			[
				-79.40932,
				43.64537
			],
			[
				-79.40958,
				43.64539
			],
			[
				-79.40986,
				43.64564
			],
			[
				-79.41113,
				43.64538
			],
			[
				-79.41127,
				43.64572
			],
			[
				-79.41152,
				43.64578
			],
			[
				-79.41276,
				43.64546
			],
			[
				-79.41449,
				43.64516
			],
			[
				-79.41581,
				43.64481
			],
			[
				-79.42476,
				43.64306
			],
			[
				-79.42486,
				43.6431
			],
			[
				-79.42633,
				43.64693
			],
			[
				-79.42707,
				43.64687
			],
			[
				-79.42808,
				43.64942
			],
			[
				-79.42783,
				43.64945
			]
		],
		"dupe": false
	},
	{
		"id": 275588182,
		"name": "Evening Ride",
		"distance": 2017,
		"moving_time": 398,
		"elapsed_time": 404,
		"total_elevation_gain": 14,
		"start_date_local": "2015-03-28T19:12:39Z",
		"average_speed": 5.068,
		"max_speed": 10.3,
		"calories": 45.4,
		"points": [
			[
				-79.39779,
				43.64537
			],
			[
				-79.39812,
				43.64547
			],
			[
				-79.39792,
				43.64561
			],
			[
				-79.39803,
				43.64604
			],
			[
				-79.39864,
				43.64743
			],
			[
				-79.40369,
				43.64649
			],
			[
				-79.4045,
				43.64872
			],
			[
				-79.40463,
				43.64883
			],
			[
				-79.40705,
				43.64833
			],
			[
				-79.40749,
				43.64927
			],
			[
				-79.40796,
				43.65063
			],
			[
				-79.40813,
				43.65086
			],
			[
				-79.40864,
				43.65242
			],
			[
				-79.40938,
				43.65402
			],
			[
				-79.40946,
				43.65445
			],
			[
				-79.40961,
				43.65463
			],
			[
				-79.40971,
				43.65506
			],
			[
				-79.41001,
				43.65562
			]
		],
		"dupe": false
	},
	{
		"id": 275644848,
		"name": "Night Ride",
		"distance": 2072.2,
		"moving_time": 387,
		"elapsed_time": 407,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-28T22:17:34Z",
		"average_speed": 5.355,
		"max_speed": 11.1,
		"calories": 29.9,
		"points": [
			[
				-79.41015,
				43.65576
			],
			[
				-79.41029,
				43.65573
			],
			[
				-79.41026,
				43.65553
			],
			[
				-79.40995,
				43.65536
			],
			[
				-79.40854,
				43.65185
			],
			[
				-79.40609,
				43.65223
			],
			[
				-79.40614,
				43.65224
			],
			[
				-79.40576,
				43.6518
			],
			[
				-79.40558,
				43.65097
			],
			[
				-79.40418,
				43.64771
			],
			[
				-79.4038,
				43.6464
			],
			[
				-79.40349,
				43.64584
			],
			[
				-79.40346,
				43.64563
			],
			[
				-79.40326,
				43.64536
			],
			[
				-79.40292,
				43.64525
			],
			[
				-79.39816,
				43.64622
			],
			[
				-79.39795,
				43.64559
			],
			[
				-79.39773,
				43.6456
			],
			[
				-79.39751,
				43.64572
			],
			[
				-79.39749,
				43.64586
			]
		],
		"dupe": false
	},
	{
		"id": 276237612,
		"name": "Night Ride",
		"distance": 795.9,
		"moving_time": 135,
		"elapsed_time": 138,
		"total_elevation_gain": 0,
		"start_date_local": "2015-03-29T22:48:39Z",
		"average_speed": 5.896,
		"max_speed": 10.8,
		"calories": 19.3,
		"points": [
			[
				-79.41828,
				43.64966
			],
			[
				-79.4184,
				43.64987
			],
			[
				-79.41834,
				43.65001
			],
			[
				-79.41841,
				43.65036
			],
			[
				-79.4186,
				43.65066
			],
			[
				-79.41854,
				43.65071
			],
			[
				-79.41878,
				43.65135
			],
			[
				-79.41922,
				43.65139
			],
			[
				-79.42135,
				43.65096
			],
			[
				-79.42231,
				43.65343
			],
			[
				-79.42261,
				43.65345
			],
			[
				-79.42343,
				43.65329
			]
		],
		"dupe": false
	},
	{
		"id": 277973222,
		"name": "Lunch Ride",
		"distance": 2470.8,
		"moving_time": 819,
		"elapsed_time": 1376,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-02T11:06:06Z",
		"average_speed": 3.017,
		"max_speed": 15.3,
		"calories": 35,
		"points": [
			[
				-79.42342,
				43.65326
			],
			[
				-79.42394,
				43.65322
			],
			[
				-79.42603,
				43.65272
			],
			[
				-79.42346,
				43.64618
			],
			[
				-79.42812,
				43.64522
			],
			[
				-79.42806,
				43.64496
			],
			[
				-79.42944,
				43.64463
			],
			[
				-79.42952,
				43.64443
			],
			[
				-79.42944,
				43.64432
			],
			[
				-79.42958,
				43.64437
			],
			[
				-79.42955,
				43.64431
			],
			[
				-79.42966,
				43.64426
			],
			[
				-79.42947,
				43.64425
			],
			[
				-79.42921,
				43.64382
			],
			[
				-79.42913,
				43.64313
			],
			[
				-79.42896,
				43.64297
			],
			[
				-79.429,
				43.64291
			],
			[
				-79.42829,
				43.64128
			],
			[
				-79.42886,
				43.64225
			],
			[
				-79.42891,
				43.64218
			],
			[
				-79.42881,
				43.64207
			],
			[
				-79.43125,
				43.64166
			],
			[
				-79.43112,
				43.64146
			],
			[
				-79.43158,
				43.64163
			],
			[
				-79.43142,
				43.64166
			],
			[
				-79.43151,
				43.64155
			]
		],
		"dupe": false
	},
	{
		"id": 280472132,
		"name": "Afternoon Ride",
		"distance": 5119.7,
		"moving_time": 1505,
		"elapsed_time": 1896,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-06T14:06:06Z",
		"average_speed": 3.402,
		"max_speed": 13.6,
		"calories": 99,
		"points": [
			[
				-73.97052,
				40.74897
			],
			[
				-73.97042,
				40.74891
			],
			[
				-73.97066,
				40.749
			],
			[
				-73.97073,
				40.74926
			],
			[
				-73.97168,
				40.74966
			],
			[
				-73.97174,
				40.74984
			],
			[
				-73.97166,
				40.74986
			],
			[
				-73.972,
				40.74998
			],
			[
				-73.97214,
				40.75014
			],
			[
				-73.97237,
				40.74977
			],
			[
				-73.97245,
				40.74942
			],
			[
				-73.97309,
				40.74863
			],
			[
				-73.97289,
				40.74841
			],
			[
				-73.97313,
				40.74797
			],
			[
				-73.97327,
				40.74788
			],
			[
				-73.97359,
				40.74791
			],
			[
				-73.97466,
				40.74654
			],
			[
				-73.97539,
				40.74552
			],
			[
				-73.9753,
				40.74546
			],
			[
				-73.97571,
				40.74498
			],
			[
				-73.97591,
				40.74458
			],
			[
				-73.97616,
				40.74442
			],
			[
				-73.98765,
				40.72859
			],
			[
				-73.991,
				40.72403
			],
			[
				-73.99124,
				40.72381
			],
			[
				-73.99127,
				40.72364
			],
			[
				-73.99112,
				40.72352
			],
			[
				-73.99291,
				40.72009
			],
			[
				-73.99304,
				40.71999
			],
			[
				-73.99343,
				40.71923
			],
			[
				-73.99344,
				40.71909
			],
			[
				-73.99388,
				40.71829
			],
			[
				-73.99387,
				40.71815
			],
			[
				-73.99092,
				40.71728
			],
			[
				-73.98845,
				40.71644
			],
			[
				-73.98821,
				40.71673
			],
			[
				-73.98803,
				40.71724
			],
			[
				-73.98796,
				40.71725
			],
			[
				-73.98797,
				40.71738
			],
			[
				-73.98795,
				40.71733
			],
			[
				-73.98805,
				40.7174
			]
		],
		"dupe": false
	},
	{
		"id": 280452535,
		"name": "Lunch Ride",
		"distance": 5442,
		"moving_time": 1253,
		"elapsed_time": 1419,
		"total_elevation_gain": 13.8,
		"start_date_local": "2015-04-06T12:24:58Z",
		"average_speed": 4.343,
		"max_speed": 15.2,
		"calories": 109.4,
		"points": [
			[
				-73.99443,
				40.74688
			],
			[
				-73.99665,
				40.7478
			],
			[
				-73.99396,
				40.75159
			],
			[
				-73.99344,
				40.75216
			],
			[
				-73.99257,
				40.75346
			],
			[
				-73.98835,
				40.75913
			],
			[
				-73.98786,
				40.75989
			],
			[
				-73.98775,
				40.75971
			],
			[
				-73.98791,
				40.75978
			],
			[
				-73.98368,
				40.76556
			],
			[
				-73.98369,
				40.76562
			],
			[
				-73.9843,
				40.76574
			],
			[
				-73.9845,
				40.7657
			],
			[
				-73.98662,
				40.76659
			],
			[
				-73.98682,
				40.76641
			],
			[
				-73.98709,
				40.76597
			],
			[
				-73.97227,
				40.75972
			],
			[
				-73.97201,
				40.75995
			],
			[
				-73.97165,
				40.75991
			],
			[
				-73.97134,
				40.75923
			],
			[
				-73.9713,
				40.75889
			],
			[
				-73.97129,
				40.75895
			],
			[
				-73.97113,
				40.75886
			],
			[
				-73.9709,
				40.75905
			],
			[
				-73.97084,
				40.75925
			],
			[
				-73.97067,
				40.75927
			],
			[
				-73.97019,
				40.75884
			],
			[
				-73.96936,
				40.75849
			],
			[
				-73.96902,
				40.75889
			],
			[
				-73.96868,
				40.75854
			],
			[
				-73.96854,
				40.75802
			],
			[
				-73.96823,
				40.75772
			],
			[
				-73.96785,
				40.75785
			],
			[
				-73.96676,
				40.75739
			],
			[
				-73.9668,
				40.75723
			],
			[
				-73.96771,
				40.75597
			]
		],
		"dupe": false
	},
	{
		"id": 280491083,
		"name": "Afternoon Ride",
		"distance": 4704.2,
		"moving_time": 1130,
		"elapsed_time": 1690,
		"total_elevation_gain": 28.2,
		"start_date_local": "2015-04-06T14:40:32Z",
		"average_speed": 4.163,
		"max_speed": 13.1,
		"calories": 108.6,
		"points": [
			[
				-73.98805,
				40.71701
			],
			[
				-73.98842,
				40.71676
			],
			[
				-73.98841,
				40.71662
			],
			[
				-73.98827,
				40.71656
			],
			[
				-73.98825,
				40.71643
			],
			[
				-73.98761,
				40.71609
			],
			[
				-73.98777,
				40.71641
			],
			[
				-73.98723,
				40.71606
			],
			[
				-73.98666,
				40.71595
			],
			[
				-73.98583,
				40.71759
			],
			[
				-73.9855,
				40.71755
			],
			[
				-73.97896,
				40.71558
			],
			[
				-73.97847,
				40.71554
			],
			[
				-73.97836,
				40.71543
			],
			[
				-73.97714,
				40.71506
			],
			[
				-73.97702,
				40.71514
			],
			[
				-73.9768,
				40.71507
			],
			[
				-73.97671,
				40.71495
			],
			[
				-73.97589,
				40.71468
			],
			[
				-73.97579,
				40.71478
			],
			[
				-73.97522,
				40.71461
			],
			[
				-73.97495,
				40.71445
			],
			[
				-73.97472,
				40.71446
			],
			[
				-73.97436,
				40.71421
			],
			[
				-73.97178,
				40.71343
			],
			[
				-73.97168,
				40.71351
			],
			[
				-73.97116,
				40.71338
			],
			[
				-73.96726,
				40.71215
			],
			[
				-73.96677,
				40.71218
			],
			[
				-73.96587,
				40.71189
			],
			[
				-73.96574,
				40.71192
			],
			[
				-73.96094,
				40.7105
			],
			[
				-73.9606,
				40.71102
			],
			[
				-73.9609,
				40.71117
			],
			[
				-73.96575,
				40.71286
			],
			[
				-73.96592,
				40.71254
			],
			[
				-73.96599,
				40.71298
			],
			[
				-73.9661,
				40.71295
			],
			[
				-73.96741,
				40.71329
			],
			[
				-73.96761,
				40.71311
			],
			[
				-73.96769,
				40.71278
			],
			[
				-73.96792,
				40.71261
			],
			[
				-73.96808,
				40.71199
			],
			[
				-73.9684,
				40.71138
			],
			[
				-73.96823,
				40.71119
			],
			[
				-73.96835,
				40.71102
			],
			[
				-73.9685,
				40.71099
			],
			[
				-73.96812,
				40.71096
			],
			[
				-73.96828,
				40.71093
			],
			[
				-73.96798,
				40.71204
			],
			[
				-73.96716,
				40.71384
			],
			[
				-73.96692,
				40.71386
			],
			[
				-73.96462,
				40.71322
			],
			[
				-73.96264,
				40.71255
			]
		],
		"dupe": false
	},
	{
		"id": 280534778,
		"name": "Afternoon Ride",
		"distance": 5136.4,
		"moving_time": 1037,
		"elapsed_time": 1250,
		"total_elevation_gain": 10.9,
		"start_date_local": "2015-04-06T15:46:31Z",
		"average_speed": 4.953,
		"max_speed": 11.5,
		"calories": 111.7,
		"points": [
			[
				-73.96597,
				40.71284
			],
			[
				-73.96586,
				40.71265
			],
			[
				-73.96684,
				40.71041
			],
			[
				-73.96741,
				40.70742
			],
			[
				-73.9673,
				40.707
			],
			[
				-73.96641,
				40.70488
			],
			[
				-73.9665,
				40.70472
			],
			[
				-73.96735,
				40.70412
			],
			[
				-73.96738,
				40.70352
			],
			[
				-73.96738,
				40.704
			],
			[
				-73.96728,
				40.70418
			],
			[
				-73.96255,
				40.70042
			],
			[
				-73.96239,
				40.69972
			],
			[
				-73.96289,
				40.69792
			],
			[
				-73.96299,
				40.69786
			],
			[
				-73.96305,
				40.69744
			],
			[
				-73.96345,
				40.69683
			],
			[
				-73.96406,
				40.69634
			],
			[
				-73.96478,
				40.69602
			],
			[
				-73.96547,
				40.69593
			],
			[
				-73.9664,
				40.69596
			],
			[
				-73.96642,
				40.69582
			],
			[
				-73.96681,
				40.69598
			],
			[
				-73.9694,
				40.69608
			],
			[
				-73.96972,
				40.69764
			],
			[
				-73.96972,
				40.69813
			],
			[
				-73.96963,
				40.69809
			],
			[
				-73.96975,
				40.69802
			],
			[
				-73.9698,
				40.69785
			],
			[
				-73.97125,
				40.69802
			],
			[
				-73.9715,
				40.69794
			],
			[
				-73.97216,
				40.69798
			],
			[
				-73.9725,
				40.69784
			],
			[
				-73.97268,
				40.69787
			],
			[
				-73.97273,
				40.69796
			],
			[
				-73.97367,
				40.69803
			],
			[
				-73.97895,
				40.69826
			],
			[
				-73.98055,
				40.69824
			],
			[
				-73.98047,
				40.69671
			],
			[
				-73.98016,
				40.69462
			],
			[
				-73.97932,
				40.69378
			],
			[
				-73.97921,
				40.69354
			],
			[
				-73.9795,
				40.69349
			],
			[
				-73.98105,
				40.69354
			],
			[
				-73.98433,
				40.69375
			],
			[
				-73.98428,
				40.69339
			],
			[
				-73.98433,
				40.69224
			]
		],
		"dupe": false
	},
	{
		"id": 280555464,
		"name": "Afternoon Ride",
		"distance": 4309.6,
		"moving_time": 1079,
		"elapsed_time": 1225,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-06T17:04:04Z",
		"average_speed": 3.994,
		"max_speed": 10.6,
		"calories": 77.3,
		"points": [
			[
				-73.98775,
				40.7178
			],
			[
				-73.98781,
				40.71769
			],
			[
				-73.98671,
				40.71966
			],
			[
				-73.98696,
				40.71965
			],
			[
				-73.99146,
				40.72101
			],
			[
				-73.99024,
				40.72347
			],
			[
				-73.99038,
				40.72357
			],
			[
				-73.99248,
				40.72421
			],
			[
				-73.99525,
				40.72518
			],
			[
				-73.99681,
				40.72553
			],
			[
				-74.00273,
				40.72837
			],
			[
				-74.00267,
				40.72869
			],
			[
				-74.00239,
				40.72931
			],
			[
				-74.00134,
				40.73111
			],
			[
				-73.99942,
				40.73386
			],
			[
				-73.9996,
				40.73393
			],
			[
				-73.9998,
				40.73438
			],
			[
				-74.00277,
				40.73935
			],
			[
				-73.99983,
				40.74339
			],
			[
				-73.99712,
				40.74227
			]
		],
		"dupe": false
	},
	{
		"id": 280544449,
		"name": "Afternoon Ride",
		"distance": 4902.4,
		"moving_time": 1130,
		"elapsed_time": 1281,
		"total_elevation_gain": 13,
		"start_date_local": "2015-04-06T16:36:48Z",
		"average_speed": 4.338,
		"max_speed": 13.6,
		"calories": 101.5,
		"points": [
			[
				-73.98613,
				40.69256
			],
			[
				-73.98609,
				40.69231
			],
			[
				-73.98641,
				40.69223
			],
			[
				-73.98713,
				40.69251
			],
			[
				-73.98722,
				40.69264
			],
			[
				-73.9872,
				40.69279
			],
			[
				-73.98697,
				40.69294
			],
			[
				-73.98695,
				40.6931
			],
			[
				-73.98705,
				40.69321
			],
			[
				-73.98702,
				40.69368
			],
			[
				-73.98709,
				40.69381
			],
			[
				-73.98691,
				40.69427
			],
			[
				-73.98702,
				40.69431
			],
			[
				-73.98704,
				40.69449
			],
			[
				-73.98696,
				40.69461
			],
			[
				-73.98712,
				40.69497
			],
			[
				-73.98701,
				40.69517
			],
			[
				-73.98707,
				40.69527
			],
			[
				-73.98691,
				40.69557
			],
			[
				-73.987,
				40.69618
			],
			[
				-73.98695,
				40.69638
			],
			[
				-73.98701,
				40.69719
			],
			[
				-73.98688,
				40.69744
			],
			[
				-73.98696,
				40.69767
			],
			[
				-73.9868,
				40.69767
			],
			[
				-73.98685,
				40.69823
			],
			[
				-73.9867,
				40.69912
			],
			[
				-73.98674,
				40.69935
			],
			[
				-73.98664,
				40.69949
			],
			[
				-73.98673,
				40.69973
			],
			[
				-73.98666,
				40.69995
			],
			[
				-73.98673,
				40.7
			],
			[
				-73.98551,
				40.69985
			],
			[
				-73.98512,
				40.69964
			],
			[
				-73.98533,
				40.6994
			],
			[
				-73.98607,
				40.69948
			],
			[
				-73.98615,
				40.69957
			],
			[
				-73.98709,
				40.70114
			],
			[
				-73.98751,
				40.70203
			],
			[
				-73.98744,
				40.70252
			],
			[
				-73.98789,
				40.7028
			],
			[
				-73.98836,
				40.70353
			],
			[
				-73.98878,
				40.70467
			],
			[
				-73.98968,
				40.70575
			],
			[
				-73.9899,
				40.70621
			],
			[
				-73.99031,
				40.70665
			],
			[
				-73.99058,
				40.70709
			],
			[
				-73.99091,
				40.70777
			],
			[
				-73.99118,
				40.70871
			],
			[
				-73.99139,
				40.70898
			],
			[
				-73.99186,
				40.71001
			],
			[
				-73.99203,
				40.71003
			],
			[
				-73.99223,
				40.71039
			],
			[
				-73.99216,
				40.71048
			],
			[
				-73.99232,
				40.71087
			],
			[
				-73.99286,
				40.71171
			],
			[
				-73.99298,
				40.71204
			],
			[
				-73.99323,
				40.71233
			],
			[
				-73.99319,
				40.7124
			],
			[
				-73.99328,
				40.71264
			],
			[
				-73.99359,
				40.71299
			],
			[
				-73.99475,
				40.71493
			],
			[
				-73.99484,
				40.71565
			],
			[
				-73.99457,
				40.71571
			],
			[
				-73.99026,
				40.71441
			],
			[
				-73.99024,
				40.71413
			],
			[
				-73.98997,
				40.71408
			],
			[
				-73.9824,
				40.71467
			],
			[
				-73.9867,
				40.71598
			],
			[
				-73.9869,
				40.71602
			],
			[
				-73.98702,
				40.71576
			],
			[
				-73.98671,
				40.71608
			],
			[
				-73.98683,
				40.71617
			]
		],
		"dupe": false
	},
	{
		"id": 281098601,
		"name": "Afternoon Ride",
		"distance": 2207.4,
		"moving_time": 469,
		"elapsed_time": 630,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-07T15:41:07Z",
		"average_speed": 4.707,
		"max_speed": 11.3,
		"calories": 41.2,
		"points": [
			[
				-74.00711,
				40.72868
			],
			[
				-74.0071,
				40.7288
			],
			[
				-74.00721,
				40.72885
			],
			[
				-74.0072,
				40.729
			],
			[
				-74.00732,
				40.72894
			],
			[
				-74.00795,
				40.72901
			],
			[
				-74.0083,
				40.72887
			],
			[
				-74.00982,
				40.7291
			],
			[
				-74.01032,
				40.72906
			],
			[
				-74.01026,
				40.72906
			],
			[
				-74.01076,
				40.72919
			],
			[
				-74.01085,
				40.72912
			],
			[
				-74.01083,
				40.72817
			],
			[
				-74.01095,
				40.72773
			],
			[
				-74.011,
				40.72697
			],
			[
				-74.01109,
				40.72691
			],
			[
				-74.0113,
				40.72521
			],
			[
				-74.01181,
				40.723
			],
			[
				-74.01191,
				40.72212
			],
			[
				-74.01265,
				40.7191
			],
			[
				-74.01263,
				40.71889
			],
			[
				-74.01304,
				40.71761
			],
			[
				-74.014,
				40.71389
			],
			[
				-74.01389,
				40.71396
			],
			[
				-74.01397,
				40.71366
			],
			[
				-74.01389,
				40.71362
			]
		],
		"dupe": false
	},
	{
		"id": 281116034,
		"name": "Afternoon Ride",
		"distance": 6106.6,
		"moving_time": 1300,
		"elapsed_time": 1364,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-07T16:11:32Z",
		"average_speed": 4.697,
		"max_speed": 16.9,
		"calories": 101.8,
		"points": [
			[
				-74.01182,
				40.70155
			],
			[
				-74.01248,
				40.70149
			],
			[
				-74.01247,
				40.70142
			],
			[
				-74.01227,
				40.70134
			],
			[
				-74.01127,
				40.70164
			],
			[
				-74.01025,
				40.70176
			],
			[
				-74.00915,
				40.70209
			],
			[
				-74.00695,
				40.70356
			],
			[
				-74.00708,
				40.70374
			],
			[
				-74.00692,
				40.70365
			],
			[
				-74.00651,
				40.70383
			],
			[
				-74.00642,
				40.70399
			],
			[
				-74.00608,
				40.7042
			],
			[
				-74.00568,
				40.70427
			],
			[
				-74.00408,
				40.70531
			],
			[
				-74.00375,
				40.70556
			],
			[
				-74.00362,
				40.70578
			],
			[
				-74.0022,
				40.70663
			],
			[
				-74.00186,
				40.70649
			],
			[
				-74.00192,
				40.70642
			],
			[
				-74.00184,
				40.70668
			],
			[
				-74.00169,
				40.70676
			],
			[
				-74.00149,
				40.70671
			],
			[
				-74.00154,
				40.70695
			],
			[
				-74.00142,
				40.70718
			],
			[
				-74.00078,
				40.70741
			],
			[
				-74.00023,
				40.7078
			],
			[
				-73.99968,
				40.70783
			],
			[
				-73.99985,
				40.7077
			],
			[
				-73.99976,
				40.70764
			],
			[
				-73.99946,
				40.70797
			],
			[
				-73.99844,
				40.70849
			],
			[
				-73.99851,
				40.70845
			],
			[
				-73.99829,
				40.70826
			],
			[
				-73.99775,
				40.70824
			],
			[
				-73.99736,
				40.70837
			],
			[
				-73.99733,
				40.70878
			],
			[
				-73.99711,
				40.70883
			],
			[
				-73.99426,
				40.7093
			],
			[
				-73.99395,
				40.70926
			],
			[
				-73.99245,
				40.70946
			],
			[
				-73.99221,
				40.70957
			],
			[
				-73.99177,
				40.70958
			],
			[
				-73.99157,
				40.70973
			],
			[
				-73.98615,
				40.71045
			],
			[
				-73.98476,
				40.71057
			],
			[
				-73.98373,
				40.71052
			],
			[
				-73.98009,
				40.71079
			],
			[
				-73.97911,
				40.71109
			],
			[
				-73.97822,
				40.71163
			],
			[
				-73.97759,
				40.71236
			],
			[
				-73.97494,
				40.71745
			],
			[
				-73.97478,
				40.7182
			],
			[
				-73.97474,
				40.71886
			],
			[
				-73.97458,
				40.71897
			],
			[
				-73.97454,
				40.71912
			],
			[
				-73.97464,
				40.71939
			],
			[
				-73.97449,
				40.72149
			],
			[
				-73.97445,
				40.72145
			],
			[
				-73.97449,
				40.7211
			],
			[
				-73.97442,
				40.72125
			],
			[
				-73.97477,
				40.72131
			],
			[
				-73.97489,
				40.7213
			],
			[
				-73.97496,
				40.7211
			],
			[
				-73.97477,
				40.72159
			],
			[
				-73.9768,
				40.72244
			],
			[
				-73.97678,
				40.72267
			],
			[
				-73.97517,
				40.72487
			],
			[
				-73.98128,
				40.72744
			]
		],
		"dupe": false
	},
	{
		"id": 281142093,
		"name": "Afternoon Ride",
		"distance": 2705,
		"moving_time": 595,
		"elapsed_time": 718,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-07T17:19:35Z",
		"average_speed": 4.546,
		"max_speed": 9.2,
		"calories": 53.7,
		"points": [
			[
				-73.98059,
				40.73008
			],
			[
				-73.9803,
				40.73043
			],
			[
				-73.98033,
				40.73062
			],
			[
				-73.98225,
				40.73138
			],
			[
				-73.98163,
				40.73232
			],
			[
				-73.98174,
				40.73237
			],
			[
				-73.98161,
				40.73265
			],
			[
				-73.98118,
				40.73326
			],
			[
				-73.98738,
				40.73587
			],
			[
				-73.9873,
				40.73599
			],
			[
				-73.98782,
				40.73605
			],
			[
				-73.98894,
				40.73651
			],
			[
				-73.98873,
				40.73692
			],
			[
				-73.98721,
				40.73893
			],
			[
				-73.98726,
				40.73888
			],
			[
				-73.99668,
				40.74284
			],
			[
				-73.99669,
				40.74272
			],
			[
				-73.99731,
				40.74181
			]
		],
		"dupe": false
	},
	{
		"id": 282729632,
		"name": "Lunch Ride",
		"distance": 2689.5,
		"moving_time": 520,
		"elapsed_time": 520,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-10T12:19:47Z",
		"average_speed": 5.172,
		"max_speed": 9.9,
		"calories": 51.5,
		"points": [
			[
				-79.42878,
				43.64508
			],
			[
				-79.42931,
				43.64489
			],
			[
				-79.42953,
				43.6449
			],
			[
				-79.42982,
				43.64545
			],
			[
				-79.42842,
				43.64575
			],
			[
				-79.42885,
				43.64698
			],
			[
				-79.42899,
				43.64761
			],
			[
				-79.42933,
				43.64839
			],
			[
				-79.42938,
				43.64871
			],
			[
				-79.42931,
				43.64883
			],
			[
				-79.42942,
				43.64884
			],
			[
				-79.42966,
				43.64922
			],
			[
				-79.42971,
				43.64951
			],
			[
				-79.42966,
				43.64957
			],
			[
				-79.42567,
				43.64945
			],
			[
				-79.42527,
				43.64937
			],
			[
				-79.42261,
				43.64938
			],
			[
				-79.42134,
				43.64925
			],
			[
				-79.42055,
				43.6493
			],
			[
				-79.41774,
				43.64998
			],
			[
				-79.41707,
				43.64999
			],
			[
				-79.41669,
				43.65013
			],
			[
				-79.41632,
				43.65015
			],
			[
				-79.41506,
				43.6505
			],
			[
				-79.41449,
				43.65053
			],
			[
				-79.41292,
				43.65086
			],
			[
				-79.41268,
				43.65081
			],
			[
				-79.41282,
				43.65092
			],
			[
				-79.4138,
				43.65341
			],
			[
				-79.41323,
				43.65353
			],
			[
				-79.41388,
				43.65517
			],
			[
				-79.41322,
				43.65528
			]
		],
		"dupe": false
	},
	{
		"id": 282826011,
		"name": "Afternoon Ride",
		"distance": 3373.1,
		"moving_time": 995,
		"elapsed_time": 5625,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-10T13:26:40Z",
		"average_speed": 3.39,
		"max_speed": 10.8,
		"calories": 54.7,
		"points": [
			[
				-79.41299,
				43.65546
			],
			[
				-79.41378,
				43.65536
			],
			[
				-79.41373,
				43.65535
			],
			[
				-79.41525,
				43.65495
			],
			[
				-79.41778,
				43.65531
			],
			[
				-79.41906,
				43.65523
			],
			[
				-79.42175,
				43.65462
			],
			[
				-79.42595,
				43.65381
			],
			[
				-79.42761,
				43.6534
			],
			[
				-79.43004,
				43.65295
			],
			[
				-79.43092,
				43.65294
			],
			[
				-79.43119,
				43.65283
			],
			[
				-79.43104,
				43.65268
			],
			[
				-79.43063,
				43.65168
			],
			[
				-79.42805,
				43.64495
			],
			[
				-79.42955,
				43.64461
			],
			[
				-79.42959,
				43.64445
			],
			[
				-79.4295,
				43.64433
			],
			[
				-79.42963,
				43.64426
			],
			[
				-79.42976,
				43.6444
			],
			[
				-79.42958,
				43.64432
			],
			[
				-79.42966,
				43.64438
			],
			[
				-79.42967,
				43.64428
			],
			[
				-79.42941,
				43.64409
			],
			[
				-79.42911,
				43.64312
			],
			[
				-79.42869,
				43.64272
			],
			[
				-79.42828,
				43.64196
			],
			[
				-79.42883,
				43.64192
			],
			[
				-79.4288,
				43.64219
			],
			[
				-79.42881,
				43.64208
			],
			[
				-79.43152,
				43.6416
			]
		],
		"dupe": false
	},
	{
		"id": 283587400,
		"name": "Evening Ride",
		"distance": 6095.9,
		"moving_time": 951,
		"elapsed_time": 973,
		"total_elevation_gain": 25.4,
		"start_date_local": "2015-04-11T20:16:00Z",
		"average_speed": 6.41,
		"max_speed": 11.9,
		"calories": 131.8,
		"points": [
			[
				-79.42376,
				43.65406
			],
			[
				-79.42379,
				43.65421
			],
			[
				-79.42402,
				43.65428
			],
			[
				-79.42507,
				43.65399
			],
			[
				-79.42971,
				43.653
			],
			[
				-79.43091,
				43.65298
			],
			[
				-79.43996,
				43.65113
			],
			[
				-79.43989,
				43.65114
			],
			[
				-79.44015,
				43.65133
			],
			[
				-79.44121,
				43.65428
			],
			[
				-79.44251,
				43.6574
			],
			[
				-79.44274,
				43.65833
			],
			[
				-79.44376,
				43.65822
			],
			[
				-79.4468,
				43.65754
			],
			[
				-79.4525,
				43.65643
			],
			[
				-79.46004,
				43.6548
			],
			[
				-79.46238,
				43.65422
			],
			[
				-79.46532,
				43.65365
			],
			[
				-79.4732,
				43.65185
			],
			[
				-79.47909,
				43.65062
			],
			[
				-79.479,
				43.65062
			],
			[
				-79.47913,
				43.65068
			],
			[
				-79.48053,
				43.65416
			],
			[
				-79.48442,
				43.6533
			],
			[
				-79.48435,
				43.65332
			]
		],
		"dupe": false
	},
	{
		"id": 284292002,
		"name": "First rec ride of the year",
		"distance": 43401.4,
		"moving_time": 7012,
		"elapsed_time": 10258,
		"total_elevation_gain": 105.1,
		"start_date_local": "2015-04-12T14:13:00Z",
		"average_speed": 6.19,
		"max_speed": 16.4,
		"calories": 894.3,
		"points": [
			[
				-79.42355,
				43.65324
			],
			[
				-79.42348,
				43.65324
			],
			[
				-79.42419,
				43.65315
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.42349,
				43.64625
			],
			[
				-79.42377,
				43.64611
			],
			[
				-79.42812,
				43.64522
			],
			[
				-79.42806,
				43.64497
			],
			[
				-79.42934,
				43.64465
			],
			[
				-79.4295,
				43.6445
			],
			[
				-79.42951,
				43.64428
			],
			[
				-79.42933,
				43.64421
			],
			[
				-79.42859,
				43.64199
			],
			[
				-79.42863,
				43.64204
			],
			[
				-79.42867,
				43.64192
			],
			[
				-79.42874,
				43.64205
			],
			[
				-79.42877,
				43.64196
			],
			[
				-79.42858,
				43.64203
			],
			[
				-79.42861,
				43.6421
			],
			[
				-79.42773,
				43.63968
			],
			[
				-79.42487,
				43.63257
			],
			[
				-79.42491,
				43.6321
			],
			[
				-79.42508,
				43.63195
			],
			[
				-79.42614,
				43.6319
			],
			[
				-79.42915,
				43.63145
			],
			[
				-79.42928,
				43.63107
			],
			[
				-79.42882,
				43.63097
			],
			[
				-79.42881,
				43.63083
			],
			[
				-79.42894,
				43.63073
			],
			[
				-79.42891,
				43.63022
			],
			[
				-79.42914,
				43.63006
			],
			[
				-79.43307,
				43.63081
			],
			[
				-79.43353,
				43.63093
			],
			[
				-79.4342,
				43.6313
			],
			[
				-79.43442,
				43.63156
			],
			[
				-79.43484,
				43.63174
			],
			[
				-79.43507,
				43.63198
			],
			[
				-79.4353,
				43.63195
			],
			[
				-79.43572,
				43.63226
			],
			[
				-79.43626,
				43.63242
			],
			[
				-79.43664,
				43.63286
			],
			[
				-79.43724,
				43.63332
			],
			[
				-79.43741,
				43.63354
			],
			[
				-79.43775,
				43.63366
			],
			[
				-79.43786,
				43.63382
			],
			[
				-79.43859,
				43.63418
			],
			[
				-79.44145,
				43.63541
			],
			[
				-79.44198,
				43.63575
			],
			[
				-79.44319,
				43.63615
			],
			[
				-79.44522,
				43.63699
			],
			[
				-79.44638,
				43.63729
			],
			[
				-79.44662,
				43.63728
			],
			[
				-79.44701,
				43.63691
			],
			[
				-79.44756,
				43.63693
			],
			[
				-79.45169,
				43.63763
			],
			[
				-79.45247,
				43.6377
			],
			[
				-79.45319,
				43.63766
			],
			[
				-79.45345,
				43.63781
			],
			[
				-79.45411,
				43.63791
			],
			[
				-79.45566,
				43.63797
			],
			[
				-79.45721,
				43.63787
			],
			[
				-79.45945,
				43.63745
			],
			[
				-79.46045,
				43.63695
			],
			[
				-79.46128,
				43.63679
			],
			[
				-79.4616,
				43.6366
			],
			[
				-79.46233,
				43.63638
			],
			[
				-79.46302,
				43.63623
			],
			[
				-79.46384,
				43.63625
			],
			[
				-79.46446,
				43.63617
			],
			[
				-79.46605,
				43.63567
			],
			[
				-79.46621,
				43.6356
			],
			[
				-79.46641,
				43.63522
			],
			[
				-79.46663,
				43.63504
			],
			[
				-79.46767,
				43.63464
			],
			[
				-79.46884,
				43.63403
			],
			[
				-79.4692,
				43.63355
			],
			[
				-79.46931,
				43.63333
			],
			[
				-79.46929,
				43.63315
			],
			[
				-79.46954,
				43.63293
			],
			[
				-79.47079,
				43.63257
			],
			[
				-79.47095,
				43.63225
			],
			[
				-79.4717,
				43.6313
			],
			[
				-79.47253,
				43.63071
			],
			[
				-79.47284,
				43.63009
			],
			[
				-79.47322,
				43.63
			],
			[
				-79.4733,
				43.63023
			],
			[
				-79.47375,
				43.63043
			],
			[
				-79.47433,
				43.63024
			],
			[
				-79.47425,
				43.63012
			],
			[
				-79.47437,
				43.63002
			],
			[
				-79.47453,
				43.63
			],
			[
				-79.47547,
				43.62934
			],
			[
				-79.47571,
				43.62904
			],
			[
				-79.4764,
				43.6278
			],
			[
				-79.4776,
				43.62461
			],
			[
				-79.4785,
				43.62311
			],
			[
				-79.47898,
				43.62271
			],
			[
				-79.47939,
				43.62259
			],
			[
				-79.47984,
				43.62257
			],
			[
				-79.48024,
				43.62181
			],
			[
				-79.48043,
				43.62097
			],
			[
				-79.48076,
				43.6207
			],
			[
				-79.48137,
				43.62037
			],
			[
				-79.4815,
				43.61991
			],
			[
				-79.48121,
				43.61946
			],
			[
				-79.48104,
				43.61945
			],
			[
				-79.48096,
				43.6193
			],
			[
				-79.48136,
				43.61897
			],
			[
				-79.4818,
				43.61875
			],
			[
				-79.48253,
				43.61853
			],
			[
				-79.48316,
				43.61845
			],
			[
				-79.48364,
				43.61816
			],
			[
				-79.48437,
				43.61803
			],
			[
				-79.48509,
				43.6174
			],
			[
				-79.48548,
				43.6164
			],
			[
				-79.48545,
				43.61596
			],
			[
				-79.48552,
				43.61563
			],
			[
				-79.48611,
				43.61452
			],
			[
				-79.48611,
				43.61395
			],
			[
				-79.48639,
				43.61369
			],
			[
				-79.48711,
				43.61363
			],
			[
				-79.48726,
				43.61352
			],
			[
				-79.48749,
				43.61313
			],
			[
				-79.48745,
				43.61287
			],
			[
				-79.48698,
				43.61256
			],
			[
				-79.48691,
				43.61244
			],
			[
				-79.48716,
				43.61195
			],
			[
				-79.48693,
				43.61142
			],
			[
				-79.48693,
				43.61094
			],
			[
				-79.48713,
				43.6108
			],
			[
				-79.48736,
				43.61077
			],
			[
				-79.49006,
				43.61094
			],
			[
				-79.49024,
				43.61015
			],
			[
				-79.49053,
				43.60725
			],
			[
				-79.49075,
				43.60639
			],
			[
				-79.49154,
				43.60495
			],
			[
				-79.49222,
				43.60431
			],
			[
				-79.49306,
				43.60382
			],
			[
				-79.49299,
				43.60384
			],
			[
				-79.49315,
				43.60386
			],
			[
				-79.49334,
				43.60419
			],
			[
				-79.49456,
				43.60719
			],
			[
				-79.49476,
				43.6075
			],
			[
				-79.49498,
				43.60818
			],
			[
				-79.49663,
				43.61202
			],
			[
				-79.49767,
				43.6147
			],
			[
				-79.49886,
				43.61738
			],
			[
				-79.49916,
				43.61826
			],
			[
				-79.49954,
				43.61899
			],
			[
				-79.4997,
				43.61954
			],
			[
				-79.50041,
				43.62119
			],
			[
				-79.50095,
				43.62292
			],
			[
				-79.50152,
				43.6239
			],
			[
				-79.50219,
				43.62554
			],
			[
				-79.50305,
				43.62728
			],
			[
				-79.50418,
				43.6303
			],
			[
				-79.50532,
				43.63286
			],
			[
				-79.50586,
				43.63439
			],
			[
				-79.50679,
				43.63654
			],
			[
				-79.50715,
				43.63726
			],
			[
				-79.50824,
				43.63862
			],
			[
				-79.50846,
				43.63901
			],
			[
				-79.50861,
				43.6395
			],
			[
				-79.5086,
				43.64053
			],
			[
				-79.50869,
				43.64102
			],
			[
				-79.51063,
				43.64589
			],
			[
				-79.51099,
				43.64659
			],
			[
				-79.5112,
				43.64735
			],
			[
				-79.51177,
				43.64842
			],
			[
				-79.51444,
				43.65438
			],
			[
				-79.515,
				43.65582
			],
			[
				-79.51643,
				43.65864
			],
			[
				-79.51713,
				43.66064
			],
			[
				-79.51749,
				43.66129
			],
			[
				-79.51841,
				43.6634
			],
			[
				-79.51859,
				43.66364
			],
			[
				-79.52119,
				43.66994
			],
			[
				-79.52271,
				43.67332
			],
			[
				-79.52314,
				43.67409
			],
			[
				-79.52358,
				43.67547
			],
			[
				-79.52427,
				43.67719
			],
			[
				-79.52543,
				43.67963
			],
			[
				-79.52641,
				43.68213
			],
			[
				-79.5263,
				43.68227
			],
			[
				-79.52527,
				43.68252
			],
			[
				-79.52195,
				43.68313
			],
			[
				-79.52004,
				43.68331
			],
			[
				-79.5172,
				43.68333
			],
			[
				-79.51367,
				43.6835
			],
			[
				-79.507,
				43.68456
			],
			[
				-79.50536,
				43.6845
			],
			[
				-79.50266,
				43.68394
			],
			[
				-79.50085,
				43.68395
			],
			[
				-79.49794,
				43.68457
			],
			[
				-79.49351,
				43.68594
			],
			[
				-79.48911,
				43.68701
			],
			[
				-79.48607,
				43.68756
			],
			[
				-79.48302,
				43.68823
			],
			[
				-79.48276,
				43.68794
			],
			[
				-79.48195,
				43.68613
			],
			[
				-79.48022,
				43.68321
			],
			[
				-79.4792,
				43.68298
			],
			[
				-79.47841,
				43.6827
			],
			[
				-79.47781,
				43.68231
			],
			[
				-79.47641,
				43.68119
			],
			[
				-79.47432,
				43.67902
			],
			[
				-79.47371,
				43.67861
			],
			[
				-79.47076,
				43.67625
			],
			[
				-79.47027,
				43.6757
			],
			[
				-79.46961,
				43.67433
			],
			[
				-79.46938,
				43.67408
			],
			[
				-79.46951,
				43.67414
			],
			[
				-79.46976,
				43.67398
			],
			[
				-79.46967,
				43.67389
			],
			[
				-79.46965,
				43.67401
			],
			[
				-79.46936,
				43.6741
			],
			[
				-79.4692,
				43.67379
			],
			[
				-79.46813,
				43.67324
			],
			[
				-79.46789,
				43.67303
			],
			[
				-79.46794,
				43.67302
			],
			[
				-79.46784,
				43.67293
			],
			[
				-79.46783,
				43.67273
			],
			[
				-79.46762,
				43.67273
			],
			[
				-79.4676,
				43.6726
			],
			[
				-79.46735,
				43.67251
			],
			[
				-79.46667,
				43.67261
			],
			[
				-79.46469,
				43.67313
			],
			[
				-79.46308,
				43.67342
			],
			[
				-79.46238,
				43.67108
			],
			[
				-79.45838,
				43.67003
			],
			[
				-79.45709,
				43.67019
			],
			[
				-79.45704,
				43.67012
			],
			[
				-79.45596,
				43.6703
			],
			[
				-79.45521,
				43.67031
			],
			[
				-79.45377,
				43.67061
			],
			[
				-79.45348,
				43.6706
			],
			[
				-79.44558,
				43.67193
			],
			[
				-79.44475,
				43.67217
			],
			[
				-79.44272,
				43.67252
			],
			[
				-79.44005,
				43.67312
			],
			[
				-79.43817,
				43.67372
			],
			[
				-79.43742,
				43.67388
			],
			[
				-79.43502,
				43.67424
			],
			[
				-79.43423,
				43.67425
			],
			[
				-79.4329,
				43.67451
			],
			[
				-79.43221,
				43.67458
			],
			[
				-79.43185,
				43.67453
			],
			[
				-79.43099,
				43.67469
			],
			[
				-79.4304,
				43.67465
			],
			[
				-79.42894,
				43.67475
			],
			[
				-79.42877,
				43.67465
			],
			[
				-79.42796,
				43.67468
			],
			[
				-79.42599,
				43.67489
			],
			[
				-79.42313,
				43.6755
			],
			[
				-79.4232,
				43.67549
			],
			[
				-79.42301,
				43.67536
			],
			[
				-79.42257,
				43.67439
			],
			[
				-79.41963,
				43.6666
			],
			[
				-79.42151,
				43.6661
			],
			[
				-79.42201,
				43.66589
			],
			[
				-79.42445,
				43.66534
			],
			[
				-79.42192,
				43.65911
			],
			[
				-79.4221,
				43.65898
			],
			[
				-79.42429,
				43.65853
			],
			[
				-79.42428,
				43.65816
			],
			[
				-79.42344,
				43.65606
			],
			[
				-79.4231,
				43.65541
			],
			[
				-79.42279,
				43.65426
			],
			[
				-79.42244,
				43.65352
			],
			[
				-79.42601,
				43.65273
			],
			[
				-79.42602,
				43.65265
			],
			[
				-79.42351,
				43.64631
			],
			[
				-79.42372,
				43.64612
			],
			[
				-79.42814,
				43.64522
			],
			[
				-79.42807,
				43.645
			],
			[
				-79.42829,
				43.64486
			],
			[
				-79.42954,
				43.64461
			],
			[
				-79.42952,
				43.64438
			],
			[
				-79.42924,
				43.64395
			],
			[
				-79.42908,
				43.64331
			],
			[
				-79.42883,
				43.64274
			],
			[
				-79.42874,
				43.64219
			],
			[
				-79.42883,
				43.64225
			],
			[
				-79.42965,
				43.64205
			],
			[
				-79.42993,
				43.64214
			],
			[
				-79.42983,
				43.64225
			],
			[
				-79.42991,
				43.64246
			],
			[
				-79.43259,
				43.64226
			],
			[
				-79.43268,
				43.6421
			],
			[
				-79.43243,
				43.64145
			],
			[
				-79.43154,
				43.64156
			],
			[
				-79.43041,
				43.64187
			],
			[
				-79.4295,
				43.64197
			],
			[
				-79.42918,
				43.64212
			],
			[
				-79.42872,
				43.64211
			],
			[
				-79.42801,
				43.64225
			],
			[
				-79.42638,
				43.64267
			],
			[
				-79.42478,
				43.6429
			],
			[
				-79.42171,
				43.64363
			],
			[
				-79.41957,
				43.64404
			],
			[
				-79.41896,
				43.64407
			],
			[
				-79.41525,
				43.64493
			],
			[
				-79.41339,
				43.64526
			],
			[
				-79.41318,
				43.6452
			],
			[
				-79.41283,
				43.64431
			],
			[
				-79.41247,
				43.64435
			],
			[
				-79.4101,
				43.64478
			],
			[
				-79.40953,
				43.64504
			],
			[
				-79.40924,
				43.64536
			],
			[
				-79.40384,
				43.64644
			],
			[
				-79.40332,
				43.64533
			],
			[
				-79.40292,
				43.64525
			],
			[
				-79.39163,
				43.64755
			],
			[
				-79.39144,
				43.64722
			],
			[
				-79.39148,
				43.64731
			],
			[
				-79.39106,
				43.64749
			],
			[
				-79.39093,
				43.64769
			],
			[
				-79.38553,
				43.64886
			],
			[
				-79.38503,
				43.64784
			],
			[
				-79.3853,
				43.64756
			],
			[
				-79.38489,
				43.64765
			],
			[
				-79.38481,
				43.64755
			],
			[
				-79.38399,
				43.6461
			],
			[
				-79.38396,
				43.64574
			],
			[
				-79.38372,
				43.64555
			],
			[
				-79.38364,
				43.64533
			],
			[
				-79.38332,
				43.64528
			],
			[
				-79.38347,
				43.64516
			],
			[
				-79.38275,
				43.64521
			],
			[
				-79.38237,
				43.64514
			],
			[
				-79.38237,
				43.64508
			],
			[
				-79.38242,
				43.64529
			],
			[
				-79.37792,
				43.64622
			],
			[
				-79.37744,
				43.64647
			],
			[
				-79.37695,
				43.64688
			],
			[
				-79.37636,
				43.64717
			],
			[
				-79.3738,
				43.64838
			],
			[
				-79.37116,
				43.64949
			],
			[
				-79.36798,
				43.65029
			],
			[
				-79.36781,
				43.65025
			],
			[
				-79.36643,
				43.64709
			],
			[
				-79.36648,
				43.64687
			],
			[
				-79.36664,
				43.64678
			],
			[
				-79.36656,
				43.64674
			],
			[
				-79.36661,
				43.64661
			],
			[
				-79.36638,
				43.64646
			],
			[
				-79.36634,
				43.64633
			],
			[
				-79.36621,
				43.64627
			],
			[
				-79.36613,
				43.64635
			],
			[
				-79.36545,
				43.64528
			],
			[
				-79.36561,
				43.6451
			],
			[
				-79.36532,
				43.6446
			],
			[
				-79.36779,
				43.64364
			],
			[
				-79.36766,
				43.64345
			],
			[
				-79.36772,
				43.64335
			],
			[
				-79.36737,
				43.64315
			],
			[
				-79.36724,
				43.64286
			]
		],
		"dupe": false
	},
	{
		"id": 283663131,
		"name": "Night Ride",
		"distance": 5715.7,
		"moving_time": 849,
		"elapsed_time": 849,
		"total_elevation_gain": 15,
		"start_date_local": "2015-04-12T01:56:24Z",
		"average_speed": 6.732,
		"max_speed": 14.1,
		"calories": 113.4,
		"points": [
			[
				-79.48356,
				43.6535
			],
			[
				-79.47778,
				43.65473
			],
			[
				-79.47628,
				43.65113
			],
			[
				-79.47612,
				43.65108
			],
			[
				-79.46802,
				43.65293
			],
			[
				-79.45984,
				43.65465
			],
			[
				-79.45341,
				43.65612
			],
			[
				-79.45255,
				43.65625
			],
			[
				-79.45261,
				43.65625
			],
			[
				-79.45245,
				43.65616
			],
			[
				-79.45234,
				43.65553
			],
			[
				-79.45202,
				43.65473
			],
			[
				-79.45183,
				43.65383
			],
			[
				-79.45132,
				43.65355
			],
			[
				-79.44847,
				43.65218
			],
			[
				-79.44692,
				43.65156
			],
			[
				-79.44572,
				43.65128
			],
			[
				-79.4453,
				43.65109
			],
			[
				-79.44115,
				43.65027
			],
			[
				-79.44065,
				43.65044
			],
			[
				-79.43968,
				43.65105
			],
			[
				-79.43893,
				43.65128
			],
			[
				-79.43318,
				43.65242
			],
			[
				-79.43256,
				43.65246
			],
			[
				-79.43075,
				43.65288
			],
			[
				-79.43004,
				43.65281
			],
			[
				-79.42651,
				43.65355
			],
			[
				-79.42631,
				43.65337
			],
			[
				-79.42606,
				43.65275
			],
			[
				-79.424,
				43.65316
			]
		],
		"dupe": false
	},
	{
		"id": 284349367,
		"name": "Evening Ride",
		"distance": 7004.6,
		"moving_time": 1412,
		"elapsed_time": 1909,
		"total_elevation_gain": 16,
		"start_date_local": "2015-04-12T18:39:27Z",
		"average_speed": 4.961,
		"max_speed": 13.6,
		"calories": 137.5,
		"points": [
			[
				-79.36719,
				43.64302
			],
			[
				-79.36766,
				43.64372
			],
			[
				-79.36789,
				43.64387
			],
			[
				-79.36809,
				43.64385
			],
			[
				-79.36828,
				43.644
			],
			[
				-79.36852,
				43.64399
			],
			[
				-79.36912,
				43.64375
			],
			[
				-79.36946,
				43.64375
			],
			[
				-79.37001,
				43.64357
			],
			[
				-79.37586,
				43.64147
			],
			[
				-79.37572,
				43.64151
			],
			[
				-79.37571,
				43.64165
			],
			[
				-79.37594,
				43.64166
			],
			[
				-79.37614,
				43.64153
			],
			[
				-79.37675,
				43.64136
			],
			[
				-79.3769,
				43.64118
			],
			[
				-79.38009,
				43.64007
			],
			[
				-79.38266,
				43.63962
			],
			[
				-79.38295,
				43.63989
			],
			[
				-79.38379,
				43.63971
			],
			[
				-79.3843,
				43.63931
			],
			[
				-79.38655,
				43.63896
			],
			[
				-79.39291,
				43.63754
			],
			[
				-79.39541,
				43.63718
			],
			[
				-79.39736,
				43.63654
			],
			[
				-79.39837,
				43.63579
			],
			[
				-79.3986,
				43.63554
			],
			[
				-79.40057,
				43.63447
			],
			[
				-79.40106,
				43.63443
			],
			[
				-79.40122,
				43.63429
			],
			[
				-79.4017,
				43.63435
			],
			[
				-79.40242,
				43.63513
			],
			[
				-79.40292,
				43.63526
			],
			[
				-79.40368,
				43.63524
			],
			[
				-79.40411,
				43.63432
			],
			[
				-79.40466,
				43.63406
			],
			[
				-79.40545,
				43.63395
			],
			[
				-79.40616,
				43.63398
			],
			[
				-79.40717,
				43.63426
			],
			[
				-79.40755,
				43.63444
			],
			[
				-79.40794,
				43.63448
			],
			[
				-79.40834,
				43.63435
			],
			[
				-79.40854,
				43.63437
			],
			[
				-79.409,
				43.63453
			],
			[
				-79.4091,
				43.63492
			],
			[
				-79.40971,
				43.63471
			],
			[
				-79.41025,
				43.6356
			],
			[
				-79.41027,
				43.63582
			],
			[
				-79.41019,
				43.636
			],
			[
				-79.41168,
				43.63645
			],
			[
				-79.41228,
				43.63683
			],
			[
				-79.41274,
				43.63691
			],
			[
				-79.42049,
				43.63487
			],
			[
				-79.42164,
				43.63394
			],
			[
				-79.42221,
				43.63283
			],
			[
				-79.42263,
				43.63274
			],
			[
				-79.42328,
				43.63296
			],
			[
				-79.42425,
				43.63274
			],
			[
				-79.4248,
				43.63274
			],
			[
				-79.42498,
				43.63283
			],
			[
				-79.42737,
				43.6389
			],
			[
				-79.42728,
				43.639
			],
			[
				-79.42846,
				43.64207
			],
			[
				-79.42682,
				43.64252
			],
			[
				-79.42481,
				43.6429
			]
		],
		"dupe": false
	},
	{
		"id": 287121302,
		"name": "Morning Ride",
		"distance": 7329.8,
		"moving_time": 1530,
		"elapsed_time": 1689,
		"total_elevation_gain": 68.3,
		"start_date_local": "2015-04-17T09:46:41Z",
		"average_speed": 4.791,
		"max_speed": 10,
		"calories": 177.5,
		"points": [
			[
				-79.4234,
				43.65385
			],
			[
				-79.42338,
				43.65374
			],
			[
				-79.42301,
				43.65379
			],
			[
				-79.42265,
				43.65387
			],
			[
				-79.42251,
				43.65405
			],
			[
				-79.42186,
				43.65424
			],
			[
				-79.42141,
				43.65425
			],
			[
				-79.42135,
				43.65463
			],
			[
				-79.42078,
				43.65471
			],
			[
				-79.42024,
				43.65488
			],
			[
				-79.42024,
				43.65495
			],
			[
				-79.42048,
				43.6558
			],
			[
				-79.4206,
				43.65591
			],
			[
				-79.42087,
				43.65664
			],
			[
				-79.42092,
				43.6566
			],
			[
				-79.42096,
				43.65689
			],
			[
				-79.42176,
				43.65893
			],
			[
				-79.42183,
				43.65896
			],
			[
				-79.42316,
				43.66249
			],
			[
				-79.42343,
				43.6629
			],
			[
				-79.42343,
				43.66311
			],
			[
				-79.42388,
				43.66399
			],
			[
				-79.42396,
				43.66434
			],
			[
				-79.42422,
				43.66484
			],
			[
				-79.4248,
				43.66638
			],
			[
				-79.42507,
				43.66698
			],
			[
				-79.42521,
				43.66713
			],
			[
				-79.4255,
				43.66808
			],
			[
				-79.42567,
				43.66826
			],
			[
				-79.42583,
				43.66893
			],
			[
				-79.42656,
				43.67053
			],
			[
				-79.42665,
				43.67096
			],
			[
				-79.4268,
				43.671
			],
			[
				-79.42833,
				43.67473
			],
			[
				-79.428,
				43.67481
			],
			[
				-79.42759,
				43.67476
			],
			[
				-79.42696,
				43.67481
			],
			[
				-79.42538,
				43.67504
			],
			[
				-79.42318,
				43.67555
			],
			[
				-79.42308,
				43.67562
			],
			[
				-79.42337,
				43.67611
			],
			[
				-79.42373,
				43.67737
			],
			[
				-79.42421,
				43.67814
			],
			[
				-79.42395,
				43.67828
			],
			[
				-79.42212,
				43.67867
			],
			[
				-79.42222,
				43.67901
			],
			[
				-79.42215,
				43.67905
			],
			[
				-79.41712,
				43.68009
			],
			[
				-79.41714,
				43.68034
			],
			[
				-79.41736,
				43.68101
			],
			[
				-79.41764,
				43.68153
			],
			[
				-79.41798,
				43.68154
			],
			[
				-79.41897,
				43.68219
			],
			[
				-79.42029,
				43.68281
			],
			[
				-79.42099,
				43.6834
			],
			[
				-79.42225,
				43.68642
			],
			[
				-79.4228,
				43.68715
			],
			[
				-79.42342,
				43.68748
			],
			[
				-79.42606,
				43.68858
			],
			[
				-79.42649,
				43.68872
			],
			[
				-79.42701,
				43.68876
			],
			[
				-79.42691,
				43.69003
			],
			[
				-79.42848,
				43.69014
			],
			[
				-79.42871,
				43.69012
			],
			[
				-79.42875,
				43.69005
			],
			[
				-79.43002,
				43.69036
			],
			[
				-79.43035,
				43.69065
			],
			[
				-79.43054,
				43.69103
			],
			[
				-79.43043,
				43.69144
			],
			[
				-79.42991,
				43.692
			],
			[
				-79.42989,
				43.69221
			],
			[
				-79.43036,
				43.69239
			],
			[
				-79.43083,
				43.69269
			],
			[
				-79.4315,
				43.6929
			],
			[
				-79.43197,
				43.69319
			],
			[
				-79.43285,
				43.69385
			],
			[
				-79.43296,
				43.69403
			],
			[
				-79.43301,
				43.69441
			],
			[
				-79.43323,
				43.69465
			],
			[
				-79.43465,
				43.69541
			],
			[
				-79.43592,
				43.69868
			],
			[
				-79.43516,
				43.69891
			],
			[
				-79.4329,
				43.69936
			],
			[
				-79.43295,
				43.69945
			],
			[
				-79.43307,
				43.69934
			],
			[
				-79.43309,
				43.69941
			],
			[
				-79.43304,
				43.69935
			],
			[
				-79.433,
				43.6994
			],
			[
				-79.43303,
				43.69935
			]
		],
		"dupe": false
	},
	{
		"id": 287217086,
		"name": "Afternoon Ride",
		"distance": 3378.4,
		"moving_time": 612,
		"elapsed_time": 658,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-17T13:08:41Z",
		"average_speed": 5.52,
		"max_speed": 10.4,
		"calories": 60.3,
		"points": [
			[
				-79.4001,
				43.6529
			],
			[
				-79.40015,
				43.65294
			],
			[
				-79.40005,
				43.65291
			],
			[
				-79.39991,
				43.65263
			],
			[
				-79.4,
				43.6525
			],
			[
				-79.40177,
				43.6522
			],
			[
				-79.40377,
				43.65174
			],
			[
				-79.40511,
				43.65228
			],
			[
				-79.40536,
				43.65247
			],
			[
				-79.40577,
				43.65248
			],
			[
				-79.40597,
				43.65238
			],
			[
				-79.40673,
				43.65225
			],
			[
				-79.40749,
				43.65197
			],
			[
				-79.40838,
				43.65189
			],
			[
				-79.40867,
				43.65176
			],
			[
				-79.40938,
				43.65173
			],
			[
				-79.41004,
				43.65153
			],
			[
				-79.41087,
				43.65144
			],
			[
				-79.41285,
				43.65092
			],
			[
				-79.413,
				43.65095
			],
			[
				-79.41287,
				43.65098
			],
			[
				-79.41294,
				43.65087
			],
			[
				-79.4135,
				43.65092
			],
			[
				-79.41495,
				43.65053
			],
			[
				-79.41605,
				43.65034
			],
			[
				-79.41654,
				43.65014
			],
			[
				-79.41712,
				43.65012
			],
			[
				-79.41855,
				43.64987
			],
			[
				-79.42061,
				43.6494
			],
			[
				-79.42326,
				43.64946
			],
			[
				-79.42315,
				43.64942
			],
			[
				-79.42499,
				43.64954
			],
			[
				-79.42625,
				43.64949
			],
			[
				-79.42974,
				43.64967
			],
			[
				-79.42984,
				43.64959
			],
			[
				-79.42804,
				43.64491
			],
			[
				-79.42953,
				43.6446
			],
			[
				-79.42952,
				43.6443
			]
		],
		"dupe": false
	},
	{
		"id": 287151826,
		"name": "Lunch Ride",
		"distance": 7923.8,
		"moving_time": 1287,
		"elapsed_time": 1350,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-17T11:00:58Z",
		"average_speed": 6.157,
		"max_speed": 12.1,
		"calories": 127.5,
		"points": [
			[
				-79.432,
				43.69962
			],
			[
				-79.43195,
				43.6995
			],
			[
				-79.43158,
				43.69966
			],
			[
				-79.42881,
				43.70022
			],
			[
				-79.42614,
				43.70087
			],
			[
				-79.42343,
				43.70133
			],
			[
				-79.4232,
				43.70145
			],
			[
				-79.41912,
				43.70229
			],
			[
				-79.41904,
				43.70229
			],
			[
				-79.41801,
				43.70128
			],
			[
				-79.41269,
				43.68863
			],
			[
				-79.41248,
				43.68779
			],
			[
				-79.41118,
				43.68469
			],
			[
				-79.41788,
				43.6833
			],
			[
				-79.41816,
				43.68311
			],
			[
				-79.41812,
				43.683
			],
			[
				-79.41825,
				43.683
			],
			[
				-79.4182,
				43.68259
			],
			[
				-79.41743,
				43.68081
			],
			[
				-79.41698,
				43.67943
			],
			[
				-79.41636,
				43.67803
			],
			[
				-79.41592,
				43.67679
			],
			[
				-79.41539,
				43.67564
			],
			[
				-79.41463,
				43.67351
			],
			[
				-79.41417,
				43.67255
			],
			[
				-79.41308,
				43.66967
			],
			[
				-79.41269,
				43.66886
			],
			[
				-79.41151,
				43.6657
			],
			[
				-79.41145,
				43.66528
			],
			[
				-79.41123,
				43.66518
			],
			[
				-79.41088,
				43.66403
			],
			[
				-79.41033,
				43.66285
			],
			[
				-79.41006,
				43.66203
			],
			[
				-79.40692,
				43.65431
			],
			[
				-79.40236,
				43.65522
			],
			[
				-79.40197,
				43.6545
			],
			[
				-79.40096,
				43.65472
			],
			[
				-79.40071,
				43.65471
			],
			[
				-79.40011,
				43.6531
			],
			[
				-79.4002,
				43.65335
			]
		],
		"dupe": false
	},
	{
		"id": 288041570,
		"name": "Afternoon Ride",
		"distance": 39938.1,
		"moving_time": 6881,
		"elapsed_time": 9166,
		"total_elevation_gain": 130.7,
		"start_date_local": "2015-04-18T15:44:35Z",
		"average_speed": 5.804,
		"max_speed": 19.9,
		"calories": 806.3,
		"points": [
			[
				-79.42043,
				43.64832
			],
			[
				-79.42041,
				43.64808
			],
			[
				-79.41975,
				43.64662
			],
			[
				-79.41937,
				43.64533
			],
			[
				-79.41892,
				43.64422
			],
			[
				-79.41856,
				43.64415
			],
			[
				-79.41651,
				43.64459
			],
			[
				-79.41622,
				43.64447
			],
			[
				-79.41477,
				43.64067
			],
			[
				-79.41262,
				43.64055
			],
			[
				-79.41144,
				43.64069
			],
			[
				-79.40913,
				43.63498
			],
			[
				-79.40869,
				43.63446
			],
			[
				-79.40849,
				43.63435
			],
			[
				-79.40782,
				43.63443
			],
			[
				-79.40592,
				43.63397
			],
			[
				-79.40474,
				43.63396
			],
			[
				-79.40435,
				43.63409
			],
			[
				-79.40409,
				43.6343
			],
			[
				-79.40351,
				43.6354
			],
			[
				-79.40284,
				43.63532
			],
			[
				-79.40272,
				43.63511
			],
			[
				-79.40245,
				43.63497
			],
			[
				-79.40173,
				43.63433
			],
			[
				-79.40136,
				43.63421
			],
			[
				-79.40056,
				43.63447
			],
			[
				-79.39804,
				43.63581
			],
			[
				-79.39713,
				43.63643
			],
			[
				-79.39549,
				43.63698
			],
			[
				-79.3938,
				43.63729
			],
			[
				-79.39276,
				43.63705
			],
			[
				-79.3921,
				43.63711
			],
			[
				-79.39191,
				43.637
			],
			[
				-79.39184,
				43.6371
			],
			[
				-79.39196,
				43.63745
			],
			[
				-79.39192,
				43.6376
			],
			[
				-79.39176,
				43.63764
			],
			[
				-79.39208,
				43.63839
			],
			[
				-79.39184,
				43.63861
			],
			[
				-79.391,
				43.63888
			],
			[
				-79.38707,
				43.63953
			],
			[
				-79.38689,
				43.63967
			],
			[
				-79.3846,
				43.63993
			],
			[
				-79.38365,
				43.64017
			],
			[
				-79.3823,
				43.6407
			],
			[
				-79.38176,
				43.6408
			],
			[
				-79.38169,
				43.64079
			],
			[
				-79.38155,
				43.64046
			],
			[
				-79.38135,
				43.64057
			],
			[
				-79.38074,
				43.64068
			],
			[
				-79.38061,
				43.64066
			],
			[
				-79.38079,
				43.64068
			],
			[
				-79.37986,
				43.64056
			],
			[
				-79.37953,
				43.64041
			],
			[
				-79.3796,
				43.64045
			],
			[
				-79.37933,
				43.64047
			],
			[
				-79.37898,
				43.64067
			],
			[
				-79.37848,
				43.64068
			],
			[
				-79.37777,
				43.64106
			],
			[
				-79.37757,
				43.6412
			],
			[
				-79.37759,
				43.64128
			],
			[
				-79.3776,
				43.64113
			],
			[
				-79.37738,
				43.64116
			],
			[
				-79.37704,
				43.64106
			],
			[
				-79.37653,
				43.64115
			],
			[
				-79.3761,
				43.64139
			],
			[
				-79.37509,
				43.64176
			],
			[
				-79.36215,
				43.64632
			],
			[
				-79.36177,
				43.64657
			],
			[
				-79.36122,
				43.64774
			],
			[
				-79.36086,
				43.64793
			],
			[
				-79.35821,
				43.64881
			],
			[
				-79.35715,
				43.64904
			],
			[
				-79.3564,
				43.64904
			],
			[
				-79.35584,
				43.64875
			],
			[
				-79.35468,
				43.64792
			],
			[
				-79.35481,
				43.648
			],
			[
				-79.3546,
				43.64819
			],
			[
				-79.35447,
				43.64819
			],
			[
				-79.35501,
				43.6486
			],
			[
				-79.35499,
				43.64867
			],
			[
				-79.35446,
				43.64852
			],
			[
				-79.35409,
				43.64863
			],
			[
				-79.35401,
				43.64874
			],
			[
				-79.35401,
				43.64912
			],
			[
				-79.3538,
				43.6494
			],
			[
				-79.35392,
				43.6498
			],
			[
				-79.35416,
				43.65005
			],
			[
				-79.35419,
				43.65021
			],
			[
				-79.35405,
				43.65019
			],
			[
				-79.3539,
				43.65032
			],
			[
				-79.35186,
				43.65096
			],
			[
				-79.35084,
				43.65105
			],
			[
				-79.35069,
				43.65094
			],
			[
				-79.35019,
				43.65081
			],
			[
				-79.35004,
				43.65084
			],
			[
				-79.35,
				43.65095
			],
			[
				-79.34971,
				43.65091
			],
			[
				-79.3492,
				43.6511
			],
			[
				-79.34869,
				43.65141
			],
			[
				-79.34789,
				43.65137
			],
			[
				-79.34757,
				43.65146
			],
			[
				-79.3474,
				43.6514
			],
			[
				-79.34735,
				43.6512
			],
			[
				-79.34704,
				43.6513
			],
			[
				-79.34687,
				43.65126
			],
			[
				-79.34538,
				43.65199
			],
			[
				-79.34429,
				43.65233
			],
			[
				-79.34103,
				43.6538
			],
			[
				-79.3398,
				43.65417
			],
			[
				-79.33881,
				43.65465
			],
			[
				-79.33875,
				43.65478
			],
			[
				-79.33903,
				43.65536
			],
			[
				-79.33901,
				43.65552
			],
			[
				-79.33805,
				43.65579
			],
			[
				-79.33774,
				43.6556
			],
			[
				-79.33754,
				43.65527
			],
			[
				-79.32959,
				43.65899
			],
			[
				-79.32936,
				43.659
			],
			[
				-79.32864,
				43.65929
			],
			[
				-79.32725,
				43.65966
			],
			[
				-79.32643,
				43.65977
			],
			[
				-79.32535,
				43.66016
			],
			[
				-79.32402,
				43.66034
			],
			[
				-79.32302,
				43.66057
			],
			[
				-79.32278,
				43.66045
			],
			[
				-79.32141,
				43.66089
			],
			[
				-79.31582,
				43.66213
			],
			[
				-79.31528,
				43.66213
			],
			[
				-79.31494,
				43.66221
			],
			[
				-79.31496,
				43.66227
			],
			[
				-79.31453,
				43.66195
			],
			[
				-79.31409,
				43.66178
			],
			[
				-79.31296,
				43.66188
			],
			[
				-79.31271,
				43.6618
			],
			[
				-79.31246,
				43.66158
			],
			[
				-79.3122,
				43.66169
			],
			[
				-79.31185,
				43.66157
			],
			[
				-79.31153,
				43.6616
			],
			[
				-79.31007,
				43.66217
			],
			[
				-79.3095,
				43.6623
			],
			[
				-79.30868,
				43.66269
			],
			[
				-79.30779,
				43.66325
			],
			[
				-79.30726,
				43.66377
			],
			[
				-79.30722,
				43.66391
			],
			[
				-79.30596,
				43.66444
			],
			[
				-79.30487,
				43.66538
			],
			[
				-79.30422,
				43.66511
			],
			[
				-79.30062,
				43.66573
			],
			[
				-79.29869,
				43.66621
			],
			[
				-79.29845,
				43.66618
			],
			[
				-79.29716,
				43.66639
			],
			[
				-79.29611,
				43.66707
			],
			[
				-79.29502,
				43.66736
			],
			[
				-79.29495,
				43.6674
			],
			[
				-79.2949,
				43.66767
			],
			[
				-79.29419,
				43.66786
			],
			[
				-79.29355,
				43.66772
			],
			[
				-79.29277,
				43.66814
			],
			[
				-79.29235,
				43.66814
			],
			[
				-79.29095,
				43.66855
			],
			[
				-79.29058,
				43.66857
			],
			[
				-79.28965,
				43.66894
			],
			[
				-79.28862,
				43.66921
			],
			[
				-79.28834,
				43.66936
			],
			[
				-79.28827,
				43.66948
			],
			[
				-79.28699,
				43.6699
			],
			[
				-79.28626,
				43.67028
			],
			[
				-79.28603,
				43.6703
			],
			[
				-79.28616,
				43.67066
			],
			[
				-79.28657,
				43.67075
			],
			[
				-79.28526,
				43.67037
			],
			[
				-79.28493,
				43.67046
			],
			[
				-79.28417,
				43.67033
			],
			[
				-79.28412,
				43.67069
			],
			[
				-79.28466,
				43.67201
			],
			[
				-79.28477,
				43.6726
			],
			[
				-79.28505,
				43.67303
			],
			[
				-79.28494,
				43.67312
			],
			[
				-79.28116,
				43.67392
			],
			[
				-79.28117,
				43.67372
			],
			[
				-79.28056,
				43.67227
			],
			[
				-79.27813,
				43.67309
			],
			[
				-79.27801,
				43.67312
			],
			[
				-79.27774,
				43.67302
			],
			[
				-79.2777,
				43.67295
			],
			[
				-79.27784,
				43.67282
			],
			[
				-79.27986,
				43.67203
			],
			[
				-79.27993,
				43.67183
			],
			[
				-79.27972,
				43.67174
			],
			[
				-79.27858,
				43.67215
			],
			[
				-79.27786,
				43.67291
			],
			[
				-79.27783,
				43.67303
			],
			[
				-79.27811,
				43.67312
			],
			[
				-79.2798,
				43.67244
			],
			[
				-79.27986,
				43.67265
			],
			[
				-79.2775,
				43.67349
			],
			[
				-79.27729,
				43.67366
			],
			[
				-79.27797,
				43.67467
			],
			[
				-79.27809,
				43.67472
			],
			[
				-79.27929,
				43.67442
			],
			[
				-79.27917,
				43.67411
			],
			[
				-79.27894,
				43.67384
			],
			[
				-79.27897,
				43.67375
			],
			[
				-79.27921,
				43.67363
			],
			[
				-79.27935,
				43.67362
			],
			[
				-79.27947,
				43.67393
			],
			[
				-79.27977,
				43.67423
			],
			[
				-79.28102,
				43.67394
			],
			[
				-79.28114,
				43.67367
			],
			[
				-79.28063,
				43.67244
			],
			[
				-79.28001,
				43.67248
			],
			[
				-79.27818,
				43.6731
			],
			[
				-79.278,
				43.67308
			],
			[
				-79.27786,
				43.67296
			],
			[
				-79.27783,
				43.67282
			],
			[
				-79.27792,
				43.67271
			],
			[
				-79.27887,
				43.67244
			],
			[
				-79.27971,
				43.67207
			],
			[
				-79.27993,
				43.67206
			],
			[
				-79.27999,
				43.67181
			],
			[
				-79.27967,
				43.67173
			],
			[
				-79.27853,
				43.67211
			],
			[
				-79.27777,
				43.673
			],
			[
				-79.2778,
				43.67307
			],
			[
				-79.27808,
				43.67312
			],
			[
				-79.27948,
				43.67258
			],
			[
				-79.2799,
				43.67248
			],
			[
				-79.28015,
				43.67251
			],
			[
				-79.28037,
				43.67265
			],
			[
				-79.28092,
				43.67377
			],
			[
				-79.2812,
				43.67393
			],
			[
				-79.28121,
				43.67401
			],
			[
				-79.28104,
				43.67408
			],
			[
				-79.28017,
				43.67433
			],
			[
				-79.28013,
				43.67448
			],
			[
				-79.28024,
				43.67467
			],
			[
				-79.28033,
				43.6752
			],
			[
				-79.28052,
				43.67547
			],
			[
				-79.28097,
				43.6765
			],
			[
				-79.28108,
				43.67695
			],
			[
				-79.28164,
				43.67828
			],
			[
				-79.28191,
				43.67845
			],
			[
				-79.28231,
				43.67848
			],
			[
				-79.28309,
				43.6787
			],
			[
				-79.28318,
				43.67883
			],
			[
				-79.28322,
				43.67913
			],
			[
				-79.28372,
				43.68018
			],
			[
				-79.28582,
				43.68542
			],
			[
				-79.28675,
				43.68755
			],
			[
				-79.28682,
				43.68778
			],
			[
				-79.28666,
				43.68772
			],
			[
				-79.28673,
				43.68789
			],
			[
				-79.28689,
				43.68802
			],
			[
				-79.28708,
				43.68837
			],
			[
				-79.28795,
				43.69053
			],
			[
				-79.28807,
				43.69065
			],
			[
				-79.28828,
				43.69135
			],
			[
				-79.28923,
				43.69369
			],
			[
				-79.28908,
				43.69378
			],
			[
				-79.28988,
				43.69506
			],
			[
				-79.29001,
				43.69567
			],
			[
				-79.29023,
				43.6962
			],
			[
				-79.29076,
				43.69743
			],
			[
				-79.29121,
				43.69825
			],
			[
				-79.29135,
				43.69815
			],
			[
				-79.29125,
				43.69791
			],
			[
				-79.29154,
				43.69789
			],
			[
				-79.29236,
				43.69799
			],
			[
				-79.29294,
				43.69821
			],
			[
				-79.29335,
				43.69825
			],
			[
				-79.29435,
				43.69816
			],
			[
				-79.29514,
				43.69789
			],
			[
				-79.29573,
				43.69786
			],
			[
				-79.29634,
				43.69769
			],
			[
				-79.29745,
				43.6977
			],
			[
				-79.29767,
				43.6975
			],
			[
				-79.29769,
				43.6974
			],
			[
				-79.29759,
				43.69726
			],
			[
				-79.29808,
				43.69702
			],
			[
				-79.29875,
				43.69717
			],
			[
				-79.29953,
				43.69709
			],
			[
				-79.30113,
				43.69731
			],
			[
				-79.30181,
				43.69766
			],
			[
				-79.30296,
				43.69782
			],
			[
				-79.30358,
				43.69804
			],
			[
				-79.30461,
				43.69862
			],
			[
				-79.30498,
				43.69872
			],
			[
				-79.30517,
				43.6989
			],
			[
				-79.3058,
				43.69913
			],
			[
				-79.30696,
				43.69911
			],
			[
				-79.30774,
				43.69933
			],
			[
				-79.30963,
				43.69939
			],
			[
				-79.31043,
				43.69954
			],
			[
				-79.31079,
				43.69973
			],
			[
				-79.31096,
				43.69991
			],
			[
				-79.31142,
				43.70012
			],
			[
				-79.31206,
				43.7006
			],
			[
				-79.31349,
				43.70125
			],
			[
				-79.3146,
				43.70136
			],
			[
				-79.31537,
				43.70125
			],
			[
				-79.31602,
				43.70128
			],
			[
				-79.31683,
				43.70148
			],
			[
				-79.31702,
				43.70146
			],
			[
				-79.3175,
				43.70168
			],
			[
				-79.31831,
				43.7019
			],
			[
				-79.31935,
				43.7017
			],
			[
				-79.32005,
				43.70192
			],
			[
				-79.32025,
				43.70187
			],
			[
				-79.32088,
				43.70232
			],
			[
				-79.32095,
				43.70254
			],
			[
				-79.3214,
				43.7027
			],
			[
				-79.32218,
				43.70274
			],
			[
				-79.32304,
				43.70211
			],
			[
				-79.32377,
				43.70186
			],
			[
				-79.32384,
				43.70197
			],
			[
				-79.32411,
				43.70197
			],
			[
				-79.32473,
				43.70175
			],
			[
				-79.32503,
				43.70174
			],
			[
				-79.32556,
				43.70195
			],
			[
				-79.32641,
				43.70204
			],
			[
				-79.32704,
				43.70189
			],
			[
				-79.32732,
				43.70198
			],
			[
				-79.32775,
				43.70198
			],
			[
				-79.32862,
				43.70191
			],
			[
				-79.32874,
				43.70196
			],
			[
				-79.32895,
				43.70187
			],
			[
				-79.33072,
				43.70207
			],
			[
				-79.331,
				43.70153
			],
			[
				-79.33119,
				43.70148
			],
			[
				-79.33124,
				43.70155
			],
			[
				-79.33159,
				43.70157
			],
			[
				-79.3318,
				43.70133
			],
			[
				-79.3317,
				43.70127
			],
			[
				-79.33166,
				43.70131
			],
			[
				-79.33199,
				43.70178
			],
			[
				-79.33201,
				43.70195
			],
			[
				-79.33194,
				43.70199
			],
			[
				-79.33207,
				43.70252
			],
			[
				-79.332,
				43.70281
			],
			[
				-79.33236,
				43.70319
			],
			[
				-79.3327,
				43.70332
			],
			[
				-79.333,
				43.70312
			],
			[
				-79.33386,
				43.70293
			],
			[
				-79.33424,
				43.70277
			],
			[
				-79.33425,
				43.70269
			],
			[
				-79.33422,
				43.70275
			],
			[
				-79.33436,
				43.7029
			],
			[
				-79.33491,
				43.70311
			],
			[
				-79.33563,
				43.70291
			],
			[
				-79.33565,
				43.70279
			],
			[
				-79.33678,
				43.70229
			],
			[
				-79.33718,
				43.70199
			],
			[
				-79.33782,
				43.70181
			],
			[
				-79.33897,
				43.70121
			],
			[
				-79.33927,
				43.70101
			],
			[
				-79.34018,
				43.69986
			],
			[
				-79.34068,
				43.69954
			],
			[
				-79.34143,
				43.69931
			],
			[
				-79.34249,
				43.69871
			],
			[
				-79.34378,
				43.69832
			],
			[
				-79.34466,
				43.69819
			],
			[
				-79.34604,
				43.6984
			],
			[
				-79.34676,
				43.69861
			],
			[
				-79.34759,
				43.6986
			],
			[
				-79.34809,
				43.69871
			],
			[
				-79.34887,
				43.69851
			],
			[
				-79.3493,
				43.69855
			],
			[
				-79.35088,
				43.69846
			],
			[
				-79.35161,
				43.6983
			],
			[
				-79.35201,
				43.69831
			],
			[
				-79.35237,
				43.69811
			],
			[
				-79.35285,
				43.69817
			],
			[
				-79.35354,
				43.69812
			],
			[
				-79.3538,
				43.69803
			],
			[
				-79.3546,
				43.69798
			],
			[
				-79.35488,
				43.69787
			],
			[
				-79.35543,
				43.69735
			],
			[
				-79.35559,
				43.69702
			],
			[
				-79.35568,
				43.69645
			],
			[
				-79.35541,
				43.69628
			],
			[
				-79.35475,
				43.69606
			],
			[
				-79.35451,
				43.69613
			],
			[
				-79.3543,
				43.69642
			],
			[
				-79.35402,
				43.69661
			],
			[
				-79.35361,
				43.69661
			],
			[
				-79.35337,
				43.69638
			],
			[
				-79.35343,
				43.69616
			],
			[
				-79.35418,
				43.69541
			],
			[
				-79.35426,
				43.69504
			],
			[
				-79.35421,
				43.69438
			],
			[
				-79.35411,
				43.69407
			],
			[
				-79.35379,
				43.69355
			],
			[
				-79.35378,
				43.69329
			],
			[
				-79.35337,
				43.69275
			],
			[
				-79.3534,
				43.69269
			],
			[
				-79.35563,
				43.69229
			],
			[
				-79.3559,
				43.69218
			],
			[
				-79.35595,
				43.69198
			],
			[
				-79.35586,
				43.69165
			],
			[
				-79.35503,
				43.68966
			],
			[
				-79.355,
				43.68936
			],
			[
				-79.35521,
				43.68903
			],
			[
				-79.35526,
				43.68867
			],
			[
				-79.35572,
				43.68775
			],
			[
				-79.35614,
				43.68651
			],
			[
				-79.35684,
				43.68484
			],
			[
				-79.35704,
				43.68415
			],
			[
				-79.35695,
				43.68421
			],
			[
				-79.35723,
				43.68407
			],
			[
				-79.35745,
				43.68425
			],
			[
				-79.35753,
				43.68444
			],
			[
				-79.35742,
				43.68495
			],
			[
				-79.3575,
				43.68518
			],
			[
				-79.35892,
				43.68652
			],
			[
				-79.35918,
				43.68668
			],
			[
				-79.35993,
				43.68677
			],
			[
				-79.36052,
				43.68701
			],
			[
				-79.3616,
				43.68763
			],
			[
				-79.36227,
				43.68874
			],
			[
				-79.36258,
				43.68887
			],
			[
				-79.36286,
				43.68887
			],
			[
				-79.36318,
				43.68871
			],
			[
				-79.36326,
				43.68835
			],
			[
				-79.36363,
				43.68793
			],
			[
				-79.36356,
				43.68789
			],
			[
				-79.36364,
				43.68768
			],
			[
				-79.36319,
				43.68703
			],
			[
				-79.36262,
				43.68584
			],
			[
				-79.36262,
				43.68546
			],
			[
				-79.3629,
				43.68483
			],
			[
				-79.36325,
				43.68444
			],
			[
				-79.36364,
				43.68415
			],
			[
				-79.36513,
				43.68348
			],
			[
				-79.36659,
				43.68256
			],
			[
				-79.3674,
				43.6819
			],
			[
				-79.36865,
				43.68058
			],
			[
				-79.36883,
				43.68009
			],
			[
				-79.36886,
				43.67975
			],
			[
				-79.36865,
				43.67903
			],
			[
				-79.36796,
				43.67831
			],
			[
				-79.36621,
				43.67703
			],
			[
				-79.36555,
				43.67631
			],
			[
				-79.36491,
				43.67523
			],
			[
				-79.36384,
				43.67312
			],
			[
				-79.36329,
				43.6725
			],
			[
				-79.36266,
				43.67196
			],
			[
				-79.36155,
				43.67112
			],
			[
				-79.36098,
				43.67083
			],
			[
				-79.3607,
				43.67052
			],
			[
				-79.35878,
				43.66903
			],
			[
				-79.35828,
				43.66832
			],
			[
				-79.35766,
				43.66657
			],
			[
				-79.35781,
				43.66489
			],
			[
				-79.35809,
				43.66463
			],
			[
				-79.35917,
				43.66468
			],
			[
				-79.35954,
				43.66457
			],
			[
				-79.35963,
				43.66438
			],
			[
				-79.35941,
				43.66371
			],
			[
				-79.3594,
				43.66376
			],
			[
				-79.36018,
				43.66353
			],
			[
				-79.36691,
				43.66206
			],
			[
				-79.36703,
				43.66212
			],
			[
				-79.36711,
				43.66235
			],
			[
				-79.36728,
				43.66252
			],
			[
				-79.36796,
				43.66424
			],
			[
				-79.37618,
				43.66252
			],
			[
				-79.37714,
				43.66234
			],
			[
				-79.37727,
				43.6624
			],
			[
				-79.38121,
				43.66156
			],
			[
				-79.38193,
				43.66145
			],
			[
				-79.38306,
				43.66141
			],
			[
				-79.3855,
				43.66094
			],
			[
				-79.38564,
				43.66097
			],
			[
				-79.38558,
				43.66089
			],
			[
				-79.38909,
				43.66017
			],
			[
				-79.38919,
				43.66022
			],
			[
				-79.39029,
				43.66001
			],
			[
				-79.39197,
				43.6596
			],
			[
				-79.39824,
				43.65836
			],
			[
				-79.39831,
				43.65831
			],
			[
				-79.39818,
				43.65818
			],
			[
				-79.39746,
				43.65652
			],
			[
				-79.39906,
				43.65617
			],
			[
				-79.39946,
				43.65658
			],
			[
				-79.39955,
				43.6568
			],
			[
				-79.39967,
				43.65671
			],
			[
				-79.40392,
				43.65585
			],
			[
				-79.40432,
				43.65697
			],
			[
				-79.40448,
				43.65709
			],
			[
				-79.40478,
				43.6571
			],
			[
				-79.40765,
				43.65659
			],
			[
				-79.41531,
				43.65499
			],
			[
				-79.41791,
				43.65532
			],
			[
				-79.41897,
				43.65525
			],
			[
				-79.42008,
				43.65502
			]
		],
		"dupe": false
	},
	{
		"id": 291378338,
		"name": "Evening Ride",
		"distance": 3498.5,
		"moving_time": 597,
		"elapsed_time": 653,
		"total_elevation_gain": 13.4,
		"start_date_local": "2015-04-23T19:21:37Z",
		"average_speed": 5.86,
		"max_speed": 10.9,
		"calories": 79.6,
		"points": [
			[
				-79.42927,
				43.64424
			],
			[
				-79.42953,
				43.64428
			],
			[
				-79.42943,
				43.6443
			],
			[
				-79.42942,
				43.64444
			],
			[
				-79.43067,
				43.6477
			],
			[
				-79.43093,
				43.64815
			],
			[
				-79.43129,
				43.64935
			],
			[
				-79.4316,
				43.65
			],
			[
				-79.43236,
				43.6522
			],
			[
				-79.43319,
				43.6541
			],
			[
				-79.43338,
				43.65491
			],
			[
				-79.43413,
				43.65691
			],
			[
				-79.4345,
				43.65761
			],
			[
				-79.43468,
				43.65826
			],
			[
				-79.4352,
				43.65945
			],
			[
				-79.43525,
				43.65979
			],
			[
				-79.4344,
				43.66006
			],
			[
				-79.4308,
				43.66088
			],
			[
				-79.42083,
				43.66295
			],
			[
				-79.41644,
				43.6639
			],
			[
				-79.41566,
				43.66413
			],
			[
				-79.41586,
				43.66407
			]
		],
		"dupe": false
	},
	{
		"id": 292505679,
		"name": "Ride through the Scarborough Hydro Corridor",
		"distance": 59611.6,
		"moving_time": 9226,
		"elapsed_time": 11945,
		"total_elevation_gain": 208.9,
		"start_date_local": "2015-04-25T14:45:47Z",
		"average_speed": 6.461,
		"max_speed": 14.9,
		"calories": 1202.3,
		"points": [
			[
				-79.4227,
				43.65468
			],
			[
				-79.42312,
				43.65549
			],
			[
				-79.42316,
				43.65578
			],
			[
				-79.42403,
				43.65773
			],
			[
				-79.42422,
				43.65848
			],
			[
				-79.42421,
				43.65841
			],
			[
				-79.42398,
				43.6586
			],
			[
				-79.40006,
				43.66349
			],
			[
				-79.39894,
				43.66374
			],
			[
				-79.39815,
				43.66415
			],
			[
				-79.39445,
				43.66492
			],
			[
				-79.39416,
				43.66493
			],
			[
				-79.39381,
				43.66479
			],
			[
				-79.39357,
				43.66393
			],
			[
				-79.3926,
				43.66322
			],
			[
				-79.39088,
				43.66357
			],
			[
				-79.39079,
				43.66343
			],
			[
				-79.39079,
				43.66359
			],
			[
				-79.37783,
				43.6664
			],
			[
				-79.37726,
				43.6665
			],
			[
				-79.37622,
				43.66641
			],
			[
				-79.37584,
				43.66646
			],
			[
				-79.37467,
				43.66701
			],
			[
				-79.36955,
				43.66815
			],
			[
				-79.37094,
				43.67152
			],
			[
				-79.37091,
				43.67189
			],
			[
				-79.37059,
				43.67199
			],
			[
				-79.3702,
				43.67233
			],
			[
				-79.36678,
				43.67462
			],
			[
				-79.36125,
				43.67567
			],
			[
				-79.35893,
				43.67622
			],
			[
				-79.35878,
				43.67714
			],
			[
				-79.35822,
				43.679
			],
			[
				-79.358,
				43.68005
			],
			[
				-79.35768,
				43.68097
			],
			[
				-79.35099,
				43.68229
			],
			[
				-79.35328,
				43.68847
			],
			[
				-79.35255,
				43.68867
			],
			[
				-79.34414,
				43.69028
			],
			[
				-79.34371,
				43.69028
			],
			[
				-79.34365,
				43.69038
			],
			[
				-79.34241,
				43.69064
			],
			[
				-79.34212,
				43.69082
			],
			[
				-79.34183,
				43.69085
			],
			[
				-79.34179,
				43.69106
			],
			[
				-79.34131,
				43.69124
			],
			[
				-79.31745,
				43.69641
			],
			[
				-79.31747,
				43.69671
			],
			[
				-79.31849,
				43.69888
			],
			[
				-79.31891,
				43.69994
			],
			[
				-79.31893,
				43.70018
			],
			[
				-79.31587,
				43.70253
			],
			[
				-79.31571,
				43.70277
			],
			[
				-79.315,
				43.70325
			],
			[
				-79.31321,
				43.70479
			],
			[
				-79.31289,
				43.70526
			],
			[
				-79.31262,
				43.70551
			],
			[
				-79.31136,
				43.70737
			],
			[
				-79.31104,
				43.70761
			],
			[
				-79.31056,
				43.70823
			],
			[
				-79.31039,
				43.70863
			],
			[
				-79.31004,
				43.70897
			],
			[
				-79.30983,
				43.70935
			],
			[
				-79.30942,
				43.70979
			],
			[
				-79.30884,
				43.71071
			],
			[
				-79.3086,
				43.71087
			],
			[
				-79.30854,
				43.71109
			],
			[
				-79.30788,
				43.71199
			],
			[
				-79.30674,
				43.71333
			],
			[
				-79.30653,
				43.7134
			],
			[
				-79.30613,
				43.71386
			],
			[
				-79.30587,
				43.71441
			],
			[
				-79.30501,
				43.7153
			],
			[
				-79.30506,
				43.71549
			],
			[
				-79.30484,
				43.71598
			],
			[
				-79.30459,
				43.71833
			],
			[
				-79.3044,
				43.71889
			],
			[
				-79.30421,
				43.71994
			],
			[
				-79.30419,
				43.72052
			],
			[
				-79.30385,
				43.72185
			],
			[
				-79.30348,
				43.72237
			],
			[
				-79.30311,
				43.72271
			],
			[
				-79.30202,
				43.72344
			],
			[
				-79.30258,
				43.72454
			],
			[
				-79.30378,
				43.72739
			],
			[
				-79.30424,
				43.72864
			],
			[
				-79.30448,
				43.72907
			],
			[
				-79.30449,
				43.72935
			],
			[
				-79.30409,
				43.72937
			],
			[
				-79.30391,
				43.72927
			],
			[
				-79.30386,
				43.72895
			],
			[
				-79.30399,
				43.72936
			],
			[
				-79.30436,
				43.72929
			],
			[
				-79.30443,
				43.72917
			],
			[
				-79.30414,
				43.72859
			],
			[
				-79.30399,
				43.72859
			],
			[
				-79.30339,
				43.72875
			],
			[
				-79.30262,
				43.72916
			],
			[
				-79.30219,
				43.72925
			],
			[
				-79.30184,
				43.72967
			],
			[
				-79.30172,
				43.72967
			],
			[
				-79.30091,
				43.73011
			],
			[
				-79.30058,
				43.73013
			],
			[
				-79.29977,
				43.72994
			],
			[
				-79.29842,
				43.73013
			],
			[
				-79.29769,
				43.73041
			],
			[
				-79.2962,
				43.73123
			],
			[
				-79.29589,
				43.73126
			],
			[
				-79.2956,
				43.73153
			],
			[
				-79.29506,
				43.73261
			],
			[
				-79.29497,
				43.7327
			],
			[
				-79.2946,
				43.73263
			],
			[
				-79.29374,
				43.73304
			],
			[
				-79.29354,
				43.73339
			],
			[
				-79.29354,
				43.7335
			],
			[
				-79.29372,
				43.73371
			],
			[
				-79.29366,
				43.73387
			],
			[
				-79.29281,
				43.73438
			],
			[
				-79.29187,
				43.73476
			],
			[
				-79.29093,
				43.73531
			],
			[
				-79.29034,
				43.73547
			],
			[
				-79.28853,
				43.73649
			],
			[
				-79.28765,
				43.73683
			],
			[
				-79.28671,
				43.73702
			],
			[
				-79.28596,
				43.73672
			],
			[
				-79.2854,
				43.73681
			],
			[
				-79.28504,
				43.73696
			],
			[
				-79.28479,
				43.73716
			],
			[
				-79.28484,
				43.7373
			],
			[
				-79.28544,
				43.73756
			],
			[
				-79.28546,
				43.73766
			],
			[
				-79.28511,
				43.73788
			],
			[
				-79.28501,
				43.73819
			],
			[
				-79.28436,
				43.73859
			],
			[
				-79.28362,
				43.73869
			],
			[
				-79.28316,
				43.73867
			],
			[
				-79.28308,
				43.73873
			],
			[
				-79.28236,
				43.73849
			],
			[
				-79.28205,
				43.73852
			],
			[
				-79.28166,
				43.73882
			],
			[
				-79.28143,
				43.7389
			],
			[
				-79.28138,
				43.7388
			],
			[
				-79.28114,
				43.73887
			],
			[
				-79.28092,
				43.73906
			],
			[
				-79.28056,
				43.73917
			],
			[
				-79.28056,
				43.73927
			],
			[
				-79.2796,
				43.73962
			],
			[
				-79.27914,
				43.73993
			],
			[
				-79.2789,
				43.73993
			],
			[
				-79.27775,
				43.74052
			],
			[
				-79.27709,
				43.74073
			],
			[
				-79.2768,
				43.74097
			],
			[
				-79.27636,
				43.74103
			],
			[
				-79.27628,
				43.74113
			],
			[
				-79.27549,
				43.74147
			],
			[
				-79.27537,
				43.74166
			],
			[
				-79.27488,
				43.74175
			],
			[
				-79.27425,
				43.74209
			],
			[
				-79.27392,
				43.74212
			],
			[
				-79.27293,
				43.74262
			],
			[
				-79.27251,
				43.74268
			],
			[
				-79.27239,
				43.74266
			],
			[
				-79.27217,
				43.74228
			],
			[
				-79.27198,
				43.74217
			],
			[
				-79.27099,
				43.74243
			],
			[
				-79.27035,
				43.74208
			],
			[
				-79.26941,
				43.74213
			],
			[
				-79.26912,
				43.74199
			],
			[
				-79.26774,
				43.74228
			],
			[
				-79.26751,
				43.74192
			],
			[
				-79.26724,
				43.74172
			],
			[
				-79.26726,
				43.74164
			],
			[
				-79.26695,
				43.74109
			],
			[
				-79.26676,
				43.74027
			],
			[
				-79.26635,
				43.73971
			],
			[
				-79.26628,
				43.73935
			],
			[
				-79.26605,
				43.73898
			],
			[
				-79.2659,
				43.73847
			],
			[
				-79.26578,
				43.73836
			],
			[
				-79.26586,
				43.73823
			],
			[
				-79.26583,
				43.73813
			],
			[
				-79.26562,
				43.73789
			],
			[
				-79.26552,
				43.73755
			],
			[
				-79.26526,
				43.73742
			],
			[
				-79.26515,
				43.73718
			],
			[
				-79.26517,
				43.73695
			],
			[
				-79.2649,
				43.73633
			],
			[
				-79.26494,
				43.73604
			],
			[
				-79.2646,
				43.7356
			],
			[
				-79.26453,
				43.73515
			],
			[
				-79.26433,
				43.73488
			],
			[
				-79.26505,
				43.73339
			],
			[
				-79.26504,
				43.73328
			],
			[
				-79.2663,
				43.73386
			],
			[
				-79.26601,
				43.73395
			],
			[
				-79.26563,
				43.73374
			],
			[
				-79.26497,
				43.73359
			],
			[
				-79.26437,
				43.73456
			],
			[
				-79.26431,
				43.73497
			],
			[
				-79.26452,
				43.73527
			],
			[
				-79.26475,
				43.73585
			],
			[
				-79.2649,
				43.73599
			],
			[
				-79.26494,
				43.73644
			],
			[
				-79.26521,
				43.73692
			],
			[
				-79.2655,
				43.73774
			],
			[
				-79.26581,
				43.73811
			],
			[
				-79.2658,
				43.73834
			],
			[
				-79.26637,
				43.7397
			],
			[
				-79.26669,
				43.74017
			],
			[
				-79.26679,
				43.74077
			],
			[
				-79.26699,
				43.7413
			],
			[
				-79.2672,
				43.74173
			],
			[
				-79.26736,
				43.74183
			],
			[
				-79.26624,
				43.74209
			],
			[
				-79.26648,
				43.74199
			],
			[
				-79.26631,
				43.74203
			],
			[
				-79.26642,
				43.74205
			],
			[
				-79.26631,
				43.74199
			],
			[
				-79.26584,
				43.74224
			],
			[
				-79.26286,
				43.74287
			],
			[
				-79.26414,
				43.746
			],
			[
				-79.2641,
				43.74629
			],
			[
				-79.26348,
				43.74627
			],
			[
				-79.2624,
				43.7465
			],
			[
				-79.2615,
				43.74657
			],
			[
				-79.26079,
				43.74677
			],
			[
				-79.26072,
				43.74693
			],
			[
				-79.26097,
				43.74752
			],
			[
				-79.26121,
				43.74787
			],
			[
				-79.26119,
				43.74807
			],
			[
				-79.2606,
				43.74812
			],
			[
				-79.25915,
				43.74847
			],
			[
				-79.25891,
				43.74867
			],
			[
				-79.25836,
				43.75009
			],
			[
				-79.25804,
				43.75145
			],
			[
				-79.25822,
				43.75212
			],
			[
				-79.25841,
				43.7525
			],
			[
				-79.25789,
				43.75267
			],
			[
				-79.25702,
				43.75263
			],
			[
				-79.25649,
				43.75274
			],
			[
				-79.25552,
				43.75341
			],
			[
				-79.25556,
				43.75366
			],
			[
				-79.25517,
				43.75378
			],
			[
				-79.25519,
				43.75412
			],
			[
				-79.25495,
				43.75463
			],
			[
				-79.25373,
				43.75524
			],
			[
				-79.25165,
				43.75659
			],
			[
				-79.25114,
				43.75681
			],
			[
				-79.25109,
				43.75697
			],
			[
				-79.25161,
				43.75735
			],
			[
				-79.25193,
				43.75774
			],
			[
				-79.25212,
				43.75783
			],
			[
				-79.25208,
				43.75798
			],
			[
				-79.2526,
				43.75826
			],
			[
				-79.2524,
				43.75872
			],
			[
				-79.25237,
				43.75862
			],
			[
				-79.25186,
				43.75843
			],
			[
				-79.25077,
				43.75856
			],
			[
				-79.25018,
				43.75871
			],
			[
				-79.24964,
				43.75912
			],
			[
				-79.24931,
				43.75926
			],
			[
				-79.24897,
				43.75923
			],
			[
				-79.24842,
				43.75886
			],
			[
				-79.24805,
				43.75883
			],
			[
				-79.24741,
				43.75961
			],
			[
				-79.24722,
				43.75999
			],
			[
				-79.2469,
				43.76007
			],
			[
				-79.24699,
				43.76011
			],
			[
				-79.24687,
				43.76008
			],
			[
				-79.24631,
				43.76037
			],
			[
				-79.24522,
				43.76105
			],
			[
				-79.245,
				43.76124
			],
			[
				-79.24493,
				43.76143
			],
			[
				-79.24453,
				43.76169
			],
			[
				-79.24429,
				43.76201
			],
			[
				-79.24294,
				43.76292
			],
			[
				-79.24182,
				43.76331
			],
			[
				-79.24162,
				43.76374
			],
			[
				-79.24061,
				43.76443
			],
			[
				-79.24007,
				43.76466
			],
			[
				-79.23959,
				43.76503
			],
			[
				-79.23796,
				43.76543
			],
			[
				-79.23554,
				43.76729
			],
			[
				-79.23512,
				43.76733
			],
			[
				-79.23489,
				43.76745
			],
			[
				-79.23177,
				43.76965
			],
			[
				-79.23112,
				43.77002
			],
			[
				-79.23079,
				43.77028
			],
			[
				-79.23071,
				43.7705
			],
			[
				-79.23074,
				43.77081
			],
			[
				-79.2306,
				43.77097
			],
			[
				-79.23037,
				43.77112
			],
			[
				-79.22948,
				43.77134
			],
			[
				-79.22929,
				43.77147
			],
			[
				-79.22921,
				43.77156
			],
			[
				-79.22918,
				43.77189
			],
			[
				-79.22858,
				43.77225
			],
			[
				-79.22773,
				43.77294
			],
			[
				-79.22713,
				43.77314
			],
			[
				-79.2271,
				43.77361
			],
			[
				-79.22678,
				43.77377
			],
			[
				-79.22626,
				43.77424
			],
			[
				-79.22512,
				43.77474
			],
			[
				-79.22459,
				43.77517
			],
			[
				-79.2241,
				43.77537
			],
			[
				-79.22355,
				43.77576
			],
			[
				-79.2235,
				43.77617
			],
			[
				-79.22332,
				43.77634
			],
			[
				-79.2228,
				43.77655
			],
			[
				-79.22206,
				43.77649
			],
			[
				-79.22181,
				43.77657
			],
			[
				-79.22182,
				43.77687
			],
			[
				-79.22162,
				43.77703
			],
			[
				-79.22216,
				43.77835
			],
			[
				-79.22207,
				43.7785
			],
			[
				-79.22197,
				43.77852
			],
			[
				-79.22209,
				43.77868
			],
			[
				-79.21731,
				43.77977
			],
			[
				-79.21738,
				43.77976
			],
			[
				-79.21739,
				43.77966
			],
			[
				-79.21879,
				43.77958
			],
			[
				-79.21881,
				43.77946
			],
			[
				-79.22246,
				43.7786
			],
			[
				-79.22218,
				43.7784
			],
			[
				-79.22221,
				43.77848
			],
			[
				-79.22201,
				43.77802
			],
			[
				-79.21601,
				43.76324
			],
			[
				-79.21588,
				43.76278
			],
			[
				-79.21585,
				43.762
			],
			[
				-79.21623,
				43.76186
			],
			[
				-79.2294,
				43.75906
			],
			[
				-79.23082,
				43.75867
			],
			[
				-79.2313,
				43.75865
			],
			[
				-79.23267,
				43.7583
			],
			[
				-79.23423,
				43.75807
			],
			[
				-79.23757,
				43.75726
			],
			[
				-79.23826,
				43.75719
			],
			[
				-79.2382,
				43.75719
			],
			[
				-79.23838,
				43.75743
			],
			[
				-79.2383,
				43.75725
			],
			[
				-79.23856,
				43.75713
			],
			[
				-79.24618,
				43.75545
			],
			[
				-79.24757,
				43.7552
			],
			[
				-79.24793,
				43.75522
			],
			[
				-79.24779,
				43.75523
			],
			[
				-79.2483,
				43.75506
			],
			[
				-79.25126,
				43.75445
			],
			[
				-79.25141,
				43.75446
			],
			[
				-79.25149,
				43.75466
			],
			[
				-79.25173,
				43.75466
			],
			[
				-79.25253,
				43.75444
			],
			[
				-79.25318,
				43.75442
			],
			[
				-79.2547,
				43.75393
			],
			[
				-79.25629,
				43.75353
			],
			[
				-79.25673,
				43.75332
			],
			[
				-79.25846,
				43.75297
			],
			[
				-79.25893,
				43.7528
			],
			[
				-79.26539,
				43.75158
			],
			[
				-79.26552,
				43.75168
			],
			[
				-79.2657,
				43.75164
			],
			[
				-79.26575,
				43.75151
			],
			[
				-79.27356,
				43.74991
			],
			[
				-79.27378,
				43.74981
			],
			[
				-79.27492,
				43.74964
			],
			[
				-79.27653,
				43.74925
			],
			[
				-79.27676,
				43.74926
			],
			[
				-79.28477,
				43.74766
			],
			[
				-79.28602,
				43.74733
			],
			[
				-79.28859,
				43.74692
			],
			[
				-79.29108,
				43.74629
			],
			[
				-79.29142,
				43.74631
			],
			[
				-79.29599,
				43.74523
			],
			[
				-79.29802,
				43.74487
			],
			[
				-79.2986,
				43.74467
			],
			[
				-79.30016,
				43.74442
			],
			[
				-79.3004,
				43.74433
			],
			[
				-79.30043,
				43.74425
			],
			[
				-79.30377,
				43.74356
			],
			[
				-79.30553,
				43.7431
			],
			[
				-79.30902,
				43.74244
			],
			[
				-79.31183,
				43.74179
			],
			[
				-79.31347,
				43.74165
			],
			[
				-79.31408,
				43.74169
			],
			[
				-79.3146,
				43.74158
			],
			[
				-79.31582,
				43.74158
			],
			[
				-79.31668,
				43.74135
			],
			[
				-79.31724,
				43.74144
			],
			[
				-79.31909,
				43.74133
			],
			[
				-79.31919,
				43.7414
			],
			[
				-79.32097,
				43.74132
			],
			[
				-79.32232,
				43.74119
			],
			[
				-79.32257,
				43.74107
			],
			[
				-79.32563,
				43.74076
			],
			[
				-79.32847,
				43.74028
			],
			[
				-79.32881,
				43.74026
			],
			[
				-79.32898,
				43.74035
			],
			[
				-79.33052,
				43.73988
			],
			[
				-79.33812,
				43.73839
			],
			[
				-79.34652,
				43.73661
			],
			[
				-79.34781,
				43.73627
			],
			[
				-79.34827,
				43.73626
			],
			[
				-79.35618,
				43.73456
			],
			[
				-79.35707,
				43.73451
			],
			[
				-79.35874,
				43.73471
			],
			[
				-79.35963,
				43.73463
			],
			[
				-79.35999,
				43.73515
			],
			[
				-79.35966,
				43.73463
			],
			[
				-79.35866,
				43.73471
			],
			[
				-79.35672,
				43.73445
			],
			[
				-79.35606,
				43.73447
			],
			[
				-79.35547,
				43.7319
			],
			[
				-79.35533,
				43.73154
			],
			[
				-79.35497,
				43.73099
			],
			[
				-79.35376,
				43.72963
			],
			[
				-79.35158,
				43.7274
			],
			[
				-79.35,
				43.72618
			],
			[
				-79.34924,
				43.72518
			],
			[
				-79.34895,
				43.7244
			],
			[
				-79.34886,
				43.72358
			],
			[
				-79.34904,
				43.72289
			],
			[
				-79.34996,
				43.72054
			],
			[
				-79.35051,
				43.7188
			],
			[
				-79.35054,
				43.71825
			],
			[
				-79.35029,
				43.71724
			],
			[
				-79.35047,
				43.71706
			],
			[
				-79.35084,
				43.71688
			],
			[
				-79.35424,
				43.71585
			],
			[
				-79.35772,
				43.71522
			],
			[
				-79.3603,
				43.7146
			],
			[
				-79.36607,
				43.71342
			],
			[
				-79.36707,
				43.71313
			],
			[
				-79.36766,
				43.71308
			],
			[
				-79.37038,
				43.71248
			],
			[
				-79.37655,
				43.71129
			],
			[
				-79.38181,
				43.71015
			],
			[
				-79.38224,
				43.71012
			],
			[
				-79.3888,
				43.70878
			],
			[
				-79.38893,
				43.7087
			],
			[
				-79.38887,
				43.70875
			],
			[
				-79.39011,
				43.70849
			],
			[
				-79.39022,
				43.70857
			],
			[
				-79.39031,
				43.70849
			],
			[
				-79.39024,
				43.70826
			],
			[
				-79.38837,
				43.70351
			],
			[
				-79.38817,
				43.70317
			],
			[
				-79.38812,
				43.70286
			],
			[
				-79.38732,
				43.701
			],
			[
				-79.38718,
				43.70049
			],
			[
				-79.38699,
				43.70055
			],
			[
				-79.38717,
				43.70048
			],
			[
				-79.38666,
				43.69831
			],
			[
				-79.38667,
				43.69836
			],
			[
				-79.38687,
				43.6981
			],
			[
				-79.38653,
				43.69792
			],
			[
				-79.3855,
				43.69532
			],
			[
				-79.38595,
				43.69525
			],
			[
				-79.38613,
				43.69494
			],
			[
				-79.38634,
				43.69484
			],
			[
				-79.38724,
				43.69481
			],
			[
				-79.38848,
				43.69436
			],
			[
				-79.38859,
				43.69445
			],
			[
				-79.3888,
				43.69422
			],
			[
				-79.38909,
				43.69412
			],
			[
				-79.3897,
				43.69352
			],
			[
				-79.38996,
				43.69352
			],
			[
				-79.39048,
				43.69317
			],
			[
				-79.39108,
				43.69318
			],
			[
				-79.3916,
				43.69336
			],
			[
				-79.39218,
				43.69415
			],
			[
				-79.39262,
				43.69434
			],
			[
				-79.39322,
				43.69442
			],
			[
				-79.39425,
				43.69428
			],
			[
				-79.39451,
				43.6941
			],
			[
				-79.39471,
				43.69368
			],
			[
				-79.39543,
				43.6929
			],
			[
				-79.39533,
				43.69284
			],
			[
				-79.39517,
				43.69203
			],
			[
				-79.39541,
				43.69208
			],
			[
				-79.39551,
				43.69195
			],
			[
				-79.39508,
				43.69023
			],
			[
				-79.39532,
				43.69012
			],
			[
				-79.4193,
				43.68534
			],
			[
				-79.41859,
				43.68352
			],
			[
				-79.41836,
				43.68347
			],
			[
				-79.41844,
				43.68341
			],
			[
				-79.41816,
				43.68276
			],
			[
				-79.41825,
				43.68267
			],
			[
				-79.41668,
				43.67867
			],
			[
				-79.41663,
				43.67832
			],
			[
				-79.41622,
				43.67757
			],
			[
				-79.41613,
				43.6772
			],
			[
				-79.41448,
				43.67313
			],
			[
				-79.4162,
				43.67284
			],
			[
				-79.42039,
				43.6719
			],
			[
				-79.42427,
				43.67118
			],
			[
				-79.42879,
				43.67018
			],
			[
				-79.42895,
				43.67016
			],
			[
				-79.42908,
				43.67025
			],
			[
				-79.42869,
				43.66892
			],
			[
				-79.42809,
				43.66766
			],
			[
				-79.42705,
				43.66495
			],
			[
				-79.42605,
				43.66286
			],
			[
				-79.4258,
				43.66202
			],
			[
				-79.42552,
				43.66172
			],
			[
				-79.42535,
				43.66126
			],
			[
				-79.42527,
				43.66072
			],
			[
				-79.42306,
				43.65504
			]
		],
		"dupe": false
	},
	{
		"id": 291460254,
		"name": "Night Ride",
		"distance": 2533,
		"moving_time": 694,
		"elapsed_time": 1303,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-23T23:59:51Z",
		"average_speed": 3.65,
		"max_speed": 10,
		"calories": 55.8,
		"points": [
			[
				-79.40978,
				43.66551
			],
			[
				-79.41517,
				43.66431
			],
			[
				-79.41561,
				43.66427
			],
			[
				-79.41559,
				43.66412
			],
			[
				-79.41557,
				43.66428
			],
			[
				-79.41546,
				43.66421
			],
			[
				-79.41545,
				43.66426
			],
			[
				-79.42263,
				43.66274
			],
			[
				-79.42324,
				43.66253
			],
			[
				-79.42193,
				43.65911
			],
			[
				-79.42216,
				43.65897
			],
			[
				-79.42428,
				43.65853
			],
			[
				-79.42401,
				43.65755
			],
			[
				-79.42296,
				43.65506
			],
			[
				-79.42271,
				43.65412
			],
			[
				-79.42244,
				43.65356
			],
			[
				-79.42338,
				43.6533
			]
		],
		"dupe": false
	},
	{
		"id": 295094020,
		"name": "Evening Ride",
		"distance": 4049.8,
		"moving_time": 646,
		"elapsed_time": 680,
		"total_elevation_gain": 0,
		"start_date_local": "2015-04-29T19:17:08Z",
		"average_speed": 6.269,
		"max_speed": 11.2,
		"calories": 76.2,
		"points": [
			[
				-79.4234,
				43.65344
			],
			[
				-79.42323,
				43.65326
			],
			[
				-79.42249,
				43.65343
			],
			[
				-79.42231,
				43.65356
			],
			[
				-79.4225,
				43.65399
			],
			[
				-79.42258,
				43.65441
			],
			[
				-79.42084,
				43.65472
			],
			[
				-79.41939,
				43.6551
			],
			[
				-79.41765,
				43.6553
			],
			[
				-79.41687,
				43.65518
			],
			[
				-79.41599,
				43.65492
			],
			[
				-79.41543,
				43.65487
			],
			[
				-79.40351,
				43.65728
			],
			[
				-79.39856,
				43.65813
			],
			[
				-79.397,
				43.6585
			],
			[
				-79.39595,
				43.65862
			],
			[
				-79.38685,
				43.66059
			],
			[
				-79.38318,
				43.6613
			],
			[
				-79.38331,
				43.66128
			],
			[
				-79.38307,
				43.66121
			],
			[
				-79.3826,
				43.65987
			],
			[
				-79.38228,
				43.6593
			],
			[
				-79.38234,
				43.65904
			],
			[
				-79.38208,
				43.65868
			],
			[
				-79.38161,
				43.65761
			],
			[
				-79.3814,
				43.65738
			],
			[
				-79.38134,
				43.65749
			],
			[
				-79.38119,
				43.65745
			],
			[
				-79.38124,
				43.65744
			]
		],
		"dupe": false
	},
	{
		"id": 295184052,
		"name": "Night Ride",
		"distance": 4402.2,
		"moving_time": 675,
		"elapsed_time": 679,
		"total_elevation_gain": 13.5,
		"start_date_local": "2015-04-29T23:14:39Z",
		"average_speed": 6.522,
		"max_speed": 11.2,
		"calories": 95.6,
		"points": [
			[
				-79.37932,
				43.65414
			],
			[
				-79.37989,
				43.65437
			],
			[
				-79.38,
				43.65429
			],
			[
				-79.38004,
				43.65446
			],
			[
				-79.38023,
				43.65464
			],
			[
				-79.38031,
				43.65513
			],
			[
				-79.38045,
				43.65534
			],
			[
				-79.38082,
				43.65649
			],
			[
				-79.38124,
				43.65719
			],
			[
				-79.38151,
				43.65803
			],
			[
				-79.38204,
				43.65902
			],
			[
				-79.38238,
				43.65905
			],
			[
				-79.38487,
				43.65853
			],
			[
				-79.38581,
				43.66066
			],
			[
				-79.38579,
				43.6606
			],
			[
				-79.38587,
				43.66075
			],
			[
				-79.38618,
				43.66078
			],
			[
				-79.39768,
				43.65843
			],
			[
				-79.40033,
				43.65805
			],
			[
				-79.41523,
				43.65502
			],
			[
				-79.41779,
				43.65531
			],
			[
				-79.41903,
				43.65524
			],
			[
				-79.42263,
				43.65446
			],
			[
				-79.42274,
				43.65441
			],
			[
				-79.42258,
				43.65411
			],
			[
				-79.42247,
				43.65348
			],
			[
				-79.42249,
				43.65354
			],
			[
				-79.42262,
				43.65345
			],
			[
				-79.42333,
				43.65331
			]
		],
		"dupe": false
	},
	{
		"id": 296316514,
		"name": "Evening Ride",
		"distance": 5683,
		"moving_time": 975,
		"elapsed_time": 1212,
		"total_elevation_gain": 16,
		"start_date_local": "2015-05-01T18:56:23Z",
		"average_speed": 5.829,
		"max_speed": 15.1,
		"calories": 110.3,
		"points": [
			[
				-79.42898,
				43.64294
			],
			[
				-79.42884,
				43.64189
			],
			[
				-79.42864,
				43.64213
			],
			[
				-79.429,
				43.64217
			],
			[
				-79.43016,
				43.64201
			],
			[
				-79.43058,
				43.64185
			],
			[
				-79.4324,
				43.64155
			],
			[
				-79.43548,
				43.64086
			],
			[
				-79.43634,
				43.64075
			],
			[
				-79.43651,
				43.64064
			],
			[
				-79.43731,
				43.64046
			],
			[
				-79.43462,
				43.6334
			],
			[
				-79.43462,
				43.6331
			],
			[
				-79.43445,
				43.63253
			],
			[
				-79.43485,
				43.63247
			],
			[
				-79.43509,
				43.6319
			],
			[
				-79.43431,
				43.6315
			],
			[
				-79.43419,
				43.63121
			],
			[
				-79.43388,
				43.63115
			],
			[
				-79.43364,
				43.63095
			],
			[
				-79.43326,
				43.6308
			],
			[
				-79.43251,
				43.63072
			],
			[
				-79.42993,
				43.63016
			],
			[
				-79.42845,
				43.62996
			],
			[
				-79.426,
				43.62977
			],
			[
				-79.42525,
				43.62986
			],
			[
				-79.42519,
				43.62993
			],
			[
				-79.42112,
				43.63023
			],
			[
				-79.42104,
				43.63013
			],
			[
				-79.42038,
				43.63023
			],
			[
				-79.42,
				43.63009
			],
			[
				-79.41979,
				43.63023
			],
			[
				-79.41896,
				43.62988
			],
			[
				-79.41862,
				43.62996
			],
			[
				-79.41855,
				43.63002
			],
			[
				-79.41861,
				43.6305
			],
			[
				-79.41897,
				43.6314
			],
			[
				-79.41928,
				43.63144
			],
			[
				-79.41945,
				43.63156
			],
			[
				-79.41955,
				43.63202
			],
			[
				-79.41994,
				43.63268
			],
			[
				-79.4215,
				43.6323
			],
			[
				-79.42327,
				43.63296
			],
			[
				-79.42422,
				43.63275
			],
			[
				-79.42488,
				43.63276
			],
			[
				-79.42514,
				43.63316
			],
			[
				-79.42565,
				43.63442
			],
			[
				-79.42739,
				43.63893
			],
			[
				-79.42739,
				43.63917
			],
			[
				-79.42761,
				43.63957
			],
			[
				-79.42796,
				43.64057
			],
			[
				-79.42799,
				43.64085
			],
			[
				-79.42856,
				43.64217
			],
			[
				-79.42837,
				43.64219
			],
			[
				-79.42859,
				43.64205
			],
			[
				-79.42856,
				43.64199
			],
			[
				-79.42785,
				43.6423
			],
			[
				-79.42583,
				43.64284
			],
			[
				-79.42385,
				43.64314
			]
		],
		"dupe": false
	},
	{
		"id": 296323996,
		"name": "Evening Ride",
		"distance": 3127.6,
		"moving_time": 623,
		"elapsed_time": 978,
		"total_elevation_gain": 16.5,
		"start_date_local": "2015-05-01T20:45:06Z",
		"average_speed": 5.02,
		"max_speed": 10.5,
		"calories": 74.4,
		"points": [
			[
				-79.42235,
				43.64357
			],
			[
				-79.4228,
				43.64451
			],
			[
				-79.42382,
				43.6471
			],
			[
				-79.42412,
				43.64787
			],
			[
				-79.42408,
				43.648
			],
			[
				-79.4242,
				43.64808
			],
			[
				-79.4243,
				43.64831
			],
			[
				-79.42425,
				43.64845
			],
			[
				-79.42437,
				43.6485
			],
			[
				-79.42639,
				43.65356
			],
			[
				-79.42626,
				43.65356
			],
			[
				-79.42635,
				43.65356
			],
			[
				-79.42657,
				43.65391
			],
			[
				-79.42935,
				43.66116
			],
			[
				-79.43363,
				43.6603
			],
			[
				-79.43432,
				43.66008
			],
			[
				-79.43517,
				43.65998
			],
			[
				-79.43586,
				43.65978
			],
			[
				-79.44097,
				43.65882
			],
			[
				-79.4409,
				43.65884
			],
			[
				-79.44088,
				43.65873
			],
			[
				-79.44125,
				43.65866
			]
		],
		"dupe": false
	},
	{
		"id": 296751644,
		"name": "Night Ride",
		"distance": 2199.3,
		"moving_time": 285,
		"elapsed_time": 285,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-01T22:47:03Z",
		"average_speed": 7.717,
		"max_speed": 12.5,
		"calories": 40.7,
		"points": [
			[
				-79.44173,
				43.65863
			],
			[
				-79.44058,
				43.65877
			],
			[
				-79.43803,
				43.65927
			],
			[
				-79.43665,
				43.65963
			],
			[
				-79.43393,
				43.66012
			],
			[
				-79.43174,
				43.66065
			],
			[
				-79.43111,
				43.6607
			],
			[
				-79.42945,
				43.66112
			],
			[
				-79.42923,
				43.66087
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.42386,
				43.65319
			]
		],
		"dupe": false
	},
	{
		"id": 296805676,
		"name": "Afternoon Ride",
		"distance": 1800.9,
		"moving_time": 550,
		"elapsed_time": 808,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-02T14:06:22Z",
		"average_speed": 3.274,
		"max_speed": 10.8,
		"calories": 31.8,
		"points": [
			[
				-79.42335,
				43.65364
			],
			[
				-79.42316,
				43.65377
			],
			[
				-79.42255,
				43.6539
			],
			[
				-79.42245,
				43.65408
			],
			[
				-79.42253,
				43.65443
			],
			[
				-79.42143,
				43.65461
			],
			[
				-79.42091,
				43.65483
			],
			[
				-79.42049,
				43.65482
			],
			[
				-79.41946,
				43.65506
			],
			[
				-79.41789,
				43.65526
			],
			[
				-79.41582,
				43.65499
			],
			[
				-79.41575,
				43.65495
			],
			[
				-79.4159,
				43.65497
			],
			[
				-79.4158,
				43.6547
			],
			[
				-79.41586,
				43.65489
			],
			[
				-79.41575,
				43.65503
			],
			[
				-79.41589,
				43.65498
			],
			[
				-79.4155,
				43.65489
			],
			[
				-79.41507,
				43.65491
			],
			[
				-79.41402,
				43.65528
			],
			[
				-79.41325,
				43.65535
			],
			[
				-79.41251,
				43.65553
			],
			[
				-79.41257,
				43.65559
			],
			[
				-79.41286,
				43.65544
			],
			[
				-79.41298,
				43.6556
			],
			[
				-79.41282,
				43.65538
			],
			[
				-79.41291,
				43.65546
			],
			[
				-79.41228,
				43.65547
			],
			[
				-79.41156,
				43.65569
			],
			[
				-79.41041,
				43.65584
			],
			[
				-79.40837,
				43.65626
			],
			[
				-79.40816,
				43.65596
			],
			[
				-79.40773,
				43.65599
			],
			[
				-79.40753,
				43.65578
			],
			[
				-79.40735,
				43.65531
			],
			[
				-79.4072,
				43.65515
			]
		],
		"dupe": false
	},
	{
		"id": 296946305,
		"name": "Evening Ride",
		"distance": 1761.6,
		"moving_time": 401,
		"elapsed_time": 408,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-02T18:36:09Z",
		"average_speed": 4.393,
		"max_speed": 9.4,
		"calories": 31.8,
		"points": [
			[
				-79.40708,
				43.65533
			],
			[
				-79.40726,
				43.65525
			],
			[
				-79.40707,
				43.65532
			],
			[
				-79.40752,
				43.65618
			],
			[
				-79.40763,
				43.65649
			],
			[
				-79.40755,
				43.65656
			],
			[
				-79.40769,
				43.65666
			],
			[
				-79.40762,
				43.6565
			],
			[
				-79.40766,
				43.65659
			],
			[
				-79.40788,
				43.65655
			],
			[
				-79.41144,
				43.65582
			],
			[
				-79.41313,
				43.65539
			],
			[
				-79.41381,
				43.65533
			],
			[
				-79.415,
				43.65505
			],
			[
				-79.41593,
				43.65504
			],
			[
				-79.41766,
				43.65528
			],
			[
				-79.41906,
				43.65523
			],
			[
				-79.42063,
				43.65483
			],
			[
				-79.42256,
				43.65449
			],
			[
				-79.42265,
				43.65445
			],
			[
				-79.42261,
				43.65436
			],
			[
				-79.4227,
				43.65428
			],
			[
				-79.42246,
				43.65367
			],
			[
				-79.42247,
				43.65349
			],
			[
				-79.42348,
				43.65328
			],
			[
				-79.4235,
				43.65362
			],
			[
				-79.42351,
				43.65352
			]
		],
		"dupe": false
	},
	{
		"id": 297479409,
		"name": "Afternoon Ride",
		"distance": 2354.2,
		"moving_time": 481,
		"elapsed_time": 526,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-03T13:52:33Z",
		"average_speed": 4.894,
		"max_speed": 11.1,
		"calories": 49,
		"points": [
			[
				-79.39773,
				43.64556
			],
			[
				-79.39782,
				43.64552
			],
			[
				-79.3978,
				43.64513
			],
			[
				-79.39771,
				43.64505
			],
			[
				-79.39832,
				43.64488
			],
			[
				-79.40081,
				43.64439
			],
			[
				-79.40185,
				43.64409
			],
			[
				-79.4027,
				43.64401
			],
			[
				-79.40418,
				43.64361
			],
			[
				-79.4051,
				43.64348
			],
			[
				-79.40565,
				43.64329
			],
			[
				-79.40876,
				43.64278
			],
			[
				-79.41156,
				43.64215
			],
			[
				-79.41191,
				43.64216
			],
			[
				-79.41198,
				43.64198
			],
			[
				-79.4115,
				43.64076
			],
			[
				-79.41162,
				43.64003
			],
			[
				-79.41093,
				43.63929
			],
			[
				-79.41106,
				43.63944
			],
			[
				-79.41136,
				43.63917
			],
			[
				-79.41524,
				43.63837
			],
			[
				-79.41699,
				43.63863
			],
			[
				-79.41714,
				43.63887
			],
			[
				-79.41717,
				43.63925
			],
			[
				-79.41796,
				43.63921
			],
			[
				-79.41774,
				43.63923
			],
			[
				-79.41772,
				43.63931
			],
			[
				-79.41765,
				43.63903
			]
		],
		"dupe": false
	},
	{
		"id": 297747421,
		"name": "Night Ride",
		"distance": 38745,
		"moving_time": 5286,
		"elapsed_time": 5609,
		"total_elevation_gain": 57.6,
		"start_date_local": "2015-05-03T22:09:50Z",
		"average_speed": 7.33,
		"max_speed": 18.2,
		"calories": 762.9,
		"points": [
			[
				-79.426,
				43.65273
			],
			[
				-79.42605,
				43.65271
			],
			[
				-79.4235,
				43.64626
			],
			[
				-79.42366,
				43.64614
			],
			[
				-79.42812,
				43.64522
			],
			[
				-79.42805,
				43.64495
			],
			[
				-79.42945,
				43.64463
			],
			[
				-79.42945,
				43.64435
			],
			[
				-79.4288,
				43.64227
			],
			[
				-79.42873,
				43.64212
			],
			[
				-79.42859,
				43.64206
			],
			[
				-79.42867,
				43.64193
			],
			[
				-79.42844,
				43.64135
			],
			[
				-79.42822,
				43.64107
			],
			[
				-79.42773,
				43.63993
			],
			[
				-79.42748,
				43.63901
			],
			[
				-79.42755,
				43.63908
			],
			[
				-79.42755,
				43.63903
			],
			[
				-79.42736,
				43.63886
			],
			[
				-79.42565,
				43.63441
			],
			[
				-79.4249,
				43.63268
			],
			[
				-79.42429,
				43.63273
			],
			[
				-79.42332,
				43.63297
			],
			[
				-79.42143,
				43.63231
			],
			[
				-79.41996,
				43.6327
			],
			[
				-79.41956,
				43.63205
			],
			[
				-79.41939,
				43.63152
			],
			[
				-79.41891,
				43.63143
			],
			[
				-79.41842,
				43.63152
			],
			[
				-79.41832,
				43.63133
			],
			[
				-79.41853,
				43.63075
			],
			[
				-79.41841,
				43.63009
			],
			[
				-79.41847,
				43.63001
			],
			[
				-79.41895,
				43.62991
			],
			[
				-79.41999,
				43.63034
			],
			[
				-79.42493,
				43.62993
			],
			[
				-79.42559,
				43.62997
			],
			[
				-79.42633,
				43.63018
			],
			[
				-79.4272,
				43.6303
			],
			[
				-79.4277,
				43.63001
			],
			[
				-79.42862,
				43.63004
			],
			[
				-79.42935,
				43.63015
			],
			[
				-79.42985,
				43.63012
			],
			[
				-79.43041,
				43.63032
			],
			[
				-79.43142,
				43.63047
			],
			[
				-79.43352,
				43.63094
			],
			[
				-79.43457,
				43.63152
			],
			[
				-79.43547,
				43.63184
			],
			[
				-79.43568,
				43.63213
			],
			[
				-79.43634,
				43.6325
			],
			[
				-79.4369,
				43.63304
			],
			[
				-79.43765,
				43.63354
			],
			[
				-79.4376,
				43.63368
			],
			[
				-79.43792,
				43.63385
			],
			[
				-79.44145,
				43.63541
			],
			[
				-79.44198,
				43.63575
			],
			[
				-79.44321,
				43.63616
			],
			[
				-79.44515,
				43.63696
			],
			[
				-79.44614,
				43.63725
			],
			[
				-79.44653,
				43.63728
			],
			[
				-79.44695,
				43.63688
			],
			[
				-79.44744,
				43.63686
			],
			[
				-79.44865,
				43.63714
			],
			[
				-79.45236,
				43.63777
			],
			[
				-79.45277,
				43.63779
			],
			[
				-79.45317,
				43.63768
			],
			[
				-79.45356,
				43.63786
			],
			[
				-79.45459,
				43.63796
			],
			[
				-79.45659,
				43.638
			],
			[
				-79.45911,
				43.63761
			],
			[
				-79.45959,
				43.63749
			],
			[
				-79.46016,
				43.63712
			],
			[
				-79.4606,
				43.63694
			],
			[
				-79.46104,
				43.63688
			],
			[
				-79.46194,
				43.63654
			],
			[
				-79.46292,
				43.6363
			],
			[
				-79.46444,
				43.63623
			],
			[
				-79.46538,
				43.63592
			],
			[
				-79.46616,
				43.63564
			],
			[
				-79.4663,
				43.63553
			],
			[
				-79.46647,
				43.63519
			],
			[
				-79.46664,
				43.63508
			],
			[
				-79.46789,
				43.63461
			],
			[
				-79.469,
				43.63389
			],
			[
				-79.4694,
				43.6331
			],
			[
				-79.46974,
				43.63287
			],
			[
				-79.47065,
				43.63259
			],
			[
				-79.47178,
				43.63128
			],
			[
				-79.47258,
				43.63062
			],
			[
				-79.47287,
				43.63004
			],
			[
				-79.47324,
				43.62999
			],
			[
				-79.47364,
				43.6302
			],
			[
				-79.47393,
				43.63019
			],
			[
				-79.47459,
				43.62994
			],
			[
				-79.47544,
				43.62935
			],
			[
				-79.47571,
				43.62902
			],
			[
				-79.4764,
				43.6278
			],
			[
				-79.47754,
				43.62476
			],
			[
				-79.47782,
				43.62422
			],
			[
				-79.47779,
				43.62411
			],
			[
				-79.47872,
				43.62288
			],
			[
				-79.47907,
				43.62268
			],
			[
				-79.47961,
				43.62257
			],
			[
				-79.48007,
				43.62217
			],
			[
				-79.48028,
				43.62181
			],
			[
				-79.48051,
				43.6209
			],
			[
				-79.48115,
				43.62051
			],
			[
				-79.48137,
				43.62028
			],
			[
				-79.48144,
				43.61992
			],
			[
				-79.48096,
				43.61929
			],
			[
				-79.48146,
				43.61891
			],
			[
				-79.48229,
				43.61859
			],
			[
				-79.48323,
				43.61849
			],
			[
				-79.48395,
				43.61807
			],
			[
				-79.48438,
				43.61808
			],
			[
				-79.48514,
				43.61731
			],
			[
				-79.48527,
				43.61697
			],
			[
				-79.48524,
				43.61675
			],
			[
				-79.48545,
				43.61631
			],
			[
				-79.48537,
				43.61576
			],
			[
				-79.48611,
				43.61447
			],
			[
				-79.48613,
				43.61389
			],
			[
				-79.48645,
				43.61367
			],
			[
				-79.48714,
				43.61361
			],
			[
				-79.48736,
				43.61347
			],
			[
				-79.48752,
				43.61322
			],
			[
				-79.48751,
				43.61299
			],
			[
				-79.48696,
				43.61256
			],
			[
				-79.4869,
				43.61243
			],
			[
				-79.48718,
				43.61184
			],
			[
				-79.48694,
				43.61152
			],
			[
				-79.48693,
				43.61127
			],
			[
				-79.48683,
				43.61108
			],
			[
				-79.48692,
				43.61083
			],
			[
				-79.48724,
				43.61072
			],
			[
				-79.48811,
				43.61088
			],
			[
				-79.4901,
				43.61095
			],
			[
				-79.49024,
				43.61071
			],
			[
				-79.49023,
				43.61013
			],
			[
				-79.49035,
				43.60967
			],
			[
				-79.4904,
				43.60823
			],
			[
				-79.49056,
				43.60698
			],
			[
				-79.49078,
				43.6063
			],
			[
				-79.49139,
				43.60512
			],
			[
				-79.49239,
				43.6042
			],
			[
				-79.49332,
				43.60363
			],
			[
				-79.496,
				43.60259
			],
			[
				-79.49797,
				43.6024
			],
			[
				-79.49978,
				43.60199
			],
			[
				-79.5012,
				43.60177
			],
			[
				-79.51264,
				43.59919
			],
			[
				-79.51565,
				43.59859
			],
			[
				-79.51738,
				43.59814
			],
			[
				-79.51835,
				43.59799
			],
			[
				-79.52082,
				43.59738
			],
			[
				-79.52807,
				43.59584
			],
			[
				-79.53176,
				43.59489
			],
			[
				-79.53331,
				43.59461
			],
			[
				-79.53558,
				43.59402
			],
			[
				-79.5389,
				43.59335
			],
			[
				-79.53946,
				43.5934
			],
			[
				-79.54083,
				43.59312
			],
			[
				-79.54114,
				43.59321
			],
			[
				-79.54136,
				43.5934
			],
			[
				-79.54178,
				43.59417
			],
			[
				-79.54245,
				43.59581
			],
			[
				-79.54273,
				43.59622
			],
			[
				-79.54341,
				43.59778
			],
			[
				-79.54416,
				43.59966
			],
			[
				-79.54436,
				43.59993
			],
			[
				-79.5445,
				43.60039
			],
			[
				-79.54502,
				43.60137
			],
			[
				-79.54509,
				43.60168
			],
			[
				-79.54642,
				43.60452
			],
			[
				-79.54808,
				43.60856
			],
			[
				-79.54819,
				43.60895
			],
			[
				-79.54807,
				43.60905
			],
			[
				-79.54721,
				43.60923
			],
			[
				-79.54785,
				43.61069
			],
			[
				-79.54753,
				43.61089
			],
			[
				-79.54678,
				43.6109
			],
			[
				-79.54647,
				43.61114
			],
			[
				-79.54666,
				43.61105
			],
			[
				-79.54659,
				43.61107
			],
			[
				-79.54665,
				43.61117
			],
			[
				-79.54706,
				43.61121
			],
			[
				-79.55656,
				43.60904
			],
			[
				-79.55657,
				43.60935
			],
			[
				-79.55692,
				43.61012
			],
			[
				-79.5546,
				43.61068
			],
			[
				-79.55406,
				43.61091
			],
			[
				-79.55273,
				43.61215
			],
			[
				-79.55246,
				43.61255
			],
			[
				-79.55227,
				43.61303
			],
			[
				-79.55241,
				43.61317
			],
			[
				-79.55475,
				43.61384
			],
			[
				-79.55599,
				43.61363
			],
			[
				-79.55597,
				43.61329
			],
			[
				-79.55613,
				43.61313
			],
			[
				-79.55698,
				43.61326
			],
			[
				-79.55783,
				43.61357
			],
			[
				-79.55871,
				43.61351
			],
			[
				-79.55981,
				43.6132
			],
			[
				-79.56114,
				43.61247
			],
			[
				-79.56136,
				43.61221
			],
			[
				-79.56084,
				43.61216
			],
			[
				-79.5606,
				43.61202
			],
			[
				-79.56022,
				43.61194
			],
			[
				-79.56004,
				43.61194
			],
			[
				-79.55949,
				43.61216
			],
			[
				-79.55928,
				43.61255
			],
			[
				-79.55976,
				43.61275
			],
			[
				-79.56016,
				43.61266
			],
			[
				-79.56031,
				43.61251
			],
			[
				-79.56071,
				43.61253
			],
			[
				-79.56081,
				43.61244
			],
			[
				-79.56014,
				43.61192
			],
			[
				-79.5598,
				43.61199
			],
			[
				-79.55938,
				43.61227
			],
			[
				-79.55934,
				43.61252
			],
			[
				-79.55976,
				43.61274
			],
			[
				-79.55999,
				43.61267
			],
			[
				-79.56044,
				43.61271
			],
			[
				-79.56071,
				43.61253
			],
			[
				-79.5607,
				43.6124
			],
			[
				-79.56056,
				43.61224
			],
			[
				-79.56029,
				43.61215
			],
			[
				-79.5594,
				43.61256
			],
			[
				-79.55939,
				43.61269
			],
			[
				-79.55993,
				43.61329
			],
			[
				-79.56035,
				43.61301
			],
			[
				-79.56037,
				43.61291
			],
			[
				-79.56118,
				43.61244
			],
			[
				-79.56139,
				43.61213
			],
			[
				-79.56138,
				43.61187
			],
			[
				-79.56074,
				43.61068
			],
			[
				-79.56036,
				43.61032
			],
			[
				-79.55992,
				43.61007
			],
			[
				-79.56021,
				43.60966
			],
			[
				-79.55993,
				43.60949
			],
			[
				-79.55999,
				43.60928
			],
			[
				-79.55977,
				43.60923
			],
			[
				-79.55978,
				43.60915
			],
			[
				-79.55927,
				43.60885
			],
			[
				-79.55873,
				43.60834
			],
			[
				-79.55777,
				43.60866
			],
			[
				-79.54921,
				43.61059
			],
			[
				-79.5487,
				43.60947
			],
			[
				-79.54868,
				43.60913
			],
			[
				-79.54863,
				43.60904
			],
			[
				-79.54839,
				43.60901
			],
			[
				-79.54738,
				43.6092
			],
			[
				-79.54736,
				43.60947
			],
			[
				-79.54787,
				43.61072
			],
			[
				-79.54747,
				43.61093
			],
			[
				-79.54681,
				43.61092
			],
			[
				-79.54681,
				43.61114
			],
			[
				-79.54672,
				43.61117
			],
			[
				-79.5296,
				43.61503
			],
			[
				-79.53064,
				43.61742
			],
			[
				-79.53227,
				43.61711
			],
			[
				-79.53259,
				43.61768
			],
			[
				-79.53294,
				43.61872
			],
			[
				-79.53325,
				43.6191
			],
			[
				-79.53295,
				43.6193
			],
			[
				-79.53168,
				43.61959
			],
			[
				-79.53173,
				43.61971
			],
			[
				-79.53204,
				43.61988
			],
			[
				-79.53351,
				43.61958
			],
			[
				-79.53419,
				43.61975
			],
			[
				-79.53573,
				43.62348
			],
			[
				-79.53549,
				43.6236
			],
			[
				-79.53319,
				43.62414
			],
			[
				-79.52869,
				43.62516
			],
			[
				-79.52846,
				43.62482
			],
			[
				-79.52788,
				43.62321
			],
			[
				-79.52714,
				43.6218
			],
			[
				-79.5268,
				43.62094
			],
			[
				-79.5263,
				43.62097
			],
			[
				-79.52426,
				43.62152
			],
			[
				-79.52138,
				43.62204
			],
			[
				-79.5193,
				43.62257
			],
			[
				-79.5191,
				43.62255
			],
			[
				-79.5178,
				43.62287
			],
			[
				-79.51706,
				43.62297
			],
			[
				-79.51436,
				43.6236
			],
			[
				-79.51236,
				43.6242
			],
			[
				-79.50927,
				43.62478
			],
			[
				-79.50308,
				43.62621
			],
			[
				-79.50241,
				43.6263
			],
			[
				-79.49962,
				43.62706
			],
			[
				-79.49793,
				43.62731
			],
			[
				-79.49519,
				43.62796
			],
			[
				-79.49036,
				43.62893
			],
			[
				-79.4876,
				43.62962
			],
			[
				-79.48267,
				43.63067
			],
			[
				-79.48176,
				43.6308
			],
			[
				-79.4803,
				43.63125
			],
			[
				-79.47934,
				43.6313
			],
			[
				-79.4774,
				43.63173
			],
			[
				-79.47699,
				43.63189
			],
			[
				-79.47653,
				43.63224
			],
			[
				-79.47584,
				43.63314
			],
			[
				-79.47514,
				43.63381
			],
			[
				-79.47413,
				43.6345
			],
			[
				-79.47377,
				43.63508
			],
			[
				-79.47337,
				43.63548
			],
			[
				-79.47316,
				43.63557
			],
			[
				-79.47314,
				43.63568
			],
			[
				-79.47221,
				43.63636
			],
			[
				-79.47168,
				43.63664
			],
			[
				-79.47124,
				43.63682
			],
			[
				-79.4703,
				43.63704
			],
			[
				-79.46852,
				43.63727
			],
			[
				-79.46624,
				43.63772
			],
			[
				-79.4621,
				43.63896
			],
			[
				-79.45948,
				43.63944
			],
			[
				-79.45773,
				43.6396
			],
			[
				-79.45309,
				43.63963
			],
			[
				-79.4523,
				43.63958
			],
			[
				-79.45094,
				43.6392
			],
			[
				-79.44716,
				43.6385
			],
			[
				-79.44664,
				43.63857
			],
			[
				-79.44569,
				43.6389
			],
			[
				-79.44404,
				43.6391
			],
			[
				-79.43795,
				43.64032
			],
			[
				-79.43716,
				43.6404
			],
			[
				-79.43661,
				43.64062
			],
			[
				-79.43555,
				43.64074
			],
			[
				-79.43484,
				43.64097
			],
			[
				-79.43144,
				43.64161
			],
			[
				-79.43094,
				43.64179
			],
			[
				-79.43016,
				43.64193
			],
			[
				-79.42971,
				43.64194
			],
			[
				-79.42921,
				43.64208
			],
			[
				-79.42818,
				43.64221
			],
			[
				-79.42818,
				43.64189
			],
			[
				-79.42795,
				43.64184
			],
			[
				-79.42706,
				43.64248
			],
			[
				-79.4226,
				43.64343
			],
			[
				-79.4224,
				43.64351
			],
			[
				-79.42241,
				43.64374
			],
			[
				-79.42274,
				43.64439
			],
			[
				-79.42604,
				43.6527
			],
			[
				-79.42522,
				43.6529
			]
		],
		"dupe": false
	},
	{
		"id": 300549750,
		"name": "Lunch Ride",
		"distance": 1905.6,
		"moving_time": 733,
		"elapsed_time": 3380,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-08T12:37:51Z",
		"average_speed": 2.6,
		"max_speed": 8.5,
		"calories": 40.3,
		"points": [
			[
				-79.4292,
				43.64357
			],
			[
				-79.42925,
				43.64345
			],
			[
				-79.42895,
				43.64284
			],
			[
				-79.42881,
				43.64235
			],
			[
				-79.42884,
				43.64222
			],
			[
				-79.4296,
				43.64211
			],
			[
				-79.42977,
				43.64215
			],
			[
				-79.42987,
				43.64249
			],
			[
				-79.42998,
				43.64254
			],
			[
				-79.42985,
				43.64243
			],
			[
				-79.42992,
				43.64258
			],
			[
				-79.42981,
				43.64242
			],
			[
				-79.42988,
				43.64239
			],
			[
				-79.42986,
				43.64272
			],
			[
				-79.42996,
				43.64272
			],
			[
				-79.42977,
				43.64241
			],
			[
				-79.42994,
				43.64252
			],
			[
				-79.43012,
				43.64284
			],
			[
				-79.43264,
				43.64236
			],
			[
				-79.43286,
				43.64276
			],
			[
				-79.43314,
				43.6428
			],
			[
				-79.43422,
				43.64253
			],
			[
				-79.43467,
				43.64252
			],
			[
				-79.43483,
				43.64274
			],
			[
				-79.43502,
				43.64326
			],
			[
				-79.43736,
				43.64277
			],
			[
				-79.43742,
				43.64283
			],
			[
				-79.43738,
				43.64271
			],
			[
				-79.43743,
				43.64282
			],
			[
				-79.43764,
				43.64267
			],
			[
				-79.43872,
				43.64244
			],
			[
				-79.43909,
				43.64346
			],
			[
				-79.43921,
				43.64361
			],
			[
				-79.4421,
				43.64302
			],
			[
				-79.44225,
				43.64294
			],
			[
				-79.44217,
				43.6429
			],
			[
				-79.44223,
				43.64293
			],
			[
				-79.44223,
				43.64285
			],
			[
				-79.44236,
				43.64285
			],
			[
				-79.44217,
				43.64279
			],
			[
				-79.44218,
				43.64289
			]
		],
		"dupe": false
	},
	{
		"id": 301177855,
		"name": "Lunch Ride",
		"distance": 9517.7,
		"moving_time": 1636,
		"elapsed_time": 1673,
		"total_elevation_gain": 15.8,
		"start_date_local": "2015-05-09T12:59:28Z",
		"average_speed": 5.818,
		"max_speed": 11.6,
		"calories": 181,
		"points": [
			[
				-79.42358,
				43.65397
			],
			[
				-79.42351,
				43.65391
			],
			[
				-79.42377,
				43.65421
			],
			[
				-79.4237,
				43.65423
			],
			[
				-79.42362,
				43.65413
			],
			[
				-79.42367,
				43.65414
			],
			[
				-79.42161,
				43.65462
			],
			[
				-79.41852,
				43.65518
			],
			[
				-79.41754,
				43.65519
			],
			[
				-79.41541,
				43.65491
			],
			[
				-79.41387,
				43.65513
			],
			[
				-79.41307,
				43.65536
			],
			[
				-79.41215,
				43.65543
			],
			[
				-79.41186,
				43.65559
			],
			[
				-79.41133,
				43.6556
			],
			[
				-79.40532,
				43.65683
			],
			[
				-79.40362,
				43.65726
			],
			[
				-79.4013,
				43.65763
			],
			[
				-79.40093,
				43.65777
			],
			[
				-79.39985,
				43.65786
			],
			[
				-79.39644,
				43.65857
			],
			[
				-79.39373,
				43.659
			],
			[
				-79.39361,
				43.65914
			],
			[
				-79.39196,
				43.65956
			],
			[
				-79.39096,
				43.65969
			],
			[
				-79.39087,
				43.65964
			],
			[
				-79.39086,
				43.65975
			],
			[
				-79.3908,
				43.6597
			],
			[
				-79.39027,
				43.65976
			],
			[
				-79.38834,
				43.66016
			],
			[
				-79.38735,
				43.66015
			],
			[
				-79.38659,
				43.66041
			],
			[
				-79.38648,
				43.66064
			],
			[
				-79.38326,
				43.66129
			],
			[
				-79.38324,
				43.66135
			],
			[
				-79.38334,
				43.66128
			],
			[
				-79.38328,
				43.66138
			],
			[
				-79.38241,
				43.66128
			],
			[
				-79.3813,
				43.66137
			],
			[
				-79.37307,
				43.66317
			],
			[
				-79.37225,
				43.66124
			],
			[
				-79.37166,
				43.66131
			],
			[
				-79.3687,
				43.66202
			],
			[
				-79.36801,
				43.66207
			],
			[
				-79.36713,
				43.66188
			],
			[
				-79.36419,
				43.66249
			],
			[
				-79.36315,
				43.66283
			],
			[
				-79.35891,
				43.6637
			],
			[
				-79.35548,
				43.66473
			],
			[
				-79.354,
				43.66509
			],
			[
				-79.35285,
				43.66548
			],
			[
				-79.35079,
				43.66577
			],
			[
				-79.34882,
				43.6663
			],
			[
				-79.34859,
				43.66628
			],
			[
				-79.3449,
				43.66705
			],
			[
				-79.33985,
				43.66825
			],
			[
				-79.33509,
				43.66925
			],
			[
				-79.33402,
				43.66956
			],
			[
				-79.33124,
				43.6701
			],
			[
				-79.33065,
				43.67031
			],
			[
				-79.32844,
				43.6708
			],
			[
				-79.32804,
				43.67082
			],
			[
				-79.32815,
				43.67085
			],
			[
				-79.32799,
				43.67071
			],
			[
				-79.32665,
				43.66764
			],
			[
				-79.32553,
				43.66761
			],
			[
				-79.32501,
				43.66772
			],
			[
				-79.32288,
				43.66774
			],
			[
				-79.32188,
				43.66787
			],
			[
				-79.32194,
				43.66795
			],
			[
				-79.32294,
				43.66779
			],
			[
				-79.3248,
				43.66772
			],
			[
				-79.32473,
				43.66774
			]
		],
		"dupe": false
	},
	{
		"id": 301268588,
		"name": "Afternoon Ride",
		"distance": 9863.4,
		"moving_time": 1445,
		"elapsed_time": 1522,
		"total_elevation_gain": 10.4,
		"start_date_local": "2015-05-09T15:27:01Z",
		"average_speed": 6.826,
		"max_speed": 13.8,
		"calories": 215.2,
		"points": [
			[
				-79.32451,
				43.66722
			],
			[
				-79.32431,
				43.66725
			],
			[
				-79.32429,
				43.66741
			],
			[
				-79.32445,
				43.6673
			],
			[
				-79.32428,
				43.66723
			],
			[
				-79.3243,
				43.66716
			],
			[
				-79.3241,
				43.6672
			],
			[
				-79.32405,
				43.66685
			],
			[
				-79.32461,
				43.66664
			],
			[
				-79.32554,
				43.66651
			],
			[
				-79.32603,
				43.66628
			],
			[
				-79.32606,
				43.66618
			],
			[
				-79.32587,
				43.66587
			],
			[
				-79.32576,
				43.66539
			],
			[
				-79.32538,
				43.6645
			],
			[
				-79.32548,
				43.66439
			],
			[
				-79.34304,
				43.66043
			],
			[
				-79.34404,
				43.66027
			],
			[
				-79.34969,
				43.65894
			],
			[
				-79.34982,
				43.65886
			],
			[
				-79.34978,
				43.65866
			],
			[
				-79.34928,
				43.65749
			],
			[
				-79.3492,
				43.65709
			],
			[
				-79.34928,
				43.65698
			],
			[
				-79.35068,
				43.65707
			],
			[
				-79.35213,
				43.6569
			],
			[
				-79.35448,
				43.65611
			],
			[
				-79.35541,
				43.65561
			],
			[
				-79.35627,
				43.65527
			],
			[
				-79.35733,
				43.65502
			],
			[
				-79.35853,
				43.655
			],
			[
				-79.35969,
				43.65523
			],
			[
				-79.36049,
				43.65529
			],
			[
				-79.36156,
				43.6552
			],
			[
				-79.3639,
				43.65471
			],
			[
				-79.36457,
				43.65446
			],
			[
				-79.36586,
				43.65425
			],
			[
				-79.36632,
				43.65411
			],
			[
				-79.36637,
				43.65401
			],
			[
				-79.37224,
				43.65267
			],
			[
				-79.37335,
				43.6528
			],
			[
				-79.37408,
				43.65276
			],
			[
				-79.38569,
				43.65019
			],
			[
				-79.38593,
				43.6502
			],
			[
				-79.38708,
				43.64991
			],
			[
				-79.38708,
				43.64982
			],
			[
				-79.38731,
				43.64986
			],
			[
				-79.39234,
				43.64877
			],
			[
				-79.39328,
				43.64839
			],
			[
				-79.39547,
				43.64791
			],
			[
				-79.39681,
				43.64785
			],
			[
				-79.40374,
				43.64647
			],
			[
				-79.40461,
				43.64638
			],
			[
				-79.40746,
				43.64574
			],
			[
				-79.40961,
				43.64539
			],
			[
				-79.41002,
				43.64607
			],
			[
				-79.41434,
				43.6452
			],
			[
				-79.41569,
				43.64486
			],
			[
				-79.41667,
				43.64473
			],
			[
				-79.41852,
				43.64429
			],
			[
				-79.42032,
				43.64399
			],
			[
				-79.42209,
				43.64355
			],
			[
				-79.42452,
				43.64314
			],
			[
				-79.42664,
				43.64268
			],
			[
				-79.427,
				43.64264
			],
			[
				-79.42722,
				43.64273
			],
			[
				-79.4277,
				43.64396
			],
			[
				-79.42779,
				43.64404
			],
			[
				-79.42914,
				43.64383
			],
			[
				-79.42921,
				43.64384
			],
			[
				-79.42925,
				43.64399
			],
			[
				-79.42937,
				43.64398
			],
			[
				-79.42936,
				43.64409
			]
		],
		"dupe": false
	},
	{
		"id": 301937830,
		"name": "#soaked",
		"distance": 48103.6,
		"moving_time": 6571,
		"elapsed_time": 8054,
		"total_elevation_gain": 147.4,
		"start_date_local": "2015-05-10T11:11:16Z",
		"average_speed": 7.321,
		"max_speed": 15.3,
		"calories": 1017.9,
		"points": [
			[
				-79.42594,
				43.65243
			],
			[
				-79.42243,
				43.64358
			],
			[
				-79.42636,
				43.64267
			],
			[
				-79.42808,
				43.64243
			],
			[
				-79.44233,
				43.63956
			],
			[
				-79.44281,
				43.63939
			],
			[
				-79.4461,
				43.63883
			],
			[
				-79.4469,
				43.63862
			],
			[
				-79.4475,
				43.63867
			],
			[
				-79.45076,
				43.6393
			],
			[
				-79.4528,
				43.63983
			],
			[
				-79.45786,
				43.63979
			],
			[
				-79.45942,
				43.63966
			],
			[
				-79.46094,
				43.63941
			],
			[
				-79.46254,
				43.63903
			],
			[
				-79.46645,
				43.63788
			],
			[
				-79.46824,
				43.63753
			],
			[
				-79.47047,
				43.63725
			],
			[
				-79.47148,
				43.63696
			],
			[
				-79.47265,
				43.63636
			],
			[
				-79.47345,
				43.63577
			],
			[
				-79.47442,
				43.63467
			],
			[
				-79.47666,
				43.6326
			],
			[
				-79.47711,
				43.63237
			],
			[
				-79.47971,
				43.63149
			],
			[
				-79.48198,
				43.63087
			],
			[
				-79.48309,
				43.63069
			],
			[
				-79.49429,
				43.62819
			],
			[
				-79.49523,
				43.62807
			],
			[
				-79.49743,
				43.62753
			],
			[
				-79.50242,
				43.62648
			],
			[
				-79.50236,
				43.62647
			],
			[
				-79.50254,
				43.62645
			],
			[
				-79.50285,
				43.62695
			],
			[
				-79.5029,
				43.62727
			],
			[
				-79.50355,
				43.62848
			],
			[
				-79.50378,
				43.62926
			],
			[
				-79.50527,
				43.63269
			],
			[
				-79.50608,
				43.63493
			],
			[
				-79.50708,
				43.63696
			],
			[
				-79.51893,
				43.63429
			],
			[
				-79.52076,
				43.63378
			],
			[
				-79.53101,
				43.6315
			],
			[
				-79.53103,
				43.63129
			],
			[
				-79.52907,
				43.62662
			],
			[
				-79.52865,
				43.62525
			],
			[
				-79.52891,
				43.62511
			],
			[
				-79.53575,
				43.62354
			],
			[
				-79.54841,
				43.62071
			],
			[
				-79.54948,
				43.62054
			],
			[
				-79.55688,
				43.61878
			],
			[
				-79.55739,
				43.61858
			],
			[
				-79.55767,
				43.61827
			],
			[
				-79.55773,
				43.61795
			],
			[
				-79.55707,
				43.61612
			],
			[
				-79.55765,
				43.61592
			],
			[
				-79.55865,
				43.61535
			],
			[
				-79.5591,
				43.61471
			],
			[
				-79.55889,
				43.6144
			],
			[
				-79.55896,
				43.6142
			],
			[
				-79.55999,
				43.6139
			],
			[
				-79.56091,
				43.61351
			],
			[
				-79.56766,
				43.60958
			],
			[
				-79.56866,
				43.60892
			],
			[
				-79.57,
				43.60736
			],
			[
				-79.57599,
				43.60217
			],
			[
				-79.57725,
				43.60113
			],
			[
				-79.57922,
				43.59984
			],
			[
				-79.59306,
				43.58792
			],
			[
				-79.59428,
				43.58671
			],
			[
				-79.5951,
				43.58528
			],
			[
				-79.59525,
				43.58512
			],
			[
				-79.59552,
				43.58521
			],
			[
				-79.59592,
				43.58485
			],
			[
				-79.59614,
				43.5844
			],
			[
				-79.59607,
				43.58428
			],
			[
				-79.59934,
				43.58148
			],
			[
				-79.59948,
				43.58127
			],
			[
				-79.59987,
				43.58102
			],
			[
				-79.60334,
				43.57803
			],
			[
				-79.6036,
				43.5777
			],
			[
				-79.60447,
				43.57707
			],
			[
				-79.60616,
				43.5756
			],
			[
				-79.60797,
				43.57389
			],
			[
				-79.60984,
				43.57233
			],
			[
				-79.61236,
				43.56995
			],
			[
				-79.62243,
				43.56098
			],
			[
				-79.62254,
				43.56079
			],
			[
				-79.62454,
				43.55911
			],
			[
				-79.63323,
				43.55144
			],
			[
				-79.63352,
				43.55111
			],
			[
				-79.63421,
				43.55122
			],
			[
				-79.63421,
				43.55151
			],
			[
				-79.63457,
				43.55162
			],
			[
				-79.63776,
				43.55385
			],
			[
				-79.63763,
				43.55407
			],
			[
				-79.63621,
				43.55536
			],
			[
				-79.63595,
				43.55552
			],
			[
				-79.63549,
				43.55562
			],
			[
				-79.63554,
				43.55586
			],
			[
				-79.6363,
				43.55531
			],
			[
				-79.63654,
				43.55528
			],
			[
				-79.63919,
				43.55708
			],
			[
				-79.63877,
				43.55687
			],
			[
				-79.63812,
				43.55726
			],
			[
				-79.63769,
				43.55768
			],
			[
				-79.63725,
				43.55843
			],
			[
				-79.63671,
				43.55847
			],
			[
				-79.63637,
				43.55895
			],
			[
				-79.63527,
				43.55971
			],
			[
				-79.63395,
				43.55876
			],
			[
				-79.63304,
				43.55861
			],
			[
				-79.63277,
				43.55865
			],
			[
				-79.63205,
				43.55824
			],
			[
				-79.63164,
				43.55836
			],
			[
				-79.63162,
				43.55862
			],
			[
				-79.63297,
				43.55962
			],
			[
				-79.63237,
				43.56009
			],
			[
				-79.63166,
				43.55965
			],
			[
				-79.63133,
				43.55971
			],
			[
				-79.62829,
				43.56235
			],
			[
				-79.62787,
				43.56291
			],
			[
				-79.62783,
				43.56309
			],
			[
				-79.62792,
				43.56335
			],
			[
				-79.63018,
				43.56414
			],
			[
				-79.63181,
				43.56525
			],
			[
				-79.63226,
				43.56569
			],
			[
				-79.63247,
				43.56579
			],
			[
				-79.63257,
				43.56596
			],
			[
				-79.63247,
				43.56625
			],
			[
				-79.63082,
				43.56785
			],
			[
				-79.63022,
				43.5683
			],
			[
				-79.62812,
				43.57023
			],
			[
				-79.62578,
				43.57218
			],
			[
				-79.62534,
				43.57243
			],
			[
				-79.62355,
				43.5739
			],
			[
				-79.60938,
				43.58619
			],
			[
				-79.60382,
				43.5912
			],
			[
				-79.60047,
				43.5941
			],
			[
				-79.59967,
				43.59492
			],
			[
				-79.59867,
				43.59571
			],
			[
				-79.59851,
				43.59572
			],
			[
				-79.59836,
				43.59584
			],
			[
				-79.59839,
				43.59596
			],
			[
				-79.5899,
				43.60381
			],
			[
				-79.58417,
				43.60893
			],
			[
				-79.58375,
				43.60905
			],
			[
				-79.58353,
				43.60928
			],
			[
				-79.58359,
				43.6094
			],
			[
				-79.58037,
				43.6123
			],
			[
				-79.57375,
				43.61854
			],
			[
				-79.57359,
				43.61862
			],
			[
				-79.57231,
				43.61993
			],
			[
				-79.56631,
				43.62564
			],
			[
				-79.56535,
				43.62633
			],
			[
				-79.56445,
				43.62672
			],
			[
				-79.56161,
				43.62741
			],
			[
				-79.55857,
				43.62797
			],
			[
				-79.55614,
				43.62865
			],
			[
				-79.55556,
				43.62872
			],
			[
				-79.55041,
				43.63017
			],
			[
				-79.54747,
				43.63081
			],
			[
				-79.54656,
				43.63109
			],
			[
				-79.54534,
				43.63166
			],
			[
				-79.54331,
				43.63279
			],
			[
				-79.54122,
				43.63468
			],
			[
				-79.5405,
				43.6354
			],
			[
				-79.53701,
				43.63933
			],
			[
				-79.5369,
				43.63956
			],
			[
				-79.53667,
				43.63952
			],
			[
				-79.53618,
				43.6399
			],
			[
				-79.53606,
				43.64004
			],
			[
				-79.53611,
				43.64026
			],
			[
				-79.53224,
				43.64257
			],
			[
				-79.53086,
				43.64299
			],
			[
				-79.52894,
				43.64337
			],
			[
				-79.52771,
				43.6437
			],
			[
				-79.52364,
				43.64451
			],
			[
				-79.52362,
				43.64457
			],
			[
				-79.52077,
				43.64519
			],
			[
				-79.52019,
				43.64538
			],
			[
				-79.51726,
				43.64595
			],
			[
				-79.51462,
				43.6466
			],
			[
				-79.51324,
				43.64683
			],
			[
				-79.51272,
				43.647
			],
			[
				-79.50561,
				43.64852
			],
			[
				-79.50363,
				43.64904
			],
			[
				-79.49957,
				43.64993
			],
			[
				-79.49842,
				43.64998
			],
			[
				-79.49631,
				43.64979
			],
			[
				-79.49422,
				43.64919
			],
			[
				-79.49392,
				43.649
			],
			[
				-79.49039,
				43.64782
			],
			[
				-79.48819,
				43.64763
			],
			[
				-79.48773,
				43.64766
			],
			[
				-79.4863,
				43.64797
			],
			[
				-79.48587,
				43.64815
			],
			[
				-79.48467,
				43.64912
			],
			[
				-79.48313,
				43.64959
			],
			[
				-79.48101,
				43.64999
			],
			[
				-79.47849,
				43.65064
			],
			[
				-79.47764,
				43.65072
			],
			[
				-79.47429,
				43.65147
			],
			[
				-79.47372,
				43.65166
			],
			[
				-79.47002,
				43.65241
			],
			[
				-79.46646,
				43.65328
			],
			[
				-79.46576,
				43.65334
			],
			[
				-79.4633,
				43.65396
			],
			[
				-79.45969,
				43.65466
			],
			[
				-79.45695,
				43.65529
			],
			[
				-79.45649,
				43.65546
			],
			[
				-79.45258,
				43.65625
			],
			[
				-79.45252,
				43.65625
			],
			[
				-79.45244,
				43.65602
			],
			[
				-79.45238,
				43.65543
			],
			[
				-79.4521,
				43.65475
			],
			[
				-79.45187,
				43.65381
			],
			[
				-79.44922,
				43.65262
			],
			[
				-79.44771,
				43.65185
			],
			[
				-79.44706,
				43.65158
			],
			[
				-79.4453,
				43.65109
			],
			[
				-79.44151,
				43.65028
			],
			[
				-79.44109,
				43.65025
			],
			[
				-79.44076,
				43.65037
			],
			[
				-79.44014,
				43.65077
			]
		],
		"dupe": false
	},
	{
		"id": 302494899,
		"name": "Afternoon Ride",
		"distance": 3164.7,
		"moving_time": 735,
		"elapsed_time": 735,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-11T13:08:49Z",
		"average_speed": 4.306,
		"max_speed": 12.4,
		"calories": 54,
		"points": [
			[
				-79.45064,
				43.65027
			],
			[
				-79.45027,
				43.64916
			],
			[
				-79.45001,
				43.6487
			],
			[
				-79.44968,
				43.64772
			],
			[
				-79.4494,
				43.64716
			],
			[
				-79.44933,
				43.64683
			],
			[
				-79.44911,
				43.64653
			],
			[
				-79.44919,
				43.64647
			],
			[
				-79.44921,
				43.64627
			],
			[
				-79.44908,
				43.64619
			],
			[
				-79.44888,
				43.64572
			],
			[
				-79.44889,
				43.64554
			],
			[
				-79.44881,
				43.64547
			],
			[
				-79.44831,
				43.6456
			],
			[
				-79.44821,
				43.64553
			],
			[
				-79.44357,
				43.64645
			],
			[
				-79.44288,
				43.64477
			],
			[
				-79.44271,
				43.64474
			],
			[
				-79.43985,
				43.6453
			],
			[
				-79.43879,
				43.64257
			],
			[
				-79.4385,
				43.64249
			],
			[
				-79.43551,
				43.6431
			],
			[
				-79.43503,
				43.64329
			],
			[
				-79.43476,
				43.64258
			],
			[
				-79.43446,
				43.64247
			],
			[
				-79.43288,
				43.64277
			],
			[
				-79.43272,
				43.64237
			],
			[
				-79.43012,
				43.64284
			],
			[
				-79.42974,
				43.64203
			],
			[
				-79.42879,
				43.64216
			],
			[
				-79.42872,
				43.64224
			],
			[
				-79.4291,
				43.64261
			],
			[
				-79.42907,
				43.64336
			],
			[
				-79.42919,
				43.64348
			],
			[
				-79.4294,
				43.64406
			],
			[
				-79.42935,
				43.64414
			],
			[
				-79.42946,
				43.64416
			],
			[
				-79.42952,
				43.64434
			],
			[
				-79.42945,
				43.64437
			],
			[
				-79.4297,
				43.64433
			],
			[
				-79.4295,
				43.6444
			]
		],
		"dupe": false
	},
	{
		"id": 302394164,
		"name": "Morning Ride",
		"distance": 521.2,
		"moving_time": 91,
		"elapsed_time": 91,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-11T10:15:15Z",
		"average_speed": 5.727,
		"max_speed": 10.1,
		"calories": 12.6,
		"points": [
			[
				-79.42509,
				43.64291
			],
			[
				-79.42712,
				43.64256
			],
			[
				-79.42723,
				43.64268
			],
			[
				-79.42773,
				43.644
			],
			[
				-79.42791,
				43.64405
			],
			[
				-79.42914,
				43.64383
			],
			[
				-79.42941,
				43.64403
			],
			[
				-79.42945,
				43.64421
			]
		],
		"dupe": false
	},
	{
		"id": 303425012,
		"name": "Night Ride",
		"distance": 1024.2,
		"moving_time": 141,
		"elapsed_time": 141,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-12T21:22:16Z",
		"average_speed": 7.264,
		"max_speed": 11.2,
		"calories": 23.3,
		"points": [
			[
				-79.44014,
				43.63991
			],
			[
				-79.44026,
				43.63986
			],
			[
				-79.44021,
				43.63982
			],
			[
				-79.44031,
				43.63984
			],
			[
				-79.43873,
				43.64019
			],
			[
				-79.43701,
				43.64046
			],
			[
				-79.43656,
				43.64067
			],
			[
				-79.43521,
				43.64083
			],
			[
				-79.43474,
				43.64098
			],
			[
				-79.43171,
				43.64153
			],
			[
				-79.43108,
				43.64172
			],
			[
				-79.42906,
				43.64211
			],
			[
				-79.42884,
				43.64207
			],
			[
				-79.42865,
				43.64224
			]
		],
		"dupe": false
	},
	{
		"id": 304081678,
		"name": "Evening Ride",
		"distance": 18725.2,
		"moving_time": 2791,
		"elapsed_time": 2854,
		"total_elevation_gain": 49.4,
		"start_date_local": "2015-05-13T19:41:08Z",
		"average_speed": 6.709,
		"max_speed": 15.5,
		"calories": 381.2,
		"points": [
			[
				-79.42854,
				43.64194
			],
			[
				-79.42856,
				43.64165
			],
			[
				-79.42764,
				43.63927
			],
			[
				-79.42736,
				43.63888
			],
			[
				-79.4252,
				43.63329
			],
			[
				-79.42487,
				43.63263
			],
			[
				-79.42332,
				43.63297
			],
			[
				-79.42153,
				43.6323
			],
			[
				-79.41996,
				43.6327
			],
			[
				-79.41955,
				43.63203
			],
			[
				-79.41937,
				43.63139
			],
			[
				-79.4189,
				43.63144
			],
			[
				-79.41833,
				43.63135
			],
			[
				-79.41876,
				43.63082
			],
			[
				-79.41852,
				43.63025
			],
			[
				-79.41857,
				43.63005
			],
			[
				-79.4189,
				43.62995
			],
			[
				-79.41956,
				43.63025
			],
			[
				-79.42,
				43.63034
			],
			[
				-79.42543,
				43.62994
			],
			[
				-79.42669,
				43.63031
			],
			[
				-79.42745,
				43.63034
			],
			[
				-79.4279,
				43.63003
			],
			[
				-79.42952,
				43.63018
			],
			[
				-79.43187,
				43.6306
			],
			[
				-79.43371,
				43.63104
			],
			[
				-79.43477,
				43.6316
			],
			[
				-79.43534,
				43.63179
			],
			[
				-79.43569,
				43.63199
			],
			[
				-79.43589,
				43.63229
			],
			[
				-79.4362,
				43.63244
			],
			[
				-79.43744,
				43.63349
			],
			[
				-79.43811,
				43.63385
			],
			[
				-79.43813,
				43.63394
			],
			[
				-79.44144,
				43.63542
			],
			[
				-79.4417,
				43.63564
			],
			[
				-79.44579,
				43.63717
			],
			[
				-79.44641,
				43.63725
			],
			[
				-79.4468,
				43.63685
			],
			[
				-79.44761,
				43.63687
			],
			[
				-79.451,
				43.63757
			],
			[
				-79.45217,
				43.63769
			],
			[
				-79.45272,
				43.63783
			],
			[
				-79.45314,
				43.6377
			],
			[
				-79.45359,
				43.63786
			],
			[
				-79.45513,
				43.63803
			],
			[
				-79.45563,
				43.63791
			],
			[
				-79.45613,
				43.63804
			],
			[
				-79.45789,
				43.63783
			],
			[
				-79.45948,
				43.63747
			],
			[
				-79.46022,
				43.63709
			],
			[
				-79.46127,
				43.63689
			],
			[
				-79.46168,
				43.63665
			],
			[
				-79.46267,
				43.63635
			],
			[
				-79.4632,
				43.63625
			],
			[
				-79.46383,
				43.63631
			],
			[
				-79.46432,
				43.63626
			],
			[
				-79.46618,
				43.63568
			],
			[
				-79.46656,
				43.63518
			],
			[
				-79.46781,
				43.63468
			],
			[
				-79.46855,
				43.63426
			],
			[
				-79.46909,
				43.63381
			],
			[
				-79.46939,
				43.63327
			],
			[
				-79.46961,
				43.63304
			],
			[
				-79.47069,
				43.63259
			],
			[
				-79.47088,
				43.63226
			],
			[
				-79.4713,
				43.63193
			],
			[
				-79.47173,
				43.63142
			],
			[
				-79.47214,
				43.63112
			],
			[
				-79.47216,
				43.63103
			],
			[
				-79.4717,
				43.63087
			],
			[
				-79.47125,
				43.63079
			],
			[
				-79.47101,
				43.63092
			],
			[
				-79.47196,
				43.63172
			],
			[
				-79.47371,
				43.63274
			],
			[
				-79.47432,
				43.63305
			],
			[
				-79.47445,
				43.6329
			],
			[
				-79.47446,
				43.63318
			],
			[
				-79.47481,
				43.63339
			],
			[
				-79.47492,
				43.63342
			],
			[
				-79.47539,
				43.63322
			],
			[
				-79.47591,
				43.63347
			],
			[
				-79.476,
				43.63342
			],
			[
				-79.47633,
				43.63349
			],
			[
				-79.47632,
				43.63376
			],
			[
				-79.47651,
				43.63394
			],
			[
				-79.4766,
				43.63421
			],
			[
				-79.47781,
				43.63571
			],
			[
				-79.47851,
				43.63632
			],
			[
				-79.47897,
				43.63659
			],
			[
				-79.47964,
				43.63659
			],
			[
				-79.48105,
				43.63635
			],
			[
				-79.48228,
				43.63652
			],
			[
				-79.48278,
				43.63642
			],
			[
				-79.48355,
				43.63612
			],
			[
				-79.4838,
				43.63595
			],
			[
				-79.48403,
				43.63557
			],
			[
				-79.4848,
				43.63553
			],
			[
				-79.48577,
				43.63511
			],
			[
				-79.48623,
				43.63525
			],
			[
				-79.48632,
				43.63551
			],
			[
				-79.48643,
				43.63556
			],
			[
				-79.4881,
				43.63956
			],
			[
				-79.48891,
				43.63952
			],
			[
				-79.48957,
				43.63974
			],
			[
				-79.49015,
				43.64006
			],
			[
				-79.49031,
				43.64048
			],
			[
				-79.49057,
				43.64071
			],
			[
				-79.49235,
				43.64129
			],
			[
				-79.49275,
				43.64172
			],
			[
				-79.49305,
				43.64264
			],
			[
				-79.49296,
				43.64309
			],
			[
				-79.4927,
				43.64369
			],
			[
				-79.49266,
				43.64424
			],
			[
				-79.49272,
				43.64435
			],
			[
				-79.49263,
				43.64475
			],
			[
				-79.49275,
				43.64496
			],
			[
				-79.49407,
				43.64601
			],
			[
				-79.4957,
				43.64627
			],
			[
				-79.496,
				43.6464
			],
			[
				-79.49622,
				43.64669
			],
			[
				-79.496,
				43.6469
			],
			[
				-79.49585,
				43.64767
			],
			[
				-79.49568,
				43.64796
			],
			[
				-79.49536,
				43.64812
			],
			[
				-79.49446,
				43.64826
			],
			[
				-79.49417,
				43.64839
			],
			[
				-79.49356,
				43.64891
			],
			[
				-79.49304,
				43.64966
			],
			[
				-79.49209,
				43.65001
			],
			[
				-79.49188,
				43.6502
			],
			[
				-79.49179,
				43.65038
			],
			[
				-79.49181,
				43.65054
			],
			[
				-79.49235,
				43.65112
			],
			[
				-79.49086,
				43.65155
			],
			[
				-79.48979,
				43.65161
			],
			[
				-79.48997,
				43.65239
			],
			[
				-79.49029,
				43.65299
			],
			[
				-79.49088,
				43.65341
			],
			[
				-79.49137,
				43.65349
			],
			[
				-79.49278,
				43.65506
			],
			[
				-79.49289,
				43.65539
			],
			[
				-79.49293,
				43.65598
			],
			[
				-79.49269,
				43.65672
			],
			[
				-79.49256,
				43.65763
			],
			[
				-79.49175,
				43.65769
			],
			[
				-79.48843,
				43.65842
			],
			[
				-79.48819,
				43.65824
			],
			[
				-79.47548,
				43.66105
			],
			[
				-79.47472,
				43.66128
			],
			[
				-79.47389,
				43.66179
			],
			[
				-79.47337,
				43.662
			],
			[
				-79.46521,
				43.66378
			],
			[
				-79.46472,
				43.6638
			],
			[
				-79.46273,
				43.66343
			],
			[
				-79.45953,
				43.6641
			],
			[
				-79.45884,
				43.6644
			],
			[
				-79.45674,
				43.66316
			],
			[
				-79.45455,
				43.66095
			],
			[
				-79.45404,
				43.66034
			],
			[
				-79.4533,
				43.65881
			],
			[
				-79.45287,
				43.65772
			],
			[
				-79.45287,
				43.65737
			],
			[
				-79.45267,
				43.6569
			],
			[
				-79.4518,
				43.6536
			],
			[
				-79.45156,
				43.65358
			],
			[
				-79.44841,
				43.6522
			],
			[
				-79.44646,
				43.65144
			],
			[
				-79.44113,
				43.6502
			],
			[
				-79.4405,
				43.65021
			],
			[
				-79.43992,
				43.6501
			],
			[
				-79.4394,
				43.65016
			],
			[
				-79.43757,
				43.64996
			],
			[
				-79.43571,
				43.64993
			],
			[
				-79.43326,
				43.64965
			],
			[
				-79.43156,
				43.64956
			],
			[
				-79.43165,
				43.64958
			],
			[
				-79.43133,
				43.64901
			],
			[
				-79.43096,
				43.64795
			],
			[
				-79.43036,
				43.6467
			],
			[
				-79.43004,
				43.64553
			],
			[
				-79.42975,
				43.6451
			],
			[
				-79.42965,
				43.64481
			]
		],
		"dupe": false
	},
	{
		"id": 305068414,
		"name": "Lunch Ride",
		"distance": 3075.1,
		"moving_time": 727,
		"elapsed_time": 820,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-15T12:22:15Z",
		"average_speed": 4.23,
		"max_speed": 9.6,
		"calories": 55.6,
		"points": [
			[
				-79.42933,
				43.64423
			],
			[
				-79.42939,
				43.64423
			],
			[
				-79.42928,
				43.64396
			],
			[
				-79.42912,
				43.64383
			],
			[
				-79.42775,
				43.64402
			],
			[
				-79.4271,
				43.64241
			],
			[
				-79.4263,
				43.64268
			],
			[
				-79.42436,
				43.64298
			],
			[
				-79.4228,
				43.64342
			],
			[
				-79.42202,
				43.6435
			],
			[
				-79.41964,
				43.64403
			],
			[
				-79.41886,
				43.64408
			],
			[
				-79.41633,
				43.64462
			],
			[
				-79.41564,
				43.64309
			],
			[
				-79.41505,
				43.64144
			],
			[
				-79.41497,
				43.64139
			],
			[
				-79.40964,
				43.64242
			],
			[
				-79.40838,
				43.64276
			],
			[
				-79.40744,
				43.64288
			],
			[
				-79.40334,
				43.64369
			],
			[
				-79.4032,
				43.64373
			],
			[
				-79.40307,
				43.64394
			],
			[
				-79.40169,
				43.64404
			],
			[
				-79.40103,
				43.64416
			],
			[
				-79.40066,
				43.64431
			],
			[
				-79.4008,
				43.64423
			],
			[
				-79.40083,
				43.64417
			],
			[
				-79.40075,
				43.64417
			],
			[
				-79.40081,
				43.64419
			],
			[
				-79.40083,
				43.64414
			],
			[
				-79.40076,
				43.64416
			],
			[
				-79.40081,
				43.64406
			],
			[
				-79.40079,
				43.6442
			],
			[
				-79.40073,
				43.64418
			]
		],
		"dupe": false
	},
	{
		"id": 305091971,
		"name": "Afternoon Ride",
		"distance": 2837.6,
		"moving_time": 485,
		"elapsed_time": 485,
		"total_elevation_gain": 10.9,
		"start_date_local": "2015-05-15T13:06:17Z",
		"average_speed": 5.851,
		"max_speed": 12,
		"calories": 59.8,
		"points": [
			[
				-79.40117,
				43.64414
			],
			[
				-79.40118,
				43.64424
			],
			[
				-79.40219,
				43.64393
			],
			[
				-79.40248,
				43.64398
			],
			[
				-79.40317,
				43.64384
			],
			[
				-79.40359,
				43.64388
			],
			[
				-79.40398,
				43.64362
			],
			[
				-79.40471,
				43.64359
			],
			[
				-79.40601,
				43.64321
			],
			[
				-79.40741,
				43.64295
			],
			[
				-79.40774,
				43.64297
			],
			[
				-79.41123,
				43.6422
			],
			[
				-79.41492,
				43.64154
			],
			[
				-79.41485,
				43.64153
			],
			[
				-79.41512,
				43.64166
			],
			[
				-79.41631,
				43.64468
			],
			[
				-79.41646,
				43.64471
			],
			[
				-79.41988,
				43.644
			],
			[
				-79.42066,
				43.64392
			],
			[
				-79.42291,
				43.64347
			],
			[
				-79.42714,
				43.64255
			],
			[
				-79.42773,
				43.644
			],
			[
				-79.42793,
				43.64404
			],
			[
				-79.42917,
				43.64383
			],
			[
				-79.42953,
				43.64429
			]
		],
		"dupe": false
	},
	{
		"id": 305506650,
		"name": "Ride to the Island Airport",
		"distance": 4118,
		"moving_time": 719,
		"elapsed_time": 741,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-16T07:48:50Z",
		"average_speed": 5.727,
		"max_speed": 10.2,
		"calories": 68,
		"points": [
			[
				-79.42313,
				43.65336
			],
			[
				-79.42312,
				43.6533
			],
			[
				-79.42284,
				43.65333
			],
			[
				-79.42248,
				43.65348
			],
			[
				-79.42238,
				43.65345
			],
			[
				-79.42224,
				43.65296
			],
			[
				-79.4219,
				43.6523
			],
			[
				-79.42174,
				43.65173
			],
			[
				-79.42135,
				43.65086
			],
			[
				-79.42112,
				43.65005
			],
			[
				-79.42095,
				43.64978
			],
			[
				-79.42072,
				43.64889
			],
			[
				-79.42016,
				43.64769
			],
			[
				-79.41959,
				43.64591
			],
			[
				-79.4192,
				43.64509
			],
			[
				-79.41896,
				43.64431
			],
			[
				-79.4187,
				43.64428
			],
			[
				-79.41863,
				43.64416
			],
			[
				-79.41837,
				43.64427
			],
			[
				-79.41632,
				43.64465
			],
			[
				-79.41502,
				43.64142
			],
			[
				-79.41393,
				43.64156
			],
			[
				-79.41289,
				43.64187
			],
			[
				-79.41214,
				43.64195
			],
			[
				-79.41102,
				43.64224
			],
			[
				-79.41078,
				43.64222
			],
			[
				-79.40802,
				43.6429
			],
			[
				-79.40593,
				43.6432
			],
			[
				-79.40405,
				43.64366
			],
			[
				-79.40351,
				43.64367
			],
			[
				-79.40252,
				43.64395
			],
			[
				-79.40262,
				43.64375
			],
			[
				-79.40236,
				43.64287
			],
			[
				-79.40181,
				43.64169
			],
			[
				-79.40159,
				43.64097
			],
			[
				-79.40095,
				43.63951
			],
			[
				-79.4003,
				43.63765
			],
			[
				-79.39983,
				43.63657
			],
			[
				-79.3995,
				43.63619
			],
			[
				-79.39852,
				43.63574
			],
			[
				-79.39793,
				43.63522
			],
			[
				-79.39717,
				43.63434
			],
			[
				-79.3978,
				43.63402
			],
			[
				-79.3976,
				43.63373
			]
		],
		"dupe": false
	},
	{
		"id": 306427625,
		"name": "Lunch Ride",
		"distance": 1474.2,
		"moving_time": 336,
		"elapsed_time": 364,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-17T11:43:19Z",
		"average_speed": 4.388,
		"max_speed": 9.9,
		"calories": 29.9,
		"points": [
			[
				-73.99215,
				40.73713
			],
			[
				-73.99215,
				40.73701
			],
			[
				-73.99201,
				40.73709
			],
			[
				-73.99207,
				40.73698
			],
			[
				-73.99191,
				40.73693
			],
			[
				-73.99104,
				40.73679
			],
			[
				-73.99089,
				40.73683
			],
			[
				-73.99075,
				40.73662
			],
			[
				-73.99086,
				40.73641
			],
			[
				-73.99123,
				40.73607
			],
			[
				-73.99116,
				40.73601
			],
			[
				-73.99119,
				40.73593
			],
			[
				-73.99172,
				40.73538
			],
			[
				-73.99168,
				40.73525
			],
			[
				-73.99102,
				40.73479
			],
			[
				-73.99104,
				40.73467
			],
			[
				-73.99099,
				40.73497
			],
			[
				-73.99063,
				40.73462
			],
			[
				-73.99047,
				40.73467
			],
			[
				-73.99032,
				40.7346
			],
			[
				-73.99029,
				40.73449
			],
			[
				-73.98996,
				40.73434
			],
			[
				-73.98771,
				40.73349
			],
			[
				-73.98758,
				40.73335
			],
			[
				-73.98518,
				40.73234
			],
			[
				-73.98514,
				40.73213
			],
			[
				-73.98721,
				40.72919
			]
		],
		"dupe": false
	},
	{
		"id": 306670881,
		"name": "More like Shitty Bike",
		"distance": 4642.8,
		"moving_time": 883,
		"elapsed_time": 911,
		"total_elevation_gain": 11.4,
		"start_date_local": "2015-05-17T16:34:03Z",
		"average_speed": 5.258,
		"max_speed": 12.3,
		"calories": 107.6,
		"points": [
			[
				-73.96336,
				40.71689
			],
			[
				-73.96387,
				40.71646
			],
			[
				-73.96452,
				40.71574
			],
			[
				-73.96613,
				40.71208
			],
			[
				-73.96497,
				40.71166
			],
			[
				-73.96096,
				40.7105
			],
			[
				-73.96145,
				40.71057
			],
			[
				-73.96307,
				40.71106
			],
			[
				-73.96315,
				40.711
			],
			[
				-73.9669,
				40.7122
			],
			[
				-73.96746,
				40.71224
			],
			[
				-73.96824,
				40.71252
			],
			[
				-73.97034,
				40.71306
			],
			[
				-73.9857,
				40.71777
			],
			[
				-73.98572,
				40.71788
			],
			[
				-73.98563,
				40.71788
			],
			[
				-73.98557,
				40.71812
			],
			[
				-73.98373,
				40.7215
			],
			[
				-73.98371,
				40.72165
			],
			[
				-73.98302,
				40.72259
			],
			[
				-73.9831,
				40.72272
			],
			[
				-73.98299,
				40.72264
			],
			[
				-73.98334,
				40.72297
			],
			[
				-73.9836,
				40.72302
			],
			[
				-73.98408,
				40.72327
			],
			[
				-73.98441,
				40.72328
			],
			[
				-73.98966,
				40.72548
			],
			[
				-73.98961,
				40.7257
			]
		],
		"dupe": false
	},
	{
		"id": 306787341,
		"name": "Evening Ride",
		"distance": 3819.8,
		"moving_time": 1659,
		"elapsed_time": 12146,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-17T18:21:42Z",
		"average_speed": 2.302,
		"max_speed": 11.1,
		"calories": 56.4,
		"points": [
			[
				-73.98759,
				40.72859
			],
			[
				-73.98763,
				40.72854
			],
			[
				-73.98745,
				40.72851
			],
			[
				-73.98759,
				40.72856
			],
			[
				-73.98811,
				40.72781
			],
			[
				-73.98845,
				40.7275
			],
			[
				-73.98889,
				40.72682
			],
			[
				-73.98921,
				40.72655
			],
			[
				-73.98995,
				40.72567
			],
			[
				-73.99007,
				40.72568
			],
			[
				-73.991,
				40.72612
			],
			[
				-73.99229,
				40.72652
			],
			[
				-73.99267,
				40.7268
			],
			[
				-74.00136,
				40.73103
			],
			[
				-74.00106,
				40.73163
			],
			[
				-74.00011,
				40.73292
			],
			[
				-74.00161,
				40.73368
			],
			[
				-74.00308,
				40.73318
			],
			[
				-74.00358,
				40.73227
			],
			[
				-74.00248,
				40.73032
			],
			[
				-74.00231,
				40.73037
			],
			[
				-74.00247,
				40.73042
			],
			[
				-74.00257,
				40.73058
			],
			[
				-74.00293,
				40.73082
			],
			[
				-74.00306,
				40.73107
			],
			[
				-74.00297,
				40.73126
			],
			[
				-74.00339,
				40.73166
			],
			[
				-74.00331,
				40.73182
			],
			[
				-74.00342,
				40.73198
			],
			[
				-74.00239,
				40.73032
			],
			[
				-74.00244,
				40.73038
			],
			[
				-74.00219,
				40.73051
			],
			[
				-74.00163,
				40.73053
			],
			[
				-74.00125,
				40.73126
			],
			[
				-74.00127,
				40.73108
			],
			[
				-74.00099,
				40.73091
			],
			[
				-74.00081,
				40.73066
			],
			[
				-74.00082,
				40.73052
			],
			[
				-74.00034,
				40.73048
			],
			[
				-74.00019,
				40.73054
			],
			[
				-73.99992,
				40.73011
			],
			[
				-73.99946,
				40.73014
			],
			[
				-73.99946,
				40.73023
			],
			[
				-73.99968,
				40.73039
			],
			[
				-74.0002,
				40.73039
			],
			[
				-74.00031,
				40.73052
			],
			[
				-74.0007,
				40.73064
			],
			[
				-74.00091,
				40.73086
			],
			[
				-74.00087,
				40.73068
			],
			[
				-74.00023,
				40.73056
			],
			[
				-74.00024,
				40.73013
			],
			[
				-74.00014,
				40.73009
			],
			[
				-74.00052,
				40.73025
			],
			[
				-74.00034,
				40.73018
			]
		],
		"dupe": false
	},
	{
		"id": 306796670,
		"name": "Night Ride",
		"distance": 2088.2,
		"moving_time": 368,
		"elapsed_time": 385,
		"total_elevation_gain": 0,
		"start_date_local": "2015-05-17T22:17:22Z",
		"average_speed": 5.674,
		"max_speed": 11.2,
		"calories": 57.2,
		"points": [
			[
				-74.00182,
				40.73092
			],
			[
				-74.00215,
				40.73063
			],
			[
				-74.0021,
				40.73051
			],
			[
				-74.0017,
				40.73059
			],
			[
				-74.00149,
				40.73074
			],
			[
				-74.00113,
				40.73117
			],
			[
				-74.00111,
				40.73151
			],
			[
				-74.00083,
				40.73168
			],
			[
				-74.00078,
				40.73182
			],
			[
				-74.00049,
				40.73207
			],
			[
				-74.00042,
				40.73232
			],
			[
				-73.99993,
				40.7327
			],
			[
				-73.99995,
				40.73283
			],
			[
				-73.99973,
				40.73302
			],
			[
				-73.99935,
				40.73373
			],
			[
				-73.99918,
				40.73386
			],
			[
				-73.99917,
				40.73408
			],
			[
				-73.99898,
				40.73415
			],
			[
				-73.99875,
				40.7344
			],
			[
				-73.99824,
				40.7352
			],
			[
				-73.99803,
				40.73529
			],
			[
				-73.99795,
				40.73556
			],
			[
				-73.99751,
				40.73597
			],
			[
				-73.99739,
				40.73633
			],
			[
				-73.99706,
				40.7367
			],
			[
				-73.99705,
				40.73681
			],
			[
				-73.99668,
				40.73711
			],
			[
				-73.99694,
				40.73711
			],
			[
				-73.99706,
				40.73697
			],
			[
				-73.99635,
				40.73765
			],
			[
				-73.99584,
				40.73857
			],
			[
				-73.99559,
				40.73878
			],
			[
				-73.99561,
				40.73887
			],
			[
				-73.99532,
				40.73925
			],
			[
				-73.99534,
				40.73941
			],
			[
				-73.99489,
				40.73977
			],
			[
				-73.99454,
				40.74025
			],
			[
				-73.99439,
				40.7403
			],
			[
				-73.99436,
				40.74067
			],
			[
				-73.99414,
				40.74087
			],
			[
				-73.99398,
				40.74111
			],
			[
				-73.994,
				40.74125
			],
			[
				-73.99371,
				40.74163
			],
			[
				-73.99378,
				40.74178
			],
			[
				-73.9941,
				40.74202
			],
			[
				-73.99422,
				40.74201
			],
			[
				-73.99418,
				40.74221
			],
			[
				-73.99488,
				40.74232
			],
			[
				-73.99516,
				40.7422
			],
			[
				-73.99672,
				40.74286
			],
			[
				-73.99676,
				40.74256
			],
			[
				-73.99689,
				40.74238
			],
			[
				-73.9971,
				40.74236
			]
		],
		"dupe": false
	},
	{
		"id": 306825281,
		"name": "Night Ride through Central Park",
		"distance": 21616.7,
		"moving_time": 3691,
		"elapsed_time": 4065,
		"total_elevation_gain": 91.4,
		"start_date_local": "2015-05-18T00:12:25Z",
		"average_speed": 5.857,
		"max_speed": 15.6,
		"calories": 524.7,
		"points": [
			[
				-73.99903,
				40.74449
			],
			[
				-73.99878,
				40.74488
			],
			[
				-73.99853,
				40.74508
			],
			[
				-73.99856,
				40.74519
			],
			[
				-73.99791,
				40.74593
			],
			[
				-73.99737,
				40.74674
			],
			[
				-73.99724,
				40.7468
			],
			[
				-73.99707,
				40.74717
			],
			[
				-73.99659,
				40.74781
			],
			[
				-73.99646,
				40.74785
			],
			[
				-73.99618,
				40.74838
			],
			[
				-73.99578,
				40.74859
			],
			[
				-73.99574,
				40.74872
			],
			[
				-73.99582,
				40.74886
			],
			[
				-73.99393,
				40.75149
			],
			[
				-73.99391,
				40.75135
			],
			[
				-73.99388,
				40.7515
			],
			[
				-73.99368,
				40.75168
			],
			[
				-73.99375,
				40.75161
			],
			[
				-73.9937,
				40.75158
			],
			[
				-73.99307,
				40.75155
			],
			[
				-73.99289,
				40.75168
			],
			[
				-73.99295,
				40.75208
			],
			[
				-73.9928,
				40.75216
			],
			[
				-73.99253,
				40.75268
			],
			[
				-73.99244,
				40.75303
			],
			[
				-73.99258,
				40.75329
			],
			[
				-73.99237,
				40.75349
			],
			[
				-73.99223,
				40.75376
			],
			[
				-73.99223,
				40.75393
			],
			[
				-73.99203,
				40.75401
			],
			[
				-73.99179,
				40.75428
			],
			[
				-73.99148,
				40.75497
			],
			[
				-73.99133,
				40.75502
			],
			[
				-73.9912,
				40.75535
			],
			[
				-73.99097,
				40.75544
			],
			[
				-73.99055,
				40.75598
			],
			[
				-73.98985,
				40.75653
			],
			[
				-73.98975,
				40.75668
			],
			[
				-73.98963,
				40.75726
			],
			[
				-73.98875,
				40.75801
			],
			[
				-73.98894,
				40.75806
			],
			[
				-73.98886,
				40.75829
			],
			[
				-73.98819,
				40.75895
			],
			[
				-73.98811,
				40.75918
			],
			[
				-73.98815,
				40.75945
			],
			[
				-73.98795,
				40.75956
			],
			[
				-73.98788,
				40.75974
			],
			[
				-73.98756,
				40.75984
			],
			[
				-73.98721,
				40.76037
			],
			[
				-73.98706,
				40.76041
			],
			[
				-73.98671,
				40.76076
			],
			[
				-73.98688,
				40.76077
			],
			[
				-73.9868,
				40.76079
			],
			[
				-73.98678,
				40.76099
			],
			[
				-73.98564,
				40.76203
			],
			[
				-73.98563,
				40.76212
			],
			[
				-73.98516,
				40.76233
			],
			[
				-73.98511,
				40.76269
			],
			[
				-73.98489,
				40.76291
			],
			[
				-73.98494,
				40.76301
			],
			[
				-73.98472,
				40.76316
			],
			[
				-73.98449,
				40.76319
			],
			[
				-73.98442,
				40.76355
			],
			[
				-73.9842,
				40.76398
			],
			[
				-73.98389,
				40.76432
			],
			[
				-73.98373,
				40.76467
			],
			[
				-73.98354,
				40.7648
			],
			[
				-73.98338,
				40.76515
			],
			[
				-73.98303,
				40.7654
			],
			[
				-73.98303,
				40.76574
			],
			[
				-73.98238,
				40.76666
			],
			[
				-73.98214,
				40.76688
			],
			[
				-73.98215,
				40.76705
			],
			[
				-73.98223,
				40.76705
			],
			[
				-73.98212,
				40.76747
			],
			[
				-73.98198,
				40.76757
			],
			[
				-73.98154,
				40.76764
			],
			[
				-73.98158,
				40.7677
			],
			[
				-73.98143,
				40.76785
			],
			[
				-73.98138,
				40.76821
			],
			[
				-73.98154,
				40.76852
			],
			[
				-73.98,
				40.77071
			],
			[
				-73.97993,
				40.77076
			],
			[
				-73.97966,
				40.77064
			],
			[
				-73.97949,
				40.77045
			],
			[
				-73.97931,
				40.77038
			],
			[
				-73.97911,
				40.77042
			],
			[
				-73.9787,
				40.77017
			],
			[
				-73.97825,
				40.77015
			],
			[
				-73.97806,
				40.77051
			],
			[
				-73.97764,
				40.7709
			],
			[
				-73.97748,
				40.77128
			],
			[
				-73.97679,
				40.77193
			],
			[
				-73.97618,
				40.77339
			],
			[
				-73.97549,
				40.77422
			],
			[
				-73.97506,
				40.77447
			],
			[
				-73.97458,
				40.77453
			],
			[
				-73.97424,
				40.77446
			],
			[
				-73.97309,
				40.77374
			],
			[
				-73.97264,
				40.7737
			],
			[
				-73.9717,
				40.77382
			],
			[
				-73.97005,
				40.7734
			],
			[
				-73.96926,
				40.7734
			],
			[
				-73.96895,
				40.77327
			],
			[
				-73.96851,
				40.77289
			],
			[
				-73.96762,
				40.77265
			],
			[
				-73.96779,
				40.77284
			],
			[
				-73.96768,
				40.7727
			],
			[
				-73.96719,
				40.77249
			],
			[
				-73.96718,
				40.77239
			],
			[
				-73.96735,
				40.77219
			],
			[
				-73.96729,
				40.77211
			],
			[
				-73.96797,
				40.77113
			],
			[
				-73.97287,
				40.76447
			],
			[
				-73.97376,
				40.76439
			],
			[
				-73.97366,
				40.76437
			],
			[
				-73.97355,
				40.76446
			],
			[
				-73.97303,
				40.76507
			],
			[
				-73.97275,
				40.76558
			],
			[
				-73.97267,
				40.7656
			],
			[
				-73.97279,
				40.76572
			],
			[
				-73.97261,
				40.766
			],
			[
				-73.97259,
				40.7663
			],
			[
				-73.97302,
				40.76712
			],
			[
				-73.97317,
				40.76788
			],
			[
				-73.9729,
				40.76899
			],
			[
				-73.97252,
				40.76941
			],
			[
				-73.97149,
				40.7699
			],
			[
				-73.9709,
				40.77074
			],
			[
				-73.97064,
				40.77099
			],
			[
				-73.96964,
				40.77153
			],
			[
				-73.96934,
				40.77179
			],
			[
				-73.96889,
				40.77248
			],
			[
				-73.96883,
				40.77312
			],
			[
				-73.96903,
				40.77338
			],
			[
				-73.96924,
				40.77344
			],
			[
				-73.97005,
				40.7734
			],
			[
				-73.97181,
				40.7738
			],
			[
				-73.97272,
				40.77376
			],
			[
				-73.97315,
				40.77385
			],
			[
				-73.97412,
				40.77455
			],
			[
				-73.97531,
				40.77482
			],
			[
				-73.97552,
				40.77507
			],
			[
				-73.97558,
				40.7753
			],
			[
				-73.9755,
				40.77602
			],
			[
				-73.97544,
				40.77594
			],
			[
				-73.97475,
				40.77644
			],
			[
				-73.97424,
				40.77728
			],
			[
				-73.97349,
				40.77803
			],
			[
				-73.97297,
				40.77821
			],
			[
				-73.97216,
				40.7787
			],
			[
				-73.9711,
				40.77974
			],
			[
				-73.97056,
				40.78013
			],
			[
				-73.96981,
				40.78048
			],
			[
				-73.9695,
				40.78084
			],
			[
				-73.96933,
				40.78123
			],
			[
				-73.9694,
				40.78214
			],
			[
				-73.96934,
				40.78244
			],
			[
				-73.969,
				40.7828
			],
			[
				-73.96827,
				40.78319
			],
			[
				-73.96779,
				40.78355
			],
			[
				-73.96714,
				40.7842
			],
			[
				-73.96702,
				40.78493
			],
			[
				-73.96726,
				40.78532
			],
			[
				-73.96725,
				40.78568
			],
			[
				-73.96747,
				40.78611
			],
			[
				-73.96755,
				40.78659
			],
			[
				-73.96725,
				40.78724
			],
			[
				-73.96694,
				40.78745
			],
			[
				-73.96674,
				40.78776
			],
			[
				-73.96638,
				40.78805
			],
			[
				-73.96526,
				40.78855
			],
			[
				-73.96506,
				40.78853
			],
			[
				-73.96442,
				40.78903
			],
			[
				-73.96419,
				40.78946
			],
			[
				-73.96423,
				40.79005
			],
			[
				-73.96414,
				40.79043
			],
			[
				-73.96379,
				40.7907
			],
			[
				-73.96304,
				40.79104
			],
			[
				-73.96261,
				40.79141
			],
			[
				-73.9623,
				40.79212
			],
			[
				-73.96199,
				40.79257
			],
			[
				-73.96192,
				40.79283
			],
			[
				-73.96136,
				40.79349
			],
			[
				-73.96063,
				40.79383
			],
			[
				-73.95966,
				40.79403
			],
			[
				-73.95932,
				40.79424
			],
			[
				-73.95903,
				40.79486
			],
			[
				-73.9588,
				40.79505
			],
			[
				-73.95728,
				40.79557
			],
			[
				-73.95725,
				40.79568
			],
			[
				-73.9571,
				40.79572
			],
			[
				-73.95708,
				40.79583
			],
			[
				-73.95678,
				40.79608
			],
			[
				-73.95667,
				40.79639
			],
			[
				-73.95698,
				40.79744
			],
			[
				-73.95691,
				40.79772
			],
			[
				-73.95718,
				40.79806
			],
			[
				-73.95819,
				40.7983
			],
			[
				-73.95848,
				40.79849
			],
			[
				-73.9585,
				40.7989
			],
			[
				-73.95835,
				40.79923
			],
			[
				-73.95782,
				40.7996
			],
			[
				-73.95767,
				40.79961
			],
			[
				-73.95712,
				40.79941
			],
			[
				-73.95585,
				40.79924
			],
			[
				-73.95485,
				40.79869
			],
			[
				-73.95432,
				40.79816
			],
			[
				-73.95439,
				40.79789
			],
			[
				-73.95463,
				40.79741
			],
			[
				-73.95515,
				40.79677
			],
			[
				-73.95579,
				40.79627
			],
			[
				-73.95588,
				40.79605
			],
			[
				-73.95583,
				40.79589
			],
			[
				-73.95562,
				40.79567
			],
			[
				-73.95513,
				40.79557
			],
			[
				-73.95474,
				40.79563
			],
			[
				-73.9543,
				40.79584
			],
			[
				-73.95381,
				40.79591
			],
			[
				-73.95351,
				40.7958
			],
			[
				-73.95337,
				40.7955
			],
			[
				-73.95353,
				40.79533
			],
			[
				-73.9544,
				40.79496
			],
			[
				-73.95498,
				40.79437
			],
			[
				-73.95543,
				40.7945
			],
			[
				-73.95541,
				40.79444
			],
			[
				-73.95504,
				40.79437
			],
			[
				-73.95495,
				40.79424
			],
			[
				-73.95497,
				40.79386
			],
			[
				-73.95507,
				40.79364
			],
			[
				-73.95502,
				40.79351
			],
			[
				-73.95508,
				40.79304
			],
			[
				-73.95491,
				40.79253
			],
			[
				-73.95494,
				40.79232
			],
			[
				-73.95592,
				40.79151
			],
			[
				-73.95646,
				40.7912
			],
			[
				-73.95704,
				40.79063
			],
			[
				-73.95743,
				40.79005
			],
			[
				-73.95751,
				40.78981
			],
			[
				-73.95749,
				40.78937
			],
			[
				-73.95704,
				40.78893
			],
			[
				-73.95689,
				40.7885
			],
			[
				-73.95692,
				40.78827
			],
			[
				-73.9571,
				40.78806
			],
			[
				-73.95722,
				40.78773
			],
			[
				-73.95713,
				40.78708
			],
			[
				-73.9572,
				40.78679
			],
			[
				-73.95778,
				40.78587
			],
			[
				-73.95797,
				40.78571
			],
			[
				-73.96075,
				40.78183
			],
			[
				-73.96134,
				40.78161
			],
			[
				-73.96282,
				40.78163
			],
			[
				-73.96336,
				40.78149
			],
			[
				-73.96399,
				40.78118
			],
			[
				-73.9648,
				40.78028
			],
			[
				-73.96512,
				40.77965
			],
			[
				-73.96672,
				40.77814
			],
			[
				-73.9669,
				40.77778
			],
			[
				-73.96712,
				40.77692
			],
			[
				-73.96742,
				40.77639
			],
			[
				-73.96731,
				40.77589
			],
			[
				-73.96741,
				40.77559
			],
			[
				-73.96755,
				40.77544
			],
			[
				-73.96863,
				40.77499
			],
			[
				-73.96931,
				40.77416
			],
			[
				-73.96914,
				40.7736
			],
			[
				-73.96872,
				40.77314
			],
			[
				-73.96894,
				40.77255
			],
			[
				-73.96925,
				40.77204
			],
			[
				-73.96962,
				40.77168
			],
			[
				-73.97089,
				40.77098
			],
			[
				-73.97114,
				40.77046
			],
			[
				-73.97137,
				40.77018
			],
			[
				-73.97181,
				40.76988
			],
			[
				-73.97242,
				40.76963
			],
			[
				-73.97248,
				40.76943
			],
			[
				-73.97271,
				40.76926
			],
			[
				-73.97288,
				40.76893
			],
			[
				-73.97295,
				40.76901
			],
			[
				-73.97324,
				40.76854
			],
			[
				-73.97319,
				40.76836
			],
			[
				-73.97326,
				40.76807
			],
			[
				-73.97325,
				40.76747
			],
			[
				-73.97308,
				40.76688
			],
			[
				-73.9728,
				40.76657
			],
			[
				-73.97266,
				40.76625
			],
			[
				-73.97266,
				40.76599
			],
			[
				-73.97313,
				40.76552
			],
			[
				-73.97322,
				40.76532
			],
			[
				-73.97316,
				40.76521
			],
			[
				-73.97374,
				40.76444
			],
			[
				-73.97366,
				40.7646
			],
			[
				-73.97312,
				40.76434
			],
			[
				-73.97316,
				40.76408
			],
			[
				-73.98088,
				40.75348
			],
			[
				-73.98081,
				40.75344
			],
			[
				-73.98086,
				40.75352
			],
			[
				-73.98212,
				40.75181
			],
			[
				-73.98262,
				40.75172
			],
			[
				-73.98269,
				40.75177
			],
			[
				-73.98249,
				40.75166
			],
			[
				-73.9824,
				40.75144
			],
			[
				-73.9866,
				40.74567
			],
			[
				-73.9872,
				40.74546
			],
			[
				-73.98898,
				40.74621
			],
			[
				-73.98883,
				40.74612
			],
			[
				-73.98893,
				40.7458
			],
			[
				-73.98871,
				40.74548
			],
			[
				-73.98881,
				40.74527
			],
			[
				-73.98874,
				40.74523
			],
			[
				-73.98883,
				40.74514
			],
			[
				-73.98871,
				40.74512
			],
			[
				-73.98867,
				40.7449
			],
			[
				-73.98885,
				40.74464
			],
			[
				-73.98893,
				40.74424
			],
			[
				-73.98886,
				40.74393
			],
			[
				-73.98873,
				40.7439
			],
			[
				-73.98906,
				40.74354
			],
			[
				-73.98893,
				40.74367
			],
			[
				-73.98893,
				40.74335
			],
			[
				-73.98924,
				40.74284
			],
			[
				-73.98918,
				40.7421
			],
			[
				-73.99112,
				40.73945
			],
			[
				-73.99176,
				40.73924
			],
			[
				-73.99457,
				40.74042
			],
			[
				-73.99413,
				40.74114
			],
			[
				-73.99481,
				40.74127
			],
			[
				-73.99594,
				40.7417
			]
		],
		"dupe": false
	},
	{
		"id": 307645836,
		"name": "Ride from Billy Bishop",
		"distance": 4091.4,
		"moving_time": 763,
		"elapsed_time": 881,
		"total_elevation_gain": 16,
		"start_date_local": "2015-05-19T12:17:47Z",
		"average_speed": 5.362,
		"max_speed": 9.6,
		"calories": 78.1,
		"points": [
			[
				-79.39776,
				43.63374
			],
			[
				-79.39786,
				43.63376
			],
			[
				-79.39784,
				43.63402
			],
			[
				-79.39738,
				43.63432
			],
			[
				-79.39748,
				43.63449
			],
			[
				-79.39747,
				43.63478
			],
			[
				-79.39839,
				43.63577
			],
			[
				-79.39959,
				43.6364
			],
			[
				-79.39986,
				43.6364
			],
			[
				-79.3998,
				43.63645
			],
			[
				-79.39966,
				43.63637
			],
			[
				-79.39974,
				43.63667
			],
			[
				-79.40163,
				43.63615
			],
			[
				-79.40386,
				43.63595
			],
			[
				-79.40687,
				43.63639
			],
			[
				-79.40862,
				43.63632
			],
			[
				-79.41045,
				43.63608
			],
			[
				-79.41168,
				43.63645
			],
			[
				-79.41212,
				43.63676
			],
			[
				-79.41271,
				43.63691
			],
			[
				-79.42058,
				43.63483
			],
			[
				-79.42163,
				43.63395
			],
			[
				-79.42221,
				43.63283
			],
			[
				-79.42257,
				43.63272
			],
			[
				-79.42333,
				43.63297
			],
			[
				-79.42425,
				43.63274
			],
			[
				-79.42485,
				43.63275
			],
			[
				-79.4251,
				43.63308
			],
			[
				-79.42566,
				43.63444
			],
			[
				-79.42739,
				43.63893
			],
			[
				-79.42733,
				43.63914
			],
			[
				-79.4281,
				43.64113
			],
			[
				-79.42836,
				43.64174
			],
			[
				-79.42859,
				43.64205
			],
			[
				-79.42872,
				43.64205
			],
			[
				-79.42864,
				43.6422
			],
			[
				-79.42873,
				43.64211
			],
			[
				-79.42902,
				43.6432
			],
			[
				-79.4289,
				43.64325
			],
			[
				-79.42904,
				43.64361
			],
			[
				-79.42923,
				43.64384
			]
		],
		"dupe": false
	},
	{
		"id": 310378555,
		"name": "To Port Credit and Back",
		"distance": 43795,
		"moving_time": 6083,
		"elapsed_time": 11160,
		"total_elevation_gain": 61,
		"start_date_local": "2015-05-23T15:45:53Z",
		"average_speed": 7.2,
		"max_speed": 14.1,
		"calories": 858.5,
		"points": [
			[
				-79.42587,
				43.65225
			],
			[
				-79.42241,
				43.64351
			],
			[
				-79.4335,
				43.64133
			],
			[
				-79.43742,
				43.64047
			],
			[
				-79.43795,
				43.64042
			],
			[
				-79.4453,
				43.63893
			],
			[
				-79.44696,
				43.63868
			],
			[
				-79.44758,
				43.6387
			],
			[
				-79.45006,
				43.63914
			],
			[
				-79.45259,
				43.6398
			],
			[
				-79.4576,
				43.6398
			],
			[
				-79.45934,
				43.63967
			],
			[
				-79.46165,
				43.63927
			],
			[
				-79.4664,
				43.63792
			],
			[
				-79.46772,
				43.63764
			],
			[
				-79.47041,
				43.63727
			],
			[
				-79.47177,
				43.63684
			],
			[
				-79.47273,
				43.63633
			],
			[
				-79.47347,
				43.63575
			],
			[
				-79.47675,
				43.63259
			],
			[
				-79.47775,
				43.63219
			],
			[
				-79.48113,
				43.63114
			],
			[
				-79.50649,
				43.62561
			],
			[
				-79.50689,
				43.62546
			],
			[
				-79.50727,
				43.62543
			],
			[
				-79.51572,
				43.62345
			],
			[
				-79.52512,
				43.62145
			],
			[
				-79.52976,
				43.62031
			],
			[
				-79.52986,
				43.62036
			],
			[
				-79.53059,
				43.62021
			],
			[
				-79.53105,
				43.62001
			],
			[
				-79.53465,
				43.61918
			],
			[
				-79.53723,
				43.61872
			],
			[
				-79.54539,
				43.61674
			],
			[
				-79.54817,
				43.61611
			],
			[
				-79.54867,
				43.61609
			],
			[
				-79.54917,
				43.61587
			],
			[
				-79.55614,
				43.6143
			],
			[
				-79.55627,
				43.61417
			],
			[
				-79.55605,
				43.61364
			],
			[
				-79.55604,
				43.61305
			],
			[
				-79.55959,
				43.61069
			],
			[
				-79.55981,
				43.61086
			],
			[
				-79.56055,
				43.61064
			],
			[
				-79.56089,
				43.61086
			],
			[
				-79.5615,
				43.61081
			],
			[
				-79.56201,
				43.61165
			],
			[
				-79.56255,
				43.61232
			],
			[
				-79.56283,
				43.61239
			],
			[
				-79.56295,
				43.61234
			],
			[
				-79.56069,
				43.61255
			],
			[
				-79.56092,
				43.61261
			],
			[
				-79.56079,
				43.61252
			],
			[
				-79.56046,
				43.61285
			],
			[
				-79.55984,
				43.6132
			],
			[
				-79.56043,
				43.61369
			],
			[
				-79.56078,
				43.61358
			],
			[
				-79.56219,
				43.6127
			],
			[
				-79.56773,
				43.60954
			],
			[
				-79.56887,
				43.60876
			],
			[
				-79.56986,
				43.60751
			],
			[
				-79.57496,
				43.60312
			],
			[
				-79.57636,
				43.60183
			],
			[
				-79.57738,
				43.60102
			],
			[
				-79.57864,
				43.60027
			],
			[
				-79.57943,
				43.5997
			],
			[
				-79.58038,
				43.5988
			],
			[
				-79.58249,
				43.59705
			],
			[
				-79.58709,
				43.59301
			],
			[
				-79.59322,
				43.58782
			],
			[
				-79.5942,
				43.58678
			],
			[
				-79.59513,
				43.58523
			],
			[
				-79.59523,
				43.58514
			],
			[
				-79.59543,
				43.58523
			],
			[
				-79.5956,
				43.58518
			],
			[
				-79.59588,
				43.58492
			],
			[
				-79.59621,
				43.58431
			],
			[
				-79.59618,
				43.58422
			],
			[
				-79.59687,
				43.58385
			],
			[
				-79.59871,
				43.58228
			],
			[
				-79.59928,
				43.58169
			],
			[
				-79.59933,
				43.58141
			],
			[
				-79.60007,
				43.58104
			],
			[
				-79.60087,
				43.58041
			],
			[
				-79.60173,
				43.57962
			],
			[
				-79.60164,
				43.5795
			],
			[
				-79.60615,
				43.57561
			],
			[
				-79.60617,
				43.57545
			],
			[
				-79.60712,
				43.57452
			],
			[
				-79.60758,
				43.57419
			],
			[
				-79.60754,
				43.57386
			],
			[
				-79.60608,
				43.57292
			],
			[
				-79.60341,
				43.57099
			],
			[
				-79.60164,
				43.56946
			],
			[
				-79.60165,
				43.56934
			],
			[
				-79.60151,
				43.56917
			],
			[
				-79.59996,
				43.56797
			],
			[
				-79.59743,
				43.56647
			],
			[
				-79.5917,
				43.56226
			],
			[
				-79.58204,
				43.55535
			],
			[
				-79.58189,
				43.55518
			],
			[
				-79.58193,
				43.55522
			],
			[
				-79.58352,
				43.55364
			],
			[
				-79.58336,
				43.55365
			],
			[
				-79.58263,
				43.55433
			],
			[
				-79.58254,
				43.55438
			],
			[
				-79.58242,
				43.55431
			],
			[
				-79.58233,
				43.55439
			],
			[
				-79.58221,
				43.55475
			],
			[
				-79.58129,
				43.55557
			],
			[
				-79.58104,
				43.55589
			],
			[
				-79.57986,
				43.55687
			],
			[
				-79.5776,
				43.55913
			],
			[
				-79.57264,
				43.56369
			],
			[
				-79.57055,
				43.56574
			],
			[
				-79.56649,
				43.56948
			],
			[
				-79.56496,
				43.57101
			],
			[
				-79.56389,
				43.57192
			],
			[
				-79.56257,
				43.57328
			],
			[
				-79.56054,
				43.57506
			],
			[
				-79.55949,
				43.57617
			],
			[
				-79.55545,
				43.57991
			],
			[
				-79.55455,
				43.58087
			],
			[
				-79.55407,
				43.58118
			],
			[
				-79.5522,
				43.58294
			],
			[
				-79.55139,
				43.58386
			],
			[
				-79.55099,
				43.5842
			],
			[
				-79.55084,
				43.58419
			],
			[
				-79.54953,
				43.58555
			],
			[
				-79.549,
				43.586
			],
			[
				-79.54852,
				43.58665
			],
			[
				-79.54547,
				43.59
			],
			[
				-79.54501,
				43.59032
			],
			[
				-79.5449,
				43.59055
			],
			[
				-79.54414,
				43.59117
			],
			[
				-79.5439,
				43.59153
			],
			[
				-79.54333,
				43.59206
			],
			[
				-79.54247,
				43.59238
			],
			[
				-79.54076,
				43.59268
			],
			[
				-79.53716,
				43.5936
			],
			[
				-79.53038,
				43.5951
			],
			[
				-79.52403,
				43.59656
			],
			[
				-79.52328,
				43.59679
			],
			[
				-79.51316,
				43.59893
			],
			[
				-79.51251,
				43.59915
			],
			[
				-79.50576,
				43.60065
			],
			[
				-79.50527,
				43.60067
			],
			[
				-79.50175,
				43.60149
			],
			[
				-79.50091,
				43.60175
			],
			[
				-79.49799,
				43.60234
			],
			[
				-79.49581,
				43.60255
			],
			[
				-79.49362,
				43.6034
			],
			[
				-79.49225,
				43.60409
			],
			[
				-79.4916,
				43.60467
			],
			[
				-79.49121,
				43.60519
			],
			[
				-79.49048,
				43.60682
			],
			[
				-79.4904,
				43.60716
			],
			[
				-79.49025,
				43.60951
			],
			[
				-79.48963,
				43.61306
			],
			[
				-79.48909,
				43.61422
			],
			[
				-79.48846,
				43.61518
			],
			[
				-79.48833,
				43.61563
			],
			[
				-79.48727,
				43.61732
			],
			[
				-79.4868,
				43.61833
			],
			[
				-79.48613,
				43.61936
			],
			[
				-79.48555,
				43.61979
			],
			[
				-79.48333,
				43.62038
			],
			[
				-79.48258,
				43.621
			],
			[
				-79.4808,
				43.62356
			],
			[
				-79.48036,
				43.62438
			],
			[
				-79.47892,
				43.62733
			],
			[
				-79.47842,
				43.62883
			],
			[
				-79.47728,
				43.63003
			],
			[
				-79.47709,
				43.63004
			],
			[
				-79.47586,
				43.63088
			],
			[
				-79.47553,
				43.63117
			],
			[
				-79.47512,
				43.6317
			],
			[
				-79.47464,
				43.6321
			],
			[
				-79.47401,
				43.63251
			],
			[
				-79.47232,
				43.63332
			],
			[
				-79.4716,
				43.63357
			],
			[
				-79.46964,
				43.63404
			],
			[
				-79.46763,
				43.63514
			],
			[
				-79.46567,
				43.63592
			],
			[
				-79.4641,
				43.63642
			],
			[
				-79.46298,
				43.63703
			],
			[
				-79.46235,
				43.63728
			],
			[
				-79.46153,
				43.63743
			],
			[
				-79.46002,
				43.63747
			],
			[
				-79.45722,
				43.638
			],
			[
				-79.45517,
				43.63806
			],
			[
				-79.452,
				43.63775
			],
			[
				-79.44761,
				43.63699
			],
			[
				-79.44554,
				43.63698
			],
			[
				-79.44304,
				43.63596
			],
			[
				-79.44148,
				43.63547
			],
			[
				-79.43787,
				43.63382
			],
			[
				-79.43741,
				43.63358
			],
			[
				-79.43618,
				43.6327
			],
			[
				-79.43499,
				43.63222
			],
			[
				-79.43502,
				43.63177
			],
			[
				-79.43496,
				43.6317
			],
			[
				-79.43436,
				43.63152
			],
			[
				-79.43425,
				43.63125
			],
			[
				-79.43404,
				43.63111
			],
			[
				-79.43384,
				43.63112
			],
			[
				-79.43357,
				43.63091
			],
			[
				-79.43054,
				43.63033
			],
			[
				-79.42757,
				43.6299
			],
			[
				-79.42581,
				43.62978
			],
			[
				-79.42499,
				43.62992
			],
			[
				-79.42023,
				43.63031
			],
			[
				-79.41846,
				43.63054
			],
			[
				-79.4147,
				43.63119
			],
			[
				-79.41019,
				43.63255
			],
			[
				-79.40962,
				43.63226
			],
			[
				-79.40941,
				43.63207
			],
			[
				-79.40892,
				43.63208
			],
			[
				-79.40878,
				43.63244
			],
			[
				-79.40877,
				43.63365
			],
			[
				-79.40861,
				43.63416
			],
			[
				-79.40875,
				43.63425
			],
			[
				-79.40862,
				43.63444
			],
			[
				-79.40911,
				43.63492
			],
			[
				-79.41071,
				43.63895
			],
			[
				-79.41162,
				43.64012
			],
			[
				-79.4115,
				43.64073
			],
			[
				-79.41154,
				43.64093
			],
			[
				-79.41323,
				43.64532
			],
			[
				-79.41325,
				43.64572
			],
			[
				-79.41344,
				43.64595
			],
			[
				-79.41398,
				43.64605
			],
			[
				-79.4143,
				43.64642
			],
			[
				-79.41466,
				43.6477
			],
			[
				-79.4148,
				43.64785
			],
			[
				-79.41487,
				43.64818
			],
			[
				-79.41501,
				43.6484
			]
		],
		"dupe": false
	},
	{
		"id": 312589112,
		"name": "Don Valley",
		"distance": 35827.4,
		"moving_time": 7474,
		"elapsed_time": 10695,
		"total_elevation_gain": 138.9,
		"start_date_local": "2015-05-26T19:06:52Z",
		"average_speed": 4.794,
		"max_speed": 18.2,
		"calories": 681.9,
		"points": [
			[
				-79.42836,
				43.64113
			],
			[
				-79.42801,
				43.64037
			],
			[
				-79.42764,
				43.63926
			],
			[
				-79.4273,
				43.6387
			],
			[
				-79.42566,
				43.63443
			],
			[
				-79.42488,
				43.63264
			],
			[
				-79.42327,
				43.63296
			],
			[
				-79.42218,
				43.63259
			],
			[
				-79.42163,
				43.63231
			],
			[
				-79.42115,
				43.63236
			],
			[
				-79.41997,
				43.6327
			],
			[
				-79.41956,
				43.63205
			],
			[
				-79.41937,
				43.63138
			],
			[
				-79.41891,
				43.63142
			],
			[
				-79.41885,
				43.63106
			],
			[
				-79.41845,
				43.63007
			],
			[
				-79.41809,
				43.6305
			],
			[
				-79.41792,
				43.63059
			],
			[
				-79.41451,
				43.63124
			],
			[
				-79.41009,
				43.63262
			],
			[
				-79.40943,
				43.63209
			],
			[
				-79.4091,
				43.63209
			],
			[
				-79.40886,
				43.63229
			],
			[
				-79.40883,
				43.6332
			],
			[
				-79.40874,
				43.63336
			],
			[
				-79.40876,
				43.63376
			],
			[
				-79.40894,
				43.63397
			],
			[
				-79.40843,
				43.63466
			],
			[
				-79.40803,
				43.63447
			],
			[
				-79.40623,
				43.63401
			],
			[
				-79.40552,
				43.63393
			],
			[
				-79.40476,
				43.63396
			],
			[
				-79.40437,
				43.63408
			],
			[
				-79.40409,
				43.6343
			],
			[
				-79.4035,
				43.63546
			],
			[
				-79.40288,
				43.63529
			],
			[
				-79.40283,
				43.63515
			],
			[
				-79.40192,
				43.63417
			],
			[
				-79.40144,
				43.63415
			],
			[
				-79.40065,
				43.63447
			],
			[
				-79.4001,
				43.63483
			],
			[
				-79.40006,
				43.63493
			],
			[
				-79.39985,
				43.63495
			],
			[
				-79.39924,
				43.63525
			],
			[
				-79.39919,
				43.63535
			],
			[
				-79.39814,
				43.6358
			],
			[
				-79.39725,
				43.63643
			],
			[
				-79.39558,
				43.63701
			],
			[
				-79.39441,
				43.63726
			],
			[
				-79.39452,
				43.63772
			],
			[
				-79.39444,
				43.63776
			],
			[
				-79.39435,
				43.63819
			],
			[
				-79.39426,
				43.63825
			],
			[
				-79.39197,
				43.63851
			],
			[
				-79.39151,
				43.63872
			],
			[
				-79.3912,
				43.63863
			],
			[
				-79.39078,
				43.63889
			],
			[
				-79.38733,
				43.63949
			],
			[
				-79.38718,
				43.63936
			],
			[
				-79.38696,
				43.63952
			],
			[
				-79.38653,
				43.63955
			],
			[
				-79.38653,
				43.63947
			],
			[
				-79.38641,
				43.63956
			],
			[
				-79.38631,
				43.63947
			],
			[
				-79.38603,
				43.63968
			],
			[
				-79.38544,
				43.63973
			],
			[
				-79.3852,
				43.63961
			],
			[
				-79.38365,
				43.64006
			],
			[
				-79.38373,
				43.64
			],
			[
				-79.38363,
				43.63966
			],
			[
				-79.38306,
				43.63973
			],
			[
				-79.38305,
				43.63981
			],
			[
				-79.38264,
				43.63979
			],
			[
				-79.38288,
				43.64009
			],
			[
				-79.38282,
				43.64013
			],
			[
				-79.38286,
				43.64019
			],
			[
				-79.38274,
				43.64036
			],
			[
				-79.38235,
				43.64049
			],
			[
				-79.38249,
				43.64085
			],
			[
				-79.38262,
				43.64173
			],
			[
				-79.38258,
				43.6417
			],
			[
				-79.38273,
				43.64192
			],
			[
				-79.38066,
				43.64071
			],
			[
				-79.38037,
				43.64081
			],
			[
				-79.3796,
				43.64059
			],
			[
				-79.3793,
				43.64039
			],
			[
				-79.37911,
				43.64049
			],
			[
				-79.37906,
				43.64044
			],
			[
				-79.37911,
				43.6402
			],
			[
				-79.37926,
				43.64008
			],
			[
				-79.37973,
				43.64029
			],
			[
				-79.37966,
				43.64033
			],
			[
				-79.37969,
				43.64046
			],
			[
				-79.37872,
				43.64026
			],
			[
				-79.37859,
				43.64015
			],
			[
				-79.37861,
				43.64087
			],
			[
				-79.37849,
				43.64088
			],
			[
				-79.37852,
				43.64096
			],
			[
				-79.37833,
				43.64106
			],
			[
				-79.37736,
				43.64092
			],
			[
				-79.37679,
				43.64092
			],
			[
				-79.37664,
				43.6408
			],
			[
				-79.37674,
				43.64073
			],
			[
				-79.37667,
				43.641
			],
			[
				-79.37685,
				43.64105
			],
			[
				-79.37705,
				43.6413
			],
			[
				-79.37712,
				43.64114
			],
			[
				-79.37677,
				43.64119
			],
			[
				-79.37693,
				43.6411
			],
			[
				-79.37666,
				43.64124
			],
			[
				-79.37629,
				43.64126
			],
			[
				-79.37618,
				43.64138
			],
			[
				-79.36194,
				43.64641
			],
			[
				-79.36173,
				43.64663
			],
			[
				-79.36122,
				43.64774
			],
			[
				-79.36097,
				43.64789
			],
			[
				-79.35836,
				43.64876
			],
			[
				-79.35734,
				43.64901
			],
			[
				-79.35644,
				43.64905
			],
			[
				-79.35564,
				43.64858
			],
			[
				-79.35574,
				43.64844
			],
			[
				-79.35562,
				43.64836
			],
			[
				-79.35539,
				43.64838
			],
			[
				-79.35471,
				43.64794
			],
			[
				-79.35453,
				43.64791
			],
			[
				-79.35467,
				43.64811
			],
			[
				-79.35465,
				43.64819
			],
			[
				-79.35455,
				43.64819
			],
			[
				-79.35469,
				43.64813
			],
			[
				-79.35465,
				43.64808
			],
			[
				-79.35491,
				43.64837
			],
			[
				-79.35518,
				43.64853
			],
			[
				-79.35487,
				43.64864
			],
			[
				-79.35443,
				43.6485
			],
			[
				-79.354,
				43.64863
			],
			[
				-79.35397,
				43.64885
			],
			[
				-79.3541,
				43.64904
			],
			[
				-79.35394,
				43.64944
			],
			[
				-79.354,
				43.64972
			],
			[
				-79.35413,
				43.64994
			],
			[
				-79.35441,
				43.65011
			],
			[
				-79.35439,
				43.65018
			],
			[
				-79.35317,
				43.65056
			],
			[
				-79.35108,
				43.65099
			],
			[
				-79.35016,
				43.65079
			],
			[
				-79.35,
				43.65101
			],
			[
				-79.34961,
				43.65097
			],
			[
				-79.34894,
				43.65125
			],
			[
				-79.34877,
				43.65149
			],
			[
				-79.34885,
				43.65175
			],
			[
				-79.34883,
				43.65202
			],
			[
				-79.34926,
				43.65252
			],
			[
				-79.34957,
				43.65275
			],
			[
				-79.34971,
				43.65311
			],
			[
				-79.35001,
				43.65342
			],
			[
				-79.34998,
				43.65357
			],
			[
				-79.35071,
				43.65379
			],
			[
				-79.35111,
				43.65456
			],
			[
				-79.35148,
				43.65499
			],
			[
				-79.35356,
				43.65684
			],
			[
				-79.35443,
				43.65767
			],
			[
				-79.3546,
				43.65794
			],
			[
				-79.35466,
				43.65836
			],
			[
				-79.35504,
				43.65928
			],
			[
				-79.35531,
				43.66033
			],
			[
				-79.35563,
				43.6612
			],
			[
				-79.35567,
				43.66163
			],
			[
				-79.35587,
				43.66193
			],
			[
				-79.35616,
				43.66283
			],
			[
				-79.35653,
				43.6642
			],
			[
				-79.35642,
				43.66459
			],
			[
				-79.35664,
				43.66494
			],
			[
				-79.35683,
				43.66571
			],
			[
				-79.35711,
				43.66631
			],
			[
				-79.3576,
				43.6682
			],
			[
				-79.35783,
				43.6685
			],
			[
				-79.35783,
				43.66858
			],
			[
				-79.35737,
				43.66869
			],
			[
				-79.35732,
				43.66877
			],
			[
				-79.35749,
				43.66896
			],
			[
				-79.35771,
				43.66953
			],
			[
				-79.35875,
				43.67098
			],
			[
				-79.35884,
				43.67106
			],
			[
				-79.35925,
				43.67106
			],
			[
				-79.35981,
				43.67148
			],
			[
				-79.35984,
				43.67164
			],
			[
				-79.36011,
				43.67198
			],
			[
				-79.36128,
				43.67304
			],
			[
				-79.36185,
				43.67365
			],
			[
				-79.36229,
				43.67426
			],
			[
				-79.36266,
				43.67504
			],
			[
				-79.36282,
				43.67589
			],
			[
				-79.36311,
				43.67633
			],
			[
				-79.36348,
				43.6773
			],
			[
				-79.36351,
				43.6776
			],
			[
				-79.36332,
				43.6781
			],
			[
				-79.36354,
				43.6792
			],
			[
				-79.36433,
				43.67992
			],
			[
				-79.36494,
				43.68014
			],
			[
				-79.36552,
				43.68054
			],
			[
				-79.3658,
				43.68089
			],
			[
				-79.36586,
				43.68114
			],
			[
				-79.36553,
				43.68157
			],
			[
				-79.36466,
				43.68194
			],
			[
				-79.36432,
				43.68218
			],
			[
				-79.36427,
				43.68245
			],
			[
				-79.36401,
				43.68261
			],
			[
				-79.36382,
				43.68287
			],
			[
				-79.36316,
				43.68299
			],
			[
				-79.36308,
				43.68316
			],
			[
				-79.36276,
				43.68319
			],
			[
				-79.36245,
				43.68335
			],
			[
				-79.36207,
				43.6838
			],
			[
				-79.36166,
				43.68455
			],
			[
				-79.36137,
				43.68463
			],
			[
				-79.36106,
				43.68556
			],
			[
				-79.36095,
				43.68654
			],
			[
				-79.36116,
				43.68682
			],
			[
				-79.36179,
				43.6869
			],
			[
				-79.36189,
				43.68699
			],
			[
				-79.3622,
				43.68806
			],
			[
				-79.36213,
				43.68823
			],
			[
				-79.36206,
				43.68829
			],
			[
				-79.36184,
				43.68816
			],
			[
				-79.3617,
				43.68848
			],
			[
				-79.36136,
				43.68872
			],
			[
				-79.36082,
				43.68966
			],
			[
				-79.36068,
				43.69019
			],
			[
				-79.36039,
				43.69044
			],
			[
				-79.35986,
				43.69112
			],
			[
				-79.3596,
				43.69172
			],
			[
				-79.35982,
				43.6924
			],
			[
				-79.35957,
				43.69281
			],
			[
				-79.35865,
				43.69332
			],
			[
				-79.35838,
				43.69382
			],
			[
				-79.35751,
				43.69436
			],
			[
				-79.35664,
				43.69509
			],
			[
				-79.35616,
				43.69525
			],
			[
				-79.35609,
				43.69566
			],
			[
				-79.35561,
				43.69645
			],
			[
				-79.35553,
				43.69709
			],
			[
				-79.35536,
				43.69736
			],
			[
				-79.35482,
				43.69782
			],
			[
				-79.35445,
				43.6979
			],
			[
				-79.35379,
				43.69788
			],
			[
				-79.35366,
				43.69796
			],
			[
				-79.35298,
				43.69805
			],
			[
				-79.35227,
				43.69795
			],
			[
				-79.35221,
				43.69815
			],
			[
				-79.35202,
				43.69826
			],
			[
				-79.3514,
				43.69825
			],
			[
				-79.34849,
				43.69855
			],
			[
				-79.34746,
				43.69845
			],
			[
				-79.34679,
				43.69858
			],
			[
				-79.34618,
				43.69835
			],
			[
				-79.34508,
				43.69809
			],
			[
				-79.34402,
				43.69807
			],
			[
				-79.34191,
				43.69889
			],
			[
				-79.34144,
				43.69923
			],
			[
				-79.34036,
				43.69963
			],
			[
				-79.33996,
				43.69997
			],
			[
				-79.33975,
				43.70038
			],
			[
				-79.33923,
				43.70092
			],
			[
				-79.33814,
				43.70158
			],
			[
				-79.33788,
				43.70186
			],
			[
				-79.3372,
				43.70201
			],
			[
				-79.33592,
				43.70267
			],
			[
				-79.33493,
				43.70307
			],
			[
				-79.33483,
				43.70309
			],
			[
				-79.33459,
				43.70289
			],
			[
				-79.33435,
				43.70281
			],
			[
				-79.33387,
				43.70282
			],
			[
				-79.3337,
				43.70294
			],
			[
				-79.33352,
				43.70381
			],
			[
				-79.3336,
				43.7049
			],
			[
				-79.33352,
				43.7052
			],
			[
				-79.33334,
				43.70536
			],
			[
				-79.33336,
				43.70581
			],
			[
				-79.33421,
				43.70532
			],
			[
				-79.33487,
				43.70534
			],
			[
				-79.3354,
				43.7052
			],
			[
				-79.33552,
				43.70522
			],
			[
				-79.33584,
				43.7055
			],
			[
				-79.33656,
				43.7057
			],
			[
				-79.3369,
				43.70588
			],
			[
				-79.337,
				43.70609
			],
			[
				-79.33691,
				43.70674
			],
			[
				-79.33699,
				43.70729
			],
			[
				-79.33682,
				43.70837
			],
			[
				-79.33724,
				43.7088
			],
			[
				-79.33749,
				43.70927
			],
			[
				-79.33756,
				43.70966
			],
			[
				-79.3378,
				43.70995
			],
			[
				-79.33835,
				43.71038
			],
			[
				-79.33923,
				43.71081
			],
			[
				-79.33933,
				43.71105
			],
			[
				-79.33921,
				43.71158
			],
			[
				-79.33941,
				43.71203
			],
			[
				-79.33924,
				43.71279
			],
			[
				-79.33939,
				43.71298
			],
			[
				-79.33982,
				43.71322
			],
			[
				-79.34081,
				43.7134
			],
			[
				-79.34067,
				43.71337
			],
			[
				-79.34055,
				43.71347
			],
			[
				-79.34063,
				43.71398
			],
			[
				-79.34055,
				43.71409
			],
			[
				-79.34033,
				43.71387
			],
			[
				-79.34,
				43.71375
			],
			[
				-79.33961,
				43.71401
			],
			[
				-79.33877,
				43.71425
			],
			[
				-79.33854,
				43.71439
			],
			[
				-79.33854,
				43.71452
			],
			[
				-79.33907,
				43.71528
			],
			[
				-79.3391,
				43.71547
			],
			[
				-79.33906,
				43.71517
			],
			[
				-79.33856,
				43.71432
			],
			[
				-79.33969,
				43.71398
			],
			[
				-79.3398,
				43.7138
			],
			[
				-79.33966,
				43.71366
			],
			[
				-79.33898,
				43.71372
			],
			[
				-79.33694,
				43.71427
			],
			[
				-79.33574,
				43.71447
			],
			[
				-79.33564,
				43.71445
			],
			[
				-79.33558,
				43.71423
			],
			[
				-79.33538,
				43.71408
			],
			[
				-79.33536,
				43.71397
			],
			[
				-79.33545,
				43.71391
			],
			[
				-79.33572,
				43.71398
			],
			[
				-79.33579,
				43.71412
			],
			[
				-79.33583,
				43.71401
			],
			[
				-79.33595,
				43.714
			],
			[
				-79.33555,
				43.71348
			],
			[
				-79.3356,
				43.71328
			],
			[
				-79.33537,
				43.71276
			],
			[
				-79.33559,
				43.71267
			],
			[
				-79.3356,
				43.71249
			],
			[
				-79.33513,
				43.71163
			],
			[
				-79.33497,
				43.71109
			],
			[
				-79.33411,
				43.70924
			],
			[
				-79.33387,
				43.70854
			],
			[
				-79.33375,
				43.70771
			],
			[
				-79.33371,
				43.70688
			],
			[
				-79.33388,
				43.70561
			],
			[
				-79.33421,
				43.70403
			],
			[
				-79.33465,
				43.70319
			],
			[
				-79.33522,
				43.70269
			],
			[
				-79.33607,
				43.70225
			],
			[
				-79.33598,
				43.7022
			],
			[
				-79.33604,
				43.7023
			],
			[
				-79.33598,
				43.70219
			],
			[
				-79.33611,
				43.70222
			],
			[
				-79.33651,
				43.70207
			],
			[
				-79.33733,
				43.7017
			],
			[
				-79.33745,
				43.70155
			],
			[
				-79.33783,
				43.70164
			],
			[
				-79.33793,
				43.70156
			],
			[
				-79.33777,
				43.70123
			],
			[
				-79.33808,
				43.70112
			],
			[
				-79.33926,
				43.7004
			],
			[
				-79.33948,
				43.70004
			],
			[
				-79.34018,
				43.69978
			],
			[
				-79.34079,
				43.69942
			],
			[
				-79.34123,
				43.6993
			],
			[
				-79.34211,
				43.69881
			],
			[
				-79.3441,
				43.69804
			],
			[
				-79.34522,
				43.69813
			],
			[
				-79.34554,
				43.69825
			],
			[
				-79.34629,
				43.69833
			],
			[
				-79.3468,
				43.69853
			],
			[
				-79.34748,
				43.69848
			],
			[
				-79.34842,
				43.69859
			],
			[
				-79.35155,
				43.69828
			],
			[
				-79.35197,
				43.69832
			],
			[
				-79.35235,
				43.69804
			],
			[
				-79.35299,
				43.69809
			],
			[
				-79.35396,
				43.69785
			],
			[
				-79.35452,
				43.69791
			],
			[
				-79.35501,
				43.69767
			],
			[
				-79.35546,
				43.69716
			],
			[
				-79.35556,
				43.69641
			],
			[
				-79.35594,
				43.69587
			],
			[
				-79.35613,
				43.69536
			],
			[
				-79.35727,
				43.69461
			],
			[
				-79.35753,
				43.69454
			],
			[
				-79.35776,
				43.69424
			],
			[
				-79.35831,
				43.69385
			],
			[
				-79.35853,
				43.69348
			],
			[
				-79.35968,
				43.69273
			],
			[
				-79.35982,
				43.69237
			],
			[
				-79.35959,
				43.69175
			],
			[
				-79.35966,
				43.69139
			],
			[
				-79.36046,
				43.69033
			],
			[
				-79.36082,
				43.68965
			],
			[
				-79.36094,
				43.6892
			],
			[
				-79.36138,
				43.68872
			],
			[
				-79.36172,
				43.68849
			],
			[
				-79.36182,
				43.68837
			],
			[
				-79.3618,
				43.68826
			],
			[
				-79.3619,
				43.68826
			],
			[
				-79.36224,
				43.68871
			],
			[
				-79.36251,
				43.68887
			],
			[
				-79.36288,
				43.68885
			],
			[
				-79.36311,
				43.68871
			],
			[
				-79.36326,
				43.68815
			],
			[
				-79.3632,
				43.68816
			],
			[
				-79.36353,
				43.68792
			],
			[
				-79.36355,
				43.68776
			],
			[
				-79.36263,
				43.68588
			],
			[
				-79.36256,
				43.68539
			],
			[
				-79.36275,
				43.685
			],
			[
				-79.36351,
				43.68423
			],
			[
				-79.36477,
				43.68364
			],
			[
				-79.36502,
				43.68389
			],
			[
				-79.36529,
				43.68437
			],
			[
				-79.36632,
				43.6841
			],
			[
				-79.36641,
				43.68393
			],
			[
				-79.36604,
				43.68301
			],
			[
				-79.36673,
				43.68273
			],
			[
				-79.36665,
				43.68257
			],
			[
				-79.36754,
				43.6818
			],
			[
				-79.36854,
				43.68075
			],
			[
				-79.36872,
				43.68044
			],
			[
				-79.36884,
				43.68001
			],
			[
				-79.36876,
				43.67918
			],
			[
				-79.36941,
				43.67897
			],
			[
				-79.37005,
				43.67893
			],
			[
				-79.37049,
				43.67871
			],
			[
				-79.37074,
				43.67876
			],
			[
				-79.37143,
				43.67865
			],
			[
				-79.37188,
				43.67869
			],
			[
				-79.37283,
				43.67844
			],
			[
				-79.373,
				43.67838
			],
			[
				-79.37343,
				43.67801
			],
			[
				-79.37366,
				43.67767
			],
			[
				-79.37391,
				43.67755
			],
			[
				-79.37581,
				43.67771
			],
			[
				-79.37763,
				43.67743
			],
			[
				-79.37774,
				43.67749
			],
			[
				-79.37786,
				43.67775
			],
			[
				-79.37831,
				43.67797
			],
			[
				-79.37942,
				43.67815
			],
			[
				-79.38016,
				43.67818
			],
			[
				-79.38126,
				43.67853
			],
			[
				-79.38194,
				43.67856
			],
			[
				-79.38271,
				43.67844
			],
			[
				-79.38376,
				43.67793
			],
			[
				-79.38516,
				43.6777
			],
			[
				-79.38578,
				43.67749
			],
			[
				-79.3867,
				43.67752
			],
			[
				-79.38927,
				43.677
			],
			[
				-79.38948,
				43.67706
			],
			[
				-79.39089,
				43.68043
			],
			[
				-79.39071,
				43.68058
			],
			[
				-79.39013,
				43.68076
			],
			[
				-79.39025,
				43.68087
			],
			[
				-79.39055,
				43.68086
			],
			[
				-79.39043,
				43.68072
			],
			[
				-79.39029,
				43.68063
			],
			[
				-79.3904,
				43.68082
			],
			[
				-79.3907,
				43.68092
			],
			[
				-79.39133,
				43.68156
			],
			[
				-79.39137,
				43.68139
			],
			[
				-79.39108,
				43.68127
			],
			[
				-79.3912,
				43.68092
			],
			[
				-79.39113,
				43.68079
			],
			[
				-79.39078,
				43.68089
			],
			[
				-79.39085,
				43.68085
			],
			[
				-79.39073,
				43.68075
			],
			[
				-79.39052,
				43.68081
			],
			[
				-79.39049,
				43.6806
			],
			[
				-79.39034,
				43.68055
			],
			[
				-79.39051,
				43.68067
			],
			[
				-79.39065,
				43.68067
			],
			[
				-79.391,
				43.6805
			],
			[
				-79.39108,
				43.68034
			],
			[
				-79.39089,
				43.68013
			],
			[
				-79.3907,
				43.67943
			],
			[
				-79.39022,
				43.67862
			],
			[
				-79.39017,
				43.67828
			],
			[
				-79.38988,
				43.67754
			],
			[
				-79.38927,
				43.6763
			],
			[
				-79.3891,
				43.67581
			],
			[
				-79.38914,
				43.67558
			],
			[
				-79.38925,
				43.67553
			],
			[
				-79.39281,
				43.67473
			],
			[
				-79.3938,
				43.67513
			],
			[
				-79.39429,
				43.67517
			],
			[
				-79.39661,
				43.67471
			],
			[
				-79.39709,
				43.67471
			],
			[
				-79.39862,
				43.67441
			],
			[
				-79.39912,
				43.67438
			],
			[
				-79.40047,
				43.67454
			],
			[
				-79.40229,
				43.67562
			],
			[
				-79.40273,
				43.67576
			],
			[
				-79.40874,
				43.67458
			],
			[
				-79.40999,
				43.67407
			],
			[
				-79.41187,
				43.67376
			],
			[
				-79.41374,
				43.67328
			],
			[
				-79.41442,
				43.67323
			],
			[
				-79.41808,
				43.67236
			],
			[
				-79.4241,
				43.67119
			],
			[
				-79.42664,
				43.67061
			],
			[
				-79.4232,
				43.66231
			],
			[
				-79.42048,
				43.6554
			]
		],
		"dupe": false
	},
	{
		"id": 316478410,
		"name": "Evening Ride",
		"distance": 1314.5,
		"moving_time": 262,
		"elapsed_time": 274,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-01T20:40:24Z",
		"average_speed": 5.017,
		"max_speed": 10.8,
		"calories": 33.3,
		"points": [
			[
				-79.41228,
				43.65252
			],
			[
				-79.41269,
				43.65269
			],
			[
				-79.41338,
				43.65254
			],
			[
				-79.41359,
				43.65285
			],
			[
				-79.4138,
				43.65339
			],
			[
				-79.41489,
				43.65321
			],
			[
				-79.41505,
				43.65377
			],
			[
				-79.41522,
				43.654
			],
			[
				-79.41543,
				43.65463
			],
			[
				-79.41565,
				43.655
			],
			[
				-79.41623,
				43.65513
			],
			[
				-79.41651,
				43.65508
			],
			[
				-79.41769,
				43.65531
			],
			[
				-79.41936,
				43.65518
			],
			[
				-79.42151,
				43.65469
			],
			[
				-79.42263,
				43.65449
			],
			[
				-79.42275,
				43.65454
			],
			[
				-79.42247,
				43.65453
			],
			[
				-79.42277,
				43.65441
			],
			[
				-79.42259,
				43.65408
			],
			[
				-79.42259,
				43.65394
			],
			[
				-79.42362,
				43.65368
			]
		],
		"dupe": false
	},
	{
		"id": 317749078,
		"name": "Evening Ride",
		"distance": 1150.8,
		"moving_time": 269,
		"elapsed_time": 269,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-03T18:05:37Z",
		"average_speed": 4.278,
		"max_speed": 9.9,
		"calories": 23.1,
		"points": [
			[
				-79.42944,
				43.64432
			],
			[
				-79.42947,
				43.64464
			],
			[
				-79.42915,
				43.64477
			],
			[
				-79.42813,
				43.64491
			],
			[
				-79.42799,
				43.64499
			],
			[
				-79.42803,
				43.64517
			],
			[
				-79.42794,
				43.64531
			],
			[
				-79.42746,
				43.64535
			],
			[
				-79.42347,
				43.64618
			],
			[
				-79.42376,
				43.64693
			],
			[
				-79.42306,
				43.64722
			],
			[
				-79.42213,
				43.64738
			],
			[
				-79.42149,
				43.64756
			],
			[
				-79.42148,
				43.64766
			],
			[
				-79.4213,
				43.64763
			],
			[
				-79.42041,
				43.64779
			],
			[
				-79.42017,
				43.64772
			],
			[
				-79.42013,
				43.64735
			],
			[
				-79.42002,
				43.64729
			],
			[
				-79.42007,
				43.64718
			],
			[
				-79.42001,
				43.64709
			],
			[
				-79.42005,
				43.64728
			],
			[
				-79.42013,
				43.64728
			],
			[
				-79.42009,
				43.64713
			],
			[
				-79.42002,
				43.64714
			]
		],
		"dupe": false
	},
	{
		"id": 319718689,
		"name": "Early morning ride to Hamilton and back",
		"distance": 149824,
		"moving_time": 23125,
		"elapsed_time": 34936,
		"total_elevation_gain": 204.8,
		"start_date_local": "2015-06-06T04:11:18Z",
		"average_speed": 6.479,
		"max_speed": 13,
		"calories": 2598.4,
		"points": [
			[
				-79.42417,
				43.65313
			],
			[
				-79.42604,
				43.65271
			],
			[
				-79.42347,
				43.6462
			],
			[
				-79.4281,
				43.64523
			],
			[
				-79.42805,
				43.64494
			],
			[
				-79.42945,
				43.64463
			],
			[
				-79.42916,
				43.64348
			],
			[
				-79.42883,
				43.64273
			],
			[
				-79.42889,
				43.64198
			],
			[
				-79.4289,
				43.64221
			],
			[
				-79.42963,
				43.64208
			],
			[
				-79.4306,
				43.64182
			],
			[
				-79.43114,
				43.64179
			],
			[
				-79.43313,
				43.6414
			],
			[
				-79.44344,
				43.63924
			],
			[
				-79.44573,
				43.63881
			],
			[
				-79.44715,
				43.63864
			],
			[
				-79.45077,
				43.63928
			],
			[
				-79.45244,
				43.63975
			],
			[
				-79.45333,
				43.63988
			],
			[
				-79.45826,
				43.63976
			],
			[
				-79.46007,
				43.63955
			],
			[
				-79.46116,
				43.63934
			],
			[
				-79.46651,
				43.63792
			],
			[
				-79.46904,
				43.63742
			],
			[
				-79.47049,
				43.63725
			],
			[
				-79.47141,
				43.637
			],
			[
				-79.47202,
				43.63674
			],
			[
				-79.47291,
				43.63619
			],
			[
				-79.47644,
				43.63278
			],
			[
				-79.47705,
				43.63235
			],
			[
				-79.48016,
				43.63137
			],
			[
				-79.4827,
				43.63071
			],
			[
				-79.48413,
				43.63044
			],
			[
				-79.48437,
				43.63058
			],
			[
				-79.48627,
				43.63516
			],
			[
				-79.4879,
				43.63481
			],
			[
				-79.48756,
				43.63495
			],
			[
				-79.48763,
				43.63487
			],
			[
				-79.48637,
				43.63514
			],
			[
				-79.48623,
				43.63507
			],
			[
				-79.48431,
				43.63044
			],
			[
				-79.4857,
				43.63006
			],
			[
				-79.49212,
				43.62874
			],
			[
				-79.49546,
				43.62794
			],
			[
				-79.50649,
				43.62559
			],
			[
				-79.5079,
				43.62521
			],
			[
				-79.51341,
				43.62404
			],
			[
				-79.51561,
				43.62346
			],
			[
				-79.52512,
				43.62143
			],
			[
				-79.5258,
				43.62123
			],
			[
				-79.52656,
				43.62112
			],
			[
				-79.52915,
				43.62046
			],
			[
				-79.53021,
				43.62024
			],
			[
				-79.53066,
				43.62022
			],
			[
				-79.53097,
				43.62008
			],
			[
				-79.53477,
				43.6192
			],
			[
				-79.53535,
				43.61915
			],
			[
				-79.53822,
				43.6185
			],
			[
				-79.54459,
				43.61696
			],
			[
				-79.54594,
				43.6167
			],
			[
				-79.5467,
				43.61644
			],
			[
				-79.54724,
				43.61638
			],
			[
				-79.55516,
				43.61451
			],
			[
				-79.556,
				43.61436
			],
			[
				-79.55911,
				43.61413
			],
			[
				-79.56026,
				43.61379
			],
			[
				-79.56097,
				43.61345
			],
			[
				-79.56299,
				43.61223
			],
			[
				-79.56335,
				43.61209
			],
			[
				-79.56333,
				43.61199
			],
			[
				-79.5634,
				43.61194
			],
			[
				-79.56464,
				43.61131
			],
			[
				-79.5687,
				43.60892
			],
			[
				-79.56998,
				43.60737
			],
			[
				-79.57472,
				43.60331
			],
			[
				-79.57541,
				43.60262
			],
			[
				-79.57697,
				43.60129
			],
			[
				-79.57929,
				43.59978
			],
			[
				-79.59306,
				43.58793
			],
			[
				-79.59403,
				43.58701
			],
			[
				-79.59523,
				43.58507
			],
			[
				-79.59586,
				43.5844
			],
			[
				-79.60758,
				43.5743
			],
			[
				-79.60746,
				43.5743
			],
			[
				-79.60767,
				43.57411
			],
			[
				-79.60761,
				43.57404
			],
			[
				-79.60323,
				43.57092
			],
			[
				-79.60203,
				43.56992
			],
			[
				-79.60097,
				43.56884
			],
			[
				-79.59991,
				43.56794
			],
			[
				-79.59951,
				43.56777
			],
			[
				-79.59803,
				43.56687
			],
			[
				-79.59598,
				43.56552
			],
			[
				-79.59473,
				43.56451
			],
			[
				-79.59347,
				43.56369
			],
			[
				-79.59034,
				43.56137
			],
			[
				-79.58496,
				43.55758
			],
			[
				-79.58187,
				43.55527
			],
			[
				-79.58201,
				43.555
			],
			[
				-79.58438,
				43.5529
			],
			[
				-79.58582,
				43.55148
			],
			[
				-79.58842,
				43.54928
			],
			[
				-79.58881,
				43.54882
			],
			[
				-79.59294,
				43.54516
			],
			[
				-79.59396,
				43.54409
			],
			[
				-79.59544,
				43.54286
			],
			[
				-79.6035,
				43.53542
			],
			[
				-79.60499,
				43.53396
			],
			[
				-79.60747,
				43.53174
			],
			[
				-79.60813,
				43.53105
			],
			[
				-79.61133,
				43.52822
			],
			[
				-79.61764,
				43.52236
			],
			[
				-79.61874,
				43.52123
			],
			[
				-79.62255,
				43.51793
			],
			[
				-79.62561,
				43.51504
			],
			[
				-79.62742,
				43.51351
			],
			[
				-79.6281,
				43.51276
			],
			[
				-79.62993,
				43.51112
			],
			[
				-79.62993,
				43.51099
			],
			[
				-79.62853,
				43.50988
			],
			[
				-79.61329,
				43.49858
			],
			[
				-79.6129,
				43.49808
			],
			[
				-79.61281,
				43.49774
			],
			[
				-79.61282,
				43.49752
			],
			[
				-79.61307,
				43.49704
			],
			[
				-79.62502,
				43.48592
			],
			[
				-79.62881,
				43.48223
			],
			[
				-79.63158,
				43.47971
			],
			[
				-79.63212,
				43.47912
			],
			[
				-79.63299,
				43.47842
			],
			[
				-79.6378,
				43.47388
			],
			[
				-79.6386,
				43.47325
			],
			[
				-79.64252,
				43.46957
			],
			[
				-79.64519,
				43.46721
			],
			[
				-79.64644,
				43.46596
			],
			[
				-79.64783,
				43.46475
			],
			[
				-79.65488,
				43.45819
			],
			[
				-79.65562,
				43.45764
			],
			[
				-79.65596,
				43.4572
			],
			[
				-79.65681,
				43.45647
			],
			[
				-79.66033,
				43.45309
			],
			[
				-79.66068,
				43.45284
			],
			[
				-79.66192,
				43.4516
			],
			[
				-79.66286,
				43.45081
			],
			[
				-79.66485,
				43.44889
			],
			[
				-79.66646,
				43.44754
			],
			[
				-79.66676,
				43.44717
			],
			[
				-79.6694,
				43.44465
			],
			[
				-79.6732,
				43.44125
			],
			[
				-79.67496,
				43.43947
			],
			[
				-79.6761,
				43.43851
			],
			[
				-79.68043,
				43.43434
			],
			[
				-79.68695,
				43.42831
			],
			[
				-79.68868,
				43.4264
			],
			[
				-79.68915,
				43.4258
			],
			[
				-79.68956,
				43.42507
			],
			[
				-79.68966,
				43.42439
			],
			[
				-79.68964,
				43.42374
			],
			[
				-79.68908,
				43.42132
			],
			[
				-79.68908,
				43.42037
			],
			[
				-79.68926,
				43.41983
			],
			[
				-79.69021,
				43.4184
			],
			[
				-79.69092,
				43.41692
			],
			[
				-79.69121,
				43.41557
			],
			[
				-79.6913,
				43.41439
			],
			[
				-79.69166,
				43.41204
			],
			[
				-79.69205,
				43.41083
			],
			[
				-79.69258,
				43.40978
			],
			[
				-79.69406,
				43.40777
			],
			[
				-79.69506,
				43.40659
			],
			[
				-79.69618,
				43.40589
			],
			[
				-79.69848,
				43.40488
			],
			[
				-79.70193,
				43.40256
			],
			[
				-79.70639,
				43.39937
			],
			[
				-79.70666,
				43.39923
			],
			[
				-79.70683,
				43.39935
			],
			[
				-79.7071,
				43.39912
			],
			[
				-79.7069,
				43.39894
			],
			[
				-79.70698,
				43.3988
			],
			[
				-79.70738,
				43.3985
			],
			[
				-79.71133,
				43.39476
			],
			[
				-79.71147,
				43.39469
			],
			[
				-79.71184,
				43.39501
			],
			[
				-79.71179,
				43.39516
			],
			[
				-79.71139,
				43.39547
			],
			[
				-79.71139,
				43.39562
			],
			[
				-79.71187,
				43.39516
			],
			[
				-79.71189,
				43.39498
			],
			[
				-79.7115,
				43.39478
			],
			[
				-79.71149,
				43.39465
			],
			[
				-79.71175,
				43.39445
			],
			[
				-79.71198,
				43.39447
			],
			[
				-79.71196,
				43.39423
			],
			[
				-79.71317,
				43.39321
			],
			[
				-79.7142,
				43.39205
			],
			[
				-79.71695,
				43.38735
			],
			[
				-79.71709,
				43.38684
			],
			[
				-79.71728,
				43.38531
			],
			[
				-79.71722,
				43.38474
			],
			[
				-79.7173,
				43.38418
			],
			[
				-79.71728,
				43.38239
			],
			[
				-79.71736,
				43.38204
			],
			[
				-79.71759,
				43.37815
			],
			[
				-79.71809,
				43.37713
			],
			[
				-79.71856,
				43.37659
			],
			[
				-79.72061,
				43.37496
			],
			[
				-79.72458,
				43.3722
			],
			[
				-79.72532,
				43.37154
			],
			[
				-79.72919,
				43.36849
			],
			[
				-79.73062,
				43.36772
			],
			[
				-79.73382,
				43.36652
			],
			[
				-79.73731,
				43.36497
			],
			[
				-79.7394,
				43.36388
			],
			[
				-79.75623,
				43.35245
			],
			[
				-79.75686,
				43.35188
			],
			[
				-79.75729,
				43.35135
			],
			[
				-79.75823,
				43.34914
			],
			[
				-79.75865,
				43.3486
			],
			[
				-79.75963,
				43.34778
			],
			[
				-79.76174,
				43.34632
			],
			[
				-79.76428,
				43.34417
			],
			[
				-79.76595,
				43.34225
			],
			[
				-79.7674,
				43.34074
			],
			[
				-79.7719,
				43.33648
			],
			[
				-79.77259,
				43.33591
			],
			[
				-79.77428,
				43.33503
			],
			[
				-79.77652,
				43.33405
			],
			[
				-79.78086,
				43.33196
			],
			[
				-79.78237,
				43.33131
			],
			[
				-79.78591,
				43.32955
			],
			[
				-79.78878,
				43.32826
			],
			[
				-79.79068,
				43.32712
			],
			[
				-79.79243,
				43.32671
			],
			[
				-79.7945,
				43.32522
			],
			[
				-79.79607,
				43.32457
			],
			[
				-79.79726,
				43.32371
			],
			[
				-79.79992,
				43.32135
			],
			[
				-79.80171,
				43.31931
			],
			[
				-79.80143,
				43.31927
			],
			[
				-79.80129,
				43.31909
			],
			[
				-79.80141,
				43.31889
			],
			[
				-79.8013,
				43.31871
			],
			[
				-79.80115,
				43.31643
			],
			[
				-79.80122,
				43.31338
			],
			[
				-79.80091,
				43.31022
			],
			[
				-79.80019,
				43.30799
			],
			[
				-79.79946,
				43.30478
			],
			[
				-79.79847,
				43.30271
			],
			[
				-79.79891,
				43.30235
			],
			[
				-79.79831,
				43.30251
			],
			[
				-79.79817,
				43.30237
			],
			[
				-79.79796,
				43.30183
			],
			[
				-79.79764,
				43.30136
			],
			[
				-79.79703,
				43.30108
			],
			[
				-79.79665,
				43.30074
			],
			[
				-79.79575,
				43.30029
			],
			[
				-79.79555,
				43.29989
			],
			[
				-79.79595,
				43.29965
			],
			[
				-79.79613,
				43.29974
			],
			[
				-79.79596,
				43.29984
			],
			[
				-79.79572,
				43.2996
			],
			[
				-79.79571,
				43.29944
			],
			[
				-79.79535,
				43.29893
			],
			[
				-79.79533,
				43.29876
			],
			[
				-79.79516,
				43.29862
			],
			[
				-79.79473,
				43.29796
			],
			[
				-79.79437,
				43.29652
			],
			[
				-79.79355,
				43.29487
			],
			[
				-79.79343,
				43.29392
			],
			[
				-79.79328,
				43.29375
			],
			[
				-79.79293,
				43.29365
			],
			[
				-79.79261,
				43.29322
			],
			[
				-79.79016,
				43.28891
			],
			[
				-79.78958,
				43.28772
			],
			[
				-79.78728,
				43.2845
			],
			[
				-79.78716,
				43.28443
			],
			[
				-79.78646,
				43.28472
			],
			[
				-79.7862,
				43.28449
			],
			[
				-79.78508,
				43.28303
			],
			[
				-79.78449,
				43.282
			],
			[
				-79.78293,
				43.28004
			],
			[
				-79.78172,
				43.27821
			],
			[
				-79.78094,
				43.27725
			],
			[
				-79.78022,
				43.27612
			],
			[
				-79.77829,
				43.27387
			],
			[
				-79.77759,
				43.27282
			],
			[
				-79.77621,
				43.27118
			],
			[
				-79.77587,
				43.27063
			],
			[
				-79.77304,
				43.26727
			],
			[
				-79.77258,
				43.26684
			],
			[
				-79.77224,
				43.26624
			],
			[
				-79.7708,
				43.26458
			],
			[
				-79.7706,
				43.26441
			],
			[
				-79.76995,
				43.26416
			],
			[
				-79.7694,
				43.26341
			],
			[
				-79.76723,
				43.26129
			],
			[
				-79.76696,
				43.2606
			],
			[
				-79.76505,
				43.25904
			],
			[
				-79.76477,
				43.25868
			],
			[
				-79.76392,
				43.25794
			],
			[
				-79.76306,
				43.25695
			],
			[
				-79.76184,
				43.25598
			],
			[
				-79.76132,
				43.25568
			],
			[
				-79.76007,
				43.25441
			],
			[
				-79.76015,
				43.25418
			],
			[
				-79.75935,
				43.25356
			],
			[
				-79.75911,
				43.2536
			],
			[
				-79.75927,
				43.25364
			],
			[
				-79.75953,
				43.25332
			],
			[
				-79.76018,
				43.25288
			],
			[
				-79.761,
				43.25217
			],
			[
				-79.76093,
				43.25189
			],
			[
				-79.75925,
				43.24959
			],
			[
				-79.75866,
				43.24919
			],
			[
				-79.75707,
				43.24881
			],
			[
				-79.75581,
				43.24834
			],
			[
				-79.75679,
				43.24664
			],
			[
				-79.75698,
				43.24596
			],
			[
				-79.75747,
				43.24492
			],
			[
				-79.75782,
				43.2434
			],
			[
				-79.75838,
				43.24192
			],
			[
				-79.76017,
				43.23783
			],
			[
				-79.76031,
				43.23737
			],
			[
				-79.76039,
				43.23734
			],
			[
				-79.76061,
				43.23658
			],
			[
				-79.76085,
				43.23619
			],
			[
				-79.76092,
				43.23589
			],
			[
				-79.76202,
				43.23357
			],
			[
				-79.76419,
				43.22816
			],
			[
				-79.76566,
				43.22832
			],
			[
				-79.7667,
				43.22861
			],
			[
				-79.76717,
				43.22863
			],
			[
				-79.76832,
				43.229
			],
			[
				-79.77272,
				43.22981
			],
			[
				-79.77693,
				43.23084
			],
			[
				-79.77756,
				43.23092
			],
			[
				-79.78039,
				43.23166
			],
			[
				-79.78509,
				43.23254
			],
			[
				-79.79265,
				43.2345
			],
			[
				-79.79274,
				43.23452
			],
			[
				-79.79263,
				43.23449
			],
			[
				-79.79277,
				43.23443
			],
			[
				-79.7931,
				43.23462
			],
			[
				-79.79365,
				43.23476
			],
			[
				-79.79629,
				43.23526
			],
			[
				-79.79771,
				43.23568
			],
			[
				-79.79945,
				43.23604
			],
			[
				-79.79978,
				43.23618
			],
			[
				-79.8,
				43.23638
			],
			[
				-79.8005,
				43.23702
			],
			[
				-79.80053,
				43.23728
			],
			[
				-79.80044,
				43.23759
			],
			[
				-79.80049,
				43.23783
			],
			[
				-79.80222,
				43.23844
			],
			[
				-79.80527,
				43.23936
			],
			[
				-79.8097,
				43.2405
			],
			[
				-79.81487,
				43.24147
			],
			[
				-79.81887,
				43.24237
			],
			[
				-79.82364,
				43.24353
			],
			[
				-79.82404,
				43.24369
			],
			[
				-79.82447,
				43.24396
			],
			[
				-79.82522,
				43.24464
			],
			[
				-79.82753,
				43.24718
			],
			[
				-79.82867,
				43.24795
			],
			[
				-79.82884,
				43.2478
			],
			[
				-79.82984,
				43.24527
			],
			[
				-79.83066,
				43.24352
			],
			[
				-79.83061,
				43.24356
			],
			[
				-79.83078,
				43.24348
			],
			[
				-79.84034,
				43.24607
			],
			[
				-79.84037,
				43.24617
			],
			[
				-79.84057,
				43.24613
			],
			[
				-79.84075,
				43.24585
			],
			[
				-79.84111,
				43.24587
			],
			[
				-79.85065,
				43.24826
			],
			[
				-79.85076,
				43.24815
			],
			[
				-79.85205,
				43.24517
			],
			[
				-79.85187,
				43.24521
			],
			[
				-79.85314,
				43.24565
			],
			[
				-79.85415,
				43.24611
			],
			[
				-79.85471,
				43.24621
			],
			[
				-79.85518,
				43.24649
			],
			[
				-79.85657,
				43.24689
			],
			[
				-79.85779,
				43.24741
			],
			[
				-79.85914,
				43.24779
			],
			[
				-79.85987,
				43.24784
			],
			[
				-79.86074,
				43.24821
			],
			[
				-79.86152,
				43.24822
			],
			[
				-79.86184,
				43.24794
			],
			[
				-79.86276,
				43.24823
			],
			[
				-79.86306,
				43.24821
			],
			[
				-79.86446,
				43.2486
			],
			[
				-79.86492,
				43.24774
			],
			[
				-79.8658,
				43.24821
			],
			[
				-79.86674,
				43.24857
			],
			[
				-79.86857,
				43.24895
			],
			[
				-79.86857,
				43.24903
			],
			[
				-79.86873,
				43.24909
			],
			[
				-79.87207,
				43.24986
			],
			[
				-79.87238,
				43.24984
			],
			[
				-79.87874,
				43.25135
			],
			[
				-79.88188,
				43.25218
			],
			[
				-79.88658,
				43.25326
			],
			[
				-79.88671,
				43.25294
			],
			[
				-79.88689,
				43.25293
			],
			[
				-79.88686,
				43.25282
			],
			[
				-79.88693,
				43.2528
			],
			[
				-79.88653,
				43.25341
			],
			[
				-79.88644,
				43.2538
			],
			[
				-79.8861,
				43.25437
			],
			[
				-79.88147,
				43.26555
			],
			[
				-79.88166,
				43.26576
			],
			[
				-79.88497,
				43.26784
			],
			[
				-79.88534,
				43.26826
			],
			[
				-79.88589,
				43.26935
			],
			[
				-79.88648,
				43.27077
			],
			[
				-79.88688,
				43.27238
			],
			[
				-79.88802,
				43.27445
			],
			[
				-79.89007,
				43.2774
			],
			[
				-79.89053,
				43.27848
			],
			[
				-79.8913,
				43.28075
			],
			[
				-79.89205,
				43.28337
			],
			[
				-79.8921,
				43.28383
			],
			[
				-79.89229,
				43.28421
			],
			[
				-79.8926,
				43.28592
			],
			[
				-79.89237,
				43.28662
			],
			[
				-79.89058,
				43.28952
			],
			[
				-79.89045,
				43.29
			],
			[
				-79.89024,
				43.29251
			],
			[
				-79.88987,
				43.29312
			],
			[
				-79.88924,
				43.29344
			],
			[
				-79.88815,
				43.29351
			],
			[
				-79.88651,
				43.2932
			],
			[
				-79.88421,
				43.29261
			],
			[
				-79.87824,
				43.29093
			],
			[
				-79.87717,
				43.29076
			],
			[
				-79.87613,
				43.29071
			],
			[
				-79.87446,
				43.29084
			],
			[
				-79.87292,
				43.29127
			],
			[
				-79.87131,
				43.29199
			],
			[
				-79.86949,
				43.29303
			],
			[
				-79.86877,
				43.29355
			],
			[
				-79.86491,
				43.29716
			],
			[
				-79.86295,
				43.29884
			],
			[
				-79.85528,
				43.30466
			],
			[
				-79.85436,
				43.30544
			],
			[
				-79.85337,
				43.30615
			],
			[
				-79.85316,
				43.30622
			],
			[
				-79.85173,
				43.30741
			],
			[
				-79.85138,
				43.3076
			],
			[
				-79.84119,
				43.31542
			],
			[
				-79.83709,
				43.31848
			],
			[
				-79.83306,
				43.32131
			],
			[
				-79.83184,
				43.32233
			],
			[
				-79.83145,
				43.32255
			],
			[
				-79.82912,
				43.32433
			],
			[
				-79.82843,
				43.32499
			],
			[
				-79.82772,
				43.32585
			],
			[
				-79.8267,
				43.32672
			],
			[
				-79.82573,
				43.32735
			],
			[
				-79.825,
				43.32758
			],
			[
				-79.82479,
				43.32781
			],
			[
				-79.82473,
				43.32777
			],
			[
				-79.82388,
				43.32802
			],
			[
				-79.81976,
				43.32929
			],
			[
				-79.81867,
				43.3298
			],
			[
				-79.81806,
				43.33027
			],
			[
				-79.81742,
				43.33093
			],
			[
				-79.81576,
				43.33289
			],
			[
				-79.81488,
				43.33374
			],
			[
				-79.81437,
				43.33438
			],
			[
				-79.81234,
				43.33615
			],
			[
				-79.81047,
				43.33796
			],
			[
				-79.80828,
				43.33957
			],
			[
				-79.80497,
				43.34144
			],
			[
				-79.79991,
				43.34526
			],
			[
				-79.79819,
				43.34644
			],
			[
				-79.79727,
				43.34735
			],
			[
				-79.79602,
				43.34883
			],
			[
				-79.79243,
				43.35105
			],
			[
				-79.79213,
				43.3516
			],
			[
				-79.7916,
				43.35157
			],
			[
				-79.79094,
				43.35185
			],
			[
				-79.78845,
				43.35249
			],
			[
				-79.78762,
				43.35292
			],
			[
				-79.7875,
				43.35306
			],
			[
				-79.78722,
				43.35317
			],
			[
				-79.78453,
				43.35487
			],
			[
				-79.78376,
				43.35548
			],
			[
				-79.7835,
				43.35582
			],
			[
				-79.78293,
				43.35628
			],
			[
				-79.78133,
				43.35816
			],
			[
				-79.77934,
				43.35987
			],
			[
				-79.77772,
				43.36153
			],
			[
				-79.77607,
				43.36304
			],
			[
				-79.77143,
				43.36658
			],
			[
				-79.76672,
				43.36999
			],
			[
				-79.76449,
				43.3717
			],
			[
				-79.76372,
				43.37244
			],
			[
				-79.76284,
				43.3731
			],
			[
				-79.76228,
				43.37342
			],
			[
				-79.76235,
				43.37341
			],
			[
				-79.76208,
				43.37335
			],
			[
				-79.76021,
				43.37205
			],
			[
				-79.7603,
				43.37206
			],
			[
				-79.76023,
				43.37219
			],
			[
				-79.76042,
				43.37229
			],
			[
				-79.76039,
				43.37235
			],
			[
				-79.75985,
				43.37292
			],
			[
				-79.75977,
				43.37314
			],
			[
				-79.75923,
				43.37387
			],
			[
				-79.75911,
				43.37426
			],
			[
				-79.75839,
				43.37514
			],
			[
				-79.75826,
				43.37553
			],
			[
				-79.75779,
				43.37606
			],
			[
				-79.7575,
				43.37653
			],
			[
				-79.75729,
				43.37664
			],
			[
				-79.75673,
				43.37767
			],
			[
				-79.75652,
				43.37785
			],
			[
				-79.7565,
				43.37798
			],
			[
				-79.75548,
				43.3793
			],
			[
				-79.75437,
				43.38114
			],
			[
				-79.75406,
				43.38141
			],
			[
				-79.75341,
				43.38244
			],
			[
				-79.75288,
				43.3831
			],
			[
				-79.75252,
				43.38375
			],
			[
				-79.75194,
				43.38437
			],
			[
				-79.75166,
				43.38498
			],
			[
				-79.75133,
				43.3853
			],
			[
				-79.75079,
				43.38622
			],
			[
				-79.74995,
				43.38723
			],
			[
				-79.74948,
				43.38806
			],
			[
				-79.74857,
				43.3892
			],
			[
				-79.74837,
				43.38924
			],
			[
				-79.74818,
				43.38912
			],
			[
				-79.74501,
				43.3869
			],
			[
				-79.74407,
				43.3865
			],
			[
				-79.74318,
				43.38631
			],
			[
				-79.73631,
				43.38568
			],
			[
				-79.73557,
				43.38543
			],
			[
				-79.7344,
				43.38486
			],
			[
				-79.73418,
				43.38485
			],
			[
				-79.72752,
				43.39097
			],
			[
				-79.72391,
				43.39448
			],
			[
				-79.72035,
				43.39746
			],
			[
				-79.71597,
				43.40158
			],
			[
				-79.7097,
				43.40727
			],
			[
				-79.70949,
				43.40757
			],
			[
				-79.70899,
				43.40793
			],
			[
				-79.6908,
				43.4247
			],
			[
				-79.69034,
				43.42541
			],
			[
				-79.69021,
				43.42585
			],
			[
				-79.69027,
				43.42799
			],
			[
				-79.69021,
				43.42869
			],
			[
				-79.68979,
				43.42966
			],
			[
				-79.689,
				43.4306
			],
			[
				-79.68774,
				43.43156
			],
			[
				-79.6823,
				43.4361
			],
			[
				-79.68032,
				43.43719
			],
			[
				-79.679,
				43.43802
			],
			[
				-79.67199,
				43.44458
			],
			[
				-79.67156,
				43.44481
			],
			[
				-79.67125,
				43.44477
			],
			[
				-79.67083,
				43.44457
			],
			[
				-79.67062,
				43.44468
			],
			[
				-79.66735,
				43.44778
			],
			[
				-79.66739,
				43.44793
			],
			[
				-79.66877,
				43.44871
			],
			[
				-79.67156,
				43.45058
			],
			[
				-79.67503,
				43.45185
			],
			[
				-79.67671,
				43.45262
			],
			[
				-79.67703,
				43.45285
			],
			[
				-79.67755,
				43.45303
			],
			[
				-79.67872,
				43.45366
			],
			[
				-79.67916,
				43.45381
			],
			[
				-79.67987,
				43.45432
			],
			[
				-79.68008,
				43.45479
			],
			[
				-79.67948,
				43.4552
			],
			[
				-79.67862,
				43.45598
			],
			[
				-79.67787,
				43.45687
			],
			[
				-79.67783,
				43.45695
			],
			[
				-79.6779,
				43.45703
			],
			[
				-79.67827,
				43.45711
			],
			[
				-79.67868,
				43.45678
			],
			[
				-79.67902,
				43.45689
			],
			[
				-79.67896,
				43.45681
			],
			[
				-79.67908,
				43.45674
			],
			[
				-79.67849,
				43.45751
			],
			[
				-79.67852,
				43.45757
			],
			[
				-79.67781,
				43.45806
			],
			[
				-79.67726,
				43.45781
			],
			[
				-79.67692,
				43.45785
			],
			[
				-79.67679,
				43.45818
			],
			[
				-79.67653,
				43.45846
			],
			[
				-79.67566,
				43.45917
			],
			[
				-79.67407,
				43.46076
			],
			[
				-79.67375,
				43.46146
			],
			[
				-79.67359,
				43.46314
			],
			[
				-79.67343,
				43.46347
			],
			[
				-79.67222,
				43.46508
			],
			[
				-79.67177,
				43.4662
			],
			[
				-79.67149,
				43.46664
			],
			[
				-79.67066,
				43.46772
			],
			[
				-79.66995,
				43.46847
			],
			[
				-79.66961,
				43.46902
			],
			[
				-79.66858,
				43.47034
			],
			[
				-79.66743,
				43.4713
			],
			[
				-79.6656,
				43.47223
			],
			[
				-79.66449,
				43.47303
			],
			[
				-79.66248,
				43.47519
			],
			[
				-79.66078,
				43.47688
			],
			[
				-79.65969,
				43.47811
			],
			[
				-79.6582,
				43.47941
			],
			[
				-79.65498,
				43.48247
			],
			[
				-79.65471,
				43.48281
			],
			[
				-79.65452,
				43.48323
			],
			[
				-79.6542,
				43.48445
			],
			[
				-79.65392,
				43.48497
			],
			[
				-79.6534,
				43.48563
			],
			[
				-79.65051,
				43.48799
			],
			[
				-79.65071,
				43.48816
			],
			[
				-79.65061,
				43.48839
			],
			[
				-79.65144,
				43.48888
			],
			[
				-79.65159,
				43.48906
			],
			[
				-79.65191,
				43.4892
			],
			[
				-79.65242,
				43.48959
			],
			[
				-79.65113,
				43.49135
			],
			[
				-79.64934,
				43.49316
			],
			[
				-79.64829,
				43.49403
			],
			[
				-79.64712,
				43.49519
			],
			[
				-79.64582,
				43.49625
			],
			[
				-79.64565,
				43.49653
			],
			[
				-79.64308,
				43.49883
			],
			[
				-79.64156,
				43.50032
			],
			[
				-79.63586,
				43.50542
			],
			[
				-79.63551,
				43.50585
			],
			[
				-79.63398,
				43.50731
			],
			[
				-79.6335,
				43.50764
			],
			[
				-79.63336,
				43.50787
			],
			[
				-79.63203,
				43.50902
			],
			[
				-79.62941,
				43.51155
			],
			[
				-79.62674,
				43.51381
			],
			[
				-79.6239,
				43.51653
			],
			[
				-79.62337,
				43.51693
			],
			[
				-79.62219,
				43.51813
			],
			[
				-79.62071,
				43.51932
			],
			[
				-79.61638,
				43.5234
			],
			[
				-79.61514,
				43.52442
			],
			[
				-79.61427,
				43.52535
			],
			[
				-79.61146,
				43.52775
			],
			[
				-79.6105,
				43.52875
			],
			[
				-79.60781,
				43.53122
			],
			[
				-79.60715,
				43.53177
			],
			[
				-79.60676,
				43.53196
			],
			[
				-79.60677,
				43.53213
			],
			[
				-79.60627,
				43.53264
			],
			[
				-79.59931,
				43.53906
			],
			[
				-79.59892,
				43.5395
			],
			[
				-79.59764,
				43.54061
			],
			[
				-79.59722,
				43.54108
			],
			[
				-79.59613,
				43.54199
			],
			[
				-79.59501,
				43.54314
			],
			[
				-79.59465,
				43.54338
			],
			[
				-79.59273,
				43.54521
			],
			[
				-79.58981,
				43.54775
			],
			[
				-79.58871,
				43.54881
			],
			[
				-79.58866,
				43.54894
			],
			[
				-79.58743,
				43.54998
			],
			[
				-79.58653,
				43.5509
			],
			[
				-79.58452,
				43.55253
			],
			[
				-79.5837,
				43.55336
			],
			[
				-79.58207,
				43.55478
			],
			[
				-79.57821,
				43.55856
			],
			[
				-79.57753,
				43.55908
			],
			[
				-79.57359,
				43.56289
			],
			[
				-79.56891,
				43.56717
			],
			[
				-79.56798,
				43.56815
			],
			[
				-79.55928,
				43.57625
			],
			[
				-79.55899,
				43.57664
			],
			[
				-79.55774,
				43.57782
			],
			[
				-79.55676,
				43.57865
			],
			[
				-79.55556,
				43.57982
			],
			[
				-79.55472,
				43.58037
			],
			[
				-79.55358,
				43.58141
			],
			[
				-79.55306,
				43.58193
			],
			[
				-79.55302,
				43.582
			],
			[
				-79.55311,
				43.58214
			],
			[
				-79.54908,
				43.58601
			],
			[
				-79.54561,
				43.5898
			],
			[
				-79.54484,
				43.59038
			],
			[
				-79.54455,
				43.59071
			],
			[
				-79.54457,
				43.59079
			],
			[
				-79.54349,
				43.59194
			],
			[
				-79.54277,
				43.59232
			],
			[
				-79.53245,
				43.59466
			],
			[
				-79.52843,
				43.59568
			],
			[
				-79.52586,
				43.59616
			],
			[
				-79.52198,
				43.59707
			],
			[
				-79.51884,
				43.5977
			],
			[
				-79.51484,
				43.59866
			],
			[
				-79.51024,
				43.5996
			],
			[
				-79.50604,
				43.60058
			],
			[
				-79.5048,
				43.60094
			],
			[
				-79.50052,
				43.60176
			],
			[
				-79.49799,
				43.60234
			],
			[
				-79.49587,
				43.60256
			],
			[
				-79.49318,
				43.60356
			],
			[
				-79.49219,
				43.60418
			],
			[
				-79.49143,
				43.60488
			],
			[
				-79.49058,
				43.60642
			],
			[
				-79.49042,
				43.60694
			],
			[
				-79.49018,
				43.60992
			],
			[
				-79.48967,
				43.6129
			],
			[
				-79.48825,
				43.6156
			],
			[
				-79.48625,
				43.6191
			],
			[
				-79.48568,
				43.61973
			],
			[
				-79.48361,
				43.62028
			],
			[
				-79.48299,
				43.62062
			],
			[
				-79.48236,
				43.62128
			],
			[
				-79.48208,
				43.62181
			],
			[
				-79.4812,
				43.62301
			],
			[
				-79.48298,
				43.62363
			],
			[
				-79.48379,
				43.62409
			],
			[
				-79.48652,
				43.62613
			],
			[
				-79.48666,
				43.62646
			],
			[
				-79.48703,
				43.62654
			],
			[
				-79.4885,
				43.62764
			],
			[
				-79.48846,
				43.62774
			],
			[
				-79.48994,
				43.62886
			],
			[
				-79.49002,
				43.62894
			],
			[
				-79.49001,
				43.62907
			],
			[
				-79.48682,
				43.62982
			],
			[
				-79.48246,
				43.63069
			],
			[
				-79.48084,
				43.63116
			],
			[
				-79.47728,
				43.63174
			],
			[
				-79.4765,
				43.63224
			],
			[
				-79.47575,
				43.63315
			],
			[
				-79.4731,
				43.63574
			],
			[
				-79.4719,
				43.63651
			],
			[
				-79.47034,
				43.63707
			],
			[
				-79.46932,
				43.63716
			],
			[
				-79.46744,
				43.63747
			],
			[
				-79.46493,
				43.63805
			],
			[
				-79.4617,
				43.63907
			],
			[
				-79.45832,
				43.63961
			],
			[
				-79.45272,
				43.63965
			],
			[
				-79.45193,
				43.63952
			],
			[
				-79.45091,
				43.6392
			],
			[
				-79.44842,
				43.63871
			],
			[
				-79.44761,
				43.63858
			],
			[
				-79.44673,
				43.63857
			],
			[
				-79.44218,
				43.63947
			],
			[
				-79.441,
				43.63978
			],
			[
				-79.4365,
				43.64058
			],
			[
				-79.43596,
				43.64077
			],
			[
				-79.43121,
				43.64165
			],
			[
				-79.42927,
				43.6421
			],
			[
				-79.42832,
				43.64221
			],
			[
				-79.4276,
				43.64211
			],
			[
				-79.42758,
				43.64223
			],
			[
				-79.42729,
				43.64245
			],
			[
				-79.4247,
				43.643
			],
			[
				-79.4249,
				43.64337
			],
			[
				-79.42495,
				43.64335
			],
			[
				-79.42583,
				43.64565
			],
			[
				-79.42389,
				43.64614
			],
			[
				-79.42348,
				43.64618
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.42422,
				43.65312
			]
		],
		"dupe": false
	},
	{
		"id": 318521499,
		"name": "Evening Ride",
		"distance": 2125.1,
		"moving_time": 396,
		"elapsed_time": 406,
		"total_elevation_gain": 10.5,
		"start_date_local": "2015-06-04T19:54:50Z",
		"average_speed": 5.366,
		"max_speed": 9.8,
		"calories": 47,
		"points": [
			[
				-79.42332,
				43.65387
			],
			[
				-79.42338,
				43.65379
			],
			[
				-79.42296,
				43.6538
			],
			[
				-79.42252,
				43.65391
			],
			[
				-79.42249,
				43.65401
			],
			[
				-79.42284,
				43.65461
			],
			[
				-79.42307,
				43.65559
			],
			[
				-79.42356,
				43.65689
			],
			[
				-79.42402,
				43.65782
			],
			[
				-79.42421,
				43.65849
			],
			[
				-79.42398,
				43.6586
			],
			[
				-79.42205,
				43.65899
			],
			[
				-79.42193,
				43.65934
			],
			[
				-79.42278,
				43.6613
			],
			[
				-79.42315,
				43.66248
			],
			[
				-79.42255,
				43.66268
			],
			[
				-79.42002,
				43.66316
			],
			[
				-79.41901,
				43.66345
			],
			[
				-79.41886,
				43.66338
			],
			[
				-79.4172,
				43.66375
			],
			[
				-79.41686,
				43.66389
			],
			[
				-79.41612,
				43.66397
			],
			[
				-79.41472,
				43.66438
			],
			[
				-79.41411,
				43.66445
			],
			[
				-79.41296,
				43.66476
			],
			[
				-79.41305,
				43.66478
			]
		],
		"dupe": false
	},
	{
		"id": 319088055,
		"name": "Evening Ride",
		"distance": 4413.7,
		"moving_time": 1278,
		"elapsed_time": 1768,
		"total_elevation_gain": 11.5,
		"start_date_local": "2015-06-05T18:22:12Z",
		"average_speed": 3.454,
		"max_speed": 10.1,
		"calories": 97.1,
		"points": [
			[
				-79.42322,
				43.65391
			],
			[
				-79.42308,
				43.65386
			],
			[
				-79.42262,
				43.65396
			],
			[
				-79.42255,
				43.65404
			],
			[
				-79.42262,
				43.65439
			],
			[
				-79.42354,
				43.65659
			],
			[
				-79.42365,
				43.65711
			],
			[
				-79.42412,
				43.6579
			],
			[
				-79.42421,
				43.65851
			],
			[
				-79.39894,
				43.66375
			],
			[
				-79.39833,
				43.66411
			],
			[
				-79.39855,
				43.6643
			],
			[
				-79.39977,
				43.66729
			],
			[
				-79.3995,
				43.66751
			],
			[
				-79.39778,
				43.66794
			],
			[
				-79.39744,
				43.66811
			],
			[
				-79.39752,
				43.66808
			],
			[
				-79.3975,
				43.66831
			],
			[
				-79.39788,
				43.66918
			],
			[
				-79.39756,
				43.66931
			],
			[
				-79.39761,
				43.6693
			],
			[
				-79.39751,
				43.66915
			],
			[
				-79.39742,
				43.66916
			],
			[
				-79.39787,
				43.66917
			],
			[
				-79.39763,
				43.6693
			],
			[
				-79.39754,
				43.66907
			],
			[
				-79.39734,
				43.66901
			],
			[
				-79.39759,
				43.66919
			],
			[
				-79.3975,
				43.66919
			],
			[
				-79.39751,
				43.669
			],
			[
				-79.39753,
				43.66906
			],
			[
				-79.39726,
				43.6691
			],
			[
				-79.3972,
				43.66891
			],
			[
				-79.39754,
				43.66914
			],
			[
				-79.39745,
				43.66925
			],
			[
				-79.39723,
				43.66915
			],
			[
				-79.39715,
				43.6694
			],
			[
				-79.3978,
				43.66923
			],
			[
				-79.39755,
				43.6691
			],
			[
				-79.39752,
				43.66919
			],
			[
				-79.39766,
				43.66912
			],
			[
				-79.39764,
				43.66918
			],
			[
				-79.39786,
				43.66929
			],
			[
				-79.39754,
				43.66919
			],
			[
				-79.39743,
				43.66904
			],
			[
				-79.39752,
				43.66894
			],
			[
				-79.39746,
				43.66893
			],
			[
				-79.3975,
				43.66907
			],
			[
				-79.39735,
				43.66898
			],
			[
				-79.39742,
				43.66923
			],
			[
				-79.39749,
				43.66916
			],
			[
				-79.39738,
				43.66901
			],
			[
				-79.3977,
				43.66887
			],
			[
				-79.39773,
				43.66893
			],
			[
				-79.39743,
				43.66905
			],
			[
				-79.3975,
				43.66902
			]
		],
		"dupe": false
	},
	{
		"id": 319801530,
		"name": "Afternoon Ride",
		"distance": 3235.7,
		"moving_time": 697,
		"elapsed_time": 748,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-06T15:52:27Z",
		"average_speed": 4.642,
		"max_speed": 9.7,
		"calories": 52.3,
		"points": [
			[
				-79.42306,
				43.65344
			],
			[
				-79.4223,
				43.65337
			],
			[
				-79.42226,
				43.65308
			],
			[
				-79.42164,
				43.65157
			],
			[
				-79.42133,
				43.65059
			],
			[
				-79.42102,
				43.65
			],
			[
				-79.42087,
				43.64934
			],
			[
				-79.42075,
				43.64923
			],
			[
				-79.41851,
				43.64977
			],
			[
				-79.4169,
				43.65002
			],
			[
				-79.41669,
				43.65012
			],
			[
				-79.41444,
				43.65058
			],
			[
				-79.41091,
				43.65123
			],
			[
				-79.40952,
				43.64776
			],
			[
				-79.40935,
				43.64776
			],
			[
				-79.40899,
				43.64793
			],
			[
				-79.4048,
				43.64878
			],
			[
				-79.40432,
				43.64769
			],
			[
				-79.40411,
				43.64733
			],
			[
				-79.40402,
				43.64731
			],
			[
				-79.40414,
				43.64732
			],
			[
				-79.40379,
				43.6472
			],
			[
				-79.39866,
				43.64819
			],
			[
				-79.39581,
				43.64887
			],
			[
				-79.39384,
				43.64921
			]
		],
		"dupe": false
	},
	{
		"id": 322491679,
		"name": "Lunch Ride",
		"distance": 379.7,
		"moving_time": 81,
		"elapsed_time": 179,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-10T11:08:36Z",
		"average_speed": 4.688,
		"max_speed": 8.5,
		"calories": 10,
		"points": [
			[
				-79.42336,
				43.65318
			],
			[
				-79.42592,
				43.64953
			],
			[
				-79.42648,
				43.64949
			],
			[
				-79.42676,
				43.64957
			],
			[
				-79.42832,
				43.64954
			],
			[
				-79.4296,
				43.64962
			],
			[
				-79.42974,
				43.64956
			],
			[
				-79.42973,
				43.64941
			],
			[
				-79.42978,
				43.64942
			],
			[
				-79.42971,
				43.64945
			]
		],
		"dupe": false
	},
	{
		"id": 323641186,
		"name": "Ride to Tom's for soft-serve",
		"distance": 23546.5,
		"moving_time": 3427,
		"elapsed_time": 6004,
		"total_elevation_gain": 62.9,
		"start_date_local": "2015-06-11T20:13:07Z",
		"average_speed": 6.871,
		"max_speed": 17,
		"calories": 486,
		"points": [
			[
				-79.42596,
				43.65249
			],
			[
				-79.42477,
				43.6495
			],
			[
				-79.42517,
				43.64944
			],
			[
				-79.42996,
				43.64962
			],
			[
				-79.43087,
				43.64958
			],
			[
				-79.43268,
				43.64976
			],
			[
				-79.43554,
				43.6499
			],
			[
				-79.43558,
				43.64968
			],
			[
				-79.4324,
				43.64154
			],
			[
				-79.43539,
				43.64094
			],
			[
				-79.43592,
				43.64076
			],
			[
				-79.4363,
				43.64076
			],
			[
				-79.43834,
				43.64028
			],
			[
				-79.4398,
				43.64006
			],
			[
				-79.44016,
				43.63988
			],
			[
				-79.44071,
				43.63985
			],
			[
				-79.44305,
				43.63928
			],
			[
				-79.44702,
				43.63866
			],
			[
				-79.44758,
				43.63869
			],
			[
				-79.45086,
				43.63931
			],
			[
				-79.45281,
				43.63982
			],
			[
				-79.45754,
				43.63977
			],
			[
				-79.45955,
				43.63958
			],
			[
				-79.45921,
				43.63849
			],
			[
				-79.45915,
				43.63803
			],
			[
				-79.45896,
				43.63775
			],
			[
				-79.459,
				43.63767
			],
			[
				-79.45937,
				43.63785
			],
			[
				-79.45967,
				43.6378
			],
			[
				-79.45983,
				43.63773
			],
			[
				-79.45991,
				43.63713
			],
			[
				-79.46,
				43.63707
			],
			[
				-79.46117,
				43.63678
			],
			[
				-79.46217,
				43.63638
			],
			[
				-79.4631,
				43.6362
			],
			[
				-79.46419,
				43.63624
			],
			[
				-79.46617,
				43.63559
			],
			[
				-79.46645,
				43.63517
			],
			[
				-79.46794,
				43.63455
			],
			[
				-79.46879,
				43.63408
			],
			[
				-79.46918,
				43.63359
			],
			[
				-79.46948,
				43.63294
			],
			[
				-79.46969,
				43.6328
			],
			[
				-79.4702,
				43.6327
			],
			[
				-79.4707,
				43.63249
			],
			[
				-79.47169,
				43.63136
			],
			[
				-79.47265,
				43.63059
			],
			[
				-79.47276,
				43.63018
			],
			[
				-79.4729,
				43.63005
			],
			[
				-79.47318,
				43.62998
			],
			[
				-79.47331,
				43.63004
			],
			[
				-79.47324,
				43.6302
			],
			[
				-79.47383,
				43.63047
			],
			[
				-79.47436,
				43.6302
			],
			[
				-79.47476,
				43.62978
			],
			[
				-79.47544,
				43.62935
			],
			[
				-79.47569,
				43.62907
			],
			[
				-79.47638,
				43.62783
			],
			[
				-79.47764,
				43.62454
			],
			[
				-79.47852,
				43.62309
			],
			[
				-79.47897,
				43.62271
			],
			[
				-79.47964,
				43.62257
			],
			[
				-79.48024,
				43.62185
			],
			[
				-79.48045,
				43.62096
			],
			[
				-79.48129,
				43.6204
			],
			[
				-79.48144,
				43.62004
			],
			[
				-79.48133,
				43.61975
			],
			[
				-79.4808,
				43.61922
			],
			[
				-79.48025,
				43.61908
			],
			[
				-79.48009,
				43.6192
			],
			[
				-79.48001,
				43.61907
			],
			[
				-79.47963,
				43.61904
			],
			[
				-79.47928,
				43.61889
			],
			[
				-79.47878,
				43.61806
			],
			[
				-79.47772,
				43.61734
			],
			[
				-79.47742,
				43.61683
			],
			[
				-79.47746,
				43.61632
			],
			[
				-79.47816,
				43.61512
			],
			[
				-79.47812,
				43.61502
			],
			[
				-79.47819,
				43.61488
			],
			[
				-79.47806,
				43.6146
			],
			[
				-79.47884,
				43.61456
			],
			[
				-79.47904,
				43.61464
			],
			[
				-79.47924,
				43.61448
			],
			[
				-79.47953,
				43.6141
			],
			[
				-79.47984,
				43.61326
			],
			[
				-79.47981,
				43.61294
			],
			[
				-79.47993,
				43.61294
			],
			[
				-79.47992,
				43.61301
			],
			[
				-79.47968,
				43.61307
			],
			[
				-79.47958,
				43.61324
			],
			[
				-79.4797,
				43.61346
			],
			[
				-79.47927,
				43.61418
			],
			[
				-79.47899,
				43.61444
			],
			[
				-79.4787,
				43.61456
			],
			[
				-79.47795,
				43.61458
			],
			[
				-79.47771,
				43.61468
			],
			[
				-79.47782,
				43.61523
			],
			[
				-79.47724,
				43.61641
			],
			[
				-79.47725,
				43.61672
			],
			[
				-79.47741,
				43.61677
			],
			[
				-79.47752,
				43.61709
			],
			[
				-79.47778,
				43.6174
			],
			[
				-79.47881,
				43.6181
			],
			[
				-79.47916,
				43.61877
			],
			[
				-79.47935,
				43.61894
			],
			[
				-79.47967,
				43.61905
			],
			[
				-79.48021,
				43.61907
			],
			[
				-79.48025,
				43.61896
			],
			[
				-79.4804,
				43.61892
			],
			[
				-79.48078,
				43.619
			],
			[
				-79.48116,
				43.6192
			],
			[
				-79.48159,
				43.6189
			],
			[
				-79.48235,
				43.61855
			],
			[
				-79.48324,
				43.6184
			],
			[
				-79.48382,
				43.61805
			],
			[
				-79.48444,
				43.61796
			],
			[
				-79.4851,
				43.61731
			],
			[
				-79.4853,
				43.61662
			],
			[
				-79.48551,
				43.61637
			],
			[
				-79.48559,
				43.6161
			],
			[
				-79.48548,
				43.61568
			],
			[
				-79.48608,
				43.61449
			],
			[
				-79.48602,
				43.61398
			],
			[
				-79.48631,
				43.61368
			],
			[
				-79.48717,
				43.61355
			],
			[
				-79.48747,
				43.61315
			],
			[
				-79.4875,
				43.61297
			],
			[
				-79.4874,
				43.61279
			],
			[
				-79.48703,
				43.61259
			],
			[
				-79.48692,
				43.61244
			],
			[
				-79.48721,
				43.61189
			],
			[
				-79.48699,
				43.61165
			],
			[
				-79.48697,
				43.61088
			],
			[
				-79.48708,
				43.61079
			],
			[
				-79.48754,
				43.61069
			],
			[
				-79.48786,
				43.6108
			],
			[
				-79.49013,
				43.61095
			],
			[
				-79.49029,
				43.61037
			],
			[
				-79.49046,
				43.60903
			],
			[
				-79.49044,
				43.60798
			],
			[
				-79.49082,
				43.60629
			],
			[
				-79.49123,
				43.60541
			],
			[
				-79.49161,
				43.60489
			],
			[
				-79.49234,
				43.60425
			],
			[
				-79.49253,
				43.6042
			],
			[
				-79.49284,
				43.60393
			],
			[
				-79.4932,
				43.60381
			],
			[
				-79.49524,
				43.60861
			],
			[
				-79.49601,
				43.61064
			],
			[
				-79.49654,
				43.6118
			],
			[
				-79.49669,
				43.61233
			],
			[
				-79.49696,
				43.61283
			],
			[
				-79.4974,
				43.61401
			],
			[
				-79.49771,
				43.61452
			],
			[
				-79.49828,
				43.61594
			],
			[
				-79.49819,
				43.61602
			],
			[
				-79.49823,
				43.61614
			],
			[
				-79.49868,
				43.61695
			],
			[
				-79.49958,
				43.61897
			],
			[
				-79.50052,
				43.62144
			],
			[
				-79.50094,
				43.62281
			],
			[
				-79.50203,
				43.62497
			],
			[
				-79.5025,
				43.62623
			],
			[
				-79.50244,
				43.62628
			],
			[
				-79.49602,
				43.62771
			],
			[
				-79.49611,
				43.62774
			],
			[
				-79.49587,
				43.62762
			],
			[
				-79.49596,
				43.62781
			],
			[
				-79.49643,
				43.62785
			],
			[
				-79.49632,
				43.62775
			],
			[
				-79.49603,
				43.62775
			],
			[
				-79.49423,
				43.62808
			],
			[
				-79.49156,
				43.62867
			],
			[
				-79.49129,
				43.62879
			],
			[
				-79.48999,
				43.62901
			],
			[
				-79.48662,
				43.62981
			],
			[
				-79.48369,
				43.63039
			],
			[
				-79.48296,
				43.63061
			],
			[
				-79.4817,
				43.63082
			],
			[
				-79.48073,
				43.6311
			],
			[
				-79.47979,
				43.6312
			],
			[
				-79.47761,
				43.63164
			],
			[
				-79.47716,
				43.63179
			],
			[
				-79.4766,
				43.63215
			],
			[
				-79.47564,
				43.63332
			],
			[
				-79.47288,
				43.63588
			],
			[
				-79.47207,
				43.63641
			],
			[
				-79.47136,
				43.63674
			],
			[
				-79.47025,
				43.63704
			],
			[
				-79.4687,
				43.63725
			],
			[
				-79.46611,
				43.63775
			],
			[
				-79.46223,
				43.6389
			],
			[
				-79.45961,
				43.63944
			],
			[
				-79.4596,
				43.63961
			],
			[
				-79.46075,
				43.64035
			],
			[
				-79.46081,
				43.64077
			],
			[
				-79.46089,
				43.64086
			],
			[
				-79.46183,
				43.64127
			],
			[
				-79.46193,
				43.6414
			],
			[
				-79.46176,
				43.64155
			],
			[
				-79.46191,
				43.64162
			],
			[
				-79.46185,
				43.64162
			],
			[
				-79.46237,
				43.64213
			],
			[
				-79.46301,
				43.64222
			],
			[
				-79.46375,
				43.64303
			],
			[
				-79.46471,
				43.64391
			],
			[
				-79.46509,
				43.64412
			],
			[
				-79.4653,
				43.64448
			],
			[
				-79.4653,
				43.6448
			],
			[
				-79.46523,
				43.64507
			],
			[
				-79.46475,
				43.6456
			],
			[
				-79.46372,
				43.6452
			],
			[
				-79.46181,
				43.64527
			],
			[
				-79.45964,
				43.64509
			],
			[
				-79.45894,
				43.64487
			],
			[
				-79.45833,
				43.64428
			],
			[
				-79.458,
				43.6443
			],
			[
				-79.45739,
				43.64462
			],
			[
				-79.45629,
				43.64487
			],
			[
				-79.4439,
				43.64732
			],
			[
				-79.44383,
				43.64741
			],
			[
				-79.44386,
				43.64752
			],
			[
				-79.44403,
				43.64778
			],
			[
				-79.44511,
				43.65048
			],
			[
				-79.44527,
				43.65114
			],
			[
				-79.44237,
				43.65042
			],
			[
				-79.4411,
				43.65025
			],
			[
				-79.44074,
				43.65032
			],
			[
				-79.44058,
				43.65052
			],
			[
				-79.43959,
				43.65109
			],
			[
				-79.43882,
				43.65128
			],
			[
				-79.43561,
				43.65185
			],
			[
				-79.43118,
				43.65284
			],
			[
				-79.43004,
				43.65281
			],
			[
				-79.42928,
				43.65298
			]
		],
		"dupe": false
	},
	{
		"id": 325237966,
		"name": "Ride to Rouge Park and back",
		"distance": 76702.3,
		"moving_time": 11773,
		"elapsed_time": 13873,
		"total_elevation_gain": 340.4,
		"start_date_local": "2015-06-14T05:46:36Z",
		"average_speed": 6.515,
		"max_speed": 19.6,
		"calories": 1530.3,
		"points": [
			[
				-79.42224,
				43.65327
			],
			[
				-79.42207,
				43.6525
			],
			[
				-79.42114,
				43.65001
			],
			[
				-79.42101,
				43.64986
			],
			[
				-79.42056,
				43.64852
			],
			[
				-79.41966,
				43.64637
			],
			[
				-79.41949,
				43.64574
			],
			[
				-79.41903,
				43.64471
			],
			[
				-79.41886,
				43.64412
			],
			[
				-79.41382,
				43.64512
			],
			[
				-79.41323,
				43.64533
			],
			[
				-79.41154,
				43.64094
			],
			[
				-79.4115,
				43.64074
			],
			[
				-79.41167,
				43.64037
			],
			[
				-79.41158,
				43.64022
			],
			[
				-79.4116,
				43.63998
			],
			[
				-79.41115,
				43.63955
			],
			[
				-79.41074,
				43.639
			],
			[
				-79.40959,
				43.63614
			],
			[
				-79.40865,
				43.63632
			],
			[
				-79.40687,
				43.63639
			],
			[
				-79.40703,
				43.63596
			],
			[
				-79.40683,
				43.63582
			],
			[
				-79.40674,
				43.63553
			],
			[
				-79.40761,
				43.6353
			],
			[
				-79.40844,
				43.63487
			],
			[
				-79.4087,
				43.63462
			],
			[
				-79.40869,
				43.63447
			],
			[
				-79.4085,
				43.63435
			],
			[
				-79.40781,
				43.63443
			],
			[
				-79.40594,
				43.63397
			],
			[
				-79.40475,
				43.63396
			],
			[
				-79.40416,
				43.63421
			],
			[
				-79.40357,
				43.63529
			],
			[
				-79.40317,
				43.63528
			],
			[
				-79.40295,
				43.63526
			],
			[
				-79.40193,
				43.63418
			],
			[
				-79.40153,
				43.63415
			],
			[
				-79.40043,
				43.63471
			],
			[
				-79.40023,
				43.63465
			],
			[
				-79.39802,
				43.63582
			],
			[
				-79.39715,
				43.63642
			],
			[
				-79.39543,
				43.637
			],
			[
				-79.39087,
				43.63782
			],
			[
				-79.38814,
				43.63845
			],
			[
				-79.38688,
				43.63882
			],
			[
				-79.38245,
				43.63957
			],
			[
				-79.38161,
				43.6398
			],
			[
				-79.38147,
				43.63976
			],
			[
				-79.3804,
				43.63993
			],
			[
				-79.37989,
				43.64015
			],
			[
				-79.37974,
				43.64016
			],
			[
				-79.37965,
				43.64006
			],
			[
				-79.37943,
				43.6402
			],
			[
				-79.37902,
				43.64024
			],
			[
				-79.37834,
				43.64053
			],
			[
				-79.37814,
				43.6409
			],
			[
				-79.37781,
				43.64094
			],
			[
				-79.3778,
				43.64108
			],
			[
				-79.37772,
				43.64112
			],
			[
				-79.37745,
				43.64111
			],
			[
				-79.37739,
				43.64123
			],
			[
				-79.37678,
				43.64108
			],
			[
				-79.3763,
				43.64125
			],
			[
				-79.37636,
				43.64128
			],
			[
				-79.37614,
				43.64127
			],
			[
				-79.37598,
				43.64133
			],
			[
				-79.37598,
				43.64143
			],
			[
				-79.3751,
				43.64175
			],
			[
				-79.36217,
				43.64631
			],
			[
				-79.36194,
				43.64641
			],
			[
				-79.36173,
				43.64663
			],
			[
				-79.36117,
				43.64782
			],
			[
				-79.35831,
				43.64878
			],
			[
				-79.3573,
				43.64902
			],
			[
				-79.35643,
				43.64905
			],
			[
				-79.35584,
				43.64875
			],
			[
				-79.35576,
				43.64844
			],
			[
				-79.35555,
				43.6483
			],
			[
				-79.35534,
				43.64835
			],
			[
				-79.35476,
				43.64797
			],
			[
				-79.35454,
				43.64796
			],
			[
				-79.35121,
				43.6493
			],
			[
				-79.35058,
				43.64948
			],
			[
				-79.34985,
				43.64989
			],
			[
				-79.34963,
				43.64989
			],
			[
				-79.34908,
				43.6501
			],
			[
				-79.3489,
				43.65027
			],
			[
				-79.3485,
				43.65035
			],
			[
				-79.34796,
				43.65064
			],
			[
				-79.34711,
				43.65094
			],
			[
				-79.34714,
				43.65099
			],
			[
				-79.34687,
				43.65112
			],
			[
				-79.34484,
				43.65183
			],
			[
				-79.33847,
				43.65451
			],
			[
				-79.33013,
				43.6584
			],
			[
				-79.32854,
				43.65897
			],
			[
				-79.32423,
				43.65996
			],
			[
				-79.32097,
				43.66088
			],
			[
				-79.31373,
				43.66243
			],
			[
				-79.30671,
				43.66412
			],
			[
				-79.30548,
				43.66482
			],
			[
				-79.30499,
				43.66535
			],
			[
				-79.30476,
				43.66587
			],
			[
				-79.30474,
				43.66638
			],
			[
				-79.30666,
				43.67099
			],
			[
				-79.30701,
				43.67213
			],
			[
				-79.30781,
				43.67396
			],
			[
				-79.3053,
				43.67623
			],
			[
				-79.30453,
				43.67675
			],
			[
				-79.30387,
				43.67701
			],
			[
				-79.30325,
				43.67738
			],
			[
				-79.30273,
				43.67747
			],
			[
				-79.30118,
				43.67814
			],
			[
				-79.29992,
				43.67853
			],
			[
				-79.29918,
				43.67859
			],
			[
				-79.29886,
				43.67872
			],
			[
				-79.2986,
				43.67869
			],
			[
				-79.29851,
				43.67877
			],
			[
				-79.29707,
				43.67905
			],
			[
				-79.29439,
				43.67978
			],
			[
				-79.29292,
				43.68007
			],
			[
				-79.29089,
				43.68027
			],
			[
				-79.28967,
				43.68033
			],
			[
				-79.28949,
				43.68028
			],
			[
				-79.28858,
				43.68045
			],
			[
				-79.28745,
				43.68048
			],
			[
				-79.28441,
				43.68086
			],
			[
				-79.28425,
				43.68085
			],
			[
				-79.28421,
				43.68071
			],
			[
				-79.28424,
				43.6808
			],
			[
				-79.28295,
				43.68091
			],
			[
				-79.2795,
				43.68212
			],
			[
				-79.27801,
				43.68286
			],
			[
				-79.27431,
				43.68552
			],
			[
				-79.27332,
				43.68612
			],
			[
				-79.27196,
				43.68711
			],
			[
				-79.26964,
				43.68856
			],
			[
				-79.26833,
				43.68949
			],
			[
				-79.26694,
				43.69025
			],
			[
				-79.2668,
				43.69041
			],
			[
				-79.26446,
				43.69164
			],
			[
				-79.2624,
				43.69288
			],
			[
				-79.25799,
				43.69634
			],
			[
				-79.25661,
				43.69771
			],
			[
				-79.2543,
				43.70128
			],
			[
				-79.25424,
				43.70155
			],
			[
				-79.254,
				43.7018
			],
			[
				-79.2534,
				43.70287
			],
			[
				-79.25319,
				43.70305
			],
			[
				-79.25243,
				43.70445
			],
			[
				-79.25181,
				43.70535
			],
			[
				-79.25065,
				43.70735
			],
			[
				-79.2501,
				43.70806
			],
			[
				-79.24917,
				43.70954
			],
			[
				-79.24895,
				43.71
			],
			[
				-79.24862,
				43.71046
			],
			[
				-79.24829,
				43.7105
			],
			[
				-79.24803,
				43.71081
			],
			[
				-79.24811,
				43.71106
			],
			[
				-79.24777,
				43.71155
			],
			[
				-79.24642,
				43.71289
			],
			[
				-79.24554,
				43.71357
			],
			[
				-79.24332,
				43.7156
			],
			[
				-79.2372,
				43.72087
			],
			[
				-79.23694,
				43.72117
			],
			[
				-79.23654,
				43.72143
			],
			[
				-79.23476,
				43.72296
			],
			[
				-79.23432,
				43.72343
			],
			[
				-79.23286,
				43.72458
			],
			[
				-79.23066,
				43.72662
			],
			[
				-79.22896,
				43.72793
			],
			[
				-79.22713,
				43.72968
			],
			[
				-79.22609,
				43.73052
			],
			[
				-79.22585,
				43.73029
			],
			[
				-79.22596,
				43.73033
			],
			[
				-79.22575,
				43.73084
			],
			[
				-79.22063,
				43.73538
			],
			[
				-79.21989,
				43.73618
			],
			[
				-79.21813,
				43.73769
			],
			[
				-79.21711,
				43.73874
			],
			[
				-79.21616,
				43.73947
			],
			[
				-79.2128,
				43.74254
			],
			[
				-79.21187,
				43.74352
			],
			[
				-79.21089,
				43.74427
			],
			[
				-79.21013,
				43.74501
			],
			[
				-79.21006,
				43.74517
			],
			[
				-79.20988,
				43.74527
			],
			[
				-79.20668,
				43.74877
			],
			[
				-79.20594,
				43.74947
			],
			[
				-79.20572,
				43.74947
			],
			[
				-79.20512,
				43.74917
			],
			[
				-79.20493,
				43.74892
			],
			[
				-79.20463,
				43.74808
			],
			[
				-79.20459,
				43.74723
			],
			[
				-79.20449,
				43.74708
			],
			[
				-79.20441,
				43.74655
			],
			[
				-79.204,
				43.74622
			],
			[
				-79.20348,
				43.74602
			],
			[
				-79.2031,
				43.74602
			],
			[
				-79.20248,
				43.74629
			],
			[
				-79.2017,
				43.74682
			],
			[
				-79.20123,
				43.747
			],
			[
				-79.19757,
				43.74787
			],
			[
				-79.19008,
				43.74947
			],
			[
				-79.18876,
				43.74986
			],
			[
				-79.18855,
				43.74982
			],
			[
				-79.18399,
				43.75086
			],
			[
				-79.18311,
				43.75115
			],
			[
				-79.18242,
				43.75151
			],
			[
				-79.1798,
				43.75318
			],
			[
				-79.17987,
				43.75336
			],
			[
				-79.17971,
				43.7532
			],
			[
				-79.17951,
				43.75326
			],
			[
				-79.17694,
				43.75434
			],
			[
				-79.17662,
				43.75454
			],
			[
				-79.17647,
				43.75498
			],
			[
				-79.17625,
				43.75525
			],
			[
				-79.17356,
				43.75611
			],
			[
				-79.17351,
				43.75625
			],
			[
				-79.17374,
				43.75663
			],
			[
				-79.17421,
				43.7569
			],
			[
				-79.17582,
				43.75728
			],
			[
				-79.17592,
				43.75744
			],
			[
				-79.17563,
				43.75777
			],
			[
				-79.1756,
				43.75791
			],
			[
				-79.17589,
				43.75869
			],
			[
				-79.17625,
				43.75904
			],
			[
				-79.1722,
				43.75985
			],
			[
				-79.17093,
				43.75997
			],
			[
				-79.16639,
				43.76079
			],
			[
				-79.16604,
				43.76094
			],
			[
				-79.16263,
				43.7616
			],
			[
				-79.16097,
				43.76158
			],
			[
				-79.16029,
				43.76181
			],
			[
				-79.1595,
				43.76239
			],
			[
				-79.15835,
				43.76262
			],
			[
				-79.15459,
				43.76363
			],
			[
				-79.15329,
				43.76344
			],
			[
				-79.1516,
				43.76381
			],
			[
				-79.15151,
				43.76364
			],
			[
				-79.14997,
				43.76405
			],
			[
				-79.14902,
				43.76416
			],
			[
				-79.14858,
				43.76434
			],
			[
				-79.14787,
				43.76482
			],
			[
				-79.14727,
				43.7655
			],
			[
				-79.14657,
				43.76603
			],
			[
				-79.14587,
				43.76636
			],
			[
				-79.14535,
				43.76692
			],
			[
				-79.14367,
				43.76784
			],
			[
				-79.14216,
				43.76888
			],
			[
				-79.14171,
				43.76928
			],
			[
				-79.14155,
				43.76935
			],
			[
				-79.14104,
				43.76936
			],
			[
				-79.14014,
				43.77016
			],
			[
				-79.13911,
				43.77064
			],
			[
				-79.13855,
				43.77139
			],
			[
				-79.13761,
				43.77187
			],
			[
				-79.13687,
				43.77288
			],
			[
				-79.1363,
				43.77323
			],
			[
				-79.13598,
				43.7737
			],
			[
				-79.13516,
				43.77452
			],
			[
				-79.13446,
				43.7748
			],
			[
				-79.13416,
				43.77501
			],
			[
				-79.13412,
				43.77521
			],
			[
				-79.13421,
				43.77561
			],
			[
				-79.13399,
				43.77607
			],
			[
				-79.13342,
				43.77651
			],
			[
				-79.13251,
				43.77745
			],
			[
				-79.13198,
				43.77815
			],
			[
				-79.13153,
				43.77858
			],
			[
				-79.13119,
				43.7791
			],
			[
				-79.1284,
				43.78194
			],
			[
				-79.1275,
				43.78245
			],
			[
				-79.12727,
				43.7828
			],
			[
				-79.12699,
				43.78296
			],
			[
				-79.12695,
				43.78309
			],
			[
				-79.12661,
				43.78342
			],
			[
				-79.1264,
				43.78379
			],
			[
				-79.1254,
				43.78426
			],
			[
				-79.12485,
				43.78506
			],
			[
				-79.12447,
				43.78538
			],
			[
				-79.12415,
				43.78609
			],
			[
				-79.12348,
				43.78655
			],
			[
				-79.12336,
				43.78688
			],
			[
				-79.123,
				43.7874
			],
			[
				-79.12245,
				43.78767
			],
			[
				-79.12234,
				43.7878
			],
			[
				-79.12205,
				43.78852
			],
			[
				-79.1216,
				43.78886
			],
			[
				-79.12143,
				43.7891
			],
			[
				-79.12142,
				43.78932
			],
			[
				-79.12121,
				43.7898
			],
			[
				-79.12075,
				43.79016
			],
			[
				-79.12073,
				43.79045
			],
			[
				-79.12047,
				43.7911
			],
			[
				-79.11907,
				43.79279
			],
			[
				-79.11885,
				43.79329
			],
			[
				-79.1183,
				43.79381
			],
			[
				-79.1181,
				43.79416
			],
			[
				-79.11842,
				43.79437
			],
			[
				-79.11861,
				43.7944
			],
			[
				-79.11883,
				43.79436
			],
			[
				-79.11893,
				43.79418
			],
			[
				-79.11892,
				43.79436
			],
			[
				-79.1184,
				43.79488
			],
			[
				-79.11798,
				43.79469
			],
			[
				-79.11769,
				43.79466
			],
			[
				-79.1173,
				43.79535
			],
			[
				-79.11691,
				43.79558
			],
			[
				-79.11677,
				43.79614
			],
			[
				-79.1164,
				43.7969
			],
			[
				-79.11685,
				43.79709
			],
			[
				-79.1176,
				43.79709
			],
			[
				-79.11774,
				43.79716
			],
			[
				-79.11983,
				43.79976
			],
			[
				-79.12178,
				43.79937
			],
			[
				-79.12228,
				43.7994
			],
			[
				-79.12257,
				43.79957
			],
			[
				-79.12496,
				43.80158
			],
			[
				-79.12619,
				43.80231
			],
			[
				-79.12644,
				43.80264
			],
			[
				-79.12724,
				43.80476
			],
			[
				-79.12813,
				43.80682
			],
			[
				-79.12875,
				43.80781
			],
			[
				-79.12902,
				43.80891
			],
			[
				-79.13213,
				43.81626
			],
			[
				-79.13245,
				43.81633
			],
			[
				-79.13542,
				43.81567
			],
			[
				-79.13603,
				43.81693
			],
			[
				-79.13664,
				43.81779
			],
			[
				-79.13841,
				43.81746
			],
			[
				-79.13849,
				43.81739
			],
			[
				-79.14444,
				43.81607
			],
			[
				-79.14482,
				43.81607
			],
			[
				-79.14595,
				43.81574
			],
			[
				-79.14769,
				43.81543
			],
			[
				-79.14779,
				43.81533
			],
			[
				-79.15083,
				43.81466
			],
			[
				-79.15505,
				43.81432
			],
			[
				-79.1557,
				43.81436
			],
			[
				-79.15661,
				43.81461
			],
			[
				-79.15687,
				43.8146
			],
			[
				-79.15705,
				43.81438
			],
			[
				-79.15661,
				43.81325
			],
			[
				-79.15678,
				43.81247
			],
			[
				-79.15645,
				43.81179
			],
			[
				-79.1565,
				43.8116
			],
			[
				-79.16213,
				43.81031
			],
			[
				-79.16293,
				43.80994
			],
			[
				-79.16358,
				43.80991
			],
			[
				-79.1636,
				43.80999
			],
			[
				-79.1636,
				43.80991
			],
			[
				-79.16423,
				43.80985
			],
			[
				-79.16563,
				43.80953
			],
			[
				-79.16596,
				43.8094
			],
			[
				-79.16614,
				43.80918
			],
			[
				-79.16736,
				43.80919
			],
			[
				-79.16785,
				43.80912
			],
			[
				-79.17916,
				43.80667
			],
			[
				-79.18034,
				43.80633
			],
			[
				-79.18039,
				43.8061
			],
			[
				-79.18138,
				43.80527
			],
			[
				-79.18211,
				43.80474
			],
			[
				-79.1824,
				43.80463
			],
			[
				-79.18255,
				43.80439
			],
			[
				-79.18305,
				43.80398
			],
			[
				-79.18528,
				43.8025
			],
			[
				-79.18549,
				43.80225
			],
			[
				-79.1868,
				43.80137
			],
			[
				-79.18754,
				43.80074
			],
			[
				-79.1878,
				43.80041
			],
			[
				-79.18815,
				43.80026
			],
			[
				-79.1883,
				43.80001
			],
			[
				-79.18828,
				43.79977
			],
			[
				-79.18957,
				43.7994
			],
			[
				-79.18837,
				43.79979
			],
			[
				-79.18809,
				43.7993
			],
			[
				-79.18797,
				43.79926
			],
			[
				-79.18636,
				43.79548
			],
			[
				-79.1856,
				43.79388
			],
			[
				-79.18556,
				43.79355
			],
			[
				-79.18456,
				43.79146
			],
			[
				-79.18457,
				43.79131
			],
			[
				-79.18338,
				43.78856
			],
			[
				-79.18328,
				43.78792
			],
			[
				-79.19344,
				43.78532
			],
			[
				-79.19339,
				43.78534
			],
			[
				-79.19346,
				43.78519
			],
			[
				-79.19309,
				43.78457
			],
			[
				-79.19143,
				43.7807
			],
			[
				-79.19134,
				43.78034
			],
			[
				-79.19157,
				43.78019
			],
			[
				-79.19206,
				43.77943
			],
			[
				-79.19229,
				43.7793
			],
			[
				-79.19275,
				43.77923
			],
			[
				-79.19305,
				43.7789
			],
			[
				-79.19298,
				43.77874
			],
			[
				-79.19336,
				43.77861
			],
			[
				-79.19371,
				43.77818
			],
			[
				-79.19399,
				43.77798
			],
			[
				-79.19524,
				43.77756
			],
			[
				-79.19626,
				43.77705
			],
			[
				-79.19676,
				43.77664
			],
			[
				-79.19733,
				43.77639
			],
			[
				-79.19889,
				43.77613
			],
			[
				-79.1989,
				43.77599
			],
			[
				-79.19923,
				43.77569
			],
			[
				-79.1993,
				43.77531
			],
			[
				-79.19917,
				43.77491
			],
			[
				-79.19845,
				43.77502
			],
			[
				-79.19769,
				43.77433
			],
			[
				-79.19768,
				43.77415
			],
			[
				-79.19808,
				43.77362
			],
			[
				-79.19887,
				43.77292
			],
			[
				-79.19903,
				43.77265
			],
			[
				-79.19905,
				43.77229
			],
			[
				-79.19894,
				43.77207
			],
			[
				-79.19868,
				43.77184
			],
			[
				-79.19861,
				43.77161
			],
			[
				-79.19881,
				43.77108
			],
			[
				-79.19942,
				43.77057
			],
			[
				-79.19971,
				43.76977
			],
			[
				-79.19975,
				43.76937
			],
			[
				-79.19969,
				43.76904
			],
			[
				-79.20031,
				43.76828
			],
			[
				-79.20042,
				43.76781
			],
			[
				-79.20107,
				43.76752
			],
			[
				-79.20131,
				43.7671
			],
			[
				-79.20167,
				43.76697
			],
			[
				-79.20182,
				43.76686
			],
			[
				-79.20188,
				43.7667
			],
			[
				-79.20185,
				43.76613
			],
			[
				-79.20152,
				43.7658
			],
			[
				-79.20147,
				43.76563
			],
			[
				-79.20143,
				43.7657
			],
			[
				-79.20182,
				43.76617
			],
			[
				-79.20195,
				43.76619
			],
			[
				-79.20174,
				43.76558
			],
			[
				-79.20178,
				43.76524
			],
			[
				-79.20119,
				43.76427
			],
			[
				-79.20125,
				43.76331
			],
			[
				-79.20099,
				43.76292
			],
			[
				-79.2014,
				43.76267
			],
			[
				-79.20159,
				43.76235
			],
			[
				-79.20173,
				43.76233
			],
			[
				-79.20235,
				43.76279
			],
			[
				-79.20261,
				43.76279
			],
			[
				-79.20287,
				43.76234
			],
			[
				-79.20294,
				43.76146
			],
			[
				-79.20278,
				43.76103
			],
			[
				-79.20275,
				43.76048
			],
			[
				-79.20302,
				43.75934
			],
			[
				-79.2022,
				43.75868
			],
			[
				-79.20206,
				43.75788
			],
			[
				-79.20242,
				43.75745
			],
			[
				-79.20275,
				43.75739
			],
			[
				-79.20284,
				43.7573
			],
			[
				-79.20307,
				43.75671
			],
			[
				-79.20284,
				43.75641
			],
			[
				-79.20283,
				43.75625
			],
			[
				-79.20292,
				43.75616
			],
			[
				-79.20275,
				43.75592
			],
			[
				-79.201,
				43.75625
			],
			[
				-79.20069,
				43.7564
			],
			[
				-79.20051,
				43.75676
			],
			[
				-79.20073,
				43.75743
			],
			[
				-79.20068,
				43.75752
			],
			[
				-79.20014,
				43.75763
			],
			[
				-79.19988,
				43.75734
			],
			[
				-79.19948,
				43.75711
			],
			[
				-79.19957,
				43.75694
			],
			[
				-79.20073,
				43.75562
			],
			[
				-79.20238,
				43.75398
			],
			[
				-79.20512,
				43.75075
			],
			[
				-79.20751,
				43.74822
			],
			[
				-79.20799,
				43.74759
			],
			[
				-79.20971,
				43.74585
			],
			[
				-79.21078,
				43.74461
			],
			[
				-79.21706,
				43.73901
			],
			[
				-79.2196,
				43.73658
			],
			[
				-79.22058,
				43.73584
			],
			[
				-79.22714,
				43.72987
			],
			[
				-79.22969,
				43.72772
			],
			[
				-79.23117,
				43.72631
			],
			[
				-79.23206,
				43.72562
			],
			[
				-79.23694,
				43.72133
			],
			[
				-79.24173,
				43.71727
			],
			[
				-79.24791,
				43.71175
			],
			[
				-79.24912,
				43.71012
			],
			[
				-79.25121,
				43.70677
			],
			[
				-79.25148,
				43.70622
			],
			[
				-79.25339,
				43.70324
			],
			[
				-79.25402,
				43.70266
			],
			[
				-79.25506,
				43.70191
			],
			[
				-79.2567,
				43.70041
			],
			[
				-79.25805,
				43.69942
			],
			[
				-79.25911,
				43.69893
			],
			[
				-79.26627,
				43.69622
			],
			[
				-79.26898,
				43.69536
			],
			[
				-79.2736,
				43.69449
			],
			[
				-79.27558,
				43.69402
			],
			[
				-79.27745,
				43.69372
			],
			[
				-79.27811,
				43.69352
			],
			[
				-79.27902,
				43.69341
			],
			[
				-79.28213,
				43.69266
			],
			[
				-79.28833,
				43.69134
			],
			[
				-79.2894,
				43.69103
			],
			[
				-79.29638,
				43.68944
			],
			[
				-79.29682,
				43.6894
			],
			[
				-79.29789,
				43.68906
			],
			[
				-79.29943,
				43.68879
			],
			[
				-79.30302,
				43.68794
			],
			[
				-79.3091,
				43.68665
			],
			[
				-79.31276,
				43.68577
			],
			[
				-79.31347,
				43.68552
			],
			[
				-79.31507,
				43.68523
			],
			[
				-79.31525,
				43.68526
			],
			[
				-79.31984,
				43.6842
			],
			[
				-79.32175,
				43.68384
			],
			[
				-79.32284,
				43.68351
			],
			[
				-79.32421,
				43.68335
			],
			[
				-79.32428,
				43.68328
			],
			[
				-79.32944,
				43.68212
			],
			[
				-79.33297,
				43.68143
			],
			[
				-79.33724,
				43.68047
			],
			[
				-79.34362,
				43.67921
			],
			[
				-79.34454,
				43.67911
			],
			[
				-79.34589,
				43.67876
			],
			[
				-79.3496,
				43.67811
			],
			[
				-79.35017,
				43.67793
			],
			[
				-79.35456,
				43.67714
			],
			[
				-79.35668,
				43.67666
			],
			[
				-79.35888,
				43.67629
			],
			[
				-79.3587,
				43.67615
			],
			[
				-79.35866,
				43.67627
			],
			[
				-79.35892,
				43.67643
			],
			[
				-79.35886,
				43.67635
			],
			[
				-79.35902,
				43.67626
			],
			[
				-79.36674,
				43.67478
			],
			[
				-79.36732,
				43.67451
			],
			[
				-79.37052,
				43.67226
			],
			[
				-79.37116,
				43.672
			],
			[
				-79.37201,
				43.67199
			],
			[
				-79.37576,
				43.67251
			],
			[
				-79.37682,
				43.67236
			],
			[
				-79.3769,
				43.67224
			],
			[
				-79.37684,
				43.67184
			],
			[
				-79.37671,
				43.67174
			],
			[
				-79.37653,
				43.67124
			],
			[
				-79.37641,
				43.67123
			],
			[
				-79.37469,
				43.66715
			],
			[
				-79.37479,
				43.66695
			],
			[
				-79.37546,
				43.66658
			],
			[
				-79.37622,
				43.66641
			],
			[
				-79.37724,
				43.6665
			],
			[
				-79.37804,
				43.66636
			],
			[
				-79.39283,
				43.66316
			],
			[
				-79.39318,
				43.6637
			],
			[
				-79.39351,
				43.66402
			],
			[
				-79.39382,
				43.66507
			],
			[
				-79.39406,
				43.66508
			],
			[
				-79.39454,
				43.6649
			],
			[
				-79.39823,
				43.66413
			],
			[
				-79.39897,
				43.66373
			],
			[
				-79.4,
				43.6635
			],
			[
				-79.40854,
				43.6618
			],
			[
				-79.4169,
				43.66002
			],
			[
				-79.42022,
				43.65938
			],
			[
				-79.4207,
				43.65935
			],
			[
				-79.42126,
				43.65915
			],
			[
				-79.4243,
				43.65853
			],
			[
				-79.42415,
				43.65789
			],
			[
				-79.42361,
				43.65679
			],
			[
				-79.42282,
				43.65442
			]
		],
		"dupe": false
	},
	{
		"id": 323901282,
		"name": "Morning Ride",
		"distance": 2978.4,
		"moving_time": 471,
		"elapsed_time": 494,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-12T10:34:21Z",
		"average_speed": 6.324,
		"max_speed": 13.2,
		"calories": 54.4,
		"points": [
			[
				-79.42342,
				43.65333
			],
			[
				-79.426,
				43.65273
			],
			[
				-79.42535,
				43.65092
			],
			[
				-79.42244,
				43.6436
			],
			[
				-79.4336,
				43.64121
			],
			[
				-79.43378,
				43.64126
			],
			[
				-79.43422,
				43.64118
			],
			[
				-79.43596,
				43.64077
			],
			[
				-79.43663,
				43.64069
			],
			[
				-79.43768,
				43.64038
			],
			[
				-79.43871,
				43.64027
			],
			[
				-79.43939,
				43.64007
			],
			[
				-79.44145,
				43.63973
			]
		],
		"dupe": false
	},
	{
		"id": 325343308,
		"name": "Lunch Ride",
		"distance": 2748.2,
		"moving_time": 564,
		"elapsed_time": 574,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-14T11:29:58Z",
		"average_speed": 4.873,
		"max_speed": 9,
		"calories": 51.2,
		"points": [
			[
				-79.42547,
				43.65295
			],
			[
				-79.42542,
				43.65286
			],
			[
				-79.42599,
				43.65273
			],
			[
				-79.4264,
				43.6536
			],
			[
				-79.42718,
				43.65351
			],
			[
				-79.42998,
				43.65292
			],
			[
				-79.43032,
				43.65296
			],
			[
				-79.43187,
				43.65277
			],
			[
				-79.43245,
				43.65267
			],
			[
				-79.43248,
				43.65233
			],
			[
				-79.43234,
				43.6518
			],
			[
				-79.43243,
				43.65169
			],
			[
				-79.4342,
				43.65132
			],
			[
				-79.43439,
				43.65144
			],
			[
				-79.43433,
				43.65144
			],
			[
				-79.43606,
				43.65113
			],
			[
				-79.43639,
				43.6518
			],
			[
				-79.43666,
				43.65181
			],
			[
				-79.4398,
				43.65118
			],
			[
				-79.44098,
				43.65035
			],
			[
				-79.44114,
				43.65031
			],
			[
				-79.44385,
				43.65084
			],
			[
				-79.44442,
				43.65102
			],
			[
				-79.44507,
				43.65113
			],
			[
				-79.44529,
				43.6511
			],
			[
				-79.44489,
				43.64996
			],
			[
				-79.4464,
				43.6497
			],
			[
				-79.44701,
				43.64951
			],
			[
				-79.44815,
				43.64941
			],
			[
				-79.44851,
				43.64928
			],
			[
				-79.44898,
				43.64937
			],
			[
				-79.44909,
				43.64946
			],
			[
				-79.44905,
				43.64961
			],
			[
				-79.4494,
				43.65058
			],
			[
				-79.45014,
				43.65055
			],
			[
				-79.45046,
				43.65047
			],
			[
				-79.45053,
				43.65035
			],
			[
				-79.45054,
				43.65045
			],
			[
				-79.45077,
				43.65052
			]
		],
		"dupe": false
	},
	{
		"id": 326045287,
		"name": "Lunch Ride",
		"distance": 3168.8,
		"moving_time": 547,
		"elapsed_time": 689,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-15T12:47:54Z",
		"average_speed": 5.793,
		"max_speed": 11.9,
		"calories": 71.8,
		"points": [
			[
				-79.43013,
				43.6464
			],
			[
				-79.43083,
				43.648
			],
			[
				-79.43082,
				43.64816
			],
			[
				-79.43104,
				43.6488
			],
			[
				-79.43136,
				43.64952
			],
			[
				-79.43108,
				43.64961
			],
			[
				-79.42947,
				43.6495
			],
			[
				-79.42671,
				43.64953
			],
			[
				-79.42501,
				43.64939
			],
			[
				-79.42277,
				43.64939
			],
			[
				-79.42093,
				43.64929
			],
			[
				-79.41997,
				43.64943
			],
			[
				-79.41627,
				43.65023
			],
			[
				-79.41584,
				43.65026
			],
			[
				-79.41165,
				43.65118
			],
			[
				-79.41117,
				43.65116
			],
			[
				-79.41142,
				43.65111
			],
			[
				-79.41118,
				43.65116
			],
			[
				-79.41125,
				43.65115
			],
			[
				-79.41121,
				43.65099
			],
			[
				-79.41125,
				43.65105
			],
			[
				-79.41134,
				43.651
			],
			[
				-79.4112,
				43.65109
			],
			[
				-79.4111,
				43.6513
			],
			[
				-79.41099,
				43.65133
			],
			[
				-79.40627,
				43.65225
			],
			[
				-79.40553,
				43.65233
			],
			[
				-79.40415,
				43.65173
			],
			[
				-79.40376,
				43.65167
			],
			[
				-79.40117,
				43.65229
			],
			[
				-79.39994,
				43.65249
			],
			[
				-79.39991,
				43.65256
			],
			[
				-79.3999,
				43.65251
			],
			[
				-79.39998,
				43.6526
			]
		],
		"dupe": false
	},
	{
		"id": 326274030,
		"name": "Evening Ride",
		"distance": 1573.2,
		"moving_time": 278,
		"elapsed_time": 346,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-15T18:55:47Z",
		"average_speed": 5.659,
		"max_speed": 13.4,
		"calories": 31,
		"points": [
			[
				-79.42948,
				43.644
			],
			[
				-79.42941,
				43.64424
			],
			[
				-79.42906,
				43.64308
			],
			[
				-79.42853,
				43.64196
			],
			[
				-79.42863,
				43.64202
			],
			[
				-79.42861,
				43.64212
			],
			[
				-79.42875,
				43.64214
			],
			[
				-79.42858,
				43.64223
			],
			[
				-79.42913,
				43.6422
			],
			[
				-79.43651,
				43.64071
			],
			[
				-79.43642,
				43.64064
			],
			[
				-79.4365,
				43.64071
			],
			[
				-79.44208,
				43.63961
			],
			[
				-79.44193,
				43.63964
			],
			[
				-79.44198,
				43.63963
			]
		],
		"dupe": false
	},
	{
		"id": 326075825,
		"name": "Afternoon Ride",
		"distance": 1957.9,
		"moving_time": 389,
		"elapsed_time": 389,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-15T13:36:10Z",
		"average_speed": 5.033,
		"max_speed": 9.9,
		"calories": 30,
		"points": [
			[
				-79.40011,
				43.65276
			],
			[
				-79.39996,
				43.6526
			],
			[
				-79.40001,
				43.65248
			],
			[
				-79.40221,
				43.65211
			],
			[
				-79.40219,
				43.65185
			],
			[
				-79.40191,
				43.65134
			],
			[
				-79.40175,
				43.65092
			],
			[
				-79.40179,
				43.65085
			],
			[
				-79.40063,
				43.64792
			],
			[
				-79.40174,
				43.64764
			],
			[
				-79.40365,
				43.6473
			],
			[
				-79.40377,
				43.64722
			],
			[
				-79.40614,
				43.64678
			],
			[
				-79.40652,
				43.64677
			],
			[
				-79.40826,
				43.64632
			],
			[
				-79.40983,
				43.64607
			],
			[
				-79.41035,
				43.64586
			],
			[
				-79.41095,
				43.64582
			],
			[
				-79.41302,
				43.64545
			],
			[
				-79.41471,
				43.64504
			],
			[
				-79.41506,
				43.64503
			],
			[
				-79.41498,
				43.64499
			]
		],
		"dupe": false
	},
	{
		"id": 326654585,
		"name": "Lunch Ride",
		"distance": 10499.6,
		"moving_time": 1596,
		"elapsed_time": 1645,
		"total_elevation_gain": 31.1,
		"start_date_local": "2015-06-16T12:00:01Z",
		"average_speed": 6.579,
		"max_speed": 12.5,
		"calories": 217.4,
		"points": [
			[
				-79.42345,
				43.65307
			],
			[
				-79.42348,
				43.65325
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.42241,
				43.6435
			],
			[
				-79.43315,
				43.6414
			],
			[
				-79.43381,
				43.64118
			],
			[
				-79.43648,
				43.64072
			],
			[
				-79.43639,
				43.64057
			],
			[
				-79.43635,
				43.64063
			],
			[
				-79.43659,
				43.64055
			],
			[
				-79.43762,
				43.64049
			],
			[
				-79.43946,
				43.64011
			],
			[
				-79.44018,
				43.63988
			],
			[
				-79.44138,
				43.63975
			],
			[
				-79.4465,
				43.63871
			],
			[
				-79.44728,
				43.63867
			],
			[
				-79.44807,
				43.63875
			],
			[
				-79.45064,
				43.63925
			],
			[
				-79.4528,
				43.6398
			],
			[
				-79.45344,
				43.63985
			],
			[
				-79.4579,
				43.63977
			],
			[
				-79.45993,
				43.63959
			],
			[
				-79.46112,
				43.63937
			],
			[
				-79.46641,
				43.63792
			],
			[
				-79.46913,
				43.63741
			],
			[
				-79.47055,
				43.63724
			],
			[
				-79.47192,
				43.63678
			],
			[
				-79.47271,
				43.63634
			],
			[
				-79.47331,
				43.63589
			],
			[
				-79.47652,
				43.63269
			],
			[
				-79.47737,
				43.63228
			],
			[
				-79.4806,
				43.63125
			],
			[
				-79.48198,
				43.63089
			],
			[
				-79.49036,
				43.62912
			],
			[
				-79.49023,
				43.62911
			],
			[
				-79.49047,
				43.6293
			],
			[
				-79.4934,
				43.63613
			],
			[
				-79.49947,
				43.63484
			],
			[
				-79.49975,
				43.63527
			],
			[
				-79.50532,
				43.64825
			],
			[
				-79.50783,
				43.64765
			],
			[
				-79.50796,
				43.64796
			],
			[
				-79.50753,
				43.64811
			]
		],
		"dupe": false
	},
	{
		"id": 326701572,
		"name": "Afternoon Ride",
		"distance": 8880.9,
		"moving_time": 1343,
		"elapsed_time": 1615,
		"total_elevation_gain": 16,
		"start_date_local": "2015-06-16T13:01:19Z",
		"average_speed": 6.613,
		"max_speed": 16.7,
		"calories": 178,
		"points": [
			[
				-79.50765,
				43.64826
			],
			[
				-79.5075,
				43.64814
			],
			[
				-79.50567,
				43.64854
			],
			[
				-79.50554,
				43.64854
			],
			[
				-79.50535,
				43.64835
			],
			[
				-79.49958,
				43.6349
			],
			[
				-79.49919,
				43.63488
			],
			[
				-79.49353,
				43.6361
			],
			[
				-79.49333,
				43.63596
			],
			[
				-79.49041,
				43.62915
			],
			[
				-79.49024,
				43.62893
			],
			[
				-79.49006,
				43.62904
			],
			[
				-79.48353,
				43.63048
			],
			[
				-79.48004,
				43.63123
			],
			[
				-79.47853,
				43.63142
			],
			[
				-79.47722,
				43.63177
			],
			[
				-79.47672,
				43.63206
			],
			[
				-79.47525,
				43.63375
			],
			[
				-79.47375,
				43.63507
			],
			[
				-79.47339,
				43.63554
			],
			[
				-79.47195,
				43.63647
			],
			[
				-79.47056,
				43.63699
			],
			[
				-79.46803,
				43.63735
			],
			[
				-79.46664,
				43.63763
			],
			[
				-79.46149,
				43.63909
			],
			[
				-79.45986,
				43.63938
			],
			[
				-79.4579,
				43.63959
			],
			[
				-79.45251,
				43.63962
			],
			[
				-79.45098,
				43.63916
			],
			[
				-79.44736,
				43.63847
			],
			[
				-79.44623,
				43.63861
			],
			[
				-79.44234,
				43.63945
			],
			[
				-79.4413,
				43.63957
			],
			[
				-79.44117,
				43.63966
			],
			[
				-79.43563,
				43.64071
			],
			[
				-79.4348,
				43.6409
			],
			[
				-79.43464,
				43.641
			],
			[
				-79.42927,
				43.64209
			],
			[
				-79.42869,
				43.64215
			],
			[
				-79.42823,
				43.6423
			],
			[
				-79.42882,
				43.64303
			],
			[
				-79.429,
				43.64353
			],
			[
				-79.42942,
				43.64398
			],
			[
				-79.42953,
				43.64427
			],
			[
				-79.42967,
				43.6443
			],
			[
				-79.42959,
				43.64429
			]
		],
		"dupe": false
	},
	{
		"id": 327374847,
		"name": "Lunch Ride",
		"distance": 1732.4,
		"moving_time": 295,
		"elapsed_time": 295,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-17T12:50:52Z",
		"average_speed": 5.873,
		"max_speed": 11,
		"calories": 23.8,
		"points": [
			[
				-79.42937,
				43.64421
			],
			[
				-79.42944,
				43.64396
			],
			[
				-79.42928,
				43.6438
			],
			[
				-79.42896,
				43.64291
			],
			[
				-79.42879,
				43.6421
			],
			[
				-79.42892,
				43.64218
			],
			[
				-79.42925,
				43.64215
			],
			[
				-79.43144,
				43.64167
			],
			[
				-79.43214,
				43.64159
			],
			[
				-79.433,
				43.64135
			],
			[
				-79.4341,
				43.64122
			],
			[
				-79.43981,
				43.63996
			],
			[
				-79.44597,
				43.63886
			],
			[
				-79.4459,
				43.63883
			],
			[
				-79.44609,
				43.63902
			]
		],
		"dupe": false
	},
	{
		"id": 328897901,
		"name": "Evening Ride",
		"distance": 10863.7,
		"moving_time": 1599,
		"elapsed_time": 1668,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-19T19:11:54Z",
		"average_speed": 6.794,
		"max_speed": 12.6,
		"calories": 224,
		"points": [
			[
				-79.42951,
				43.64426
			],
			[
				-79.42925,
				43.64377
			],
			[
				-79.42795,
				43.64404
			],
			[
				-79.42775,
				43.64402
			],
			[
				-79.42705,
				43.64231
			],
			[
				-79.42698,
				43.64245
			],
			[
				-79.42646,
				43.64264
			],
			[
				-79.4239,
				43.6431
			],
			[
				-79.42288,
				43.64341
			],
			[
				-79.42251,
				43.64347
			],
			[
				-79.42274,
				43.64341
			],
			[
				-79.42261,
				43.64334
			],
			[
				-79.42239,
				43.64338
			],
			[
				-79.42003,
				43.64399
			],
			[
				-79.41973,
				43.64399
			],
			[
				-79.41944,
				43.64413
			],
			[
				-79.41885,
				43.64408
			],
			[
				-79.41698,
				43.64458
			],
			[
				-79.41528,
				43.64489
			],
			[
				-79.41518,
				43.64485
			],
			[
				-79.41497,
				43.64495
			],
			[
				-79.41411,
				43.64506
			],
			[
				-79.41348,
				43.64529
			],
			[
				-79.41023,
				43.64589
			],
			[
				-79.40992,
				43.64587
			],
			[
				-79.40977,
				43.64526
			],
			[
				-79.40377,
				43.64646
			],
			[
				-79.40352,
				43.64595
			],
			[
				-79.40343,
				43.64555
			],
			[
				-79.4032,
				43.64534
			],
			[
				-79.40275,
				43.64529
			],
			[
				-79.39804,
				43.6462
			],
			[
				-79.39602,
				43.64677
			],
			[
				-79.39502,
				43.64682
			],
			[
				-79.39302,
				43.64733
			],
			[
				-79.39296,
				43.64728
			],
			[
				-79.39199,
				43.64741
			],
			[
				-79.39179,
				43.64751
			],
			[
				-79.39088,
				43.64766
			],
			[
				-79.39029,
				43.64791
			],
			[
				-79.38923,
				43.64812
			],
			[
				-79.38874,
				43.64831
			],
			[
				-79.38861,
				43.64823
			],
			[
				-79.3883,
				43.64833
			],
			[
				-79.3875,
				43.64839
			],
			[
				-79.38684,
				43.64862
			],
			[
				-79.38594,
				43.64867
			],
			[
				-79.38582,
				43.64898
			],
			[
				-79.38584,
				43.64915
			],
			[
				-79.38589,
				43.64904
			],
			[
				-79.38583,
				43.64909
			],
			[
				-79.3856,
				43.64855
			],
			[
				-79.38568,
				43.64854
			],
			[
				-79.38583,
				43.64874
			],
			[
				-79.38582,
				43.64898
			],
			[
				-79.38569,
				43.64883
			],
			[
				-79.3809,
				43.64985
			],
			[
				-79.38108,
				43.64981
			],
			[
				-79.38069,
				43.64971
			],
			[
				-79.38044,
				43.64982
			],
			[
				-79.38025,
				43.64979
			],
			[
				-79.38022,
				43.65
			],
			[
				-79.37312,
				43.65157
			],
			[
				-79.37265,
				43.6516
			],
			[
				-79.37181,
				43.65148
			],
			[
				-79.37146,
				43.65152
			],
			[
				-79.36477,
				43.65297
			],
			[
				-79.36406,
				43.65325
			],
			[
				-79.36187,
				43.65462
			],
			[
				-79.36106,
				43.6549
			],
			[
				-79.35996,
				43.655
			],
			[
				-79.35694,
				43.65476
			],
			[
				-79.35634,
				43.65479
			],
			[
				-79.35551,
				43.65507
			],
			[
				-79.35373,
				43.6562
			],
			[
				-79.35203,
				43.6568
			],
			[
				-79.35024,
				43.65692
			],
			[
				-79.34853,
				43.65647
			],
			[
				-79.34774,
				43.65644
			],
			[
				-79.33526,
				43.65911
			],
			[
				-79.33283,
				43.65972
			],
			[
				-79.33178,
				43.65989
			],
			[
				-79.33085,
				43.66021
			],
			[
				-79.32985,
				43.66128
			],
			[
				-79.32909,
				43.66159
			],
			[
				-79.32529,
				43.66247
			],
			[
				-79.32437,
				43.66278
			],
			[
				-79.32324,
				43.66294
			],
			[
				-79.3208,
				43.66357
			],
			[
				-79.32052,
				43.66356
			],
			[
				-79.31975,
				43.66374
			],
			[
				-79.31914,
				43.66408
			],
			[
				-79.31808,
				43.66496
			],
			[
				-79.31627,
				43.66554
			],
			[
				-79.31634,
				43.66555
			],
			[
				-79.31625,
				43.66578
			],
			[
				-79.31645,
				43.66629
			],
			[
				-79.31647,
				43.66624
			],
			[
				-79.3162,
				43.66638
			],
			[
				-79.31522,
				43.6666
			],
			[
				-79.31531,
				43.66659
			],
			[
				-79.3149,
				43.66656
			],
			[
				-79.31552,
				43.66649
			]
		],
		"dupe": false
	},
	{
		"id": 328967579,
		"name": "Night Ride",
		"distance": 8430.4,
		"moving_time": 1185,
		"elapsed_time": 1194,
		"total_elevation_gain": 12.7,
		"start_date_local": "2015-06-19T23:18:42Z",
		"average_speed": 7.114,
		"max_speed": 11.7,
		"calories": 178,
		"points": [
			[
				-79.32432,
				43.66792
			],
			[
				-79.32423,
				43.66773
			],
			[
				-79.32442,
				43.66779
			],
			[
				-79.32489,
				43.66768
			],
			[
				-79.3281,
				43.66761
			],
			[
				-79.32962,
				43.66732
			],
			[
				-79.33209,
				43.66665
			],
			[
				-79.33422,
				43.66621
			],
			[
				-79.33704,
				43.66589
			],
			[
				-79.33921,
				43.66545
			],
			[
				-79.3417,
				43.66489
			],
			[
				-79.34282,
				43.66429
			],
			[
				-79.34362,
				43.66404
			],
			[
				-79.34766,
				43.66339
			],
			[
				-79.35328,
				43.66203
			],
			[
				-79.35627,
				43.66167
			],
			[
				-79.36028,
				43.66071
			],
			[
				-79.36416,
				43.65989
			],
			[
				-79.36491,
				43.65965
			],
			[
				-79.36603,
				43.65951
			],
			[
				-79.37022,
				43.65852
			],
			[
				-79.37212,
				43.65833
			],
			[
				-79.37273,
				43.65807
			],
			[
				-79.37343,
				43.65741
			],
			[
				-79.37378,
				43.65727
			],
			[
				-79.37451,
				43.65717
			],
			[
				-79.37888,
				43.65621
			],
			[
				-79.38031,
				43.65645
			],
			[
				-79.38087,
				43.65635
			],
			[
				-79.38089,
				43.65627
			],
			[
				-79.38096,
				43.6564
			],
			[
				-79.38638,
				43.65525
			],
			[
				-79.38794,
				43.65485
			],
			[
				-79.38789,
				43.65485
			],
			[
				-79.38866,
				43.6548
			],
			[
				-79.39001,
				43.65452
			],
			[
				-79.39095,
				43.65449
			],
			[
				-79.3944,
				43.65368
			],
			[
				-79.39744,
				43.65311
			],
			[
				-79.39854,
				43.65279
			],
			[
				-79.39986,
				43.6526
			],
			[
				-79.40366,
				43.65177
			],
			[
				-79.4041,
				43.65182
			],
			[
				-79.40547,
				43.65242
			],
			[
				-79.40597,
				43.6524
			],
			[
				-79.41152,
				43.65117
			],
			[
				-79.41863,
				43.64982
			],
			[
				-79.4201,
				43.64946
			],
			[
				-79.42146,
				43.64937
			],
			[
				-79.42231,
				43.64943
			],
			[
				-79.4222,
				43.64939
			]
		],
		"dupe": false
	},
	{
		"id": 329522398,
		"name": "Afternoon Ride",
		"distance": 1674.9,
		"moving_time": 378,
		"elapsed_time": 382,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-20T16:47:42Z",
		"average_speed": 4.431,
		"max_speed": 8.9,
		"calories": 27.6,
		"points": [
			[
				-79.42382,
				43.65412
			],
			[
				-79.42374,
				43.65402
			],
			[
				-79.42374,
				43.65412
			],
			[
				-79.42365,
				43.65416
			],
			[
				-79.42284,
				43.65428
			],
			[
				-79.42251,
				43.65443
			],
			[
				-79.42198,
				43.65448
			],
			[
				-79.42064,
				43.65482
			],
			[
				-79.42041,
				43.65498
			],
			[
				-79.42046,
				43.65529
			],
			[
				-79.42029,
				43.65541
			],
			[
				-79.41965,
				43.65543
			],
			[
				-79.41922,
				43.65557
			],
			[
				-79.41868,
				43.65558
			],
			[
				-79.41856,
				43.65566
			],
			[
				-79.41801,
				43.65572
			],
			[
				-79.41783,
				43.65567
			],
			[
				-79.41749,
				43.65577
			],
			[
				-79.41712,
				43.65556
			],
			[
				-79.41664,
				43.65564
			],
			[
				-79.41652,
				43.65544
			],
			[
				-79.41602,
				43.65549
			],
			[
				-79.41586,
				43.6553
			],
			[
				-79.41568,
				43.65525
			],
			[
				-79.41525,
				43.65536
			],
			[
				-79.41519,
				43.65541
			],
			[
				-79.41524,
				43.65568
			],
			[
				-79.41639,
				43.65855
			],
			[
				-79.41558,
				43.65871
			],
			[
				-79.41538,
				43.65873
			],
			[
				-79.41528,
				43.65864
			],
			[
				-79.41445,
				43.65669
			],
			[
				-79.41431,
				43.65614
			],
			[
				-79.41402,
				43.65557
			]
		],
		"dupe": false
	},
	{
		"id": 329624834,
		"name": "Night Ride",
		"distance": 2225.8,
		"moving_time": 552,
		"elapsed_time": 552,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-20T21:23:45Z",
		"average_speed": 4.032,
		"max_speed": 7.9,
		"calories": 32,
		"points": [
			[
				-79.41005,
				43.65625
			],
			[
				-79.41012,
				43.65627
			],
			[
				-79.41008,
				43.65633
			],
			[
				-79.41032,
				43.65629
			],
			[
				-79.41153,
				43.6593
			],
			[
				-79.41396,
				43.6588
			],
			[
				-79.41403,
				43.65868
			],
			[
				-79.41393,
				43.65869
			],
			[
				-79.41514,
				43.65843
			],
			[
				-79.41502,
				43.65835
			],
			[
				-79.41528,
				43.65878
			],
			[
				-79.41626,
				43.65858
			],
			[
				-79.41636,
				43.65846
			],
			[
				-79.41525,
				43.65541
			],
			[
				-79.41582,
				43.65525
			],
			[
				-79.41569,
				43.6553
			],
			[
				-79.41582,
				43.65528
			],
			[
				-79.41589,
				43.65559
			],
			[
				-79.41602,
				43.65543
			],
			[
				-79.41646,
				43.65542
			],
			[
				-79.41663,
				43.65573
			],
			[
				-79.41677,
				43.65575
			],
			[
				-79.41678,
				43.65566
			],
			[
				-79.41691,
				43.65563
			],
			[
				-79.41726,
				43.65563
			],
			[
				-79.41731,
				43.65575
			],
			[
				-79.41727,
				43.65566
			],
			[
				-79.4173,
				43.65574
			],
			[
				-79.41927,
				43.65556
			],
			[
				-79.42037,
				43.65531
			],
			[
				-79.42042,
				43.65522
			],
			[
				-79.4203,
				43.65491
			],
			[
				-79.4204,
				43.65482
			],
			[
				-79.42103,
				43.65476
			],
			[
				-79.42271,
				43.6544
			],
			[
				-79.42241,
				43.65352
			],
			[
				-79.42336,
				43.6533
			]
		],
		"dupe": false
	},
	{
		"id": 330433980,
		"name": "hip 2 b… rectangle?",
		"distance": 33967.1,
		"moving_time": 4767,
		"elapsed_time": 5369,
		"total_elevation_gain": 128.6,
		"start_date_local": "2015-06-21T22:05:30Z",
		"average_speed": 7.125,
		"max_speed": 15.9,
		"calories": 707.6,
		"points": [
			[
				-79.42634,
				43.65367
			],
			[
				-79.42649,
				43.6538
			],
			[
				-79.42932,
				43.6611
			],
			[
				-79.42962,
				43.66155
			],
			[
				-79.42965,
				43.66218
			],
			[
				-79.43169,
				43.66715
			],
			[
				-79.43165,
				43.66734
			],
			[
				-79.43192,
				43.66794
			],
			[
				-79.43204,
				43.66798
			],
			[
				-79.43259,
				43.66924
			],
			[
				-79.43462,
				43.67418
			],
			[
				-79.43368,
				43.67441
			],
			[
				-79.43378,
				43.67445
			],
			[
				-79.43577,
				43.67927
			],
			[
				-79.43556,
				43.67959
			],
			[
				-79.43571,
				43.67961
			],
			[
				-79.43897,
				43.68779
			],
			[
				-79.43969,
				43.68997
			],
			[
				-79.44013,
				43.69104
			],
			[
				-79.44011,
				43.69119
			],
			[
				-79.4402,
				43.69122
			],
			[
				-79.44081,
				43.69259
			],
			[
				-79.4428,
				43.69729
			],
			[
				-79.44328,
				43.69726
			],
			[
				-79.44597,
				43.69672
			],
			[
				-79.4485,
				43.69611
			],
			[
				-79.45061,
				43.69573
			],
			[
				-79.4512,
				43.69553
			],
			[
				-79.45163,
				43.69549
			],
			[
				-79.45475,
				43.69474
			],
			[
				-79.45666,
				43.69437
			],
			[
				-79.4585,
				43.69386
			],
			[
				-79.46308,
				43.69293
			],
			[
				-79.46435,
				43.69232
			],
			[
				-79.46509,
				43.69209
			],
			[
				-79.46889,
				43.69163
			],
			[
				-79.46933,
				43.69146
			],
			[
				-79.46976,
				43.69143
			],
			[
				-79.47252,
				43.69073
			],
			[
				-79.47331,
				43.69064
			],
			[
				-79.47388,
				43.69045
			],
			[
				-79.47962,
				43.68931
			],
			[
				-79.4846,
				43.688
			],
			[
				-79.48699,
				43.68751
			],
			[
				-79.48767,
				43.68752
			],
			[
				-79.48778,
				43.68739
			],
			[
				-79.48934,
				43.68715
			],
			[
				-79.49341,
				43.68611
			],
			[
				-79.49791,
				43.68471
			],
			[
				-79.50098,
				43.68408
			],
			[
				-79.50288,
				43.68411
			],
			[
				-79.5057,
				43.6847
			],
			[
				-79.50654,
				43.68476
			],
			[
				-79.5074,
				43.68469
			],
			[
				-79.51174,
				43.684
			],
			[
				-79.51141,
				43.68375
			],
			[
				-79.51127,
				43.68378
			],
			[
				-79.51182,
				43.68375
			],
			[
				-79.51225,
				43.68363
			],
			[
				-79.51235,
				43.6837
			],
			[
				-79.51377,
				43.68348
			],
			[
				-79.51444,
				43.68349
			],
			[
				-79.51499,
				43.6834
			],
			[
				-79.51742,
				43.68342
			],
			[
				-79.51995,
				43.68328
			],
			[
				-79.52037,
				43.68337
			],
			[
				-79.52103,
				43.68333
			],
			[
				-79.52627,
				43.68227
			],
			[
				-79.5263,
				43.68242
			],
			[
				-79.52628,
				43.68233
			],
			[
				-79.52631,
				43.6824
			],
			[
				-79.52675,
				43.68217
			],
			[
				-79.52765,
				43.68197
			],
			[
				-79.53153,
				43.6813
			],
			[
				-79.54032,
				43.67952
			],
			[
				-79.54211,
				43.67906
			],
			[
				-79.54304,
				43.67898
			],
			[
				-79.54951,
				43.67745
			],
			[
				-79.55048,
				43.67734
			],
			[
				-79.55107,
				43.67707
			],
			[
				-79.55129,
				43.67685
			],
			[
				-79.5515,
				43.67697
			],
			[
				-79.56204,
				43.67457
			],
			[
				-79.56209,
				43.67435
			],
			[
				-79.5622,
				43.67429
			],
			[
				-79.56243,
				43.67425
			],
			[
				-79.56276,
				43.67432
			],
			[
				-79.5631,
				43.67427
			],
			[
				-79.56316,
				43.67418
			],
			[
				-79.56301,
				43.674
			],
			[
				-79.56209,
				43.67184
			],
			[
				-79.56091,
				43.66947
			],
			[
				-79.56054,
				43.66846
			],
			[
				-79.55993,
				43.66731
			],
			[
				-79.55985,
				43.66697
			],
			[
				-79.55962,
				43.66669
			],
			[
				-79.55914,
				43.66551
			],
			[
				-79.55884,
				43.66506
			],
			[
				-79.55761,
				43.66208
			],
			[
				-79.55695,
				43.66091
			],
			[
				-79.55622,
				43.65936
			],
			[
				-79.55565,
				43.65801
			],
			[
				-79.55549,
				43.65779
			],
			[
				-79.55535,
				43.65726
			],
			[
				-79.55504,
				43.6568
			],
			[
				-79.55479,
				43.65658
			],
			[
				-79.55375,
				43.65595
			],
			[
				-79.55183,
				43.65496
			],
			[
				-79.55162,
				43.6547
			],
			[
				-79.55102,
				43.6533
			],
			[
				-79.55057,
				43.65253
			],
			[
				-79.55053,
				43.65225
			],
			[
				-79.55038,
				43.65201
			],
			[
				-79.54917,
				43.64883
			],
			[
				-79.54901,
				43.64876
			],
			[
				-79.54756,
				43.64548
			],
			[
				-79.54769,
				43.64526
			],
			[
				-79.55007,
				43.64471
			],
			[
				-79.55009,
				43.6446
			],
			[
				-79.5479,
				43.63945
			],
			[
				-79.54773,
				43.6386
			],
			[
				-79.54752,
				43.63801
			],
			[
				-79.54543,
				43.63292
			],
			[
				-79.5439,
				43.63115
			],
			[
				-79.54364,
				43.6307
			],
			[
				-79.54317,
				43.62839
			],
			[
				-79.54066,
				43.62253
			],
			[
				-79.54097,
				43.62239
			],
			[
				-79.54258,
				43.62203
			],
			[
				-79.54262,
				43.62195
			],
			[
				-79.54238,
				43.62119
			],
			[
				-79.54091,
				43.61785
			],
			[
				-79.54114,
				43.61773
			],
			[
				-79.54082,
				43.61767
			],
			[
				-79.54091,
				43.61766
			],
			[
				-79.53644,
				43.61874
			],
			[
				-79.53425,
				43.61909
			],
			[
				-79.5257,
				43.62113
			],
			[
				-79.51784,
				43.62282
			],
			[
				-79.51644,
				43.62324
			],
			[
				-79.51493,
				43.62347
			],
			[
				-79.51113,
				43.62441
			],
			[
				-79.50627,
				43.62548
			],
			[
				-79.50578,
				43.62552
			],
			[
				-79.49836,
				43.62726
			],
			[
				-79.49358,
				43.62822
			],
			[
				-79.48665,
				43.62979
			],
			[
				-79.48172,
				43.63081
			],
			[
				-79.48029,
				43.63118
			],
			[
				-79.47748,
				43.63168
			],
			[
				-79.47707,
				43.63184
			],
			[
				-79.47657,
				43.63218
			],
			[
				-79.47584,
				43.63309
			],
			[
				-79.47457,
				43.63441
			],
			[
				-79.47379,
				43.63504
			],
			[
				-79.47295,
				43.63593
			],
			[
				-79.47198,
				43.63648
			],
			[
				-79.47103,
				43.63689
			],
			[
				-79.47035,
				43.63706
			],
			[
				-79.46979,
				43.63706
			],
			[
				-79.4683,
				43.63726
			],
			[
				-79.46794,
				43.63744
			],
			[
				-79.46725,
				43.63757
			],
			[
				-79.46699,
				43.63753
			],
			[
				-79.46557,
				43.63783
			],
			[
				-79.46261,
				43.63879
			],
			[
				-79.46154,
				43.63906
			],
			[
				-79.45908,
				43.63947
			],
			[
				-79.4581,
				43.63954
			],
			[
				-79.45329,
				43.63962
			],
			[
				-79.45231,
				43.63957
			],
			[
				-79.45134,
				43.63926
			],
			[
				-79.44745,
				43.6385
			],
			[
				-79.44679,
				43.63849
			],
			[
				-79.44622,
				43.63858
			],
			[
				-79.4461,
				43.63849
			],
			[
				-79.44587,
				43.63868
			],
			[
				-79.44519,
				43.63885
			],
			[
				-79.44216,
				43.63941
			],
			[
				-79.44098,
				43.63973
			],
			[
				-79.43879,
				43.64007
			],
			[
				-79.43598,
				43.64063
			],
			[
				-79.43391,
				43.64115
			],
			[
				-79.4311,
				43.64163
			],
			[
				-79.42839,
				43.64221
			],
			[
				-79.42825,
				43.64215
			],
			[
				-79.42765,
				43.64234
			],
			[
				-79.42271,
				43.64334
			],
			[
				-79.4224,
				43.64347
			],
			[
				-79.42252,
				43.64383
			],
			[
				-79.42599,
				43.65258
			]
		],
		"dupe": false
	},
	{
		"id": 331080176,
		"name": "Evening Ride",
		"distance": 2239.6,
		"moving_time": 421,
		"elapsed_time": 422,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-22T20:20:35Z",
		"average_speed": 5.32,
		"max_speed": 12.7,
		"calories": 47.3,
		"points": [
			[
				-79.41334,
				43.66471
			],
			[
				-79.41815,
				43.66372
			],
			[
				-79.41986,
				43.66323
			],
			[
				-79.42084,
				43.66308
			],
			[
				-79.42228,
				43.6627
			],
			[
				-79.42279,
				43.6627
			],
			[
				-79.42335,
				43.66254
			],
			[
				-79.42325,
				43.66243
			],
			[
				-79.42189,
				43.65902
			],
			[
				-79.42427,
				43.65853
			],
			[
				-79.42413,
				43.65795
			],
			[
				-79.42357,
				43.65667
			],
			[
				-79.42321,
				43.65546
			],
			[
				-79.42299,
				43.65509
			],
			[
				-79.42287,
				43.65453
			],
			[
				-79.42276,
				43.6545
			],
			[
				-79.42244,
				43.65369
			],
			[
				-79.42244,
				43.65355
			],
			[
				-79.42266,
				43.65344
			],
			[
				-79.42342,
				43.65329
			],
			[
				-79.42348,
				43.65345
			],
			[
				-79.42332,
				43.65352
			]
		],
		"dupe": false
	},
	{
		"id": 331622726,
		"name": "Morning Ride",
		"distance": 3560.2,
		"moving_time": 577,
		"elapsed_time": 655,
		"total_elevation_gain": 12,
		"start_date_local": "2015-06-23T18:55:38Z",
		"average_speed": 6.17,
		"max_speed": 12.4,
		"calories": 74.2,
		"points": [
			[
				-79.42932,
				43.64413
			],
			[
				-79.42923,
				43.64407
			],
			[
				-79.4294,
				43.64429
			],
			[
				-79.43107,
				43.64843
			],
			[
				-79.43101,
				43.64858
			],
			[
				-79.43132,
				43.6496
			],
			[
				-79.42082,
				43.64931
			],
			[
				-79.41863,
				43.6498
			],
			[
				-79.41835,
				43.64977
			],
			[
				-79.41765,
				43.64994
			],
			[
				-79.41748,
				43.64991
			],
			[
				-79.41192,
				43.65113
			],
			[
				-79.40907,
				43.65159
			],
			[
				-79.40741,
				43.65204
			],
			[
				-79.40662,
				43.65219
			],
			[
				-79.40658,
				43.6521
			],
			[
				-79.40614,
				43.65225
			],
			[
				-79.40606,
				43.65255
			],
			[
				-79.4067,
				43.65422
			],
			[
				-79.40658,
				43.65435
			],
			[
				-79.40236,
				43.65522
			],
			[
				-79.40289,
				43.65659
			],
			[
				-79.40286,
				43.65649
			]
		],
		"dupe": false
	},
	{
		"id": 333330197,
		"name": "Morning Ride",
		"distance": 2747.7,
		"moving_time": 414,
		"elapsed_time": 421,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-26T08:29:58Z",
		"average_speed": 6.637,
		"max_speed": 12.9,
		"calories": 41.1,
		"points": [
			[
				-79.42293,
				43.65337
			],
			[
				-79.42328,
				43.65322
			],
			[
				-79.42326,
				43.65328
			],
			[
				-79.42423,
				43.65311
			],
			[
				-79.42595,
				43.65274
			],
			[
				-79.42603,
				43.65266
			],
			[
				-79.42166,
				43.64152
			],
			[
				-79.42149,
				43.64134
			],
			[
				-79.42129,
				43.6413
			],
			[
				-79.41753,
				43.64117
			],
			[
				-79.41741,
				43.64112
			],
			[
				-79.41737,
				43.641
			],
			[
				-79.41742,
				43.64113
			],
			[
				-79.41812,
				43.64089
			],
			[
				-79.41872,
				43.64086
			],
			[
				-79.42149,
				43.64003
			],
			[
				-79.42131,
				43.64005
			],
			[
				-79.42137,
				43.64013
			],
			[
				-79.42112,
				43.64026
			],
			[
				-79.42129,
				43.64021
			],
			[
				-79.4211,
				43.63939
			],
			[
				-79.42114,
				43.6391
			],
			[
				-79.42102,
				43.63893
			],
			[
				-79.42083,
				43.63891
			],
			[
				-79.42022,
				43.63741
			]
		],
		"dupe": false
	},
	{
		"id": 333387876,
		"name": "Morning Ride",
		"distance": 1216.2,
		"moving_time": 212,
		"elapsed_time": 212,
		"total_elevation_gain": 0,
		"start_date_local": "2015-06-26T10:11:03Z",
		"average_speed": 5.737,
		"max_speed": 11,
		"calories": 27.2,
		"points": [
			[
				-79.42248,
				43.63789
			],
			[
				-79.42273,
				43.63778
			],
			[
				-79.423,
				43.63783
			],
			[
				-79.42444,
				43.63756
			],
			[
				-79.42657,
				43.63707
			],
			[
				-79.42678,
				43.63734
			],
			[
				-79.42738,
				43.63892
			],
			[
				-79.42748,
				43.63954
			],
			[
				-79.42772,
				43.63999
			],
			[
				-79.4278,
				43.64037
			],
			[
				-79.42844,
				43.64202
			],
			[
				-79.42859,
				43.64214
			],
			[
				-79.42862,
				43.64258
			],
			[
				-79.42847,
				43.64333
			],
			[
				-79.42861,
				43.64341
			],
			[
				-79.42878,
				43.64375
			],
			[
				-79.42917,
				43.64403
			],
			[
				-79.42934,
				43.64442
			]
		],
		"dupe": false
	},
	{
		"id": 337130493,
		"name": "Afternoon Ride",
		"distance": 8941,
		"moving_time": 1377,
		"elapsed_time": 1505,
		"total_elevation_gain": 26.5,
		"start_date_local": "2015-07-01T15:51:28Z",
		"average_speed": 6.493,
		"max_speed": 17.9,
		"calories": 199.9,
		"points": [
			[
				-79.42372,
				43.65441
			],
			[
				-79.42299,
				43.65435
			],
			[
				-79.42295,
				43.65442
			],
			[
				-79.42267,
				43.65431
			],
			[
				-79.42208,
				43.65452
			],
			[
				-79.42156,
				43.65455
			],
			[
				-79.4198,
				43.65498
			],
			[
				-79.41793,
				43.65521
			],
			[
				-79.41752,
				43.65523
			],
			[
				-79.41539,
				43.65489
			],
			[
				-79.41491,
				43.65495
			],
			[
				-79.41357,
				43.65532
			],
			[
				-79.41266,
				43.65542
			],
			[
				-79.41211,
				43.65558
			],
			[
				-79.4111,
				43.65575
			],
			[
				-79.41029,
				43.65577
			],
			[
				-79.40966,
				43.65603
			],
			[
				-79.40895,
				43.65609
			],
			[
				-79.40863,
				43.65623
			],
			[
				-79.40827,
				43.65625
			],
			[
				-79.40675,
				43.65663
			],
			[
				-79.40552,
				43.65679
			],
			[
				-79.40528,
				43.65692
			],
			[
				-79.40056,
				43.65779
			],
			[
				-79.40033,
				43.65791
			],
			[
				-79.40024,
				43.6578
			],
			[
				-79.40007,
				43.65777
			],
			[
				-79.39832,
				43.65816
			],
			[
				-79.39831,
				43.65823
			],
			[
				-79.39807,
				43.65829
			],
			[
				-79.39501,
				43.65887
			],
			[
				-79.39473,
				43.65885
			],
			[
				-79.39392,
				43.65906
			],
			[
				-79.39323,
				43.65912
			],
			[
				-79.39181,
				43.65943
			],
			[
				-79.39083,
				43.65976
			],
			[
				-79.38863,
				43.66014
			],
			[
				-79.38819,
				43.66028
			],
			[
				-79.38751,
				43.66033
			],
			[
				-79.38759,
				43.66041
			],
			[
				-79.38748,
				43.66037
			],
			[
				-79.38599,
				43.66062
			],
			[
				-79.38524,
				43.66087
			],
			[
				-79.38391,
				43.6611
			],
			[
				-79.38374,
				43.6613
			],
			[
				-79.38347,
				43.66131
			],
			[
				-79.38338,
				43.66148
			],
			[
				-79.38302,
				43.66128
			],
			[
				-79.3833,
				43.66141
			],
			[
				-79.38306,
				43.66134
			],
			[
				-79.38216,
				43.66138
			],
			[
				-79.38195,
				43.66151
			],
			[
				-79.38152,
				43.66132
			],
			[
				-79.38015,
				43.66168
			],
			[
				-79.3794,
				43.66179
			],
			[
				-79.37931,
				43.66173
			],
			[
				-79.37849,
				43.65983
			],
			[
				-79.37547,
				43.6605
			],
			[
				-79.37532,
				43.66045
			],
			[
				-79.3732,
				43.66101
			],
			[
				-79.37237,
				43.66111
			],
			[
				-79.37244,
				43.66119
			],
			[
				-79.37227,
				43.66113
			],
			[
				-79.36881,
				43.66198
			],
			[
				-79.36817,
				43.66202
			],
			[
				-79.36705,
				43.66187
			],
			[
				-79.36619,
				43.66212
			],
			[
				-79.35947,
				43.66353
			],
			[
				-79.35654,
				43.66435
			],
			[
				-79.35512,
				43.66484
			],
			[
				-79.35378,
				43.66514
			],
			[
				-79.35292,
				43.66548
			],
			[
				-79.35234,
				43.66547
			],
			[
				-79.35182,
				43.66564
			],
			[
				-79.35027,
				43.66591
			],
			[
				-79.34585,
				43.66693
			],
			[
				-79.34284,
				43.66751
			],
			[
				-79.34213,
				43.66777
			],
			[
				-79.34072,
				43.66802
			],
			[
				-79.34078,
				43.66809
			],
			[
				-79.34072,
				43.66801
			],
			[
				-79.34059,
				43.6681
			],
			[
				-79.33941,
				43.66828
			],
			[
				-79.33891,
				43.66847
			],
			[
				-79.33782,
				43.66863
			],
			[
				-79.33619,
				43.66907
			],
			[
				-79.3357,
				43.66911
			],
			[
				-79.33504,
				43.66935
			],
			[
				-79.32976,
				43.67046
			],
			[
				-79.32819,
				43.67081
			],
			[
				-79.32802,
				43.67091
			],
			[
				-79.32802,
				43.67121
			],
			[
				-79.32856,
				43.67234
			],
			[
				-79.32893,
				43.67339
			],
			[
				-79.32916,
				43.67376
			],
			[
				-79.32943,
				43.67385
			],
			[
				-79.32988,
				43.67375
			]
		],
		"dupe": false
	},
	{
		"id": 337138803,
		"name": "Afternoon Ride",
		"distance": 1017.8,
		"moving_time": 162,
		"elapsed_time": 165,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-01T16:26:06Z",
		"average_speed": 6.283,
		"max_speed": 12.9,
		"calories": 14.5,
		"points": [
			[
				-79.33011,
				43.67366
			],
			[
				-79.32933,
				43.67384
			],
			[
				-79.32667,
				43.66761
			],
			[
				-79.32628,
				43.66752
			],
			[
				-79.32462,
				43.66765
			],
			[
				-79.3245,
				43.66758
			],
			[
				-79.32457,
				43.66768
			]
		],
		"dupe": false
	},
	{
		"id": 337306003,
		"name": "Night Ride",
		"distance": 10709.1,
		"moving_time": 1777,
		"elapsed_time": 1992,
		"total_elevation_gain": 22.5,
		"start_date_local": "2015-07-01T22:17:05Z",
		"average_speed": 6.027,
		"max_speed": 14.8,
		"calories": 199.2,
		"points": [
			[
				-79.3131,
				43.66634
			],
			[
				-79.31344,
				43.6662
			],
			[
				-79.31802,
				43.66516
			],
			[
				-79.31942,
				43.66402
			],
			[
				-79.31976,
				43.66385
			],
			[
				-79.32054,
				43.66369
			],
			[
				-79.32042,
				43.66366
			],
			[
				-79.32064,
				43.66384
			],
			[
				-79.32075,
				43.66431
			],
			[
				-79.32116,
				43.66517
			],
			[
				-79.32145,
				43.66529
			],
			[
				-79.33258,
				43.66283
			],
			[
				-79.33334,
				43.66257
			],
			[
				-79.33222,
				43.65989
			],
			[
				-79.33614,
				43.65908
			],
			[
				-79.33763,
				43.6587
			],
			[
				-79.33937,
				43.65839
			],
			[
				-79.34642,
				43.65678
			],
			[
				-79.34801,
				43.65656
			],
			[
				-79.35022,
				43.65706
			],
			[
				-79.35198,
				43.65692
			],
			[
				-79.35285,
				43.6567
			],
			[
				-79.35536,
				43.65566
			],
			[
				-79.3556,
				43.65549
			],
			[
				-79.35695,
				43.65507
			],
			[
				-79.35812,
				43.65497
			],
			[
				-79.36031,
				43.65524
			],
			[
				-79.36155,
				43.65515
			],
			[
				-79.37224,
				43.65267
			],
			[
				-79.37335,
				43.6528
			],
			[
				-79.37413,
				43.65275
			],
			[
				-79.38903,
				43.64947
			],
			[
				-79.38896,
				43.64949
			],
			[
				-79.38925,
				43.64965
			],
			[
				-79.3892,
				43.64961
			],
			[
				-79.38875,
				43.64989
			],
			[
				-79.38903,
				43.64959
			],
			[
				-79.38913,
				43.64959
			],
			[
				-79.38916,
				43.64944
			],
			[
				-79.39251,
				43.64873
			],
			[
				-79.39328,
				43.64839
			],
			[
				-79.39546,
				43.64791
			],
			[
				-79.39675,
				43.64786
			],
			[
				-79.39983,
				43.64721
			],
			[
				-79.40372,
				43.64647
			],
			[
				-79.40445,
				43.64642
			],
			[
				-79.40529,
				43.64619
			],
			[
				-79.40576,
				43.64616
			],
			[
				-79.40921,
				43.64546
			],
			[
				-79.40959,
				43.64544
			],
			[
				-79.40976,
				43.64556
			],
			[
				-79.40998,
				43.64599
			],
			[
				-79.41009,
				43.64603
			],
			[
				-79.41317,
				43.64544
			],
			[
				-79.41305,
				43.64541
			],
			[
				-79.41327,
				43.64559
			],
			[
				-79.41343,
				43.64604
			],
			[
				-79.41293,
				43.64638
			],
			[
				-79.41449,
				43.64719
			],
			[
				-79.41517,
				43.64868
			],
			[
				-79.41675,
				43.6491
			],
			[
				-79.41779,
				43.64963
			],
			[
				-79.41793,
				43.64979
			],
			[
				-79.4184,
				43.64977
			],
			[
				-79.41826,
				43.64977
			],
			[
				-79.41847,
				43.6506
			],
			[
				-79.41879,
				43.65129
			],
			[
				-79.41878,
				43.65141
			],
			[
				-79.41886,
				43.65145
			],
			[
				-79.42129,
				43.65097
			],
			[
				-79.42231,
				43.65351
			],
			[
				-79.42259,
				43.65346
			]
		],
		"dupe": false
	},
	{
		"id": 339963933,
		"name": "Burger Burpies",
		"distance": 62433,
		"moving_time": 12149,
		"elapsed_time": 16376,
		"total_elevation_gain": 81.2,
		"start_date_local": "2015-07-05T13:27:09Z",
		"average_speed": 5.139,
		"max_speed": 15.7,
		"calories": 988.6,
		"points": [
			[
				-79.44375,
				43.65815
			],
			[
				-79.44698,
				43.65745
			],
			[
				-79.44989,
				43.65693
			],
			[
				-79.45036,
				43.65676
			],
			[
				-79.45191,
				43.65646
			],
			[
				-79.45201,
				43.65652
			],
			[
				-79.45243,
				43.6561
			],
			[
				-79.45235,
				43.65542
			],
			[
				-79.45198,
				43.65396
			],
			[
				-79.45148,
				43.65234
			],
			[
				-79.45119,
				43.65172
			],
			[
				-79.45118,
				43.65146
			],
			[
				-79.4509,
				43.65092
			],
			[
				-79.44989,
				43.64814
			],
			[
				-79.4497,
				43.64784
			],
			[
				-79.44911,
				43.64599
			],
			[
				-79.44886,
				43.64551
			],
			[
				-79.44842,
				43.64423
			],
			[
				-79.4471,
				43.64111
			],
			[
				-79.44696,
				43.64052
			],
			[
				-79.44647,
				43.63928
			],
			[
				-79.44615,
				43.63865
			],
			[
				-79.44616,
				43.63857
			],
			[
				-79.44643,
				43.63869
			],
			[
				-79.44684,
				43.63863
			],
			[
				-79.44687,
				43.63828
			],
			[
				-79.44743,
				43.63696
			],
			[
				-79.44774,
				43.63691
			],
			[
				-79.44738,
				43.63693
			],
			[
				-79.44723,
				43.6369
			],
			[
				-79.44725,
				43.63685
			],
			[
				-79.44779,
				43.63685
			],
			[
				-79.44911,
				43.63712
			],
			[
				-79.45056,
				43.63725
			],
			[
				-79.45245,
				43.63768
			],
			[
				-79.45319,
				43.63769
			],
			[
				-79.45342,
				43.63783
			],
			[
				-79.4562,
				43.63796
			],
			[
				-79.4572,
				43.63788
			],
			[
				-79.45941,
				43.63746
			],
			[
				-79.46065,
				43.63687
			],
			[
				-79.46118,
				43.6368
			],
			[
				-79.46249,
				43.63636
			],
			[
				-79.46318,
				43.6362
			],
			[
				-79.46409,
				43.63623
			],
			[
				-79.46481,
				43.63597
			],
			[
				-79.46519,
				43.63595
			],
			[
				-79.46626,
				43.63553
			],
			[
				-79.46657,
				43.63509
			],
			[
				-79.46682,
				43.63498
			],
			[
				-79.46709,
				43.63512
			],
			[
				-79.46914,
				43.63405
			],
			[
				-79.46927,
				43.63353
			],
			[
				-79.46949,
				43.63309
			],
			[
				-79.4699,
				43.6328
			],
			[
				-79.47071,
				43.63256
			],
			[
				-79.47176,
				43.6313
			],
			[
				-79.47221,
				43.63089
			],
			[
				-79.47264,
				43.63064
			],
			[
				-79.47283,
				43.63013
			],
			[
				-79.47317,
				43.62998
			],
			[
				-79.47393,
				43.63021
			],
			[
				-79.47435,
				43.62999
			],
			[
				-79.47454,
				43.62999
			],
			[
				-79.47543,
				43.62935
			],
			[
				-79.47571,
				43.62902
			],
			[
				-79.47637,
				43.62786
			],
			[
				-79.47764,
				43.62452
			],
			[
				-79.47848,
				43.62314
			],
			[
				-79.47871,
				43.6229
			],
			[
				-79.47908,
				43.62267
			],
			[
				-79.47964,
				43.62257
			],
			[
				-79.48027,
				43.62265
			],
			[
				-79.48013,
				43.62261
			],
			[
				-79.48088,
				43.62288
			],
			[
				-79.48082,
				43.62286
			],
			[
				-79.48152,
				43.62292
			],
			[
				-79.48249,
				43.62145
			],
			[
				-79.48324,
				43.62071
			],
			[
				-79.48387,
				43.62039
			],
			[
				-79.48476,
				43.62017
			],
			[
				-79.48495,
				43.62002
			],
			[
				-79.48555,
				43.61991
			],
			[
				-79.48601,
				43.61967
			],
			[
				-79.48631,
				43.61938
			],
			[
				-79.48804,
				43.6162
			],
			[
				-79.48945,
				43.61382
			],
			[
				-79.48992,
				43.61275
			],
			[
				-79.49005,
				43.6117
			],
			[
				-79.4903,
				43.61072
			],
			[
				-79.49022,
				43.61063
			],
			[
				-79.49031,
				43.61027
			],
			[
				-79.49062,
				43.60699
			],
			[
				-79.49086,
				43.60627
			],
			[
				-79.49133,
				43.60532
			],
			[
				-79.49173,
				43.60478
			],
			[
				-79.49233,
				43.60422
			],
			[
				-79.49324,
				43.60369
			],
			[
				-79.49606,
				43.60256
			],
			[
				-79.49815,
				43.60238
			],
			[
				-79.50081,
				43.60185
			],
			[
				-79.50303,
				43.6013
			],
			[
				-79.50462,
				43.60098
			],
			[
				-79.50475,
				43.60103
			],
			[
				-79.50572,
				43.60074
			],
			[
				-79.50855,
				43.60015
			],
			[
				-79.50858,
				43.60008
			],
			[
				-79.50916,
				43.59993
			],
			[
				-79.51038,
				43.59972
			],
			[
				-79.51136,
				43.59944
			],
			[
				-79.51244,
				43.5993
			],
			[
				-79.51659,
				43.59829
			],
			[
				-79.5169,
				43.59829
			],
			[
				-79.51898,
				43.59782
			],
			[
				-79.51994,
				43.59751
			],
			[
				-79.52038,
				43.59747
			],
			[
				-79.53079,
				43.59521
			],
			[
				-79.53608,
				43.59388
			],
			[
				-79.53721,
				43.59372
			],
			[
				-79.53891,
				43.59335
			],
			[
				-79.5395,
				43.59345
			],
			[
				-79.54051,
				43.59317
			],
			[
				-79.54093,
				43.59313
			],
			[
				-79.5413,
				43.59336
			],
			[
				-79.54215,
				43.59493
			],
			[
				-79.54332,
				43.59768
			],
			[
				-79.54461,
				43.60035
			],
			[
				-79.54508,
				43.60158
			],
			[
				-79.54533,
				43.60196
			],
			[
				-79.5474,
				43.60687
			],
			[
				-79.54784,
				43.60769
			],
			[
				-79.54825,
				43.6089
			],
			[
				-79.54839,
				43.60901
			],
			[
				-79.54852,
				43.60888
			],
			[
				-79.5487,
				43.60911
			],
			[
				-79.54869,
				43.60936
			],
			[
				-79.5489,
				43.60977
			],
			[
				-79.54914,
				43.61054
			],
			[
				-79.54943,
				43.61066
			],
			[
				-79.54973,
				43.61061
			],
			[
				-79.55771,
				43.60878
			],
			[
				-79.55863,
				43.60845
			],
			[
				-79.55939,
				43.6091
			],
			[
				-79.56032,
				43.60952
			],
			[
				-79.56092,
				43.60992
			],
			[
				-79.56221,
				43.61192
			],
			[
				-79.5626,
				43.61237
			],
			[
				-79.56283,
				43.61235
			],
			[
				-79.56638,
				43.61031
			],
			[
				-79.56892,
				43.60875
			],
			[
				-79.56994,
				43.60745
			],
			[
				-79.57299,
				43.60479
			],
			[
				-79.57423,
				43.60381
			],
			[
				-79.57579,
				43.60236
			],
			[
				-79.57732,
				43.60109
			],
			[
				-79.57927,
				43.59986
			],
			[
				-79.58059,
				43.59859
			],
			[
				-79.5819,
				43.59755
			],
			[
				-79.59106,
				43.58964
			],
			[
				-79.59221,
				43.58876
			],
			[
				-79.59407,
				43.58696
			],
			[
				-79.59522,
				43.58516
			],
			[
				-79.59573,
				43.58458
			],
			[
				-79.59996,
				43.58094
			],
			[
				-79.60024,
				43.58099
			],
			[
				-79.60096,
				43.58034
			],
			[
				-79.60163,
				43.57988
			],
			[
				-79.60334,
				43.57833
			],
			[
				-79.60356,
				43.57794
			],
			[
				-79.60502,
				43.57682
			],
			[
				-79.6051,
				43.57672
			],
			[
				-79.60502,
				43.5766
			],
			[
				-79.60612,
				43.57566
			],
			[
				-79.60607,
				43.57562
			],
			[
				-79.60616,
				43.57562
			],
			[
				-79.60761,
				43.57436
			],
			[
				-79.60897,
				43.57304
			],
			[
				-79.6103,
				43.57202
			],
			[
				-79.61316,
				43.56924
			],
			[
				-79.61923,
				43.56388
			],
			[
				-79.62182,
				43.56147
			],
			[
				-79.62223,
				43.56117
			],
			[
				-79.62268,
				43.56067
			],
			[
				-79.62401,
				43.55958
			],
			[
				-79.62831,
				43.55575
			],
			[
				-79.62868,
				43.55518
			],
			[
				-79.62969,
				43.55446
			],
			[
				-79.62982,
				43.55423
			],
			[
				-79.63058,
				43.5536
			],
			[
				-79.63069,
				43.55331
			],
			[
				-79.63081,
				43.55322
			],
			[
				-79.63111,
				43.55329
			],
			[
				-79.63323,
				43.55144
			],
			[
				-79.6336,
				43.55138
			],
			[
				-79.63464,
				43.55166
			],
			[
				-79.63526,
				43.55155
			],
			[
				-79.6378,
				43.55146
			],
			[
				-79.63866,
				43.55146
			],
			[
				-79.63919,
				43.55156
			],
			[
				-79.64112,
				43.55247
			],
			[
				-79.64168,
				43.55292
			],
			[
				-79.64226,
				43.55316
			],
			[
				-79.64378,
				43.55427
			],
			[
				-79.64514,
				43.55514
			],
			[
				-79.64527,
				43.5551
			],
			[
				-79.64553,
				43.55525
			],
			[
				-79.64578,
				43.5552
			],
			[
				-79.64608,
				43.55525
			],
			[
				-79.6462,
				43.55517
			],
			[
				-79.6461,
				43.55511
			],
			[
				-79.64617,
				43.55516
			],
			[
				-79.64607,
				43.55478
			],
			[
				-79.64799,
				43.55317
			],
			[
				-79.64794,
				43.55307
			],
			[
				-79.64885,
				43.55201
			],
			[
				-79.65051,
				43.54847
			],
			[
				-79.65141,
				43.54733
			],
			[
				-79.65229,
				43.54659
			],
			[
				-79.65396,
				43.54538
			],
			[
				-79.65836,
				43.54317
			],
			[
				-79.65913,
				43.54272
			],
			[
				-79.65926,
				43.54249
			],
			[
				-79.65823,
				43.54196
			],
			[
				-79.6576,
				43.54173
			],
			[
				-79.65544,
				43.54127
			],
			[
				-79.65473,
				43.54
			],
			[
				-79.65436,
				43.53954
			],
			[
				-79.65357,
				43.53892
			],
			[
				-79.65259,
				43.53852
			],
			[
				-79.65214,
				43.53821
			],
			[
				-79.65164,
				43.53741
			],
			[
				-79.65159,
				43.53651
			],
			[
				-79.65137,
				43.53599
			],
			[
				-79.65119,
				43.53585
			],
			[
				-79.65108,
				43.53587
			],
			[
				-79.65116,
				43.53585
			],
			[
				-79.65061,
				43.53553
			],
			[
				-79.6506,
				43.53546
			],
			[
				-79.64963,
				43.53512
			],
			[
				-79.64878,
				43.53505
			],
			[
				-79.64837,
				43.5351
			],
			[
				-79.64738,
				43.5355
			],
			[
				-79.64664,
				43.53606
			],
			[
				-79.64429,
				43.53748
			],
			[
				-79.64038,
				43.53965
			],
			[
				-79.6383,
				43.54095
			],
			[
				-79.63698,
				43.54162
			],
			[
				-79.6269,
				43.54739
			],
			[
				-79.62599,
				43.54775
			],
			[
				-79.62221,
				43.54834
			],
			[
				-79.62118,
				43.54874
			],
			[
				-79.61943,
				43.55014
			],
			[
				-79.6178,
				43.55209
			],
			[
				-79.61727,
				43.55238
			],
			[
				-79.61655,
				43.55247
			],
			[
				-79.61544,
				43.55241
			],
			[
				-79.61231,
				43.55211
			],
			[
				-79.6119,
				43.552
			],
			[
				-79.60806,
				43.55169
			],
			[
				-79.59682,
				43.55052
			],
			[
				-79.59402,
				43.55037
			],
			[
				-79.59347,
				43.55018
			],
			[
				-79.59163,
				43.54906
			],
			[
				-79.58958,
				43.54802
			],
			[
				-79.58919,
				43.54828
			],
			[
				-79.58894,
				43.54877
			],
			[
				-79.58811,
				43.54939
			],
			[
				-79.58781,
				43.54977
			],
			[
				-79.58742,
				43.55006
			],
			[
				-79.58704,
				43.55057
			],
			[
				-79.58533,
				43.55195
			],
			[
				-79.58536,
				43.55202
			],
			[
				-79.5853,
				43.552
			],
			[
				-79.58521,
				43.5522
			],
			[
				-79.58454,
				43.55261
			],
			[
				-79.58398,
				43.55307
			],
			[
				-79.5834,
				43.5537
			],
			[
				-79.5824,
				43.55451
			],
			[
				-79.58265,
				43.55454
			],
			[
				-79.58241,
				43.5544
			],
			[
				-79.58257,
				43.55426
			],
			[
				-79.58236,
				43.5543
			],
			[
				-79.58249,
				43.55427
			],
			[
				-79.5824,
				43.55427
			],
			[
				-79.58233,
				43.55438
			],
			[
				-79.58264,
				43.55439
			],
			[
				-79.58208,
				43.55461
			],
			[
				-79.58217,
				43.55466
			],
			[
				-79.58166,
				43.55499
			],
			[
				-79.58101,
				43.5545
			],
			[
				-79.5805,
				43.55428
			],
			[
				-79.58045,
				43.55423
			],
			[
				-79.58052,
				43.55411
			],
			[
				-79.58013,
				43.5538
			],
			[
				-79.57957,
				43.55424
			],
			[
				-79.57846,
				43.55432
			],
			[
				-79.57815,
				43.55448
			],
			[
				-79.57791,
				43.55473
			],
			[
				-79.57709,
				43.55498
			],
			[
				-79.57708,
				43.55522
			],
			[
				-79.57681,
				43.55535
			],
			[
				-79.57682,
				43.55546
			],
			[
				-79.57754,
				43.55593
			],
			[
				-79.57756,
				43.55605
			],
			[
				-79.57581,
				43.55773
			],
			[
				-79.57565,
				43.55773
			],
			[
				-79.57459,
				43.55704
			],
			[
				-79.5743,
				43.55693
			],
			[
				-79.5735,
				43.55715
			],
			[
				-79.57244,
				43.55768
			],
			[
				-79.57226,
				43.55796
			],
			[
				-79.5723,
				43.55818
			],
			[
				-79.57221,
				43.55835
			],
			[
				-79.57117,
				43.55873
			],
			[
				-79.56843,
				43.5613
			],
			[
				-79.56865,
				43.56167
			],
			[
				-79.56861,
				43.56197
			],
			[
				-79.56875,
				43.56271
			],
			[
				-79.56857,
				43.56294
			],
			[
				-79.56781,
				43.56307
			],
			[
				-79.5672,
				43.56352
			],
			[
				-79.5668,
				43.56357
			],
			[
				-79.5667,
				43.56381
			],
			[
				-79.56593,
				43.56444
			],
			[
				-79.56501,
				43.56493
			],
			[
				-79.56495,
				43.56512
			],
			[
				-79.56501,
				43.56546
			],
			[
				-79.56346,
				43.56678
			],
			[
				-79.56334,
				43.56676
			],
			[
				-79.56297,
				43.5664
			],
			[
				-79.56274,
				43.56639
			],
			[
				-79.56191,
				43.56722
			],
			[
				-79.56168,
				43.56724
			],
			[
				-79.56156,
				43.5674
			],
			[
				-79.56118,
				43.56762
			],
			[
				-79.5605,
				43.5677
			],
			[
				-79.55992,
				43.56813
			],
			[
				-79.55955,
				43.5681
			],
			[
				-79.5593,
				43.56853
			],
			[
				-79.55894,
				43.56875
			],
			[
				-79.55729,
				43.56936
			],
			[
				-79.55642,
				43.56994
			],
			[
				-79.55618,
				43.57025
			],
			[
				-79.55636,
				43.57034
			],
			[
				-79.55629,
				43.57065
			],
			[
				-79.55668,
				43.57096
			],
			[
				-79.55738,
				43.57177
			],
			[
				-79.55741,
				43.57215
			],
			[
				-79.55779,
				43.57247
			],
			[
				-79.55761,
				43.57277
			],
			[
				-79.55791,
				43.57293
			],
			[
				-79.55795,
				43.57307
			],
			[
				-79.55681,
				43.57409
			],
			[
				-79.55625,
				43.57446
			],
			[
				-79.55598,
				43.57489
			],
			[
				-79.55563,
				43.57525
			],
			[
				-79.55522,
				43.57544
			],
			[
				-79.55512,
				43.57567
			],
			[
				-79.55412,
				43.57652
			],
			[
				-79.55404,
				43.57668
			],
			[
				-79.55433,
				43.57697
			],
			[
				-79.55465,
				43.57698
			],
			[
				-79.55476,
				43.57714
			],
			[
				-79.55613,
				43.57821
			],
			[
				-79.55652,
				43.57839
			],
			[
				-79.55665,
				43.57865
			],
			[
				-79.55655,
				43.57886
			],
			[
				-79.55621,
				43.57904
			],
			[
				-79.55608,
				43.57928
			],
			[
				-79.55579,
				43.57955
			],
			[
				-79.55498,
				43.58008
			],
			[
				-79.55402,
				43.58121
			],
			[
				-79.55355,
				43.58159
			],
			[
				-79.5536,
				43.58168
			],
			[
				-79.5517,
				43.58345
			],
			[
				-79.55138,
				43.58327
			],
			[
				-79.55102,
				43.58277
			],
			[
				-79.55007,
				43.58212
			],
			[
				-79.5496,
				43.58218
			],
			[
				-79.54949,
				43.58237
			],
			[
				-79.54915,
				43.58254
			],
			[
				-79.54817,
				43.58275
			],
			[
				-79.54773,
				43.58252
			],
			[
				-79.54768,
				43.58237
			],
			[
				-79.54743,
				43.58223
			],
			[
				-79.54711,
				43.58179
			],
			[
				-79.54687,
				43.58165
			],
			[
				-79.54665,
				43.58162
			],
			[
				-79.54491,
				43.582
			],
			[
				-79.54466,
				43.58196
			],
			[
				-79.54439,
				43.58215
			],
			[
				-79.54408,
				43.58217
			],
			[
				-79.54395,
				43.58229
			],
			[
				-79.54396,
				43.58284
			],
			[
				-79.54369,
				43.58329
			],
			[
				-79.54372,
				43.58367
			],
			[
				-79.54356,
				43.58405
			],
			[
				-79.54362,
				43.58442
			],
			[
				-79.54356,
				43.58465
			],
			[
				-79.54368,
				43.58499
			],
			[
				-79.54477,
				43.58565
			],
			[
				-79.54486,
				43.5859
			],
			[
				-79.54478,
				43.58604
			],
			[
				-79.54389,
				43.58672
			],
			[
				-79.54328,
				43.58702
			],
			[
				-79.54282,
				43.58699
			],
			[
				-79.54266,
				43.58692
			],
			[
				-79.54249,
				43.58671
			],
			[
				-79.54157,
				43.58646
			],
			[
				-79.54128,
				43.58628
			],
			[
				-79.54098,
				43.58625
			],
			[
				-79.54057,
				43.58633
			],
			[
				-79.5405,
				43.58655
			],
			[
				-79.54036,
				43.58659
			],
			[
				-79.53845,
				43.58806
			],
			[
				-79.53738,
				43.58859
			],
			[
				-79.53507,
				43.5891
			],
			[
				-79.53462,
				43.58839
			],
			[
				-79.534,
				43.58839
			],
			[
				-79.5316,
				43.5889
			],
			[
				-79.53147,
				43.58875
			],
			[
				-79.53117,
				43.58872
			],
			[
				-79.52949,
				43.5891
			],
			[
				-79.52928,
				43.58924
			],
			[
				-79.52896,
				43.58994
			],
			[
				-79.52867,
				43.59011
			],
			[
				-79.52829,
				43.59019
			],
			[
				-79.52449,
				43.59063
			],
			[
				-79.52055,
				43.59097
			],
			[
				-79.51938,
				43.59118
			],
			[
				-79.51918,
				43.59106
			],
			[
				-79.51865,
				43.59118
			],
			[
				-79.51823,
				43.5908
			],
			[
				-79.51703,
				43.59105
			],
			[
				-79.51623,
				43.59104
			],
			[
				-79.51612,
				43.5911
			],
			[
				-79.51603,
				43.59133
			],
			[
				-79.5162,
				43.59176
			],
			[
				-79.51588,
				43.59206
			],
			[
				-79.51577,
				43.59227
			],
			[
				-79.51511,
				43.59225
			],
			[
				-79.515,
				43.59211
			],
			[
				-79.51441,
				43.59207
			],
			[
				-79.51416,
				43.59242
			],
			[
				-79.5131,
				43.59339
			],
			[
				-79.51246,
				43.59379
			],
			[
				-79.51144,
				43.59387
			],
			[
				-79.51077,
				43.59421
			],
			[
				-79.5088,
				43.59465
			],
			[
				-79.50762,
				43.59468
			],
			[
				-79.50805,
				43.59576
			],
			[
				-79.5076,
				43.59577
			],
			[
				-79.50706,
				43.59546
			],
			[
				-79.50566,
				43.59542
			],
			[
				-79.50535,
				43.5949
			],
			[
				-79.505,
				43.59481
			],
			[
				-79.50369,
				43.59489
			],
			[
				-79.50138,
				43.59544
			],
			[
				-79.50084,
				43.59599
			],
			[
				-79.50114,
				43.59672
			],
			[
				-79.50062,
				43.59689
			],
			[
				-79.50027,
				43.59754
			],
			[
				-79.50028,
				43.59789
			],
			[
				-79.49922,
				43.59946
			],
			[
				-79.49856,
				43.59957
			],
			[
				-79.49854,
				43.5997
			],
			[
				-79.49876,
				43.60023
			],
			[
				-79.49853,
				43.60037
			],
			[
				-79.49797,
				43.60108
			],
			[
				-79.49844,
				43.60218
			],
			[
				-79.49796,
				43.60234
			],
			[
				-79.49573,
				43.60257
			],
			[
				-79.49314,
				43.60358
			],
			[
				-79.492,
				43.60435
			],
			[
				-79.4915,
				43.60483
			],
			[
				-79.49103,
				43.60555
			],
			[
				-79.49043,
				43.60697
			],
			[
				-79.49022,
				43.60976
			],
			[
				-79.49003,
				43.61089
			],
			[
				-79.48972,
				43.61093
			],
			[
				-79.48747,
				43.61076
			],
			[
				-79.48686,
				43.61095
			],
			[
				-79.48698,
				43.61143
			],
			[
				-79.48695,
				43.61158
			],
			[
				-79.48726,
				43.61197
			],
			[
				-79.48701,
				43.61255
			],
			[
				-79.48749,
				43.61288
			],
			[
				-79.48754,
				43.61322
			],
			[
				-79.48727,
				43.61353
			],
			[
				-79.48683,
				43.61368
			],
			[
				-79.48634,
				43.61368
			],
			[
				-79.48603,
				43.61401
			],
			[
				-79.4861,
				43.61422
			],
			[
				-79.48606,
				43.61458
			],
			[
				-79.48572,
				43.61534
			],
			[
				-79.48559,
				43.61542
			],
			[
				-79.48559,
				43.61556
			],
			[
				-79.48537,
				43.61572
			],
			[
				-79.4855,
				43.61587
			],
			[
				-79.48556,
				43.61627
			],
			[
				-79.48535,
				43.61658
			],
			[
				-79.48528,
				43.61705
			],
			[
				-79.48507,
				43.61752
			],
			[
				-79.48452,
				43.61783
			],
			[
				-79.4843,
				43.61807
			],
			[
				-79.48381,
				43.61813
			],
			[
				-79.48313,
				43.61849
			],
			[
				-79.4826,
				43.61846
			],
			[
				-79.4819,
				43.61867
			],
			[
				-79.48139,
				43.6189
			],
			[
				-79.48118,
				43.61909
			],
			[
				-79.48109,
				43.61929
			],
			[
				-79.48116,
				43.61953
			],
			[
				-79.48146,
				43.61994
			],
			[
				-79.48148,
				43.62008
			],
			[
				-79.4812,
				43.62048
			],
			[
				-79.48082,
				43.62062
			],
			[
				-79.48037,
				43.621
			],
			[
				-79.48025,
				43.6219
			],
			[
				-79.48005,
				43.62224
			],
			[
				-79.47971,
				43.62238
			],
			[
				-79.47917,
				43.62236
			],
			[
				-79.47876,
				43.62245
			],
			[
				-79.47851,
				43.62259
			],
			[
				-79.47823,
				43.62289
			],
			[
				-79.47834,
				43.62311
			],
			[
				-79.4774,
				43.62471
			],
			[
				-79.47641,
				43.62777
			],
			[
				-79.47569,
				43.62907
			],
			[
				-79.47503,
				43.62937
			],
			[
				-79.47485,
				43.62965
			],
			[
				-79.47433,
				43.63011
			],
			[
				-79.47366,
				43.63024
			],
			[
				-79.47318,
				43.63002
			],
			[
				-79.47301,
				43.63001
			],
			[
				-79.47283,
				43.63013
			],
			[
				-79.47259,
				43.63067
			],
			[
				-79.47164,
				43.63147
			],
			[
				-79.47073,
				43.63258
			],
			[
				-79.46968,
				43.63294
			],
			[
				-79.46936,
				43.63324
			],
			[
				-79.46927,
				43.6335
			],
			[
				-79.4689,
				43.63403
			],
			[
				-79.46784,
				43.63457
			],
			[
				-79.46764,
				43.63475
			],
			[
				-79.46696,
				43.63498
			],
			[
				-79.46698,
				43.63516
			],
			[
				-79.46649,
				43.63539
			],
			[
				-79.46622,
				43.63571
			],
			[
				-79.46368,
				43.63659
			],
			[
				-79.46324,
				43.63633
			],
			[
				-79.46244,
				43.63653
			],
			[
				-79.46206,
				43.63651
			],
			[
				-79.46102,
				43.63693
			],
			[
				-79.46043,
				43.63698
			],
			[
				-79.45947,
				43.63747
			],
			[
				-79.45813,
				43.63773
			],
			[
				-79.4581,
				43.63785
			],
			[
				-79.45578,
				43.63808
			],
			[
				-79.45285,
				43.63786
			],
			[
				-79.44774,
				43.63701
			],
			[
				-79.44593,
				43.63703
			],
			[
				-79.44549,
				43.63696
			],
			[
				-79.44305,
				43.63596
			],
			[
				-79.44141,
				43.63546
			],
			[
				-79.4379,
				43.63384
			],
			[
				-79.43749,
				43.63363
			],
			[
				-79.43626,
				43.63274
			],
			[
				-79.43513,
				43.63226
			],
			[
				-79.43308,
				43.63183
			],
			[
				-79.4321,
				43.63173
			],
			[
				-79.43201,
				43.63145
			],
			[
				-79.43154,
				43.63147
			],
			[
				-79.43107,
				43.63133
			],
			[
				-79.43055,
				43.63138
			],
			[
				-79.43012,
				43.63129
			],
			[
				-79.42999,
				43.63139
			],
			[
				-79.42862,
				43.63101
			],
			[
				-79.42573,
				43.62999
			],
			[
				-79.42494,
				43.62992
			],
			[
				-79.41987,
				43.63037
			],
			[
				-79.41948,
				43.63032
			],
			[
				-79.4189,
				43.63048
			],
			[
				-79.41462,
				43.63121
			],
			[
				-79.40999,
				43.63268
			],
			[
				-79.40938,
				43.63219
			],
			[
				-79.40882,
				43.63223
			],
			[
				-79.40871,
				43.63237
			],
			[
				-79.40867,
				43.63285
			],
			[
				-79.40875,
				43.63352
			],
			[
				-79.40868,
				43.63392
			],
			[
				-79.40884,
				43.63413
			],
			[
				-79.40863,
				43.63443
			],
			[
				-79.40771,
				43.63442
			],
			[
				-79.40591,
				43.63396
			],
			[
				-79.4048,
				43.63395
			],
			[
				-79.40416,
				43.63421
			],
			[
				-79.40355,
				43.63528
			],
			[
				-79.40311,
				43.63524
			],
			[
				-79.40283,
				43.6353
			],
			[
				-79.40267,
				43.63525
			],
			[
				-79.40273,
				43.63514
			],
			[
				-79.40267,
				43.63508
			],
			[
				-79.40238,
				43.63494
			],
			[
				-79.40251,
				43.63482
			],
			[
				-79.40192,
				43.63417
			],
			[
				-79.40127,
				43.63418
			],
			[
				-79.40047,
				43.63451
			],
			[
				-79.39807,
				43.63579
			],
			[
				-79.39729,
				43.63635
			],
			[
				-79.39678,
				43.63656
			],
			[
				-79.39505,
				43.63709
			],
			[
				-79.39146,
				43.6377
			],
			[
				-79.39178,
				43.63778
			],
			[
				-79.39214,
				43.63852
			],
			[
				-79.39284,
				43.64056
			],
			[
				-79.39279,
				43.64045
			],
			[
				-79.39266,
				43.64047
			],
			[
				-79.39268,
				43.64054
			],
			[
				-79.39257,
				43.64055
			],
			[
				-79.39257,
				43.64061
			],
			[
				-79.39292,
				43.64065
			],
			[
				-79.39407,
				43.64348
			]
		],
		"dupe": false
	},
	{
		"id": 339191950,
		"name": "Broken Spoke; Crooked Wheel",
		"distance": 35613.7,
		"moving_time": 5003,
		"elapsed_time": 6836,
		"total_elevation_gain": 195.3,
		"start_date_local": "2015-07-04T16:16:43Z",
		"average_speed": 7.118,
		"max_speed": 18.9,
		"calories": 824.8,
		"points": [
			[
				-79.42419,
				43.65312
			],
			[
				-79.42602,
				43.65273
			],
			[
				-79.42478,
				43.64953
			],
			[
				-79.42511,
				43.6495
			],
			[
				-79.42856,
				43.64955
			],
			[
				-79.42973,
				43.64966
			],
			[
				-79.43274,
				43.64973
			],
			[
				-79.43534,
				43.64989
			],
			[
				-79.43562,
				43.64999
			],
			[
				-79.44107,
				43.65029
			],
			[
				-79.44596,
				43.65141
			],
			[
				-79.44724,
				43.65176
			],
			[
				-79.44798,
				43.65212
			],
			[
				-79.44839,
				43.65223
			],
			[
				-79.4489,
				43.65201
			],
			[
				-79.45111,
				43.65157
			],
			[
				-79.45133,
				43.65143
			],
			[
				-79.45173,
				43.65135
			],
			[
				-79.45204,
				43.65142
			],
			[
				-79.45208,
				43.65128
			],
			[
				-79.45485,
				43.65069
			],
			[
				-79.45687,
				43.64971
			],
			[
				-79.45778,
				43.64865
			],
			[
				-79.45804,
				43.64854
			],
			[
				-79.45829,
				43.64816
			],
			[
				-79.4585,
				43.6477
			],
			[
				-79.45852,
				43.64721
			],
			[
				-79.45831,
				43.64687
			],
			[
				-79.45812,
				43.64675
			],
			[
				-79.45806,
				43.64655
			],
			[
				-79.45808,
				43.64515
			],
			[
				-79.45775,
				43.6446
			],
			[
				-79.45775,
				43.64447
			],
			[
				-79.45776,
				43.64436
			],
			[
				-79.45835,
				43.64423
			],
			[
				-79.45858,
				43.64436
			],
			[
				-79.45856,
				43.64447
			],
			[
				-79.45879,
				43.64478
			],
			[
				-79.45947,
				43.64506
			],
			[
				-79.46178,
				43.64527
			],
			[
				-79.46376,
				43.6452
			],
			[
				-79.46474,
				43.64561
			],
			[
				-79.46461,
				43.64616
			],
			[
				-79.46493,
				43.64709
			],
			[
				-79.46488,
				43.64783
			],
			[
				-79.46503,
				43.64872
			],
			[
				-79.46486,
				43.64979
			],
			[
				-79.46479,
				43.65218
			],
			[
				-79.46484,
				43.65248
			],
			[
				-79.46529,
				43.65354
			],
			[
				-79.46593,
				43.65351
			],
			[
				-79.4699,
				43.65258
			],
			[
				-79.47005,
				43.65261
			],
			[
				-79.47182,
				43.65213
			],
			[
				-79.47459,
				43.65161
			],
			[
				-79.47546,
				43.65132
			],
			[
				-79.4791,
				43.65063
			],
			[
				-79.48454,
				43.64939
			],
			[
				-79.48491,
				43.64923
			],
			[
				-79.48557,
				43.64861
			],
			[
				-79.48637,
				43.64812
			],
			[
				-79.48754,
				43.64786
			],
			[
				-79.48808,
				43.64783
			],
			[
				-79.48978,
				43.64792
			],
			[
				-79.49067,
				43.64808
			],
			[
				-79.49455,
				43.64935
			],
			[
				-79.49503,
				43.6496
			],
			[
				-79.49501,
				43.64972
			],
			[
				-79.49524,
				43.64966
			],
			[
				-79.49695,
				43.65001
			],
			[
				-79.49918,
				43.65016
			],
			[
				-79.50139,
				43.65058
			],
			[
				-79.50265,
				43.65109
			],
			[
				-79.5034,
				43.65162
			],
			[
				-79.50433,
				43.65243
			],
			[
				-79.50514,
				43.65277
			],
			[
				-79.50622,
				43.65281
			],
			[
				-79.50765,
				43.65262
			],
			[
				-79.5075,
				43.65264
			],
			[
				-79.5073,
				43.65287
			],
			[
				-79.50744,
				43.65272
			],
			[
				-79.50737,
				43.65274
			],
			[
				-79.50758,
				43.6528
			],
			[
				-79.50819,
				43.6526
			],
			[
				-79.51023,
				43.65298
			],
			[
				-79.51218,
				43.6538
			],
			[
				-79.51284,
				43.65431
			],
			[
				-79.5146,
				43.65756
			],
			[
				-79.51473,
				43.65801
			],
			[
				-79.51599,
				43.65779
			],
			[
				-79.51614,
				43.658
			],
			[
				-79.51736,
				43.66099
			],
			[
				-79.51779,
				43.66174
			],
			[
				-79.51829,
				43.66294
			],
			[
				-79.5186,
				43.66343
			],
			[
				-79.51925,
				43.66531
			],
			[
				-79.52096,
				43.6691
			],
			[
				-79.52119,
				43.66982
			],
			[
				-79.52195,
				43.67159
			],
			[
				-79.52308,
				43.67384
			],
			[
				-79.52347,
				43.6749
			],
			[
				-79.52353,
				43.67528
			],
			[
				-79.52455,
				43.67779
			],
			[
				-79.52451,
				43.67774
			],
			[
				-79.52564,
				43.68007
			],
			[
				-79.52613,
				43.68123
			],
			[
				-79.52642,
				43.68216
			],
			[
				-79.52614,
				43.68233
			],
			[
				-79.52311,
				43.68302
			],
			[
				-79.52058,
				43.68333
			],
			[
				-79.5147,
				43.6834
			],
			[
				-79.51373,
				43.68349
			],
			[
				-79.51247,
				43.68378
			],
			[
				-79.51147,
				43.68386
			],
			[
				-79.51142,
				43.68356
			],
			[
				-79.51132,
				43.68353
			],
			[
				-79.51071,
				43.68408
			],
			[
				-79.50742,
				43.68457
			],
			[
				-79.50661,
				43.68462
			],
			[
				-79.50555,
				43.68456
			],
			[
				-79.50251,
				43.68392
			],
			[
				-79.50087,
				43.68395
			],
			[
				-79.4986,
				43.68442
			],
			[
				-79.49799,
				43.68469
			],
			[
				-79.49691,
				43.68489
			],
			[
				-79.49642,
				43.6851
			],
			[
				-79.4917,
				43.68641
			],
			[
				-79.48853,
				43.68713
			],
			[
				-79.48672,
				43.6874
			],
			[
				-79.48486,
				43.6878
			],
			[
				-79.48303,
				43.68823
			],
			[
				-79.48019,
				43.68911
			],
			[
				-79.47915,
				43.68935
			],
			[
				-79.47791,
				43.68943
			],
			[
				-79.47621,
				43.68987
			],
			[
				-79.47587,
				43.68988
			],
			[
				-79.47399,
				43.6903
			],
			[
				-79.4733,
				43.69054
			],
			[
				-79.47229,
				43.69069
			],
			[
				-79.47068,
				43.69119
			],
			[
				-79.46999,
				43.69125
			],
			[
				-79.46886,
				43.69156
			],
			[
				-79.46768,
				43.69174
			],
			[
				-79.46626,
				43.69175
			],
			[
				-79.46522,
				43.69192
			],
			[
				-79.46451,
				43.69212
			],
			[
				-79.46396,
				43.69243
			],
			[
				-79.46483,
				43.6945
			],
			[
				-79.46514,
				43.69443
			],
			[
				-79.46534,
				43.69466
			],
			[
				-79.46519,
				43.69551
			],
			[
				-79.46502,
				43.69585
			],
			[
				-79.46466,
				43.69644
			],
			[
				-79.46387,
				43.69722
			],
			[
				-79.46334,
				43.69748
			],
			[
				-79.46315,
				43.69747
			],
			[
				-79.46184,
				43.69815
			],
			[
				-79.46124,
				43.69821
			],
			[
				-79.46082,
				43.69838
			],
			[
				-79.45973,
				43.69842
			],
			[
				-79.45949,
				43.69848
			],
			[
				-79.4594,
				43.69859
			],
			[
				-79.45916,
				43.69844
			],
			[
				-79.45808,
				43.69851
			],
			[
				-79.45767,
				43.69866
			],
			[
				-79.45693,
				43.69862
			],
			[
				-79.45617,
				43.6988
			],
			[
				-79.45534,
				43.69878
			],
			[
				-79.45465,
				43.69903
			],
			[
				-79.45368,
				43.69922
			],
			[
				-79.45313,
				43.69911
			],
			[
				-79.45305,
				43.69919
			],
			[
				-79.45248,
				43.69926
			],
			[
				-79.45172,
				43.69925
			],
			[
				-79.45022,
				43.69949
			],
			[
				-79.44983,
				43.69966
			],
			[
				-79.44926,
				43.6997
			],
			[
				-79.44762,
				43.70017
			],
			[
				-79.44686,
				43.70013
			],
			[
				-79.44677,
				43.70026
			],
			[
				-79.44631,
				43.70048
			],
			[
				-79.44444,
				43.70089
			],
			[
				-79.44468,
				43.70145
			],
			[
				-79.44463,
				43.70153
			],
			[
				-79.44387,
				43.70162
			],
			[
				-79.4369,
				43.70313
			],
			[
				-79.43702,
				43.7031
			],
			[
				-79.4369,
				43.70273
			],
			[
				-79.43656,
				43.70268
			],
			[
				-79.43575,
				43.70296
			],
			[
				-79.43404,
				43.70325
			],
			[
				-79.43332,
				43.70347
			],
			[
				-79.43297,
				43.70373
			],
			[
				-79.43252,
				43.70382
			],
			[
				-79.43175,
				43.70429
			],
			[
				-79.43081,
				43.70456
			],
			[
				-79.43072,
				43.70455
			],
			[
				-79.43078,
				43.70451
			],
			[
				-79.43023,
				43.70468
			],
			[
				-79.42751,
				43.70521
			],
			[
				-79.42732,
				43.70522
			],
			[
				-79.42699,
				43.70479
			],
			[
				-79.42678,
				43.70472
			],
			[
				-79.42651,
				43.70486
			],
			[
				-79.42638,
				43.70478
			],
			[
				-79.4264,
				43.70472
			],
			[
				-79.42624,
				43.70469
			],
			[
				-79.4258,
				43.70476
			],
			[
				-79.42554,
				43.70473
			],
			[
				-79.42552,
				43.70465
			],
			[
				-79.42533,
				43.70471
			],
			[
				-79.42509,
				43.70465
			],
			[
				-79.42471,
				43.70475
			],
			[
				-79.42454,
				43.70454
			],
			[
				-79.4238,
				43.7046
			],
			[
				-79.42305,
				43.70434
			],
			[
				-79.42264,
				43.70434
			],
			[
				-79.42191,
				43.70418
			],
			[
				-79.42174,
				43.70433
			],
			[
				-79.4218,
				43.70423
			],
			[
				-79.42164,
				43.70423
			],
			[
				-79.42052,
				43.70378
			],
			[
				-79.41923,
				43.70353
			],
			[
				-79.41725,
				43.7026
			],
			[
				-79.40612,
				43.69875
			],
			[
				-79.40329,
				43.6979
			],
			[
				-79.40288,
				43.69694
			],
			[
				-79.40291,
				43.69704
			],
			[
				-79.40274,
				43.69708
			],
			[
				-79.40214,
				43.69685
			],
			[
				-79.40182,
				43.69701
			],
			[
				-79.40028,
				43.69659
			],
			[
				-79.40018,
				43.69655
			],
			[
				-79.39994,
				43.69607
			],
			[
				-79.3998,
				43.69601
			],
			[
				-79.39928,
				43.69611
			],
			[
				-79.39912,
				43.69605
			],
			[
				-79.3989,
				43.69579
			],
			[
				-79.39779,
				43.69562
			],
			[
				-79.39743,
				43.69569
			],
			[
				-79.39689,
				43.69557
			],
			[
				-79.3965,
				43.69561
			],
			[
				-79.39635,
				43.6957
			],
			[
				-79.39576,
				43.69565
			],
			[
				-79.39507,
				43.69591
			],
			[
				-79.395,
				43.69579
			],
			[
				-79.3945,
				43.69562
			],
			[
				-79.39416,
				43.69564
			],
			[
				-79.39336,
				43.69586
			],
			[
				-79.39223,
				43.69586
			],
			[
				-79.39223,
				43.69559
			],
			[
				-79.39246,
				43.69538
			],
			[
				-79.39302,
				43.69522
			],
			[
				-79.39351,
				43.69487
			],
			[
				-79.39399,
				43.69469
			],
			[
				-79.39412,
				43.69455
			],
			[
				-79.39409,
				43.6944
			],
			[
				-79.39379,
				43.6941
			],
			[
				-79.39349,
				43.69411
			],
			[
				-79.39336,
				43.69422
			],
			[
				-79.39295,
				43.69432
			],
			[
				-79.39232,
				43.69412
			],
			[
				-79.39213,
				43.69372
			],
			[
				-79.3918,
				43.69331
			],
			[
				-79.39169,
				43.69271
			],
			[
				-79.39141,
				43.69239
			],
			[
				-79.39094,
				43.69222
			],
			[
				-79.3909,
				43.69212
			],
			[
				-79.39032,
				43.69236
			],
			[
				-79.39029,
				43.69269
			],
			[
				-79.39036,
				43.693
			],
			[
				-79.38937,
				43.69359
			],
			[
				-79.38911,
				43.69409
			],
			[
				-79.38857,
				43.6945
			],
			[
				-79.38778,
				43.6946
			],
			[
				-79.38719,
				43.69479
			],
			[
				-79.38672,
				43.69476
			],
			[
				-79.38608,
				43.69485
			],
			[
				-79.38593,
				43.69493
			],
			[
				-79.38564,
				43.69537
			],
			[
				-79.38549,
				43.69533
			],
			[
				-79.38376,
				43.69131
			],
			[
				-79.38313,
				43.68949
			],
			[
				-79.38325,
				43.68908
			],
			[
				-79.38436,
				43.687
			],
			[
				-79.38444,
				43.68675
			],
			[
				-79.38443,
				43.6862
			],
			[
				-79.38356,
				43.684
			],
			[
				-79.38332,
				43.68249
			],
			[
				-79.38317,
				43.68209
			],
			[
				-79.38272,
				43.68128
			],
			[
				-79.3825,
				43.68071
			],
			[
				-79.3813,
				43.67945
			],
			[
				-79.3806,
				43.67778
			],
			[
				-79.38068,
				43.67724
			],
			[
				-79.38064,
				43.6768
			],
			[
				-79.38076,
				43.67657
			],
			[
				-79.38069,
				43.67556
			],
			[
				-79.37991,
				43.67362
			],
			[
				-79.37958,
				43.6722
			],
			[
				-79.3795,
				43.67139
			],
			[
				-79.37953,
				43.67107
			],
			[
				-79.3797,
				43.67075
			],
			[
				-79.37946,
				43.67072
			],
			[
				-79.37967,
				43.67053
			],
			[
				-79.37973,
				43.67056
			],
			[
				-79.37959,
				43.67059
			],
			[
				-79.38013,
				43.67065
			],
			[
				-79.38007,
				43.67022
			],
			[
				-79.3789,
				43.66757
			],
			[
				-79.37895,
				43.66751
			],
			[
				-79.37851,
				43.66635
			],
			[
				-79.37848,
				43.66642
			],
			[
				-79.37858,
				43.66624
			],
			[
				-79.3926,
				43.66322
			],
			[
				-79.39355,
				43.66421
			],
			[
				-79.39367,
				43.66499
			],
			[
				-79.39377,
				43.66506
			],
			[
				-79.39405,
				43.66506
			],
			[
				-79.39375,
				43.6645
			],
			[
				-79.39342,
				43.6635
			],
			[
				-79.393,
				43.66259
			],
			[
				-79.39243,
				43.66172
			],
			[
				-79.39223,
				43.66125
			],
			[
				-79.39185,
				43.6609
			],
			[
				-79.39096,
				43.66044
			],
			[
				-79.39071,
				43.65991
			],
			[
				-79.39092,
				43.65978
			],
			[
				-79.39109,
				43.65982
			],
			[
				-79.39546,
				43.65889
			],
			[
				-79.39956,
				43.65812
			],
			[
				-79.40031,
				43.65805
			],
			[
				-79.40753,
				43.65661
			],
			[
				-79.40775,
				43.65673
			],
			[
				-79.40792,
				43.65654
			],
			[
				-79.41508,
				43.65505
			],
			[
				-79.41552,
				43.65502
			],
			[
				-79.41717,
				43.65526
			],
			[
				-79.41844,
				43.65531
			],
			[
				-79.42223,
				43.65455
			]
		],
		"dupe": false
	},
	{
		"id": 339968267,
		"name": "Evening Ride",
		"distance": 1567,
		"moving_time": 255,
		"elapsed_time": 275,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-05T18:06:39Z",
		"average_speed": 6.145,
		"max_speed": 10.5,
		"calories": 24.4,
		"points": [
			[
				-79.39774,
				43.6456
			],
			[
				-79.39786,
				43.64548
			],
			[
				-79.3977,
				43.64529
			],
			[
				-79.39765,
				43.64506
			],
			[
				-79.39773,
				43.645
			],
			[
				-79.39979,
				43.64455
			],
			[
				-79.40011,
				43.64456
			],
			[
				-79.40125,
				43.64434
			],
			[
				-79.40228,
				43.64406
			],
			[
				-79.4024,
				43.64411
			],
			[
				-79.40245,
				43.64402
			],
			[
				-79.40268,
				43.64403
			],
			[
				-79.40268,
				43.64392
			],
			[
				-79.40273,
				43.64399
			],
			[
				-79.40335,
				43.64388
			],
			[
				-79.40598,
				43.64324
			],
			[
				-79.41185,
				43.6421
			],
			[
				-79.412,
				43.64205
			],
			[
				-79.41177,
				43.64148
			],
			[
				-79.41341,
				43.6411
			],
			[
				-79.4135,
				43.64103
			],
			[
				-79.41348,
				43.64087
			]
		],
		"dupe": false
	},
	{
		"id": 340365560,
		"name": "Lunch Ride",
		"distance": 1636.7,
		"moving_time": 328,
		"elapsed_time": 432,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-06T12:40:02Z",
		"average_speed": 4.99,
		"max_speed": 10.6,
		"calories": 27.2,
		"points": [
			[
				-79.42953,
				43.64428
			],
			[
				-79.42891,
				43.64274
			],
			[
				-79.42877,
				43.64197
			],
			[
				-79.42885,
				43.64203
			],
			[
				-79.42871,
				43.64216
			],
			[
				-79.42882,
				43.64204
			],
			[
				-79.42866,
				43.6419
			],
			[
				-79.42831,
				43.64116
			],
			[
				-79.42746,
				43.63899
			],
			[
				-79.42737,
				43.63903
			],
			[
				-79.42742,
				43.63893
			],
			[
				-79.42661,
				43.63691
			],
			[
				-79.42627,
				43.63713
			],
			[
				-79.42497,
				43.63745
			],
			[
				-79.42425,
				43.6376
			],
			[
				-79.42378,
				43.63759
			],
			[
				-79.42352,
				43.63771
			],
			[
				-79.42061,
				43.63829
			],
			[
				-79.42009,
				43.63709
			],
			[
				-79.42028,
				43.63744
			]
		],
		"dupe": false
	},
	{
		"id": 343049237,
		"name": "Morning Ride",
		"distance": 1699.2,
		"moving_time": 266,
		"elapsed_time": 281,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-10T10:29:52Z",
		"average_speed": 6.388,
		"max_speed": 11.1,
		"calories": 34,
		"points": [
			[
				-79.42416,
				43.654
			],
			[
				-79.42414,
				43.65414
			],
			[
				-79.42445,
				43.65411
			],
			[
				-79.42725,
				43.65351
			],
			[
				-79.4278,
				43.65333
			],
			[
				-79.42822,
				43.6533
			],
			[
				-79.42905,
				43.65302
			],
			[
				-79.42995,
				43.65288
			],
			[
				-79.43109,
				43.65293
			],
			[
				-79.43148,
				43.65279
			],
			[
				-79.43205,
				43.65274
			],
			[
				-79.43254,
				43.65259
			],
			[
				-79.43261,
				43.6525
			],
			[
				-79.43231,
				43.6515
			],
			[
				-79.43125,
				43.64897
			],
			[
				-79.43113,
				43.64845
			],
			[
				-79.43066,
				43.64739
			],
			[
				-79.43055,
				43.64691
			],
			[
				-79.43036,
				43.64656
			],
			[
				-79.42996,
				43.64533
			],
			[
				-79.42977,
				43.64505
			],
			[
				-79.42971,
				43.64468
			],
			[
				-79.42952,
				43.64427
			]
		],
		"dupe": false
	},
	{
		"id": 344030801,
		"name": "Toronto -> Go Home Lake [Attempt bailed]",
		"distance": 191442,
		"moving_time": 30381,
		"elapsed_time": 45029,
		"total_elevation_gain": 1133.6,
		"start_date_local": "2015-07-11T04:11:28Z",
		"average_speed": 6.301,
		"max_speed": 18.9,
		"calories": 3815.2,
		"points": [
			[
				-79.42383,
				43.65429
			],
			[
				-79.42411,
				43.65414
			],
			[
				-79.42981,
				43.65295
			],
			[
				-79.43029,
				43.65292
			],
			[
				-79.43086,
				43.65302
			],
			[
				-79.43221,
				43.65265
			],
			[
				-79.43391,
				43.65243
			],
			[
				-79.43529,
				43.65204
			],
			[
				-79.43802,
				43.65155
			],
			[
				-79.43854,
				43.65154
			],
			[
				-79.43988,
				43.65118
			],
			[
				-79.44114,
				43.65038
			],
			[
				-79.44259,
				43.65066
			],
			[
				-79.44374,
				43.65103
			],
			[
				-79.44459,
				43.65185
			],
			[
				-79.44539,
				43.65282
			],
			[
				-79.44565,
				43.65326
			],
			[
				-79.44671,
				43.654
			],
			[
				-79.44807,
				43.65538
			],
			[
				-79.44857,
				43.65579
			],
			[
				-79.45082,
				43.65814
			],
			[
				-79.45082,
				43.65842
			],
			[
				-79.4511,
				43.65847
			],
			[
				-79.45166,
				43.659
			],
			[
				-79.45374,
				43.66112
			],
			[
				-79.45387,
				43.66133
			],
			[
				-79.45434,
				43.66168
			],
			[
				-79.4549,
				43.66237
			],
			[
				-79.45624,
				43.66375
			],
			[
				-79.45631,
				43.66396
			],
			[
				-79.45664,
				43.66412
			],
			[
				-79.45779,
				43.66526
			],
			[
				-79.45818,
				43.66611
			],
			[
				-79.45836,
				43.66634
			],
			[
				-79.45828,
				43.66651
			],
			[
				-79.45832,
				43.66671
			],
			[
				-79.45783,
				43.66684
			],
			[
				-79.45778,
				43.66695
			],
			[
				-79.4582,
				43.66745
			],
			[
				-79.45879,
				43.66858
			],
			[
				-79.45952,
				43.67025
			],
			[
				-79.4595,
				43.67037
			],
			[
				-79.45981,
				43.67056
			],
			[
				-79.46216,
				43.67109
			],
			[
				-79.46237,
				43.6713
			],
			[
				-79.46285,
				43.67329
			],
			[
				-79.46403,
				43.67713
			],
			[
				-79.4652,
				43.67926
			],
			[
				-79.46564,
				43.67989
			],
			[
				-79.46779,
				43.682
			],
			[
				-79.46904,
				43.6824
			],
			[
				-79.47291,
				43.68211
			],
			[
				-79.4766,
				43.68172
			],
			[
				-79.4769,
				43.68182
			],
			[
				-79.47804,
				43.68267
			],
			[
				-79.47893,
				43.68305
			],
			[
				-79.47998,
				43.68338
			],
			[
				-79.48165,
				43.68363
			],
			[
				-79.48366,
				43.68414
			],
			[
				-79.4856,
				43.68516
			],
			[
				-79.49045,
				43.68744
			],
			[
				-79.49491,
				43.68895
			],
			[
				-79.49743,
				43.68989
			],
			[
				-79.49925,
				43.69073
			],
			[
				-79.5007,
				43.69127
			],
			[
				-79.50082,
				43.69146
			],
			[
				-79.50254,
				43.6987
			],
			[
				-79.50339,
				43.70183
			],
			[
				-79.50354,
				43.70212
			],
			[
				-79.50368,
				43.70295
			],
			[
				-79.50401,
				43.70387
			],
			[
				-79.50602,
				43.71168
			],
			[
				-79.50614,
				43.71178
			],
			[
				-79.5067,
				43.71408
			],
			[
				-79.50704,
				43.71509
			],
			[
				-79.5072,
				43.71601
			],
			[
				-79.50816,
				43.7194
			],
			[
				-79.5088,
				43.72214
			],
			[
				-79.50902,
				43.72275
			],
			[
				-79.50904,
				43.7232
			],
			[
				-79.50931,
				43.72387
			],
			[
				-79.50962,
				43.72539
			],
			[
				-79.50998,
				43.72655
			],
			[
				-79.51085,
				43.73056
			],
			[
				-79.51098,
				43.73085
			],
			[
				-79.51103,
				43.73138
			],
			[
				-79.51142,
				43.73278
			],
			[
				-79.51191,
				43.7351
			],
			[
				-79.51287,
				43.73875
			],
			[
				-79.51338,
				43.74014
			],
			[
				-79.51335,
				43.74024
			],
			[
				-79.51395,
				43.74282
			],
			[
				-79.51465,
				43.74536
			],
			[
				-79.51574,
				43.75009
			],
			[
				-79.51613,
				43.75134
			],
			[
				-79.51894,
				43.76265
			],
			[
				-79.51929,
				43.76459
			],
			[
				-79.5195,
				43.76515
			],
			[
				-79.52012,
				43.76816
			],
			[
				-79.52029,
				43.76865
			],
			[
				-79.52046,
				43.76976
			],
			[
				-79.52088,
				43.77124
			],
			[
				-79.52157,
				43.77422
			],
			[
				-79.52164,
				43.77601
			],
			[
				-79.5214,
				43.77685
			],
			[
				-79.52082,
				43.77785
			],
			[
				-79.52068,
				43.77851
			],
			[
				-79.52069,
				43.77893
			],
			[
				-79.52144,
				43.78225
			],
			[
				-79.52156,
				43.7824
			],
			[
				-79.52215,
				43.78393
			],
			[
				-79.52325,
				43.78617
			],
			[
				-79.52366,
				43.7873
			],
			[
				-79.52384,
				43.78844
			],
			[
				-79.52379,
				43.79104
			],
			[
				-79.52417,
				43.79309
			],
			[
				-79.52441,
				43.79384
			],
			[
				-79.52467,
				43.79553
			],
			[
				-79.52509,
				43.79721
			],
			[
				-79.52556,
				43.79986
			],
			[
				-79.52648,
				43.80353
			],
			[
				-79.52693,
				43.80612
			],
			[
				-79.52705,
				43.80647
			],
			[
				-79.52707,
				43.80688
			],
			[
				-79.52719,
				43.8071
			],
			[
				-79.52736,
				43.80826
			],
			[
				-79.52757,
				43.80896
			],
			[
				-79.52775,
				43.81011
			],
			[
				-79.52797,
				43.8108
			],
			[
				-79.52821,
				43.81228
			],
			[
				-79.52846,
				43.81293
			],
			[
				-79.52875,
				43.81459
			],
			[
				-79.53015,
				43.82044
			],
			[
				-79.53023,
				43.82111
			],
			[
				-79.53233,
				43.82975
			],
			[
				-79.53325,
				43.83396
			],
			[
				-79.5336,
				43.8352
			],
			[
				-79.53357,
				43.83554
			],
			[
				-79.53385,
				43.83706
			],
			[
				-79.53423,
				43.8388
			],
			[
				-79.53437,
				43.83905
			],
			[
				-79.53436,
				43.83933
			],
			[
				-79.53513,
				43.84269
			],
			[
				-79.53575,
				43.84585
			],
			[
				-79.53623,
				43.84758
			],
			[
				-79.53634,
				43.8484
			],
			[
				-79.53658,
				43.84932
			],
			[
				-79.53649,
				43.84936
			],
			[
				-79.53677,
				43.85029
			],
			[
				-79.53694,
				43.8514
			],
			[
				-79.53779,
				43.85485
			],
			[
				-79.53814,
				43.8568
			],
			[
				-79.5386,
				43.85848
			],
			[
				-79.53906,
				43.86103
			],
			[
				-79.53914,
				43.8611
			],
			[
				-79.53937,
				43.86187
			],
			[
				-79.5396,
				43.8631
			],
			[
				-79.53977,
				43.86341
			],
			[
				-79.53991,
				43.8642
			],
			[
				-79.53986,
				43.86425
			],
			[
				-79.54024,
				43.86598
			],
			[
				-79.54109,
				43.86903
			],
			[
				-79.54163,
				43.87167
			],
			[
				-79.54257,
				43.87543
			],
			[
				-79.54294,
				43.87733
			],
			[
				-79.54321,
				43.87803
			],
			[
				-79.54318,
				43.87818
			],
			[
				-79.54393,
				43.88126
			],
			[
				-79.54393,
				43.88158
			],
			[
				-79.54411,
				43.88191
			],
			[
				-79.54444,
				43.88373
			],
			[
				-79.54473,
				43.88466
			],
			[
				-79.54484,
				43.88562
			],
			[
				-79.545,
				43.88596
			],
			[
				-79.54535,
				43.88724
			],
			[
				-79.54557,
				43.88848
			],
			[
				-79.54574,
				43.88885
			],
			[
				-79.54583,
				43.88949
			],
			[
				-79.54619,
				43.89086
			],
			[
				-79.54664,
				43.89227
			],
			[
				-79.54665,
				43.89275
			],
			[
				-79.54679,
				43.89317
			],
			[
				-79.54682,
				43.89357
			],
			[
				-79.54692,
				43.89369
			],
			[
				-79.54733,
				43.89533
			],
			[
				-79.54766,
				43.89714
			],
			[
				-79.54784,
				43.89749
			],
			[
				-79.54814,
				43.89901
			],
			[
				-79.54831,
				43.89942
			],
			[
				-79.5496,
				43.90477
			],
			[
				-79.54989,
				43.90547
			],
			[
				-79.55031,
				43.90707
			],
			[
				-79.55038,
				43.90779
			],
			[
				-79.55051,
				43.9081
			],
			[
				-79.55055,
				43.90863
			],
			[
				-79.55084,
				43.90998
			],
			[
				-79.55223,
				43.91539
			],
			[
				-79.55244,
				43.91646
			],
			[
				-79.55304,
				43.91857
			],
			[
				-79.55396,
				43.92249
			],
			[
				-79.55392,
				43.92255
			],
			[
				-79.55407,
				43.92289
			],
			[
				-79.55525,
				43.92739
			],
			[
				-79.55546,
				43.92851
			],
			[
				-79.55577,
				43.92955
			],
			[
				-79.56289,
				43.9589
			],
			[
				-79.57134,
				43.9963
			],
			[
				-79.57146,
				43.99657
			],
			[
				-79.5716,
				43.99748
			],
			[
				-79.57185,
				43.99826
			],
			[
				-79.57189,
				43.9988
			],
			[
				-79.57271,
				44.00218
			],
			[
				-79.5731,
				44.00418
			],
			[
				-79.57316,
				44.00434
			],
			[
				-79.57324,
				44.00436
			],
			[
				-79.57324,
				44.00503
			],
			[
				-79.57335,
				44.00519
			],
			[
				-79.57332,
				44.0055
			],
			[
				-79.57351,
				44.00575
			],
			[
				-79.57385,
				44.00711
			],
			[
				-79.5796,
				44.03229
			],
			[
				-79.57966,
				44.03244
			],
			[
				-79.5801,
				44.03244
			],
			[
				-79.58189,
				44.03204
			],
			[
				-79.58509,
				44.04487
			],
			[
				-79.58532,
				44.04484
			],
			[
				-79.58518,
				44.04488
			],
			[
				-79.58507,
				44.04479
			],
			[
				-79.58501,
				44.04491
			],
			[
				-79.59482,
				44.04275
			],
			[
				-79.59626,
				44.04253
			],
			[
				-79.59714,
				44.04224
			],
			[
				-79.59748,
				44.0422
			],
			[
				-79.59756,
				44.04229
			],
			[
				-79.59796,
				44.04229
			],
			[
				-79.59948,
				44.04185
			],
			[
				-79.60016,
				44.0424
			],
			[
				-79.60118,
				44.04374
			],
			[
				-79.60173,
				44.04423
			],
			[
				-79.60311,
				44.04588
			],
			[
				-79.60377,
				44.04654
			],
			[
				-79.60435,
				44.04734
			],
			[
				-79.6055,
				44.04868
			],
			[
				-79.60738,
				44.05066
			],
			[
				-79.60773,
				44.0513
			],
			[
				-79.60784,
				44.05131
			],
			[
				-79.609,
				44.05264
			],
			[
				-79.6086,
				44.053
			],
			[
				-79.60661,
				44.05405
			],
			[
				-79.60382,
				44.05577
			],
			[
				-79.60194,
				44.05649
			],
			[
				-79.59721,
				44.05859
			],
			[
				-79.59678,
				44.05897
			],
			[
				-79.59598,
				44.05943
			],
			[
				-79.59365,
				44.06114
			],
			[
				-79.59244,
				44.06249
			],
			[
				-79.59222,
				44.06263
			],
			[
				-79.59197,
				44.06312
			],
			[
				-79.58929,
				44.06661
			],
			[
				-79.58843,
				44.06883
			],
			[
				-79.58794,
				44.07038
			],
			[
				-79.5855,
				44.07712
			],
			[
				-79.58513,
				44.07797
			],
			[
				-79.58488,
				44.07819
			],
			[
				-79.58398,
				44.07853
			],
			[
				-79.58044,
				44.07966
			],
			[
				-79.57882,
				44.08056
			],
			[
				-79.57824,
				44.08112
			],
			[
				-79.57757,
				44.08235
			],
			[
				-79.57678,
				44.08414
			],
			[
				-79.57702,
				44.08434
			],
			[
				-79.57855,
				44.08514
			],
			[
				-79.57969,
				44.08529
			],
			[
				-79.59105,
				44.08267
			],
			[
				-79.59557,
				44.08168
			],
			[
				-79.59573,
				44.08165
			],
			[
				-79.5958,
				44.08171
			],
			[
				-79.59593,
				44.08251
			],
			[
				-79.59637,
				44.08385
			],
			[
				-79.59678,
				44.08566
			],
			[
				-79.59707,
				44.08649
			],
			[
				-79.59755,
				44.0893
			],
			[
				-79.59778,
				44.09026
			],
			[
				-79.59813,
				44.09127
			],
			[
				-79.59825,
				44.09203
			],
			[
				-79.59867,
				44.0935
			],
			[
				-79.59887,
				44.09476
			],
			[
				-79.59913,
				44.09575
			],
			[
				-79.59929,
				44.09608
			],
			[
				-79.60034,
				44.10045
			],
			[
				-79.60116,
				44.10328
			],
			[
				-79.60143,
				44.10443
			],
			[
				-79.60148,
				44.10511
			],
			[
				-79.602,
				44.10731
			],
			[
				-79.60263,
				44.10942
			],
			[
				-79.60334,
				44.11235
			],
			[
				-79.60377,
				44.11337
			],
			[
				-79.60436,
				44.11534
			],
			[
				-79.60467,
				44.11681
			],
			[
				-79.60594,
				44.1215
			],
			[
				-79.60658,
				44.12425
			],
			[
				-79.60703,
				44.12685
			],
			[
				-79.60802,
				44.13036
			],
			[
				-79.60844,
				44.13231
			],
			[
				-79.60879,
				44.13322
			],
			[
				-79.60908,
				44.13461
			],
			[
				-79.60942,
				44.13548
			],
			[
				-79.60959,
				44.13705
			],
			[
				-79.61,
				44.13924
			],
			[
				-79.61036,
				44.14068
			],
			[
				-79.61033,
				44.14096
			],
			[
				-79.61071,
				44.14208
			],
			[
				-79.61115,
				44.14384
			],
			[
				-79.61163,
				44.14522
			],
			[
				-79.61179,
				44.14618
			],
			[
				-79.61208,
				44.14695
			],
			[
				-79.61207,
				44.14725
			],
			[
				-79.61308,
				44.15081
			],
			[
				-79.61307,
				44.15102
			],
			[
				-79.61322,
				44.15132
			],
			[
				-79.61319,
				44.15154
			],
			[
				-79.61333,
				44.15177
			],
			[
				-79.61543,
				44.15913
			],
			[
				-79.61558,
				44.16001
			],
			[
				-79.61567,
				44.16228
			],
			[
				-79.61579,
				44.16324
			],
			[
				-79.61658,
				44.16609
			],
			[
				-79.61664,
				44.16659
			],
			[
				-79.61681,
				44.16697
			],
			[
				-79.61703,
				44.16822
			],
			[
				-79.61744,
				44.16924
			],
			[
				-79.61742,
				44.16941
			],
			[
				-79.61773,
				44.17068
			],
			[
				-79.61814,
				44.17206
			],
			[
				-79.61833,
				44.17285
			],
			[
				-79.6186,
				44.17469
			],
			[
				-79.61888,
				44.17563
			],
			[
				-79.61886,
				44.17598
			],
			[
				-79.61921,
				44.17674
			],
			[
				-79.61987,
				44.17943
			],
			[
				-79.62019,
				44.18042
			],
			[
				-79.62044,
				44.18175
			],
			[
				-79.62099,
				44.18359
			],
			[
				-79.62105,
				44.18417
			],
			[
				-79.62099,
				44.18434
			],
			[
				-79.62109,
				44.18455
			],
			[
				-79.62146,
				44.18657
			],
			[
				-79.62202,
				44.18853
			],
			[
				-79.62202,
				44.18879
			],
			[
				-79.6221,
				44.18893
			],
			[
				-79.62205,
				44.189
			],
			[
				-79.62229,
				44.18931
			],
			[
				-79.62226,
				44.18955
			],
			[
				-79.62266,
				44.19074
			],
			[
				-79.62283,
				44.19163
			],
			[
				-79.62378,
				44.1946
			],
			[
				-79.62369,
				44.19486
			],
			[
				-79.62396,
				44.19596
			],
			[
				-79.62417,
				44.19632
			],
			[
				-79.62435,
				44.19694
			],
			[
				-79.62433,
				44.19748
			],
			[
				-79.6244,
				44.19755
			],
			[
				-79.62433,
				44.19768
			],
			[
				-79.6247,
				44.19862
			],
			[
				-79.62493,
				44.19893
			],
			[
				-79.62475,
				44.19914
			],
			[
				-79.62476,
				44.19956
			],
			[
				-79.62465,
				44.19964
			],
			[
				-79.62478,
				44.19972
			],
			[
				-79.62474,
				44.19995
			],
			[
				-79.62481,
				44.20011
			],
			[
				-79.62518,
				44.20054
			],
			[
				-79.62534,
				44.201
			],
			[
				-79.6253,
				44.20113
			],
			[
				-79.62556,
				44.20159
			],
			[
				-79.62581,
				44.20324
			],
			[
				-79.62619,
				44.20436
			],
			[
				-79.62639,
				44.20519
			],
			[
				-79.62637,
				44.20538
			],
			[
				-79.62699,
				44.20751
			],
			[
				-79.62741,
				44.20835
			],
			[
				-79.62782,
				44.20891
			],
			[
				-79.62822,
				44.20974
			],
			[
				-79.6285,
				44.21003
			],
			[
				-79.62858,
				44.21032
			],
			[
				-79.62921,
				44.2115
			],
			[
				-79.62935,
				44.21198
			],
			[
				-79.63007,
				44.21361
			],
			[
				-79.6311,
				44.21786
			],
			[
				-79.63145,
				44.21902
			],
			[
				-79.63174,
				44.22042
			],
			[
				-79.63227,
				44.22228
			],
			[
				-79.63258,
				44.22378
			],
			[
				-79.63345,
				44.22609
			],
			[
				-79.63365,
				44.22706
			],
			[
				-79.63413,
				44.2287
			],
			[
				-79.63435,
				44.22983
			],
			[
				-79.63457,
				44.23045
			],
			[
				-79.63487,
				44.2319
			],
			[
				-79.63504,
				44.2323
			],
			[
				-79.63513,
				44.23312
			],
			[
				-79.63546,
				44.23392
			],
			[
				-79.63553,
				44.23458
			],
			[
				-79.63547,
				44.2347
			],
			[
				-79.63572,
				44.23531
			],
			[
				-79.63603,
				44.23672
			],
			[
				-79.63603,
				44.23696
			],
			[
				-79.63592,
				44.23702
			],
			[
				-79.6359,
				44.2372
			],
			[
				-79.63607,
				44.23791
			],
			[
				-79.63644,
				44.23897
			],
			[
				-79.63683,
				44.24044
			],
			[
				-79.63702,
				44.24149
			],
			[
				-79.63781,
				44.24412
			],
			[
				-79.63785,
				44.24458
			],
			[
				-79.63825,
				44.2463
			],
			[
				-79.63905,
				44.2488
			],
			[
				-79.63903,
				44.24913
			],
			[
				-79.63921,
				44.25038
			],
			[
				-79.63966,
				44.25249
			],
			[
				-79.64003,
				44.2537
			],
			[
				-79.64,
				44.25392
			],
			[
				-79.64022,
				44.25448
			],
			[
				-79.64022,
				44.25486
			],
			[
				-79.64061,
				44.25672
			],
			[
				-79.64096,
				44.25777
			],
			[
				-79.64093,
				44.25807
			],
			[
				-79.64118,
				44.25878
			],
			[
				-79.64114,
				44.25912
			],
			[
				-79.64127,
				44.25933
			],
			[
				-79.64125,
				44.25959
			],
			[
				-79.64145,
				44.26015
			],
			[
				-79.64138,
				44.2602
			],
			[
				-79.64139,
				44.26034
			],
			[
				-79.64149,
				44.26069
			],
			[
				-79.64179,
				44.26122
			],
			[
				-79.64217,
				44.26227
			],
			[
				-79.6422,
				44.26265
			],
			[
				-79.64229,
				44.26271
			],
			[
				-79.64248,
				44.26322
			],
			[
				-79.64283,
				44.26513
			],
			[
				-79.64299,
				44.26549
			],
			[
				-79.64315,
				44.26627
			],
			[
				-79.64336,
				44.2667
			],
			[
				-79.64335,
				44.26717
			],
			[
				-79.6438,
				44.26827
			],
			[
				-79.644,
				44.26947
			],
			[
				-79.6443,
				44.27021
			],
			[
				-79.64455,
				44.27134
			],
			[
				-79.6445,
				44.27151
			],
			[
				-79.64483,
				44.27238
			],
			[
				-79.64511,
				44.2736
			],
			[
				-79.64531,
				44.27404
			],
			[
				-79.64569,
				44.27544
			],
			[
				-79.64589,
				44.27586
			],
			[
				-79.64602,
				44.2767
			],
			[
				-79.64612,
				44.27691
			],
			[
				-79.646,
				44.27701
			],
			[
				-79.64602,
				44.27712
			],
			[
				-79.64639,
				44.27801
			],
			[
				-79.64649,
				44.27809
			],
			[
				-79.64666,
				44.27879
			],
			[
				-79.64659,
				44.27899
			],
			[
				-79.64698,
				44.28035
			],
			[
				-79.64714,
				44.28064
			],
			[
				-79.64736,
				44.28157
			],
			[
				-79.64726,
				44.28185
			],
			[
				-79.64751,
				44.28235
			],
			[
				-79.6481,
				44.28438
			],
			[
				-79.64834,
				44.28489
			],
			[
				-79.64825,
				44.28501
			],
			[
				-79.64833,
				44.28522
			],
			[
				-79.64822,
				44.28554
			],
			[
				-79.64855,
				44.28595
			],
			[
				-79.64884,
				44.28667
			],
			[
				-79.64883,
				44.28691
			],
			[
				-79.649,
				44.28757
			],
			[
				-79.64887,
				44.28793
			],
			[
				-79.64917,
				44.28882
			],
			[
				-79.64944,
				44.28919
			],
			[
				-79.6497,
				44.28982
			],
			[
				-79.64982,
				44.29064
			],
			[
				-79.65013,
				44.29142
			],
			[
				-79.65018,
				44.29189
			],
			[
				-79.65048,
				44.29269
			],
			[
				-79.65108,
				44.29535
			],
			[
				-79.65149,
				44.29652
			],
			[
				-79.65142,
				44.29679
			],
			[
				-79.65148,
				44.29714
			],
			[
				-79.65141,
				44.29729
			],
			[
				-79.6514,
				44.29806
			],
			[
				-79.65147,
				44.29826
			],
			[
				-79.6514,
				44.29874
			],
			[
				-79.65145,
				44.29903
			],
			[
				-79.65169,
				44.29948
			],
			[
				-79.65185,
				44.30022
			],
			[
				-79.65179,
				44.30056
			],
			[
				-79.65188,
				44.30061
			],
			[
				-79.65181,
				44.30074
			],
			[
				-79.65189,
				44.30077
			],
			[
				-79.65184,
				44.30093
			],
			[
				-79.65192,
				44.30111
			],
			[
				-79.6519,
				44.30135
			],
			[
				-79.65175,
				44.3013
			],
			[
				-79.65209,
				44.30153
			],
			[
				-79.65194,
				44.30166
			],
			[
				-79.65228,
				44.30219
			],
			[
				-79.6525,
				44.30272
			],
			[
				-79.65246,
				44.3029
			],
			[
				-79.65254,
				44.303
			],
			[
				-79.65262,
				44.30374
			],
			[
				-79.65299,
				44.30478
			],
			[
				-79.65297,
				44.30509
			],
			[
				-79.65338,
				44.30631
			],
			[
				-79.65337,
				44.30662
			],
			[
				-79.65352,
				44.30682
			],
			[
				-79.65361,
				44.30741
			],
			[
				-79.65391,
				44.30818
			],
			[
				-79.65388,
				44.30842
			],
			[
				-79.65423,
				44.30948
			],
			[
				-79.65444,
				44.30982
			],
			[
				-79.65444,
				44.31009
			],
			[
				-79.65468,
				44.31067
			],
			[
				-79.65483,
				44.31159
			],
			[
				-79.65509,
				44.31221
			],
			[
				-79.65511,
				44.31269
			],
			[
				-79.65529,
				44.31325
			],
			[
				-79.65526,
				44.31342
			],
			[
				-79.65547,
				44.3137
			],
			[
				-79.65548,
				44.31386
			],
			[
				-79.65537,
				44.31398
			],
			[
				-79.65547,
				44.31406
			],
			[
				-79.65548,
				44.31425
			],
			[
				-79.65567,
				44.31441
			],
			[
				-79.65563,
				44.31447
			],
			[
				-79.65571,
				44.31454
			],
			[
				-79.65565,
				44.31466
			],
			[
				-79.65575,
				44.31468
			],
			[
				-79.6557,
				44.31472
			],
			[
				-79.65579,
				44.31511
			],
			[
				-79.65595,
				44.31526
			],
			[
				-79.65588,
				44.31535
			],
			[
				-79.65606,
				44.31572
			],
			[
				-79.65606,
				44.31607
			],
			[
				-79.65637,
				44.31686
			],
			[
				-79.65643,
				44.3176
			],
			[
				-79.65675,
				44.31868
			],
			[
				-79.65682,
				44.31918
			],
			[
				-79.65706,
				44.3195
			],
			[
				-79.65701,
				44.31994
			],
			[
				-79.65732,
				44.32018
			],
			[
				-79.65738,
				44.32034
			],
			[
				-79.65727,
				44.32043
			],
			[
				-79.65721,
				44.32078
			],
			[
				-79.65726,
				44.32095
			],
			[
				-79.65741,
				44.32109
			],
			[
				-79.65735,
				44.32131
			],
			[
				-79.65742,
				44.3216
			],
			[
				-79.65767,
				44.3222
			],
			[
				-79.65763,
				44.32227
			],
			[
				-79.65792,
				44.32244
			],
			[
				-79.65776,
				44.32246
			],
			[
				-79.6578,
				44.32304
			],
			[
				-79.65761,
				44.32334
			],
			[
				-79.65798,
				44.32375
			],
			[
				-79.65805,
				44.32434
			],
			[
				-79.65797,
				44.32437
			],
			[
				-79.65833,
				44.32475
			],
			[
				-79.65826,
				44.32506
			],
			[
				-79.65809,
				44.32505
			],
			[
				-79.65809,
				44.32516
			],
			[
				-79.65832,
				44.32535
			],
			[
				-79.65866,
				44.32595
			],
			[
				-79.65861,
				44.32634
			],
			[
				-79.65868,
				44.32659
			],
			[
				-79.65876,
				44.3266
			],
			[
				-79.65874,
				44.32692
			],
			[
				-79.65881,
				44.32709
			],
			[
				-79.65874,
				44.3272
			],
			[
				-79.65893,
				44.32747
			],
			[
				-79.65877,
				44.32764
			],
			[
				-79.65919,
				44.32823
			],
			[
				-79.65937,
				44.3283
			],
			[
				-79.65919,
				44.32847
			],
			[
				-79.65905,
				44.32839
			],
			[
				-79.65916,
				44.32847
			],
			[
				-79.65912,
				44.32881
			],
			[
				-79.65936,
				44.32897
			],
			[
				-79.65936,
				44.32941
			],
			[
				-79.65928,
				44.32938
			],
			[
				-79.65967,
				44.32986
			],
			[
				-79.65954,
				44.32994
			],
			[
				-79.65973,
				44.33023
			],
			[
				-79.65982,
				44.33082
			],
			[
				-79.66028,
				44.33192
			],
			[
				-79.6603,
				44.33256
			],
			[
				-79.66056,
				44.33335
			],
			[
				-79.66051,
				44.33361
			],
			[
				-79.6607,
				44.33388
			],
			[
				-79.66078,
				44.33467
			],
			[
				-79.66121,
				44.33582
			],
			[
				-79.66116,
				44.3361
			],
			[
				-79.66137,
				44.3364
			],
			[
				-79.66128,
				44.33644
			],
			[
				-79.66129,
				44.33675
			],
			[
				-79.66146,
				44.33699
			],
			[
				-79.66139,
				44.33714
			],
			[
				-79.66141,
				44.33749
			],
			[
				-79.66167,
				44.33791
			],
			[
				-79.66165,
				44.33826
			],
			[
				-79.66178,
				44.33849
			],
			[
				-79.66169,
				44.33867
			],
			[
				-79.66185,
				44.33905
			],
			[
				-79.66197,
				44.33915
			],
			[
				-79.66208,
				44.33974
			],
			[
				-79.66204,
				44.33994
			],
			[
				-79.66241,
				44.34092
			],
			[
				-79.66236,
				44.34112
			],
			[
				-79.66249,
				44.3414
			],
			[
				-79.66241,
				44.34171
			],
			[
				-79.66248,
				44.34206
			],
			[
				-79.66293,
				44.34235
			],
			[
				-79.66285,
				44.34251
			],
			[
				-79.66297,
				44.34274
			],
			[
				-79.66298,
				44.34296
			],
			[
				-79.66287,
				44.343
			],
			[
				-79.66291,
				44.34352
			],
			[
				-79.6631,
				44.34376
			],
			[
				-79.66326,
				44.34452
			],
			[
				-79.6635,
				44.34494
			],
			[
				-79.6633,
				44.3449
			],
			[
				-79.66334,
				44.3452
			],
			[
				-79.66354,
				44.3456
			],
			[
				-79.66377,
				44.34581
			],
			[
				-79.66369,
				44.34583
			],
			[
				-79.66375,
				44.34593
			],
			[
				-79.66361,
				44.34615
			],
			[
				-79.66373,
				44.34626
			],
			[
				-79.6639,
				44.34691
			],
			[
				-79.66382,
				44.34736
			],
			[
				-79.66389,
				44.34756
			],
			[
				-79.66382,
				44.34853
			],
			[
				-79.66407,
				44.3491
			],
			[
				-79.66436,
				44.35037
			],
			[
				-79.66452,
				44.35058
			],
			[
				-79.66457,
				44.35146
			],
			[
				-79.66475,
				44.35171
			],
			[
				-79.66468,
				44.35184
			],
			[
				-79.66484,
				44.35202
			],
			[
				-79.66478,
				44.35232
			],
			[
				-79.66483,
				44.35249
			],
			[
				-79.66507,
				44.35285
			],
			[
				-79.66496,
				44.35293
			],
			[
				-79.66502,
				44.35304
			],
			[
				-79.66496,
				44.35322
			],
			[
				-79.66522,
				44.35361
			],
			[
				-79.66526,
				44.35383
			],
			[
				-79.6652,
				44.35402
			],
			[
				-79.66549,
				44.35448
			],
			[
				-79.6655,
				44.35466
			],
			[
				-79.66543,
				44.35473
			],
			[
				-79.66557,
				44.35496
			],
			[
				-79.66563,
				44.35546
			],
			[
				-79.66575,
				44.35559
			],
			[
				-79.66577,
				44.35589
			],
			[
				-79.66599,
				44.35643
			],
			[
				-79.66603,
				44.35708
			],
			[
				-79.66617,
				44.35723
			],
			[
				-79.66645,
				44.35805
			],
			[
				-79.66661,
				44.35825
			],
			[
				-79.66657,
				44.35853
			],
			[
				-79.66665,
				44.35876
			],
			[
				-79.66687,
				44.35897
			],
			[
				-79.6669,
				44.35926
			],
			[
				-79.66718,
				44.35963
			],
			[
				-79.66739,
				44.36064
			],
			[
				-79.66758,
				44.36083
			],
			[
				-79.66758,
				44.36115
			],
			[
				-79.66772,
				44.36144
			],
			[
				-79.66771,
				44.3616
			],
			[
				-79.66792,
				44.36186
			],
			[
				-79.66789,
				44.36206
			],
			[
				-79.66802,
				44.3623
			],
			[
				-79.66799,
				44.3634
			],
			[
				-79.6682,
				44.36371
			],
			[
				-79.66878,
				44.36597
			],
			[
				-79.66878,
				44.36626
			],
			[
				-79.66897,
				44.36659
			],
			[
				-79.66915,
				44.36736
			],
			[
				-79.66929,
				44.3676
			],
			[
				-79.66936,
				44.36813
			],
			[
				-79.66957,
				44.36851
			],
			[
				-79.66984,
				44.3694
			],
			[
				-79.67,
				44.37015
			],
			[
				-79.66949,
				44.37019
			],
			[
				-79.66877,
				44.37006
			],
			[
				-79.66859,
				44.3702
			],
			[
				-79.6685,
				44.37067
			],
			[
				-79.66872,
				44.37161
			],
			[
				-79.66893,
				44.3719
			],
			[
				-79.66926,
				44.37286
			],
			[
				-79.66945,
				44.37292
			],
			[
				-79.67234,
				44.37263
			],
			[
				-79.67374,
				44.37279
			],
			[
				-79.67392,
				44.37268
			],
			[
				-79.67492,
				44.37279
			],
			[
				-79.67518,
				44.37275
			],
			[
				-79.67791,
				44.37318
			],
			[
				-79.67816,
				44.37319
			],
			[
				-79.67825,
				44.37308
			],
			[
				-79.67843,
				44.37309
			],
			[
				-79.68041,
				44.37353
			],
			[
				-79.68108,
				44.37357
			],
			[
				-79.6813,
				44.37349
			],
			[
				-79.68319,
				44.37385
			],
			[
				-79.6833,
				44.37376
			],
			[
				-79.68422,
				44.37384
			],
			[
				-79.68526,
				44.37406
			],
			[
				-79.68583,
				44.37405
			],
			[
				-79.68723,
				44.37439
			],
			[
				-79.68847,
				44.3748
			],
			[
				-79.68845,
				44.37491
			],
			[
				-79.6888,
				44.37496
			],
			[
				-79.68919,
				44.37589
			],
			[
				-79.68913,
				44.37595
			],
			[
				-79.68919,
				44.37611
			],
			[
				-79.68908,
				44.37624
			],
			[
				-79.68914,
				44.37691
			],
			[
				-79.68966,
				44.37818
			],
			[
				-79.68969,
				44.37863
			],
			[
				-79.68998,
				44.37941
			],
			[
				-79.6902,
				44.38143
			],
			[
				-79.69042,
				44.38262
			],
			[
				-79.69035,
				44.383
			],
			[
				-79.69034,
				44.38456
			],
			[
				-79.69046,
				44.38545
			],
			[
				-79.69018,
				44.38582
			],
			[
				-79.6901,
				44.38615
			],
			[
				-79.69032,
				44.38643
			],
			[
				-79.69001,
				44.38699
			],
			[
				-79.69002,
				44.38719
			],
			[
				-79.68892,
				44.38785
			],
			[
				-79.68907,
				44.38813
			],
			[
				-79.68951,
				44.38848
			],
			[
				-79.69008,
				44.38914
			],
			[
				-79.69001,
				44.38978
			],
			[
				-79.69014,
				44.39002
			],
			[
				-79.69087,
				44.39012
			],
			[
				-79.69186,
				44.39117
			],
			[
				-79.69205,
				44.39121
			],
			[
				-79.69215,
				44.39114
			],
			[
				-79.69221,
				44.39075
			],
			[
				-79.6928,
				44.39077
			],
			[
				-79.69293,
				44.39063
			],
			[
				-79.69235,
				44.38989
			],
			[
				-79.69255,
				44.38964
			],
			[
				-79.69194,
				44.38934
			],
			[
				-79.69237,
				44.38918
			],
			[
				-79.69233,
				44.38905
			],
			[
				-79.69217,
				44.38898
			],
			[
				-79.69226,
				44.38889
			],
			[
				-79.69231,
				44.38908
			],
			[
				-79.69184,
				44.38929
			],
			[
				-79.69165,
				44.38916
			],
			[
				-79.69118,
				44.38853
			],
			[
				-79.69099,
				44.38854
			],
			[
				-79.69084,
				44.38842
			],
			[
				-79.69101,
				44.38858
			],
			[
				-79.69094,
				44.38866
			],
			[
				-79.69079,
				44.38857
			],
			[
				-79.69088,
				44.38865
			],
			[
				-79.69095,
				44.38866
			],
			[
				-79.69089,
				44.38859
			],
			[
				-79.69098,
				44.38872
			],
			[
				-79.69105,
				44.38868
			],
			[
				-79.69082,
				44.38838
			],
			[
				-79.69078,
				44.38816
			],
			[
				-79.69029,
				44.38772
			],
			[
				-79.69032,
				44.38761
			],
			[
				-79.69008,
				44.38729
			],
			[
				-79.69046,
				44.38638
			],
			[
				-79.6904,
				44.38627
			],
			[
				-79.69015,
				44.3862
			],
			[
				-79.69022,
				44.38613
			],
			[
				-79.69024,
				44.38579
			],
			[
				-79.69031,
				44.38562
			],
			[
				-79.69061,
				44.38538
			],
			[
				-79.69063,
				44.38427
			],
			[
				-79.69027,
				44.38051
			],
			[
				-79.6899,
				44.37887
			],
			[
				-79.68932,
				44.37704
			],
			[
				-79.68932,
				44.37616
			],
			[
				-79.68912,
				44.37554
			],
			[
				-79.68883,
				44.37501
			],
			[
				-79.68866,
				44.37486
			],
			[
				-79.68802,
				44.37477
			],
			[
				-79.68619,
				44.37412
			],
			[
				-79.68403,
				44.37381
			],
			[
				-79.68297,
				44.37402
			],
			[
				-79.68274,
				44.37427
			],
			[
				-79.6815,
				44.37427
			],
			[
				-79.68124,
				44.37403
			],
			[
				-79.68092,
				44.37392
			],
			[
				-79.68096,
				44.37351
			],
			[
				-79.68024,
				44.3733
			],
			[
				-79.67902,
				44.37324
			],
			[
				-79.67235,
				44.3725
			],
			[
				-79.66929,
				44.37279
			],
			[
				-79.669,
				44.37256
			],
			[
				-79.66904,
				44.37248
			],
			[
				-79.66896,
				44.37233
			],
			[
				-79.66902,
				44.37225
			],
			[
				-79.66861,
				44.37066
			],
			[
				-79.66876,
				44.37001
			],
			[
				-79.66789,
				44.36966
			],
			[
				-79.6669,
				44.36911
			],
			[
				-79.66254,
				44.36632
			],
			[
				-79.66083,
				44.36506
			],
			[
				-79.66066,
				44.36478
			],
			[
				-79.66014,
				44.36429
			],
			[
				-79.65947,
				44.36388
			],
			[
				-79.65834,
				44.36289
			],
			[
				-79.65555,
				44.36072
			],
			[
				-79.65395,
				44.35978
			],
			[
				-79.64826,
				44.35697
			],
			[
				-79.64704,
				44.35661
			],
			[
				-79.64106,
				44.35441
			],
			[
				-79.64032,
				44.35421
			],
			[
				-79.63712,
				44.35302
			],
			[
				-79.63469,
				44.35221
			],
			[
				-79.63073,
				44.35073
			],
			[
				-79.62984,
				44.35029
			],
			[
				-79.62877,
				44.34968
			],
			[
				-79.6276,
				44.34867
			],
			[
				-79.62757,
				44.34852
			],
			[
				-79.627,
				44.34799
			],
			[
				-79.62674,
				44.3476
			],
			[
				-79.62562,
				44.34637
			],
			[
				-79.62523,
				44.3458
			],
			[
				-79.62459,
				44.34458
			],
			[
				-79.62424,
				44.34296
			],
			[
				-79.62375,
				44.34123
			],
			[
				-79.62295,
				44.33748
			],
			[
				-79.62265,
				44.33658
			],
			[
				-79.62196,
				44.33381
			],
			[
				-79.62161,
				44.33266
			],
			[
				-79.62135,
				44.33208
			],
			[
				-79.62097,
				44.33024
			],
			[
				-79.62071,
				44.32953
			],
			[
				-79.62057,
				44.32878
			],
			[
				-79.61973,
				44.32586
			],
			[
				-79.6195,
				44.3247
			],
			[
				-79.61925,
				44.32405
			],
			[
				-79.61815,
				44.31964
			],
			[
				-79.61768,
				44.31843
			],
			[
				-79.61667,
				44.3143
			],
			[
				-79.61619,
				44.31279
			],
			[
				-79.6153,
				44.30859
			],
			[
				-79.61465,
				44.30495
			],
			[
				-79.61421,
				44.30325
			],
			[
				-79.61358,
				44.30004
			],
			[
				-79.61339,
				44.29961
			],
			[
				-79.61296,
				44.29761
			],
			[
				-79.61245,
				44.29599
			],
			[
				-79.61081,
				44.28965
			],
			[
				-79.61051,
				44.2888
			],
			[
				-79.61007,
				44.2865
			],
			[
				-79.60917,
				44.28349
			],
			[
				-79.60766,
				44.27748
			],
			[
				-79.60721,
				44.27603
			],
			[
				-79.60709,
				44.2752
			],
			[
				-79.60663,
				44.27322
			],
			[
				-79.60542,
				44.26905
			],
			[
				-79.6044,
				44.26488
			],
			[
				-79.60399,
				44.26352
			],
			[
				-79.60296,
				44.25945
			],
			[
				-79.60288,
				44.25885
			],
			[
				-79.60275,
				44.2586
			],
			[
				-79.60263,
				44.25783
			],
			[
				-79.60137,
				44.25322
			],
			[
				-79.60108,
				44.25178
			],
			[
				-79.60054,
				44.25004
			],
			[
				-79.60025,
				44.24846
			],
			[
				-79.60005,
				44.24796
			],
			[
				-79.59874,
				44.24257
			],
			[
				-79.59853,
				44.24212
			],
			[
				-79.59849,
				44.2417
			],
			[
				-79.59833,
				44.24136
			],
			[
				-79.59821,
				44.24027
			],
			[
				-79.59768,
				44.23868
			],
			[
				-79.59746,
				44.23749
			],
			[
				-79.59682,
				44.2351
			],
			[
				-79.59674,
				44.23448
			],
			[
				-79.59512,
				44.22832
			],
			[
				-79.59491,
				44.22758
			],
			[
				-79.59467,
				44.22708
			],
			[
				-79.59439,
				44.22564
			],
			[
				-79.59391,
				44.22397
			],
			[
				-79.59365,
				44.22271
			],
			[
				-79.59293,
				44.22071
			],
			[
				-79.59138,
				44.21763
			],
			[
				-79.59094,
				44.21703
			],
			[
				-79.5901,
				44.21531
			],
			[
				-79.58966,
				44.21541
			],
			[
				-79.58964,
				44.21506
			],
			[
				-79.59006,
				44.21494
			],
			[
				-79.59011,
				44.21481
			],
			[
				-79.58964,
				44.21411
			],
			[
				-79.58878,
				44.21219
			],
			[
				-79.58831,
				44.2102
			],
			[
				-79.58798,
				44.20921
			],
			[
				-79.58683,
				44.20439
			],
			[
				-79.58558,
				44.1997
			],
			[
				-79.58494,
				44.19668
			],
			[
				-79.58421,
				44.19397
			],
			[
				-79.5837,
				44.1915
			],
			[
				-79.58325,
				44.19001
			],
			[
				-79.58301,
				44.18862
			],
			[
				-79.58256,
				44.1873
			],
			[
				-79.58122,
				44.181
			],
			[
				-79.57963,
				44.17471
			],
			[
				-79.57856,
				44.16954
			],
			[
				-79.57767,
				44.16629
			],
			[
				-79.57552,
				44.15726
			],
			[
				-79.57384,
				44.15081
			],
			[
				-79.57373,
				44.14999
			],
			[
				-79.57326,
				44.14794
			],
			[
				-79.57247,
				44.14513
			],
			[
				-79.57102,
				44.13856
			],
			[
				-79.5683,
				44.12777
			],
			[
				-79.56808,
				44.12678
			],
			[
				-79.56814,
				44.12673
			],
			[
				-79.56787,
				44.12619
			],
			[
				-79.56771,
				44.1256
			],
			[
				-79.56709,
				44.12278
			],
			[
				-79.56684,
				44.12207
			],
			[
				-79.56671,
				44.12121
			],
			[
				-79.56642,
				44.1204
			],
			[
				-79.56619,
				44.11913
			],
			[
				-79.56535,
				44.11647
			],
			[
				-79.56513,
				44.11528
			],
			[
				-79.56483,
				44.11456
			],
			[
				-79.56487,
				44.11433
			],
			[
				-79.56451,
				44.1143
			],
			[
				-79.56034,
				44.1153
			],
			[
				-79.55865,
				44.11563
			],
			[
				-79.55743,
				44.11579
			],
			[
				-79.55687,
				44.11575
			],
			[
				-79.55275,
				44.11489
			],
			[
				-79.55218,
				44.1147
			],
			[
				-79.54919,
				44.11405
			],
			[
				-79.54698,
				44.11344
			],
			[
				-79.5455,
				44.11319
			],
			[
				-79.53694,
				44.11081
			],
			[
				-79.52478,
				44.10758
			],
			[
				-79.52297,
				44.10707
			],
			[
				-79.52153,
				44.10649
			],
			[
				-79.51936,
				44.10531
			],
			[
				-79.51889,
				44.10498
			],
			[
				-79.51791,
				44.10406
			],
			[
				-79.51623,
				44.10203
			],
			[
				-79.50793,
				44.09249
			],
			[
				-79.50652,
				44.09124
			],
			[
				-79.50533,
				44.09047
			],
			[
				-79.50406,
				44.08985
			],
			[
				-79.50291,
				44.08949
			],
			[
				-79.50238,
				44.08924
			],
			[
				-79.49747,
				44.08748
			],
			[
				-79.49622,
				44.08695
			],
			[
				-79.4947,
				44.08644
			],
			[
				-79.49188,
				44.08535
			],
			[
				-79.49082,
				44.08486
			],
			[
				-79.48956,
				44.08405
			],
			[
				-79.48878,
				44.08344
			],
			[
				-79.48734,
				44.08173
			],
			[
				-79.48679,
				44.08067
			],
			[
				-79.48563,
				44.07608
			],
			[
				-79.48503,
				44.07299
			],
			[
				-79.48432,
				44.0706
			],
			[
				-79.48372,
				44.06787
			],
			[
				-79.48263,
				44.06374
			],
			[
				-79.48256,
				44.06316
			],
			[
				-79.48064,
				44.05574
			],
			[
				-79.48056,
				44.05515
			],
			[
				-79.47965,
				44.05159
			],
			[
				-79.47948,
				44.05059
			],
			[
				-79.47914,
				44.04918
			],
			[
				-79.47904,
				44.04901
			],
			[
				-79.47833,
				44.04566
			],
			[
				-79.47804,
				44.04477
			],
			[
				-79.47803,
				44.04444
			],
			[
				-79.47714,
				44.04089
			],
			[
				-79.4762,
				44.03667
			],
			[
				-79.47618,
				44.03628
			],
			[
				-79.4755,
				44.03345
			],
			[
				-79.47552,
				44.03335
			],
			[
				-79.47576,
				44.03328
			],
			[
				-79.47618,
				44.03336
			],
			[
				-79.47734,
				44.03317
			],
			[
				-79.47744,
				44.03307
			],
			[
				-79.47767,
				44.03303
			],
			[
				-79.47771,
				44.03297
			],
			[
				-79.47754,
				44.03265
			],
			[
				-79.47761,
				44.03269
			],
			[
				-79.47748,
				44.0327
			],
			[
				-79.47768,
				44.03283
			],
			[
				-79.47773,
				44.03264
			],
			[
				-79.47754,
				44.0326
			],
			[
				-79.47733,
				44.03271
			],
			[
				-79.47733,
				44.03284
			],
			[
				-79.47748,
				44.03303
			],
			[
				-79.47744,
				44.0331
			],
			[
				-79.47682,
				44.03258
			],
			[
				-79.4758,
				44.03271
			],
			[
				-79.4755,
				44.03272
			],
			[
				-79.47538,
				44.03264
			],
			[
				-79.47442,
				44.02867
			],
			[
				-79.4743,
				44.02842
			],
			[
				-79.4743,
				44.02795
			],
			[
				-79.47409,
				44.02734
			],
			[
				-79.47409,
				44.02707
			],
			[
				-79.47347,
				44.02474
			],
			[
				-79.47331,
				44.02393
			],
			[
				-79.47335,
				44.0237
			],
			[
				-79.47227,
				44.01919
			],
			[
				-79.4722,
				44.01865
			],
			[
				-79.47205,
				44.01834
			],
			[
				-79.47205,
				44.01801
			],
			[
				-79.47146,
				44.01526
			],
			[
				-79.4712,
				44.01467
			],
			[
				-79.47123,
				44.01445
			],
			[
				-79.4711,
				44.0141
			],
			[
				-79.47108,
				44.01375
			],
			[
				-79.47092,
				44.01336
			],
			[
				-79.4707,
				44.01216
			],
			[
				-79.47039,
				44.01118
			],
			[
				-79.47011,
				44.00974
			],
			[
				-79.46949,
				44.00769
			],
			[
				-79.46941,
				44.00698
			],
			[
				-79.46902,
				44.00545
			],
			[
				-79.46894,
				44.00463
			],
			[
				-79.46801,
				44.00128
			],
			[
				-79.46799,
				44.0008
			],
			[
				-79.46775,
				44.00016
			],
			[
				-79.46728,
				43.99802
			],
			[
				-79.46715,
				43.99773
			],
			[
				-79.46691,
				43.99643
			],
			[
				-79.46676,
				43.9961
			],
			[
				-79.46667,
				43.99537
			],
			[
				-79.46657,
				43.99524
			],
			[
				-79.46652,
				43.99481
			],
			[
				-79.46614,
				43.99379
			],
			[
				-79.46611,
				43.99324
			],
			[
				-79.46551,
				43.99054
			],
			[
				-79.4653,
				43.98993
			],
			[
				-79.46485,
				43.98765
			],
			[
				-79.46473,
				43.98746
			],
			[
				-79.46431,
				43.98591
			],
			[
				-79.46383,
				43.98396
			],
			[
				-79.46376,
				43.98336
			],
			[
				-79.46328,
				43.98179
			],
			[
				-79.46321,
				43.98103
			],
			[
				-79.46288,
				43.97956
			],
			[
				-79.46271,
				43.97918
			],
			[
				-79.46261,
				43.97847
			],
			[
				-79.46266,
				43.97816
			],
			[
				-79.4626,
				43.97796
			],
			[
				-79.46251,
				43.97792
			],
			[
				-79.46231,
				43.97705
			],
			[
				-79.46212,
				43.97667
			],
			[
				-79.46199,
				43.97569
			],
			[
				-79.46175,
				43.97506
			],
			[
				-79.46164,
				43.97438
			],
			[
				-79.46153,
				43.97421
			],
			[
				-79.4615,
				43.9738
			],
			[
				-79.46139,
				43.97361
			],
			[
				-79.46139,
				43.97293
			],
			[
				-79.46111,
				43.97235
			],
			[
				-79.46093,
				43.97132
			],
			[
				-79.46079,
				43.97096
			],
			[
				-79.46078,
				43.97051
			],
			[
				-79.46084,
				43.97042
			],
			[
				-79.46042,
				43.96976
			],
			[
				-79.46037,
				43.96913
			],
			[
				-79.45988,
				43.96747
			],
			[
				-79.45991,
				43.96719
			],
			[
				-79.45967,
				43.96638
			],
			[
				-79.45974,
				43.96634
			],
			[
				-79.45973,
				43.96622
			],
			[
				-79.4596,
				43.96603
			],
			[
				-79.45964,
				43.96574
			],
			[
				-79.45938,
				43.96505
			],
			[
				-79.45932,
				43.96449
			],
			[
				-79.4589,
				43.96274
			],
			[
				-79.45858,
				43.9619
			],
			[
				-79.45858,
				43.96146
			],
			[
				-79.45812,
				43.95959
			],
			[
				-79.45806,
				43.95902
			],
			[
				-79.45789,
				43.95858
			],
			[
				-79.45746,
				43.95624
			],
			[
				-79.45693,
				43.95429
			],
			[
				-79.45691,
				43.95368
			],
			[
				-79.45672,
				43.95319
			],
			[
				-79.45659,
				43.95238
			],
			[
				-79.45596,
				43.94995
			],
			[
				-79.45573,
				43.94846
			],
			[
				-79.45552,
				43.94782
			],
			[
				-79.45476,
				43.9443
			],
			[
				-79.45436,
				43.94299
			],
			[
				-79.45355,
				43.93897
			],
			[
				-79.45359,
				43.93748
			],
			[
				-79.45373,
				43.93656
			],
			[
				-79.45366,
				43.93475
			],
			[
				-79.4532,
				43.93302
			],
			[
				-79.45136,
				43.92863
			],
			[
				-79.4512,
				43.92796
			],
			[
				-79.45034,
				43.92547
			],
			[
				-79.44965,
				43.92278
			],
			[
				-79.44943,
				43.92226
			],
			[
				-79.44867,
				43.91891
			],
			[
				-79.44821,
				43.9175
			],
			[
				-79.44791,
				43.91585
			],
			[
				-79.44732,
				43.91382
			],
			[
				-79.44705,
				43.91237
			],
			[
				-79.44676,
				43.91122
			],
			[
				-79.44667,
				43.9111
			],
			[
				-79.44659,
				43.91054
			],
			[
				-79.44614,
				43.90916
			],
			[
				-79.44584,
				43.90756
			],
			[
				-79.44549,
				43.90631
			],
			[
				-79.44533,
				43.90525
			],
			[
				-79.44487,
				43.90384
			],
			[
				-79.44491,
				43.90366
			],
			[
				-79.44479,
				43.90307
			],
			[
				-79.44462,
				43.90258
			],
			[
				-79.44412,
				43.9003
			],
			[
				-79.44394,
				43.89985
			],
			[
				-79.44395,
				43.89934
			],
			[
				-79.44341,
				43.89752
			],
			[
				-79.44311,
				43.89609
			],
			[
				-79.44301,
				43.89594
			],
			[
				-79.44301,
				43.89563
			],
			[
				-79.44228,
				43.89237
			],
			[
				-79.44208,
				43.89176
			],
			[
				-79.44183,
				43.89031
			],
			[
				-79.44158,
				43.88965
			],
			[
				-79.441,
				43.88678
			],
			[
				-79.44041,
				43.88468
			],
			[
				-79.43982,
				43.88172
			],
			[
				-79.43891,
				43.87819
			],
			[
				-79.43883,
				43.87758
			],
			[
				-79.43849,
				43.87655
			],
			[
				-79.43851,
				43.87626
			],
			[
				-79.43838,
				43.87604
			],
			[
				-79.43836,
				43.87565
			],
			[
				-79.43716,
				43.87082
			],
			[
				-79.43692,
				43.86936
			],
			[
				-79.43632,
				43.86702
			],
			[
				-79.43629,
				43.86683
			],
			[
				-79.43637,
				43.86678
			],
			[
				-79.43629,
				43.86639
			],
			[
				-79.43602,
				43.8658
			],
			[
				-79.43579,
				43.86447
			],
			[
				-79.43541,
				43.86323
			],
			[
				-79.43506,
				43.86098
			],
			[
				-79.43462,
				43.85953
			],
			[
				-79.43435,
				43.85807
			],
			[
				-79.43405,
				43.85711
			],
			[
				-79.43391,
				43.85617
			],
			[
				-79.43382,
				43.85608
			],
			[
				-79.43364,
				43.85549
			],
			[
				-79.43314,
				43.85314
			],
			[
				-79.43274,
				43.85176
			],
			[
				-79.43266,
				43.85101
			],
			[
				-79.43215,
				43.84911
			],
			[
				-79.43213,
				43.84879
			],
			[
				-79.43093,
				43.84375
			],
			[
				-79.4307,
				43.84312
			],
			[
				-79.43071,
				43.84267
			],
			[
				-79.43027,
				43.8413
			],
			[
				-79.4302,
				43.84126
			],
			[
				-79.43006,
				43.84018
			],
			[
				-79.42954,
				43.83768
			],
			[
				-79.42947,
				43.83682
			],
			[
				-79.42913,
				43.83574
			],
			[
				-79.42917,
				43.83544
			],
			[
				-79.42898,
				43.83507
			],
			[
				-79.42863,
				43.83364
			],
			[
				-79.42871,
				43.83344
			],
			[
				-79.42863,
				43.83293
			],
			[
				-79.42848,
				43.83274
			],
			[
				-79.42827,
				43.83194
			],
			[
				-79.42817,
				43.83181
			],
			[
				-79.42813,
				43.83126
			],
			[
				-79.42785,
				43.83064
			],
			[
				-79.42778,
				43.83032
			],
			[
				-79.4279,
				43.83024
			],
			[
				-79.42761,
				43.82923
			],
			[
				-79.42738,
				43.82802
			],
			[
				-79.42716,
				43.82742
			],
			[
				-79.42716,
				43.82708
			],
			[
				-79.42695,
				43.82648
			],
			[
				-79.42686,
				43.82584
			],
			[
				-79.42671,
				43.82555
			],
			[
				-79.42669,
				43.82511
			],
			[
				-79.4258,
				43.82131
			],
			[
				-79.42563,
				43.821
			],
			[
				-79.42481,
				43.81704
			],
			[
				-79.42443,
				43.81579
			],
			[
				-79.42442,
				43.81539
			],
			[
				-79.42422,
				43.81481
			],
			[
				-79.42417,
				43.81425
			],
			[
				-79.42289,
				43.80854
			],
			[
				-79.42156,
				43.80317
			],
			[
				-79.42134,
				43.80279
			],
			[
				-79.42123,
				43.80178
			],
			[
				-79.42107,
				43.80116
			],
			[
				-79.42097,
				43.80104
			],
			[
				-79.42097,
				43.80067
			],
			[
				-79.42008,
				43.79757
			],
			[
				-79.41937,
				43.79466
			],
			[
				-79.41926,
				43.79445
			],
			[
				-79.4191,
				43.79356
			],
			[
				-79.41732,
				43.78634
			],
			[
				-79.41655,
				43.78352
			],
			[
				-79.41639,
				43.78269
			],
			[
				-79.41665,
				43.7825
			],
			[
				-79.41655,
				43.78244
			],
			[
				-79.41659,
				43.78237
			],
			[
				-79.41618,
				43.78177
			],
			[
				-79.4156,
				43.77936
			],
			[
				-79.41547,
				43.77907
			],
			[
				-79.41536,
				43.77841
			],
			[
				-79.41558,
				43.77847
			],
			[
				-79.41549,
				43.77841
			],
			[
				-79.41559,
				43.77802
			],
			[
				-79.41555,
				43.77768
			],
			[
				-79.41497,
				43.7762
			],
			[
				-79.41465,
				43.77621
			],
			[
				-79.41391,
				43.77325
			],
			[
				-79.41402,
				43.77315
			],
			[
				-79.41317,
				43.76982
			],
			[
				-79.4132,
				43.76988
			],
			[
				-79.4134,
				43.76978
			],
			[
				-79.41337,
				43.76967
			],
			[
				-79.4133,
				43.76975
			],
			[
				-79.41335,
				43.7698
			],
			[
				-79.41354,
				43.76976
			],
			[
				-79.41373,
				43.7696
			],
			[
				-79.41365,
				43.76931
			],
			[
				-79.41368,
				43.76873
			],
			[
				-79.41347,
				43.76842
			],
			[
				-79.41349,
				43.76814
			],
			[
				-79.41336,
				43.76806
			],
			[
				-79.41296,
				43.76804
			],
			[
				-79.4129,
				43.76781
			],
			[
				-79.41256,
				43.76769
			],
			[
				-79.41194,
				43.76523
			],
			[
				-79.41264,
				43.76469
			],
			[
				-79.4125,
				43.76456
			],
			[
				-79.41246,
				43.76462
			],
			[
				-79.41249,
				43.76428
			],
			[
				-79.41235,
				43.76388
			],
			[
				-79.41181,
				43.7639
			],
			[
				-79.41158,
				43.76374
			],
			[
				-79.41152,
				43.76352
			],
			[
				-79.41187,
				43.76339
			],
			[
				-79.41171,
				43.76297
			],
			[
				-79.41138,
				43.76294
			],
			[
				-79.41065,
				43.75999
			],
			[
				-79.41121,
				43.75984
			],
			[
				-79.41096,
				43.75921
			],
			[
				-79.41087,
				43.75918
			],
			[
				-79.41089,
				43.7591
			],
			[
				-79.41052,
				43.75847
			],
			[
				-79.41028,
				43.75831
			],
			[
				-79.40977,
				43.7564
			],
			[
				-79.40982,
				43.75599
			],
			[
				-79.40946,
				43.75542
			],
			[
				-79.40928,
				43.75492
			],
			[
				-79.40908,
				43.75475
			],
			[
				-79.409,
				43.75452
			],
			[
				-79.40865,
				43.75406
			],
			[
				-79.40818,
				43.75286
			],
			[
				-79.40829,
				43.75264
			],
			[
				-79.40816,
				43.75184
			],
			[
				-79.40823,
				43.7503
			],
			[
				-79.4081,
				43.74939
			],
			[
				-79.40742,
				43.74669
			],
			[
				-79.40709,
				43.74486
			],
			[
				-79.4069,
				43.74427
			],
			[
				-79.40682,
				43.74426
			],
			[
				-79.4069,
				43.74427
			],
			[
				-79.40628,
				43.74122
			],
			[
				-79.40551,
				43.7379
			],
			[
				-79.40525,
				43.7371
			],
			[
				-79.40526,
				43.73679
			],
			[
				-79.40441,
				43.733
			],
			[
				-79.40301,
				43.72761
			],
			[
				-79.40293,
				43.7277
			],
			[
				-79.40301,
				43.7276
			],
			[
				-79.4024,
				43.72531
			],
			[
				-79.40253,
				43.72512
			],
			[
				-79.40256,
				43.72519
			],
			[
				-79.40229,
				43.72487
			],
			[
				-79.40213,
				43.72437
			],
			[
				-79.40213,
				43.72406
			],
			[
				-79.40079,
				43.7173
			],
			[
				-79.40077,
				43.71737
			],
			[
				-79.4009,
				43.71725
			],
			[
				-79.40062,
				43.71718
			],
			[
				-79.40055,
				43.71684
			],
			[
				-79.40063,
				43.7166
			],
			[
				-79.4001,
				43.71435
			],
			[
				-79.39916,
				43.70957
			],
			[
				-79.3987,
				43.7076
			],
			[
				-79.39855,
				43.70725
			],
			[
				-79.39861,
				43.70719
			],
			[
				-79.39829,
				43.70569
			],
			[
				-79.39807,
				43.70529
			],
			[
				-79.39854,
				43.70492
			],
			[
				-79.39825,
				43.70446
			],
			[
				-79.39784,
				43.70419
			],
			[
				-79.39751,
				43.70255
			],
			[
				-79.39757,
				43.70254
			],
			[
				-79.39725,
				43.70051
			],
			[
				-79.39686,
				43.69869
			],
			[
				-79.39656,
				43.6979
			],
			[
				-79.3966,
				43.69735
			],
			[
				-79.39611,
				43.69542
			],
			[
				-79.39533,
				43.6914
			],
			[
				-79.39517,
				43.69095
			],
			[
				-79.39521,
				43.69082
			],
			[
				-79.39508,
				43.69022
			],
			[
				-79.39528,
				43.69013
			],
			[
				-79.40231,
				43.68871
			],
			[
				-79.40242,
				43.68892
			],
			[
				-79.40245,
				43.68868
			],
			[
				-79.41501,
				43.68619
			],
			[
				-79.4174,
				43.68575
			],
			[
				-79.41753,
				43.68597
			],
			[
				-79.41781,
				43.68567
			],
			[
				-79.41922,
				43.68539
			],
			[
				-79.41902,
				43.68462
			],
			[
				-79.41851,
				43.68334
			],
			[
				-79.41854,
				43.68339
			],
			[
				-79.41834,
				43.68342
			],
			[
				-79.41848,
				43.68325
			],
			[
				-79.41724,
				43.68006
			],
			[
				-79.41694,
				43.67947
			],
			[
				-79.4166,
				43.67804
			],
			[
				-79.41622,
				43.67743
			],
			[
				-79.41593,
				43.67672
			],
			[
				-79.41595,
				43.6768
			],
			[
				-79.41639,
				43.67672
			],
			[
				-79.41721,
				43.6768
			],
			[
				-79.41933,
				43.67666
			],
			[
				-79.42248,
				43.67577
			],
			[
				-79.42482,
				43.67522
			],
			[
				-79.4263,
				43.67496
			],
			[
				-79.42741,
				43.67483
			],
			[
				-79.42974,
				43.67482
			],
			[
				-79.43109,
				43.67468
			],
			[
				-79.43021,
				43.67252
			],
			[
				-79.42944,
				43.67093
			],
			[
				-79.42918,
				43.67006
			],
			[
				-79.42819,
				43.66785
			],
			[
				-79.42624,
				43.66299
			],
			[
				-79.4256,
				43.66164
			],
			[
				-79.42285,
				43.65454
			]
		],
		"dupe": false
	},
	{
		"id": 343144602,
		"name": "Lunch Ride",
		"distance": 3952.3,
		"moving_time": 775,
		"elapsed_time": 794,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-10T12:58:25Z",
		"average_speed": 5.1,
		"max_speed": 20.2,
		"calories": 85.9,
		"points": [
			[
				-79.39236,
				43.64602
			],
			[
				-79.39298,
				43.64585
			],
			[
				-79.39259,
				43.64586
			],
			[
				-79.39248,
				43.64579
			],
			[
				-79.39221,
				43.64586
			],
			[
				-79.39423,
				43.64581
			],
			[
				-79.39409,
				43.64594
			],
			[
				-79.39499,
				43.64552
			],
			[
				-79.39593,
				43.64536
			],
			[
				-79.39561,
				43.64546
			],
			[
				-79.39524,
				43.64543
			],
			[
				-79.39538,
				43.64539
			],
			[
				-79.39529,
				43.64538
			],
			[
				-79.39507,
				43.64546
			],
			[
				-79.39764,
				43.645
			],
			[
				-79.39774,
				43.64528
			],
			[
				-79.39785,
				43.64531
			],
			[
				-79.39865,
				43.64745
			],
			[
				-79.40375,
				43.64646
			],
			[
				-79.40448,
				43.64669
			],
			[
				-79.40966,
				43.64566
			],
			[
				-79.40967,
				43.64553
			],
			[
				-79.4099,
				43.64567
			],
			[
				-79.41034,
				43.64562
			],
			[
				-79.41039,
				43.64552
			],
			[
				-79.41108,
				43.64539
			],
			[
				-79.41125,
				43.64563
			],
			[
				-79.41106,
				43.64568
			],
			[
				-79.41111,
				43.64567
			],
			[
				-79.41109,
				43.64577
			],
			[
				-79.41169,
				43.64575
			],
			[
				-79.4126,
				43.6455
			],
			[
				-79.41924,
				43.6442
			],
			[
				-79.42102,
				43.64377
			],
			[
				-79.422,
				43.64365
			],
			[
				-79.42233,
				43.64353
			],
			[
				-79.42707,
				43.6426
			],
			[
				-79.4272,
				43.64267
			],
			[
				-79.42768,
				43.64392
			],
			[
				-79.42779,
				43.64404
			],
			[
				-79.42914,
				43.64383
			],
			[
				-79.42923,
				43.64404
			],
			[
				-79.42934,
				43.6441
			]
		],
		"dupe": false
	},
	{
		"id": 344808997,
		"name": "Ride to Cherry Beach",
		"distance": 9649.9,
		"moving_time": 1552,
		"elapsed_time": 2034,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-12T16:04:51Z",
		"average_speed": 6.218,
		"max_speed": 18.8,
		"calories": 174.1,
		"points": [
			[
				-79.42295,
				43.65342
			],
			[
				-79.42302,
				43.65335
			],
			[
				-79.42265,
				43.65349
			],
			[
				-79.42241,
				43.65344
			],
			[
				-79.42234,
				43.65333
			],
			[
				-79.42211,
				43.65264
			],
			[
				-79.42194,
				43.65237
			],
			[
				-79.42162,
				43.65133
			],
			[
				-79.42137,
				43.65089
			],
			[
				-79.42139,
				43.65096
			],
			[
				-79.42114,
				43.65016
			],
			[
				-79.42099,
				43.64994
			],
			[
				-79.42091,
				43.64948
			],
			[
				-79.42074,
				43.64923
			],
			[
				-79.41355,
				43.65071
			],
			[
				-79.41153,
				43.64572
			],
			[
				-79.41099,
				43.64569
			],
			[
				-79.40996,
				43.64593
			],
			[
				-79.40961,
				43.64529
			],
			[
				-79.40378,
				43.64646
			],
			[
				-79.40349,
				43.6458
			],
			[
				-79.4035,
				43.64548
			],
			[
				-79.40326,
				43.64519
			],
			[
				-79.39568,
				43.64668
			],
			[
				-79.3922,
				43.64744
			],
			[
				-79.39211,
				43.64727
			],
			[
				-79.39178,
				43.64752
			],
			[
				-79.39047,
				43.64778
			],
			[
				-79.39036,
				43.64812
			],
			[
				-79.38934,
				43.64823
			],
			[
				-79.38904,
				43.64809
			],
			[
				-79.38541,
				43.64889
			],
			[
				-79.38524,
				43.6487
			],
			[
				-79.38498,
				43.64893
			],
			[
				-79.3845,
				43.64871
			],
			[
				-79.38359,
				43.64901
			],
			[
				-79.38335,
				43.64932
			],
			[
				-79.38081,
				43.64986
			],
			[
				-79.37895,
				43.64559
			],
			[
				-79.37861,
				43.64418
			],
			[
				-79.37832,
				43.64371
			],
			[
				-79.37798,
				43.64287
			],
			[
				-79.37801,
				43.64279
			],
			[
				-79.37772,
				43.64242
			],
			[
				-79.37776,
				43.6423
			],
			[
				-79.37746,
				43.64237
			],
			[
				-79.37748,
				43.64188
			],
			[
				-79.37737,
				43.64168
			],
			[
				-79.37719,
				43.64169
			],
			[
				-79.37699,
				43.64138
			],
			[
				-79.37707,
				43.64123
			],
			[
				-79.37691,
				43.64126
			],
			[
				-79.3768,
				43.64113
			],
			[
				-79.3731,
				43.64246
			],
			[
				-79.37321,
				43.64282
			],
			[
				-79.37299,
				43.64267
			],
			[
				-79.37306,
				43.64253
			],
			[
				-79.37203,
				43.64319
			],
			[
				-79.37209,
				43.6432
			],
			[
				-79.37217,
				43.64316
			],
			[
				-79.37215,
				43.64309
			],
			[
				-79.37194,
				43.64309
			],
			[
				-79.37186,
				43.64327
			],
			[
				-79.37197,
				43.64353
			],
			[
				-79.37181,
				43.64361
			],
			[
				-79.37166,
				43.64357
			],
			[
				-79.37145,
				43.64319
			],
			[
				-79.37104,
				43.64314
			],
			[
				-79.36213,
				43.64633
			],
			[
				-79.36177,
				43.64657
			],
			[
				-79.36122,
				43.64773
			],
			[
				-79.36102,
				43.64787
			],
			[
				-79.35781,
				43.64892
			],
			[
				-79.3571,
				43.64905
			],
			[
				-79.35646,
				43.64906
			],
			[
				-79.35584,
				43.64875
			],
			[
				-79.35574,
				43.64848
			],
			[
				-79.35555,
				43.64833
			],
			[
				-79.35537,
				43.64837
			],
			[
				-79.35436,
				43.64771
			],
			[
				-79.35256,
				43.64545
			],
			[
				-79.35236,
				43.64532
			],
			[
				-79.3522,
				43.6454
			],
			[
				-79.35232,
				43.6454
			],
			[
				-79.35215,
				43.64516
			],
			[
				-79.35222,
				43.64503
			],
			[
				-79.34964,
				43.64188
			],
			[
				-79.34472,
				43.6372
			],
			[
				-79.34432,
				43.63737
			],
			[
				-79.34397,
				43.63747
			],
			[
				-79.34396,
				43.63742
			]
		],
		"dupe": false
	},
	{
		"id": 344879057,
		"name": "Cherry Beach to Burger's Priest",
		"distance": 5322.8,
		"moving_time": 750,
		"elapsed_time": 787,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-12T18:50:53Z",
		"average_speed": 7.097,
		"max_speed": 13.2,
		"calories": 103.1,
		"points": [
			[
				-79.34417,
				43.63698
			],
			[
				-79.34431,
				43.63697
			],
			[
				-79.34434,
				43.63722
			],
			[
				-79.34386,
				43.63755
			],
			[
				-79.3433,
				43.6377
			],
			[
				-79.34262,
				43.63853
			],
			[
				-79.34236,
				43.63903
			],
			[
				-79.34207,
				43.63936
			],
			[
				-79.34186,
				43.63946
			],
			[
				-79.3413,
				43.63944
			],
			[
				-79.34098,
				43.63952
			],
			[
				-79.34071,
				43.63979
			],
			[
				-79.33978,
				43.63998
			],
			[
				-79.33918,
				43.6403
			],
			[
				-79.33819,
				43.6411
			],
			[
				-79.33793,
				43.64141
			],
			[
				-79.33754,
				43.64167
			],
			[
				-79.33729,
				43.64199
			],
			[
				-79.33656,
				43.64244
			],
			[
				-79.33646,
				43.6427
			],
			[
				-79.33607,
				43.64313
			],
			[
				-79.33555,
				43.6432
			],
			[
				-79.33535,
				43.64309
			],
			[
				-79.33472,
				43.64306
			],
			[
				-79.33423,
				43.64339
			],
			[
				-79.33383,
				43.64393
			],
			[
				-79.33394,
				43.64457
			],
			[
				-79.33374,
				43.64512
			],
			[
				-79.33362,
				43.64524
			],
			[
				-79.33366,
				43.64538
			],
			[
				-79.32918,
				43.6479
			],
			[
				-79.32737,
				43.64864
			],
			[
				-79.32728,
				43.64881
			],
			[
				-79.32804,
				43.64986
			],
			[
				-79.32811,
				43.65009
			],
			[
				-79.32292,
				43.65237
			],
			[
				-79.32763,
				43.65813
			],
			[
				-79.32805,
				43.65875
			],
			[
				-79.32737,
				43.65914
			],
			[
				-79.32708,
				43.65915
			],
			[
				-79.32388,
				43.65992
			],
			[
				-79.32155,
				43.66062
			],
			[
				-79.32104,
				43.66066
			],
			[
				-79.3183,
				43.6614
			],
			[
				-79.31459,
				43.66221
			],
			[
				-79.31517,
				43.66311
			],
			[
				-79.31609,
				43.6654
			],
			[
				-79.31634,
				43.66578
			],
			[
				-79.31647,
				43.66629
			],
			[
				-79.31648,
				43.66622
			],
			[
				-79.31621,
				43.66637
			],
			[
				-79.31552,
				43.66648
			]
		],
		"dupe": false
	},
	{
		"id": 344909115,
		"name": "Evening Ride",
		"distance": 10042.5,
		"moving_time": 1731,
		"elapsed_time": 1781,
		"total_elevation_gain": 41.4,
		"start_date_local": "2015-07-12T20:01:10Z",
		"average_speed": 5.802,
		"max_speed": 13.3,
		"calories": 206.2,
		"points": [
			[
				-79.31629,
				43.66643
			],
			[
				-79.3162,
				43.66637
			],
			[
				-79.31655,
				43.66638
			],
			[
				-79.31665,
				43.66647
			],
			[
				-79.31688,
				43.66718
			],
			[
				-79.31698,
				43.66726
			],
			[
				-79.31712,
				43.66798
			],
			[
				-79.31743,
				43.6687
			],
			[
				-79.3176,
				43.66892
			],
			[
				-79.31912,
				43.67272
			],
			[
				-79.31937,
				43.67295
			],
			[
				-79.32019,
				43.67271
			],
			[
				-79.3225,
				43.67225
			],
			[
				-79.32344,
				43.67197
			],
			[
				-79.33263,
				43.66999
			],
			[
				-79.33482,
				43.66943
			],
			[
				-79.33546,
				43.66936
			],
			[
				-79.33734,
				43.66886
			],
			[
				-79.34298,
				43.66771
			],
			[
				-79.34382,
				43.66744
			],
			[
				-79.34772,
				43.66661
			],
			[
				-79.35069,
				43.66587
			],
			[
				-79.35242,
				43.66557
			],
			[
				-79.35289,
				43.66558
			],
			[
				-79.36079,
				43.66332
			],
			[
				-79.36502,
				43.66246
			],
			[
				-79.3663,
				43.66208
			],
			[
				-79.36677,
				43.66206
			],
			[
				-79.36728,
				43.66215
			],
			[
				-79.36738,
				43.66206
			],
			[
				-79.36834,
				43.66218
			],
			[
				-79.37009,
				43.66174
			],
			[
				-79.37213,
				43.66136
			],
			[
				-79.37202,
				43.66132
			],
			[
				-79.37234,
				43.66145
			],
			[
				-79.37301,
				43.66307
			],
			[
				-79.37318,
				43.66315
			],
			[
				-79.37735,
				43.66229
			],
			[
				-79.3777,
				43.66214
			],
			[
				-79.37892,
				43.662
			],
			[
				-79.37982,
				43.66176
			],
			[
				-79.38077,
				43.66164
			],
			[
				-79.38191,
				43.66138
			],
			[
				-79.38308,
				43.6614
			],
			[
				-79.38527,
				43.66099
			],
			[
				-79.38555,
				43.66098
			],
			[
				-79.38576,
				43.66113
			],
			[
				-79.38617,
				43.66079
			],
			[
				-79.38963,
				43.66008
			],
			[
				-79.39029,
				43.66001
			],
			[
				-79.39517,
				43.65892
			],
			[
				-79.3981,
				43.65839
			],
			[
				-79.3981,
				43.65832
			],
			[
				-79.39801,
				43.65831
			],
			[
				-79.39813,
				43.65839
			],
			[
				-79.39964,
				43.65805
			],
			[
				-79.40033,
				43.65805
			],
			[
				-79.40445,
				43.65715
			],
			[
				-79.40555,
				43.657
			],
			[
				-79.40621,
				43.6568
			],
			[
				-79.40834,
				43.65642
			],
			[
				-79.40897,
				43.65617
			],
			[
				-79.40996,
				43.65611
			],
			[
				-79.41189,
				43.65563
			],
			[
				-79.41244,
				43.65558
			],
			[
				-79.4129,
				43.65541
			],
			[
				-79.41468,
				43.65508
			],
			[
				-79.41552,
				43.65504
			],
			[
				-79.41792,
				43.65531
			],
			[
				-79.4191,
				43.6552
			],
			[
				-79.42262,
				43.6544
			],
			[
				-79.42272,
				43.65412
			],
			[
				-79.42247,
				43.65355
			],
			[
				-79.42341,
				43.65329
			]
		],
		"dupe": false
	},
	{
		"id": 346912037,
		"name": "Evening Ride",
		"distance": 2347.7,
		"moving_time": 382,
		"elapsed_time": 396,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-15T18:07:04Z",
		"average_speed": 6.146,
		"max_speed": 12.3,
		"calories": 42.9,
		"points": [
			[
				-79.42958,
				43.64427
			],
			[
				-79.42923,
				43.64386
			],
			[
				-79.42909,
				43.64383
			],
			[
				-79.42796,
				43.64404
			],
			[
				-79.42772,
				43.644
			],
			[
				-79.42728,
				43.64287
			],
			[
				-79.42619,
				43.64309
			],
			[
				-79.42602,
				43.6428
			],
			[
				-79.42518,
				43.64285
			],
			[
				-79.42187,
				43.64359
			],
			[
				-79.41959,
				43.64405
			],
			[
				-79.41895,
				43.64406
			],
			[
				-79.41665,
				43.64463
			],
			[
				-79.4105,
				43.6458
			],
			[
				-79.40779,
				43.64643
			],
			[
				-79.40683,
				43.64657
			],
			[
				-79.40632,
				43.64675
			],
			[
				-79.40354,
				43.64725
			],
			[
				-79.40359,
				43.64724
			]
		],
		"dupe": false
	},
	{
		"id": 347021425,
		"name": "Night Ride",
		"distance": 2417.8,
		"moving_time": 346,
		"elapsed_time": 346,
		"total_elevation_gain": 12.4,
		"start_date_local": "2015-07-15T22:22:53Z",
		"average_speed": 6.988,
		"max_speed": 13.9,
		"calories": 51.4,
		"points": [
			[
				-79.40441,
				43.64726
			],
			[
				-79.40437,
				43.64723
			],
			[
				-79.40511,
				43.64702
			],
			[
				-79.40902,
				43.64619
			],
			[
				-79.41504,
				43.64503
			],
			[
				-79.41493,
				43.64504
			],
			[
				-79.41517,
				43.64514
			],
			[
				-79.41709,
				43.65005
			],
			[
				-79.4173,
				43.65008
			],
			[
				-79.41829,
				43.64989
			],
			[
				-79.41818,
				43.64988
			],
			[
				-79.41828,
				43.64981
			],
			[
				-79.41844,
				43.65022
			],
			[
				-79.41843,
				43.65055
			],
			[
				-79.41874,
				43.65104
			],
			[
				-79.41864,
				43.6511
			],
			[
				-79.41887,
				43.6514
			],
			[
				-79.41952,
				43.65135
			],
			[
				-79.42129,
				43.65097
			],
			[
				-79.42152,
				43.65142
			],
			[
				-79.42162,
				43.65184
			],
			[
				-79.42183,
				43.65214
			],
			[
				-79.42205,
				43.65296
			],
			[
				-79.42228,
				43.6534
			],
			[
				-79.42236,
				43.65348
			],
			[
				-79.42345,
				43.65328
			]
		],
		"dupe": false
	},
	{
		"id": 347718865,
		"name": "Afternoon Ride",
		"distance": 9862,
		"moving_time": 1456,
		"elapsed_time": 1490,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-16T17:53:19Z",
		"average_speed": 6.773,
		"max_speed": 11.6,
		"calories": 194.1,
		"points": [
			[
				-79.42944,
				43.64442
			],
			[
				-79.42963,
				43.64438
			],
			[
				-79.42978,
				43.64476
			],
			[
				-79.42984,
				43.64501
			],
			[
				-79.42978,
				43.64535
			],
			[
				-79.43137,
				43.64961
			],
			[
				-79.43138,
				43.64952
			],
			[
				-79.43117,
				43.64963
			],
			[
				-79.43014,
				43.6496
			],
			[
				-79.42998,
				43.64953
			],
			[
				-79.42934,
				43.64961
			],
			[
				-79.4268,
				43.64944
			],
			[
				-79.42648,
				43.64949
			],
			[
				-79.42442,
				43.64944
			],
			[
				-79.42384,
				43.64937
			],
			[
				-79.42118,
				43.64934
			],
			[
				-79.42118,
				43.64928
			],
			[
				-79.42106,
				43.64928
			],
			[
				-79.41989,
				43.64947
			],
			[
				-79.41836,
				43.64984
			],
			[
				-79.41641,
				43.65013
			],
			[
				-79.41604,
				43.65027
			],
			[
				-79.41537,
				43.65036
			],
			[
				-79.41497,
				43.65051
			],
			[
				-79.4137,
				43.65077
			],
			[
				-79.40997,
				43.65141
			],
			[
				-79.40821,
				43.65186
			],
			[
				-79.40554,
				43.65237
			],
			[
				-79.40445,
				43.65201
			],
			[
				-79.40411,
				43.65182
			],
			[
				-79.40355,
				43.65173
			],
			[
				-79.4009,
				43.65234
			],
			[
				-79.39841,
				43.65281
			],
			[
				-79.39771,
				43.65302
			],
			[
				-79.3951,
				43.65349
			],
			[
				-79.39408,
				43.65378
			],
			[
				-79.3939,
				43.65375
			],
			[
				-79.39303,
				43.65396
			],
			[
				-79.3916,
				43.65417
			],
			[
				-79.39157,
				43.65423
			],
			[
				-79.38903,
				43.65473
			],
			[
				-79.38799,
				43.65476
			],
			[
				-79.38685,
				43.65498
			],
			[
				-79.38587,
				43.65524
			],
			[
				-79.38349,
				43.65569
			],
			[
				-79.38266,
				43.65594
			],
			[
				-79.38092,
				43.65625
			],
			[
				-79.38044,
				43.65644
			],
			[
				-79.37888,
				43.65616
			],
			[
				-79.37581,
				43.65671
			],
			[
				-79.37401,
				43.65718
			],
			[
				-79.37352,
				43.65721
			],
			[
				-79.37319,
				43.65748
			],
			[
				-79.37282,
				43.65794
			],
			[
				-79.37206,
				43.65836
			],
			[
				-79.37115,
				43.65828
			],
			[
				-79.36853,
				43.6589
			],
			[
				-79.36605,
				43.65938
			],
			[
				-79.36377,
				43.65997
			],
			[
				-79.36283,
				43.66003
			],
			[
				-79.36173,
				43.66027
			],
			[
				-79.36125,
				43.66046
			],
			[
				-79.36069,
				43.66053
			],
			[
				-79.35673,
				43.66152
			],
			[
				-79.3538,
				43.66187
			],
			[
				-79.35224,
				43.66214
			],
			[
				-79.35142,
				43.66244
			],
			[
				-79.34825,
				43.66312
			],
			[
				-79.3483,
				43.66313
			],
			[
				-79.3474,
				43.6634
			],
			[
				-79.34557,
				43.6637
			],
			[
				-79.34396,
				43.66383
			],
			[
				-79.34284,
				43.66417
			],
			[
				-79.34129,
				43.66492
			],
			[
				-79.33754,
				43.66582
			],
			[
				-79.33441,
				43.6661
			],
			[
				-79.33387,
				43.6663
			],
			[
				-79.33113,
				43.66679
			],
			[
				-79.32838,
				43.66759
			],
			[
				-79.32725,
				43.66754
			],
			[
				-79.32571,
				43.6677
			],
			[
				-79.32459,
				43.66768
			],
			[
				-79.32432,
				43.66728
			],
			[
				-79.32411,
				43.66732
			]
		],
		"dupe": false
	},
	{
		"id": 347802465,
		"name": "Night Ride",
		"distance": 9089,
		"moving_time": 1379,
		"elapsed_time": 1404,
		"total_elevation_gain": 34.5,
		"start_date_local": "2015-07-17T00:21:20Z",
		"average_speed": 6.591,
		"max_speed": 11.1,
		"calories": 192.1,
		"points": [
			[
				-79.32417,
				43.66744
			],
			[
				-79.32419,
				43.66752
			],
			[
				-79.32426,
				43.66738
			],
			[
				-79.3244,
				43.66767
			],
			[
				-79.32495,
				43.66772
			],
			[
				-79.32785,
				43.66762
			],
			[
				-79.33051,
				43.66707
			],
			[
				-79.33314,
				43.6664
			],
			[
				-79.33426,
				43.66625
			],
			[
				-79.33417,
				43.66624
			],
			[
				-79.33439,
				43.66635
			],
			[
				-79.33482,
				43.66751
			],
			[
				-79.33534,
				43.66847
			],
			[
				-79.33548,
				43.66894
			],
			[
				-79.33558,
				43.66913
			],
			[
				-79.3357,
				43.66918
			],
			[
				-79.34083,
				43.66816
			],
			[
				-79.34181,
				43.66781
			],
			[
				-79.34258,
				43.66776
			],
			[
				-79.34253,
				43.66768
			],
			[
				-79.34266,
				43.66771
			],
			[
				-79.34417,
				43.66728
			],
			[
				-79.34633,
				43.6669
			],
			[
				-79.34982,
				43.66607
			],
			[
				-79.35244,
				43.66552
			],
			[
				-79.35357,
				43.66538
			],
			[
				-79.35943,
				43.66362
			],
			[
				-79.36273,
				43.66292
			],
			[
				-79.36377,
				43.66263
			],
			[
				-79.36542,
				43.66238
			],
			[
				-79.36667,
				43.66203
			],
			[
				-79.36696,
				43.66202
			],
			[
				-79.36708,
				43.66209
			],
			[
				-79.36729,
				43.66275
			],
			[
				-79.36794,
				43.66411
			],
			[
				-79.36822,
				43.6642
			],
			[
				-79.36858,
				43.66418
			],
			[
				-79.36995,
				43.66385
			],
			[
				-79.37047,
				43.66383
			],
			[
				-79.37198,
				43.66338
			],
			[
				-79.37316,
				43.66325
			],
			[
				-79.37481,
				43.66284
			],
			[
				-79.37667,
				43.66249
			],
			[
				-79.37726,
				43.66228
			],
			[
				-79.37755,
				43.66205
			],
			[
				-79.37889,
				43.66206
			],
			[
				-79.3807,
				43.66155
			],
			[
				-79.38192,
				43.66149
			],
			[
				-79.38201,
				43.6614
			],
			[
				-79.38225,
				43.66152
			],
			[
				-79.38296,
				43.66134
			],
			[
				-79.3832,
				43.66135
			],
			[
				-79.38365,
				43.66118
			],
			[
				-79.38385,
				43.66121
			],
			[
				-79.38483,
				43.66105
			],
			[
				-79.38663,
				43.66062
			],
			[
				-79.38712,
				43.66061
			],
			[
				-79.38793,
				43.66039
			],
			[
				-79.38837,
				43.6604
			],
			[
				-79.38916,
				43.66014
			],
			[
				-79.39017,
				43.65997
			],
			[
				-79.39035,
				43.65983
			],
			[
				-79.3915,
				43.65957
			],
			[
				-79.39184,
				43.65956
			],
			[
				-79.39214,
				43.65939
			],
			[
				-79.39283,
				43.65936
			],
			[
				-79.39324,
				43.65919
			],
			[
				-79.39367,
				43.65922
			],
			[
				-79.3955,
				43.65881
			],
			[
				-79.39596,
				43.65887
			],
			[
				-79.39872,
				43.65821
			],
			[
				-79.40063,
				43.65801
			],
			[
				-79.40236,
				43.65756
			],
			[
				-79.40332,
				43.65745
			],
			[
				-79.40667,
				43.65677
			],
			[
				-79.40712,
				43.65675
			],
			[
				-79.40814,
				43.65641
			],
			[
				-79.41471,
				43.65505
			],
			[
				-79.41629,
				43.65501
			],
			[
				-79.41682,
				43.65515
			],
			[
				-79.41776,
				43.65523
			],
			[
				-79.41901,
				43.65516
			],
			[
				-79.41982,
				43.65501
			],
			[
				-79.42119,
				43.65465
			],
			[
				-79.42254,
				43.65447
			],
			[
				-79.42254,
				43.65434
			],
			[
				-79.42273,
				43.65433
			],
			[
				-79.42263,
				43.65389
			],
			[
				-79.42362,
				43.65368
			]
		],
		"dupe": false
	},
	{
		"id": 349375157,
		"name": "Ride to Oakville Denny's",
		"distance": 43134.3,
		"moving_time": 9148,
		"elapsed_time": 13125,
		"total_elevation_gain": 30.9,
		"start_date_local": "2015-07-19T05:32:08Z",
		"average_speed": 4.715,
		"max_speed": 14.2,
		"calories": 636.6,
		"points": [
			[
				-79.42436,
				43.65307
			],
			[
				-79.42594,
				43.65282
			],
			[
				-79.42605,
				43.65271
			],
			[
				-79.42592,
				43.65207
			],
			[
				-79.4258,
				43.65191
			],
			[
				-79.4252,
				43.65031
			],
			[
				-79.42497,
				43.64999
			],
			[
				-79.42348,
				43.64622
			],
			[
				-79.42811,
				43.64522
			],
			[
				-79.42804,
				43.64493
			],
			[
				-79.42954,
				43.64461
			],
			[
				-79.42955,
				43.64438
			],
			[
				-79.42929,
				43.64388
			],
			[
				-79.42876,
				43.64208
			],
			[
				-79.42869,
				43.64207
			],
			[
				-79.4284,
				43.64125
			],
			[
				-79.42828,
				43.6411
			],
			[
				-79.42763,
				43.63925
			],
			[
				-79.4273,
				43.63871
			],
			[
				-79.42566,
				43.63444
			],
			[
				-79.42482,
				43.6325
			],
			[
				-79.42478,
				43.63216
			],
			[
				-79.42499,
				43.63193
			],
			[
				-79.42525,
				43.63186
			],
			[
				-79.42621,
				43.63186
			],
			[
				-79.42896,
				43.63152
			],
			[
				-79.42892,
				43.63142
			],
			[
				-79.42915,
				43.63145
			],
			[
				-79.42951,
				43.63116
			],
			[
				-79.43049,
				43.63134
			],
			[
				-79.43115,
				43.63131
			],
			[
				-79.43181,
				43.63145
			],
			[
				-79.43238,
				43.63139
			],
			[
				-79.43292,
				43.63148
			],
			[
				-79.43342,
				43.63172
			],
			[
				-79.43603,
				43.63237
			],
			[
				-79.43677,
				43.63283
			],
			[
				-79.43665,
				43.63281
			],
			[
				-79.4374,
				43.63341
			],
			[
				-79.43846,
				43.63396
			],
			[
				-79.43854,
				43.63402
			],
			[
				-79.43852,
				43.63412
			],
			[
				-79.44145,
				43.63541
			],
			[
				-79.44178,
				43.63567
			],
			[
				-79.4432,
				43.63615
			],
			[
				-79.44514,
				43.63696
			],
			[
				-79.4463,
				43.63729
			],
			[
				-79.44655,
				43.63728
			],
			[
				-79.44688,
				43.63688
			],
			[
				-79.44756,
				43.63689
			],
			[
				-79.45235,
				43.63774
			],
			[
				-79.45268,
				43.63778
			],
			[
				-79.45323,
				43.6377
			],
			[
				-79.45369,
				43.63793
			],
			[
				-79.45675,
				43.638
			],
			[
				-79.45801,
				43.63786
			],
			[
				-79.45886,
				43.63763
			],
			[
				-79.45943,
				43.63757
			],
			[
				-79.46016,
				43.63714
			],
			[
				-79.46138,
				43.63681
			],
			[
				-79.46194,
				43.63653
			],
			[
				-79.46326,
				43.63622
			],
			[
				-79.46403,
				43.63628
			],
			[
				-79.4646,
				43.63619
			],
			[
				-79.46609,
				43.63573
			],
			[
				-79.46631,
				43.63563
			],
			[
				-79.46641,
				43.63531
			],
			[
				-79.46651,
				43.63523
			],
			[
				-79.4678,
				43.63471
			],
			[
				-79.46899,
				43.63399
			],
			[
				-79.46951,
				43.63312
			],
			[
				-79.46966,
				43.63301
			],
			[
				-79.47091,
				43.63252
			],
			[
				-79.47091,
				43.6324
			],
			[
				-79.47137,
				43.63191
			],
			[
				-79.47131,
				43.63179
			],
			[
				-79.47218,
				43.63091
			],
			[
				-79.47255,
				43.63071
			],
			[
				-79.47288,
				43.63007
			],
			[
				-79.47322,
				43.62994
			],
			[
				-79.47339,
				43.63007
			],
			[
				-79.47337,
				43.63026
			],
			[
				-79.47387,
				43.63049
			],
			[
				-79.4743,
				43.63028
			],
			[
				-79.47465,
				43.62988
			],
			[
				-79.47544,
				43.62935
			],
			[
				-79.47569,
				43.62907
			],
			[
				-79.47639,
				43.62781
			],
			[
				-79.47764,
				43.62452
			],
			[
				-79.47849,
				43.62313
			],
			[
				-79.47873,
				43.62288
			],
			[
				-79.47907,
				43.62268
			],
			[
				-79.4794,
				43.62259
			],
			[
				-79.47996,
				43.62258
			],
			[
				-79.48032,
				43.62185
			],
			[
				-79.48037,
				43.62136
			],
			[
				-79.48056,
				43.62094
			],
			[
				-79.48126,
				43.62058
			],
			[
				-79.48142,
				43.6204
			],
			[
				-79.48163,
				43.61999
			],
			[
				-79.48155,
				43.61978
			],
			[
				-79.48122,
				43.61945
			],
			[
				-79.48132,
				43.61923
			],
			[
				-79.48164,
				43.61899
			],
			[
				-79.48264,
				43.6186
			],
			[
				-79.48323,
				43.61854
			],
			[
				-79.48415,
				43.61806
			],
			[
				-79.48456,
				43.61804
			],
			[
				-79.4847,
				43.61781
			],
			[
				-79.48513,
				43.61743
			],
			[
				-79.48543,
				43.61655
			],
			[
				-79.48558,
				43.6164
			],
			[
				-79.48565,
				43.61608
			],
			[
				-79.48555,
				43.6159
			],
			[
				-79.48554,
				43.61561
			],
			[
				-79.48608,
				43.61452
			],
			[
				-79.48606,
				43.61399
			],
			[
				-79.48616,
				43.61382
			],
			[
				-79.48639,
				43.61371
			],
			[
				-79.4869,
				43.61371
			],
			[
				-79.48723,
				43.61357
			],
			[
				-79.48749,
				43.61316
			],
			[
				-79.48744,
				43.61284
			],
			[
				-79.48698,
				43.61259
			],
			[
				-79.4869,
				43.6124
			],
			[
				-79.48717,
				43.61211
			],
			[
				-79.48722,
				43.61192
			],
			[
				-79.48696,
				43.61162
			],
			[
				-79.487,
				43.61123
			],
			[
				-79.48692,
				43.61101
			],
			[
				-79.48698,
				43.61093
			],
			[
				-79.48752,
				43.61075
			],
			[
				-79.48909,
				43.61097
			],
			[
				-79.49001,
				43.61101
			],
			[
				-79.49024,
				43.61092
			],
			[
				-79.49023,
				43.61057
			],
			[
				-79.49041,
				43.60964
			],
			[
				-79.49049,
				43.60829
			],
			[
				-79.49043,
				43.60821
			],
			[
				-79.49046,
				43.6086
			],
			[
				-79.49066,
				43.60685
			],
			[
				-79.49078,
				43.60642
			],
			[
				-79.4914,
				43.6051
			],
			[
				-79.4919,
				43.60459
			],
			[
				-79.49274,
				43.60395
			],
			[
				-79.49528,
				43.60286
			],
			[
				-79.49611,
				43.60259
			],
			[
				-79.49845,
				43.60233
			],
			[
				-79.49797,
				43.60111
			],
			[
				-79.49855,
				43.60035
			],
			[
				-79.49876,
				43.60024
			],
			[
				-79.49853,
				43.59954
			],
			[
				-79.49903,
				43.59934
			],
			[
				-79.49955,
				43.59891
			],
			[
				-79.4995,
				43.59909
			],
			[
				-79.49987,
				43.59861
			],
			[
				-79.49988,
				43.59849
			],
			[
				-79.50035,
				43.59782
			],
			[
				-79.50027,
				43.59758
			],
			[
				-79.50062,
				43.59689
			],
			[
				-79.50111,
				43.59674
			],
			[
				-79.50085,
				43.59595
			],
			[
				-79.50146,
				43.59539
			],
			[
				-79.50372,
				43.59489
			],
			[
				-79.50506,
				43.59481
			],
			[
				-79.50538,
				43.59493
			],
			[
				-79.50559,
				43.5954
			],
			[
				-79.50626,
				43.59548
			],
			[
				-79.50706,
				43.59546
			],
			[
				-79.5076,
				43.59577
			],
			[
				-79.50804,
				43.5958
			],
			[
				-79.50762,
				43.59473
			],
			[
				-79.5088,
				43.59465
			],
			[
				-79.51071,
				43.59424
			],
			[
				-79.51167,
				43.5939
			],
			[
				-79.5126,
				43.59386
			],
			[
				-79.5131,
				43.59358
			],
			[
				-79.5138,
				43.5929
			],
			[
				-79.51431,
				43.59227
			],
			[
				-79.51552,
				43.59226
			],
			[
				-79.51575,
				43.59218
			],
			[
				-79.51612,
				43.5918
			],
			[
				-79.51618,
				43.59165
			],
			[
				-79.51611,
				43.59114
			],
			[
				-79.51622,
				43.59093
			],
			[
				-79.51637,
				43.59085
			],
			[
				-79.51816,
				43.59055
			],
			[
				-79.51834,
				43.59062
			],
			[
				-79.51867,
				43.59097
			],
			[
				-79.51919,
				43.59105
			],
			[
				-79.51948,
				43.59122
			],
			[
				-79.52063,
				43.59096
			],
			[
				-79.52139,
				43.59099
			],
			[
				-79.52285,
				43.5908
			],
			[
				-79.5237,
				43.59074
			],
			[
				-79.52407,
				43.59079
			],
			[
				-79.52527,
				43.59059
			],
			[
				-79.52601,
				43.59056
			],
			[
				-79.5262,
				43.59051
			],
			[
				-79.52626,
				43.59041
			],
			[
				-79.52866,
				43.59012
			],
			[
				-79.52903,
				43.58987
			],
			[
				-79.52925,
				43.58928
			],
			[
				-79.52941,
				43.58914
			],
			[
				-79.53119,
				43.58872
			],
			[
				-79.53148,
				43.58876
			],
			[
				-79.5316,
				43.5889
			],
			[
				-79.53392,
				43.58841
			],
			[
				-79.53459,
				43.58838
			],
			[
				-79.5347,
				43.58846
			],
			[
				-79.535,
				43.58911
			],
			[
				-79.53735,
				43.5886
			],
			[
				-79.53845,
				43.58806
			],
			[
				-79.54036,
				43.58659
			],
			[
				-79.54095,
				43.58629
			],
			[
				-79.54119,
				43.58629
			],
			[
				-79.5417,
				43.58651
			],
			[
				-79.54209,
				43.58658
			],
			[
				-79.54286,
				43.58698
			],
			[
				-79.54362,
				43.58694
			],
			[
				-79.54438,
				43.58633
			],
			[
				-79.54459,
				43.58626
			],
			[
				-79.5446,
				43.58612
			],
			[
				-79.54483,
				43.58575
			],
			[
				-79.54451,
				43.58549
			],
			[
				-79.5441,
				43.58535
			],
			[
				-79.54326,
				43.58473
			],
			[
				-79.54329,
				43.58356
			],
			[
				-79.54381,
				43.58278
			],
			[
				-79.54401,
				43.58221
			],
			[
				-79.54568,
				43.58219
			],
			[
				-79.54607,
				43.58207
			],
			[
				-79.54674,
				43.58203
			],
			[
				-79.5469,
				43.58193
			],
			[
				-79.54732,
				43.58186
			],
			[
				-79.54723,
				43.58197
			],
			[
				-79.54742,
				43.58216
			],
			[
				-79.54762,
				43.58221
			],
			[
				-79.54767,
				43.58235
			],
			[
				-79.54787,
				43.5825
			],
			[
				-79.54787,
				43.5826
			],
			[
				-79.54804,
				43.58273
			],
			[
				-79.54838,
				43.58276
			],
			[
				-79.54874,
				43.5826
			],
			[
				-79.54985,
				43.58238
			],
			[
				-79.55092,
				43.58275
			],
			[
				-79.55088,
				43.58281
			],
			[
				-79.55103,
				43.58306
			],
			[
				-79.55122,
				43.58317
			],
			[
				-79.55173,
				43.58337
			],
			[
				-79.55188,
				43.58334
			],
			[
				-79.55665,
				43.57876
			],
			[
				-79.55672,
				43.57833
			],
			[
				-79.55406,
				43.57648
			],
			[
				-79.55447,
				43.57624
			],
			[
				-79.55452,
				43.57609
			],
			[
				-79.55585,
				43.57489
			],
			[
				-79.55617,
				43.57448
			],
			[
				-79.55675,
				43.57405
			],
			[
				-79.55717,
				43.57362
			],
			[
				-79.55734,
				43.57355
			],
			[
				-79.55753,
				43.57328
			],
			[
				-79.55787,
				43.573
			],
			[
				-79.55794,
				43.57279
			],
			[
				-79.55768,
				43.5726
			],
			[
				-79.55762,
				43.57264
			],
			[
				-79.55773,
				43.57251
			],
			[
				-79.55768,
				43.57241
			],
			[
				-79.55751,
				43.57246
			],
			[
				-79.55643,
				43.57153
			],
			[
				-79.55613,
				43.57073
			],
			[
				-79.55618,
				43.57025
			],
			[
				-79.55599,
				43.57007
			],
			[
				-79.55652,
				43.56963
			],
			[
				-79.55705,
				43.56932
			],
			[
				-79.55782,
				43.56898
			],
			[
				-79.55824,
				43.56889
			],
			[
				-79.55836,
				43.56898
			],
			[
				-79.559,
				43.56872
			],
			[
				-79.55934,
				43.56849
			],
			[
				-79.55969,
				43.56808
			],
			[
				-79.55996,
				43.56806
			],
			[
				-79.56034,
				43.56767
			],
			[
				-79.56143,
				43.56751
			],
			[
				-79.56194,
				43.5668
			],
			[
				-79.56223,
				43.56682
			],
			[
				-79.5627,
				43.56638
			],
			[
				-79.56312,
				43.56613
			],
			[
				-79.56387,
				43.56598
			],
			[
				-79.56399,
				43.5657
			],
			[
				-79.56395,
				43.56506
			],
			[
				-79.56427,
				43.56495
			],
			[
				-79.56432,
				43.56481
			],
			[
				-79.56433,
				43.56491
			],
			[
				-79.56477,
				43.56499
			],
			[
				-79.5654,
				43.56455
			],
			[
				-79.56586,
				43.5646
			],
			[
				-79.56606,
				43.5645
			],
			[
				-79.56598,
				43.5644
			],
			[
				-79.56672,
				43.56378
			],
			[
				-79.56724,
				43.5637
			],
			[
				-79.56752,
				43.56338
			],
			[
				-79.5678,
				43.56321
			],
			[
				-79.5685,
				43.56311
			],
			[
				-79.56855,
				43.56281
			],
			[
				-79.56873,
				43.56244
			],
			[
				-79.56871,
				43.56222
			],
			[
				-79.56891,
				43.56182
			],
			[
				-79.56888,
				43.56167
			],
			[
				-79.56856,
				43.56136
			],
			[
				-79.56852,
				43.56122
			],
			[
				-79.57117,
				43.55872
			],
			[
				-79.57137,
				43.55861
			],
			[
				-79.57193,
				43.55855
			],
			[
				-79.57214,
				43.55844
			],
			[
				-79.57227,
				43.55823
			],
			[
				-79.57229,
				43.55782
			],
			[
				-79.57248,
				43.55763
			],
			[
				-79.57348,
				43.55716
			],
			[
				-79.57425,
				43.55697
			],
			[
				-79.57452,
				43.55705
			],
			[
				-79.57571,
				43.55771
			],
			[
				-79.57758,
				43.55598
			],
			[
				-79.57671,
				43.55547
			],
			[
				-79.57691,
				43.55531
			],
			[
				-79.57708,
				43.55501
			],
			[
				-79.57775,
				43.55486
			],
			[
				-79.57808,
				43.55462
			],
			[
				-79.57837,
				43.5547
			],
			[
				-79.57857,
				43.55454
			],
			[
				-79.57988,
				43.55424
			],
			[
				-79.58025,
				43.55371
			],
			[
				-79.58089,
				43.55313
			],
			[
				-79.58129,
				43.5529
			],
			[
				-79.58144,
				43.55285
			],
			[
				-79.58151,
				43.55297
			],
			[
				-79.58209,
				43.55321
			],
			[
				-79.58423,
				43.55122
			],
			[
				-79.58436,
				43.55124
			],
			[
				-79.58454,
				43.55111
			],
			[
				-79.58442,
				43.55128
			],
			[
				-79.58483,
				43.55163
			],
			[
				-79.58564,
				43.55144
			],
			[
				-79.58604,
				43.5512
			],
			[
				-79.58673,
				43.55055
			],
			[
				-79.58654,
				43.55032
			],
			[
				-79.58695,
				43.55004
			],
			[
				-79.58698,
				43.5499
			],
			[
				-79.58489,
				43.54877
			],
			[
				-79.58471,
				43.54862
			],
			[
				-79.58409,
				43.54764
			],
			[
				-79.58386,
				43.54764
			],
			[
				-79.58384,
				43.54759
			],
			[
				-79.58402,
				43.54749
			],
			[
				-79.58413,
				43.5471
			],
			[
				-79.58432,
				43.54694
			],
			[
				-79.58431,
				43.54662
			],
			[
				-79.58483,
				43.54643
			],
			[
				-79.58518,
				43.54666
			],
			[
				-79.5854,
				43.54667
			],
			[
				-79.58587,
				43.54616
			],
			[
				-79.58609,
				43.54605
			],
			[
				-79.58961,
				43.54802
			],
			[
				-79.58987,
				43.54805
			],
			[
				-79.59092,
				43.54696
			],
			[
				-79.59263,
				43.54553
			],
			[
				-79.59676,
				43.54173
			],
			[
				-79.59685,
				43.54151
			],
			[
				-79.59662,
				43.5415
			],
			[
				-79.59681,
				43.54136
			],
			[
				-79.59662,
				43.54112
			],
			[
				-79.59667,
				43.54079
			],
			[
				-79.59635,
				43.54052
			],
			[
				-79.5965,
				43.54024
			],
			[
				-79.59671,
				43.54012
			],
			[
				-79.59669,
				43.54007
			],
			[
				-79.59668,
				43.54012
			],
			[
				-79.59705,
				43.54004
			],
			[
				-79.59782,
				43.54037
			],
			[
				-79.5981,
				43.54028
			],
			[
				-79.59844,
				43.53976
			],
			[
				-79.5989,
				43.53941
			],
			[
				-79.59954,
				43.53871
			],
			[
				-79.60003,
				43.53835
			],
			[
				-79.60076,
				43.53758
			],
			[
				-79.6017,
				43.53699
			],
			[
				-79.60397,
				43.5349
			],
			[
				-79.60488,
				43.53393
			],
			[
				-79.60587,
				43.53308
			],
			[
				-79.60714,
				43.53169
			],
			[
				-79.6079,
				43.53114
			],
			[
				-79.60813,
				43.53071
			],
			[
				-79.60823,
				43.53085
			],
			[
				-79.60878,
				43.53032
			],
			[
				-79.6098,
				43.5296
			],
			[
				-79.60986,
				43.52945
			],
			[
				-79.61097,
				43.52836
			],
			[
				-79.61202,
				43.52744
			],
			[
				-79.61202,
				43.52732
			],
			[
				-79.61526,
				43.52446
			],
			[
				-79.61582,
				43.52385
			],
			[
				-79.61736,
				43.52255
			],
			[
				-79.61844,
				43.52145
			],
			[
				-79.61935,
				43.52074
			],
			[
				-79.62088,
				43.51924
			],
			[
				-79.62118,
				43.51904
			],
			[
				-79.62139,
				43.51901
			],
			[
				-79.62382,
				43.51685
			],
			[
				-79.62591,
				43.51492
			],
			[
				-79.6264,
				43.51436
			],
			[
				-79.62652,
				43.51434
			],
			[
				-79.62768,
				43.51329
			],
			[
				-79.62853,
				43.51239
			],
			[
				-79.62945,
				43.51166
			],
			[
				-79.63003,
				43.51102
			],
			[
				-79.6264,
				43.50825
			],
			[
				-79.62232,
				43.50537
			],
			[
				-79.62187,
				43.50493
			],
			[
				-79.62007,
				43.50364
			],
			[
				-79.61829,
				43.50223
			],
			[
				-79.61787,
				43.502
			],
			[
				-79.61319,
				43.49849
			],
			[
				-79.61285,
				43.49799
			],
			[
				-79.61283,
				43.49751
			],
			[
				-79.61302,
				43.4971
			],
			[
				-79.61337,
				43.49666
			],
			[
				-79.61553,
				43.49482
			],
			[
				-79.61798,
				43.49243
			],
			[
				-79.61908,
				43.49157
			],
			[
				-79.61933,
				43.4912
			],
			[
				-79.62001,
				43.49068
			],
			[
				-79.62064,
				43.49001
			],
			[
				-79.62088,
				43.48986
			],
			[
				-79.62108,
				43.48955
			],
			[
				-79.62174,
				43.48907
			],
			[
				-79.62403,
				43.48685
			],
			[
				-79.62422,
				43.4866
			],
			[
				-79.62425,
				43.48637
			],
			[
				-79.62447,
				43.48638
			],
			[
				-79.62461,
				43.4862
			],
			[
				-79.62528,
				43.48574
			],
			[
				-79.62764,
				43.48342
			],
			[
				-79.62868,
				43.48254
			],
			[
				-79.62998,
				43.48121
			],
			[
				-79.63167,
				43.47967
			],
			[
				-79.6323,
				43.47899
			],
			[
				-79.633,
				43.47844
			],
			[
				-79.63571,
				43.4759
			],
			[
				-79.63609,
				43.47565
			],
			[
				-79.6378,
				43.47389
			],
			[
				-79.6382,
				43.47362
			],
			[
				-79.64032,
				43.47163
			],
			[
				-79.64194,
				43.47027
			],
			[
				-79.64202,
				43.4701
			],
			[
				-79.64447,
				43.46791
			],
			[
				-79.64472,
				43.46757
			],
			[
				-79.64622,
				43.46629
			],
			[
				-79.64751,
				43.465
			],
			[
				-79.64768,
				43.46494
			],
			[
				-79.64831,
				43.46431
			],
			[
				-79.64884,
				43.46393
			],
			[
				-79.65042,
				43.46229
			],
			[
				-79.65088,
				43.46199
			],
			[
				-79.65241,
				43.46062
			],
			[
				-79.65906,
				43.4544
			],
			[
				-79.65961,
				43.4538
			],
			[
				-79.66041,
				43.45318
			],
			[
				-79.66176,
				43.45183
			],
			[
				-79.66262,
				43.45112
			],
			[
				-79.66342,
				43.45025
			],
			[
				-79.66428,
				43.44956
			],
			[
				-79.66683,
				43.44721
			],
			[
				-79.66669,
				43.44719
			],
			[
				-79.66643,
				43.44743
			],
			[
				-79.66681,
				43.44762
			],
			[
				-79.66735,
				43.44771
			],
			[
				-79.66741,
				43.44791
			],
			[
				-79.66786,
				43.44823
			],
			[
				-79.66928,
				43.44903
			],
			[
				-79.66932,
				43.44931
			],
			[
				-79.66945,
				43.44931
			],
			[
				-79.66938,
				43.44919
			],
			[
				-79.66945,
				43.44919
			],
			[
				-79.67031,
				43.44974
			],
			[
				-79.67062,
				43.44984
			],
			[
				-79.67122,
				43.45037
			],
			[
				-79.67522,
				43.45195
			],
			[
				-79.67963,
				43.45411
			],
			[
				-79.67992,
				43.45442
			],
			[
				-79.6812,
				43.45724
			],
			[
				-79.68093,
				43.45734
			],
			[
				-79.68102,
				43.45739
			],
			[
				-79.68266,
				43.45694
			],
			[
				-79.68359,
				43.45659
			],
			[
				-79.68498,
				43.45534
			],
			[
				-79.68546,
				43.455
			]
		],
		"dupe": false
	},
	{
		"id": 348866549,
		"name": "Afternoon Ride",
		"distance": 4551.5,
		"moving_time": 712,
		"elapsed_time": 754,
		"total_elevation_gain": 11.4,
		"start_date_local": "2015-07-18T14:21:03Z",
		"average_speed": 6.393,
		"max_speed": 11.2,
		"calories": 89.1,
		"points": [
			[
				-79.42341,
				43.65383
			],
			[
				-79.42315,
				43.65375
			],
			[
				-79.42257,
				43.65394
			],
			[
				-79.42251,
				43.65409
			],
			[
				-79.42297,
				43.6551
			],
			[
				-79.4236,
				43.6569
			],
			[
				-79.42402,
				43.65774
			],
			[
				-79.42422,
				43.65847
			],
			[
				-79.42398,
				43.6586
			],
			[
				-79.42012,
				43.65941
			],
			[
				-79.41834,
				43.65972
			],
			[
				-79.39912,
				43.66369
			],
			[
				-79.39832,
				43.6641
			],
			[
				-79.397,
				43.66441
			],
			[
				-79.39703,
				43.66463
			],
			[
				-79.3983,
				43.66778
			],
			[
				-79.39294,
				43.66886
			],
			[
				-79.3896,
				43.66966
			],
			[
				-79.38956,
				43.66963
			],
			[
				-79.38966,
				43.66962
			],
			[
				-79.3894,
				43.66955
			],
			[
				-79.38868,
				43.66777
			],
			[
				-79.38773,
				43.66573
			],
			[
				-79.38767,
				43.66564
			],
			[
				-79.38753,
				43.66567
			]
		],
		"dupe": false
	},
	{
		"id": 349032746,
		"name": "Evening Ride",
		"distance": 4037.7,
		"moving_time": 1026,
		"elapsed_time": 1080,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-18T20:00:23Z",
		"average_speed": 3.935,
		"max_speed": 10.2,
		"calories": 69.2,
		"points": [
			[
				-79.38713,
				43.66608
			],
			[
				-79.38716,
				43.66613
			],
			[
				-79.3873,
				43.66596
			],
			[
				-79.38735,
				43.66603
			],
			[
				-79.38752,
				43.66583
			],
			[
				-79.38767,
				43.66584
			],
			[
				-79.38833,
				43.6656
			],
			[
				-79.38837,
				43.66546
			],
			[
				-79.39101,
				43.6649
			],
			[
				-79.39103,
				43.66478
			],
			[
				-79.39114,
				43.66488
			],
			[
				-79.39209,
				43.66463
			],
			[
				-79.39222,
				43.66434
			],
			[
				-79.3925,
				43.66428
			],
			[
				-79.3927,
				43.66357
			],
			[
				-79.39267,
				43.66321
			],
			[
				-79.39349,
				43.66299
			],
			[
				-79.39469,
				43.66247
			],
			[
				-79.39504,
				43.66258
			],
			[
				-79.39507,
				43.66244
			],
			[
				-79.39556,
				43.66236
			],
			[
				-79.39596,
				43.6622
			],
			[
				-79.39624,
				43.66185
			],
			[
				-79.39594,
				43.66114
			],
			[
				-79.39575,
				43.66105
			],
			[
				-79.39517,
				43.66101
			],
			[
				-79.39506,
				43.66072
			],
			[
				-79.39538,
				43.66046
			],
			[
				-79.39681,
				43.6601
			],
			[
				-79.3963,
				43.65894
			],
			[
				-79.39632,
				43.65874
			],
			[
				-79.39898,
				43.65821
			],
			[
				-79.40034,
				43.65805
			],
			[
				-79.40227,
				43.65759
			],
			[
				-79.40269,
				43.65757
			],
			[
				-79.40319,
				43.65737
			],
			[
				-79.40383,
				43.65749
			],
			[
				-79.40474,
				43.65715
			],
			[
				-79.40527,
				43.65714
			],
			[
				-79.40595,
				43.65688
			],
			[
				-79.41266,
				43.65557
			],
			[
				-79.41359,
				43.65532
			],
			[
				-79.41362,
				43.65523
			],
			[
				-79.41376,
				43.65531
			],
			[
				-79.41542,
				43.65497
			],
			[
				-79.41623,
				43.65517
			],
			[
				-79.41758,
				43.6553
			],
			[
				-79.41903,
				43.65524
			],
			[
				-79.42267,
				43.65448
			],
			[
				-79.42278,
				43.65433
			],
			[
				-79.42262,
				43.65389
			],
			[
				-79.42263,
				43.65395
			],
			[
				-79.42269,
				43.65387
			],
			[
				-79.42354,
				43.65369
			]
		],
		"dupe": false
	},
	{
		"id": 349506973,
		"name": "Lunch Ride",
		"distance": 1895.3,
		"moving_time": 404,
		"elapsed_time": 454,
		"total_elevation_gain": 11.2,
		"start_date_local": "2015-07-19T11:43:10Z",
		"average_speed": 4.691,
		"max_speed": 8.5,
		"calories": 34.1,
		"points": [
			[
				-79.41967,
				43.63635
			],
			[
				-79.41978,
				43.6364
			],
			[
				-79.41993,
				43.63684
			],
			[
				-79.42001,
				43.63688
			],
			[
				-79.42129,
				43.64012
			],
			[
				-79.42007,
				43.64034
			],
			[
				-79.41818,
				43.64082
			],
			[
				-79.41823,
				43.64086
			],
			[
				-79.41817,
				43.6407
			],
			[
				-79.41758,
				43.64081
			],
			[
				-79.41744,
				43.64094
			],
			[
				-79.4174,
				43.64116
			],
			[
				-79.41753,
				43.64124
			],
			[
				-79.41804,
				43.64124
			],
			[
				-79.4181,
				43.64118
			],
			[
				-79.42125,
				43.64129
			],
			[
				-79.4216,
				43.64142
			],
			[
				-79.424,
				43.64755
			]
		],
		"dupe": false
	},
	{
		"id": 349773494,
		"name": "Afternoon Ride",
		"distance": 2411.5,
		"moving_time": 340,
		"elapsed_time": 366,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-19T17:03:45Z",
		"average_speed": 7.093,
		"max_speed": 13.9,
		"calories": 44.9,
		"points": [
			[
				-79.40524,
				43.6624
			],
			[
				-79.40314,
				43.66279
			],
			[
				-79.40243,
				43.66311
			],
			[
				-79.4022,
				43.66304
			],
			[
				-79.40212,
				43.66292
			],
			[
				-79.40203,
				43.66241
			],
			[
				-79.40181,
				43.66215
			],
			[
				-79.40178,
				43.66181
			],
			[
				-79.40165,
				43.6617
			],
			[
				-79.4016,
				43.6614
			],
			[
				-79.4012,
				43.66045
			],
			[
				-79.40122,
				43.66026
			],
			[
				-79.4016,
				43.65991
			],
			[
				-79.40163,
				43.65951
			],
			[
				-79.40136,
				43.65921
			],
			[
				-79.40072,
				43.6591
			],
			[
				-79.40043,
				43.65881
			],
			[
				-79.40028,
				43.65813
			],
			[
				-79.39942,
				43.65592
			],
			[
				-79.39899,
				43.65505
			],
			[
				-79.39895,
				43.65477
			],
			[
				-79.39863,
				43.65409
			],
			[
				-79.39823,
				43.65278
			],
			[
				-79.3981,
				43.65262
			],
			[
				-79.39762,
				43.65128
			],
			[
				-79.39625,
				43.64799
			],
			[
				-79.39651,
				43.64788
			],
			[
				-79.39878,
				43.64743
			],
			[
				-79.39852,
				43.64711
			],
			[
				-79.39828,
				43.64654
			]
		],
		"dupe": false
	},
	{
		"id": 349731810,
		"name": "Afternoon Ride",
		"distance": 1848.3,
		"moving_time": 419,
		"elapsed_time": 430,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-19T15:59:32Z",
		"average_speed": 4.411,
		"max_speed": 7.8,
		"calories": 29.6,
		"points": [
			[
				-79.42388,
				43.6567
			],
			[
				-79.42368,
				43.65672
			],
			[
				-79.42368,
				43.65685
			],
			[
				-79.42397,
				43.65736
			],
			[
				-79.42422,
				43.65843
			],
			[
				-79.42403,
				43.65859
			],
			[
				-79.41355,
				43.66072
			],
			[
				-79.41338,
				43.66053
			],
			[
				-79.41334,
				43.6606
			],
			[
				-79.41334,
				43.66053
			],
			[
				-79.41333,
				43.66074
			],
			[
				-79.41272,
				43.66084
			],
			[
				-79.41268,
				43.66092
			],
			[
				-79.40555,
				43.66238
			]
		],
		"dupe": false
	},
	{
		"id": 353074090,
		"name": "Ride attempt to WayHome [failed due to crash]",
		"distance": 58357.1,
		"moving_time": 9152,
		"elapsed_time": 11547,
		"total_elevation_gain": 410.6,
		"start_date_local": "2015-07-24T06:23:05Z",
		"average_speed": 6.376,
		"max_speed": 14.2,
		"calories": 1226,
		"points": [
			[
				-79.4227,
				43.65481
			],
			[
				-79.42322,
				43.65571
			],
			[
				-79.42411,
				43.65836
			],
			[
				-79.42547,
				43.66189
			],
			[
				-79.42672,
				43.66462
			],
			[
				-79.42803,
				43.66792
			],
			[
				-79.42828,
				43.66827
			],
			[
				-79.42889,
				43.66981
			],
			[
				-79.42887,
				43.66996
			],
			[
				-79.42889,
				43.66991
			],
			[
				-79.42858,
				43.67008
			],
			[
				-79.42526,
				43.67087
			],
			[
				-79.419,
				43.67204
			],
			[
				-79.41493,
				43.67295
			],
			[
				-79.41432,
				43.67317
			],
			[
				-79.41433,
				43.67332
			],
			[
				-79.41512,
				43.67518
			],
			[
				-79.41527,
				43.67589
			],
			[
				-79.41579,
				43.67709
			],
			[
				-79.41582,
				43.67742
			],
			[
				-79.41603,
				43.67782
			],
			[
				-79.41615,
				43.6783
			],
			[
				-79.41668,
				43.6793
			],
			[
				-79.41709,
				43.68039
			],
			[
				-79.41712,
				43.68071
			],
			[
				-79.41733,
				43.68103
			],
			[
				-79.41743,
				43.68173
			],
			[
				-79.41795,
				43.68257
			],
			[
				-79.41814,
				43.68315
			],
			[
				-79.41854,
				43.68381
			],
			[
				-79.41899,
				43.68486
			],
			[
				-79.41902,
				43.68529
			],
			[
				-79.41891,
				43.68536
			],
			[
				-79.41893,
				43.68558
			],
			[
				-79.41905,
				43.68567
			],
			[
				-79.41916,
				43.68608
			],
			[
				-79.41974,
				43.68695
			],
			[
				-79.41992,
				43.6875
			],
			[
				-79.42001,
				43.68752
			],
			[
				-79.42045,
				43.6886
			],
			[
				-79.42043,
				43.68879
			],
			[
				-79.42066,
				43.68912
			],
			[
				-79.42058,
				43.68923
			],
			[
				-79.42108,
				43.69016
			],
			[
				-79.42165,
				43.69181
			],
			[
				-79.42202,
				43.69255
			],
			[
				-79.42209,
				43.69295
			],
			[
				-79.42287,
				43.69478
			],
			[
				-79.42339,
				43.6957
			],
			[
				-79.42372,
				43.69688
			],
			[
				-79.4243,
				43.69816
			],
			[
				-79.42453,
				43.6985
			],
			[
				-79.42446,
				43.69869
			],
			[
				-79.42451,
				43.69895
			],
			[
				-79.4249,
				43.69936
			],
			[
				-79.42483,
				43.69946
			],
			[
				-79.42484,
				43.69975
			],
			[
				-79.42533,
				43.70082
			],
			[
				-79.42546,
				43.70173
			],
			[
				-79.42556,
				43.70197
			],
			[
				-79.42549,
				43.70252
			],
			[
				-79.42585,
				43.7039
			],
			[
				-79.42577,
				43.70395
			],
			[
				-79.42623,
				43.70492
			],
			[
				-79.42638,
				43.70564
			],
			[
				-79.42632,
				43.70597
			],
			[
				-79.42649,
				43.70627
			],
			[
				-79.42668,
				43.70709
			],
			[
				-79.42682,
				43.70733
			],
			[
				-79.42683,
				43.70786
			],
			[
				-79.42705,
				43.70875
			],
			[
				-79.42741,
				43.70973
			],
			[
				-79.42753,
				43.7107
			],
			[
				-79.42766,
				43.71108
			],
			[
				-79.42761,
				43.71117
			],
			[
				-79.42797,
				43.71201
			],
			[
				-79.42804,
				43.71247
			],
			[
				-79.42832,
				43.71314
			],
			[
				-79.42829,
				43.71335
			],
			[
				-79.42848,
				43.71373
			],
			[
				-79.42846,
				43.71438
			],
			[
				-79.42925,
				43.71727
			],
			[
				-79.42918,
				43.71771
			],
			[
				-79.42927,
				43.71779
			],
			[
				-79.42952,
				43.71893
			],
			[
				-79.42963,
				43.7191
			],
			[
				-79.42977,
				43.72002
			],
			[
				-79.42989,
				43.72024
			],
			[
				-79.42987,
				43.72065
			],
			[
				-79.42995,
				43.72077
			],
			[
				-79.42997,
				43.72114
			],
			[
				-79.43018,
				43.72158
			],
			[
				-79.43027,
				43.72228
			],
			[
				-79.43042,
				43.72252
			],
			[
				-79.43051,
				43.72353
			],
			[
				-79.43087,
				43.72448
			],
			[
				-79.43076,
				43.72467
			],
			[
				-79.43078,
				43.72491
			],
			[
				-79.43085,
				43.72521
			],
			[
				-79.43109,
				43.72558
			],
			[
				-79.43145,
				43.72699
			],
			[
				-79.4314,
				43.72726
			],
			[
				-79.43166,
				43.72795
			],
			[
				-79.43164,
				43.72834
			],
			[
				-79.43211,
				43.7296
			],
			[
				-79.43208,
				43.73
			],
			[
				-79.43232,
				43.73089
			],
			[
				-79.43232,
				43.73117
			],
			[
				-79.43258,
				43.73182
			],
			[
				-79.43265,
				43.7324
			],
			[
				-79.43282,
				43.73283
			],
			[
				-79.43293,
				43.73349
			],
			[
				-79.43306,
				43.73362
			],
			[
				-79.43301,
				43.73372
			],
			[
				-79.43316,
				43.73381
			],
			[
				-79.43314,
				43.73437
			],
			[
				-79.43336,
				43.73511
			],
			[
				-79.4333,
				43.73541
			],
			[
				-79.43368,
				43.73678
			],
			[
				-79.4337,
				43.73661
			],
			[
				-79.43385,
				43.73692
			],
			[
				-79.43389,
				43.73745
			],
			[
				-79.4342,
				43.73876
			],
			[
				-79.43455,
				43.73971
			],
			[
				-79.43464,
				43.74057
			],
			[
				-79.43485,
				43.74125
			],
			[
				-79.43483,
				43.74144
			],
			[
				-79.43536,
				43.74304
			],
			[
				-79.43531,
				43.74341
			],
			[
				-79.43549,
				43.74386
			],
			[
				-79.43551,
				43.74424
			],
			[
				-79.43569,
				43.7448
			],
			[
				-79.43599,
				43.74534
			],
			[
				-79.436,
				43.746
			],
			[
				-79.43621,
				43.74664
			],
			[
				-79.43635,
				43.74684
			],
			[
				-79.43624,
				43.74701
			],
			[
				-79.43655,
				43.74792
			],
			[
				-79.43651,
				43.74815
			],
			[
				-79.43667,
				43.74859
			],
			[
				-79.43669,
				43.74907
			],
			[
				-79.43716,
				43.75041
			],
			[
				-79.43715,
				43.75074
			],
			[
				-79.43771,
				43.7527
			],
			[
				-79.43767,
				43.75285
			],
			[
				-79.43777,
				43.7534
			],
			[
				-79.43809,
				43.75427
			],
			[
				-79.43818,
				43.75503
			],
			[
				-79.43813,
				43.75543
			],
			[
				-79.43825,
				43.75564
			],
			[
				-79.43819,
				43.75576
			],
			[
				-79.43833,
				43.75615
			],
			[
				-79.43852,
				43.75645
			],
			[
				-79.4385,
				43.75711
			],
			[
				-79.43862,
				43.75772
			],
			[
				-79.43882,
				43.75807
			],
			[
				-79.43879,
				43.75842
			],
			[
				-79.43899,
				43.75907
			],
			[
				-79.4391,
				43.75919
			],
			[
				-79.43911,
				43.75944
			],
			[
				-79.43933,
				43.75976
			],
			[
				-79.43964,
				43.76073
			],
			[
				-79.43959,
				43.76096
			],
			[
				-79.44011,
				43.76263
			],
			[
				-79.44041,
				43.76387
			],
			[
				-79.44034,
				43.7639
			],
			[
				-79.44054,
				43.76431
			],
			[
				-79.44065,
				43.76538
			],
			[
				-79.44083,
				43.76581
			],
			[
				-79.44097,
				43.76657
			],
			[
				-79.44126,
				43.76719
			],
			[
				-79.44149,
				43.76857
			],
			[
				-79.4418,
				43.76933
			],
			[
				-79.44198,
				43.77057
			],
			[
				-79.44217,
				43.77089
			],
			[
				-79.44217,
				43.77116
			],
			[
				-79.44235,
				43.77148
			],
			[
				-79.44227,
				43.77163
			],
			[
				-79.44253,
				43.7722
			],
			[
				-79.44276,
				43.77381
			],
			[
				-79.44293,
				43.77412
			],
			[
				-79.4431,
				43.77476
			],
			[
				-79.44315,
				43.77537
			],
			[
				-79.44325,
				43.77547
			],
			[
				-79.44322,
				43.77562
			],
			[
				-79.44335,
				43.77585
			],
			[
				-79.44329,
				43.77599
			],
			[
				-79.44338,
				43.77618
			],
			[
				-79.44332,
				43.77633
			],
			[
				-79.4438,
				43.77742
			],
			[
				-79.44399,
				43.7785
			],
			[
				-79.44423,
				43.77903
			],
			[
				-79.44425,
				43.77956
			],
			[
				-79.44449,
				43.78019
			],
			[
				-79.44447,
				43.78035
			],
			[
				-79.44469,
				43.78075
			],
			[
				-79.44467,
				43.78101
			],
			[
				-79.44482,
				43.78129
			],
			[
				-79.44494,
				43.78213
			],
			[
				-79.44506,
				43.78232
			],
			[
				-79.44501,
				43.78235
			],
			[
				-79.44506,
				43.7828
			],
			[
				-79.44519,
				43.7831
			],
			[
				-79.44526,
				43.78407
			],
			[
				-79.44548,
				43.7845
			],
			[
				-79.44606,
				43.78645
			],
			[
				-79.44617,
				43.78708
			],
			[
				-79.44625,
				43.78715
			],
			[
				-79.44619,
				43.78721
			],
			[
				-79.44622,
				43.78758
			],
			[
				-79.44635,
				43.78792
			],
			[
				-79.44624,
				43.78794
			],
			[
				-79.44622,
				43.78804
			],
			[
				-79.44634,
				43.78831
			],
			[
				-79.44631,
				43.78859
			],
			[
				-79.44601,
				43.78919
			],
			[
				-79.44603,
				43.7895
			],
			[
				-79.4453,
				43.7904
			],
			[
				-79.44523,
				43.79116
			],
			[
				-79.4454,
				43.792
			],
			[
				-79.44573,
				43.79304
			],
			[
				-79.44567,
				43.79329
			],
			[
				-79.44594,
				43.79403
			],
			[
				-79.44604,
				43.79472
			],
			[
				-79.44645,
				43.79611
			],
			[
				-79.44654,
				43.79623
			],
			[
				-79.44651,
				43.79665
			],
			[
				-79.44666,
				43.79686
			],
			[
				-79.44678,
				43.79725
			],
			[
				-79.44673,
				43.79739
			],
			[
				-79.44685,
				43.79799
			],
			[
				-79.44682,
				43.7982
			],
			[
				-79.44714,
				43.79911
			],
			[
				-79.44733,
				43.79935
			],
			[
				-79.4474,
				43.79995
			],
			[
				-79.44766,
				43.80074
			],
			[
				-79.44807,
				43.80271
			],
			[
				-79.44818,
				43.80288
			],
			[
				-79.44816,
				43.8032
			],
			[
				-79.44832,
				43.80365
			],
			[
				-79.44865,
				43.80531
			],
			[
				-79.44886,
				43.80579
			],
			[
				-79.44907,
				43.80725
			],
			[
				-79.44932,
				43.80813
			],
			[
				-79.44936,
				43.80892
			],
			[
				-79.4495,
				43.80937
			],
			[
				-79.44939,
				43.80998
			],
			[
				-79.44944,
				43.81041
			],
			[
				-79.44962,
				43.81071
			],
			[
				-79.45036,
				43.81273
			],
			[
				-79.45065,
				43.81382
			],
			[
				-79.45079,
				43.81403
			],
			[
				-79.45084,
				43.81459
			],
			[
				-79.45116,
				43.8158
			],
			[
				-79.45135,
				43.81695
			],
			[
				-79.45188,
				43.8187
			],
			[
				-79.45184,
				43.81888
			],
			[
				-79.45201,
				43.81931
			],
			[
				-79.45209,
				43.82007
			],
			[
				-79.45268,
				43.82216
			],
			[
				-79.45262,
				43.82217
			],
			[
				-79.45281,
				43.82317
			],
			[
				-79.4534,
				43.82521
			],
			[
				-79.45358,
				43.82636
			],
			[
				-79.45371,
				43.82674
			],
			[
				-79.45373,
				43.82769
			],
			[
				-79.45391,
				43.82859
			],
			[
				-79.45401,
				43.82869
			],
			[
				-79.4544,
				43.83096
			],
			[
				-79.45493,
				43.83253
			],
			[
				-79.45536,
				43.8346
			],
			[
				-79.45562,
				43.83513
			],
			[
				-79.45596,
				43.83651
			],
			[
				-79.45609,
				43.83678
			],
			[
				-79.45608,
				43.83717
			],
			[
				-79.45642,
				43.83854
			],
			[
				-79.45661,
				43.83892
			],
			[
				-79.45662,
				43.83926
			],
			[
				-79.45682,
				43.83989
			],
			[
				-79.45686,
				43.84041
			],
			[
				-79.45707,
				43.84099
			],
			[
				-79.45722,
				43.84202
			],
			[
				-79.45737,
				43.84266
			],
			[
				-79.45749,
				43.84285
			],
			[
				-79.45745,
				43.84337
			],
			[
				-79.45771,
				43.84379
			],
			[
				-79.45769,
				43.84404
			],
			[
				-79.45777,
				43.84421
			],
			[
				-79.45766,
				43.8444
			],
			[
				-79.45764,
				43.84465
			],
			[
				-79.45772,
				43.84514
			],
			[
				-79.45801,
				43.84561
			],
			[
				-79.45834,
				43.84684
			],
			[
				-79.4583,
				43.84696
			],
			[
				-79.45849,
				43.84725
			],
			[
				-79.45851,
				43.8474
			],
			[
				-79.45845,
				43.84742
			],
			[
				-79.45884,
				43.84868
			],
			[
				-79.4588,
				43.84878
			],
			[
				-79.45909,
				43.84954
			],
			[
				-79.45919,
				43.85056
			],
			[
				-79.45952,
				43.85137
			],
			[
				-79.45955,
				43.85177
			],
			[
				-79.45975,
				43.8524
			],
			[
				-79.45966,
				43.85248
			],
			[
				-79.45975,
				43.85317
			],
			[
				-79.45982,
				43.85325
			],
			[
				-79.45977,
				43.85333
			],
			[
				-79.45998,
				43.85371
			],
			[
				-79.46003,
				43.85405
			],
			[
				-79.45998,
				43.85416
			],
			[
				-79.46016,
				43.85454
			],
			[
				-79.46023,
				43.85512
			],
			[
				-79.4604,
				43.85556
			],
			[
				-79.4608,
				43.85744
			],
			[
				-79.46078,
				43.85763
			],
			[
				-79.46097,
				43.85814
			],
			[
				-79.46108,
				43.85885
			],
			[
				-79.46129,
				43.85933
			],
			[
				-79.46137,
				43.86022
			],
			[
				-79.46157,
				43.86071
			],
			[
				-79.46168,
				43.8614
			],
			[
				-79.46197,
				43.86233
			],
			[
				-79.46206,
				43.86329
			],
			[
				-79.46228,
				43.86389
			],
			[
				-79.46246,
				43.86523
			],
			[
				-79.46264,
				43.86572
			],
			[
				-79.46273,
				43.86632
			],
			[
				-79.46283,
				43.86644
			],
			[
				-79.46295,
				43.86715
			],
			[
				-79.46325,
				43.86799
			],
			[
				-79.46337,
				43.86897
			],
			[
				-79.46346,
				43.8691
			],
			[
				-79.46374,
				43.8705
			],
			[
				-79.46391,
				43.87081
			],
			[
				-79.46401,
				43.87181
			],
			[
				-79.46422,
				43.87266
			],
			[
				-79.46466,
				43.8741
			],
			[
				-79.46502,
				43.87478
			],
			[
				-79.46494,
				43.87491
			],
			[
				-79.46502,
				43.87534
			],
			[
				-79.46525,
				43.87566
			],
			[
				-79.4652,
				43.87586
			],
			[
				-79.46534,
				43.87656
			],
			[
				-79.46558,
				43.87689
			],
			[
				-79.46568,
				43.87753
			],
			[
				-79.4656,
				43.87754
			],
			[
				-79.46564,
				43.87761
			],
			[
				-79.46557,
				43.8777
			],
			[
				-79.46591,
				43.87827
			],
			[
				-79.46615,
				43.87972
			],
			[
				-79.4665,
				43.88063
			],
			[
				-79.46697,
				43.88299
			],
			[
				-79.46748,
				43.88484
			],
			[
				-79.46749,
				43.88525
			],
			[
				-79.46766,
				43.8856
			],
			[
				-79.4679,
				43.88667
			],
			[
				-79.46805,
				43.88767
			],
			[
				-79.46802,
				43.88791
			],
			[
				-79.4682,
				43.88804
			],
			[
				-79.46813,
				43.88827
			],
			[
				-79.46805,
				43.8883
			],
			[
				-79.46824,
				43.88858
			],
			[
				-79.46839,
				43.8894
			],
			[
				-79.46847,
				43.88945
			],
			[
				-79.46865,
				43.89005
			],
			[
				-79.46862,
				43.89016
			],
			[
				-79.46869,
				43.89025
			],
			[
				-79.46858,
				43.89041
			],
			[
				-79.46885,
				43.8909
			],
			[
				-79.46872,
				43.89103
			],
			[
				-79.46888,
				43.89132
			],
			[
				-79.46897,
				43.89188
			],
			[
				-79.46945,
				43.8934
			],
			[
				-79.46983,
				43.89516
			],
			[
				-79.47004,
				43.8956
			],
			[
				-79.47007,
				43.89606
			],
			[
				-79.47063,
				43.8978
			],
			[
				-79.47073,
				43.89867
			],
			[
				-79.47099,
				43.89926
			],
			[
				-79.47132,
				43.9011
			],
			[
				-79.47229,
				43.90456
			],
			[
				-79.47246,
				43.90548
			],
			[
				-79.47275,
				43.90637
			],
			[
				-79.4729,
				43.90728
			],
			[
				-79.47308,
				43.90771
			],
			[
				-79.4731,
				43.9082
			],
			[
				-79.47335,
				43.90942
			],
			[
				-79.47374,
				43.91057
			],
			[
				-79.47376,
				43.91105
			],
			[
				-79.47388,
				43.91125
			],
			[
				-79.47411,
				43.91257
			],
			[
				-79.47469,
				43.91469
			],
			[
				-79.4753,
				43.91747
			],
			[
				-79.47605,
				43.91979
			],
			[
				-79.47695,
				43.92198
			],
			[
				-79.47704,
				43.92241
			],
			[
				-79.4776,
				43.92384
			],
			[
				-79.47812,
				43.92622
			],
			[
				-79.47839,
				43.92705
			],
			[
				-79.4788,
				43.92892
			],
			[
				-79.47893,
				43.92913
			],
			[
				-79.47921,
				43.93075
			],
			[
				-79.47952,
				43.93201
			],
			[
				-79.48022,
				43.93445
			],
			[
				-79.48048,
				43.93588
			],
			[
				-79.48113,
				43.93799
			],
			[
				-79.48112,
				43.93822
			],
			[
				-79.4805,
				43.93839
			],
			[
				-79.48045,
				43.93854
			],
			[
				-79.48062,
				43.93839
			],
			[
				-79.48107,
				43.93834
			],
			[
				-79.48131,
				43.93847
			],
			[
				-79.48142,
				43.93932
			],
			[
				-79.48182,
				43.94108
			],
			[
				-79.48219,
				43.94229
			],
			[
				-79.48257,
				43.9438
			],
			[
				-79.48263,
				43.94441
			],
			[
				-79.48289,
				43.94522
			],
			[
				-79.48309,
				43.9468
			],
			[
				-79.48371,
				43.95007
			],
			[
				-79.48481,
				43.95424
			],
			[
				-79.48521,
				43.95648
			],
			[
				-79.48565,
				43.95791
			],
			[
				-79.48576,
				43.95861
			],
			[
				-79.48604,
				43.95943
			],
			[
				-79.48685,
				43.96287
			],
			[
				-79.48732,
				43.96451
			],
			[
				-79.48763,
				43.96519
			],
			[
				-79.48762,
				43.96551
			],
			[
				-79.48802,
				43.96701
			],
			[
				-79.48806,
				43.9676
			],
			[
				-79.48848,
				43.96886
			],
			[
				-79.48847,
				43.96922
			],
			[
				-79.48904,
				43.97129
			],
			[
				-79.48932,
				43.97255
			],
			[
				-79.48936,
				43.97308
			],
			[
				-79.48954,
				43.97356
			],
			[
				-79.48951,
				43.97396
			],
			[
				-79.48962,
				43.97459
			],
			[
				-79.48985,
				43.97516
			],
			[
				-79.48972,
				43.9754
			],
			[
				-79.48975,
				43.97559
			],
			[
				-79.49069,
				43.97845
			],
			[
				-79.49086,
				43.97872
			],
			[
				-79.49102,
				43.97924
			],
			[
				-79.49097,
				43.97935
			],
			[
				-79.4915,
				43.98201
			],
			[
				-79.49168,
				43.98351
			],
			[
				-79.49231,
				43.98577
			],
			[
				-79.49266,
				43.9875
			],
			[
				-79.49298,
				43.98815
			],
			[
				-79.49303,
				43.98901
			],
			[
				-79.49329,
				43.99029
			],
			[
				-79.49401,
				43.99287
			],
			[
				-79.49447,
				43.99561
			],
			[
				-79.49484,
				43.99649
			],
			[
				-79.49478,
				43.99658
			],
			[
				-79.49494,
				43.99741
			],
			[
				-79.49523,
				43.99861
			],
			[
				-79.49544,
				43.99916
			],
			[
				-79.49575,
				44.00098
			],
			[
				-79.49591,
				44.0014
			],
			[
				-79.49598,
				44.00203
			],
			[
				-79.49625,
				44.00305
			],
			[
				-79.49631,
				44.00389
			],
			[
				-79.49653,
				44.00447
			],
			[
				-79.49649,
				44.00463
			],
			[
				-79.49663,
				44.005
			],
			[
				-79.49659,
				44.0051
			],
			[
				-79.49676,
				44.00593
			],
			[
				-79.49698,
				44.00653
			],
			[
				-79.49704,
				44.00751
			],
			[
				-79.4972,
				44.00784
			],
			[
				-79.49722,
				44.00819
			],
			[
				-79.49766,
				44.00964
			],
			[
				-79.49882,
				44.01464
			],
			[
				-79.49995,
				44.01814
			],
			[
				-79.50016,
				44.01849
			],
			[
				-79.50014,
				44.01881
			],
			[
				-79.50033,
				44.0195
			],
			[
				-79.50026,
				44.02072
			],
			[
				-79.50009,
				44.02116
			],
			[
				-79.49973,
				44.02164
			],
			[
				-79.49937,
				44.02237
			],
			[
				-79.49741,
				44.02439
			],
			[
				-79.49646,
				44.02548
			],
			[
				-79.49592,
				44.02627
			],
			[
				-79.49551,
				44.02736
			],
			[
				-79.49562,
				44.02903
			],
			[
				-79.49641,
				44.03267
			],
			[
				-79.4968,
				44.03414
			],
			[
				-79.49719,
				44.0351
			],
			[
				-79.49737,
				44.03523
			],
			[
				-79.49791,
				44.03599
			],
			[
				-79.5012,
				44.03897
			],
			[
				-79.5032,
				44.04062
			],
			[
				-79.50382,
				44.04128
			],
			[
				-79.50448,
				44.04232
			],
			[
				-79.50477,
				44.0432
			],
			[
				-79.50532,
				44.04579
			],
			[
				-79.50555,
				44.04655
			],
			[
				-79.50561,
				44.04709
			],
			[
				-79.50603,
				44.04865
			],
			[
				-79.50612,
				44.04937
			],
			[
				-79.50662,
				44.05143
			],
			[
				-79.50676,
				44.05238
			],
			[
				-79.507,
				44.05319
			],
			[
				-79.50717,
				44.05427
			],
			[
				-79.50787,
				44.05633
			],
			[
				-79.5082,
				44.05768
			],
			[
				-79.50832,
				44.05839
			],
			[
				-79.50849,
				44.06075
			],
			[
				-79.50869,
				44.06194
			],
			[
				-79.50919,
				44.06339
			],
			[
				-79.51002,
				44.06504
			],
			[
				-79.51048,
				44.06673
			],
			[
				-79.51049,
				44.06728
			],
			[
				-79.50973,
				44.06759
			],
			[
				-79.50717,
				44.06812
			],
			[
				-79.50143,
				44.06909
			],
			[
				-79.49941,
				44.06965
			],
			[
				-79.49758,
				44.07005
			],
			[
				-79.49705,
				44.07009
			],
			[
				-79.49549,
				44.07049
			],
			[
				-79.49473,
				44.07052
			],
			[
				-79.49384,
				44.07082
			],
			[
				-79.48838,
				44.072
			],
			[
				-79.48507,
				44.07287
			],
			[
				-79.48484,
				44.07314
			],
			[
				-79.48493,
				44.0739
			],
			[
				-79.48567,
				44.07691
			],
			[
				-79.48619,
				44.07863
			],
			[
				-79.48651,
				44.08013
			],
			[
				-79.48703,
				44.0815
			],
			[
				-79.48803,
				44.08292
			],
			[
				-79.4894,
				44.08413
			],
			[
				-79.49083,
				44.08499
			],
			[
				-79.49233,
				44.0857
			],
			[
				-79.49424,
				44.08634
			],
			[
				-79.5039,
				44.08997
			],
			[
				-79.50516,
				44.09053
			],
			[
				-79.5065,
				44.09137
			],
			[
				-79.50759,
				44.09232
			],
			[
				-79.50836,
				44.09318
			],
			[
				-79.51177,
				44.09717
			],
			[
				-79.51301,
				44.09851
			],
			[
				-79.51295,
				44.0985
			],
			[
				-79.5136,
				44.09929
			],
			[
				-79.51409,
				44.09976
			],
			[
				-79.51495,
				44.10089
			],
			[
				-79.51534,
				44.10119
			],
			[
				-79.51568,
				44.10173
			],
			[
				-79.5171,
				44.10325
			],
			[
				-79.51838,
				44.10478
			],
			[
				-79.52004,
				44.10592
			],
			[
				-79.52107,
				44.10642
			],
			[
				-79.52152,
				44.10675
			],
			[
				-79.5273,
				44.10843
			],
			[
				-79.52873,
				44.1087
			],
			[
				-79.52997,
				44.10909
			],
			[
				-79.53032,
				44.10927
			],
			[
				-79.53149,
				44.10947
			],
			[
				-79.53248,
				44.10983
			],
			[
				-79.53493,
				44.11046
			],
			[
				-79.53537,
				44.11047
			],
			[
				-79.53611,
				44.11077
			],
			[
				-79.53903,
				44.11157
			],
			[
				-79.54079,
				44.11203
			],
			[
				-79.54137,
				44.11211
			],
			[
				-79.54214,
				44.11241
			],
			[
				-79.54254,
				44.11244
			],
			[
				-79.54346,
				44.11275
			],
			[
				-79.54372,
				44.11272
			],
			[
				-79.54472,
				44.11306
			],
			[
				-79.54626,
				44.11344
			]
		],
		"dupe": false
	},
	{
		"id": 352679263,
		"name": "Evening Ride",
		"distance": 1791.6,
		"moving_time": 362,
		"elapsed_time": 362,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-23T18:24:13Z",
		"average_speed": 4.949,
		"max_speed": 11.8,
		"calories": 45.5,
		"points": [
			[
				-79.42347,
				43.65358
			],
			[
				-79.42355,
				43.65376
			],
			[
				-79.42253,
				43.65392
			],
			[
				-79.42244,
				43.65407
			],
			[
				-79.42255,
				43.6542
			],
			[
				-79.42327,
				43.65615
			],
			[
				-79.42348,
				43.65653
			],
			[
				-79.42385,
				43.65768
			],
			[
				-79.4255,
				43.66194
			],
			[
				-79.41859,
				43.66347
			],
			[
				-79.41726,
				43.66383
			],
			[
				-79.41721,
				43.66395
			]
		],
		"dupe": false
	},
	{
		"id": 352796815,
		"name": "Night Ride",
		"distance": 2591.9,
		"moving_time": 411,
		"elapsed_time": 411,
		"total_elevation_gain": 0,
		"start_date_local": "2015-07-23T22:43:29Z",
		"average_speed": 6.306,
		"max_speed": 11.2,
		"calories": 43.4,
		"points": [
			[
				-79.41247,
				43.66795
			],
			[
				-79.41182,
				43.66654
			],
			[
				-79.4116,
				43.6657
			],
			[
				-79.41104,
				43.66467
			],
			[
				-79.41085,
				43.66389
			],
			[
				-79.41107,
				43.66376
			],
			[
				-79.41547,
				43.66285
			],
			[
				-79.41555,
				43.66274
			],
			[
				-79.41466,
				43.66056
			],
			[
				-79.41478,
				43.66046
			],
			[
				-79.42431,
				43.65853
			],
			[
				-79.42421,
				43.65807
			],
			[
				-79.42324,
				43.65541
			],
			[
				-79.42294,
				43.65482
			],
			[
				-79.42279,
				43.65426
			],
			[
				-79.42242,
				43.65367
			],
			[
				-79.42241,
				43.65356
			],
			[
				-79.42254,
				43.65347
			],
			[
				-79.42307,
				43.65336
			]
		],
		"dupe": false
	},
	{
		"id": 353275357,
		"name": "Ride to WayHome Pt.2",
		"distance": 55542.4,
		"moving_time": 9378,
		"elapsed_time": 12997,
		"total_elevation_gain": 359.8,
		"start_date_local": "2015-07-24T14:18:34Z",
		"average_speed": 5.923,
		"max_speed": 17.5,
		"calories": 1204,
		"points": [
			[
				-79.60242,
				44.10873
			],
			[
				-79.60521,
				44.11864
			],
			[
				-79.60695,
				44.12638
			],
			[
				-79.60782,
				44.12936
			],
			[
				-79.60794,
				44.13017
			],
			[
				-79.60903,
				44.13432
			],
			[
				-79.60999,
				44.13913
			],
			[
				-79.61091,
				44.14288
			],
			[
				-79.61503,
				44.15792
			],
			[
				-79.61519,
				44.15815
			],
			[
				-79.61563,
				44.15942
			],
			[
				-79.61577,
				44.16101
			],
			[
				-79.61572,
				44.16141
			],
			[
				-79.6156,
				44.16147
			],
			[
				-79.6157,
				44.16285
			],
			[
				-79.61823,
				44.17219
			],
			[
				-79.61869,
				44.1749
			],
			[
				-79.62113,
				44.18438
			],
			[
				-79.62156,
				44.18669
			],
			[
				-79.62307,
				44.19226
			],
			[
				-79.62327,
				44.19271
			],
			[
				-79.6232,
				44.19278
			],
			[
				-79.62335,
				44.19289
			],
			[
				-79.62325,
				44.19296
			],
			[
				-79.62339,
				44.19305
			],
			[
				-79.62332,
				44.1932
			],
			[
				-79.62339,
				44.19323
			],
			[
				-79.62336,
				44.19333
			],
			[
				-79.62688,
				44.20689
			],
			[
				-79.62752,
				44.20849
			],
			[
				-79.62946,
				44.2123
			],
			[
				-79.63007,
				44.21401
			],
			[
				-79.63131,
				44.21884
			],
			[
				-79.63106,
				44.2189
			],
			[
				-79.63118,
				44.21888
			],
			[
				-79.63135,
				44.21901
			],
			[
				-79.63269,
				44.2243
			],
			[
				-79.63328,
				44.22593
			],
			[
				-79.63573,
				44.2355
			],
			[
				-79.63599,
				44.23657
			],
			[
				-79.6361,
				44.23773
			],
			[
				-79.63625,
				44.23844
			],
			[
				-79.6385,
				44.24741
			],
			[
				-79.63863,
				44.24762
			],
			[
				-79.63865,
				44.24804
			],
			[
				-79.63897,
				44.24891
			],
			[
				-79.6393,
				44.25129
			],
			[
				-79.64124,
				44.25904
			],
			[
				-79.64421,
				44.27024
			],
			[
				-79.65101,
				44.29472
			],
			[
				-79.65141,
				44.29665
			],
			[
				-79.6514,
				44.29865
			],
			[
				-79.65156,
				44.29963
			],
			[
				-79.65323,
				44.30598
			],
			[
				-79.65592,
				44.31567
			],
			[
				-79.65933,
				44.32878
			],
			[
				-79.66229,
				44.34058
			],
			[
				-79.6623,
				44.34097
			],
			[
				-79.66331,
				44.34464
			],
			[
				-79.66361,
				44.34606
			],
			[
				-79.66377,
				44.3464
			],
			[
				-79.66373,
				44.34767
			],
			[
				-79.6638,
				44.34842
			],
			[
				-79.66444,
				44.35075
			],
			[
				-79.66494,
				44.35306
			],
			[
				-79.6652,
				44.35368
			],
			[
				-79.66538,
				44.35463
			],
			[
				-79.66604,
				44.35705
			],
			[
				-79.6667,
				44.35905
			],
			[
				-79.66727,
				44.36023
			],
			[
				-79.66841,
				44.36428
			],
			[
				-79.66859,
				44.36531
			],
			[
				-79.66982,
				44.36957
			],
			[
				-79.66983,
				44.36984
			],
			[
				-79.66938,
				44.3698
			],
			[
				-79.66916,
				44.36985
			],
			[
				-79.66907,
				44.36996
			],
			[
				-79.66869,
				44.36987
			],
			[
				-79.66873,
				44.37009
			],
			[
				-79.66861,
				44.37066
			],
			[
				-79.66919,
				44.373
			],
			[
				-79.66938,
				44.373
			],
			[
				-79.6694,
				44.37291
			],
			[
				-79.67102,
				44.37272
			],
			[
				-79.67134,
				44.37362
			],
			[
				-79.6719,
				44.37394
			],
			[
				-79.67227,
				44.37398
			],
			[
				-79.6726,
				44.37416
			],
			[
				-79.67493,
				44.37411
			],
			[
				-79.67598,
				44.37416
			],
			[
				-79.67696,
				44.37401
			],
			[
				-79.67967,
				44.37419
			],
			[
				-79.68014,
				44.37388
			],
			[
				-79.68116,
				44.37399
			],
			[
				-79.68141,
				44.37426
			],
			[
				-79.68159,
				44.3743
			],
			[
				-79.68573,
				44.37433
			],
			[
				-79.68662,
				44.37448
			],
			[
				-79.68682,
				44.37437
			],
			[
				-79.68797,
				44.37488
			],
			[
				-79.6887,
				44.375
			],
			[
				-79.68929,
				44.37605
			],
			[
				-79.68932,
				44.37704
			],
			[
				-79.69001,
				44.37937
			],
			[
				-79.68947,
				44.37956
			],
			[
				-79.68973,
				44.37952
			],
			[
				-79.68967,
				44.37953
			],
			[
				-79.68981,
				44.37969
			],
			[
				-79.6899,
				44.3804
			],
			[
				-79.69027,
				44.3805
			],
			[
				-79.69028,
				44.38059
			],
			[
				-79.69031,
				44.38099
			],
			[
				-79.68973,
				44.38096
			],
			[
				-79.68972,
				44.38089
			],
			[
				-79.69015,
				44.38114
			],
			[
				-79.69006,
				44.38197
			],
			[
				-79.69021,
				44.38223
			],
			[
				-79.69043,
				44.38301
			],
			[
				-79.69035,
				44.38482
			],
			[
				-79.69054,
				44.38526
			],
			[
				-79.69045,
				44.38553
			],
			[
				-79.69017,
				44.3857
			],
			[
				-79.69011,
				44.38602
			],
			[
				-79.6901,
				44.38625
			],
			[
				-79.69031,
				44.38633
			],
			[
				-79.69035,
				44.38647
			],
			[
				-79.69002,
				44.38701
			],
			[
				-79.69003,
				44.38718
			],
			[
				-79.68968,
				44.38736
			],
			[
				-79.6898,
				44.38736
			],
			[
				-79.69026,
				44.38771
			],
			[
				-79.69042,
				44.3879
			],
			[
				-79.69044,
				44.38815
			],
			[
				-79.69055,
				44.38818
			],
			[
				-79.69093,
				44.38863
			],
			[
				-79.69118,
				44.38872
			],
			[
				-79.69112,
				44.38877
			],
			[
				-79.69138,
				44.38876
			],
			[
				-79.69228,
				44.38847
			],
			[
				-79.6925,
				44.38858
			],
			[
				-79.69277,
				44.38897
			],
			[
				-79.69265,
				44.38898
			],
			[
				-79.69268,
				44.3886
			],
			[
				-79.69249,
				44.38834
			],
			[
				-79.69234,
				44.3883
			],
			[
				-79.69044,
				44.38909
			],
			[
				-79.69009,
				44.38912
			],
			[
				-79.69003,
				44.389
			],
			[
				-79.68836,
				44.3893
			],
			[
				-79.68753,
				44.38925
			],
			[
				-79.68639,
				44.38931
			],
			[
				-79.68614,
				44.38922
			],
			[
				-79.68568,
				44.38924
			],
			[
				-79.68538,
				44.38934
			],
			[
				-79.6832,
				44.38931
			],
			[
				-79.68117,
				44.3894
			],
			[
				-79.68092,
				44.38934
			],
			[
				-79.68085,
				44.38918
			],
			[
				-79.68046,
				44.38927
			],
			[
				-79.67729,
				44.39086
			],
			[
				-79.677,
				44.39116
			],
			[
				-79.67612,
				44.39164
			],
			[
				-79.67351,
				44.39258
			],
			[
				-79.67345,
				44.39254
			],
			[
				-79.67245,
				44.39298
			],
			[
				-79.66837,
				44.39432
			],
			[
				-79.66637,
				44.3951
			],
			[
				-79.66575,
				44.39527
			],
			[
				-79.66523,
				44.3953
			],
			[
				-79.66347,
				44.3951
			],
			[
				-79.66226,
				44.39518
			],
			[
				-79.66076,
				44.39471
			],
			[
				-79.65868,
				44.39442
			],
			[
				-79.65717,
				44.39432
			],
			[
				-79.65709,
				44.39434
			],
			[
				-79.65657,
				44.39726
			],
			[
				-79.65629,
				44.39737
			],
			[
				-79.65652,
				44.39754
			],
			[
				-79.65646,
				44.39785
			],
			[
				-79.65652,
				44.39755
			],
			[
				-79.65672,
				44.39734
			],
			[
				-79.65704,
				44.39725
			],
			[
				-79.65719,
				44.39743
			],
			[
				-79.65717,
				44.39736
			],
			[
				-79.65693,
				44.39728
			],
			[
				-79.65683,
				44.39731
			],
			[
				-79.65684,
				44.39751
			],
			[
				-79.65722,
				44.39747
			],
			[
				-79.65689,
				44.39738
			],
			[
				-79.65688,
				44.39746
			],
			[
				-79.65702,
				44.39715
			],
			[
				-79.65706,
				44.39733
			],
			[
				-79.65717,
				44.39741
			],
			[
				-79.65709,
				44.39747
			],
			[
				-79.65671,
				44.39754
			],
			[
				-79.65659,
				44.3974
			],
			[
				-79.65668,
				44.39725
			],
			[
				-79.65661,
				44.39701
			],
			[
				-79.65709,
				44.39437
			],
			[
				-79.657,
				44.39433
			],
			[
				-79.6551,
				44.39405
			],
			[
				-79.6514,
				44.39376
			],
			[
				-79.64727,
				44.39332
			],
			[
				-79.64639,
				44.39326
			],
			[
				-79.64523,
				44.39332
			],
			[
				-79.64258,
				44.39438
			],
			[
				-79.64181,
				44.39483
			],
			[
				-79.64015,
				44.396
			],
			[
				-79.63895,
				44.39648
			],
			[
				-79.63656,
				44.39671
			],
			[
				-79.63403,
				44.3974
			],
			[
				-79.63368,
				44.39724
			],
			[
				-79.63318,
				44.39718
			],
			[
				-79.63055,
				44.39737
			],
			[
				-79.63042,
				44.39732
			],
			[
				-79.63001,
				44.39742
			],
			[
				-79.62736,
				44.39769
			],
			[
				-79.62541,
				44.39818
			],
			[
				-79.62318,
				44.39911
			],
			[
				-79.62273,
				44.39939
			],
			[
				-79.62252,
				44.40009
			],
			[
				-79.62219,
				44.40072
			],
			[
				-79.62228,
				44.40186
			],
			[
				-79.62217,
				44.40219
			],
			[
				-79.62201,
				44.40225
			],
			[
				-79.62214,
				44.40229
			],
			[
				-79.6222,
				44.40224
			],
			[
				-79.62226,
				44.40153
			],
			[
				-79.62263,
				44.39958
			],
			[
				-79.62286,
				44.39928
			],
			[
				-79.62329,
				44.39906
			],
			[
				-79.62297,
				44.39921
			],
			[
				-79.6216,
				44.39945
			],
			[
				-79.62122,
				44.39959
			],
			[
				-79.6208,
				44.39981
			],
			[
				-79.62063,
				44.40001
			],
			[
				-79.6204,
				44.39994
			],
			[
				-79.61865,
				44.40077
			],
			[
				-79.6183,
				44.40081
			],
			[
				-79.61822,
				44.40092
			],
			[
				-79.61818,
				44.40088
			],
			[
				-79.61821,
				44.40094
			],
			[
				-79.61805,
				44.40093
			],
			[
				-79.618,
				44.40116
			],
			[
				-79.61776,
				44.40134
			],
			[
				-79.61703,
				44.40144
			],
			[
				-79.61687,
				44.40164
			],
			[
				-79.61639,
				44.40166
			],
			[
				-79.61575,
				44.40207
			],
			[
				-79.61563,
				44.40203
			],
			[
				-79.61546,
				44.40217
			],
			[
				-79.61532,
				44.40212
			],
			[
				-79.61502,
				44.4022
			],
			[
				-79.61455,
				44.40252
			],
			[
				-79.61337,
				44.40308
			],
			[
				-79.61291,
				44.40318
			],
			[
				-79.61177,
				44.40365
			],
			[
				-79.61044,
				44.40431
			],
			[
				-79.60856,
				44.40509
			],
			[
				-79.60849,
				44.40502
			],
			[
				-79.60902,
				44.40244
			],
			[
				-79.60871,
				44.40236
			],
			[
				-79.60154,
				44.40627
			],
			[
				-79.59719,
				44.40849
			],
			[
				-79.59191,
				44.41015
			],
			[
				-79.58912,
				44.4109
			],
			[
				-79.58572,
				44.41223
			],
			[
				-79.58125,
				44.41302
			],
			[
				-79.57874,
				44.41382
			],
			[
				-79.57633,
				44.41472
			],
			[
				-79.57439,
				44.41511
			],
			[
				-79.57297,
				44.41566
			],
			[
				-79.57097,
				44.41706
			],
			[
				-79.56661,
				44.41959
			],
			[
				-79.56599,
				44.42012
			],
			[
				-79.56506,
				44.42117
			],
			[
				-79.56445,
				44.42166
			],
			[
				-79.56071,
				44.42291
			],
			[
				-79.56022,
				44.42331
			],
			[
				-79.5599,
				44.42375
			],
			[
				-79.55792,
				44.42851
			],
			[
				-79.55748,
				44.43008
			],
			[
				-79.55706,
				44.43077
			],
			[
				-79.5558,
				44.43172
			],
			[
				-79.55295,
				44.43333
			],
			[
				-79.55219,
				44.43385
			],
			[
				-79.55147,
				44.4342
			],
			[
				-79.54733,
				44.43587
			],
			[
				-79.54007,
				44.43902
			],
			[
				-79.53898,
				44.4397
			],
			[
				-79.53807,
				44.44048
			],
			[
				-79.5373,
				44.44144
			],
			[
				-79.53653,
				44.44309
			],
			[
				-79.53551,
				44.44475
			],
			[
				-79.53234,
				44.4481
			],
			[
				-79.53163,
				44.44893
			],
			[
				-79.53074,
				44.45021
			],
			[
				-79.53017,
				44.45083
			],
			[
				-79.52948,
				44.45135
			],
			[
				-79.52726,
				44.45263
			],
			[
				-79.52458,
				44.45518
			],
			[
				-79.52416,
				44.45549
			],
			[
				-79.52288,
				44.45628
			],
			[
				-79.52153,
				44.45697
			],
			[
				-79.51861,
				44.45884
			],
			[
				-79.51792,
				44.45937
			],
			[
				-79.51793,
				44.45962
			],
			[
				-79.51829,
				44.46007
			],
			[
				-79.52217,
				44.46457
			],
			[
				-79.52355,
				44.46633
			],
			[
				-79.52494,
				44.46784
			],
			[
				-79.52585,
				44.46908
			],
			[
				-79.52656,
				44.46982
			],
			[
				-79.52801,
				44.47159
			],
			[
				-79.5279,
				44.47158
			],
			[
				-79.52733,
				44.47189
			]
		],
		"dupe": false
	},
	{
		"id": 354654469,
		"name": "WayHome to Allandale Waterfront GO station",
		"distance": 20436.2,
		"moving_time": 3038,
		"elapsed_time": 3145,
		"total_elevation_gain": 26.5,
		"start_date_local": "2015-07-26T12:45:32Z",
		"average_speed": 6.727,
		"max_speed": 10.5,
		"calories": 404.5,
		"points": [
			[
				-79.52672,
				44.46992
			],
			[
				-79.5246,
				44.46735
			],
			[
				-79.52366,
				44.46636
			],
			[
				-79.52191,
				44.46415
			],
			[
				-79.51905,
				44.46078
			],
			[
				-79.519,
				44.46063
			],
			[
				-79.51795,
				44.45947
			],
			[
				-79.51827,
				44.45915
			],
			[
				-79.52099,
				44.4574
			],
			[
				-79.52185,
				44.45691
			],
			[
				-79.52296,
				44.45641
			],
			[
				-79.52429,
				44.45554
			],
			[
				-79.52723,
				44.4528
			],
			[
				-79.52984,
				44.45113
			],
			[
				-79.53031,
				44.45073
			],
			[
				-79.53201,
				44.4486
			],
			[
				-79.53541,
				44.44493
			],
			[
				-79.53647,
				44.44339
			],
			[
				-79.53762,
				44.44121
			],
			[
				-79.53814,
				44.44058
			],
			[
				-79.53895,
				44.43982
			],
			[
				-79.54051,
				44.43886
			],
			[
				-79.54291,
				44.43792
			],
			[
				-79.5455,
				44.43673
			],
			[
				-79.55214,
				44.43399
			],
			[
				-79.55629,
				44.43148
			],
			[
				-79.55698,
				44.43091
			],
			[
				-79.5576,
				44.42999
			],
			[
				-79.55828,
				44.4279
			],
			[
				-79.56,
				44.42377
			],
			[
				-79.56035,
				44.42326
			],
			[
				-79.56068,
				44.42298
			],
			[
				-79.56452,
				44.42171
			],
			[
				-79.56553,
				44.42091
			],
			[
				-79.5662,
				44.42009
			],
			[
				-79.56692,
				44.41945
			],
			[
				-79.56801,
				44.41876
			],
			[
				-79.56883,
				44.41838
			],
			[
				-79.57096,
				44.41715
			],
			[
				-79.57285,
				44.41586
			],
			[
				-79.57371,
				44.41544
			],
			[
				-79.5766,
				44.4147
			],
			[
				-79.57704,
				44.41449
			],
			[
				-79.57748,
				44.4144
			],
			[
				-79.57993,
				44.41351
			],
			[
				-79.5814,
				44.41307
			],
			[
				-79.58582,
				44.41231
			],
			[
				-79.5865,
				44.41211
			],
			[
				-79.58892,
				44.41109
			],
			[
				-79.5963,
				44.40882
			],
			[
				-79.59718,
				44.40862
			],
			[
				-79.60545,
				44.40431
			],
			[
				-79.60904,
				44.40221
			],
			[
				-79.61109,
				44.40121
			],
			[
				-79.6124,
				44.40081
			],
			[
				-79.61393,
				44.40052
			],
			[
				-79.61728,
				44.40018
			],
			[
				-79.62645,
				44.39718
			],
			[
				-79.62763,
				44.39691
			],
			[
				-79.62902,
				44.39693
			],
			[
				-79.63159,
				44.39755
			],
			[
				-79.63284,
				44.39767
			],
			[
				-79.63435,
				44.39742
			],
			[
				-79.63668,
				44.39682
			],
			[
				-79.63889,
				44.39662
			],
			[
				-79.64025,
				44.39607
			],
			[
				-79.64191,
				44.39487
			],
			[
				-79.64273,
				44.39439
			],
			[
				-79.6456,
				44.39329
			],
			[
				-79.6467,
				44.39328
			],
			[
				-79.64997,
				44.39372
			],
			[
				-79.65985,
				44.39461
			],
			[
				-79.66183,
				44.39503
			],
			[
				-79.66207,
				44.39518
			],
			[
				-79.66243,
				44.39525
			],
			[
				-79.66375,
				44.39521
			],
			[
				-79.66582,
				44.39535
			],
			[
				-79.67226,
				44.39312
			],
			[
				-79.67342,
				44.39281
			],
			[
				-79.67425,
				44.39243
			],
			[
				-79.67652,
				44.39168
			],
			[
				-79.67701,
				44.39143
			],
			[
				-79.67759,
				44.39073
			],
			[
				-79.67854,
				44.39043
			],
			[
				-79.68053,
				44.38943
			],
			[
				-79.68293,
				44.38943
			],
			[
				-79.68345,
				44.38934
			],
			[
				-79.68462,
				44.38942
			],
			[
				-79.68523,
				44.38935
			],
			[
				-79.68779,
				44.3894
			],
			[
				-79.68906,
				44.3893
			],
			[
				-79.68984,
				44.38934
			],
			[
				-79.68988,
				44.38926
			],
			[
				-79.69052,
				44.38922
			],
			[
				-79.69279,
				44.3882
			],
			[
				-79.69342,
				44.38784
			],
			[
				-79.69125,
				44.38532
			],
			[
				-79.69134,
				44.38542
			],
			[
				-79.69064,
				44.38541
			],
			[
				-79.69054,
				44.38305
			],
			[
				-79.69024,
				44.38029
			],
			[
				-79.6899,
				44.37889
			],
			[
				-79.68931,
				44.37702
			],
			[
				-79.6893,
				44.37609
			],
			[
				-79.68916,
				44.37562
			],
			[
				-79.68876,
				44.37487
			],
			[
				-79.68885,
				44.37446
			]
		],
		"dupe": false
	},
	{
		"id": 354772850,
		"name": "Union to Home",
		"distance": 5567.4,
		"moving_time": 1112,
		"elapsed_time": 1156,
		"total_elevation_gain": 10.7,
		"start_date_local": "2015-07-26T15:59:41Z",
		"average_speed": 5.007,
		"max_speed": 14,
		"calories": 106.9,
		"points": [
			[
				-79.38275,
				43.64495
			],
			[
				-79.38219,
				43.64478
			],
			[
				-79.38204,
				43.64481
			],
			[
				-79.38205,
				43.64496
			],
			[
				-79.38233,
				43.64535
			],
			[
				-79.38221,
				43.64564
			],
			[
				-79.38232,
				43.64587
			],
			[
				-79.38203,
				43.64631
			],
			[
				-79.38178,
				43.64633
			],
			[
				-79.38185,
				43.64635
			],
			[
				-79.38178,
				43.64642
			],
			[
				-79.38219,
				43.64645
			],
			[
				-79.38235,
				43.64682
			],
			[
				-79.38256,
				43.64699
			],
			[
				-79.38268,
				43.64722
			],
			[
				-79.3825,
				43.64719
			],
			[
				-79.38269,
				43.6473
			],
			[
				-79.38278,
				43.64746
			],
			[
				-79.38274,
				43.648
			],
			[
				-79.38294,
				43.64827
			],
			[
				-79.38331,
				43.64831
			],
			[
				-79.38352,
				43.64817
			],
			[
				-79.38326,
				43.64798
			],
			[
				-79.38313,
				43.648
			],
			[
				-79.38307,
				43.64816
			],
			[
				-79.38347,
				43.64813
			],
			[
				-79.3835,
				43.6482
			],
			[
				-79.38337,
				43.64845
			],
			[
				-79.38305,
				43.64862
			],
			[
				-79.38307,
				43.64869
			],
			[
				-79.38297,
				43.64876
			],
			[
				-79.38327,
				43.64877
			],
			[
				-79.38336,
				43.64896
			],
			[
				-79.38329,
				43.64913
			],
			[
				-79.38352,
				43.64922
			],
			[
				-79.38375,
				43.64954
			],
			[
				-79.38369,
				43.6497
			],
			[
				-79.38377,
				43.64967
			],
			[
				-79.38362,
				43.64986
			],
			[
				-79.3838,
				43.64986
			],
			[
				-79.38365,
				43.64999
			],
			[
				-79.38351,
				43.64996
			],
			[
				-79.38358,
				43.65007
			],
			[
				-79.38401,
				43.65009
			],
			[
				-79.38459,
				43.65071
			],
			[
				-79.38471,
				43.65053
			],
			[
				-79.38515,
				43.65043
			],
			[
				-79.38546,
				43.6502
			],
			[
				-79.38564,
				43.65036
			],
			[
				-79.38604,
				43.65051
			],
			[
				-79.38622,
				43.65042
			],
			[
				-79.38632,
				43.65024
			],
			[
				-79.38642,
				43.65029
			],
			[
				-79.38657,
				43.65002
			],
			[
				-79.38726,
				43.65013
			],
			[
				-79.38733,
				43.65009
			],
			[
				-79.3874,
				43.64974
			],
			[
				-79.38787,
				43.6496
			],
			[
				-79.38803,
				43.64945
			],
			[
				-79.38844,
				43.64935
			],
			[
				-79.38909,
				43.64954
			],
			[
				-79.38934,
				43.64942
			],
			[
				-79.38992,
				43.64952
			],
			[
				-79.38993,
				43.64943
			],
			[
				-79.39031,
				43.64925
			],
			[
				-79.39088,
				43.64918
			],
			[
				-79.39086,
				43.649
			],
			[
				-79.39126,
				43.64897
			],
			[
				-79.39156,
				43.64911
			],
			[
				-79.39171,
				43.64911
			],
			[
				-79.39184,
				43.649
			],
			[
				-79.39228,
				43.64902
			],
			[
				-79.3924,
				43.64885
			],
			[
				-79.3925,
				43.64888
			],
			[
				-79.39302,
				43.64861
			],
			[
				-79.39336,
				43.64833
			],
			[
				-79.39378,
				43.64833
			],
			[
				-79.39398,
				43.64823
			],
			[
				-79.39433,
				43.64829
			],
			[
				-79.3947,
				43.64823
			],
			[
				-79.39591,
				43.6478
			],
			[
				-79.39647,
				43.64793
			],
			[
				-79.3965,
				43.64787
			],
			[
				-79.39707,
				43.64787
			],
			[
				-79.39728,
				43.64776
			],
			[
				-79.39751,
				43.64779
			],
			[
				-79.39821,
				43.64764
			],
			[
				-79.3986,
				43.64771
			],
			[
				-79.39895,
				43.64758
			],
			[
				-79.39927,
				43.64759
			],
			[
				-79.39997,
				43.64732
			],
			[
				-79.4015,
				43.64709
			],
			[
				-79.40256,
				43.64676
			],
			[
				-79.40306,
				43.64684
			],
			[
				-79.40319,
				43.64671
			],
			[
				-79.40326,
				43.64675
			],
			[
				-79.40388,
				43.64651
			],
			[
				-79.4051,
				43.64632
			],
			[
				-79.40582,
				43.64608
			],
			[
				-79.4073,
				43.64583
			],
			[
				-79.40918,
				43.64537
			],
			[
				-79.4095,
				43.64535
			],
			[
				-79.40969,
				43.64548
			],
			[
				-79.4098,
				43.64584
			],
			[
				-79.40972,
				43.64587
			],
			[
				-79.40992,
				43.64604
			],
			[
				-79.41213,
				43.64556
			],
			[
				-79.41313,
				43.64549
			],
			[
				-79.41438,
				43.64513
			],
			[
				-79.41497,
				43.64507
			],
			[
				-79.41507,
				43.64512
			],
			[
				-79.41502,
				43.64533
			],
			[
				-79.41533,
				43.64567
			],
			[
				-79.41526,
				43.64567
			],
			[
				-79.41533,
				43.64601
			],
			[
				-79.41541,
				43.64606
			],
			[
				-79.41537,
				43.64613
			],
			[
				-79.41581,
				43.64689
			],
			[
				-79.41575,
				43.64698
			],
			[
				-79.4158,
				43.64725
			],
			[
				-79.41603,
				43.64758
			],
			[
				-79.41597,
				43.64775
			],
			[
				-79.41606,
				43.64807
			],
			[
				-79.41619,
				43.64837
			],
			[
				-79.41658,
				43.64882
			],
			[
				-79.41654,
				43.64904
			],
			[
				-79.41671,
				43.64947
			],
			[
				-79.41665,
				43.64954
			],
			[
				-79.41685,
				43.64979
			],
			[
				-79.41694,
				43.65005
			],
			[
				-79.41713,
				43.65011
			],
			[
				-79.41827,
				43.64992
			],
			[
				-79.41869,
				43.65104
			],
			[
				-79.41878,
				43.65108
			],
			[
				-79.41883,
				43.65136
			],
			[
				-79.41895,
				43.65142
			],
			[
				-79.4198,
				43.65136
			],
			[
				-79.42128,
				43.65103
			],
			[
				-79.42144,
				43.65174
			],
			[
				-79.42164,
				43.65204
			],
			[
				-79.42189,
				43.65289
			],
			[
				-79.42213,
				43.65334
			],
			[
				-79.42227,
				43.65346
			],
			[
				-79.42251,
				43.65347
			]
		],
		"dupe": false
	},
	{
		"id": 371533735,
		"name": "The Lakeview to King & Spadina",
		"distance": 2413.8,
		"moving_time": 463,
		"elapsed_time": 463,
		"total_elevation_gain": 0,
		"start_date_local": "2015-08-18T01:24:21Z",
		"average_speed": 5.213,
		"max_speed": 11.5,
		"calories": 48.2,
		"points": [
			[
				-79.41969,
				43.64379
			],
			[
				-79.41961,
				43.644
			],
			[
				-79.41901,
				43.64402
			],
			[
				-79.41871,
				43.64394
			],
			[
				-79.41819,
				43.64414
			],
			[
				-79.41801,
				43.64434
			],
			[
				-79.41785,
				43.64428
			],
			[
				-79.41533,
				43.6449
			],
			[
				-79.41396,
				43.64509
			],
			[
				-79.41225,
				43.6455
			],
			[
				-79.41081,
				43.64572
			],
			[
				-79.41088,
				43.64574
			],
			[
				-79.40998,
				43.64589
			],
			[
				-79.40961,
				43.6453
			],
			[
				-79.40379,
				43.64646
			],
			[
				-79.40345,
				43.64553
			],
			[
				-79.40331,
				43.64533
			],
			[
				-79.40295,
				43.64525
			],
			[
				-79.39785,
				43.64628
			],
			[
				-79.39723,
				43.6466
			],
			[
				-79.39669,
				43.6466
			],
			[
				-79.39569,
				43.64678
			],
			[
				-79.3956,
				43.64676
			],
			[
				-79.39553,
				43.64646
			],
			[
				-79.3952,
				43.64582
			],
			[
				-79.39494,
				43.64565
			],
			[
				-79.39497,
				43.64557
			],
			[
				-79.39473,
				43.6456
			],
			[
				-79.39468,
				43.64551
			],
			[
				-79.39474,
				43.64549
			]
		],
		"dupe": false
	},
	{
		"id": 371764242,
		"name": "Morning Ride",
		"distance": 2725.8,
		"moving_time": 689,
		"elapsed_time": 770,
		"total_elevation_gain": 0,
		"start_date_local": "2015-08-18T09:27:07Z",
		"average_speed": 3.956,
		"max_speed": 7.6,
		"calories": 47.3,
		"points": [
			[
				-79.395,
				43.64504
			],
			[
				-79.3948,
				43.6449
			],
			[
				-79.39483,
				43.64461
			],
			[
				-79.39465,
				43.64416
			],
			[
				-79.39471,
				43.64398
			],
			[
				-79.39865,
				43.64319
			],
			[
				-79.39907,
				43.64312
			],
			[
				-79.39953,
				43.64316
			],
			[
				-79.40019,
				43.64452
			],
			[
				-79.40117,
				43.64705
			],
			[
				-79.40131,
				43.64694
			],
			[
				-79.40375,
				43.64646
			],
			[
				-79.40455,
				43.64638
			],
			[
				-79.40527,
				43.64614
			],
			[
				-79.40549,
				43.64617
			],
			[
				-79.40619,
				43.64599
			],
			[
				-79.40628,
				43.64604
			],
			[
				-79.40949,
				43.64532
			],
			[
				-79.40974,
				43.64546
			],
			[
				-79.40994,
				43.64592
			],
			[
				-79.41015,
				43.64598
			],
			[
				-79.41183,
				43.64557
			],
			[
				-79.41252,
				43.64553
			],
			[
				-79.41359,
				43.64533
			],
			[
				-79.41574,
				43.64476
			],
			[
				-79.41625,
				43.64479
			],
			[
				-79.41817,
				43.64429
			],
			[
				-79.41919,
				43.64415
			],
			[
				-79.41968,
				43.644
			],
			[
				-79.42004,
				43.64404
			],
			[
				-79.42001,
				43.64389
			],
			[
				-79.41973,
				43.64389
			],
			[
				-79.41994,
				43.64387
			]
		],
		"dupe": false
	},
	{
		"id": 380403997,
		"name": "Ride to Leslie Spit and back",
		"distance": 33555.6,
		"moving_time": 5886,
		"elapsed_time": 10403,
		"total_elevation_gain": 31,
		"start_date_local": "2015-08-29T14:45:38Z",
		"average_speed": 5.701,
		"max_speed": 15.6,
		"calories": 625.5,
		"points": [
			[
				-79.42237,
				43.65347
			],
			[
				-79.42228,
				43.6534
			],
			[
				-79.4223,
				43.65327
			],
			[
				-79.42207,
				43.65268
			],
			[
				-79.42202,
				43.65234
			],
			[
				-79.42129,
				43.65074
			],
			[
				-79.4212,
				43.65025
			],
			[
				-79.42086,
				43.64954
			],
			[
				-79.42086,
				43.64923
			],
			[
				-79.42053,
				43.64871
			],
			[
				-79.42034,
				43.64798
			],
			[
				-79.41977,
				43.64667
			],
			[
				-79.41952,
				43.64578
			],
			[
				-79.41915,
				43.64502
			],
			[
				-79.41911,
				43.6446
			],
			[
				-79.41884,
				43.64405
			],
			[
				-79.41277,
				43.64539
			],
			[
				-79.41219,
				43.64546
			],
			[
				-79.41139,
				43.64571
			],
			[
				-79.41031,
				43.64582
			],
			[
				-79.40986,
				43.64594
			],
			[
				-79.40987,
				43.64577
			],
			[
				-79.40946,
				43.64505
			],
			[
				-79.4089,
				43.64516
			],
			[
				-79.40867,
				43.64548
			],
			[
				-79.40384,
				43.64644
			],
			[
				-79.4035,
				43.64584
			],
			[
				-79.40333,
				43.64532
			],
			[
				-79.40336,
				43.6452
			],
			[
				-79.40316,
				43.64501
			],
			[
				-79.40322,
				43.64513
			],
			[
				-79.40328,
				43.64504
			],
			[
				-79.40305,
				43.64522
			],
			[
				-79.3903,
				43.64782
			],
			[
				-79.37756,
				43.65059
			],
			[
				-79.37733,
				43.65084
			],
			[
				-79.37725,
				43.65066
			],
			[
				-79.37747,
				43.65061
			],
			[
				-79.37302,
				43.65159
			],
			[
				-79.37192,
				43.65148
			],
			[
				-79.37141,
				43.65153
			],
			[
				-79.36482,
				43.65296
			],
			[
				-79.36468,
				43.65282
			],
			[
				-79.3633,
				43.64976
			],
			[
				-79.36333,
				43.64984
			],
			[
				-79.36319,
				43.64979
			],
			[
				-79.36277,
				43.64988
			],
			[
				-79.36228,
				43.65011
			],
			[
				-79.36217,
				43.64999
			],
			[
				-79.36193,
				43.65009
			],
			[
				-79.36191,
				43.65024
			],
			[
				-79.35741,
				43.65131
			],
			[
				-79.35668,
				43.64977
			],
			[
				-79.35665,
				43.64951
			],
			[
				-79.35643,
				43.64919
			],
			[
				-79.35615,
				43.64893
			],
			[
				-79.35542,
				43.64849
			],
			[
				-79.35534,
				43.64834
			],
			[
				-79.35519,
				43.64836
			],
			[
				-79.35521,
				43.64826
			],
			[
				-79.35437,
				43.64771
			],
			[
				-79.35202,
				43.64479
			],
			[
				-79.35185,
				43.64474
			],
			[
				-79.35177,
				43.64464
			],
			[
				-79.35184,
				43.64456
			],
			[
				-79.34963,
				43.64187
			],
			[
				-79.34473,
				43.63722
			],
			[
				-79.34331,
				43.63766
			],
			[
				-79.3428,
				43.6384
			],
			[
				-79.34272,
				43.63866
			],
			[
				-79.34246,
				43.63886
			],
			[
				-79.34236,
				43.63908
			],
			[
				-79.34196,
				43.63948
			],
			[
				-79.34124,
				43.63962
			],
			[
				-79.34099,
				43.6396
			],
			[
				-79.34064,
				43.63974
			],
			[
				-79.34012,
				43.63975
			],
			[
				-79.33923,
				43.64031
			],
			[
				-79.33912,
				43.6405
			],
			[
				-79.33895,
				43.64056
			],
			[
				-79.33862,
				43.64091
			],
			[
				-79.33813,
				43.64119
			],
			[
				-79.33713,
				43.64216
			],
			[
				-79.33657,
				43.6424
			],
			[
				-79.33625,
				43.64284
			],
			[
				-79.33624,
				43.64299
			],
			[
				-79.33583,
				43.6432
			],
			[
				-79.33557,
				43.64323
			],
			[
				-79.33519,
				43.64306
			],
			[
				-79.33463,
				43.64312
			],
			[
				-79.33415,
				43.64346
			],
			[
				-79.33387,
				43.6438
			],
			[
				-79.33388,
				43.64454
			],
			[
				-79.33371,
				43.64505
			],
			[
				-79.33374,
				43.64518
			],
			[
				-79.33359,
				43.64542
			],
			[
				-79.3292,
				43.64789
			],
			[
				-79.32851,
				43.64818
			],
			[
				-79.32798,
				43.64776
			],
			[
				-79.32651,
				43.6471
			],
			[
				-79.32454,
				43.64718
			],
			[
				-79.32416,
				43.64712
			],
			[
				-79.32395,
				43.64688
			],
			[
				-79.32388,
				43.6465
			],
			[
				-79.32407,
				43.64606
			],
			[
				-79.32465,
				43.64555
			],
			[
				-79.32435,
				43.64543
			],
			[
				-79.32353,
				43.64541
			],
			[
				-79.32349,
				43.64548
			],
			[
				-79.3233,
				43.64536
			],
			[
				-79.32257,
				43.64517
			],
			[
				-79.32336,
				43.64529
			],
			[
				-79.32339,
				43.64541
			],
			[
				-79.3234,
				43.64532
			],
			[
				-79.32371,
				43.64551
			],
			[
				-79.3243,
				43.64555
			],
			[
				-79.32445,
				43.64564
			],
			[
				-79.32442,
				43.64578
			],
			[
				-79.32406,
				43.64596
			],
			[
				-79.32382,
				43.6463
			],
			[
				-79.3239,
				43.64637
			],
			[
				-79.32388,
				43.64671
			],
			[
				-79.32295,
				43.64687
			],
			[
				-79.32167,
				43.64647
			],
			[
				-79.32124,
				43.64648
			],
			[
				-79.32093,
				43.64628
			],
			[
				-79.32093,
				43.64613
			],
			[
				-79.32086,
				43.64614
			],
			[
				-79.32195,
				43.6419
			],
			[
				-79.32208,
				43.6404
			],
			[
				-79.32365,
				43.63493
			],
			[
				-79.32395,
				43.63418
			],
			[
				-79.32983,
				43.62752
			],
			[
				-79.33096,
				43.62668
			],
			[
				-79.33081,
				43.62647
			],
			[
				-79.33108,
				43.62602
			],
			[
				-79.33141,
				43.62579
			],
			[
				-79.33216,
				43.62579
			],
			[
				-79.3426,
				43.61781
			],
			[
				-79.34274,
				43.61764
			],
			[
				-79.34296,
				43.61579
			],
			[
				-79.34344,
				43.61468
			],
			[
				-79.34342,
				43.61454
			],
			[
				-79.34371,
				43.61365
			],
			[
				-79.34371,
				43.61332
			],
			[
				-79.34346,
				43.61316
			],
			[
				-79.34311,
				43.61317
			],
			[
				-79.34286,
				43.6135
			],
			[
				-79.34284,
				43.61366
			],
			[
				-79.34267,
				43.6137
			],
			[
				-79.34233,
				43.61444
			],
			[
				-79.34191,
				43.61496
			],
			[
				-79.34066,
				43.61596
			],
			[
				-79.34047,
				43.61632
			],
			[
				-79.3401,
				43.61674
			],
			[
				-79.33909,
				43.61751
			],
			[
				-79.33857,
				43.61824
			],
			[
				-79.338,
				43.61858
			],
			[
				-79.33749,
				43.61902
			],
			[
				-79.33621,
				43.61959
			],
			[
				-79.33486,
				43.61991
			],
			[
				-79.33456,
				43.62006
			],
			[
				-79.33331,
				43.62033
			],
			[
				-79.33238,
				43.62044
			],
			[
				-79.33197,
				43.62078
			],
			[
				-79.33124,
				43.62105
			],
			[
				-79.33077,
				43.62204
			],
			[
				-79.32992,
				43.62201
			],
			[
				-79.32671,
				43.62238
			],
			[
				-79.32458,
				43.62239
			],
			[
				-79.32416,
				43.62252
			],
			[
				-79.32405,
				43.62274
			],
			[
				-79.32349,
				43.6277
			],
			[
				-79.32346,
				43.62884
			],
			[
				-79.32337,
				43.62905
			],
			[
				-79.32311,
				43.63134
			],
			[
				-79.32275,
				43.63305
			],
			[
				-79.32281,
				43.63358
			],
			[
				-79.32296,
				43.63389
			],
			[
				-79.32377,
				43.63449
			],
			[
				-79.32207,
				43.64042
			],
			[
				-79.32197,
				43.64181
			],
			[
				-79.32088,
				43.64605
			],
			[
				-79.32078,
				43.64726
			],
			[
				-79.32105,
				43.64893
			],
			[
				-79.32147,
				43.65055
			],
			[
				-79.32786,
				43.65843
			],
			[
				-79.3278,
				43.65848
			],
			[
				-79.32793,
				43.65858
			],
			[
				-79.32794,
				43.6587
			],
			[
				-79.32743,
				43.65896
			],
			[
				-79.32743,
				43.6591
			],
			[
				-79.32757,
				43.65927
			],
			[
				-79.32799,
				43.65919
			],
			[
				-79.32852,
				43.6594
			],
			[
				-79.32885,
				43.66009
			],
			[
				-79.32945,
				43.65999
			],
			[
				-79.32944,
				43.65985
			],
			[
				-79.32928,
				43.65973
			],
			[
				-79.32872,
				43.65982
			],
			[
				-79.32867,
				43.65972
			],
			[
				-79.32873,
				43.65985
			],
			[
				-79.32867,
				43.65973
			],
			[
				-79.329,
				43.6599
			],
			[
				-79.32873,
				43.65965
			],
			[
				-79.3285,
				43.65927
			],
			[
				-79.32856,
				43.65922
			],
			[
				-79.32985,
				43.65892
			],
			[
				-79.32985,
				43.65877
			],
			[
				-79.33641,
				43.65566
			],
			[
				-79.34056,
				43.65389
			],
			[
				-79.34104,
				43.65384
			],
			[
				-79.34095,
				43.65385
			],
			[
				-79.34139,
				43.65359
			],
			[
				-79.34479,
				43.65215
			],
			[
				-79.34701,
				43.65131
			],
			[
				-79.34722,
				43.65136
			],
			[
				-79.34733,
				43.65114
			],
			[
				-79.3473,
				43.65142
			],
			[
				-79.34744,
				43.65155
			],
			[
				-79.34808,
				43.65143
			],
			[
				-79.34862,
				43.65147
			],
			[
				-79.34898,
				43.65137
			],
			[
				-79.34939,
				43.6511
			],
			[
				-79.34997,
				43.65086
			],
			[
				-79.3509,
				43.65106
			],
			[
				-79.3518,
				43.651
			],
			[
				-79.35269,
				43.65075
			],
			[
				-79.35293,
				43.65057
			],
			[
				-79.35336,
				43.65053
			],
			[
				-79.354,
				43.65025
			],
			[
				-79.35439,
				43.65019
			],
			[
				-79.35416,
				43.65001
			],
			[
				-79.35391,
				43.64963
			],
			[
				-79.35409,
				43.64907
			],
			[
				-79.35405,
				43.64872
			],
			[
				-79.35416,
				43.6486
			],
			[
				-79.35434,
				43.64858
			],
			[
				-79.35463,
				43.64857
			],
			[
				-79.35552,
				43.64879
			],
			[
				-79.35574,
				43.64905
			],
			[
				-79.35632,
				43.64948
			],
			[
				-79.35659,
				43.64955
			],
			[
				-79.35682,
				43.64982
			],
			[
				-79.35682,
				43.6502
			],
			[
				-79.35738,
				43.6513
			],
			[
				-79.35976,
				43.65074
			],
			[
				-79.35985,
				43.65092
			],
			[
				-79.36023,
				43.65078
			],
			[
				-79.36027,
				43.65062
			],
			[
				-79.36187,
				43.65025
			],
			[
				-79.3622,
				43.65031
			],
			[
				-79.36313,
				43.65249
			],
			[
				-79.36279,
				43.65253
			],
			[
				-79.36305,
				43.65281
			],
			[
				-79.36312,
				43.653
			],
			[
				-79.36334,
				43.65299
			],
			[
				-79.36705,
				43.66194
			],
			[
				-79.367,
				43.66215
			],
			[
				-79.36708,
				43.66235
			],
			[
				-79.36729,
				43.66257
			],
			[
				-79.36796,
				43.66424
			],
			[
				-79.37722,
				43.66231
			],
			[
				-79.37734,
				43.66238
			],
			[
				-79.37843,
				43.66216
			],
			[
				-79.37893,
				43.66201
			],
			[
				-79.37902,
				43.66192
			],
			[
				-79.37904,
				43.66172
			],
			[
				-79.37916,
				43.66185
			],
			[
				-79.37943,
				43.66193
			],
			[
				-79.38094,
				43.66151
			],
			[
				-79.38213,
				43.66141
			],
			[
				-79.38227,
				43.66137
			],
			[
				-79.38238,
				43.66116
			],
			[
				-79.38269,
				43.6612
			],
			[
				-79.38284,
				43.66127
			],
			[
				-79.38288,
				43.66138
			],
			[
				-79.38294,
				43.6613
			],
			[
				-79.38316,
				43.6614
			],
			[
				-79.38381,
				43.66116
			],
			[
				-79.38395,
				43.66124
			],
			[
				-79.38975,
				43.66003
			],
			[
				-79.39036,
				43.65999
			],
			[
				-79.39084,
				43.6598
			],
			[
				-79.39264,
				43.6595
			],
			[
				-79.39559,
				43.65881
			],
			[
				-79.39634,
				43.65874
			],
			[
				-79.39983,
				43.658
			],
			[
				-79.39997,
				43.65808
			],
			[
				-79.40041,
				43.65803
			],
			[
				-79.40189,
				43.65773
			],
			[
				-79.40238,
				43.65756
			],
			[
				-79.40284,
				43.65753
			],
			[
				-79.40333,
				43.65737
			],
			[
				-79.40491,
				43.65713
			],
			[
				-79.40557,
				43.65693
			],
			[
				-79.40728,
				43.65664
			],
			[
				-79.40753,
				43.65656
			],
			[
				-79.40766,
				43.65629
			],
			[
				-79.40766,
				43.65659
			],
			[
				-79.40857,
				43.65641
			],
			[
				-79.41142,
				43.65579
			],
			[
				-79.41188,
				43.65563
			],
			[
				-79.41265,
				43.65557
			],
			[
				-79.41297,
				43.65537
			],
			[
				-79.41347,
				43.65523
			],
			[
				-79.41441,
				43.65521
			],
			[
				-79.41534,
				43.65497
			],
			[
				-79.41704,
				43.65524
			],
			[
				-79.4183,
				43.65532
			],
			[
				-79.41913,
				43.65521
			],
			[
				-79.41906,
				43.65524
			],
			[
				-79.42234,
				43.65455
			]
		],
		"dupe": false
	},
	{
		"id": 379698032,
		"name": "Night Ride",
		"distance": 10886.8,
		"moving_time": 1937,
		"elapsed_time": 2000,
		"total_elevation_gain": 0,
		"start_date_local": "2015-08-28T21:10:37Z",
		"average_speed": 5.62,
		"max_speed": 16.7,
		"calories": 227.1,
		"points": [
			[
				-79.44159,
				43.63981
			],
			[
				-79.44167,
				43.63964
			],
			[
				-79.44179,
				43.63962
			],
			[
				-79.44154,
				43.63955
			],
			[
				-79.44094,
				43.6398
			],
			[
				-79.43875,
				43.64014
			],
			[
				-79.43628,
				43.64075
			],
			[
				-79.4361,
				43.64069
			],
			[
				-79.43504,
				43.64097
			],
			[
				-79.43456,
				43.64099
			],
			[
				-79.43286,
				43.6413
			],
			[
				-79.43178,
				43.64161
			],
			[
				-79.43009,
				43.64183
			],
			[
				-79.42906,
				43.64213
			],
			[
				-79.42837,
				43.64216
			],
			[
				-79.42855,
				43.64196
			],
			[
				-79.42741,
				43.6423
			],
			[
				-79.42714,
				43.64251
			],
			[
				-79.42642,
				43.64266
			],
			[
				-79.42578,
				43.64269
			],
			[
				-79.4132,
				43.64524
			],
			[
				-79.41214,
				43.64553
			],
			[
				-79.40997,
				43.64589
			],
			[
				-79.40824,
				43.64631
			],
			[
				-79.40786,
				43.64647
			],
			[
				-79.40422,
				43.64707
			],
			[
				-79.39912,
				43.6481
			],
			[
				-79.39876,
				43.64824
			],
			[
				-79.39661,
				43.64861
			],
			[
				-79.39344,
				43.64936
			],
			[
				-79.39302,
				43.64937
			],
			[
				-79.38913,
				43.65025
			],
			[
				-79.3875,
				43.65054
			],
			[
				-79.38712,
				43.65072
			],
			[
				-79.38642,
				43.65077
			],
			[
				-79.38446,
				43.65128
			],
			[
				-79.38276,
				43.6516
			],
			[
				-79.38224,
				43.65182
			],
			[
				-79.38058,
				43.65209
			],
			[
				-79.38058,
				43.65201
			],
			[
				-79.38023,
				43.65181
			],
			[
				-79.38008,
				43.65194
			],
			[
				-79.3801,
				43.65222
			],
			[
				-79.37928,
				43.65241
			],
			[
				-79.37943,
				43.65284
			],
			[
				-79.37933,
				43.65289
			],
			[
				-79.37965,
				43.6533
			],
			[
				-79.37962,
				43.65353
			],
			[
				-79.37992,
				43.65389
			],
			[
				-79.37943,
				43.65417
			],
			[
				-79.37881,
				43.65434
			],
			[
				-79.37831,
				43.65431
			],
			[
				-79.37814,
				43.6544
			],
			[
				-79.37788,
				43.6544
			],
			[
				-79.37762,
				43.65428
			],
			[
				-79.37728,
				43.65441
			],
			[
				-79.37682,
				43.6544
			],
			[
				-79.37638,
				43.65456
			],
			[
				-79.3763,
				43.65485
			],
			[
				-79.37011,
				43.65616
			],
			[
				-79.37099,
				43.65824
			],
			[
				-79.3698,
				43.65859
			],
			[
				-79.36951,
				43.6586
			],
			[
				-79.36801,
				43.65901
			],
			[
				-79.36699,
				43.65916
			],
			[
				-79.36633,
				43.65935
			],
			[
				-79.36453,
				43.65965
			],
			[
				-79.36319,
				43.66003
			],
			[
				-79.36187,
				43.66026
			],
			[
				-79.3609,
				43.66053
			],
			[
				-79.36057,
				43.66053
			],
			[
				-79.35661,
				43.66153
			],
			[
				-79.35356,
				43.66188
			],
			[
				-79.34916,
				43.66286
			],
			[
				-79.34863,
				43.66307
			],
			[
				-79.34699,
				43.66346
			],
			[
				-79.34387,
				43.66385
			],
			[
				-79.34319,
				43.66404
			],
			[
				-79.34149,
				43.6648
			],
			[
				-79.33966,
				43.66532
			],
			[
				-79.33763,
				43.6657
			],
			[
				-79.33382,
				43.66619
			],
			[
				-79.32891,
				43.66733
			],
			[
				-79.32792,
				43.66748
			],
			[
				-79.32511,
				43.66764
			],
			[
				-79.32455,
				43.66761
			],
			[
				-79.32435,
				43.66733
			],
			[
				-79.32416,
				43.6673
			],
			[
				-79.32406,
				43.66733
			],
			[
				-79.32423,
				43.66754
			],
			[
				-79.32415,
				43.66751
			],
			[
				-79.32418,
				43.66768
			]
		],
		"dupe": false
	},
	{
		"id": 382668928,
		"name": "Afternoon Ride",
		"distance": 1985.9,
		"moving_time": 438,
		"elapsed_time": 450,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-01T14:22:12Z",
		"average_speed": 4.534,
		"max_speed": 10.6,
		"calories": 36.4,
		"points": [
			[
				-79.42333,
				43.65341
			],
			[
				-79.4233,
				43.65332
			],
			[
				-79.42314,
				43.65331
			],
			[
				-79.42293,
				43.65345
			],
			[
				-79.42246,
				43.6534
			],
			[
				-79.42138,
				43.65096
			],
			[
				-79.42126,
				43.65054
			],
			[
				-79.42096,
				43.65001
			],
			[
				-79.42099,
				43.64989
			],
			[
				-79.42084,
				43.64953
			],
			[
				-79.42062,
				43.64924
			],
			[
				-79.41844,
				43.6498
			],
			[
				-79.41688,
				43.65007
			],
			[
				-79.41646,
				43.65024
			],
			[
				-79.414,
				43.65064
			],
			[
				-79.41136,
				43.65117
			],
			[
				-79.40993,
				43.65154
			],
			[
				-79.40792,
				43.65186
			],
			[
				-79.40666,
				43.65216
			],
			[
				-79.40664,
				43.65226
			],
			[
				-79.40609,
				43.65234
			],
			[
				-79.40601,
				43.65244
			],
			[
				-79.40603,
				43.6526
			],
			[
				-79.40578,
				43.65272
			],
			[
				-79.40578,
				43.6528
			],
			[
				-79.40566,
				43.65272
			],
			[
				-79.40574,
				43.65268
			],
			[
				-79.4058,
				43.65281
			],
			[
				-79.40585,
				43.65271
			]
		],
		"dupe": false
	},
	{
		"id": 385004454,
		"name": "Evening Ride",
		"distance": 2359.4,
		"moving_time": 338,
		"elapsed_time": 419,
		"total_elevation_gain": 10,
		"start_date_local": "2015-09-04T20:14:47Z",
		"average_speed": 6.98,
		"max_speed": 10.5,
		"calories": 37.3,
		"points": [
			[
				-79.42332,
				43.65386
			],
			[
				-79.42383,
				43.65393
			],
			[
				-79.4192,
				43.65515
			],
			[
				-79.4179,
				43.65527
			],
			[
				-79.41559,
				43.65491
			],
			[
				-79.41436,
				43.65509
			],
			[
				-79.40969,
				43.6561
			],
			[
				-79.40785,
				43.65639
			],
			[
				-79.40775,
				43.6565
			],
			[
				-79.40777,
				43.65668
			],
			[
				-79.40821,
				43.65793
			],
			[
				-79.40871,
				43.65896
			],
			[
				-79.40888,
				43.6597
			],
			[
				-79.40914,
				43.66013
			],
			[
				-79.40958,
				43.6613
			],
			[
				-79.4096,
				43.66158
			],
			[
				-79.40997,
				43.66224
			],
			[
				-79.41101,
				43.66485
			],
			[
				-79.41116,
				43.66504
			]
		],
		"dupe": false
	},
	{
		"id": 385675297,
		"name": "Don Valley to Bridle Path to Lawrence to Bathurst",
		"distance": 37098.9,
		"moving_time": 5410,
		"elapsed_time": 6032,
		"total_elevation_gain": 194,
		"start_date_local": "2015-09-05T15:25:51Z",
		"average_speed": 6.857,
		"max_speed": 19.1,
		"calories": 840.8,
		"points": [
			[
				-79.42264,
				43.65452
			],
			[
				-79.42412,
				43.65822
			],
			[
				-79.42417,
				43.65851
			],
			[
				-79.42406,
				43.65858
			],
			[
				-79.41523,
				43.66037
			],
			[
				-79.41515,
				43.66019
			],
			[
				-79.41493,
				43.66043
			],
			[
				-79.40855,
				43.6618
			],
			[
				-79.39911,
				43.66369
			],
			[
				-79.39802,
				43.66418
			],
			[
				-79.39444,
				43.66493
			],
			[
				-79.39383,
				43.66483
			],
			[
				-79.39361,
				43.66424
			],
			[
				-79.39332,
				43.66379
			],
			[
				-79.3926,
				43.66322
			],
			[
				-79.38095,
				43.66572
			],
			[
				-79.38295,
				43.67051
			],
			[
				-79.38268,
				43.67106
			],
			[
				-79.38246,
				43.67121
			],
			[
				-79.38271,
				43.67202
			],
			[
				-79.3828,
				43.67216
			],
			[
				-79.3836,
				43.67203
			],
			[
				-79.38417,
				43.67225
			],
			[
				-79.38475,
				43.67218
			],
			[
				-79.38483,
				43.67232
			],
			[
				-79.38487,
				43.67246
			],
			[
				-79.38466,
				43.67279
			],
			[
				-79.38415,
				43.67338
			],
			[
				-79.38371,
				43.67346
			],
			[
				-79.37976,
				43.67315
			],
			[
				-79.37615,
				43.67316
			],
			[
				-79.37033,
				43.67265
			],
			[
				-79.36913,
				43.67232
			],
			[
				-79.36798,
				43.67175
			],
			[
				-79.36601,
				43.67166
			],
			[
				-79.36142,
				43.67015
			],
			[
				-79.36075,
				43.67004
			],
			[
				-79.36031,
				43.67017
			],
			[
				-79.36023,
				43.67022
			],
			[
				-79.36029,
				43.67042
			],
			[
				-79.36233,
				43.67191
			],
			[
				-79.36343,
				43.67288
			],
			[
				-79.36392,
				43.67356
			],
			[
				-79.36485,
				43.67557
			],
			[
				-79.36541,
				43.67641
			],
			[
				-79.36599,
				43.67705
			],
			[
				-79.36652,
				43.67748
			],
			[
				-79.36643,
				43.67763
			],
			[
				-79.36699,
				43.67806
			],
			[
				-79.36746,
				43.67861
			],
			[
				-79.36751,
				43.67883
			],
			[
				-79.36723,
				43.67919
			],
			[
				-79.36744,
				43.67948
			],
			[
				-79.36807,
				43.67993
			],
			[
				-79.36821,
				43.68042
			],
			[
				-79.36818,
				43.68064
			],
			[
				-79.36803,
				43.68073
			],
			[
				-79.36781,
				43.68129
			],
			[
				-79.36691,
				43.68218
			],
			[
				-79.36512,
				43.68336
			],
			[
				-79.3644,
				43.68371
			],
			[
				-79.3636,
				43.68391
			],
			[
				-79.36312,
				43.68424
			],
			[
				-79.36268,
				43.68473
			],
			[
				-79.36242,
				43.68526
			],
			[
				-79.36241,
				43.68588
			],
			[
				-79.3625,
				43.6862
			],
			[
				-79.36344,
				43.68789
			],
			[
				-79.36312,
				43.68814
			],
			[
				-79.36306,
				43.68858
			],
			[
				-79.36292,
				43.68884
			],
			[
				-79.36261,
				43.68885
			],
			[
				-79.36238,
				43.68872
			],
			[
				-79.36202,
				43.68822
			],
			[
				-79.36184,
				43.68821
			],
			[
				-79.36164,
				43.68859
			],
			[
				-79.36143,
				43.68869
			],
			[
				-79.36135,
				43.68893
			],
			[
				-79.36112,
				43.68902
			],
			[
				-79.36111,
				43.68925
			],
			[
				-79.36101,
				43.68926
			],
			[
				-79.36085,
				43.6898
			],
			[
				-79.35994,
				43.69102
			],
			[
				-79.35975,
				43.69139
			],
			[
				-79.35963,
				43.69176
			],
			[
				-79.35977,
				43.69241
			],
			[
				-79.35961,
				43.69274
			],
			[
				-79.35862,
				43.6934
			],
			[
				-79.35834,
				43.694
			],
			[
				-79.35821,
				43.69411
			],
			[
				-79.35734,
				43.69449
			],
			[
				-79.3571,
				43.69484
			],
			[
				-79.35613,
				43.69533
			],
			[
				-79.35588,
				43.69599
			],
			[
				-79.35558,
				43.6965
			],
			[
				-79.3556,
				43.69694
			],
			[
				-79.3555,
				43.69716
			],
			[
				-79.35484,
				43.69773
			],
			[
				-79.35294,
				43.69803
			],
			[
				-79.35235,
				43.69795
			],
			[
				-79.35174,
				43.69829
			],
			[
				-79.3516,
				43.69821
			],
			[
				-79.35086,
				43.69833
			],
			[
				-79.35075,
				43.69829
			],
			[
				-79.34882,
				43.69857
			],
			[
				-79.3475,
				43.69843
			],
			[
				-79.34659,
				43.69858
			],
			[
				-79.3463,
				43.6983
			],
			[
				-79.34529,
				43.69825
			],
			[
				-79.34491,
				43.69812
			],
			[
				-79.34423,
				43.69808
			],
			[
				-79.34222,
				43.69869
			],
			[
				-79.342,
				43.69895
			],
			[
				-79.34082,
				43.69938
			],
			[
				-79.34016,
				43.69972
			],
			[
				-79.33992,
				43.69997
			],
			[
				-79.33963,
				43.70052
			],
			[
				-79.33934,
				43.70087
			],
			[
				-79.33801,
				43.70175
			],
			[
				-79.33707,
				43.70196
			],
			[
				-79.33603,
				43.70262
			],
			[
				-79.33505,
				43.70304
			],
			[
				-79.33465,
				43.70306
			],
			[
				-79.33454,
				43.70287
			],
			[
				-79.33429,
				43.70281
			],
			[
				-79.33374,
				43.7029
			],
			[
				-79.33352,
				43.70359
			],
			[
				-79.33355,
				43.70475
			],
			[
				-79.33332,
				43.70544
			],
			[
				-79.33335,
				43.70585
			],
			[
				-79.33397,
				43.7054
			],
			[
				-79.33455,
				43.70531
			],
			[
				-79.33482,
				43.70539
			],
			[
				-79.33554,
				43.7052
			],
			[
				-79.33589,
				43.70559
			],
			[
				-79.33663,
				43.70577
			],
			[
				-79.33701,
				43.70602
			],
			[
				-79.33703,
				43.70633
			],
			[
				-79.33684,
				43.70682
			],
			[
				-79.33687,
				43.70717
			],
			[
				-79.33629,
				43.70737
			],
			[
				-79.33608,
				43.70767
			],
			[
				-79.33633,
				43.70805
			],
			[
				-79.33683,
				43.70835
			],
			[
				-79.33699,
				43.70853
			],
			[
				-79.33723,
				43.70905
			],
			[
				-79.3374,
				43.70922
			],
			[
				-79.33747,
				43.70964
			],
			[
				-79.33786,
				43.71007
			],
			[
				-79.33812,
				43.71032
			],
			[
				-79.33885,
				43.71063
			],
			[
				-79.33912,
				43.71096
			],
			[
				-79.33929,
				43.71102
			],
			[
				-79.33912,
				43.71138
			],
			[
				-79.33927,
				43.71221
			],
			[
				-79.33913,
				43.71242
			],
			[
				-79.33913,
				43.71258
			],
			[
				-79.33925,
				43.7129
			],
			[
				-79.33954,
				43.71318
			],
			[
				-79.34028,
				43.7134
			],
			[
				-79.34201,
				43.71352
			],
			[
				-79.34244,
				43.71362
			],
			[
				-79.34367,
				43.71451
			],
			[
				-79.34426,
				43.71484
			],
			[
				-79.34467,
				43.71523
			],
			[
				-79.34475,
				43.7154
			],
			[
				-79.3451,
				43.71554
			],
			[
				-79.34588,
				43.71556
			],
			[
				-79.34766,
				43.7159
			],
			[
				-79.34788,
				43.71602
			],
			[
				-79.34846,
				43.7161
			],
			[
				-79.34877,
				43.71623
			],
			[
				-79.34881,
				43.71615
			],
			[
				-79.34882,
				43.71625
			],
			[
				-79.34906,
				43.71626
			],
			[
				-79.34918,
				43.71642
			],
			[
				-79.34948,
				43.7165
			],
			[
				-79.35139,
				43.71596
			],
			[
				-79.35184,
				43.71598
			],
			[
				-79.35233,
				43.71619
			],
			[
				-79.35247,
				43.71643
			],
			[
				-79.35249,
				43.71666
			],
			[
				-79.35235,
				43.71753
			],
			[
				-79.3525,
				43.71789
			],
			[
				-79.35253,
				43.7184
			],
			[
				-79.35283,
				43.71884
			],
			[
				-79.35254,
				43.7193
			],
			[
				-79.35239,
				43.71935
			],
			[
				-79.35234,
				43.71991
			],
			[
				-79.3521,
				43.72061
			],
			[
				-79.35216,
				43.72081
			],
			[
				-79.3525,
				43.72105
			],
			[
				-79.35278,
				43.72116
			],
			[
				-79.3531,
				43.72117
			],
			[
				-79.35362,
				43.72107
			],
			[
				-79.35406,
				43.72085
			],
			[
				-79.36148,
				43.72003
			],
			[
				-79.36189,
				43.72012
			],
			[
				-79.36335,
				43.72087
			],
			[
				-79.36443,
				43.72129
			],
			[
				-79.36461,
				43.72158
			],
			[
				-79.36473,
				43.72284
			],
			[
				-79.36472,
				43.72326
			],
			[
				-79.36442,
				43.72353
			],
			[
				-79.36459,
				43.72359
			],
			[
				-79.36455,
				43.72339
			],
			[
				-79.36443,
				43.7235
			],
			[
				-79.36388,
				43.72367
			],
			[
				-79.36304,
				43.72345
			],
			[
				-79.36198,
				43.72374
			],
			[
				-79.36137,
				43.72425
			],
			[
				-79.36121,
				43.72504
			],
			[
				-79.36101,
				43.7252
			],
			[
				-79.35798,
				43.7246
			],
			[
				-79.35378,
				43.72523
			],
			[
				-79.35359,
				43.72514
			],
			[
				-79.35355,
				43.72497
			],
			[
				-79.35366,
				43.72478
			],
			[
				-79.35354,
				43.72454
			],
			[
				-79.35359,
				43.72436
			],
			[
				-79.35349,
				43.72422
			],
			[
				-79.35314,
				43.72418
			],
			[
				-79.35304,
				43.72431
			],
			[
				-79.35316,
				43.72446
			],
			[
				-79.35325,
				43.72491
			],
			[
				-79.3534,
				43.72517
			],
			[
				-79.35397,
				43.72538
			],
			[
				-79.35401,
				43.7253
			],
			[
				-79.35431,
				43.72522
			],
			[
				-79.35455,
				43.72523
			],
			[
				-79.35439,
				43.7255
			],
			[
				-79.35432,
				43.72639
			],
			[
				-79.35455,
				43.72672
			],
			[
				-79.35522,
				43.72692
			],
			[
				-79.35527,
				43.72704
			],
			[
				-79.35516,
				43.72734
			],
			[
				-79.35525,
				43.72742
			],
			[
				-79.3602,
				43.72637
			],
			[
				-79.36045,
				43.72961
			],
			[
				-79.36207,
				43.73166
			],
			[
				-79.36501,
				43.73349
			],
			[
				-79.36831,
				43.73278
			],
			[
				-79.36842,
				43.73264
			],
			[
				-79.36816,
				43.73282
			],
			[
				-79.36504,
				43.7335
			],
			[
				-79.36675,
				43.73491
			],
			[
				-79.37013,
				43.73631
			],
			[
				-79.37177,
				43.73765
			],
			[
				-79.37269,
				43.73813
			],
			[
				-79.37396,
				43.7379
			],
			[
				-79.37633,
				43.73775
			],
			[
				-79.37986,
				43.73662
			],
			[
				-79.38192,
				43.73641
			],
			[
				-79.382,
				43.7362
			],
			[
				-79.38191,
				43.73568
			],
			[
				-79.38129,
				43.73303
			],
			[
				-79.38121,
				43.73176
			],
			[
				-79.38174,
				43.72905
			],
			[
				-79.38199,
				43.72854
			],
			[
				-79.38211,
				43.72802
			],
			[
				-79.38235,
				43.72781
			],
			[
				-79.38261,
				43.72772
			],
			[
				-79.384,
				43.72797
			],
			[
				-79.3859,
				43.72845
			],
			[
				-79.38654,
				43.72845
			],
			[
				-79.39232,
				43.72729
			],
			[
				-79.39395,
				43.72688
			],
			[
				-79.39587,
				43.72654
			],
			[
				-79.39617,
				43.72644
			],
			[
				-79.39612,
				43.72635
			],
			[
				-79.39618,
				43.72647
			],
			[
				-79.3962,
				43.72632
			],
			[
				-79.39649,
				43.72639
			],
			[
				-79.39822,
				43.72604
			],
			[
				-79.39922,
				43.72574
			],
			[
				-79.40002,
				43.72564
			],
			[
				-79.40123,
				43.7253
			],
			[
				-79.40486,
				43.72464
			],
			[
				-79.41381,
				43.72269
			],
			[
				-79.41506,
				43.72248
			],
			[
				-79.41887,
				43.72158
			],
			[
				-79.42008,
				43.72142
			],
			[
				-79.42248,
				43.72092
			],
			[
				-79.42553,
				43.72016
			],
			[
				-79.4261,
				43.72015
			],
			[
				-79.42674,
				43.71993
			],
			[
				-79.42687,
				43.71903
			],
			[
				-79.42628,
				43.71747
			],
			[
				-79.42922,
				43.71682
			],
			[
				-79.42932,
				43.71666
			],
			[
				-79.42906,
				43.71562
			],
			[
				-79.42885,
				43.71526
			],
			[
				-79.42871,
				43.7146
			],
			[
				-79.42872,
				43.71425
			],
			[
				-79.4285,
				43.71363
			],
			[
				-79.42851,
				43.71334
			],
			[
				-79.42805,
				43.71142
			],
			[
				-79.42789,
				43.71104
			],
			[
				-79.42752,
				43.70938
			],
			[
				-79.42735,
				43.709
			],
			[
				-79.42701,
				43.70766
			],
			[
				-79.42703,
				43.70741
			],
			[
				-79.42684,
				43.70688
			],
			[
				-79.42666,
				43.70583
			],
			[
				-79.42639,
				43.70521
			],
			[
				-79.42626,
				43.70444
			],
			[
				-79.42607,
				43.70393
			],
			[
				-79.42571,
				43.70217
			],
			[
				-79.42573,
				43.70145
			],
			[
				-79.42563,
				43.70099
			],
			[
				-79.42512,
				43.69974
			],
			[
				-79.42502,
				43.69929
			],
			[
				-79.42395,
				43.69692
			],
			[
				-79.42251,
				43.69318
			],
			[
				-79.4222,
				43.69268
			],
			[
				-79.42203,
				43.6921
			],
			[
				-79.42118,
				43.69031
			],
			[
				-79.42041,
				43.68805
			],
			[
				-79.42015,
				43.68761
			],
			[
				-79.42018,
				43.68749
			],
			[
				-79.41991,
				43.68695
			],
			[
				-79.41976,
				43.68633
			],
			[
				-79.41939,
				43.68572
			],
			[
				-79.41923,
				43.6851
			],
			[
				-79.41882,
				43.68423
			],
			[
				-79.41877,
				43.68393
			],
			[
				-79.41863,
				43.68377
			],
			[
				-79.41794,
				43.68188
			],
			[
				-79.41738,
				43.68078
			],
			[
				-79.41728,
				43.68019
			],
			[
				-79.41672,
				43.67872
			],
			[
				-79.41589,
				43.67694
			],
			[
				-79.41613,
				43.67677
			],
			[
				-79.41757,
				43.67679
			],
			[
				-79.41943,
				43.67664
			],
			[
				-79.42217,
				43.67586
			],
			[
				-79.42348,
				43.67558
			],
			[
				-79.42409,
				43.67535
			],
			[
				-79.42615,
				43.67498
			],
			[
				-79.42831,
				43.67477
			],
			[
				-79.42983,
				43.67473
			],
			[
				-79.4303,
				43.67482
			],
			[
				-79.431,
				43.67471
			],
			[
				-79.42983,
				43.67161
			],
			[
				-79.42925,
				43.67058
			],
			[
				-79.42861,
				43.66869
			],
			[
				-79.4276,
				43.66656
			],
			[
				-79.4273,
				43.66555
			],
			[
				-79.42638,
				43.66349
			],
			[
				-79.42604,
				43.66255
			],
			[
				-79.42576,
				43.66208
			],
			[
				-79.42465,
				43.65914
			],
			[
				-79.42444,
				43.65862
			],
			[
				-79.42433,
				43.65853
			],
			[
				-79.42368,
				43.6566
			],
			[
				-79.42296,
				43.65488
			],
			[
				-79.42288,
				43.65447
			]
		],
		"dupe": false
	},
	{
		"id": 385589421,
		"name": "Afternoon Ride",
		"distance": 2340.7,
		"moving_time": 424,
		"elapsed_time": 430,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-05T14:27:55Z",
		"average_speed": 5.521,
		"max_speed": 10.6,
		"calories": 41.1,
		"points": [
			[
				-79.44527,
				43.65521
			],
			[
				-79.44505,
				43.65466
			],
			[
				-79.44494,
				43.65461
			],
			[
				-79.44421,
				43.65279
			],
			[
				-79.44311,
				43.65099
			],
			[
				-79.44307,
				43.65067
			],
			[
				-79.44109,
				43.65022
			],
			[
				-79.44075,
				43.65035
			],
			[
				-79.43978,
				43.651
			],
			[
				-79.4388,
				43.65129
			],
			[
				-79.4365,
				43.65169
			],
			[
				-79.43075,
				43.65289
			],
			[
				-79.42989,
				43.65281
			],
			[
				-79.42642,
				43.65352
			],
			[
				-79.42647,
				43.65354
			],
			[
				-79.42634,
				43.65344
			],
			[
				-79.42599,
				43.65255
			],
			[
				-79.4256,
				43.65274
			],
			[
				-79.42416,
				43.65314
			],
			[
				-79.42387,
				43.65314
			],
			[
				-79.42341,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"id": 385487858,
		"name": "Lunch Ride",
		"distance": 2294.7,
		"moving_time": 333,
		"elapsed_time": 333,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-05T12:23:55Z",
		"average_speed": 6.891,
		"max_speed": 11.4,
		"calories": 55.2,
		"points": [
			[
				-79.42361,
				43.65414
			],
			[
				-79.42378,
				43.65401
			],
			[
				-79.42387,
				43.65408
			],
			[
				-79.42372,
				43.65412
			],
			[
				-79.42377,
				43.65405
			],
			[
				-79.42406,
				43.65408
			],
			[
				-79.42499,
				43.65396
			],
			[
				-79.42712,
				43.65349
			],
			[
				-79.42816,
				43.65342
			],
			[
				-79.42866,
				43.65322
			],
			[
				-79.42916,
				43.65319
			],
			[
				-79.43008,
				43.65296
			],
			[
				-79.43072,
				43.65302
			],
			[
				-79.4316,
				43.65274
			],
			[
				-79.43347,
				43.65249
			],
			[
				-79.43376,
				43.65238
			],
			[
				-79.43501,
				43.65223
			],
			[
				-79.43569,
				43.65206
			],
			[
				-79.43611,
				43.65205
			],
			[
				-79.43622,
				43.65191
			],
			[
				-79.43657,
				43.65183
			],
			[
				-79.44005,
				43.65115
			],
			[
				-79.44117,
				43.65046
			],
			[
				-79.44141,
				43.65044
			],
			[
				-79.44271,
				43.65074
			],
			[
				-79.44296,
				43.6509
			],
			[
				-79.44418,
				43.65274
			],
			[
				-79.44516,
				43.65516
			],
			[
				-79.44545,
				43.65521
			],
			[
				-79.44536,
				43.6554
			]
		],
		"dupe": false
	},
	{
		"id": 385820282,
		"name": "Night Ride",
		"distance": 3057.5,
		"moving_time": 489,
		"elapsed_time": 503,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-06T00:26:01Z",
		"average_speed": 6.253,
		"max_speed": 11.8,
		"calories": 67.4,
		"points": [
			[
				-79.39657,
				43.64866
			],
			[
				-79.39974,
				43.64812
			],
			[
				-79.40074,
				43.64781
			],
			[
				-79.40118,
				43.64786
			],
			[
				-79.40164,
				43.64778
			],
			[
				-79.40381,
				43.64727
			],
			[
				-79.4039,
				43.6473
			],
			[
				-79.4042,
				43.64783
			],
			[
				-79.40418,
				43.64795
			],
			[
				-79.4046,
				43.64886
			],
			[
				-79.40478,
				43.64946
			],
			[
				-79.40528,
				43.65051
			],
			[
				-79.40528,
				43.6508
			],
			[
				-79.40565,
				43.65165
			],
			[
				-79.40586,
				43.65243
			],
			[
				-79.40921,
				43.65168
			],
			[
				-79.4111,
				43.65136
			],
			[
				-79.41497,
				43.65052
			],
			[
				-79.41597,
				43.6504
			],
			[
				-79.41653,
				43.6502
			],
			[
				-79.41818,
				43.6499
			],
			[
				-79.41837,
				43.65004
			],
			[
				-79.41837,
				43.65018
			],
			[
				-79.41866,
				43.65078
			],
			[
				-79.41866,
				43.65106
			],
			[
				-79.41887,
				43.65142
			],
			[
				-79.42128,
				43.65098
			],
			[
				-79.42198,
				43.65255
			],
			[
				-79.42228,
				43.65345
			],
			[
				-79.42235,
				43.6535
			],
			[
				-79.42341,
				43.65329
			]
		],
		"dupe": false
	},
	{
		"id": 387125139,
		"name": "Afternoon Ride",
		"distance": 4272,
		"moving_time": 895,
		"elapsed_time": 1503,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-07T13:46:10Z",
		"average_speed": 4.773,
		"max_speed": 11.8,
		"calories": 74.5,
		"points": [
			[
				-79.42342,
				43.65311
			],
			[
				-79.42353,
				43.6533
			],
			[
				-79.42432,
				43.65318
			],
			[
				-79.42442,
				43.65307
			],
			[
				-79.42605,
				43.65272
			],
			[
				-79.42475,
				43.64945
			],
			[
				-79.42444,
				43.64938
			],
			[
				-79.4233,
				43.64936
			],
			[
				-79.42334,
				43.64933
			],
			[
				-79.42321,
				43.64924
			],
			[
				-79.42309,
				43.6493
			],
			[
				-79.42303,
				43.64903
			],
			[
				-79.42308,
				43.649
			],
			[
				-79.42289,
				43.64869
			],
			[
				-79.42289,
				43.64843
			],
			[
				-79.42063,
				43.64886
			],
			[
				-79.42044,
				43.64873
			],
			[
				-79.42018,
				43.64782
			],
			[
				-79.42001,
				43.64752
			],
			[
				-79.41998,
				43.64728
			],
			[
				-79.42007,
				43.64725
			],
			[
				-79.41999,
				43.64726
			],
			[
				-79.41995,
				43.64695
			],
			[
				-79.4197,
				43.6468
			],
			[
				-79.41961,
				43.64616
			],
			[
				-79.41915,
				43.64482
			],
			[
				-79.41912,
				43.64476
			],
			[
				-79.419,
				43.64483
			],
			[
				-79.41917,
				43.6448
			],
			[
				-79.41908,
				43.64483
			],
			[
				-79.41888,
				43.64431
			],
			[
				-79.41856,
				43.64414
			],
			[
				-79.41642,
				43.64464
			],
			[
				-79.41625,
				43.64452
			],
			[
				-79.41477,
				43.64069
			],
			[
				-79.41456,
				43.64062
			],
			[
				-79.41255,
				43.64055
			],
			[
				-79.41154,
				43.64073
			],
			[
				-79.41157,
				43.63993
			],
			[
				-79.41112,
				43.63951
			],
			[
				-79.41073,
				43.639
			],
			[
				-79.40962,
				43.63623
			],
			[
				-79.40694,
				43.63639
			],
			[
				-79.40673,
				43.63549
			],
			[
				-79.40632,
				43.63543
			],
			[
				-79.40276,
				43.63583
			],
			[
				-79.40143,
				43.63584
			],
			[
				-79.40057,
				43.63609
			],
			[
				-79.39968,
				43.63646
			],
			[
				-79.3995,
				43.63619
			],
			[
				-79.39882,
				43.63591
			],
			[
				-79.3988,
				43.63558
			],
			[
				-79.39857,
				43.63559
			]
		],
		"dupe": false
	},
	{
		"id": 387358510,
		"name": "Evening Ride",
		"distance": 3265.4,
		"moving_time": 749,
		"elapsed_time": 886,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-07T19:25:41Z",
		"average_speed": 4.36,
		"max_speed": 11.2,
		"calories": 54.8,
		"points": [
			[
				-79.39193,
				43.63744
			],
			[
				-79.39234,
				43.6375
			],
			[
				-79.39528,
				43.63713
			],
			[
				-79.39686,
				43.63665
			],
			[
				-79.39739,
				43.63633
			],
			[
				-79.39799,
				43.63576
			],
			[
				-79.3985,
				43.63566
			],
			[
				-79.39985,
				43.63501
			],
			[
				-79.3999,
				43.63498
			],
			[
				-79.39985,
				43.63485
			],
			[
				-79.40057,
				43.63447
			],
			[
				-79.40122,
				43.63428
			],
			[
				-79.40159,
				43.63429
			],
			[
				-79.40221,
				43.63486
			],
			[
				-79.40245,
				43.63526
			],
			[
				-79.40276,
				43.63532
			],
			[
				-79.40312,
				43.63526
			],
			[
				-79.40363,
				43.63504
			],
			[
				-79.40388,
				43.63446
			],
			[
				-79.40414,
				43.6342
			],
			[
				-79.40468,
				43.63401
			],
			[
				-79.40532,
				43.63398
			],
			[
				-79.40632,
				43.63409
			],
			[
				-79.40777,
				43.63453
			],
			[
				-79.40845,
				43.63444
			],
			[
				-79.40858,
				43.63453
			],
			[
				-79.40871,
				43.63449
			],
			[
				-79.40891,
				43.63467
			],
			[
				-79.40919,
				43.63511
			],
			[
				-79.41071,
				43.63896
			],
			[
				-79.41102,
				43.63938
			],
			[
				-79.41103,
				43.63927
			],
			[
				-79.41126,
				43.63919
			],
			[
				-79.41393,
				43.63869
			],
			[
				-79.41416,
				43.63915
			],
			[
				-79.41701,
				43.63894
			],
			[
				-79.41715,
				43.63914
			],
			[
				-79.41723,
				43.63957
			],
			[
				-79.41731,
				43.6396
			],
			[
				-79.41971,
				43.6397
			],
			[
				-79.42103,
				43.63947
			],
			[
				-79.42112,
				43.63964
			],
			[
				-79.42129,
				43.63967
			],
			[
				-79.42132,
				43.63975
			],
			[
				-79.42116,
				43.63994
			],
			[
				-79.4212,
				43.63982
			]
		],
		"dupe": false
	},
	{
		"id": 387388272,
		"name": "Night Ride",
		"distance": 2955.7,
		"moving_time": 508,
		"elapsed_time": 514,
		"total_elevation_gain": 16.2,
		"start_date_local": "2015-09-07T21:01:46Z",
		"average_speed": 5.818,
		"max_speed": 13.9,
		"calories": 61.6,
		"points": [
			[
				-79.42093,
				43.64012
			],
			[
				-79.42134,
				43.64025
			],
			[
				-79.42164,
				43.64023
			],
			[
				-79.42236,
				43.64001
			],
			[
				-79.42297,
				43.63997
			],
			[
				-79.42332,
				43.63985
			],
			[
				-79.4248,
				43.63963
			],
			[
				-79.42664,
				43.63915
			],
			[
				-79.42721,
				43.63907
			],
			[
				-79.42734,
				43.63912
			],
			[
				-79.42738,
				43.63944
			],
			[
				-79.42812,
				43.64099
			],
			[
				-79.42851,
				43.6422
			],
			[
				-79.42853,
				43.64214
			],
			[
				-79.42849,
				43.64263
			],
			[
				-79.42896,
				43.64306
			],
			[
				-79.42908,
				43.64381
			],
			[
				-79.42962,
				43.64497
			],
			[
				-79.42969,
				43.64532
			],
			[
				-79.43129,
				43.64953
			],
			[
				-79.43133,
				43.64948
			],
			[
				-79.43114,
				43.64959
			],
			[
				-79.42469,
				43.64939
			],
			[
				-79.42605,
				43.65271
			],
			[
				-79.42347,
				43.65328
			],
			[
				-79.42342,
				43.65337
			]
		],
		"dupe": false
	},
	{
		"id": 389054378,
		"name": "DUWEST ",
		"distance": 21811.7,
		"moving_time": 3585,
		"elapsed_time": 3835,
		"total_elevation_gain": 137.5,
		"start_date_local": "2015-09-10T06:11:56Z",
		"average_speed": 6.084,
		"max_speed": 12.5,
		"calories": 435.8,
		"points": [
			[
				-79.42411,
				43.65455
			],
			[
				-79.42493,
				43.65668
			],
			[
				-79.42736,
				43.65618
			],
			[
				-79.42746,
				43.65629
			],
			[
				-79.42931,
				43.66107
			],
			[
				-79.42963,
				43.66156
			],
			[
				-79.42964,
				43.66215
			],
			[
				-79.43037,
				43.66394
			],
			[
				-79.43034,
				43.66403
			],
			[
				-79.43052,
				43.66431
			],
			[
				-79.43466,
				43.67426
			],
			[
				-79.43491,
				43.67434
			],
			[
				-79.43762,
				43.67397
			],
			[
				-79.43996,
				43.67324
			],
			[
				-79.4406,
				43.67316
			],
			[
				-79.44377,
				43.6724
			],
			[
				-79.4443,
				43.67235
			],
			[
				-79.44505,
				43.67212
			],
			[
				-79.44823,
				43.67169
			],
			[
				-79.44991,
				43.67131
			],
			[
				-79.45115,
				43.6712
			],
			[
				-79.45156,
				43.67183
			],
			[
				-79.4525,
				43.67283
			],
			[
				-79.45437,
				43.67458
			],
			[
				-79.45527,
				43.67651
			],
			[
				-79.45575,
				43.67775
			],
			[
				-79.45603,
				43.67816
			],
			[
				-79.45607,
				43.67841
			],
			[
				-79.45636,
				43.679
			],
			[
				-79.45633,
				43.6791
			],
			[
				-79.45803,
				43.68308
			],
			[
				-79.45928,
				43.68336
			],
			[
				-79.45962,
				43.68353
			],
			[
				-79.46045,
				43.68351
			],
			[
				-79.46313,
				43.68282
			],
			[
				-79.46324,
				43.68287
			],
			[
				-79.46645,
				43.68213
			],
			[
				-79.46749,
				43.68197
			],
			[
				-79.46912,
				43.6824
			],
			[
				-79.47671,
				43.68175
			],
			[
				-79.47709,
				43.68189
			],
			[
				-79.47758,
				43.68236
			],
			[
				-79.47832,
				43.68281
			],
			[
				-79.47989,
				43.68336
			],
			[
				-79.48334,
				43.68399
			],
			[
				-79.48609,
				43.68541
			],
			[
				-79.48777,
				43.68609
			],
			[
				-79.4895,
				43.68695
			],
			[
				-79.48973,
				43.68696
			],
			[
				-79.49045,
				43.68692
			],
			[
				-79.49183,
				43.68647
			],
			[
				-79.49324,
				43.68617
			],
			[
				-79.49808,
				43.68468
			],
			[
				-79.49859,
				43.68461
			],
			[
				-79.49865,
				43.68454
			],
			[
				-79.4986,
				43.68432
			],
			[
				-79.50069,
				43.68397
			],
			[
				-79.50259,
				43.68393
			],
			[
				-79.50558,
				43.6845
			],
			[
				-79.5067,
				43.68455
			],
			[
				-79.50799,
				43.68443
			],
			[
				-79.51043,
				43.68402
			],
			[
				-79.51089,
				43.68393
			],
			[
				-79.51108,
				43.68381
			],
			[
				-79.51382,
				43.68349
			],
			[
				-79.51569,
				43.68338
			],
			[
				-79.51881,
				43.68333
			],
			[
				-79.52227,
				43.68304
			],
			[
				-79.52318,
				43.68284
			],
			[
				-79.52327,
				43.68293
			],
			[
				-79.52759,
				43.68198
			],
			[
				-79.52909,
				43.68173
			],
			[
				-79.52917,
				43.68155
			],
			[
				-79.52954,
				43.68138
			],
			[
				-79.53002,
				43.68133
			],
			[
				-79.53078,
				43.68143
			],
			[
				-79.53963,
				43.67965
			],
			[
				-79.54164,
				43.6791
			],
			[
				-79.54306,
				43.67889
			],
			[
				-79.54317,
				43.6788
			],
			[
				-79.54603,
				43.67824
			],
			[
				-79.54872,
				43.67755
			],
			[
				-79.54919,
				43.67736
			],
			[
				-79.5499,
				43.67728
			],
			[
				-79.55035,
				43.67714
			],
			[
				-79.55074,
				43.67714
			],
			[
				-79.55166,
				43.67676
			],
			[
				-79.55399,
				43.67636
			],
			[
				-79.55479,
				43.67607
			],
			[
				-79.55993,
				43.67497
			],
			[
				-79.56162,
				43.67445
			],
			[
				-79.56239,
				43.67432
			],
			[
				-79.56322,
				43.6743
			],
			[
				-79.56489,
				43.67398
			],
			[
				-79.56548,
				43.67396
			],
			[
				-79.56722,
				43.6736
			],
			[
				-79.56849,
				43.6732
			],
			[
				-79.56875,
				43.67303
			],
			[
				-79.56891,
				43.67279
			],
			[
				-79.5707,
				43.67194
			],
			[
				-79.57152,
				43.67169
			],
			[
				-79.57153,
				43.67157
			],
			[
				-79.57165,
				43.67153
			],
			[
				-79.57299,
				43.67129
			],
			[
				-79.57311,
				43.6714
			],
			[
				-79.57757,
				43.67065
			],
			[
				-79.58089,
				43.67039
			],
			[
				-79.58408,
				43.66966
			],
			[
				-79.58501,
				43.66927
			],
			[
				-79.58571,
				43.6687
			],
			[
				-79.58614,
				43.66813
			],
			[
				-79.5863,
				43.66736
			],
			[
				-79.58692,
				43.66655
			],
			[
				-79.58713,
				43.66611
			],
			[
				-79.58724,
				43.66608
			],
			[
				-79.5894,
				43.66665
			],
			[
				-79.58982,
				43.6669
			],
			[
				-79.59024,
				43.66728
			],
			[
				-79.5929,
				43.6715
			],
			[
				-79.59321,
				43.67223
			],
			[
				-79.59343,
				43.67261
			],
			[
				-79.59357,
				43.6727
			],
			[
				-79.59449,
				43.67431
			],
			[
				-79.59464,
				43.67479
			],
			[
				-79.59459,
				43.67532
			],
			[
				-79.59417,
				43.67598
			],
			[
				-79.59345,
				43.67637
			],
			[
				-79.59323,
				43.67675
			],
			[
				-79.59331,
				43.67802
			],
			[
				-79.59342,
				43.67826
			],
			[
				-79.59355,
				43.67929
			],
			[
				-79.59382,
				43.68007
			],
			[
				-79.5943,
				43.68084
			],
			[
				-79.59557,
				43.68209
			],
			[
				-79.59644,
				43.68257
			],
			[
				-79.59748,
				43.68295
			],
			[
				-79.59774,
				43.68296
			],
			[
				-79.59988,
				43.68382
			],
			[
				-79.60213,
				43.6846
			],
			[
				-79.60235,
				43.68449
			],
			[
				-79.60299,
				43.68457
			],
			[
				-79.60367,
				43.68439
			],
			[
				-79.60516,
				43.68379
			],
			[
				-79.60525,
				43.68358
			],
			[
				-79.6052,
				43.68352
			],
			[
				-79.60491,
				43.68344
			],
			[
				-79.60471,
				43.6835
			],
			[
				-79.60285,
				43.68446
			],
			[
				-79.60199,
				43.68499
			],
			[
				-79.60193,
				43.6851
			],
			[
				-79.60198,
				43.68536
			],
			[
				-79.60216,
				43.68565
			],
			[
				-79.60249,
				43.68591
			],
			[
				-79.60309,
				43.68595
			],
			[
				-79.60451,
				43.68581
			],
			[
				-79.60566,
				43.6856
			],
			[
				-79.60741,
				43.68542
			],
			[
				-79.61169,
				43.68537
			],
			[
				-79.61352,
				43.68575
			],
			[
				-79.62013,
				43.68791
			],
			[
				-79.62078,
				43.688
			]
		],
		"dupe": false
	},
	{
		"id": 393836107,
		"name": "Afternoon Ride",
		"distance": 20500.6,
		"moving_time": 3086,
		"elapsed_time": 3230,
		"total_elevation_gain": 189.7,
		"start_date_local": "2015-09-16T17:13:22Z",
		"average_speed": 6.643,
		"max_speed": 14.2,
		"calories": 489.4,
		"points": [
			[
				-122.79021,
				49.04677
			],
			[
				-122.79022,
				49.0491
			],
			[
				-122.79035,
				49.0494
			],
			[
				-122.79308,
				49.05188
			],
			[
				-122.79352,
				49.0524
			],
			[
				-122.79409,
				49.05279
			],
			[
				-122.79477,
				49.0528
			],
			[
				-122.79476,
				49.05298
			],
			[
				-122.79369,
				49.05254
			],
			[
				-122.79324,
				49.05278
			],
			[
				-122.7933,
				49.05294
			],
			[
				-122.79306,
				49.05279
			],
			[
				-122.79276,
				49.0529
			],
			[
				-122.79284,
				49.05305
			],
			[
				-122.79289,
				49.05311
			],
			[
				-122.79308,
				49.05309
			],
			[
				-122.80054,
				49.05855
			],
			[
				-122.80071,
				49.05887
			],
			[
				-122.80071,
				49.05954
			],
			[
				-122.80047,
				49.05989
			],
			[
				-122.79997,
				49.06007
			],
			[
				-122.79845,
				49.06015
			],
			[
				-122.79755,
				49.06056
			],
			[
				-122.79654,
				49.06016
			],
			[
				-122.79558,
				49.06005
			],
			[
				-122.78787,
				49.06013
			],
			[
				-122.77621,
				49.06012
			],
			[
				-122.75695,
				49.06031
			],
			[
				-122.7568,
				49.0749
			],
			[
				-122.74023,
				49.07464
			],
			[
				-122.73929,
				49.07468
			],
			[
				-122.73776,
				49.07459
			],
			[
				-122.73684,
				49.07465
			],
			[
				-122.73587,
				49.0746
			],
			[
				-122.73571,
				49.07444
			],
			[
				-122.73562,
				49.07406
			],
			[
				-122.7357,
				49.07324
			],
			[
				-122.73563,
				49.07309
			],
			[
				-122.73573,
				49.07251
			],
			[
				-122.73572,
				49.07222
			],
			[
				-122.73561,
				49.07209
			],
			[
				-122.73567,
				49.07121
			],
			[
				-122.73557,
				49.07002
			],
			[
				-122.73577,
				49.06895
			],
			[
				-122.7357,
				49.06876
			],
			[
				-122.73587,
				49.06849
			],
			[
				-122.73572,
				49.06835
			],
			[
				-122.73571,
				49.06799
			],
			[
				-122.73586,
				49.06754
			],
			[
				-122.73576,
				49.06734
			],
			[
				-122.7359,
				49.06681
			],
			[
				-122.73578,
				49.06675
			],
			[
				-122.73574,
				49.06662
			],
			[
				-122.73583,
				49.06613
			],
			[
				-122.73567,
				49.06606
			],
			[
				-122.73573,
				49.06572
			],
			[
				-122.73564,
				49.06556
			],
			[
				-122.73575,
				49.0646
			],
			[
				-122.73561,
				49.06407
			],
			[
				-122.73571,
				49.06293
			],
			[
				-122.73565,
				49.06285
			],
			[
				-122.73575,
				49.06256
			],
			[
				-122.73558,
				49.06241
			],
			[
				-122.73553,
				49.06158
			],
			[
				-122.73554,
				49.06076
			],
			[
				-122.73569,
				49.06036
			],
			[
				-122.73547,
				49.05985
			],
			[
				-122.73555,
				49.05938
			],
			[
				-122.73566,
				49.05922
			],
			[
				-122.73542,
				49.05898
			],
			[
				-122.73555,
				49.05805
			],
			[
				-122.73545,
				49.05782
			],
			[
				-122.73549,
				49.05749
			],
			[
				-122.73541,
				49.05718
			],
			[
				-122.73548,
				49.05697
			],
			[
				-122.73533,
				49.05586
			],
			[
				-122.73544,
				49.05554
			],
			[
				-122.7354,
				49.05512
			],
			[
				-122.73552,
				49.05425
			],
			[
				-122.73541,
				49.05366
			],
			[
				-122.73548,
				49.05312
			],
			[
				-122.73541,
				49.05267
			],
			[
				-122.7355,
				49.05251
			],
			[
				-122.73542,
				49.0524
			],
			[
				-122.73555,
				49.05153
			],
			[
				-122.7353,
				49.04915
			],
			[
				-122.73543,
				49.04827
			],
			[
				-122.73535,
				49.04745
			],
			[
				-122.73546,
				49.04676
			],
			[
				-122.73539,
				49.04609
			],
			[
				-122.73547,
				49.04577
			],
			[
				-122.73747,
				49.04578
			],
			[
				-122.73934,
				49.04589
			],
			[
				-122.74373,
				49.04573
			],
			[
				-122.74523,
				49.04582
			],
			[
				-122.74616,
				49.04578
			],
			[
				-122.74621,
				49.04585
			],
			[
				-122.74895,
				49.04576
			],
			[
				-122.75028,
				49.04581
			],
			[
				-122.75049,
				49.04566
			],
			[
				-122.75637,
				49.04566
			],
			[
				-122.75708,
				49.04577
			],
			[
				-122.75754,
				49.04565
			],
			[
				-122.77542,
				49.04554
			],
			[
				-122.77694,
				49.0456
			],
			[
				-122.78745,
				49.04555
			],
			[
				-122.79355,
				49.04564
			],
			[
				-122.79383,
				49.04577
			],
			[
				-122.79421,
				49.04562
			],
			[
				-122.80109,
				49.04563
			],
			[
				-122.80127,
				49.04572
			],
			[
				-122.8012,
				49.04565
			],
			[
				-122.8044,
				49.04562
			],
			[
				-122.80461,
				49.0457
			],
			[
				-122.80444,
				49.04575
			],
			[
				-122.80444,
				49.04561
			],
			[
				-122.8042,
				49.04588
			],
			[
				-122.80428,
				49.04617
			],
			[
				-122.80458,
				49.04657
			],
			[
				-122.80469,
				49.04698
			],
			[
				-122.80477,
				49.04712
			],
			[
				-122.80535,
				49.0474
			],
			[
				-122.80563,
				49.04799
			],
			[
				-122.80605,
				49.04849
			],
			[
				-122.80646,
				49.04872
			],
			[
				-122.80696,
				49.04885
			],
			[
				-122.8072,
				49.04903
			],
			[
				-122.80799,
				49.0493
			],
			[
				-122.80802,
				49.04925
			],
			[
				-122.80836,
				49.04947
			],
			[
				-122.80873,
				49.04994
			],
			[
				-122.80913,
				49.05028
			],
			[
				-122.80944,
				49.05091
			],
			[
				-122.81013,
				49.05136
			],
			[
				-122.81062,
				49.05203
			],
			[
				-122.81121,
				49.05226
			],
			[
				-122.81152,
				49.05228
			],
			[
				-122.81163,
				49.0524
			],
			[
				-122.81271,
				49.0525
			],
			[
				-122.8128,
				49.05259
			],
			[
				-122.81261,
				49.05256
			],
			[
				-122.81227,
				49.05266
			],
			[
				-122.81225,
				49.05285
			],
			[
				-122.81188,
				49.05284
			],
			[
				-122.8118,
				49.05292
			],
			[
				-122.80679,
				49.0529
			],
			[
				-122.80608,
				49.05302
			],
			[
				-122.80597,
				49.05293
			],
			[
				-122.80571,
				49.05304
			],
			[
				-122.8057,
				49.05316
			],
			[
				-122.8019,
				49.05513
			],
			[
				-122.80146,
				49.05517
			],
			[
				-122.80143,
				49.0551
			],
			[
				-122.80137,
				49.05517
			],
			[
				-122.80133,
				49.05351
			],
			[
				-122.80121,
				49.05286
			],
			[
				-122.79402,
				49.05274
			],
			[
				-122.79032,
				49.04935
			],
			[
				-122.79022,
				49.04909
			],
			[
				-122.7902,
				49.04712
			]
		],
		"dupe": false
	},
	{
		"id": 394065953,
		"name": "YYZ -> DuWest",
		"distance": 21405.1,
		"moving_time": 3382,
		"elapsed_time": 3960,
		"total_elevation_gain": 26.3,
		"start_date_local": "2015-09-17T07:38:48Z",
		"average_speed": 6.329,
		"max_speed": 17.6,
		"calories": 344.1,
		"points": [
			[
				-79.6212,
				43.68902
			],
			[
				-79.61615,
				43.68754
			],
			[
				-79.61139,
				43.68604
			],
			[
				-79.60985,
				43.6858
			],
			[
				-79.60841,
				43.68572
			],
			[
				-79.60708,
				43.68579
			],
			[
				-79.60026,
				43.68636
			],
			[
				-79.5993,
				43.68647
			],
			[
				-79.59904,
				43.6866
			],
			[
				-79.598,
				43.68659
			],
			[
				-79.59798,
				43.68675
			],
			[
				-79.59771,
				43.6866
			],
			[
				-79.59279,
				43.68668
			],
			[
				-79.59127,
				43.68686
			],
			[
				-79.58687,
				43.6878
			],
			[
				-79.5829,
				43.68868
			],
			[
				-79.57915,
				43.68965
			],
			[
				-79.57884,
				43.68964
			],
			[
				-79.57868,
				43.68978
			],
			[
				-79.57786,
				43.6899
			],
			[
				-79.57692,
				43.69019
			],
			[
				-79.57415,
				43.69082
			],
			[
				-79.56889,
				43.69194
			],
			[
				-79.56613,
				43.69244
			],
			[
				-79.5641,
				43.69297
			],
			[
				-79.55882,
				43.69416
			],
			[
				-79.55859,
				43.69406
			],
			[
				-79.55805,
				43.69298
			],
			[
				-79.55736,
				43.69124
			],
			[
				-79.5568,
				43.69018
			],
			[
				-79.55473,
				43.68537
			],
			[
				-79.55308,
				43.68184
			],
			[
				-79.55292,
				43.68181
			],
			[
				-79.55301,
				43.6817
			],
			[
				-79.55249,
				43.68045
			],
			[
				-79.55091,
				43.67721
			],
			[
				-79.55046,
				43.67723
			],
			[
				-79.54546,
				43.67839
			],
			[
				-79.54454,
				43.67852
			],
			[
				-79.54146,
				43.67916
			],
			[
				-79.53987,
				43.67958
			],
			[
				-79.53085,
				43.68142
			],
			[
				-79.52977,
				43.68133
			],
			[
				-79.52792,
				43.68167
			],
			[
				-79.52746,
				43.68189
			],
			[
				-79.52744,
				43.68201
			],
			[
				-79.52663,
				43.68219
			],
			[
				-79.52654,
				43.68214
			],
			[
				-79.52631,
				43.68242
			],
			[
				-79.52575,
				43.68239
			],
			[
				-79.52309,
				43.68296
			],
			[
				-79.52232,
				43.68308
			],
			[
				-79.5222,
				43.68303
			],
			[
				-79.52087,
				43.68315
			],
			[
				-79.52074,
				43.68326
			],
			[
				-79.514,
				43.68347
			],
			[
				-79.51103,
				43.68393
			],
			[
				-79.51111,
				43.68374
			],
			[
				-79.50966,
				43.68196
			],
			[
				-79.50941,
				43.68177
			],
			[
				-79.50921,
				43.68166
			],
			[
				-79.50815,
				43.68187
			],
			[
				-79.5074,
				43.68162
			],
			[
				-79.50709,
				43.68143
			],
			[
				-79.50694,
				43.68123
			],
			[
				-79.50735,
				43.68011
			],
			[
				-79.50747,
				43.67944
			],
			[
				-79.50746,
				43.67903
			],
			[
				-79.50767,
				43.67895
			],
			[
				-79.5075,
				43.67908
			],
			[
				-79.50791,
				43.67885
			],
			[
				-79.50787,
				43.67908
			],
			[
				-79.50763,
				43.67922
			],
			[
				-79.50775,
				43.67915
			],
			[
				-79.50625,
				43.67714
			],
			[
				-79.50603,
				43.67662
			],
			[
				-79.50602,
				43.67551
			],
			[
				-79.50496,
				43.67433
			],
			[
				-79.50424,
				43.67292
			],
			[
				-79.50407,
				43.67237
			],
			[
				-79.50369,
				43.6717
			],
			[
				-79.503,
				43.67082
			],
			[
				-79.50196,
				43.66975
			],
			[
				-79.49964,
				43.667
			],
			[
				-79.49942,
				43.66618
			],
			[
				-79.49827,
				43.6663
			],
			[
				-79.49788,
				43.66625
			],
			[
				-79.49515,
				43.6668
			],
			[
				-79.4918,
				43.66707
			],
			[
				-79.49154,
				43.66655
			],
			[
				-79.49128,
				43.66623
			],
			[
				-79.49125,
				43.66592
			],
			[
				-79.49081,
				43.66566
			],
			[
				-79.476,
				43.66548
			],
			[
				-79.47509,
				43.66556
			],
			[
				-79.47367,
				43.66544
			],
			[
				-79.47225,
				43.66225
			],
			[
				-79.46505,
				43.6638
			],
			[
				-79.46446,
				43.66377
			],
			[
				-79.46274,
				43.66343
			],
			[
				-79.45949,
				43.66411
			],
			[
				-79.45882,
				43.66442
			],
			[
				-79.45782,
				43.66426
			],
			[
				-79.45724,
				43.66437
			],
			[
				-79.45734,
				43.66433
			],
			[
				-79.45462,
				43.665
			],
			[
				-79.44449,
				43.66709
			],
			[
				-79.44419,
				43.66718
			],
			[
				-79.44367,
				43.66765
			],
			[
				-79.44329,
				43.66781
			],
			[
				-79.44031,
				43.6684
			],
			[
				-79.43883,
				43.6684
			],
			[
				-79.43286,
				43.66963
			],
			[
				-79.43272,
				43.66958
			],
			[
				-79.43223,
				43.66844
			],
			[
				-79.43191,
				43.66744
			],
			[
				-79.43127,
				43.66614
			],
			[
				-79.43107,
				43.66543
			],
			[
				-79.43096,
				43.66537
			],
			[
				-79.42968,
				43.66223
			],
			[
				-79.42963,
				43.66158
			],
			[
				-79.42935,
				43.66117
			],
			[
				-79.42941,
				43.66104
			],
			[
				-79.42915,
				43.66067
			],
			[
				-79.42917,
				43.66042
			],
			[
				-79.42894,
				43.66
			],
			[
				-79.42883,
				43.65944
			],
			[
				-79.42866,
				43.6594
			],
			[
				-79.42607,
				43.65276
			],
			[
				-79.42392,
				43.65318
			]
		],
		"dupe": false
	},
	{
		"id": 394874011,
		"name": "Afternoon Ride",
		"distance": 2672.5,
		"moving_time": 435,
		"elapsed_time": 449,
		"total_elevation_gain": 10.6,
		"start_date_local": "2015-09-18T13:03:59Z",
		"average_speed": 6.144,
		"max_speed": 11.9,
		"calories": 56.4,
		"points": [
			[
				-79.42953,
				43.64438
			],
			[
				-79.43002,
				43.6451
			],
			[
				-79.42985,
				43.64521
			],
			[
				-79.42982,
				43.64543
			],
			[
				-79.42987,
				43.64559
			],
			[
				-79.43,
				43.64567
			],
			[
				-79.43007,
				43.64593
			],
			[
				-79.43254,
				43.64545
			],
			[
				-79.43316,
				43.64563
			],
			[
				-79.43383,
				43.64551
			],
			[
				-79.43396,
				43.6456
			],
			[
				-79.43482,
				43.64781
			],
			[
				-79.43718,
				43.64737
			],
			[
				-79.4373,
				43.64742
			],
			[
				-79.43755,
				43.64808
			],
			[
				-79.43889,
				43.64789
			],
			[
				-79.43941,
				43.64906
			],
			[
				-79.43952,
				43.64892
			],
			[
				-79.43919,
				43.64787
			],
			[
				-79.43914,
				43.64731
			],
			[
				-79.43922,
				43.64704
			],
			[
				-79.43935,
				43.64693
			],
			[
				-79.44037,
				43.64673
			],
			[
				-79.44019,
				43.64624
			],
			[
				-79.44048,
				43.64613
			],
			[
				-79.44318,
				43.64559
			],
			[
				-79.44327,
				43.64571
			],
			[
				-79.44365,
				43.64564
			],
			[
				-79.44421,
				43.64538
			],
			[
				-79.44841,
				43.64452
			],
			[
				-79.44829,
				43.64457
			],
			[
				-79.44876,
				43.64562
			],
			[
				-79.44909,
				43.64666
			]
		],
		"dupe": false
	},
	{
		"id": 395043087,
		"name": "Afternoon Ride",
		"distance": 2671,
		"moving_time": 467,
		"elapsed_time": 481,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-18T14:16:04Z",
		"average_speed": 5.719,
		"max_speed": 13.9,
		"calories": 52.3,
		"points": [
			[
				-79.44874,
				43.64612
			],
			[
				-79.44881,
				43.64589
			],
			[
				-79.4488,
				43.64596
			],
			[
				-79.44889,
				43.64593
			],
			[
				-79.44875,
				43.64551
			],
			[
				-79.44859,
				43.64545
			],
			[
				-79.44061,
				43.64704
			],
			[
				-79.4405,
				43.64701
			],
			[
				-79.44038,
				43.64674
			],
			[
				-79.43925,
				43.64694
			],
			[
				-79.43873,
				43.64599
			],
			[
				-79.43832,
				43.64481
			],
			[
				-79.43801,
				43.64423
			],
			[
				-79.43774,
				43.64331
			],
			[
				-79.43749,
				43.64282
			],
			[
				-79.43754,
				43.64279
			],
			[
				-79.43736,
				43.64263
			],
			[
				-79.4371,
				43.64278
			],
			[
				-79.43508,
				43.64322
			],
			[
				-79.43513,
				43.6432
			],
			[
				-79.43497,
				43.64313
			],
			[
				-79.4347,
				43.64243
			],
			[
				-79.43296,
				43.64276
			],
			[
				-79.43283,
				43.64268
			],
			[
				-79.43237,
				43.64146
			],
			[
				-79.43222,
				43.6414
			],
			[
				-79.42866,
				43.64214
			],
			[
				-79.42913,
				43.64353
			],
			[
				-79.42868,
				43.64375
			],
			[
				-79.42884,
				43.64395
			],
			[
				-79.42908,
				43.64401
			],
			[
				-79.42947,
				43.64434
			]
		],
		"dupe": false
	},
	{
		"id": 395842948,
		"name": "y u leaf me summer¿",
		"distance": 34948.6,
		"moving_time": 5354,
		"elapsed_time": 7292,
		"total_elevation_gain": 109.1,
		"start_date_local": "2015-09-19T18:14:04Z",
		"average_speed": 6.528,
		"max_speed": 18.2,
		"calories": 675.7,
		"points": [
			[
				-79.42592,
				43.6524
			],
			[
				-79.42161,
				43.64142
			],
			[
				-79.42121,
				43.64129
			],
			[
				-79.41758,
				43.64117
			],
			[
				-79.41741,
				43.64112
			],
			[
				-79.41737,
				43.64101
			],
			[
				-79.41707,
				43.64094
			],
			[
				-79.41668,
				43.64102
			],
			[
				-79.41647,
				43.64075
			],
			[
				-79.41651,
				43.64078
			],
			[
				-79.41656,
				43.64084
			],
			[
				-79.41636,
				43.64071
			],
			[
				-79.41257,
				43.64055
			],
			[
				-79.41153,
				43.64073
			],
			[
				-79.41142,
				43.64054
			],
			[
				-79.41156,
				43.6405
			],
			[
				-79.41162,
				43.6401
			],
			[
				-79.41157,
				43.63994
			],
			[
				-79.41113,
				43.63952
			],
			[
				-79.41072,
				43.63898
			],
			[
				-79.40989,
				43.63689
			],
			[
				-79.40994,
				43.63679
			],
			[
				-79.40986,
				43.63652
			],
			[
				-79.40973,
				43.63651
			],
			[
				-79.40911,
				43.63494
			],
			[
				-79.40873,
				43.63451
			],
			[
				-79.40872,
				43.63441
			],
			[
				-79.40843,
				43.63433
			],
			[
				-79.40783,
				43.63444
			],
			[
				-79.40603,
				43.63398
			],
			[
				-79.4056,
				43.63393
			],
			[
				-79.40466,
				43.63398
			],
			[
				-79.40433,
				43.6341
			],
			[
				-79.40401,
				43.6344
			],
			[
				-79.40337,
				43.63566
			],
			[
				-79.4032,
				43.63527
			],
			[
				-79.40299,
				43.63529
			],
			[
				-79.40192,
				43.63417
			],
			[
				-79.40133,
				43.63412
			],
			[
				-79.40079,
				43.63433
			],
			[
				-79.39807,
				43.63579
			],
			[
				-79.39716,
				43.63642
			],
			[
				-79.39544,
				43.637
			],
			[
				-79.39095,
				43.6378
			],
			[
				-79.39081,
				43.6378
			],
			[
				-79.39075,
				43.63763
			],
			[
				-79.39077,
				43.63772
			],
			[
				-79.3907,
				43.63776
			],
			[
				-79.38984,
				43.63803
			],
			[
				-79.38722,
				43.63864
			],
			[
				-79.38296,
				43.63945
			],
			[
				-79.38299,
				43.6394
			],
			[
				-79.38219,
				43.63953
			],
			[
				-79.38148,
				43.63956
			],
			[
				-79.38152,
				43.63953
			],
			[
				-79.38049,
				43.63977
			],
			[
				-79.37845,
				43.64047
			],
			[
				-79.3775,
				43.64099
			],
			[
				-79.37724,
				43.64084
			],
			[
				-79.37698,
				43.64094
			],
			[
				-79.37687,
				43.64116
			],
			[
				-79.37669,
				43.64126
			],
			[
				-79.37612,
				43.64122
			],
			[
				-79.37597,
				43.64143
			],
			[
				-79.37543,
				43.64162
			],
			[
				-79.37536,
				43.64157
			],
			[
				-79.37504,
				43.64177
			],
			[
				-79.36783,
				43.64433
			],
			[
				-79.36769,
				43.64422
			],
			[
				-79.36744,
				43.64447
			],
			[
				-79.36216,
				43.64632
			],
			[
				-79.36195,
				43.64641
			],
			[
				-79.36171,
				43.64665
			],
			[
				-79.36122,
				43.64773
			],
			[
				-79.36078,
				43.64796
			],
			[
				-79.35936,
				43.64843
			],
			[
				-79.35902,
				43.64822
			],
			[
				-79.35806,
				43.64852
			],
			[
				-79.35784,
				43.64869
			],
			[
				-79.35702,
				43.64889
			],
			[
				-79.35578,
				43.64888
			],
			[
				-79.35524,
				43.64868
			],
			[
				-79.35508,
				43.64807
			],
			[
				-79.35495,
				43.64808
			],
			[
				-79.35442,
				43.64779
			],
			[
				-79.35253,
				43.64541
			],
			[
				-79.35233,
				43.64534
			],
			[
				-79.35235,
				43.64519
			],
			[
				-79.3523,
				43.64536
			],
			[
				-79.35245,
				43.64531
			],
			[
				-79.35232,
				43.64534
			],
			[
				-79.35228,
				43.64511
			],
			[
				-79.34961,
				43.64186
			],
			[
				-79.34801,
				43.64029
			],
			[
				-79.34787,
				43.64031
			],
			[
				-79.34745,
				43.63996
			],
			[
				-79.34747,
				43.63981
			],
			[
				-79.34479,
				43.63727
			],
			[
				-79.34423,
				43.63733
			],
			[
				-79.34442,
				43.63718
			],
			[
				-79.34342,
				43.63767
			],
			[
				-79.34285,
				43.63853
			],
			[
				-79.34204,
				43.63941
			],
			[
				-79.34109,
				43.63952
			],
			[
				-79.34065,
				43.63974
			],
			[
				-79.34001,
				43.63986
			],
			[
				-79.3392,
				43.64036
			],
			[
				-79.33694,
				43.64226
			],
			[
				-79.33665,
				43.64237
			],
			[
				-79.33604,
				43.64318
			],
			[
				-79.33556,
				43.64324
			],
			[
				-79.33534,
				43.64311
			],
			[
				-79.33502,
				43.64307
			],
			[
				-79.33463,
				43.64315
			],
			[
				-79.334,
				43.64368
			],
			[
				-79.33386,
				43.64392
			],
			[
				-79.33392,
				43.64448
			],
			[
				-79.33368,
				43.64511
			],
			[
				-79.33304,
				43.64562
			],
			[
				-79.33251,
				43.64581
			],
			[
				-79.33143,
				43.64644
			],
			[
				-79.33083,
				43.64689
			],
			[
				-79.33051,
				43.64693
			],
			[
				-79.33021,
				43.64716
			],
			[
				-79.32941,
				43.64752
			],
			[
				-79.32908,
				43.64785
			],
			[
				-79.32735,
				43.64872
			],
			[
				-79.32732,
				43.64895
			],
			[
				-79.32801,
				43.64985
			],
			[
				-79.32802,
				43.65019
			],
			[
				-79.32492,
				43.65157
			],
			[
				-79.32452,
				43.65162
			],
			[
				-79.32293,
				43.65236
			],
			[
				-79.32341,
				43.65312
			],
			[
				-79.32354,
				43.65314
			],
			[
				-79.32772,
				43.65825
			],
			[
				-79.32823,
				43.65892
			],
			[
				-79.32807,
				43.65901
			],
			[
				-79.32394,
				43.66
			],
			[
				-79.32179,
				43.66066
			],
			[
				-79.31484,
				43.66214
			],
			[
				-79.31443,
				43.66189
			],
			[
				-79.31405,
				43.66177
			],
			[
				-79.31361,
				43.66177
			],
			[
				-79.3131,
				43.6619
			],
			[
				-79.31275,
				43.66182
			],
			[
				-79.31246,
				43.66158
			],
			[
				-79.31251,
				43.66163
			],
			[
				-79.31251,
				43.66156
			],
			[
				-79.31227,
				43.66166
			],
			[
				-79.31186,
				43.66153
			],
			[
				-79.31158,
				43.66157
			],
			[
				-79.31092,
				43.66187
			],
			[
				-79.31042,
				43.66193
			],
			[
				-79.31027,
				43.66206
			],
			[
				-79.30893,
				43.6626
			],
			[
				-79.3076,
				43.66351
			],
			[
				-79.30731,
				43.66388
			],
			[
				-79.30606,
				43.66438
			],
			[
				-79.3055,
				43.66477
			],
			[
				-79.30502,
				43.66524
			],
			[
				-79.30371,
				43.66531
			],
			[
				-79.3034,
				43.6652
			],
			[
				-79.30283,
				43.66524
			],
			[
				-79.30167,
				43.66556
			],
			[
				-79.29987,
				43.66588
			],
			[
				-79.29871,
				43.66618
			],
			[
				-79.29807,
				43.6662
			],
			[
				-79.29723,
				43.66635
			],
			[
				-79.29611,
				43.66714
			],
			[
				-79.29574,
				43.66714
			],
			[
				-79.29493,
				43.66738
			],
			[
				-79.29435,
				43.66739
			],
			[
				-79.29336,
				43.66775
			],
			[
				-79.29316,
				43.6679
			],
			[
				-79.29311,
				43.66811
			],
			[
				-79.29305,
				43.66803
			],
			[
				-79.29124,
				43.66849
			],
			[
				-79.29129,
				43.66855
			],
			[
				-79.29125,
				43.66849
			],
			[
				-79.29016,
				43.66876
			],
			[
				-79.28972,
				43.66902
			],
			[
				-79.28856,
				43.66933
			],
			[
				-79.28832,
				43.66948
			],
			[
				-79.28788,
				43.66943
			],
			[
				-79.28766,
				43.66949
			],
			[
				-79.28742,
				43.6697
			],
			[
				-79.28692,
				43.66979
			],
			[
				-79.2867,
				43.66998
			],
			[
				-79.28672,
				43.67005
			],
			[
				-79.28618,
				43.67029
			],
			[
				-79.28587,
				43.67021
			],
			[
				-79.28459,
				43.6704
			],
			[
				-79.2841,
				43.67029
			],
			[
				-79.28446,
				43.67149
			],
			[
				-79.28476,
				43.672
			],
			[
				-79.28502,
				43.67273
			],
			[
				-79.28508,
				43.67301
			],
			[
				-79.28498,
				43.67307
			],
			[
				-79.28255,
				43.67367
			],
			[
				-79.28131,
				43.67385
			],
			[
				-79.28089,
				43.67369
			],
			[
				-79.28038,
				43.67268
			],
			[
				-79.28013,
				43.67251
			],
			[
				-79.27958,
				43.67254
			],
			[
				-79.27805,
				43.67309
			],
			[
				-79.27791,
				43.67307
			],
			[
				-79.27777,
				43.67293
			],
			[
				-79.2785,
				43.67224
			],
			[
				-79.27976,
				43.67184
			],
			[
				-79.27983,
				43.67212
			],
			[
				-79.27971,
				43.67221
			],
			[
				-79.27835,
				43.67257
			],
			[
				-79.27796,
				43.67274
			],
			[
				-79.27782,
				43.67292
			],
			[
				-79.27795,
				43.67307
			],
			[
				-79.27815,
				43.67307
			],
			[
				-79.27945,
				43.67261
			],
			[
				-79.27993,
				43.67255
			],
			[
				-79.27982,
				43.6727
			],
			[
				-79.27884,
				43.67301
			],
			[
				-79.27862,
				43.67301
			],
			[
				-79.27858,
				43.67307
			],
			[
				-79.27865,
				43.6731
			],
			[
				-79.27988,
				43.67271
			],
			[
				-79.28,
				43.67264
			],
			[
				-79.27998,
				43.67252
			],
			[
				-79.27983,
				43.6725
			],
			[
				-79.27795,
				43.67307
			],
			[
				-79.27774,
				43.67291
			],
			[
				-79.27974,
				43.67214
			],
			[
				-79.27984,
				43.67194
			],
			[
				-79.27973,
				43.67182
			],
			[
				-79.27956,
				43.67179
			],
			[
				-79.27851,
				43.67212
			],
			[
				-79.27834,
				43.67221
			],
			[
				-79.2782,
				43.67253
			],
			[
				-79.27784,
				43.67297
			],
			[
				-79.27792,
				43.67309
			],
			[
				-79.27807,
				43.67312
			],
			[
				-79.2797,
				43.67256
			],
			[
				-79.27988,
				43.67256
			],
			[
				-79.2798,
				43.67269
			],
			[
				-79.27894,
				43.673
			],
			[
				-79.27858,
				43.67301
			],
			[
				-79.27877,
				43.67307
			],
			[
				-79.28005,
				43.67262
			],
			[
				-79.28031,
				43.67263
			],
			[
				-79.2809,
				43.67376
			],
			[
				-79.28111,
				43.67388
			],
			[
				-79.28115,
				43.67399
			],
			[
				-79.2802,
				43.67433
			],
			[
				-79.28049,
				43.67514
			],
			[
				-79.28082,
				43.67574
			],
			[
				-79.28091,
				43.67623
			],
			[
				-79.28147,
				43.6773
			],
			[
				-79.28159,
				43.6778
			],
			[
				-79.28196,
				43.67842
			],
			[
				-79.28314,
				43.6787
			],
			[
				-79.28408,
				43.68082
			],
			[
				-79.28464,
				43.68086
			],
			[
				-79.28803,
				43.6805
			],
			[
				-79.29294,
				43.68014
			],
			[
				-79.29806,
				43.67899
			],
			[
				-79.29822,
				43.67878
			],
			[
				-79.29841,
				43.67873
			],
			[
				-79.29859,
				43.67888
			],
			[
				-79.29934,
				43.67863
			],
			[
				-79.30064,
				43.67846
			],
			[
				-79.30384,
				43.67719
			],
			[
				-79.30539,
				43.67634
			],
			[
				-79.30787,
				43.67408
			],
			[
				-79.30805,
				43.67433
			],
			[
				-79.30813,
				43.67477
			],
			[
				-79.30853,
				43.67556
			],
			[
				-79.30856,
				43.6758
			],
			[
				-79.30883,
				43.67631
			],
			[
				-79.30955,
				43.6782
			],
			[
				-79.30985,
				43.67861
			],
			[
				-79.30985,
				43.67887
			],
			[
				-79.31061,
				43.68076
			],
			[
				-79.3108,
				43.68082
			],
			[
				-79.3115,
				43.68059
			],
			[
				-79.31154,
				43.68049
			],
			[
				-79.31189,
				43.68034
			],
			[
				-79.31491,
				43.6781
			],
			[
				-79.31574,
				43.67714
			],
			[
				-79.31692,
				43.6762
			],
			[
				-79.31759,
				43.67599
			],
			[
				-79.31963,
				43.6757
			],
			[
				-79.32031,
				43.67532
			],
			[
				-79.3203,
				43.67509
			],
			[
				-79.32052,
				43.67514
			],
			[
				-79.32082,
				43.67498
			],
			[
				-79.3213,
				43.67488
			],
			[
				-79.32042,
				43.67275
			],
			[
				-79.32238,
				43.67222
			],
			[
				-79.32274,
				43.6722
			],
			[
				-79.33064,
				43.67042
			],
			[
				-79.33069,
				43.67048
			],
			[
				-79.33102,
				43.67034
			],
			[
				-79.33193,
				43.6702
			],
			[
				-79.33273,
				43.66995
			],
			[
				-79.33327,
				43.66991
			],
			[
				-79.33417,
				43.66963
			],
			[
				-79.3355,
				43.6694
			],
			[
				-79.33706,
				43.66895
			],
			[
				-79.34171,
				43.66796
			],
			[
				-79.34318,
				43.66757
			],
			[
				-79.34445,
				43.66736
			],
			[
				-79.34589,
				43.66694
			],
			[
				-79.34732,
				43.66671
			],
			[
				-79.34907,
				43.66627
			],
			[
				-79.34986,
				43.66618
			],
			[
				-79.35192,
				43.66565
			],
			[
				-79.35251,
				43.66554
			],
			[
				-79.35287,
				43.66558
			],
			[
				-79.3553,
				43.6649
			],
			[
				-79.35567,
				43.66468
			],
			[
				-79.3563,
				43.66448
			],
			[
				-79.35671,
				43.66436
			],
			[
				-79.35674,
				43.66447
			],
			[
				-79.3569,
				43.66432
			],
			[
				-79.35902,
				43.66375
			],
			[
				-79.35958,
				43.66374
			],
			[
				-79.35986,
				43.66359
			],
			[
				-79.36077,
				43.66335
			],
			[
				-79.36208,
				43.66322
			],
			[
				-79.36264,
				43.66301
			],
			[
				-79.36476,
				43.66251
			],
			[
				-79.36667,
				43.66217
			],
			[
				-79.3669,
				43.66206
			],
			[
				-79.36706,
				43.66222
			],
			[
				-79.36727,
				43.66293
			],
			[
				-79.36743,
				43.66313
			],
			[
				-79.36768,
				43.66383
			],
			[
				-79.36781,
				43.66398
			],
			[
				-79.36775,
				43.66415
			],
			[
				-79.36803,
				43.66427
			],
			[
				-79.36848,
				43.66428
			],
			[
				-79.3685,
				43.66413
			],
			[
				-79.37305,
				43.66317
			],
			[
				-79.37312,
				43.6633
			],
			[
				-79.37344,
				43.6631
			],
			[
				-79.37516,
				43.66273
			],
			[
				-79.37528,
				43.66282
			],
			[
				-79.37596,
				43.66261
			],
			[
				-79.37658,
				43.66268
			],
			[
				-79.37657,
				43.66245
			],
			[
				-79.37701,
				43.66245
			],
			[
				-79.37874,
				43.66206
			],
			[
				-79.3791,
				43.6621
			],
			[
				-79.37924,
				43.6619
			],
			[
				-79.38073,
				43.6617
			],
			[
				-79.38108,
				43.6616
			],
			[
				-79.38144,
				43.66135
			],
			[
				-79.38244,
				43.6614
			],
			[
				-79.38278,
				43.6613
			],
			[
				-79.38305,
				43.66133
			],
			[
				-79.38288,
				43.66141
			],
			[
				-79.38306,
				43.66159
			],
			[
				-79.38432,
				43.66119
			],
			[
				-79.38445,
				43.66117
			],
			[
				-79.38449,
				43.66133
			],
			[
				-79.38468,
				43.66122
			],
			[
				-79.38606,
				43.66107
			],
			[
				-79.38699,
				43.66085
			],
			[
				-79.38713,
				43.66071
			],
			[
				-79.38721,
				43.66076
			],
			[
				-79.38721,
				43.66059
			],
			[
				-79.38961,
				43.66006
			],
			[
				-79.38953,
				43.66009
			],
			[
				-79.39044,
				43.66014
			],
			[
				-79.39216,
				43.65967
			],
			[
				-79.39263,
				43.65968
			],
			[
				-79.39325,
				43.65944
			],
			[
				-79.39544,
				43.65896
			],
			[
				-79.39612,
				43.65888
			],
			[
				-79.39644,
				43.65872
			],
			[
				-79.39789,
				43.65841
			],
			[
				-79.39981,
				43.6581
			],
			[
				-79.39999,
				43.65819
			],
			[
				-79.39999,
				43.65795
			],
			[
				-79.40007,
				43.65794
			],
			[
				-79.40016,
				43.65818
			],
			[
				-79.4005,
				43.6579
			],
			[
				-79.41513,
				43.65497
			],
			[
				-79.41542,
				43.65494
			],
			[
				-79.41581,
				43.65513
			],
			[
				-79.41788,
				43.65531
			],
			[
				-79.41894,
				43.65526
			],
			[
				-79.42029,
				43.65498
			]
		],
		"dupe": false
	},
	{
		"id": 396725842,
		"name": "To Leslie Spit",
		"distance": 42189.7,
		"moving_time": 7669,
		"elapsed_time": 13768,
		"total_elevation_gain": 30,
		"start_date_local": "2015-09-20T15:32:06Z",
		"average_speed": 5.501,
		"max_speed": 19.6,
		"calories": 687.2,
		"points": [
			[
				-79.42578,
				43.65203
			],
			[
				-79.42252,
				43.64384
			],
			[
				-79.42248,
				43.64358
			],
			[
				-79.42255,
				43.64352
			],
			[
				-79.42236,
				43.64336
			],
			[
				-79.4222,
				43.64347
			],
			[
				-79.42142,
				43.64369
			],
			[
				-79.41899,
				43.64405
			],
			[
				-79.41835,
				43.64425
			],
			[
				-79.41306,
				43.64527
			],
			[
				-79.41317,
				43.64517
			],
			[
				-79.41217,
				43.6426
			],
			[
				-79.41201,
				43.64205
			],
			[
				-79.41206,
				43.64194
			],
			[
				-79.41194,
				43.64189
			],
			[
				-79.41152,
				43.64088
			],
			[
				-79.41161,
				43.64
			],
			[
				-79.41113,
				43.63952
			],
			[
				-79.41069,
				43.63892
			],
			[
				-79.40911,
				43.63494
			],
			[
				-79.40867,
				43.63446
			],
			[
				-79.40834,
				43.63436
			],
			[
				-79.40776,
				43.63443
			],
			[
				-79.40658,
				43.63408
			],
			[
				-79.40563,
				43.63393
			],
			[
				-79.40478,
				43.63396
			],
			[
				-79.40436,
				43.63409
			],
			[
				-79.40402,
				43.63439
			],
			[
				-79.40371,
				43.63501
			],
			[
				-79.40323,
				43.63509
			],
			[
				-79.40294,
				43.63523
			],
			[
				-79.40192,
				43.63417
			],
			[
				-79.40113,
				43.63414
			],
			[
				-79.39862,
				43.63553
			],
			[
				-79.39845,
				43.6356
			],
			[
				-79.39817,
				43.63545
			],
			[
				-79.39783,
				43.63591
			],
			[
				-79.3975,
				43.63612
			],
			[
				-79.39775,
				43.63593
			],
			[
				-79.39753,
				43.63599
			],
			[
				-79.39705,
				43.63637
			],
			[
				-79.39504,
				43.63712
			],
			[
				-79.39384,
				43.63732
			],
			[
				-79.39363,
				43.63728
			],
			[
				-79.39097,
				43.63784
			],
			[
				-79.3906,
				43.63783
			],
			[
				-79.38744,
				43.63859
			],
			[
				-79.38542,
				43.63898
			],
			[
				-79.38532,
				43.63893
			],
			[
				-79.38497,
				43.63903
			],
			[
				-79.38447,
				43.63905
			],
			[
				-79.38355,
				43.63934
			],
			[
				-79.38165,
				43.63957
			],
			[
				-79.38036,
				43.63984
			],
			[
				-79.37752,
				43.64081
			],
			[
				-79.37464,
				43.64191
			],
			[
				-79.37448,
				43.64172
			],
			[
				-79.37439,
				43.64174
			],
			[
				-79.37328,
				43.64219
			],
			[
				-79.37324,
				43.64241
			],
			[
				-79.36782,
				43.64434
			],
			[
				-79.36765,
				43.64416
			],
			[
				-79.36722,
				43.64437
			],
			[
				-79.36718,
				43.64457
			],
			[
				-79.36211,
				43.64634
			],
			[
				-79.36172,
				43.64663
			],
			[
				-79.36121,
				43.64764
			],
			[
				-79.36122,
				43.64774
			],
			[
				-79.36074,
				43.64797
			],
			[
				-79.35842,
				43.64874
			],
			[
				-79.35731,
				43.64902
			],
			[
				-79.35638,
				43.64904
			],
			[
				-79.35584,
				43.64875
			],
			[
				-79.35532,
				43.64822
			],
			[
				-79.35509,
				43.64817
			],
			[
				-79.35444,
				43.6478
			],
			[
				-79.35121,
				43.64379
			],
			[
				-79.35159,
				43.64366
			],
			[
				-79.35126,
				43.6437
			],
			[
				-79.35093,
				43.64324
			],
			[
				-79.35072,
				43.64321
			],
			[
				-79.35058,
				43.64303
			],
			[
				-79.35072,
				43.64295
			],
			[
				-79.3504,
				43.64281
			],
			[
				-79.34962,
				43.64186
			],
			[
				-79.34822,
				43.64049
			],
			[
				-79.34626,
				43.63873
			],
			[
				-79.34502,
				43.6375
			],
			[
				-79.34462,
				43.63728
			],
			[
				-79.34447,
				43.63732
			],
			[
				-79.3444,
				43.6372
			],
			[
				-79.34332,
				43.63765
			],
			[
				-79.34276,
				43.63827
			],
			[
				-79.34269,
				43.63851
			],
			[
				-79.34207,
				43.63922
			],
			[
				-79.34178,
				43.63943
			],
			[
				-79.34141,
				43.63943
			],
			[
				-79.34057,
				43.63982
			],
			[
				-79.33991,
				43.63991
			],
			[
				-79.3393,
				43.64021
			],
			[
				-79.33887,
				43.64056
			],
			[
				-79.33955,
				43.64012
			],
			[
				-79.33905,
				43.64048
			],
			[
				-79.33859,
				43.63983
			],
			[
				-79.33843,
				43.63987
			],
			[
				-79.33861,
				43.64014
			],
			[
				-79.33905,
				43.64047
			],
			[
				-79.33686,
				43.64219
			],
			[
				-79.33653,
				43.64235
			],
			[
				-79.3362,
				43.64289
			],
			[
				-79.3359,
				43.64311
			],
			[
				-79.3355,
				43.64315
			],
			[
				-79.33525,
				43.64305
			],
			[
				-79.33469,
				43.64306
			],
			[
				-79.33389,
				43.64367
			],
			[
				-79.3338,
				43.64386
			],
			[
				-79.33386,
				43.64434
			],
			[
				-79.33379,
				43.64469
			],
			[
				-79.33359,
				43.64512
			],
			[
				-79.33291,
				43.64556
			],
			[
				-79.33144,
				43.64632
			],
			[
				-79.33079,
				43.64683
			],
			[
				-79.33026,
				43.64692
			],
			[
				-79.33014,
				43.64711
			],
			[
				-79.32982,
				43.64723
			],
			[
				-79.32907,
				43.64776
			],
			[
				-79.32873,
				43.64789
			],
			[
				-79.32869,
				43.64811
			],
			[
				-79.32851,
				43.64818
			],
			[
				-79.32801,
				43.64778
			],
			[
				-79.32663,
				43.64713
			],
			[
				-79.32614,
				43.64709
			],
			[
				-79.32434,
				43.64718
			],
			[
				-79.32403,
				43.647
			],
			[
				-79.32388,
				43.64672
			],
			[
				-79.32376,
				43.6467
			],
			[
				-79.32375,
				43.64663
			],
			[
				-79.32387,
				43.6466
			],
			[
				-79.32375,
				43.64653
			],
			[
				-79.32387,
				43.64662
			],
			[
				-79.32386,
				43.64672
			],
			[
				-79.32267,
				43.64685
			],
			[
				-79.32157,
				43.64654
			],
			[
				-79.32116,
				43.64653
			],
			[
				-79.32083,
				43.64639
			],
			[
				-79.32094,
				43.64577
			],
			[
				-79.32089,
				43.64563
			],
			[
				-79.32098,
				43.64564
			],
			[
				-79.32126,
				43.6447
			],
			[
				-79.32137,
				43.64405
			],
			[
				-79.32129,
				43.64389
			],
			[
				-79.3219,
				43.64206
			],
			[
				-79.3221,
				43.64029
			],
			[
				-79.32219,
				43.64017
			],
			[
				-79.32237,
				43.63926
			],
			[
				-79.32266,
				43.63847
			],
			[
				-79.32266,
				43.63819
			],
			[
				-79.32323,
				43.63643
			],
			[
				-79.32317,
				43.63621
			],
			[
				-79.32351,
				43.63541
			],
			[
				-79.32365,
				43.63468
			],
			[
				-79.32386,
				43.6343
			],
			[
				-79.32429,
				43.63369
			],
			[
				-79.32549,
				43.63245
			],
			[
				-79.32613,
				43.63165
			],
			[
				-79.32658,
				43.63129
			],
			[
				-79.32662,
				43.63114
			],
			[
				-79.32775,
				43.6299
			],
			[
				-79.32801,
				43.62945
			],
			[
				-79.32842,
				43.62912
			],
			[
				-79.32992,
				43.62742
			],
			[
				-79.3308,
				43.6268
			],
			[
				-79.33089,
				43.62667
			],
			[
				-79.33073,
				43.62645
			],
			[
				-79.33121,
				43.62582
			],
			[
				-79.33141,
				43.62576
			],
			[
				-79.33208,
				43.62582
			],
			[
				-79.33252,
				43.62556
			],
			[
				-79.33256,
				43.62551
			],
			[
				-79.33247,
				43.6255
			],
			[
				-79.33251,
				43.62555
			],
			[
				-79.33266,
				43.62545
			],
			[
				-79.33356,
				43.62471
			],
			[
				-79.33466,
				43.62396
			],
			[
				-79.33618,
				43.62272
			],
			[
				-79.33829,
				43.62112
			],
			[
				-79.33887,
				43.62078
			],
			[
				-79.33917,
				43.62048
			],
			[
				-79.33961,
				43.62022
			],
			[
				-79.34259,
				43.61787
			],
			[
				-79.3428,
				43.61747
			],
			[
				-79.34293,
				43.61597
			],
			[
				-79.3431,
				43.61559
			],
			[
				-79.34313,
				43.61533
			],
			[
				-79.34306,
				43.61523
			],
			[
				-79.34333,
				43.61373
			],
			[
				-79.34325,
				43.61363
			],
			[
				-79.3433,
				43.61348
			],
			[
				-79.34341,
				43.61484
			],
			[
				-79.3438,
				43.61365
			],
			[
				-79.34379,
				43.61332
			],
			[
				-79.3435,
				43.61309
			],
			[
				-79.34346,
				43.61281
			],
			[
				-79.34339,
				43.61309
			],
			[
				-79.34303,
				43.61328
			],
			[
				-79.34259,
				43.61385
			],
			[
				-79.34236,
				43.61436
			],
			[
				-79.34204,
				43.61482
			],
			[
				-79.34172,
				43.61476
			],
			[
				-79.34187,
				43.6148
			],
			[
				-79.34196,
				43.61473
			],
			[
				-79.3408,
				43.61581
			],
			[
				-79.34005,
				43.61672
			],
			[
				-79.33883,
				43.61775
			],
			[
				-79.33868,
				43.61804
			],
			[
				-79.33741,
				43.61898
			],
			[
				-79.33639,
				43.61949
			],
			[
				-79.33451,
				43.62004
			],
			[
				-79.33242,
				43.62046
			],
			[
				-79.33201,
				43.62076
			],
			[
				-79.33161,
				43.62085
			],
			[
				-79.33133,
				43.62101
			],
			[
				-79.33097,
				43.62182
			],
			[
				-79.33077,
				43.62202
			],
			[
				-79.32905,
				43.62205
			],
			[
				-79.32651,
				43.6224
			],
			[
				-79.32489,
				43.62233
			],
			[
				-79.3242,
				43.62251
			],
			[
				-79.32402,
				43.62282
			],
			[
				-79.32397,
				43.62428
			],
			[
				-79.32384,
				43.6247
			],
			[
				-79.3235,
				43.6277
			],
			[
				-79.32324,
				43.63104
			],
			[
				-79.32295,
				43.63224
			],
			[
				-79.32282,
				43.63321
			],
			[
				-79.32287,
				43.63362
			],
			[
				-79.32301,
				43.63377
			],
			[
				-79.3233,
				43.63387
			],
			[
				-79.32323,
				43.63385
			],
			[
				-79.32333,
				43.63372
			],
			[
				-79.32381,
				43.63378
			],
			[
				-79.3241,
				43.63364
			],
			[
				-79.32409,
				43.63358
			],
			[
				-79.32376,
				43.63359
			],
			[
				-79.32369,
				43.63361
			],
			[
				-79.32427,
				43.6337
			],
			[
				-79.32377,
				43.63454
			],
			[
				-79.32257,
				43.63898
			],
			[
				-79.32226,
				43.63985
			],
			[
				-79.32206,
				43.64062
			],
			[
				-79.32197,
				43.64216
			],
			[
				-79.32081,
				43.64642
			],
			[
				-79.32083,
				43.64633
			],
			[
				-79.32082,
				43.64642
			],
			[
				-79.32184,
				43.64654
			],
			[
				-79.323,
				43.64686
			],
			[
				-79.3239,
				43.64674
			],
			[
				-79.32412,
				43.64713
			],
			[
				-79.32448,
				43.64727
			],
			[
				-79.32578,
				43.64711
			],
			[
				-79.32656,
				43.64711
			],
			[
				-79.32862,
				43.64813
			],
			[
				-79.3292,
				43.64789
			],
			[
				-79.33448,
				43.64491
			],
			[
				-79.33402,
				43.6441
			],
			[
				-79.33396,
				43.64384
			],
			[
				-79.33472,
				43.64313
			],
			[
				-79.33534,
				43.6431
			],
			[
				-79.33561,
				43.64323
			],
			[
				-79.33603,
				43.6431
			],
			[
				-79.33625,
				43.64293
			],
			[
				-79.33649,
				43.64252
			],
			[
				-79.33708,
				43.64224
			],
			[
				-79.33829,
				43.64108
			],
			[
				-79.33949,
				43.6402
			],
			[
				-79.34007,
				43.63989
			],
			[
				-79.34063,
				43.63979
			],
			[
				-79.34108,
				43.63958
			],
			[
				-79.34184,
				43.63953
			],
			[
				-79.34284,
				43.63845
			],
			[
				-79.3435,
				43.63786
			],
			[
				-79.34481,
				43.63766
			],
			[
				-79.34544,
				43.63792
			],
			[
				-79.34965,
				43.64189
			],
			[
				-79.35449,
				43.64789
			],
			[
				-79.35479,
				43.64793
			],
			[
				-79.35487,
				43.64833
			],
			[
				-79.35595,
				43.64906
			],
			[
				-79.35629,
				43.64902
			],
			[
				-79.35645,
				43.64914
			],
			[
				-79.35694,
				43.64895
			],
			[
				-79.35764,
				43.64882
			],
			[
				-79.35854,
				43.64841
			],
			[
				-79.36062,
				43.64784
			],
			[
				-79.36113,
				43.64743
			],
			[
				-79.36133,
				43.64743
			],
			[
				-79.36183,
				43.64649
			],
			[
				-79.3622,
				43.6463
			],
			[
				-79.37661,
				43.64121
			],
			[
				-79.37686,
				43.64119
			],
			[
				-79.37698,
				43.64133
			],
			[
				-79.37864,
				43.6407
			],
			[
				-79.37859,
				43.64058
			],
			[
				-79.3787,
				43.64054
			],
			[
				-79.37882,
				43.6406
			],
			[
				-79.37918,
				43.64054
			],
			[
				-79.37946,
				43.64033
			],
			[
				-79.38001,
				43.64025
			],
			[
				-79.37995,
				43.64011
			],
			[
				-79.38029,
				43.64023
			],
			[
				-79.38077,
				43.64003
			],
			[
				-79.38079,
				43.63985
			],
			[
				-79.38194,
				43.63969
			],
			[
				-79.38219,
				43.63972
			],
			[
				-79.38257,
				43.6396
			],
			[
				-79.38396,
				43.63943
			],
			[
				-79.38671,
				43.63883
			],
			[
				-79.38834,
				43.63836
			],
			[
				-79.39193,
				43.6376
			],
			[
				-79.39311,
				43.63738
			],
			[
				-79.39374,
				43.63737
			],
			[
				-79.39496,
				43.63719
			],
			[
				-79.39711,
				43.63647
			],
			[
				-79.39816,
				43.63567
			],
			[
				-79.39896,
				43.63543
			],
			[
				-79.40009,
				43.63484
			],
			[
				-79.40008,
				43.63473
			],
			[
				-79.40055,
				43.63448
			],
			[
				-79.40138,
				43.6342
			],
			[
				-79.40178,
				43.63427
			],
			[
				-79.40204,
				43.63471
			],
			[
				-79.4026,
				43.63521
			],
			[
				-79.40297,
				43.63522
			],
			[
				-79.40313,
				43.63537
			],
			[
				-79.40367,
				43.6355
			],
			[
				-79.40378,
				43.63482
			],
			[
				-79.404,
				43.63438
			],
			[
				-79.4042,
				43.63417
			],
			[
				-79.40485,
				43.63392
			],
			[
				-79.40598,
				43.63397
			],
			[
				-79.40762,
				43.63442
			],
			[
				-79.40798,
				43.63442
			],
			[
				-79.4085,
				43.63429
			],
			[
				-79.40877,
				43.63397
			],
			[
				-79.40883,
				43.63219
			],
			[
				-79.40905,
				43.63163
			],
			[
				-79.4097,
				43.63231
			],
			[
				-79.41058,
				43.63236
			],
			[
				-79.41235,
				43.63177
			],
			[
				-79.41251,
				43.63186
			],
			[
				-79.41441,
				43.63126
			],
			[
				-79.41786,
				43.63064
			],
			[
				-79.4207,
				43.6303
			],
			[
				-79.42227,
				43.63021
			],
			[
				-79.42332,
				43.63002
			],
			[
				-79.42408,
				43.63003
			],
			[
				-79.42529,
				43.62989
			],
			[
				-79.42606,
				43.62997
			],
			[
				-79.42683,
				43.63022
			],
			[
				-79.42705,
				43.63034
			],
			[
				-79.42709,
				43.63044
			],
			[
				-79.42856,
				43.63095
			],
			[
				-79.4295,
				43.63158
			],
			[
				-79.42992,
				43.6317
			],
			[
				-79.43017,
				43.63172
			],
			[
				-79.43085,
				43.63134
			],
			[
				-79.43129,
				43.63132
			],
			[
				-79.43198,
				43.63144
			],
			[
				-79.43235,
				43.63136
			],
			[
				-79.433,
				43.63153
			],
			[
				-79.43355,
				43.63178
			],
			[
				-79.43607,
				43.63238
			],
			[
				-79.43737,
				43.6334
			],
			[
				-79.43836,
				43.63396
			],
			[
				-79.43837,
				43.63405
			],
			[
				-79.44142,
				43.6354
			],
			[
				-79.44158,
				43.63538
			],
			[
				-79.44306,
				43.63583
			],
			[
				-79.44315,
				43.63587
			],
			[
				-79.44307,
				43.6359
			],
			[
				-79.44572,
				43.63688
			],
			[
				-79.446,
				43.63694
			],
			[
				-79.44759,
				43.6369
			],
			[
				-79.44849,
				43.63705
			],
			[
				-79.44877,
				43.63719
			],
			[
				-79.44912,
				43.63717
			],
			[
				-79.45172,
				43.63764
			],
			[
				-79.45209,
				43.63779
			],
			[
				-79.4527,
				43.63785
			],
			[
				-79.45321,
				43.6377
			],
			[
				-79.45384,
				43.63789
			],
			[
				-79.45539,
				43.63801
			],
			[
				-79.45702,
				43.63789
			],
			[
				-79.457,
				43.63794
			],
			[
				-79.45889,
				43.63759
			],
			[
				-79.45931,
				43.63913
			],
			[
				-79.45949,
				43.6395
			],
			[
				-79.4597,
				43.63967
			],
			[
				-79.45992,
				43.63957
			],
			[
				-79.46286,
				43.63897
			],
			[
				-79.46592,
				43.638
			],
			[
				-79.46901,
				43.63744
			],
			[
				-79.47057,
				43.6373
			],
			[
				-79.47142,
				43.63701
			],
			[
				-79.47167,
				43.63702
			],
			[
				-79.47167,
				43.63689
			],
			[
				-79.47261,
				43.63645
			],
			[
				-79.47325,
				43.63593
			],
			[
				-79.47622,
				43.63318
			],
			[
				-79.4763,
				43.633
			],
			[
				-79.47677,
				43.63258
			],
			[
				-79.47946,
				43.63162
			],
			[
				-79.48293,
				43.6307
			],
			[
				-79.50093,
				43.62682
			],
			[
				-79.50488,
				43.62593
			],
			[
				-79.50601,
				43.6256
			],
			[
				-79.50737,
				43.62538
			],
			[
				-79.51474,
				43.62372
			],
			[
				-79.51472,
				43.62363
			],
			[
				-79.51488,
				43.62369
			],
			[
				-79.51554,
				43.62346
			],
			[
				-79.51676,
				43.62323
			],
			[
				-79.51684,
				43.62316
			],
			[
				-79.51677,
				43.62313
			],
			[
				-79.5162,
				43.62169
			]
		],
		"dupe": false
	},
	{
		"id": 396787059,
		"name": "Back from the movies",
		"distance": 9666.2,
		"moving_time": 1311,
		"elapsed_time": 1383,
		"total_elevation_gain": 26.9,
		"start_date_local": "2015-09-20T21:46:18Z",
		"average_speed": 7.373,
		"max_speed": 12.5,
		"calories": 204.2,
		"points": [
			[
				-79.51631,
				43.62187
			],
			[
				-79.5164,
				43.62243
			],
			[
				-79.51648,
				43.62264
			],
			[
				-79.51659,
				43.62267
			],
			[
				-79.51676,
				43.6231
			],
			[
				-79.51556,
				43.62344
			],
			[
				-79.51431,
				43.62365
			],
			[
				-79.51247,
				43.62414
			],
			[
				-79.50589,
				43.62558
			],
			[
				-79.50264,
				43.62637
			],
			[
				-79.5026,
				43.6263
			],
			[
				-79.5022,
				43.62648
			],
			[
				-79.50083,
				43.62679
			],
			[
				-79.4918,
				43.6287
			],
			[
				-79.49165,
				43.62866
			],
			[
				-79.49128,
				43.62881
			],
			[
				-79.48042,
				43.63121
			],
			[
				-79.47724,
				43.63179
			],
			[
				-79.47657,
				43.63219
			],
			[
				-79.47555,
				43.63336
			],
			[
				-79.47312,
				43.63563
			],
			[
				-79.47304,
				43.6358
			],
			[
				-79.47187,
				43.63653
			],
			[
				-79.46991,
				43.63714
			],
			[
				-79.46787,
				43.63739
			],
			[
				-79.46589,
				43.63781
			],
			[
				-79.46132,
				43.63913
			],
			[
				-79.45947,
				43.63949
			],
			[
				-79.45867,
				43.63957
			],
			[
				-79.45653,
				43.63964
			],
			[
				-79.45407,
				43.63961
			],
			[
				-79.45235,
				43.63971
			],
			[
				-79.45095,
				43.63931
			],
			[
				-79.44716,
				43.63854
			],
			[
				-79.44646,
				43.63862
			],
			[
				-79.4449,
				43.639
			],
			[
				-79.44445,
				43.63918
			],
			[
				-79.44357,
				43.63925
			],
			[
				-79.44212,
				43.63952
			],
			[
				-79.43964,
				43.64004
			],
			[
				-79.43926,
				43.64019
			],
			[
				-79.43849,
				43.64026
			],
			[
				-79.43795,
				43.64044
			],
			[
				-79.43714,
				43.64049
			],
			[
				-79.43663,
				43.64066
			],
			[
				-79.43519,
				43.64089
			],
			[
				-79.43459,
				43.6411
			],
			[
				-79.42996,
				43.64192
			],
			[
				-79.42934,
				43.64211
			],
			[
				-79.42874,
				43.64219
			],
			[
				-79.42868,
				43.64203
			],
			[
				-79.42856,
				43.642
			],
			[
				-79.42697,
				43.64258
			],
			[
				-79.42258,
				43.64347
			],
			[
				-79.42236,
				43.64363
			],
			[
				-79.4224,
				43.64383
			],
			[
				-79.42252,
				43.64383
			],
			[
				-79.42604,
				43.6527
			],
			[
				-79.42432,
				43.6531
			]
		],
		"dupe": false
	},
	{
		"id": 399557372,
		"name": "Evening Ride",
		"distance": 2523.3,
		"moving_time": 430,
		"elapsed_time": 464,
		"total_elevation_gain": 11.5,
		"start_date_local": "2015-09-24T20:41:34Z",
		"average_speed": 5.868,
		"max_speed": 12.4,
		"calories": 42.9,
		"points": [
			[
				-79.42347,
				43.65335
			],
			[
				-79.42343,
				43.65331
			],
			[
				-79.42414,
				43.65324
			],
			[
				-79.42433,
				43.65309
			],
			[
				-79.42603,
				43.65272
			],
			[
				-79.42479,
				43.64954
			],
			[
				-79.42496,
				43.64949
			],
			[
				-79.43116,
				43.64967
			],
			[
				-79.43342,
				43.64985
			],
			[
				-79.43574,
				43.64992
			],
			[
				-79.4355,
				43.6495
			],
			[
				-79.43464,
				43.64739
			],
			[
				-79.43241,
				43.64155
			],
			[
				-79.43211,
				43.6415
			],
			[
				-79.43191,
				43.64154
			],
			[
				-79.43193,
				43.6416
			]
		],
		"dupe": false
	},
	{
		"id": 400862206,
		"name": "Ride to Guelph U",
		"distance": 93608.8,
		"moving_time": 13143,
		"elapsed_time": 14898,
		"total_elevation_gain": 431.4,
		"start_date_local": "2015-09-26T14:54:15Z",
		"average_speed": 7.122,
		"max_speed": 16.1,
		"calories": 2087.2,
		"points": [
			[
				-79.42394,
				43.65311
			],
			[
				-79.42602,
				43.65273
			],
			[
				-79.42602,
				43.65263
			],
			[
				-79.42241,
				43.6435
			],
			[
				-79.42479,
				43.64301
			],
			[
				-79.42629,
				43.6426
			],
			[
				-79.42714,
				43.64252
			],
			[
				-79.42691,
				43.64248
			],
			[
				-79.42749,
				43.64241
			],
			[
				-79.42778,
				43.64244
			],
			[
				-79.42814,
				43.64227
			],
			[
				-79.42821,
				43.6423
			],
			[
				-79.42814,
				43.6425
			],
			[
				-79.42874,
				43.64243
			],
			[
				-79.42891,
				43.64249
			],
			[
				-79.42955,
				43.64212
			],
			[
				-79.43089,
				43.64181
			],
			[
				-79.43135,
				43.64163
			],
			[
				-79.43238,
				43.64165
			],
			[
				-79.43301,
				43.64141
			],
			[
				-79.43379,
				43.6413
			],
			[
				-79.43394,
				43.64117
			],
			[
				-79.43431,
				43.64106
			],
			[
				-79.43481,
				43.64104
			],
			[
				-79.43497,
				43.64092
			],
			[
				-79.43526,
				43.64092
			],
			[
				-79.43552,
				43.64076
			],
			[
				-79.43633,
				43.64076
			],
			[
				-79.43648,
				43.64064
			],
			[
				-79.43679,
				43.64063
			],
			[
				-79.43744,
				43.64035
			],
			[
				-79.43789,
				43.64041
			],
			[
				-79.43947,
				43.64007
			],
			[
				-79.44028,
				43.63979
			],
			[
				-79.44135,
				43.63966
			],
			[
				-79.44145,
				43.63957
			],
			[
				-79.44438,
				43.63909
			],
			[
				-79.44493,
				43.63892
			],
			[
				-79.44516,
				43.6389
			],
			[
				-79.44543,
				43.639
			],
			[
				-79.44574,
				43.63877
			],
			[
				-79.44596,
				43.63884
			],
			[
				-79.44724,
				43.63875
			],
			[
				-79.4489,
				43.63886
			],
			[
				-79.45094,
				43.63926
			],
			[
				-79.45259,
				43.63969
			],
			[
				-79.45779,
				43.63967
			],
			[
				-79.45953,
				43.63953
			],
			[
				-79.46112,
				43.63929
			],
			[
				-79.466,
				43.63789
			],
			[
				-79.46818,
				43.63745
			],
			[
				-79.47036,
				43.63714
			],
			[
				-79.47172,
				43.63671
			],
			[
				-79.47306,
				43.63589
			],
			[
				-79.47654,
				43.63254
			],
			[
				-79.47695,
				43.63232
			],
			[
				-79.47814,
				43.63194
			],
			[
				-79.47849,
				43.63162
			],
			[
				-79.47973,
				43.63156
			],
			[
				-79.48249,
				43.63075
			],
			[
				-79.4849,
				43.63019
			],
			[
				-79.48973,
				43.62921
			],
			[
				-79.48977,
				43.62914
			],
			[
				-79.48997,
				43.62918
			],
			[
				-79.49211,
				43.62864
			],
			[
				-79.49635,
				43.62783
			],
			[
				-79.49676,
				43.62766
			],
			[
				-79.50307,
				43.6263
			],
			[
				-79.50313,
				43.62618
			],
			[
				-79.50341,
				43.62624
			],
			[
				-79.50481,
				43.62585
			],
			[
				-79.51174,
				43.62439
			],
			[
				-79.51278,
				43.62408
			],
			[
				-79.51555,
				43.62345
			],
			[
				-79.51597,
				43.62342
			],
			[
				-79.51845,
				43.6228
			],
			[
				-79.52055,
				43.62241
			],
			[
				-79.5218,
				43.62207
			],
			[
				-79.52285,
				43.62191
			],
			[
				-79.52381,
				43.62162
			],
			[
				-79.52665,
				43.6211
			],
			[
				-79.53052,
				43.62009
			],
			[
				-79.53123,
				43.61999
			],
			[
				-79.53473,
				43.61916
			],
			[
				-79.53703,
				43.61876
			],
			[
				-79.54396,
				43.61704
			],
			[
				-79.55281,
				43.61501
			],
			[
				-79.55597,
				43.61438
			],
			[
				-79.55871,
				43.61418
			],
			[
				-79.55934,
				43.61407
			],
			[
				-79.5599,
				43.61392
			],
			[
				-79.56082,
				43.61353
			],
			[
				-79.56797,
				43.60939
			],
			[
				-79.56894,
				43.60875
			],
			[
				-79.56991,
				43.60749
			],
			[
				-79.575,
				43.60315
			],
			[
				-79.57676,
				43.60155
			],
			[
				-79.57921,
				43.5999
			],
			[
				-79.58877,
				43.5916
			],
			[
				-79.593,
				43.58808
			],
			[
				-79.59433,
				43.58669
			],
			[
				-79.59505,
				43.58542
			],
			[
				-79.59553,
				43.58475
			],
			[
				-79.59677,
				43.58359
			],
			[
				-79.59861,
				43.58207
			],
			[
				-79.59976,
				43.581
			],
			[
				-79.60081,
				43.58021
			],
			[
				-79.60381,
				43.57754
			],
			[
				-79.60526,
				43.57637
			],
			[
				-79.60818,
				43.57371
			],
			[
				-79.60872,
				43.57333
			],
			[
				-79.61,
				43.57215
			],
			[
				-79.61118,
				43.57119
			],
			[
				-79.61239,
				43.56992
			],
			[
				-79.6207,
				43.56252
			],
			[
				-79.62204,
				43.56122
			],
			[
				-79.62246,
				43.56089
			],
			[
				-79.62263,
				43.5609
			],
			[
				-79.62274,
				43.56062
			],
			[
				-79.62303,
				43.56036
			],
			[
				-79.62395,
				43.55963
			],
			[
				-79.63321,
				43.55145
			],
			[
				-79.63353,
				43.55137
			],
			[
				-79.63462,
				43.55165
			],
			[
				-79.63522,
				43.55156
			],
			[
				-79.63751,
				43.55147
			],
			[
				-79.63885,
				43.5515
			],
			[
				-79.64071,
				43.55224
			],
			[
				-79.64184,
				43.55293
			],
			[
				-79.64526,
				43.55526
			],
			[
				-79.64551,
				43.55516
			],
			[
				-79.64797,
				43.55303
			],
			[
				-79.64887,
				43.55209
			],
			[
				-79.65011,
				43.54915
			],
			[
				-79.65103,
				43.54762
			],
			[
				-79.65132,
				43.54727
			],
			[
				-79.65303,
				43.54596
			],
			[
				-79.65471,
				43.54494
			],
			[
				-79.65517,
				43.54475
			],
			[
				-79.65525,
				43.54464
			],
			[
				-79.6558,
				43.5445
			],
			[
				-79.65899,
				43.54282
			],
			[
				-79.65968,
				43.54259
			],
			[
				-79.66255,
				43.54023
			],
			[
				-79.66302,
				43.53973
			],
			[
				-79.66598,
				43.53711
			],
			[
				-79.66623,
				43.537
			],
			[
				-79.66741,
				43.53587
			],
			[
				-79.66817,
				43.53529
			],
			[
				-79.66841,
				43.53548
			],
			[
				-79.66877,
				43.53528
			],
			[
				-79.66841,
				43.53501
			],
			[
				-79.66863,
				43.53481
			],
			[
				-79.66856,
				43.5347
			],
			[
				-79.66888,
				43.53457
			],
			[
				-79.67291,
				43.53089
			],
			[
				-79.67334,
				43.53102
			],
			[
				-79.6782,
				43.53383
			],
			[
				-79.6785,
				43.53406
			],
			[
				-79.67911,
				43.53499
			],
			[
				-79.67955,
				43.53529
			],
			[
				-79.68026,
				43.53548
			],
			[
				-79.68458,
				43.53586
			],
			[
				-79.68497,
				43.53602
			],
			[
				-79.68562,
				43.53659
			],
			[
				-79.68651,
				43.53715
			],
			[
				-79.68852,
				43.53867
			],
			[
				-79.68939,
				43.54
			],
			[
				-79.68977,
				43.5408
			],
			[
				-79.69003,
				43.5411
			],
			[
				-79.69082,
				43.5424
			],
			[
				-79.69118,
				43.54287
			],
			[
				-79.6916,
				43.54285
			],
			[
				-79.6931,
				43.54234
			],
			[
				-79.69419,
				43.54167
			],
			[
				-79.69954,
				43.53687
			],
			[
				-79.70018,
				43.53645
			],
			[
				-79.70059,
				43.53591
			],
			[
				-79.70142,
				43.53518
			],
			[
				-79.70183,
				43.53468
			],
			[
				-79.70241,
				43.53418
			],
			[
				-79.70268,
				43.53406
			],
			[
				-79.70398,
				43.53288
			],
			[
				-79.70421,
				43.53275
			],
			[
				-79.70506,
				43.53194
			],
			[
				-79.70528,
				43.53162
			],
			[
				-79.70585,
				43.53118
			],
			[
				-79.70585,
				43.53107
			],
			[
				-79.70708,
				43.53016
			],
			[
				-79.70761,
				43.52958
			],
			[
				-79.70873,
				43.52865
			],
			[
				-79.70938,
				43.52788
			],
			[
				-79.7115,
				43.52613
			],
			[
				-79.7148,
				43.52806
			],
			[
				-79.71551,
				43.5286
			],
			[
				-79.71588,
				43.52915
			],
			[
				-79.71625,
				43.53068
			],
			[
				-79.71644,
				43.53113
			],
			[
				-79.71805,
				43.53312
			],
			[
				-79.71857,
				43.53364
			],
			[
				-79.7221,
				43.53608
			],
			[
				-79.72768,
				43.53935
			],
			[
				-79.72797,
				43.5396
			],
			[
				-79.72816,
				43.5396
			],
			[
				-79.72953,
				43.53844
			],
			[
				-79.72965,
				43.53806
			],
			[
				-79.7302,
				43.53774
			],
			[
				-79.73156,
				43.53643
			],
			[
				-79.73187,
				43.53622
			],
			[
				-79.73307,
				43.53506
			],
			[
				-79.73336,
				43.5349
			],
			[
				-79.7335,
				43.53467
			],
			[
				-79.73505,
				43.53331
			],
			[
				-79.73541,
				43.53274
			],
			[
				-79.73601,
				43.53242
			],
			[
				-79.73834,
				43.53023
			],
			[
				-79.73974,
				43.5291
			],
			[
				-79.74245,
				43.52651
			],
			[
				-79.74971,
				43.51997
			],
			[
				-79.75236,
				43.51747
			],
			[
				-79.76089,
				43.50988
			],
			[
				-79.76106,
				43.50961
			],
			[
				-79.76198,
				43.50865
			],
			[
				-79.76286,
				43.50798
			],
			[
				-79.76463,
				43.50629
			],
			[
				-79.76585,
				43.5053
			],
			[
				-79.76596,
				43.50507
			],
			[
				-79.76688,
				43.50436
			],
			[
				-79.76784,
				43.50335
			],
			[
				-79.76806,
				43.5025
			],
			[
				-79.76807,
				43.50195
			],
			[
				-79.76819,
				43.50144
			],
			[
				-79.76844,
				43.50105
			],
			[
				-79.76898,
				43.50058
			],
			[
				-79.76976,
				43.50022
			],
			[
				-79.77027,
				43.4998
			],
			[
				-79.77227,
				43.49879
			],
			[
				-79.77288,
				43.49858
			],
			[
				-79.77341,
				43.4985
			],
			[
				-79.77431,
				43.4986
			],
			[
				-79.77586,
				43.49902
			],
			[
				-79.77765,
				43.49877
			],
			[
				-79.77843,
				43.49886
			],
			[
				-79.77892,
				43.49916
			],
			[
				-79.77969,
				43.49938
			],
			[
				-79.7811,
				43.50025
			],
			[
				-79.78128,
				43.50023
			],
			[
				-79.78424,
				43.49749
			],
			[
				-79.7851,
				43.49681
			],
			[
				-79.78698,
				43.49511
			],
			[
				-79.7903,
				43.49201
			],
			[
				-79.79069,
				43.49176
			],
			[
				-79.7919,
				43.49058
			],
			[
				-79.79206,
				43.49063
			],
			[
				-79.79365,
				43.49191
			],
			[
				-79.79756,
				43.49469
			],
			[
				-79.80095,
				43.49728
			],
			[
				-79.80212,
				43.49798
			],
			[
				-79.80438,
				43.49979
			],
			[
				-79.80504,
				43.50015
			],
			[
				-79.80565,
				43.50075
			],
			[
				-79.81298,
				43.50604
			],
			[
				-79.81348,
				43.50647
			],
			[
				-79.81334,
				43.5065
			],
			[
				-79.81353,
				43.5062
			],
			[
				-79.81511,
				43.50469
			],
			[
				-79.81728,
				43.50274
			],
			[
				-79.81915,
				43.50119
			],
			[
				-79.82489,
				43.49597
			],
			[
				-79.82792,
				43.49302
			],
			[
				-79.82818,
				43.49287
			],
			[
				-79.83024,
				43.49091
			],
			[
				-79.83101,
				43.49032
			],
			[
				-79.83128,
				43.48996
			],
			[
				-79.83151,
				43.48983
			],
			[
				-79.83289,
				43.48843
			],
			[
				-79.83566,
				43.48601
			],
			[
				-79.83684,
				43.48475
			],
			[
				-79.83768,
				43.48405
			],
			[
				-79.83826,
				43.48338
			],
			[
				-79.83891,
				43.48279
			],
			[
				-79.83979,
				43.48217
			],
			[
				-79.84056,
				43.4814
			],
			[
				-79.84182,
				43.48037
			],
			[
				-79.8424,
				43.47975
			],
			[
				-79.84322,
				43.4791
			],
			[
				-79.84494,
				43.47738
			],
			[
				-79.84536,
				43.47707
			],
			[
				-79.8455,
				43.47684
			],
			[
				-79.84581,
				43.47664
			],
			[
				-79.84703,
				43.47532
			],
			[
				-79.84861,
				43.47412
			],
			[
				-79.84901,
				43.4736
			],
			[
				-79.85035,
				43.47249
			],
			[
				-79.85047,
				43.47229
			],
			[
				-79.85159,
				43.47138
			],
			[
				-79.85631,
				43.46709
			],
			[
				-79.85626,
				43.46714
			],
			[
				-79.85671,
				43.46704
			],
			[
				-79.85682,
				43.46711
			],
			[
				-79.85697,
				43.46686
			],
			[
				-79.85809,
				43.46569
			],
			[
				-79.86174,
				43.46236
			],
			[
				-79.8621,
				43.46175
			],
			[
				-79.86667,
				43.45751
			],
			[
				-79.86785,
				43.45771
			],
			[
				-79.89467,
				43.4765
			],
			[
				-79.89502,
				43.47642
			],
			[
				-79.89829,
				43.47334
			],
			[
				-79.89956,
				43.47224
			],
			[
				-79.90198,
				43.47034
			],
			[
				-79.90482,
				43.46768
			],
			[
				-79.90584,
				43.46685
			],
			[
				-79.90717,
				43.46548
			],
			[
				-79.90808,
				43.46467
			],
			[
				-79.90841,
				43.46425
			],
			[
				-79.91024,
				43.46257
			],
			[
				-79.91184,
				43.46097
			],
			[
				-79.91287,
				43.46003
			],
			[
				-79.91485,
				43.45844
			],
			[
				-79.9157,
				43.45746
			],
			[
				-79.91583,
				43.45747
			],
			[
				-79.91654,
				43.45691
			],
			[
				-79.9195,
				43.45398
			],
			[
				-79.92058,
				43.45266
			],
			[
				-79.9221,
				43.45121
			],
			[
				-79.92283,
				43.45065
			],
			[
				-79.92308,
				43.45033
			],
			[
				-79.92394,
				43.44956
			],
			[
				-79.92407,
				43.44934
			],
			[
				-79.9242,
				43.44933
			],
			[
				-79.92465,
				43.44893
			],
			[
				-79.92509,
				43.44869
			],
			[
				-79.92536,
				43.44827
			],
			[
				-79.92631,
				43.44758
			],
			[
				-79.92649,
				43.44732
			],
			[
				-79.92804,
				43.44582
			],
			[
				-79.92846,
				43.44555
			],
			[
				-79.92967,
				43.44441
			],
			[
				-79.92983,
				43.44436
			],
			[
				-79.93004,
				43.44407
			],
			[
				-79.93006,
				43.44392
			],
			[
				-79.93287,
				43.44152
			],
			[
				-79.93431,
				43.44011
			],
			[
				-79.93523,
				43.43947
			],
			[
				-79.93632,
				43.43838
			],
			[
				-79.93749,
				43.43738
			],
			[
				-79.93786,
				43.43692
			],
			[
				-79.93816,
				43.43683
			],
			[
				-79.9386,
				43.43649
			],
			[
				-79.94021,
				43.43494
			],
			[
				-79.94283,
				43.43266
			],
			[
				-79.94317,
				43.43227
			],
			[
				-79.94359,
				43.43126
			],
			[
				-79.94501,
				43.42999
			],
			[
				-79.94534,
				43.42957
			],
			[
				-79.9461,
				43.42829
			],
			[
				-79.94649,
				43.42799
			],
			[
				-79.94736,
				43.42767
			],
			[
				-79.94811,
				43.42722
			],
			[
				-79.94854,
				43.42673
			],
			[
				-79.94867,
				43.4267
			],
			[
				-79.94897,
				43.42637
			],
			[
				-79.94959,
				43.42592
			],
			[
				-79.95055,
				43.42505
			],
			[
				-79.95081,
				43.42492
			],
			[
				-79.95148,
				43.42417
			],
			[
				-79.95273,
				43.42308
			],
			[
				-79.95429,
				43.42198
			],
			[
				-79.95441,
				43.42179
			],
			[
				-79.95535,
				43.42098
			],
			[
				-79.95572,
				43.42053
			],
			[
				-79.95672,
				43.41979
			],
			[
				-79.95687,
				43.41955
			],
			[
				-79.95759,
				43.41889
			],
			[
				-79.95779,
				43.41852
			],
			[
				-79.95922,
				43.41737
			],
			[
				-79.95938,
				43.41736
			],
			[
				-79.95978,
				43.41757
			],
			[
				-79.96081,
				43.41826
			],
			[
				-79.96233,
				43.41948
			],
			[
				-79.96388,
				43.42054
			],
			[
				-79.96509,
				43.42148
			],
			[
				-79.969,
				43.42407
			],
			[
				-79.96997,
				43.42485
			],
			[
				-79.97389,
				43.42754
			],
			[
				-79.97473,
				43.42825
			],
			[
				-79.97515,
				43.42846
			],
			[
				-79.97586,
				43.42907
			],
			[
				-79.97647,
				43.42945
			],
			[
				-79.97688,
				43.42985
			],
			[
				-79.97726,
				43.43011
			],
			[
				-79.97785,
				43.43035
			],
			[
				-79.97817,
				43.43061
			],
			[
				-79.9784,
				43.43095
			],
			[
				-79.97899,
				43.43136
			],
			[
				-79.97933,
				43.43149
			],
			[
				-79.97992,
				43.43207
			],
			[
				-79.98078,
				43.43246
			],
			[
				-79.98173,
				43.43332
			],
			[
				-79.9822,
				43.43359
			],
			[
				-79.98246,
				43.43387
			],
			[
				-79.98294,
				43.43414
			],
			[
				-79.9834,
				43.43456
			],
			[
				-79.98476,
				43.4354
			],
			[
				-79.986,
				43.43637
			],
			[
				-79.9871,
				43.43707
			],
			[
				-79.98712,
				43.43701
			],
			[
				-79.98697,
				43.43688
			],
			[
				-79.98629,
				43.43641
			],
			[
				-79.98494,
				43.43559
			],
			[
				-79.98585,
				43.43504
			],
			[
				-79.98613,
				43.43475
			],
			[
				-79.98905,
				43.43276
			],
			[
				-79.98983,
				43.43235
			],
			[
				-79.99338,
				43.42958
			],
			[
				-79.9949,
				43.42858
			],
			[
				-80.00081,
				43.4243
			],
			[
				-80.00248,
				43.42318
			],
			[
				-80.00339,
				43.4227
			],
			[
				-80.00469,
				43.42175
			],
			[
				-80.00496,
				43.42164
			],
			[
				-80.00524,
				43.42132
			],
			[
				-80.01091,
				43.41735
			],
			[
				-80.01256,
				43.41605
			],
			[
				-80.01279,
				43.41602
			],
			[
				-80.01363,
				43.4164
			],
			[
				-80.0187,
				43.41789
			],
			[
				-80.01997,
				43.41859
			],
			[
				-80.02053,
				43.41906
			],
			[
				-80.02119,
				43.41943
			],
			[
				-80.02284,
				43.42066
			],
			[
				-80.02359,
				43.42112
			],
			[
				-80.02552,
				43.4226
			],
			[
				-80.02657,
				43.42327
			],
			[
				-80.02722,
				43.42381
			],
			[
				-80.02761,
				43.42401
			],
			[
				-80.02814,
				43.42447
			],
			[
				-80.03103,
				43.42652
			],
			[
				-80.03176,
				43.42718
			],
			[
				-80.03241,
				43.42756
			],
			[
				-80.03461,
				43.4292
			],
			[
				-80.03617,
				43.43019
			],
			[
				-80.03732,
				43.43111
			],
			[
				-80.04215,
				43.43454
			],
			[
				-80.04254,
				43.43491
			],
			[
				-80.04299,
				43.43515
			],
			[
				-80.04304,
				43.43529
			],
			[
				-80.04376,
				43.43571
			],
			[
				-80.04587,
				43.43718
			],
			[
				-80.04694,
				43.43805
			],
			[
				-80.04729,
				43.43821
			],
			[
				-80.04834,
				43.43907
			],
			[
				-80.05115,
				43.44107
			],
			[
				-80.05149,
				43.4414
			],
			[
				-80.05279,
				43.4422
			],
			[
				-80.05363,
				43.44291
			],
			[
				-80.05526,
				43.444
			],
			[
				-80.05765,
				43.44583
			],
			[
				-80.05838,
				43.44625
			],
			[
				-80.05888,
				43.44637
			],
			[
				-80.06107,
				43.4465
			],
			[
				-80.06436,
				43.44658
			],
			[
				-80.06652,
				43.44649
			],
			[
				-80.06758,
				43.44662
			],
			[
				-80.06799,
				43.4465
			],
			[
				-80.06857,
				43.44617
			],
			[
				-80.06969,
				43.44526
			],
			[
				-80.07411,
				43.44199
			],
			[
				-80.07445,
				43.44202
			],
			[
				-80.07459,
				43.4422
			],
			[
				-80.07578,
				43.443
			],
			[
				-80.07795,
				43.44476
			],
			[
				-80.0796,
				43.4459
			],
			[
				-80.08389,
				43.44917
			],
			[
				-80.08591,
				43.45059
			],
			[
				-80.08859,
				43.45273
			],
			[
				-80.08945,
				43.45327
			],
			[
				-80.0911,
				43.4546
			],
			[
				-80.09205,
				43.4552
			],
			[
				-80.093,
				43.45599
			],
			[
				-80.09806,
				43.45974
			],
			[
				-80.0985,
				43.46014
			],
			[
				-80.09931,
				43.46064
			],
			[
				-80.09981,
				43.46074
			],
			[
				-80.10067,
				43.46049
			],
			[
				-80.10156,
				43.45987
			],
			[
				-80.10171,
				43.45985
			],
			[
				-80.10179,
				43.45997
			],
			[
				-80.10063,
				43.46157
			],
			[
				-80.10066,
				43.46171
			],
			[
				-80.10124,
				43.46217
			],
			[
				-80.10411,
				43.46428
			],
			[
				-80.10555,
				43.46545
			],
			[
				-80.11073,
				43.46916
			],
			[
				-80.11388,
				43.47154
			],
			[
				-80.11744,
				43.47402
			],
			[
				-80.12044,
				43.47633
			],
			[
				-80.12158,
				43.47709
			],
			[
				-80.12217,
				43.47763
			],
			[
				-80.12559,
				43.48013
			],
			[
				-80.12609,
				43.48043
			],
			[
				-80.12904,
				43.48263
			],
			[
				-80.12947,
				43.48286
			],
			[
				-80.13243,
				43.48519
			],
			[
				-80.13531,
				43.48727
			],
			[
				-80.13598,
				43.48785
			],
			[
				-80.13682,
				43.48834
			],
			[
				-80.13742,
				43.48892
			],
			[
				-80.13813,
				43.48936
			],
			[
				-80.13856,
				43.48976
			],
			[
				-80.14317,
				43.49305
			],
			[
				-80.14501,
				43.49449
			],
			[
				-80.14924,
				43.49757
			],
			[
				-80.14958,
				43.49791
			],
			[
				-80.15149,
				43.49921
			],
			[
				-80.15186,
				43.49936
			],
			[
				-80.1533,
				43.49961
			],
			[
				-80.15363,
				43.4998
			],
			[
				-80.1538,
				43.50012
			],
			[
				-80.15341,
				43.50053
			],
			[
				-80.15375,
				43.50088
			],
			[
				-80.15722,
				43.50338
			],
			[
				-80.15841,
				43.50438
			],
			[
				-80.16011,
				43.50554
			],
			[
				-80.16732,
				43.51082
			],
			[
				-80.17043,
				43.51318
			],
			[
				-80.17454,
				43.51605
			],
			[
				-80.17792,
				43.51854
			],
			[
				-80.17836,
				43.51879
			],
			[
				-80.17953,
				43.51975
			],
			[
				-80.18175,
				43.52128
			],
			[
				-80.18497,
				43.52369
			],
			[
				-80.18557,
				43.52405
			],
			[
				-80.18565,
				43.52408
			],
			[
				-80.18588,
				43.52394
			],
			[
				-80.18588,
				43.52379
			],
			[
				-80.19565,
				43.51665
			],
			[
				-80.19932,
				43.51379
			],
			[
				-80.20038,
				43.51447
			],
			[
				-80.20543,
				43.5181
			],
			[
				-80.20624,
				43.51876
			],
			[
				-80.20763,
				43.51968
			],
			[
				-80.21247,
				43.52212
			],
			[
				-80.21737,
				43.52446
			],
			[
				-80.21897,
				43.525
			],
			[
				-80.2199,
				43.52521
			]
		],
		"dupe": false
	},
	{
		"id": 400684067,
		"name": "Afternoon Ride",
		"distance": 2597.4,
		"moving_time": 620,
		"elapsed_time": 649,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-26T13:12:01Z",
		"average_speed": 4.189,
		"max_speed": 9.3,
		"calories": 44.5,
		"points": [
			[
				-79.42368,
				43.65414
			],
			[
				-79.42237,
				43.65445
			],
			[
				-79.42156,
				43.65453
			],
			[
				-79.42038,
				43.65484
			],
			[
				-79.42002,
				43.65484
			],
			[
				-79.41924,
				43.6551
			],
			[
				-79.41847,
				43.65522
			],
			[
				-79.41744,
				43.65523
			],
			[
				-79.41693,
				43.65512
			],
			[
				-79.41657,
				43.65516
			],
			[
				-79.41577,
				43.65494
			],
			[
				-79.41572,
				43.65522
			],
			[
				-79.4159,
				43.65509
			],
			[
				-79.41576,
				43.65515
			],
			[
				-79.4156,
				43.65511
			],
			[
				-79.41564,
				43.65489
			],
			[
				-79.41526,
				43.65489
			],
			[
				-79.41413,
				43.6552
			],
			[
				-79.41394,
				43.65516
			],
			[
				-79.4131,
				43.65535
			],
			[
				-79.41243,
				43.65536
			],
			[
				-79.41212,
				43.65549
			],
			[
				-79.41154,
				43.65557
			],
			[
				-79.41126,
				43.65567
			],
			[
				-79.41121,
				43.65577
			],
			[
				-79.41027,
				43.65587
			],
			[
				-79.40911,
				43.65616
			],
			[
				-79.40791,
				43.65635
			],
			[
				-79.40795,
				43.65643
			],
			[
				-79.40772,
				43.65641
			],
			[
				-79.40794,
				43.65637
			],
			[
				-79.40454,
				43.65706
			],
			[
				-79.40459,
				43.65703
			],
			[
				-79.40447,
				43.65692
			],
			[
				-79.40368,
				43.65499
			],
			[
				-79.40233,
				43.65522
			],
			[
				-79.40207,
				43.65468
			],
			[
				-79.40196,
				43.6546
			],
			[
				-79.40168,
				43.65456
			],
			[
				-79.40095,
				43.6548
			],
			[
				-79.40075,
				43.65476
			],
			[
				-79.40064,
				43.65464
			],
			[
				-79.40001,
				43.65286
			],
			[
				-79.40001,
				43.65294
			]
		],
		"dupe": false
	},
	{
		"id": 400715362,
		"name": "Afternoon Ride",
		"distance": 2547.8,
		"moving_time": 462,
		"elapsed_time": 466,
		"total_elevation_gain": 0,
		"start_date_local": "2015-09-26T13:56:48Z",
		"average_speed": 5.515,
		"max_speed": 13.8,
		"calories": 40.2,
		"points": [
			[
				-79.40002,
				43.6529
			],
			[
				-79.40007,
				43.65282
			],
			[
				-79.39994,
				43.65288
			],
			[
				-79.3999,
				43.65272
			],
			[
				-79.3999,
				43.65258
			],
			[
				-79.40001,
				43.65249
			],
			[
				-79.40067,
				43.65242
			],
			[
				-79.40098,
				43.65235
			],
			[
				-79.40101,
				43.65227
			],
			[
				-79.40208,
				43.65215
			],
			[
				-79.40376,
				43.65174
			],
			[
				-79.40431,
				43.65189
			],
			[
				-79.40545,
				43.65237
			],
			[
				-79.40586,
				43.65234
			],
			[
				-79.40608,
				43.65242
			],
			[
				-79.40632,
				43.65225
			],
			[
				-79.41828,
				43.64983
			],
			[
				-79.41845,
				43.6499
			],
			[
				-79.41886,
				43.6513
			],
			[
				-79.41832,
				43.65157
			],
			[
				-79.41837,
				43.65166
			],
			[
				-79.41835,
				43.65158
			],
			[
				-79.41902,
				43.65141
			],
			[
				-79.42127,
				43.65098
			],
			[
				-79.42153,
				43.6514
			],
			[
				-79.42167,
				43.65191
			],
			[
				-79.42217,
				43.65295
			],
			[
				-79.42232,
				43.65346
			],
			[
				-79.42338,
				43.6533
			],
			[
				-79.42342,
				43.65335
			]
		],
		"dupe": false
	},
	{
		"id": 401717853,
		"name": "Guelph to Aldershot GO",
		"distance": 43037,
		"moving_time": 6300,
		"elapsed_time": 6824,
		"total_elevation_gain": 131.4,
		"start_date_local": "2015-09-27T15:50:58Z",
		"average_speed": 6.831,
		"max_speed": 15.2,
		"calories": 766.7,
		"points": [
			[
				-80.22479,
				43.53519
			],
			[
				-80.22643,
				43.53628
			],
			[
				-80.22664,
				43.5365
			],
			[
				-80.22555,
				43.53735
			],
			[
				-80.22533,
				43.53761
			],
			[
				-80.22325,
				43.53898
			],
			[
				-80.22044,
				43.54114
			],
			[
				-80.2169,
				43.54371
			],
			[
				-80.21482,
				43.54518
			],
			[
				-80.21456,
				43.54517
			],
			[
				-80.20869,
				43.54104
			],
			[
				-80.20764,
				43.54015
			],
			[
				-80.20647,
				43.53941
			],
			[
				-80.2048,
				43.53814
			],
			[
				-80.2045,
				43.538
			],
			[
				-80.20341,
				43.53706
			],
			[
				-80.19906,
				43.53397
			],
			[
				-80.19834,
				43.53336
			],
			[
				-80.19667,
				43.53223
			],
			[
				-80.18724,
				43.52524
			],
			[
				-80.18522,
				43.52388
			],
			[
				-80.18417,
				43.52292
			],
			[
				-80.17805,
				43.51851
			],
			[
				-80.17746,
				43.51818
			],
			[
				-80.17252,
				43.51449
			],
			[
				-80.16931,
				43.51229
			],
			[
				-80.16421,
				43.50848
			],
			[
				-80.15852,
				43.50439
			],
			[
				-80.15758,
				43.50361
			],
			[
				-80.15352,
				43.50062
			],
			[
				-80.15353,
				43.50041
			],
			[
				-80.15383,
				43.50017
			],
			[
				-80.15345,
				43.49967
			],
			[
				-80.15303,
				43.49948
			],
			[
				-80.15173,
				43.49926
			],
			[
				-80.1514,
				43.49911
			],
			[
				-80.14851,
				43.49687
			],
			[
				-80.14755,
				43.49628
			],
			[
				-80.14406,
				43.49374
			],
			[
				-80.14346,
				43.49341
			],
			[
				-80.14217,
				43.49237
			],
			[
				-80.14146,
				43.49197
			],
			[
				-80.1404,
				43.49111
			],
			[
				-80.13998,
				43.49089
			],
			[
				-80.13861,
				43.48969
			],
			[
				-80.13812,
				43.4894
			],
			[
				-80.13798,
				43.48939
			],
			[
				-80.13759,
				43.48898
			],
			[
				-80.13664,
				43.48833
			],
			[
				-80.13648,
				43.48813
			],
			[
				-80.13609,
				43.48793
			],
			[
				-80.13288,
				43.48545
			],
			[
				-80.13151,
				43.48457
			],
			[
				-80.13156,
				43.48454
			],
			[
				-80.13119,
				43.4843
			],
			[
				-80.13074,
				43.48385
			],
			[
				-80.13015,
				43.48347
			],
			[
				-80.12934,
				43.48278
			],
			[
				-80.12912,
				43.4827
			],
			[
				-80.12766,
				43.48164
			],
			[
				-80.12727,
				43.48145
			],
			[
				-80.12686,
				43.48108
			],
			[
				-80.12679,
				43.48093
			],
			[
				-80.126,
				43.48035
			],
			[
				-80.12601,
				43.48027
			],
			[
				-80.12521,
				43.47979
			],
			[
				-80.12433,
				43.47904
			],
			[
				-80.12231,
				43.4776
			],
			[
				-80.12097,
				43.47654
			],
			[
				-80.11895,
				43.47517
			],
			[
				-80.11742,
				43.47402
			],
			[
				-80.11688,
				43.47373
			],
			[
				-80.11567,
				43.47274
			],
			[
				-80.11547,
				43.47267
			],
			[
				-80.11412,
				43.47172
			],
			[
				-80.11351,
				43.47118
			],
			[
				-80.11307,
				43.47095
			],
			[
				-80.11272,
				43.4706
			],
			[
				-80.11167,
				43.4699
			],
			[
				-80.11156,
				43.46966
			],
			[
				-80.11086,
				43.46934
			],
			[
				-80.11076,
				43.46919
			],
			[
				-80.11006,
				43.46869
			],
			[
				-80.10996,
				43.46849
			],
			[
				-80.10908,
				43.46797
			],
			[
				-80.10814,
				43.4672
			],
			[
				-80.10091,
				43.462
			],
			[
				-80.10087,
				43.46183
			],
			[
				-80.10059,
				43.46154
			],
			[
				-80.10147,
				43.46049
			],
			[
				-80.10183,
				43.45997
			],
			[
				-80.10184,
				43.45982
			],
			[
				-80.10152,
				43.45977
			],
			[
				-80.1008,
				43.46031
			],
			[
				-80.09995,
				43.46058
			],
			[
				-80.09945,
				43.46057
			],
			[
				-80.09866,
				43.46019
			],
			[
				-80.09829,
				43.45986
			],
			[
				-80.09785,
				43.45965
			],
			[
				-80.09697,
				43.45901
			],
			[
				-80.09582,
				43.4581
			],
			[
				-80.09541,
				43.45789
			],
			[
				-80.09339,
				43.45628
			],
			[
				-80.09195,
				43.4553
			],
			[
				-80.0902,
				43.45387
			],
			[
				-80.08696,
				43.45144
			],
			[
				-80.08609,
				43.45095
			],
			[
				-80.08437,
				43.44974
			],
			[
				-80.08315,
				43.4487
			],
			[
				-80.08165,
				43.44766
			],
			[
				-80.08144,
				43.44738
			],
			[
				-80.0807,
				43.44679
			],
			[
				-80.07856,
				43.44518
			],
			[
				-80.07782,
				43.44473
			],
			[
				-80.07743,
				43.4444
			],
			[
				-80.07719,
				43.44408
			],
			[
				-80.07451,
				43.44211
			],
			[
				-80.07439,
				43.44195
			],
			[
				-80.0742,
				43.4419
			],
			[
				-80.07377,
				43.44218
			],
			[
				-80.0713,
				43.44409
			],
			[
				-80.0686,
				43.44586
			],
			[
				-80.06828,
				43.44617
			],
			[
				-80.06779,
				43.44648
			],
			[
				-80.06729,
				43.44656
			],
			[
				-80.06556,
				43.44646
			],
			[
				-80.0637,
				43.44655
			],
			[
				-80.0628,
				43.44644
			],
			[
				-80.05873,
				43.44629
			],
			[
				-80.05808,
				43.44606
			],
			[
				-80.05716,
				43.44535
			],
			[
				-80.05558,
				43.44432
			],
			[
				-80.05524,
				43.44418
			],
			[
				-80.05406,
				43.44326
			],
			[
				-80.05275,
				43.44238
			],
			[
				-80.05174,
				43.44155
			],
			[
				-80.0512,
				43.44122
			],
			[
				-80.05041,
				43.44052
			],
			[
				-80.04742,
				43.43828
			],
			[
				-80.04593,
				43.43735
			],
			[
				-80.04493,
				43.43653
			],
			[
				-80.04416,
				43.43606
			],
			[
				-80.04303,
				43.43509
			],
			[
				-80.04178,
				43.43423
			],
			[
				-80.04144,
				43.43408
			],
			[
				-80.04064,
				43.4334
			],
			[
				-80.03841,
				43.43186
			],
			[
				-80.03721,
				43.4309
			],
			[
				-80.03487,
				43.42932
			],
			[
				-80.03338,
				43.42813
			],
			[
				-80.02917,
				43.42526
			],
			[
				-80.02719,
				43.42374
			],
			[
				-80.02707,
				43.42355
			],
			[
				-80.02617,
				43.42304
			],
			[
				-80.02333,
				43.42093
			],
			[
				-80.02269,
				43.42065
			],
			[
				-80.0193,
				43.41807
			],
			[
				-80.01877,
				43.41781
			],
			[
				-80.01374,
				43.41638
			],
			[
				-80.01167,
				43.41539
			],
			[
				-80.01052,
				43.41462
			],
			[
				-80.00904,
				43.41341
			],
			[
				-80.00843,
				43.41299
			],
			[
				-80.00801,
				43.4128
			],
			[
				-80.00699,
				43.41192
			],
			[
				-80.00517,
				43.41067
			],
			[
				-80.00355,
				43.40971
			],
			[
				-80.00057,
				43.40747
			],
			[
				-80.00024,
				43.40735
			],
			[
				-79.99863,
				43.40613
			],
			[
				-79.99826,
				43.40596
			],
			[
				-79.99739,
				43.40519
			],
			[
				-79.99715,
				43.40506
			],
			[
				-79.99576,
				43.40356
			],
			[
				-79.99333,
				43.40187
			],
			[
				-79.99259,
				43.40109
			],
			[
				-79.99148,
				43.40046
			],
			[
				-79.98999,
				43.39944
			],
			[
				-79.98982,
				43.39922
			],
			[
				-79.98885,
				43.39857
			],
			[
				-79.98877,
				43.39849
			],
			[
				-79.9888,
				43.39842
			],
			[
				-79.98764,
				43.39758
			],
			[
				-79.98661,
				43.39704
			],
			[
				-79.98541,
				43.39666
			],
			[
				-79.98233,
				43.39547
			],
			[
				-79.97943,
				43.39357
			],
			[
				-79.97882,
				43.39304
			],
			[
				-79.97797,
				43.39251
			],
			[
				-79.97616,
				43.39109
			],
			[
				-79.97503,
				43.39045
			],
			[
				-79.97431,
				43.38985
			],
			[
				-79.97433,
				43.38972
			],
			[
				-79.97325,
				43.38897
			],
			[
				-79.97306,
				43.38875
			],
			[
				-79.9727,
				43.38865
			],
			[
				-79.97191,
				43.38791
			],
			[
				-79.97004,
				43.38658
			],
			[
				-79.96944,
				43.38632
			],
			[
				-79.96885,
				43.3858
			],
			[
				-79.96791,
				43.38529
			],
			[
				-79.9671,
				43.38462
			],
			[
				-79.9657,
				43.38364
			],
			[
				-79.96525,
				43.38314
			],
			[
				-79.96479,
				43.38232
			],
			[
				-79.96434,
				43.38173
			],
			[
				-79.96383,
				43.38077
			],
			[
				-79.96344,
				43.38051
			],
			[
				-79.96304,
				43.38005
			],
			[
				-79.96019,
				43.37812
			],
			[
				-79.95939,
				43.37744
			],
			[
				-79.95777,
				43.37651
			],
			[
				-79.957,
				43.37596
			],
			[
				-79.95656,
				43.37547
			],
			[
				-79.95616,
				43.3753
			],
			[
				-79.9553,
				43.37469
			],
			[
				-79.95435,
				43.37385
			],
			[
				-79.95317,
				43.37326
			],
			[
				-79.9526,
				43.37278
			],
			[
				-79.95208,
				43.3725
			],
			[
				-79.95186,
				43.3722
			],
			[
				-79.95102,
				43.37166
			],
			[
				-79.95089,
				43.3715
			],
			[
				-79.95037,
				43.37124
			],
			[
				-79.94999,
				43.37089
			],
			[
				-79.94981,
				43.37089
			],
			[
				-79.94923,
				43.37034
			],
			[
				-79.94883,
				43.37014
			],
			[
				-79.94805,
				43.36949
			],
			[
				-79.94692,
				43.36876
			],
			[
				-79.94654,
				43.36833
			],
			[
				-79.94597,
				43.36787
			],
			[
				-79.94429,
				43.36691
			],
			[
				-79.94344,
				43.36606
			],
			[
				-79.9426,
				43.36553
			],
			[
				-79.94198,
				43.3653
			],
			[
				-79.94088,
				43.36455
			],
			[
				-79.94021,
				43.36406
			],
			[
				-79.93993,
				43.3637
			],
			[
				-79.93939,
				43.36329
			],
			[
				-79.93739,
				43.36193
			],
			[
				-79.93602,
				43.36083
			],
			[
				-79.93565,
				43.36067
			],
			[
				-79.93541,
				43.36043
			],
			[
				-79.93439,
				43.35981
			],
			[
				-79.93302,
				43.35869
			],
			[
				-79.93273,
				43.35835
			],
			[
				-79.93213,
				43.358
			],
			[
				-79.92946,
				43.35597
			],
			[
				-79.92891,
				43.35565
			],
			[
				-79.9285,
				43.35529
			],
			[
				-79.92755,
				43.35485
			],
			[
				-79.92619,
				43.35393
			],
			[
				-79.92442,
				43.35249
			],
			[
				-79.92367,
				43.35206
			],
			[
				-79.9222,
				43.35089
			],
			[
				-79.92179,
				43.35072
			],
			[
				-79.9215,
				43.35041
			],
			[
				-79.92138,
				43.35042
			],
			[
				-79.92044,
				43.34958
			],
			[
				-79.91996,
				43.34903
			],
			[
				-79.91907,
				43.34832
			],
			[
				-79.91757,
				43.34733
			],
			[
				-79.91595,
				43.34611
			],
			[
				-79.90806,
				43.34054
			],
			[
				-79.90657,
				43.3394
			],
			[
				-79.90604,
				43.33885
			],
			[
				-79.90545,
				43.33839
			],
			[
				-79.90507,
				43.3379
			],
			[
				-79.90441,
				43.33734
			],
			[
				-79.90425,
				43.33726
			],
			[
				-79.90389,
				43.33732
			],
			[
				-79.90378,
				43.33739
			],
			[
				-79.90369,
				43.33762
			],
			[
				-79.90265,
				43.33838
			],
			[
				-79.90248,
				43.33835
			],
			[
				-79.89598,
				43.3356
			],
			[
				-79.89287,
				43.33338
			],
			[
				-79.89284,
				43.33326
			],
			[
				-79.89287,
				43.33335
			],
			[
				-79.89257,
				43.33307
			],
			[
				-79.89239,
				43.33308
			],
			[
				-79.89217,
				43.33293
			],
			[
				-79.89097,
				43.33189
			],
			[
				-79.88924,
				43.3322
			],
			[
				-79.88907,
				43.33193
			],
			[
				-79.88898,
				43.33156
			],
			[
				-79.88879,
				43.3313
			],
			[
				-79.88823,
				43.33112
			],
			[
				-79.88804,
				43.33119
			],
			[
				-79.88781,
				43.33099
			],
			[
				-79.88729,
				43.33091
			],
			[
				-79.88638,
				43.33092
			],
			[
				-79.88613,
				43.3308
			],
			[
				-79.88587,
				43.33056
			],
			[
				-79.88566,
				43.33006
			],
			[
				-79.8851,
				43.32951
			],
			[
				-79.88449,
				43.32926
			],
			[
				-79.88288,
				43.32827
			],
			[
				-79.88077,
				43.32689
			],
			[
				-79.88031,
				43.3265
			],
			[
				-79.87897,
				43.32517
			],
			[
				-79.87715,
				43.32262
			],
			[
				-79.87634,
				43.32213
			],
			[
				-79.87421,
				43.32162
			],
			[
				-79.87288,
				43.32119
			],
			[
				-79.87065,
				43.31966
			],
			[
				-79.86955,
				43.31879
			],
			[
				-79.86914,
				43.31812
			],
			[
				-79.86884,
				43.31711
			],
			[
				-79.86858,
				43.31674
			],
			[
				-79.86838,
				43.31659
			],
			[
				-79.86793,
				43.31634
			],
			[
				-79.86683,
				43.31595
			],
			[
				-79.86572,
				43.31587
			],
			[
				-79.86505,
				43.31562
			],
			[
				-79.86084,
				43.31265
			],
			[
				-79.85909,
				43.31156
			],
			[
				-79.85884,
				43.31145
			],
			[
				-79.85856,
				43.31153
			],
			[
				-79.8583,
				43.31176
			],
			[
				-79.85765,
				43.31307
			],
			[
				-79.85727,
				43.31339
			],
			[
				-79.85683,
				43.31361
			]
		],
		"dupe": false
	},
	{
		"id": 401758448,
		"name": "Evening Ride",
		"distance": 2786.2,
		"moving_time": 531,
		"elapsed_time": 551,
		"total_elevation_gain": 11.5,
		"start_date_local": "2015-09-27T19:07:21Z",
		"average_speed": 5.247,
		"max_speed": 13.3,
		"calories": 65.1,
		"points": [
			[
				-79.41967,
				43.63616
			],
			[
				-79.41986,
				43.63616
			],
			[
				-79.41982,
				43.63624
			],
			[
				-79.42013,
				43.6372
			],
			[
				-79.42126,
				43.64004
			],
			[
				-79.42062,
				43.64026
			],
			[
				-79.41804,
				43.64086
			],
			[
				-79.41743,
				43.64091
			],
			[
				-79.41727,
				43.641
			],
			[
				-79.41738,
				43.64115
			],
			[
				-79.41792,
				43.64128
			],
			[
				-79.4181,
				43.64118
			],
			[
				-79.42123,
				43.64129
			],
			[
				-79.42143,
				43.64132
			],
			[
				-79.42164,
				43.64148
			],
			[
				-79.42156,
				43.64156
			],
			[
				-79.4216,
				43.64166
			],
			[
				-79.42173,
				43.64169
			],
			[
				-79.42467,
				43.64926
			],
			[
				-79.42459,
				43.64935
			],
			[
				-79.42472,
				43.64973
			],
			[
				-79.42495,
				43.65013
			],
			[
				-79.42508,
				43.65057
			],
			[
				-79.42521,
				43.65059
			],
			[
				-79.42603,
				43.65267
			],
			[
				-79.42338,
				43.6533
			],
			[
				-79.42345,
				43.65332
			]
		],
		"dupe": false
	},
	{
		"id": 406556050,
		"name": "Evening Ride",
		"distance": 3151.8,
		"moving_time": 508,
		"elapsed_time": 636,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-04T18:37:00Z",
		"average_speed": 6.204,
		"max_speed": 13.6,
		"calories": 65.2,
		"points": [
			[
				-79.42209,
				43.65252
			],
			[
				-79.42193,
				43.65232
			],
			[
				-79.42148,
				43.65095
			],
			[
				-79.42081,
				43.64941
			],
			[
				-79.42047,
				43.64932
			],
			[
				-79.42,
				43.6495
			],
			[
				-79.41857,
				43.64976
			],
			[
				-79.41841,
				43.64973
			],
			[
				-79.41845,
				43.64986
			],
			[
				-79.41823,
				43.64981
			],
			[
				-79.4179,
				43.64994
			],
			[
				-79.41496,
				43.65048
			],
			[
				-79.41384,
				43.65077
			],
			[
				-79.41295,
				43.65088
			],
			[
				-79.41221,
				43.65109
			],
			[
				-79.41121,
				43.65124
			],
			[
				-79.41089,
				43.65119
			],
			[
				-79.4112,
				43.65112
			],
			[
				-79.41081,
				43.65135
			],
			[
				-79.40689,
				43.65217
			],
			[
				-79.40542,
				43.65234
			],
			[
				-79.40426,
				43.65182
			],
			[
				-79.40371,
				43.65168
			],
			[
				-79.40089,
				43.65223
			],
			[
				-79.39905,
				43.65269
			],
			[
				-79.39857,
				43.65271
			],
			[
				-79.39894,
				43.65261
			],
			[
				-79.3987,
				43.6521
			],
			[
				-79.39803,
				43.65224
			],
			[
				-79.39785,
				43.65209
			],
			[
				-79.3978,
				43.65194
			],
			[
				-79.39783,
				43.65187
			],
			[
				-79.39839,
				43.65174
			],
			[
				-79.39861,
				43.65152
			],
			[
				-79.39851,
				43.6514
			],
			[
				-79.3985,
				43.65106
			],
			[
				-79.39762,
				43.64895
			],
			[
				-79.39773,
				43.64888
			],
			[
				-79.3976,
				43.64887
			],
			[
				-79.39802,
				43.64877
			],
			[
				-79.3981,
				43.64834
			],
			[
				-79.39736,
				43.64846
			],
			[
				-79.39751,
				43.64857
			],
			[
				-79.39733,
				43.64853
			]
		],
		"dupe": false
	},
	{
		"id": 407791347,
		"name": "Work to Queensway Cineplex",
		"distance": 8561.5,
		"moving_time": 1390,
		"elapsed_time": 1781,
		"total_elevation_gain": 30.1,
		"start_date_local": "2015-10-06T18:31:21Z",
		"average_speed": 6.159,
		"max_speed": 12.1,
		"calories": 187.4,
		"points": [
			[
				-79.42929,
				43.64396
			],
			[
				-79.42949,
				43.64422
			],
			[
				-79.42933,
				43.64399
			],
			[
				-79.42928,
				43.64365
			],
			[
				-79.42912,
				43.64348
			],
			[
				-79.42913,
				43.64333
			],
			[
				-79.42887,
				43.64277
			],
			[
				-79.42895,
				43.64212
			],
			[
				-79.4291,
				43.64221
			],
			[
				-79.42931,
				43.6421
			],
			[
				-79.42958,
				43.64211
			],
			[
				-79.43058,
				43.64186
			],
			[
				-79.4326,
				43.64151
			],
			[
				-79.43378,
				43.64121
			],
			[
				-79.43417,
				43.64121
			],
			[
				-79.43453,
				43.64107
			],
			[
				-79.43525,
				43.64105
			],
			[
				-79.4356,
				43.64091
			],
			[
				-79.43662,
				43.64074
			],
			[
				-79.43701,
				43.64053
			],
			[
				-79.43863,
				43.64032
			],
			[
				-79.44004,
				43.63996
			],
			[
				-79.44033,
				43.63997
			],
			[
				-79.44146,
				43.63969
			],
			[
				-79.44242,
				43.63956
			],
			[
				-79.44329,
				43.63932
			],
			[
				-79.4457,
				43.63899
			],
			[
				-79.44562,
				43.63897
			],
			[
				-79.44613,
				43.63882
			],
			[
				-79.44732,
				43.63873
			],
			[
				-79.44935,
				43.63916
			],
			[
				-79.45001,
				43.63924
			],
			[
				-79.45012,
				43.63919
			],
			[
				-79.45021,
				43.6393
			],
			[
				-79.45168,
				43.63954
			],
			[
				-79.45279,
				43.63994
			],
			[
				-79.45291,
				43.63984
			],
			[
				-79.45341,
				43.63992
			],
			[
				-79.45451,
				43.63992
			],
			[
				-79.45809,
				43.63982
			],
			[
				-79.46136,
				43.63936
			],
			[
				-79.4669,
				43.63783
			],
			[
				-79.47067,
				43.63721
			],
			[
				-79.47165,
				43.63689
			],
			[
				-79.47288,
				43.63633
			],
			[
				-79.47334,
				43.63594
			],
			[
				-79.47445,
				43.63473
			],
			[
				-79.47639,
				43.63291
			],
			[
				-79.47679,
				43.63259
			],
			[
				-79.47739,
				43.63233
			],
			[
				-79.47995,
				43.63147
			],
			[
				-79.48086,
				43.6313
			],
			[
				-79.48206,
				43.63092
			],
			[
				-79.48977,
				43.62925
			],
			[
				-79.48976,
				43.6291
			],
			[
				-79.49002,
				43.6292
			],
			[
				-79.50564,
				43.62575
			],
			[
				-79.50683,
				43.6255
			],
			[
				-79.50724,
				43.62554
			],
			[
				-79.50717,
				43.62515
			],
			[
				-79.50732,
				43.62532
			],
			[
				-79.50745,
				43.62529
			],
			[
				-79.50719,
				43.62511
			],
			[
				-79.50763,
				43.6252
			],
			[
				-79.50786,
				43.62507
			],
			[
				-79.50788,
				43.62522
			],
			[
				-79.50785,
				43.62483
			],
			[
				-79.50776,
				43.62465
			],
			[
				-79.50766,
				43.62463
			],
			[
				-79.50748,
				43.62472
			],
			[
				-79.50798,
				43.62517
			],
			[
				-79.50925,
				43.62496
			],
			[
				-79.5147,
				43.62373
			],
			[
				-79.51481,
				43.62379
			],
			[
				-79.51555,
				43.62345
			],
			[
				-79.5167,
				43.62325
			],
			[
				-79.51675,
				43.62306
			],
			[
				-79.51689,
				43.62301
			],
			[
				-79.5167,
				43.62294
			],
			[
				-79.51614,
				43.62157
			],
			[
				-79.51603,
				43.62103
			]
		],
		"dupe": false
	},
	{
		"id": 408467739,
		"name": "Evening Ride",
		"distance": 3483.1,
		"moving_time": 621,
		"elapsed_time": 643,
		"total_elevation_gain": 13.7,
		"start_date_local": "2015-10-07T19:08:26Z",
		"average_speed": 5.609,
		"max_speed": 11.1,
		"calories": 78.5,
		"points": [
			[
				-79.42943,
				43.64436
			],
			[
				-79.42933,
				43.64435
			],
			[
				-79.42937,
				43.6444
			],
			[
				-79.42929,
				43.64419
			],
			[
				-79.4293,
				43.64432
			],
			[
				-79.42943,
				43.6443
			],
			[
				-79.42951,
				43.64474
			],
			[
				-79.43062,
				43.64767
			],
			[
				-79.4309,
				43.64817
			],
			[
				-79.43137,
				43.6496
			],
			[
				-79.43156,
				43.64972
			],
			[
				-79.43295,
				43.64971
			],
			[
				-79.4407,
				43.65027
			],
			[
				-79.44079,
				43.65021
			],
			[
				-79.44083,
				43.65028
			],
			[
				-79.44155,
				43.65036
			],
			[
				-79.44205,
				43.65053
			],
			[
				-79.44216,
				43.65043
			],
			[
				-79.44223,
				43.65057
			],
			[
				-79.44369,
				43.65087
			],
			[
				-79.44378,
				43.65079
			],
			[
				-79.44405,
				43.65094
			],
			[
				-79.4467,
				43.65157
			],
			[
				-79.44874,
				43.65238
			],
			[
				-79.44992,
				43.65295
			],
			[
				-79.45009,
				43.65311
			],
			[
				-79.45109,
				43.65347
			],
			[
				-79.45171,
				43.65379
			],
			[
				-79.45197,
				43.65431
			],
			[
				-79.45207,
				43.65499
			],
			[
				-79.45237,
				43.65576
			],
			[
				-79.45235,
				43.65621
			],
			[
				-79.45248,
				43.65631
			],
			[
				-79.45237,
				43.65637
			],
			[
				-79.45382,
				43.65615
			],
			[
				-79.45543,
				43.65574
			],
			[
				-79.45843,
				43.65513
			],
			[
				-79.45911,
				43.6549
			],
			[
				-79.4598,
				43.65483
			],
			[
				-79.45983,
				43.65469
			],
			[
				-79.45969,
				43.65468
			]
		],
		"dupe": false
	},
	{
		"id": 408541021,
		"name": "Night Ride",
		"distance": 3986.9,
		"moving_time": 572,
		"elapsed_time": 576,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-07T21:59:21Z",
		"average_speed": 6.97,
		"max_speed": 12.6,
		"calories": 76.3,
		"points": [
			[
				-79.45937,
				43.6551
			],
			[
				-79.45925,
				43.65522
			],
			[
				-79.45923,
				43.65513
			],
			[
				-79.45908,
				43.65508
			],
			[
				-79.45921,
				43.65507
			],
			[
				-79.45921,
				43.65498
			],
			[
				-79.45903,
				43.65496
			],
			[
				-79.45903,
				43.65489
			],
			[
				-79.45902,
				43.65498
			],
			[
				-79.45895,
				43.65489
			],
			[
				-79.45906,
				43.65478
			],
			[
				-79.45866,
				43.65502
			],
			[
				-79.45801,
				43.65505
			],
			[
				-79.45516,
				43.65574
			],
			[
				-79.45386,
				43.65598
			],
			[
				-79.45362,
				43.6561
			],
			[
				-79.45262,
				43.6562
			],
			[
				-79.44939,
				43.65687
			],
			[
				-79.44878,
				43.65709
			],
			[
				-79.44648,
				43.65745
			],
			[
				-79.44278,
				43.65827
			],
			[
				-79.44273,
				43.65774
			],
			[
				-79.44218,
				43.65651
			],
			[
				-79.44169,
				43.65495
			],
			[
				-79.44013,
				43.65113
			],
			[
				-79.44015,
				43.65099
			],
			[
				-79.43999,
				43.65088
			],
			[
				-79.43959,
				43.65106
			],
			[
				-79.4379,
				43.65148
			],
			[
				-79.43637,
				43.65174
			],
			[
				-79.43565,
				43.65194
			],
			[
				-79.43272,
				43.65243
			],
			[
				-79.43088,
				43.65286
			],
			[
				-79.4301,
				43.65281
			],
			[
				-79.42974,
				43.65287
			],
			[
				-79.42647,
				43.65356
			],
			[
				-79.42632,
				43.6534
			],
			[
				-79.42605,
				43.65273
			],
			[
				-79.4233,
				43.65331
			],
			[
				-79.4233,
				43.65366
			],
			[
				-79.42339,
				43.6536
			],
			[
				-79.42323,
				43.65333
			],
			[
				-79.42346,
				43.65328
			]
		],
		"dupe": false
	},
	{
		"id": 409730349,
		"name": "Evening Ride",
		"distance": 10796.7,
		"moving_time": 1656,
		"elapsed_time": 2110,
		"total_elevation_gain": 28.8,
		"start_date_local": "2015-10-09T20:15:52Z",
		"average_speed": 6.52,
		"max_speed": 13.7,
		"calories": 228.2,
		"points": [
			[
				-79.4296,
				43.64475
			],
			[
				-79.42954,
				43.64436
			],
			[
				-79.42946,
				43.64454
			],
			[
				-79.42964,
				43.64478
			],
			[
				-79.4298,
				43.6455
			],
			[
				-79.43061,
				43.64753
			],
			[
				-79.43131,
				43.64957
			],
			[
				-79.43097,
				43.64965
			],
			[
				-79.42735,
				43.64957
			],
			[
				-79.42561,
				43.64949
			],
			[
				-79.42502,
				43.64939
			],
			[
				-79.4248,
				43.64959
			],
			[
				-79.42605,
				43.65271
			],
			[
				-79.42363,
				43.65324
			],
			[
				-79.42354,
				43.65369
			],
			[
				-79.42363,
				43.65398
			],
			[
				-79.42351,
				43.65414
			],
			[
				-79.42318,
				43.65429
			],
			[
				-79.42219,
				43.65454
			],
			[
				-79.42128,
				43.65466
			],
			[
				-79.41958,
				43.65509
			],
			[
				-79.41874,
				43.6552
			],
			[
				-79.41826,
				43.65517
			],
			[
				-79.41813,
				43.65526
			],
			[
				-79.41615,
				43.65506
			],
			[
				-79.41581,
				43.65494
			],
			[
				-79.4142,
				43.65511
			],
			[
				-79.41327,
				43.65541
			],
			[
				-79.41157,
				43.65563
			],
			[
				-79.40884,
				43.65622
			],
			[
				-79.40847,
				43.65637
			],
			[
				-79.40791,
				43.65632
			],
			[
				-79.40754,
				43.65648
			],
			[
				-79.40729,
				43.65647
			],
			[
				-79.40484,
				43.65701
			],
			[
				-79.40443,
				43.6572
			],
			[
				-79.4025,
				43.65744
			],
			[
				-79.40244,
				43.65751
			],
			[
				-79.40136,
				43.65766
			],
			[
				-79.40126,
				43.65777
			],
			[
				-79.40112,
				43.65772
			],
			[
				-79.40037,
				43.65793
			],
			[
				-79.39946,
				43.65798
			],
			[
				-79.3909,
				43.65979
			],
			[
				-79.39063,
				43.65973
			],
			[
				-79.38992,
				43.65993
			],
			[
				-79.38955,
				43.65995
			],
			[
				-79.3895,
				43.66008
			],
			[
				-79.38922,
				43.66005
			],
			[
				-79.3881,
				43.66038
			],
			[
				-79.38739,
				43.66046
			],
			[
				-79.38715,
				43.6606
			],
			[
				-79.38637,
				43.66075
			],
			[
				-79.38537,
				43.66093
			],
			[
				-79.38524,
				43.66087
			],
			[
				-79.38313,
				43.66136
			],
			[
				-79.38188,
				43.66135
			],
			[
				-79.38073,
				43.6615
			],
			[
				-79.38064,
				43.66167
			],
			[
				-79.38024,
				43.6618
			],
			[
				-79.37897,
				43.66191
			],
			[
				-79.37835,
				43.66211
			],
			[
				-79.37795,
				43.66209
			],
			[
				-79.37769,
				43.66222
			],
			[
				-79.37689,
				43.66224
			],
			[
				-79.37683,
				43.66241
			],
			[
				-79.37673,
				43.66237
			],
			[
				-79.3768,
				43.66236
			],
			[
				-79.36814,
				43.66421
			],
			[
				-79.36823,
				43.66427
			],
			[
				-79.36814,
				43.6643
			],
			[
				-79.36794,
				43.66414
			],
			[
				-79.36732,
				43.6626
			],
			[
				-79.36736,
				43.66244
			],
			[
				-79.36707,
				43.66202
			],
			[
				-79.36675,
				43.66199
			],
			[
				-79.35987,
				43.66352
			],
			[
				-79.35586,
				43.66462
			],
			[
				-79.35547,
				43.66463
			],
			[
				-79.35517,
				43.66482
			],
			[
				-79.35324,
				43.6654
			],
			[
				-79.34991,
				43.66597
			],
			[
				-79.34888,
				43.66631
			],
			[
				-79.34809,
				43.66643
			],
			[
				-79.34784,
				43.66656
			],
			[
				-79.34501,
				43.66706
			],
			[
				-79.3428,
				43.66759
			],
			[
				-79.34234,
				43.66777
			],
			[
				-79.34188,
				43.6678
			],
			[
				-79.33966,
				43.66826
			],
			[
				-79.33951,
				43.66837
			],
			[
				-79.33891,
				43.66847
			],
			[
				-79.3386,
				43.66866
			],
			[
				-79.33837,
				43.6687
			],
			[
				-79.33821,
				43.66864
			],
			[
				-79.33688,
				43.66901
			],
			[
				-79.33591,
				43.66913
			],
			[
				-79.33564,
				43.66921
			],
			[
				-79.33566,
				43.66931
			],
			[
				-79.33478,
				43.66942
			],
			[
				-79.3303,
				43.67039
			],
			[
				-79.32994,
				43.67055
			],
			[
				-79.32865,
				43.67085
			],
			[
				-79.32805,
				43.67094
			],
			[
				-79.32799,
				43.67088
			],
			[
				-79.32756,
				43.67107
			],
			[
				-79.32567,
				43.67151
			],
			[
				-79.32403,
				43.67178
			],
			[
				-79.32357,
				43.67198
			],
			[
				-79.32309,
				43.67201
			],
			[
				-79.32296,
				43.67205
			],
			[
				-79.32297,
				43.67217
			],
			[
				-79.3229,
				43.67221
			],
			[
				-79.32275,
				43.67218
			],
			[
				-79.32277,
				43.67231
			]
		],
		"dupe": false
	},
	{
		"id": 412545046,
		"name": "Evening Ride",
		"distance": 4459.2,
		"moving_time": 819,
		"elapsed_time": 925,
		"total_elevation_gain": 20.6,
		"start_date_local": "2015-10-13T19:36:43Z",
		"average_speed": 5.445,
		"max_speed": 12.4,
		"calories": 96.8,
		"points": [
			[
				-79.39792,
				43.64543
			],
			[
				-79.39783,
				43.64587
			],
			[
				-79.39813,
				43.64638
			],
			[
				-79.39859,
				43.64748
			],
			[
				-79.39912,
				43.64741
			],
			[
				-79.39933,
				43.64747
			],
			[
				-79.39943,
				43.64746
			],
			[
				-79.39961,
				43.64725
			],
			[
				-79.39981,
				43.64721
			],
			[
				-79.3999,
				43.64731
			],
			[
				-79.40032,
				43.64712
			],
			[
				-79.40373,
				43.64647
			],
			[
				-79.4048,
				43.64663
			],
			[
				-79.4062,
				43.64636
			],
			[
				-79.40618,
				43.64604
			],
			[
				-79.40714,
				43.64592
			],
			[
				-79.40823,
				43.64555
			],
			[
				-79.40906,
				43.64539
			],
			[
				-79.4095,
				43.64533
			],
			[
				-79.40959,
				43.64539
			],
			[
				-79.40984,
				43.64601
			],
			[
				-79.40998,
				43.64608
			],
			[
				-79.41118,
				43.64579
			],
			[
				-79.41148,
				43.64579
			],
			[
				-79.41511,
				43.64506
			],
			[
				-79.41717,
				43.65014
			],
			[
				-79.42058,
				43.64937
			],
			[
				-79.42387,
				43.64955
			],
			[
				-79.42417,
				43.64935
			],
			[
				-79.4247,
				43.64953
			],
			[
				-79.42482,
				43.64961
			],
			[
				-79.42535,
				43.65093
			],
			[
				-79.42932,
				43.66111
			],
			[
				-79.4281,
				43.66138
			],
			[
				-79.4279,
				43.66154
			],
			[
				-79.42792,
				43.66163
			]
		],
		"dupe": false
	},
	{
		"id": 412612506,
		"name": "Night Ride",
		"distance": 4242.3,
		"moving_time": 618,
		"elapsed_time": 675,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-13T22:15:03Z",
		"average_speed": 6.865,
		"max_speed": 14.7,
		"calories": 74.4,
		"points": [
			[
				-79.42802,
				43.6615
			],
			[
				-79.42699,
				43.66163
			],
			[
				-79.42311,
				43.66254
			],
			[
				-79.41369,
				43.66453
			],
			[
				-79.4136,
				43.66445
			],
			[
				-79.41221,
				43.66101
			],
			[
				-79.4116,
				43.66114
			],
			[
				-79.41155,
				43.66108
			],
			[
				-79.41121,
				43.66122
			],
			[
				-79.40989,
				43.66151
			],
			[
				-79.40955,
				43.661
			],
			[
				-79.40913,
				43.65974
			],
			[
				-79.40808,
				43.65729
			],
			[
				-79.40793,
				43.65659
			],
			[
				-79.40761,
				43.656
			],
			[
				-79.40705,
				43.65451
			],
			[
				-79.40639,
				43.65315
			],
			[
				-79.40598,
				43.652
			],
			[
				-79.40588,
				43.65196
			],
			[
				-79.40591,
				43.65181
			],
			[
				-79.40572,
				43.65116
			],
			[
				-79.4046,
				43.64873
			],
			[
				-79.40438,
				43.64791
			],
			[
				-79.40393,
				43.64685
			],
			[
				-79.404,
				43.64677
			],
			[
				-79.40389,
				43.64646
			],
			[
				-79.4033,
				43.64525
			],
			[
				-79.40324,
				43.64519
			],
			[
				-79.39819,
				43.64622
			],
			[
				-79.39791,
				43.64549
			]
		],
		"dupe": false
	},
	{
		"id": 414903685,
		"name": "Afternoon Ride",
		"distance": 2587.2,
		"moving_time": 494,
		"elapsed_time": 594,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-17T14:07:00Z",
		"average_speed": 5.237,
		"max_speed": 12.2,
		"calories": 42.5,
		"points": [
			[
				-79.44542,
				43.65541
			],
			[
				-79.44501,
				43.65488
			],
			[
				-79.44426,
				43.65291
			],
			[
				-79.44314,
				43.65104
			],
			[
				-79.44307,
				43.6507
			],
			[
				-79.44305,
				43.6508
			],
			[
				-79.44273,
				43.65057
			],
			[
				-79.44109,
				43.65021
			],
			[
				-79.44013,
				43.65018
			],
			[
				-79.43992,
				43.65014
			],
			[
				-79.43988,
				43.65006
			],
			[
				-79.43985,
				43.65015
			],
			[
				-79.4391,
				43.65018
			],
			[
				-79.4384,
				43.64999
			],
			[
				-79.4369,
				43.6501
			],
			[
				-79.43592,
				43.64996
			],
			[
				-79.43309,
				43.64979
			],
			[
				-79.43275,
				43.64967
			],
			[
				-79.43222,
				43.64972
			],
			[
				-79.43151,
				43.64962
			],
			[
				-79.43115,
				43.6497
			],
			[
				-79.42974,
				43.64966
			],
			[
				-79.42871,
				43.64952
			],
			[
				-79.42848,
				43.64959
			],
			[
				-79.42752,
				43.64949
			],
			[
				-79.42687,
				43.64955
			],
			[
				-79.42676,
				43.64939
			],
			[
				-79.42677,
				43.64884
			],
			[
				-79.42639,
				43.64852
			],
			[
				-79.42534,
				43.64846
			],
			[
				-79.42489,
				43.64768
			],
			[
				-79.42411,
				43.6478
			],
			[
				-79.42346,
				43.64618
			],
			[
				-79.42322,
				43.64623
			],
			[
				-79.42319,
				43.64614
			],
			[
				-79.42332,
				43.64612
			],
			[
				-79.42324,
				43.64616
			]
		],
		"dupe": false
	},
	{
		"id": 415903982,
		"name": "Night Ride",
		"distance": 3924.1,
		"moving_time": 762,
		"elapsed_time": 815,
		"total_elevation_gain": 20.1,
		"start_date_local": "2015-10-18T21:07:21Z",
		"average_speed": 5.15,
		"max_speed": 14.3,
		"calories": 90.5,
		"points": [
			[
				-79.398,
				43.64546
			],
			[
				-79.39785,
				43.6456
			],
			[
				-79.39794,
				43.64576
			],
			[
				-79.39792,
				43.64604
			],
			[
				-79.39804,
				43.64619
			],
			[
				-79.39804,
				43.64627
			],
			[
				-79.39794,
				43.64631
			],
			[
				-79.39281,
				43.64727
			],
			[
				-79.38544,
				43.64889
			],
			[
				-79.38608,
				43.65001
			],
			[
				-79.3874,
				43.65303
			],
			[
				-79.38736,
				43.65317
			],
			[
				-79.38644,
				43.65336
			],
			[
				-79.38542,
				43.65383
			],
			[
				-79.38319,
				43.65427
			],
			[
				-79.38413,
				43.65671
			],
			[
				-79.38598,
				43.66106
			],
			[
				-79.38608,
				43.66153
			],
			[
				-79.38587,
				43.66161
			],
			[
				-79.3861,
				43.6618
			],
			[
				-79.38768,
				43.66562
			],
			[
				-79.38877,
				43.668
			],
			[
				-79.38834,
				43.66827
			],
			[
				-79.38742,
				43.66848
			],
			[
				-79.3875,
				43.66873
			],
			[
				-79.38756,
				43.66844
			],
			[
				-79.38748,
				43.66869
			],
			[
				-79.38755,
				43.66865
			],
			[
				-79.38763,
				43.66889
			],
			[
				-79.38772,
				43.66891
			]
		],
		"dupe": false
	},
	{
		"id": 415931663,
		"name": "Night Ride",
		"distance": 4174.1,
		"moving_time": 619,
		"elapsed_time": 644,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-18T23:50:40Z",
		"average_speed": 6.743,
		"max_speed": 17.4,
		"calories": 78.2,
		"points": [
			[
				-79.3865,
				43.66925
			],
			[
				-79.38652,
				43.66916
			],
			[
				-79.38732,
				43.66875
			],
			[
				-79.3876,
				43.66876
			],
			[
				-79.38838,
				43.66859
			],
			[
				-79.38854,
				43.66846
			],
			[
				-79.38894,
				43.66841
			],
			[
				-79.38892,
				43.66831
			],
			[
				-79.38898,
				43.66842
			],
			[
				-79.38897,
				43.66836
			],
			[
				-79.3898,
				43.66814
			],
			[
				-79.39003,
				43.66798
			],
			[
				-79.39353,
				43.66721
			],
			[
				-79.39327,
				43.66606
			],
			[
				-79.39385,
				43.66562
			],
			[
				-79.39404,
				43.66507
			],
			[
				-79.39442,
				43.66493
			],
			[
				-79.39822,
				43.66413
			],
			[
				-79.3991,
				43.66369
			],
			[
				-79.40128,
				43.66324
			],
			[
				-79.40135,
				43.66332
			],
			[
				-79.40155,
				43.6632
			],
			[
				-79.40307,
				43.66287
			],
			[
				-79.40852,
				43.6618
			],
			[
				-79.4144,
				43.66053
			],
			[
				-79.41521,
				43.66037
			],
			[
				-79.4153,
				43.66049
			],
			[
				-79.41562,
				43.66029
			],
			[
				-79.42424,
				43.65854
			],
			[
				-79.42387,
				43.65719
			],
			[
				-79.42362,
				43.65672
			],
			[
				-79.42244,
				43.65353
			],
			[
				-79.42324,
				43.65333
			],
			[
				-79.42332,
				43.65337
			]
		],
		"dupe": false
	},
	{
		"id": 416130389,
		"name": "Morning Ride",
		"distance": 4491,
		"moving_time": 834,
		"elapsed_time": 916,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-19T09:50:02Z",
		"average_speed": 5.385,
		"max_speed": 13.9,
		"calories": 88.9,
		"points": [
			[
				-79.42325,
				43.65333
			],
			[
				-79.42338,
				43.65331
			],
			[
				-79.4233,
				43.65323
			],
			[
				-79.42321,
				43.65338
			],
			[
				-79.42238,
				43.65343
			],
			[
				-79.4223,
				43.65306
			],
			[
				-79.42181,
				43.65197
			],
			[
				-79.42165,
				43.65132
			],
			[
				-79.42117,
				43.65036
			],
			[
				-79.42083,
				43.64941
			],
			[
				-79.42087,
				43.64945
			],
			[
				-79.42092,
				43.64936
			],
			[
				-79.42006,
				43.64754
			],
			[
				-79.41952,
				43.64578
			],
			[
				-79.41932,
				43.64543
			],
			[
				-79.41896,
				43.64429
			],
			[
				-79.41885,
				43.64417
			],
			[
				-79.41856,
				43.64407
			],
			[
				-79.41821,
				43.64423
			],
			[
				-79.41757,
				43.64433
			],
			[
				-79.41735,
				43.64444
			],
			[
				-79.4164,
				43.64463
			],
			[
				-79.41632,
				43.64458
			],
			[
				-79.41615,
				43.64479
			],
			[
				-79.41535,
				43.64484
			],
			[
				-79.41441,
				43.6451
			],
			[
				-79.41413,
				43.6451
			],
			[
				-79.41382,
				43.64524
			],
			[
				-79.41329,
				43.64527
			],
			[
				-79.41319,
				43.64522
			],
			[
				-79.41255,
				43.64364
			],
			[
				-79.41152,
				43.64086
			],
			[
				-79.4114,
				43.64082
			],
			[
				-79.41117,
				43.6408
			],
			[
				-79.40615,
				43.64185
			],
			[
				-79.40449,
				43.64137
			],
			[
				-79.40194,
				43.64191
			],
			[
				-79.40159,
				43.641
			],
			[
				-79.40145,
				43.64077
			],
			[
				-79.40134,
				43.64076
			],
			[
				-79.39127,
				43.64342
			],
			[
				-79.3869,
				43.64434
			],
			[
				-79.3869,
				43.64444
			],
			[
				-79.38668,
				43.64439
			],
			[
				-79.3864,
				43.64444
			]
		],
		"dupe": false
	},
	{
		"id": 416142222,
		"name": "Morning Ride",
		"distance": 4314.2,
		"moving_time": 794,
		"elapsed_time": 834,
		"total_elevation_gain": 10.4,
		"start_date_local": "2015-10-19T10:20:27Z",
		"average_speed": 5.434,
		"max_speed": 13.8,
		"calories": 76.9,
		"points": [
			[
				-79.38686,
				43.64465
			],
			[
				-79.38667,
				43.64445
			],
			[
				-79.38684,
				43.64452
			],
			[
				-79.3881,
				43.64428
			],
			[
				-79.38846,
				43.64436
			],
			[
				-79.38924,
				43.64389
			],
			[
				-79.38959,
				43.6439
			],
			[
				-79.39025,
				43.64364
			],
			[
				-79.39048,
				43.64382
			],
			[
				-79.39106,
				43.64372
			],
			[
				-79.3914,
				43.64351
			],
			[
				-79.39142,
				43.64337
			],
			[
				-79.39378,
				43.64277
			],
			[
				-79.39388,
				43.6428
			],
			[
				-79.39443,
				43.64259
			],
			[
				-79.3988,
				43.64141
			],
			[
				-79.39955,
				43.64316
			],
			[
				-79.40484,
				43.64209
			],
			[
				-79.40476,
				43.6422
			],
			[
				-79.40474,
				43.64214
			],
			[
				-79.40487,
				43.64209
			],
			[
				-79.41147,
				43.64074
			],
			[
				-79.41153,
				43.64087
			],
			[
				-79.41186,
				43.64066
			],
			[
				-79.41237,
				43.64056
			],
			[
				-79.41636,
				43.64071
			],
			[
				-79.41653,
				43.64079
			],
			[
				-79.41669,
				43.64115
			],
			[
				-79.41912,
				43.64068
			],
			[
				-79.42133,
				43.63999
			],
			[
				-79.42125,
				43.63994
			],
			[
				-79.42106,
				43.64027
			],
			[
				-79.42349,
				43.6398
			],
			[
				-79.42357,
				43.63984
			],
			[
				-79.42375,
				43.6404
			],
			[
				-79.42394,
				43.64056
			],
			[
				-79.42667,
				43.6412
			],
			[
				-79.42775,
				43.64162
			],
			[
				-79.42823,
				43.64158
			],
			[
				-79.42835,
				43.64167
			],
			[
				-79.42857,
				43.64213
			],
			[
				-79.42867,
				43.64266
			],
			[
				-79.42909,
				43.64384
			],
			[
				-79.42925,
				43.6442
			],
			[
				-79.42934,
				43.64421
			],
			[
				-79.42948,
				43.64442
			],
			[
				-79.42944,
				43.64432
			]
		],
		"dupe": false
	},
	{
		"id": 418911011,
		"name": "Evening Ride",
		"distance": 10858.8,
		"moving_time": 1916,
		"elapsed_time": 2154,
		"total_elevation_gain": 40.8,
		"start_date_local": "2015-10-23T20:32:13Z",
		"average_speed": 5.667,
		"max_speed": 11,
		"calories": 229,
		"points": [
			[
				-79.42346,
				43.65343
			],
			[
				-79.42124,
				43.65466
			],
			[
				-79.42062,
				43.6548
			],
			[
				-79.42031,
				43.65496
			],
			[
				-79.41914,
				43.65509
			],
			[
				-79.41897,
				43.65532
			],
			[
				-79.41962,
				43.6559
			],
			[
				-79.4198,
				43.6562
			],
			[
				-79.41983,
				43.65655
			],
			[
				-79.41967,
				43.65693
			],
			[
				-79.41991,
				43.65732
			],
			[
				-79.42068,
				43.65927
			],
			[
				-79.42054,
				43.65928
			],
			[
				-79.42084,
				43.65968
			],
			[
				-79.42207,
				43.66273
			],
			[
				-79.41903,
				43.66336
			],
			[
				-79.41907,
				43.66343
			],
			[
				-79.41888,
				43.6634
			],
			[
				-79.41893,
				43.66339
			],
			[
				-79.41653,
				43.66397
			],
			[
				-79.41162,
				43.66496
			],
			[
				-79.41044,
				43.6653
			],
			[
				-79.4093,
				43.6655
			],
			[
				-79.40773,
				43.6659
			],
			[
				-79.40727,
				43.66591
			],
			[
				-79.40587,
				43.66628
			],
			[
				-79.40548,
				43.66625
			],
			[
				-79.40462,
				43.6665
			],
			[
				-79.40324,
				43.6667
			],
			[
				-79.40119,
				43.66722
			],
			[
				-79.4005,
				43.66731
			],
			[
				-79.40051,
				43.66744
			],
			[
				-79.40031,
				43.66734
			],
			[
				-79.3999,
				43.66752
			],
			[
				-79.39949,
				43.6675
			],
			[
				-79.3979,
				43.66782
			],
			[
				-79.39776,
				43.66787
			],
			[
				-79.39774,
				43.668
			],
			[
				-79.39758,
				43.66788
			],
			[
				-79.3974,
				43.66802
			],
			[
				-79.39735,
				43.66794
			],
			[
				-79.39743,
				43.66797
			],
			[
				-79.3954,
				43.66833
			],
			[
				-79.38947,
				43.66973
			],
			[
				-79.38441,
				43.67071
			],
			[
				-79.38077,
				43.67156
			],
			[
				-79.37645,
				43.67243
			],
			[
				-79.3761,
				43.67247
			],
			[
				-79.37609,
				43.67237
			],
			[
				-79.37594,
				43.67234
			],
			[
				-79.37387,
				43.67216
			],
			[
				-79.37203,
				43.67192
			],
			[
				-79.37144,
				43.67177
			],
			[
				-79.37089,
				43.67186
			],
			[
				-79.37013,
				43.6723
			],
			[
				-79.36988,
				43.6725
			],
			[
				-79.36991,
				43.67264
			],
			[
				-79.36961,
				43.67269
			],
			[
				-79.36731,
				43.67427
			],
			[
				-79.36692,
				43.67451
			],
			[
				-79.3664,
				43.67466
			],
			[
				-79.35918,
				43.67605
			],
			[
				-79.3584,
				43.67627
			],
			[
				-79.35688,
				43.67649
			],
			[
				-79.35656,
				43.67662
			],
			[
				-79.35359,
				43.67716
			],
			[
				-79.3529,
				43.67738
			],
			[
				-79.35216,
				43.67747
			],
			[
				-79.35202,
				43.67756
			],
			[
				-79.34516,
				43.67881
			],
			[
				-79.3427,
				43.6794
			],
			[
				-79.34237,
				43.6794
			],
			[
				-79.33827,
				43.68012
			],
			[
				-79.33542,
				43.68081
			],
			[
				-79.33096,
				43.68175
			],
			[
				-79.32933,
				43.68202
			],
			[
				-79.32788,
				43.67865
			],
			[
				-79.3268,
				43.67886
			],
			[
				-79.32672,
				43.6788
			],
			[
				-79.32617,
				43.67713
			],
			[
				-79.32627,
				43.67677
			],
			[
				-79.3265,
				43.67646
			],
			[
				-79.32621,
				43.67617
			],
			[
				-79.32616,
				43.6762
			],
			[
				-79.32621,
				43.67619
			],
			[
				-79.32616,
				43.67613
			],
			[
				-79.32568,
				43.67593
			],
			[
				-79.32401,
				43.67183
			],
			[
				-79.32187,
				43.67228
			],
			[
				-79.3218,
				43.67238
			],
			[
				-79.32193,
				43.67269
			],
			[
				-79.3219,
				43.67258
			]
		],
		"dupe": false
	},
	{
		"id": 421384948,
		"name": "Lunch Ride",
		"distance": 3341.1,
		"moving_time": 611,
		"elapsed_time": 620,
		"total_elevation_gain": 11.4,
		"start_date_local": "2015-10-27T12:48:42Z",
		"average_speed": 5.468,
		"max_speed": 11.6,
		"calories": 69.8,
		"points": [
			[
				-79.42711,
				43.64249
			],
			[
				-79.42715,
				43.64245
			],
			[
				-79.42631,
				43.64272
			],
			[
				-79.42524,
				43.6428
			],
			[
				-79.42247,
				43.64347
			],
			[
				-79.42256,
				43.6435
			],
			[
				-79.42251,
				43.64347
			],
			[
				-79.42256,
				43.64364
			],
			[
				-79.42179,
				43.64355
			],
			[
				-79.4207,
				43.64387
			],
			[
				-79.42024,
				43.64386
			],
			[
				-79.41979,
				43.64402
			],
			[
				-79.41905,
				43.64397
			],
			[
				-79.4186,
				43.64414
			],
			[
				-79.41793,
				43.64423
			],
			[
				-79.41759,
				43.64441
			],
			[
				-79.41664,
				43.64454
			],
			[
				-79.4165,
				43.64463
			],
			[
				-79.41593,
				43.6447
			],
			[
				-79.41589,
				43.6448
			],
			[
				-79.41577,
				43.64474
			],
			[
				-79.41545,
				43.64481
			],
			[
				-79.41475,
				43.64505
			],
			[
				-79.41306,
				43.64527
			],
			[
				-79.41165,
				43.64566
			],
			[
				-79.40774,
				43.64641
			],
			[
				-79.40778,
				43.64647
			],
			[
				-79.40767,
				43.64662
			],
			[
				-79.40788,
				43.64684
			],
			[
				-79.40773,
				43.64711
			],
			[
				-79.40729,
				43.6472
			],
			[
				-79.40661,
				43.64714
			],
			[
				-79.40678,
				43.64747
			],
			[
				-79.40699,
				43.64826
			],
			[
				-79.4058,
				43.64861
			],
			[
				-79.40609,
				43.6492
			],
			[
				-79.40619,
				43.64981
			],
			[
				-79.40627,
				43.64973
			],
			[
				-79.40628,
				43.64988
			],
			[
				-79.4066,
				43.65049
			],
			[
				-79.4071,
				43.65205
			],
			[
				-79.40624,
				43.65225
			],
			[
				-79.40531,
				43.65231
			],
			[
				-79.4042,
				43.65186
			],
			[
				-79.40357,
				43.65171
			],
			[
				-79.40302,
				43.65181
			],
			[
				-79.40276,
				43.65197
			],
			[
				-79.40195,
				43.65201
			],
			[
				-79.3999,
				43.65247
			],
			[
				-79.39982,
				43.65252
			],
			[
				-79.39985,
				43.65262
			],
			[
				-79.40005,
				43.65296
			]
		],
		"dupe": false
	},
	{
		"id": 423011930,
		"name": "Morning Ride",
		"distance": 3624,
		"moving_time": 668,
		"elapsed_time": 699,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-30T08:43:50Z",
		"average_speed": 5.425,
		"max_speed": 11.2,
		"calories": 73,
		"points": [
			[
				-79.42371,
				43.65416
			],
			[
				-79.42361,
				43.65427
			],
			[
				-79.42368,
				43.65416
			],
			[
				-79.42365,
				43.654
			],
			[
				-79.4235,
				43.65412
			],
			[
				-79.42283,
				43.65432
			],
			[
				-79.42008,
				43.65479
			],
			[
				-79.41949,
				43.65503
			],
			[
				-79.41863,
				43.6552
			],
			[
				-79.41759,
				43.6552
			],
			[
				-79.41539,
				43.65493
			],
			[
				-79.41385,
				43.65515
			],
			[
				-79.41245,
				43.65537
			],
			[
				-79.41147,
				43.65568
			],
			[
				-79.4103,
				43.65577
			],
			[
				-79.40866,
				43.65622
			],
			[
				-79.4079,
				43.65626
			],
			[
				-79.40735,
				43.65649
			],
			[
				-79.40541,
				43.65682
			],
			[
				-79.40457,
				43.65704
			],
			[
				-79.4044,
				43.6568
			],
			[
				-79.40293,
				43.65314
			],
			[
				-79.40257,
				43.65282
			],
			[
				-79.40064,
				43.64797
			],
			[
				-79.40035,
				43.64783
			],
			[
				-79.39979,
				43.648
			],
			[
				-79.39953,
				43.6477
			],
			[
				-79.39941,
				43.6474
			],
			[
				-79.39961,
				43.64725
			],
			[
				-79.40005,
				43.64717
			],
			[
				-79.40006,
				43.64709
			],
			[
				-79.39963,
				43.64595
			],
			[
				-79.39579,
				43.64668
			],
			[
				-79.39584,
				43.64667
			],
			[
				-79.39566,
				43.64661
			],
			[
				-79.39585,
				43.64666
			],
			[
				-79.39607,
				43.64686
			],
			[
				-79.39546,
				43.64672
			],
			[
				-79.39581,
				43.64684
			]
		],
		"dupe": false
	},
	{
		"id": 423884399,
		"name": "Afternoon Ride",
		"distance": 2974.7,
		"moving_time": 568,
		"elapsed_time": 590,
		"total_elevation_gain": 0,
		"start_date_local": "2015-10-31T15:03:32Z",
		"average_speed": 5.237,
		"max_speed": 11.8,
		"calories": 42.2,
		"points": [
			[
				-79.4464,
				43.66196
			],
			[
				-79.44583,
				43.66198
			],
			[
				-79.44557,
				43.6621
			],
			[
				-79.43717,
				43.6638
			],
			[
				-79.43562,
				43.66024
			],
			[
				-79.4354,
				43.65991
			],
			[
				-79.43471,
				43.65795
			],
			[
				-79.43443,
				43.6574
			],
			[
				-79.43439,
				43.6571
			],
			[
				-79.43417,
				43.65697
			],
			[
				-79.43423,
				43.65672
			],
			[
				-79.43394,
				43.65611
			],
			[
				-79.43334,
				43.65433
			],
			[
				-79.43308,
				43.65398
			],
			[
				-79.43302,
				43.65362
			],
			[
				-79.43003,
				43.65419
			],
			[
				-79.42992,
				43.65415
			],
			[
				-79.42949,
				43.65301
			],
			[
				-79.42934,
				43.65295
			],
			[
				-79.42789,
				43.65334
			],
			[
				-79.42659,
				43.65358
			],
			[
				-79.42638,
				43.65354
			],
			[
				-79.42608,
				43.65279
			],
			[
				-79.42589,
				43.65276
			],
			[
				-79.4234,
				43.65329
			],
			[
				-79.42345,
				43.65338
			]
		],
		"dupe": false
	},
	{
		"id": 427773652,
		"name": "Evening Ride",
		"distance": 9959.3,
		"moving_time": 1846,
		"elapsed_time": 1993,
		"total_elevation_gain": 19.4,
		"start_date_local": "2015-11-06T18:42:45Z",
		"average_speed": 5.395,
		"max_speed": 13.5,
		"calories": 197.1,
		"points": [
			[
				-79.39907,
				43.64741
			],
			[
				-79.39928,
				43.64753
			],
			[
				-79.39968,
				43.64724
			],
			[
				-79.40113,
				43.64698
			],
			[
				-79.40122,
				43.6471
			],
			[
				-79.40159,
				43.64689
			],
			[
				-79.40191,
				43.64683
			],
			[
				-79.40225,
				43.64691
			],
			[
				-79.40238,
				43.64673
			],
			[
				-79.40373,
				43.64647
			],
			[
				-79.40374,
				43.64638
			],
			[
				-79.40374,
				43.64647
			],
			[
				-79.4038,
				43.6464
			],
			[
				-79.40472,
				43.64662
			],
			[
				-79.40587,
				43.64643
			],
			[
				-79.40613,
				43.64637
			],
			[
				-79.40632,
				43.64596
			],
			[
				-79.40679,
				43.64589
			],
			[
				-79.40707,
				43.64575
			],
			[
				-79.40868,
				43.64554
			],
			[
				-79.40949,
				43.6453
			],
			[
				-79.40974,
				43.64542
			],
			[
				-79.40969,
				43.6455
			],
			[
				-79.40994,
				43.64574
			],
			[
				-79.40985,
				43.64591
			],
			[
				-79.41007,
				43.64603
			],
			[
				-79.41104,
				43.64578
			],
			[
				-79.41299,
				43.64552
			],
			[
				-79.41666,
				43.64459
			],
			[
				-79.41755,
				43.64454
			],
			[
				-79.41807,
				43.64434
			],
			[
				-79.4189,
				43.64427
			],
			[
				-79.42352,
				43.64327
			],
			[
				-79.42483,
				43.64307
			],
			[
				-79.42635,
				43.64267
			],
			[
				-79.42817,
				43.64236
			],
			[
				-79.42931,
				43.64208
			],
			[
				-79.42942,
				43.64215
			],
			[
				-79.43164,
				43.64164
			],
			[
				-79.43215,
				43.6416
			],
			[
				-79.43624,
				43.64077
			],
			[
				-79.4391,
				43.6401
			],
			[
				-79.44087,
				43.63985
			],
			[
				-79.44421,
				43.63911
			],
			[
				-79.44609,
				43.63883
			],
			[
				-79.44612,
				43.63875
			],
			[
				-79.44712,
				43.63866
			],
			[
				-79.4487,
				43.63889
			],
			[
				-79.44968,
				43.63915
			],
			[
				-79.44982,
				43.63928
			],
			[
				-79.45022,
				43.63925
			],
			[
				-79.45263,
				43.63982
			],
			[
				-79.45325,
				43.63989
			],
			[
				-79.45813,
				43.63979
			],
			[
				-79.45975,
				43.63965
			],
			[
				-79.45981,
				43.63972
			],
			[
				-79.46027,
				43.63955
			],
			[
				-79.46127,
				43.63939
			],
			[
				-79.46573,
				43.63809
			],
			[
				-79.4684,
				43.63752
			],
			[
				-79.47079,
				43.63714
			],
			[
				-79.47161,
				43.6369
			],
			[
				-79.47303,
				43.63618
			],
			[
				-79.47672,
				43.63262
			],
			[
				-79.47991,
				43.63148
			],
			[
				-79.48311,
				43.63063
			],
			[
				-79.48988,
				43.62923
			],
			[
				-79.49722,
				43.6276
			],
			[
				-79.49746,
				43.62774
			],
			[
				-79.49813,
				43.62958
			],
			[
				-79.49825,
				43.62961
			],
			[
				-79.49855,
				43.63048
			],
			[
				-79.49904,
				43.63053
			],
			[
				-79.50381,
				43.6295
			],
			[
				-79.50434,
				43.63069
			],
			[
				-79.50457,
				43.63082
			],
			[
				-79.50432,
				43.63076
			],
			[
				-79.5045,
				43.63083
			],
			[
				-79.50389,
				43.63085
			],
			[
				-79.50395,
				43.63078
			],
			[
				-79.504,
				43.63084
			],
			[
				-79.50364,
				43.6309
			],
			[
				-79.50383,
				43.63079
			],
			[
				-79.50358,
				43.63073
			]
		],
		"dupe": false
	},
	{
		"id": 427816168,
		"name": "Night Ride",
		"distance": 9975.9,
		"moving_time": 1406,
		"elapsed_time": 1456,
		"total_elevation_gain": 16.1,
		"start_date_local": "2015-11-06T21:16:02Z",
		"average_speed": 7.095,
		"max_speed": 14.7,
		"calories": 225.3,
		"points": [
			[
				-79.50429,
				43.63054
			],
			[
				-79.50431,
				43.63071
			],
			[
				-79.50425,
				43.63073
			],
			[
				-79.50437,
				43.63058
			],
			[
				-79.50432,
				43.63036
			],
			[
				-79.50403,
				43.62955
			],
			[
				-79.50391,
				43.62948
			],
			[
				-79.50365,
				43.62862
			],
			[
				-79.50328,
				43.62789
			],
			[
				-79.50297,
				43.62693
			],
			[
				-79.50282,
				43.62677
			],
			[
				-79.50265,
				43.62636
			],
			[
				-79.5024,
				43.6263
			],
			[
				-79.49791,
				43.62733
			],
			[
				-79.49742,
				43.6275
			],
			[
				-79.49217,
				43.62853
			],
			[
				-79.49187,
				43.62861
			],
			[
				-79.49181,
				43.62876
			],
			[
				-79.4916,
				43.62863
			],
			[
				-79.49132,
				43.62875
			],
			[
				-79.48513,
				43.63015
			],
			[
				-79.4822,
				43.63071
			],
			[
				-79.48015,
				43.63122
			],
			[
				-79.47869,
				43.63143
			],
			[
				-79.47719,
				43.63175
			],
			[
				-79.47651,
				43.63219
			],
			[
				-79.47564,
				43.63322
			],
			[
				-79.47368,
				43.63515
			],
			[
				-79.47243,
				43.63616
			],
			[
				-79.47164,
				43.63659
			],
			[
				-79.47069,
				43.63692
			],
			[
				-79.46695,
				43.63756
			],
			[
				-79.46446,
				43.63819
			],
			[
				-79.4623,
				43.63886
			],
			[
				-79.46049,
				43.63927
			],
			[
				-79.45947,
				43.63937
			],
			[
				-79.45947,
				43.63946
			],
			[
				-79.45932,
				43.63938
			],
			[
				-79.45782,
				43.63956
			],
			[
				-79.45355,
				43.63963
			],
			[
				-79.45225,
				43.63955
			],
			[
				-79.45059,
				43.6391
			],
			[
				-79.44804,
				43.6386
			],
			[
				-79.44715,
				43.63847
			],
			[
				-79.44609,
				43.63847
			],
			[
				-79.44319,
				43.63747
			],
			[
				-79.44225,
				43.63701
			],
			[
				-79.44157,
				43.63654
			],
			[
				-79.44103,
				43.63643
			],
			[
				-79.43966,
				43.6364
			],
			[
				-79.43887,
				43.63664
			],
			[
				-79.4377,
				43.63676
			],
			[
				-79.43696,
				43.63696
			],
			[
				-79.43547,
				43.6372
			],
			[
				-79.43502,
				43.63736
			],
			[
				-79.4338,
				43.63763
			],
			[
				-79.43349,
				43.63763
			],
			[
				-79.4323,
				43.63794
			],
			[
				-79.43162,
				43.63799
			],
			[
				-79.42692,
				43.63905
			],
			[
				-79.42499,
				43.63932
			],
			[
				-79.42479,
				43.63943
			],
			[
				-79.42295,
				43.63976
			],
			[
				-79.42197,
				43.64004
			],
			[
				-79.4213,
				43.6401
			],
			[
				-79.41811,
				43.64079
			],
			[
				-79.41818,
				43.6407
			],
			[
				-79.41788,
				43.6407
			],
			[
				-79.41571,
				43.64129
			],
			[
				-79.4154,
				43.64147
			],
			[
				-79.41528,
				43.64142
			],
			[
				-79.41509,
				43.6415
			],
			[
				-79.41419,
				43.64156
			],
			[
				-79.41402,
				43.64149
			],
			[
				-79.4128,
				43.64186
			],
			[
				-79.4114,
				43.64207
			],
			[
				-79.41112,
				43.6422
			],
			[
				-79.41089,
				43.64216
			],
			[
				-79.40703,
				43.64303
			],
			[
				-79.40649,
				43.64307
			],
			[
				-79.40613,
				43.6432
			],
			[
				-79.40512,
				43.6433
			],
			[
				-79.40507,
				43.64332
			],
			[
				-79.40519,
				43.6433
			],
			[
				-79.40503,
				43.64347
			],
			[
				-79.40452,
				43.64365
			],
			[
				-79.40391,
				43.64365
			],
			[
				-79.40381,
				43.64352
			],
			[
				-79.40372,
				43.64353
			],
			[
				-79.40336,
				43.64375
			],
			[
				-79.40154,
				43.6442
			],
			[
				-79.40104,
				43.64418
			],
			[
				-79.39916,
				43.64462
			],
			[
				-79.39904,
				43.64458
			],
			[
				-79.39848,
				43.64474
			],
			[
				-79.39796,
				43.64479
			],
			[
				-79.39767,
				43.64491
			],
			[
				-79.39779,
				43.6453
			],
			[
				-79.39776,
				43.64547
			],
			[
				-79.39789,
				43.64554
			],
			[
				-79.39751,
				43.64545
			],
			[
				-79.39758,
				43.64545
			]
		],
		"dupe": false
	},
	{
		"id": 428160936,
		"name": "Morning Ride",
		"distance": 4811.9,
		"moving_time": 856,
		"elapsed_time": 887,
		"total_elevation_gain": 19.7,
		"start_date_local": "2015-11-07T10:44:16Z",
		"average_speed": 5.621,
		"max_speed": 14.3,
		"calories": 103,
		"points": [
			[
				-79.39617,
				43.64793
			],
			[
				-79.3962,
				43.6481
			],
			[
				-79.39614,
				43.6481
			],
			[
				-79.39616,
				43.64804
			],
			[
				-79.39644,
				43.64812
			],
			[
				-79.39699,
				43.64797
			],
			[
				-79.397,
				43.64781
			],
			[
				-79.39968,
				43.64724
			],
			[
				-79.40444,
				43.64637
			],
			[
				-79.40438,
				43.64634
			],
			[
				-79.40919,
				43.64537
			],
			[
				-79.40965,
				43.64538
			],
			[
				-79.40997,
				43.64597
			],
			[
				-79.41114,
				43.64572
			],
			[
				-79.41159,
				43.64573
			],
			[
				-79.41173,
				43.64604
			],
			[
				-79.41194,
				43.64596
			],
			[
				-79.41258,
				43.64609
			],
			[
				-79.41429,
				43.6467
			],
			[
				-79.41443,
				43.64685
			],
			[
				-79.41462,
				43.64751
			],
			[
				-79.41521,
				43.64865
			],
			[
				-79.41599,
				43.64897
			],
			[
				-79.41674,
				43.64912
			],
			[
				-79.41778,
				43.64963
			],
			[
				-79.41802,
				43.64986
			],
			[
				-79.41881,
				43.6497
			],
			[
				-79.41896,
				43.64977
			],
			[
				-79.4197,
				43.64955
			],
			[
				-79.4206,
				43.64941
			],
			[
				-79.42283,
				43.64943
			],
			[
				-79.42341,
				43.64952
			],
			[
				-79.42417,
				43.64945
			],
			[
				-79.42542,
				43.64953
			],
			[
				-79.42629,
				43.64949
			],
			[
				-79.43135,
				43.64972
			],
			[
				-79.43183,
				43.64965
			],
			[
				-79.43307,
				43.64983
			],
			[
				-79.43402,
				43.6498
			],
			[
				-79.43692,
				43.65004
			],
			[
				-79.43719,
				43.64997
			],
			[
				-79.43796,
				43.65013
			],
			[
				-79.43978,
				43.65019
			],
			[
				-79.44225,
				43.65053
			],
			[
				-79.44292,
				43.65081
			],
			[
				-79.44374,
				43.65223
			],
			[
				-79.44392,
				43.65233
			],
			[
				-79.44409,
				43.65258
			],
			[
				-79.44514,
				43.65512
			],
			[
				-79.44533,
				43.65521
			],
			[
				-79.44532,
				43.65531
			]
		],
		"dupe": false
	},
	{
		"id": 429169516,
		"name": "Afternoon Ride",
		"distance": 18789.5,
		"moving_time": 3654,
		"elapsed_time": 6872,
		"total_elevation_gain": 92.4,
		"start_date_local": "2015-11-08T13:58:21Z",
		"average_speed": 5.142,
		"max_speed": 18,
		"calories": 393.2,
		"points": [
			[
				-79.3978,
				43.64564
			],
			[
				-79.398,
				43.64626
			],
			[
				-79.39769,
				43.64641
			],
			[
				-79.39733,
				43.64678
			],
			[
				-79.39736,
				43.64668
			],
			[
				-79.39729,
				43.64665
			],
			[
				-79.3973,
				43.64672
			],
			[
				-79.39698,
				43.64645
			],
			[
				-79.39668,
				43.64646
			],
			[
				-79.3922,
				43.64744
			],
			[
				-79.39136,
				43.64751
			],
			[
				-79.39119,
				43.64764
			],
			[
				-79.38868,
				43.64816
			],
			[
				-79.38837,
				43.64837
			],
			[
				-79.38814,
				43.6484
			],
			[
				-79.38798,
				43.64832
			],
			[
				-79.38211,
				43.64958
			],
			[
				-79.38215,
				43.64966
			],
			[
				-79.38195,
				43.64963
			],
			[
				-79.38206,
				43.64959
			],
			[
				-79.3808,
				43.64988
			],
			[
				-79.38099,
				43.64983
			],
			[
				-79.38081,
				43.64987
			],
			[
				-79.38076,
				43.6498
			],
			[
				-79.3805,
				43.64994
			],
			[
				-79.37906,
				43.65029
			],
			[
				-79.37594,
				43.65094
			],
			[
				-79.37549,
				43.65132
			],
			[
				-79.37531,
				43.65136
			],
			[
				-79.37504,
				43.65128
			],
			[
				-79.37465,
				43.65134
			],
			[
				-79.37452,
				43.65126
			],
			[
				-79.37285,
				43.65161
			],
			[
				-79.37173,
				43.65148
			],
			[
				-79.36861,
				43.65214
			],
			[
				-79.36912,
				43.65337
			],
			[
				-79.36955,
				43.65482
			],
			[
				-79.37535,
				43.66869
			],
			[
				-79.37524,
				43.66874
			],
			[
				-79.37536,
				43.66871
			],
			[
				-79.37688,
				43.67234
			],
			[
				-79.3778,
				43.67498
			],
			[
				-79.37759,
				43.67504
			],
			[
				-79.37561,
				43.67491
			],
			[
				-79.37481,
				43.67503
			],
			[
				-79.37516,
				43.67599
			],
			[
				-79.37512,
				43.6765
			],
			[
				-79.37529,
				43.67702
			],
			[
				-79.37528,
				43.67728
			],
			[
				-79.37442,
				43.67927
			],
			[
				-79.37416,
				43.68019
			],
			[
				-79.37258,
				43.68436
			],
			[
				-79.37251,
				43.68491
			],
			[
				-79.3727,
				43.68542
			],
			[
				-79.37123,
				43.68577
			],
			[
				-79.37112,
				43.68591
			],
			[
				-79.37105,
				43.68627
			],
			[
				-79.37107,
				43.68706
			],
			[
				-79.37052,
				43.68722
			],
			[
				-79.36995,
				43.68751
			],
			[
				-79.36919,
				43.68768
			],
			[
				-79.3697,
				43.68907
			],
			[
				-79.36832,
				43.69077
			],
			[
				-79.36722,
				43.69042
			],
			[
				-79.36582,
				43.69073
			],
			[
				-79.36583,
				43.69088
			],
			[
				-79.36634,
				43.6907
			],
			[
				-79.36637,
				43.69056
			],
			[
				-79.36636,
				43.69063
			],
			[
				-79.36627,
				43.69058
			],
			[
				-79.36589,
				43.69067
			],
			[
				-79.36582,
				43.6908
			],
			[
				-79.36582,
				43.69073
			],
			[
				-79.36624,
				43.6921
			],
			[
				-79.3663,
				43.69222
			],
			[
				-79.36744,
				43.69292
			],
			[
				-79.36745,
				43.693
			],
			[
				-79.36633,
				43.69402
			],
			[
				-79.36377,
				43.69355
			],
			[
				-79.36321,
				43.69336
			],
			[
				-79.36261,
				43.69301
			],
			[
				-79.36238,
				43.69277
			],
			[
				-79.36197,
				43.69201
			],
			[
				-79.36194,
				43.69137
			],
			[
				-79.36224,
				43.69076
			],
			[
				-79.36349,
				43.68962
			],
			[
				-79.36376,
				43.68891
			],
			[
				-79.36376,
				43.68833
			],
			[
				-79.36366,
				43.68802
			],
			[
				-79.36353,
				43.68794
			],
			[
				-79.36321,
				43.68806
			],
			[
				-79.36303,
				43.68868
			],
			[
				-79.36289,
				43.68881
			],
			[
				-79.36255,
				43.6888
			],
			[
				-79.3622,
				43.68842
			],
			[
				-79.36214,
				43.68831
			],
			[
				-79.3622,
				43.68825
			],
			[
				-79.36189,
				43.68774
			],
			[
				-79.36129,
				43.68726
			],
			[
				-79.36112,
				43.68691
			],
			[
				-79.36148,
				43.68638
			],
			[
				-79.36135,
				43.68564
			],
			[
				-79.36139,
				43.68513
			],
			[
				-79.36164,
				43.68451
			],
			[
				-79.36244,
				43.68335
			],
			[
				-79.36377,
				43.68263
			],
			[
				-79.36408,
				43.68231
			],
			[
				-79.36448,
				43.68161
			],
			[
				-79.36481,
				43.68133
			],
			[
				-79.36512,
				43.68116
			],
			[
				-79.36571,
				43.68133
			],
			[
				-79.36582,
				43.68114
			],
			[
				-79.36579,
				43.68077
			],
			[
				-79.36542,
				43.68043
			],
			[
				-79.36533,
				43.68041
			],
			[
				-79.36526,
				43.68052
			],
			[
				-79.36494,
				43.68045
			],
			[
				-79.36417,
				43.67981
			],
			[
				-79.36388,
				43.67966
			],
			[
				-79.3635,
				43.6792
			],
			[
				-79.36332,
				43.67832
			],
			[
				-79.36335,
				43.67799
			],
			[
				-79.36353,
				43.67753
			],
			[
				-79.36339,
				43.67682
			],
			[
				-79.36277,
				43.67572
			],
			[
				-79.36262,
				43.67533
			],
			[
				-79.36271,
				43.67542
			],
			[
				-79.36263,
				43.67535
			],
			[
				-79.36267,
				43.67516
			],
			[
				-79.36251,
				43.67462
			],
			[
				-79.36201,
				43.67385
			],
			[
				-79.36147,
				43.67332
			],
			[
				-79.36093,
				43.67263
			],
			[
				-79.36028,
				43.67214
			],
			[
				-79.36006,
				43.67188
			],
			[
				-79.35983,
				43.6714
			],
			[
				-79.35918,
				43.67091
			],
			[
				-79.35893,
				43.67101
			],
			[
				-79.35885,
				43.67096
			],
			[
				-79.3585,
				43.67062
			],
			[
				-79.35807,
				43.67001
			],
			[
				-79.35789,
				43.66999
			],
			[
				-79.35734,
				43.66872
			],
			[
				-79.3578,
				43.66856
			],
			[
				-79.35755,
				43.66799
			],
			[
				-79.35776,
				43.66785
			],
			[
				-79.35673,
				43.66447
			],
			[
				-79.35647,
				43.66438
			],
			[
				-79.35655,
				43.66436
			],
			[
				-79.35648,
				43.66406
			],
			[
				-79.35658,
				43.66398
			],
			[
				-79.35495,
				43.65846
			],
			[
				-79.35471,
				43.65789
			],
			[
				-79.35436,
				43.65753
			],
			[
				-79.35431,
				43.65754
			],
			[
				-79.35444,
				43.65773
			],
			[
				-79.35442,
				43.65782
			],
			[
				-79.35329,
				43.65805
			],
			[
				-79.35352,
				43.65844
			],
			[
				-79.35365,
				43.65896
			],
			[
				-79.35406,
				43.65981
			],
			[
				-79.35359,
				43.65999
			],
			[
				-79.35404,
				43.66001
			],
			[
				-79.35396,
				43.65996
			],
			[
				-79.35421,
				43.66006
			],
			[
				-79.35404,
				43.66001
			],
			[
				-79.35413,
				43.66002
			],
			[
				-79.354,
				43.65985
			],
			[
				-79.35409,
				43.65988
			],
			[
				-79.35416,
				43.65977
			],
			[
				-79.35382,
				43.65921
			],
			[
				-79.35345,
				43.6582
			],
			[
				-79.35327,
				43.65818
			],
			[
				-79.35537,
				43.65763
			],
			[
				-79.35681,
				43.65735
			],
			[
				-79.35703,
				43.65723
			],
			[
				-79.35836,
				43.65704
			],
			[
				-79.36273,
				43.65598
			],
			[
				-79.36495,
				43.65555
			],
			[
				-79.36549,
				43.65533
			],
			[
				-79.36736,
				43.65506
			],
			[
				-79.36869,
				43.65475
			],
			[
				-79.36893,
				43.65461
			],
			[
				-79.37007,
				43.65448
			],
			[
				-79.37134,
				43.6541
			],
			[
				-79.37344,
				43.65364
			],
			[
				-79.37567,
				43.6533
			],
			[
				-79.37703,
				43.65294
			],
			[
				-79.37737,
				43.65296
			],
			[
				-79.37772,
				43.65272
			],
			[
				-79.37787,
				43.65269
			],
			[
				-79.37813,
				43.65278
			],
			[
				-79.37899,
				43.65241
			],
			[
				-79.37929,
				43.65239
			],
			[
				-79.37943,
				43.65223
			],
			[
				-79.37949,
				43.65222
			],
			[
				-79.37952,
				43.65233
			],
			[
				-79.38015,
				43.6523
			],
			[
				-79.3803,
				43.65236
			],
			[
				-79.38036,
				43.65216
			],
			[
				-79.38075,
				43.65208
			],
			[
				-79.38082,
				43.65225
			],
			[
				-79.38095,
				43.65204
			],
			[
				-79.38152,
				43.65198
			],
			[
				-79.38171,
				43.65189
			],
			[
				-79.38156,
				43.65177
			],
			[
				-79.38133,
				43.65119
			],
			[
				-79.38228,
				43.65094
			],
			[
				-79.38218,
				43.65096
			],
			[
				-79.38256,
				43.65063
			],
			[
				-79.38255,
				43.65052
			],
			[
				-79.38258,
				43.65057
			],
			[
				-79.38338,
				43.6504
			],
			[
				-79.38352,
				43.6505
			],
			[
				-79.38357,
				43.65067
			],
			[
				-79.38921,
				43.64943
			],
			[
				-79.38926,
				43.64962
			],
			[
				-79.3898,
				43.6494
			],
			[
				-79.38986,
				43.64927
			],
			[
				-79.39251,
				43.64873
			],
			[
				-79.39328,
				43.64839
			],
			[
				-79.39554,
				43.6479
			],
			[
				-79.39588,
				43.64786
			],
			[
				-79.39598,
				43.64794
			],
			[
				-79.39613,
				43.64787
			],
			[
				-79.39675,
				43.64786
			],
			[
				-79.39865,
				43.64745
			],
			[
				-79.39848,
				43.64701
			]
		],
		"dupe": false
	},
	{
		"id": 436300135,
		"name": "Evening Ride",
		"distance": 2481,
		"moving_time": 520,
		"elapsed_time": 585,
		"total_elevation_gain": 14.6,
		"start_date_local": "2015-11-20T19:13:46Z",
		"average_speed": 4.771,
		"max_speed": 11.1,
		"calories": 59,
		"points": [
			[
				-79.42967,
				43.64418
			],
			[
				-79.42935,
				43.64447
			],
			[
				-79.42966,
				43.64506
			],
			[
				-79.4301,
				43.64638
			],
			[
				-79.43128,
				43.64934
			],
			[
				-79.43146,
				43.64963
			],
			[
				-79.4331,
				43.64975
			],
			[
				-79.43374,
				43.6499
			],
			[
				-79.43518,
				43.64989
			],
			[
				-79.43565,
				43.64999
			],
			[
				-79.43619,
				43.64994
			],
			[
				-79.43753,
				43.65012
			],
			[
				-79.43898,
				43.65014
			],
			[
				-79.4403,
				43.65036
			],
			[
				-79.44086,
				43.65032
			],
			[
				-79.44163,
				43.65039
			],
			[
				-79.44408,
				43.65096
			],
			[
				-79.44471,
				43.65103
			],
			[
				-79.44611,
				43.65154
			],
			[
				-79.44701,
				43.65168
			],
			[
				-79.44742,
				43.65189
			],
			[
				-79.44749,
				43.65185
			],
			[
				-79.44803,
				43.65231
			],
			[
				-79.44865,
				43.65247
			],
			[
				-79.44987,
				43.65304
			],
			[
				-79.45012,
				43.65307
			],
			[
				-79.45024,
				43.65319
			],
			[
				-79.4504,
				43.65321
			],
			[
				-79.45044,
				43.65336
			],
			[
				-79.45091,
				43.65347
			],
			[
				-79.45131,
				43.6532
			],
			[
				-79.45101,
				43.65345
			]
		],
		"dupe": false
	},
	{
		"id": 438858702,
		"name": "Evening Ride",
		"distance": 9450.5,
		"moving_time": 1612,
		"elapsed_time": 1752,
		"total_elevation_gain": 0,
		"start_date_local": "2015-11-24T18:46:26Z",
		"average_speed": 5.863,
		"max_speed": 15.6,
		"calories": 191.6,
		"points": [
			[
				-79.42937,
				43.64429
			],
			[
				-79.42933,
				43.64394
			],
			[
				-79.42914,
				43.64383
			],
			[
				-79.42779,
				43.64404
			],
			[
				-79.42729,
				43.64289
			],
			[
				-79.42617,
				43.64309
			],
			[
				-79.42603,
				43.64283
			],
			[
				-79.42252,
				43.64337
			],
			[
				-79.42196,
				43.64358
			],
			[
				-79.42014,
				43.64394
			],
			[
				-79.41834,
				43.64418
			],
			[
				-79.41552,
				43.64488
			],
			[
				-79.41334,
				43.64527
			],
			[
				-79.41323,
				43.64528
			],
			[
				-79.41329,
				43.64524
			],
			[
				-79.41318,
				43.6452
			],
			[
				-79.41282,
				43.64429
			],
			[
				-79.41008,
				43.64482
			],
			[
				-79.40955,
				43.645
			],
			[
				-79.4095,
				43.64512
			],
			[
				-79.40959,
				43.64525
			],
			[
				-79.40939,
				43.64533
			],
			[
				-79.40386,
				43.64644
			],
			[
				-79.40374,
				43.6463
			],
			[
				-79.40366,
				43.64595
			],
			[
				-79.40354,
				43.64587
			],
			[
				-79.40339,
				43.64536
			],
			[
				-79.403,
				43.64524
			],
			[
				-79.39564,
				43.64672
			],
			[
				-79.3957,
				43.6467
			],
			[
				-79.39557,
				43.6466
			],
			[
				-79.39454,
				43.64692
			],
			[
				-79.39385,
				43.64701
			],
			[
				-79.39379,
				43.64711
			],
			[
				-79.39287,
				43.6473
			],
			[
				-79.39287,
				43.64737
			],
			[
				-79.39285,
				43.64732
			],
			[
				-79.39247,
				43.64738
			],
			[
				-79.38864,
				43.64815
			],
			[
				-79.38585,
				43.64879
			],
			[
				-79.38586,
				43.64887
			],
			[
				-79.38556,
				43.64883
			],
			[
				-79.38212,
				43.64958
			],
			[
				-79.38186,
				43.64945
			],
			[
				-79.38164,
				43.64945
			],
			[
				-79.38121,
				43.64959
			],
			[
				-79.38094,
				43.64977
			],
			[
				-79.38018,
				43.6499
			],
			[
				-79.37982,
				43.64994
			],
			[
				-79.37963,
				43.64987
			],
			[
				-79.3798,
				43.6501
			],
			[
				-79.37932,
				43.6502
			],
			[
				-79.37918,
				43.65051
			],
			[
				-79.37912,
				43.65039
			],
			[
				-79.37841,
				43.65042
			],
			[
				-79.37831,
				43.65036
			],
			[
				-79.37768,
				43.65053
			],
			[
				-79.37759,
				43.65066
			],
			[
				-79.37745,
				43.6507
			],
			[
				-79.37732,
				43.65058
			],
			[
				-79.37687,
				43.65078
			],
			[
				-79.37592,
				43.65094
			],
			[
				-79.37594,
				43.65102
			],
			[
				-79.37557,
				43.65102
			],
			[
				-79.37299,
				43.65159
			],
			[
				-79.37148,
				43.65144
			],
			[
				-79.37136,
				43.65154
			],
			[
				-79.36475,
				43.65298
			],
			[
				-79.3641,
				43.65323
			],
			[
				-79.3619,
				43.65461
			],
			[
				-79.36102,
				43.65491
			],
			[
				-79.35998,
				43.655
			],
			[
				-79.35911,
				43.65494
			],
			[
				-79.35906,
				43.65486
			],
			[
				-79.35892,
				43.65485
			],
			[
				-79.35883,
				43.65492
			],
			[
				-79.35695,
				43.65476
			],
			[
				-79.35627,
				43.6548
			],
			[
				-79.35547,
				43.65509
			],
			[
				-79.35368,
				43.65623
			],
			[
				-79.35215,
				43.65674
			],
			[
				-79.35144,
				43.65688
			],
			[
				-79.35038,
				43.65693
			],
			[
				-79.34974,
				43.65682
			],
			[
				-79.34857,
				43.65647
			],
			[
				-79.34765,
				43.65643
			],
			[
				-79.34443,
				43.65709
			],
			[
				-79.34409,
				43.65722
			],
			[
				-79.3385,
				43.65845
			],
			[
				-79.3383,
				43.65855
			],
			[
				-79.33604,
				43.65895
			],
			[
				-79.33501,
				43.65917
			],
			[
				-79.33456,
				43.65935
			],
			[
				-79.33116,
				43.66006
			],
			[
				-79.33083,
				43.66026
			],
			[
				-79.33073,
				43.66045
			],
			[
				-79.33028,
				43.66086
			],
			[
				-79.32997,
				43.66134
			],
			[
				-79.32954,
				43.66154
			],
			[
				-79.32965,
				43.66153
			],
			[
				-79.32956,
				43.66157
			],
			[
				-79.32958,
				43.6617
			],
			[
				-79.33021,
				43.6632
			]
		],
		"dupe": false
	},
	{
		"id": 440518874,
		"name": "Evening Ride",
		"distance": 3694.7,
		"moving_time": 782,
		"elapsed_time": 814,
		"total_elevation_gain": 36.7,
		"start_date_local": "2015-11-27T20:19:01Z",
		"average_speed": 4.725,
		"max_speed": 10.1,
		"calories": 89.8,
		"points": [
			[
				-79.42345,
				43.65354
			],
			[
				-79.42365,
				43.65363
			],
			[
				-79.42339,
				43.65358
			],
			[
				-79.42332,
				43.65348
			],
			[
				-79.42361,
				43.65362
			],
			[
				-79.42351,
				43.65362
			],
			[
				-79.42354,
				43.65357
			],
			[
				-79.42362,
				43.65387
			],
			[
				-79.42375,
				43.65403
			],
			[
				-79.42373,
				43.6542
			],
			[
				-79.42367,
				43.65415
			],
			[
				-79.42377,
				43.65423
			],
			[
				-79.42604,
				43.65369
			],
			[
				-79.4264,
				43.65367
			],
			[
				-79.42649,
				43.65381
			],
			[
				-79.42933,
				43.66112
			],
			[
				-79.42963,
				43.66156
			],
			[
				-79.42963,
				43.66213
			],
			[
				-79.43466,
				43.67427
			],
			[
				-79.43601,
				43.6742
			],
			[
				-79.4376,
				43.67397
			],
			[
				-79.43884,
				43.67356
			],
			[
				-79.4438,
				43.67239
			],
			[
				-79.44371,
				43.67217
			],
			[
				-79.44346,
				43.67218
			],
			[
				-79.44364,
				43.67213
			]
		],
		"dupe": false
	},
	{
		"id": 440993106,
		"name": "Afternoon Ride",
		"distance": 941,
		"moving_time": 136,
		"elapsed_time": 181,
		"total_elevation_gain": 0,
		"start_date_local": "2015-11-28T15:06:42Z",
		"average_speed": 6.919,
		"max_speed": 10.6,
		"calories": 15.8,
		"points": [
			[
				-79.43381,
				43.65222
			],
			[
				-79.43391,
				43.65232
			],
			[
				-79.43273,
				43.65249
			],
			[
				-79.43186,
				43.65274
			],
			[
				-79.43062,
				43.65294
			],
			[
				-79.42999,
				43.65283
			],
			[
				-79.42664,
				43.65356
			],
			[
				-79.42635,
				43.65345
			],
			[
				-79.42606,
				43.65274
			],
			[
				-79.42366,
				43.65324
			]
		],
		"dupe": false
	},
	{
		"id": 449144344,
		"name": "Evening Ride",
		"distance": 3514.7,
		"moving_time": 729,
		"elapsed_time": 1230,
		"total_elevation_gain": 10.3,
		"start_date_local": "2015-12-12T20:20:08Z",
		"average_speed": 4.821,
		"max_speed": 13.8,
		"calories": 91.2,
		"points": [
			[
				-79.42379,
				43.65424
			],
			[
				-79.42401,
				43.65426
			],
			[
				-79.42436,
				43.65406
			],
			[
				-79.42597,
				43.65368
			],
			[
				-79.4268,
				43.6536
			],
			[
				-79.42813,
				43.65324
			],
			[
				-79.42883,
				43.65325
			],
			[
				-79.42989,
				43.65292
			],
			[
				-79.43103,
				43.65295
			],
			[
				-79.43623,
				43.65191
			],
			[
				-79.43642,
				43.65199
			],
			[
				-79.43642,
				43.65221
			],
			[
				-79.43655,
				43.65223
			],
			[
				-79.43853,
				43.65751
			],
			[
				-79.43918,
				43.65905
			],
			[
				-79.4398,
				43.65905
			],
			[
				-79.44178,
				43.65865
			],
			[
				-79.44236,
				43.65844
			],
			[
				-79.44517,
				43.65793
			],
			[
				-79.44522,
				43.65776
			],
			[
				-79.44539,
				43.65788
			],
			[
				-79.44552,
				43.65787
			],
			[
				-79.447,
				43.65749
			],
			[
				-79.4478,
				43.65737
			],
			[
				-79.44784,
				43.65743
			],
			[
				-79.44814,
				43.65711
			],
			[
				-79.44826,
				43.65714
			],
			[
				-79.44828,
				43.65734
			],
			[
				-79.44808,
				43.65732
			],
			[
				-79.44786,
				43.65751
			],
			[
				-79.44781,
				43.65736
			],
			[
				-79.44786,
				43.65749
			],
			[
				-79.44807,
				43.65735
			],
			[
				-79.44802,
				43.65733
			],
			[
				-79.44788,
				43.65766
			],
			[
				-79.44776,
				43.65767
			],
			[
				-79.448,
				43.65722
			],
			[
				-79.4478,
				43.65727
			],
			[
				-79.44782,
				43.65732
			],
			[
				-79.44822,
				43.65718
			],
			[
				-79.44812,
				43.6572
			],
			[
				-79.44791,
				43.65761
			],
			[
				-79.44798,
				43.65727
			],
			[
				-79.44811,
				43.65719
			],
			[
				-79.44797,
				43.65722
			],
			[
				-79.44811,
				43.65727
			],
			[
				-79.44796,
				43.65739
			],
			[
				-79.44802,
				43.65727
			],
			[
				-79.44819,
				43.65748
			],
			[
				-79.44793,
				43.65768
			],
			[
				-79.44785,
				43.65749
			],
			[
				-79.44794,
				43.65773
			],
			[
				-79.44801,
				43.65746
			],
			[
				-79.448,
				43.65758
			],
			[
				-79.44808,
				43.65753
			],
			[
				-79.44805,
				43.65722
			],
			[
				-79.44801,
				43.65736
			],
			[
				-79.44812,
				43.65739
			],
			[
				-79.44818,
				43.65729
			],
			[
				-79.44806,
				43.65725
			],
			[
				-79.44807,
				43.65734
			]
		],
		"dupe": false
	},
	{
		"id": 449192947,
		"name": "Night Ride",
		"distance": 2893.7,
		"moving_time": 463,
		"elapsed_time": 473,
		"total_elevation_gain": 0,
		"start_date_local": "2015-12-13T00:39:46Z",
		"average_speed": 6.25,
		"max_speed": 12.5,
		"calories": 57.6,
		"points": [
			[
				-79.44736,
				43.65662
			],
			[
				-79.44789,
				43.65715
			],
			[
				-79.44753,
				43.65728
			],
			[
				-79.44729,
				43.65724
			],
			[
				-79.44708,
				43.65735
			],
			[
				-79.44645,
				43.65745
			],
			[
				-79.44509,
				43.65783
			],
			[
				-79.44371,
				43.65803
			],
			[
				-79.44183,
				43.65844
			],
			[
				-79.44179,
				43.6586
			],
			[
				-79.44172,
				43.65847
			],
			[
				-79.44155,
				43.65849
			],
			[
				-79.42943,
				43.66104
			],
			[
				-79.4292,
				43.66078
			],
			[
				-79.42849,
				43.65896
			],
			[
				-79.42858,
				43.65894
			],
			[
				-79.42838,
				43.65866
			],
			[
				-79.42606,
				43.65274
			],
			[
				-79.42338,
				43.6533
			]
		],
		"dupe": false
	},
	{
		"id": 450406645,
		"name": "Night Ride",
		"distance": 2483.8,
		"moving_time": 446,
		"elapsed_time": 464,
		"total_elevation_gain": 0,
		"start_date_local": "2015-12-15T01:22:26Z",
		"average_speed": 5.569,
		"max_speed": 10.2,
		"calories": 44.8,
		"points": [
			[
				-79.40412,
				43.64729
			],
			[
				-79.40423,
				43.64735
			],
			[
				-79.40393,
				43.64741
			],
			[
				-79.40412,
				43.64742
			],
			[
				-79.40396,
				43.64741
			],
			[
				-79.40403,
				43.64742
			],
			[
				-79.40417,
				43.64793
			],
			[
				-79.40472,
				43.64882
			],
			[
				-79.40901,
				43.64793
			],
			[
				-79.40946,
				43.64773
			],
			[
				-79.41142,
				43.64737
			],
			[
				-79.41282,
				43.65091
			],
			[
				-79.41307,
				43.65092
			],
			[
				-79.41439,
				43.6506
			],
			[
				-79.41632,
				43.65031
			],
			[
				-79.41678,
				43.65016
			],
			[
				-79.4183,
				43.64991
			],
			[
				-79.41828,
				43.64981
			],
			[
				-79.41866,
				43.65093
			],
			[
				-79.4189,
				43.65135
			],
			[
				-79.41931,
				43.65137
			],
			[
				-79.42138,
				43.65095
			],
			[
				-79.42155,
				43.65167
			],
			[
				-79.42173,
				43.65194
			],
			[
				-79.422,
				43.65287
			],
			[
				-79.42231,
				43.65348
			],
			[
				-79.42341,
				43.65332
			]
		],
		"dupe": false
	},
	{
		"id": 454528934,
		"name": "Lunch Ride",
		"distance": 4452.1,
		"moving_time": 819,
		"elapsed_time": 854,
		"total_elevation_gain": 0,
		"start_date_local": "2015-12-22T11:57:29Z",
		"average_speed": 5.436,
		"max_speed": 14.9,
		"calories": 78.4,
		"points": [
			[
				-79.42952,
				43.6444
			],
			[
				-79.42931,
				43.64399
			],
			[
				-79.42916,
				43.64391
			],
			[
				-79.42773,
				43.64401
			],
			[
				-79.42696,
				43.64222
			],
			[
				-79.42705,
				43.6425
			],
			[
				-79.42541,
				43.64293
			],
			[
				-79.41977,
				43.64404
			],
			[
				-79.4188,
				43.64413
			],
			[
				-79.41859,
				43.64424
			],
			[
				-79.4158,
				43.64481
			],
			[
				-79.41481,
				43.64509
			],
			[
				-79.41014,
				43.64599
			],
			[
				-79.41,
				43.64598
			],
			[
				-79.40961,
				43.64529
			],
			[
				-79.40393,
				43.64643
			],
			[
				-79.40382,
				43.64641
			],
			[
				-79.40368,
				43.6462
			],
			[
				-79.40353,
				43.6456
			],
			[
				-79.40333,
				43.64531
			],
			[
				-79.40315,
				43.64521
			],
			[
				-79.38868,
				43.64816
			],
			[
				-79.38661,
				43.64863
			],
			[
				-79.38665,
				43.64888
			],
			[
				-79.38641,
				43.64875
			],
			[
				-79.38583,
				43.6488
			],
			[
				-79.38592,
				43.64895
			],
			[
				-79.38522,
				43.64893
			],
			[
				-79.38192,
				43.64962
			]
		],
		"dupe": false
	},
	{
		"id": 454564403,
		"name": "Afternoon Ride",
		"distance": 4326.4,
		"moving_time": 758,
		"elapsed_time": 915,
		"total_elevation_gain": 0,
		"start_date_local": "2015-12-22T13:11:46Z",
		"average_speed": 5.708,
		"max_speed": 10.7,
		"calories": 90.9,
		"points": [
			[
				-79.38342,
				43.65097
			],
			[
				-79.38344,
				43.65108
			],
			[
				-79.38347,
				43.65077
			],
			[
				-79.38391,
				43.65058
			],
			[
				-79.38466,
				43.65077
			],
			[
				-79.38486,
				43.65062
			],
			[
				-79.38501,
				43.65064
			],
			[
				-79.38565,
				43.65039
			],
			[
				-79.38574,
				43.65027
			],
			[
				-79.38598,
				43.65031
			],
			[
				-79.38669,
				43.65016
			],
			[
				-79.38698,
				43.64993
			],
			[
				-79.39235,
				43.64876
			],
			[
				-79.39328,
				43.64839
			],
			[
				-79.39546,
				43.64791
			],
			[
				-79.39681,
				43.64785
			],
			[
				-79.39983,
				43.64721
			],
			[
				-79.40114,
				43.64698
			],
			[
				-79.40165,
				43.64698
			],
			[
				-79.4018,
				43.64685
			],
			[
				-79.40375,
				43.64646
			],
			[
				-79.4048,
				43.64663
			],
			[
				-79.40621,
				43.64635
			],
			[
				-79.40634,
				43.64601
			],
			[
				-79.40696,
				43.64592
			],
			[
				-79.40744,
				43.64577
			],
			[
				-79.40734,
				43.64575
			],
			[
				-79.4091,
				43.64537
			],
			[
				-79.40969,
				43.64543
			],
			[
				-79.40997,
				43.64597
			],
			[
				-79.40996,
				43.64592
			],
			[
				-79.4107,
				43.64594
			],
			[
				-79.41381,
				43.64523
			],
			[
				-79.4149,
				43.64506
			],
			[
				-79.42038,
				43.64388
			],
			[
				-79.42239,
				43.64356
			],
			[
				-79.42693,
				43.64256
			],
			[
				-79.42717,
				43.64261
			],
			[
				-79.42713,
				43.64254
			],
			[
				-79.42719,
				43.64264
			],
			[
				-79.42775,
				43.64402
			],
			[
				-79.42914,
				43.64383
			],
			[
				-79.42935,
				43.64433
			],
			[
				-79.42949,
				43.64436
			],
			[
				-79.42944,
				43.64438
			],
			[
				-79.42938,
				43.64431
			],
			[
				-79.42967,
				43.6443
			]
		],
		"dupe": false
	},
	{
		"id": 455059053,
		"name": "Lunch Ride",
		"distance": 2489.5,
		"moving_time": 475,
		"elapsed_time": 475,
		"total_elevation_gain": 14,
		"start_date_local": "2015-12-23T11:18:42Z",
		"average_speed": 5.241,
		"max_speed": 9.8,
		"calories": 57.4,
		"points": [
			[
				-79.42341,
				43.65419
			],
			[
				-79.42362,
				43.65426
			],
			[
				-79.42352,
				43.65416
			],
			[
				-79.42382,
				43.65418
			],
			[
				-79.4226,
				43.65448
			],
			[
				-79.42165,
				43.65456
			],
			[
				-79.42121,
				43.65474
			],
			[
				-79.42018,
				43.65493
			],
			[
				-79.42003,
				43.65506
			],
			[
				-79.41985,
				43.65503
			],
			[
				-79.41843,
				43.65532
			],
			[
				-79.41747,
				43.65527
			],
			[
				-79.41553,
				43.65495
			],
			[
				-79.41433,
				43.65522
			],
			[
				-79.41401,
				43.65521
			],
			[
				-79.41394,
				43.65529
			],
			[
				-79.414,
				43.65527
			],
			[
				-79.41395,
				43.65532
			],
			[
				-79.41404,
				43.65563
			],
			[
				-79.41583,
				43.66023
			],
			[
				-79.40972,
				43.66151
			],
			[
				-79.41103,
				43.66489
			],
			[
				-79.41126,
				43.66505
			],
			[
				-79.41112,
				43.66513
			]
		],
		"dupe": false
	},
	{
		"id": 454767395,
		"name": "Night Ride",
		"distance": 2345.1,
		"moving_time": 478,
		"elapsed_time": 490,
		"total_elevation_gain": 10.8,
		"start_date_local": "2015-12-22T21:18:15Z",
		"average_speed": 4.906,
		"max_speed": 13.3,
		"calories": 50.9,
		"points": [
			[
				-79.43311,
				43.64127
			],
			[
				-79.43326,
				43.64136
			],
			[
				-79.43323,
				43.64144
			],
			[
				-79.4332,
				43.64135
			],
			[
				-79.43329,
				43.64132
			],
			[
				-79.43308,
				43.64128
			],
			[
				-79.43237,
				43.64139
			],
			[
				-79.43174,
				43.64161
			],
			[
				-79.43165,
				43.64156
			],
			[
				-79.42914,
				43.64206
			],
			[
				-79.42846,
				43.64204
			],
			[
				-79.42832,
				43.64197
			],
			[
				-79.42775,
				43.64217
			],
			[
				-79.42767,
				43.64209
			],
			[
				-79.42727,
				43.6423
			],
			[
				-79.42602,
				43.64272
			],
			[
				-79.42442,
				43.64297
			],
			[
				-79.42231,
				43.64349
			],
			[
				-79.42229,
				43.64372
			],
			[
				-79.42266,
				43.64418
			],
			[
				-79.42603,
				43.65266
			],
			[
				-79.42573,
				43.65279
			],
			[
				-79.42332,
				43.65331
			],
			[
				-79.42356,
				43.65348
			]
		],
		"dupe": false
	},
	{
		"id": 455106995,
		"name": "Lunch Ride",
		"distance": 1608.8,
		"moving_time": 262,
		"elapsed_time": 281,
		"total_elevation_gain": 0,
		"start_date_local": "2015-12-23T12:54:43Z",
		"average_speed": 6.14,
		"max_speed": 14.1,
		"calories": 41,
		"points": [
			[
				-79.41335,
				43.66074
			],
			[
				-79.41336,
				43.66067
			],
			[
				-79.4135,
				43.6608
			],
			[
				-79.41368,
				43.66081
			],
			[
				-79.41391,
				43.66064
			],
			[
				-79.41435,
				43.66054
			],
			[
				-79.41688,
				43.66003
			],
			[
				-79.41696,
				43.66011
			],
			[
				-79.41748,
				43.65991
			],
			[
				-79.42427,
				43.65854
			],
			[
				-79.42405,
				43.65759
			],
			[
				-79.42361,
				43.65662
			],
			[
				-79.42271,
				43.65405
			],
			[
				-79.42243,
				43.6535
			],
			[
				-79.42335,
				43.65331
			]
		],
		"dupe": false
	},
	{
		"id": 455784917,
		"name": "Afternoon Ride",
		"distance": 5971,
		"moving_time": 1349,
		"elapsed_time": 1405,
		"total_elevation_gain": 56.9,
		"start_date_local": "2015-12-24T16:53:06Z",
		"average_speed": 4.426,
		"max_speed": 14.5,
		"calories": 137.4,
		"points": [
			[
				-79.4199,
				43.65448
			],
			[
				-79.4199,
				43.65453
			],
			[
				-79.4198,
				43.65443
			],
			[
				-79.41963,
				43.65454
			],
			[
				-79.4189,
				43.65458
			],
			[
				-79.41885,
				43.65469
			],
			[
				-79.4191,
				43.6555
			],
			[
				-79.4196,
				43.65587
			],
			[
				-79.41981,
				43.65621
			],
			[
				-79.41983,
				43.65655
			],
			[
				-79.41966,
				43.65695
			],
			[
				-79.4199,
				43.65732
			],
			[
				-79.42069,
				43.65927
			],
			[
				-79.42178,
				43.65904
			],
			[
				-79.4219,
				43.65918
			],
			[
				-79.42232,
				43.66041
			],
			[
				-79.42254,
				43.66089
			],
			[
				-79.4226,
				43.66084
			],
			[
				-79.42309,
				43.66204
			],
			[
				-79.4232,
				43.66255
			],
			[
				-79.42376,
				43.66368
			],
			[
				-79.42376,
				43.6639
			],
			[
				-79.42526,
				43.66725
			],
			[
				-79.42589,
				43.6689
			],
			[
				-79.42595,
				43.66923
			],
			[
				-79.42652,
				43.67048
			],
			[
				-79.42663,
				43.67097
			],
			[
				-79.42675,
				43.6712
			],
			[
				-79.42689,
				43.67124
			],
			[
				-79.42834,
				43.67475
			],
			[
				-79.42848,
				43.67484
			],
			[
				-79.43015,
				43.67477
			],
			[
				-79.4303,
				43.67488
			],
			[
				-79.43251,
				43.68031
			],
			[
				-79.43663,
				43.67944
			],
			[
				-79.43668,
				43.67952
			],
			[
				-79.43694,
				43.67937
			],
			[
				-79.44305,
				43.67804
			],
			[
				-79.44315,
				43.67816
			],
			[
				-79.4434,
				43.67907
			],
			[
				-79.44407,
				43.68053
			],
			[
				-79.44572,
				43.68485
			],
			[
				-79.44612,
				43.68555
			],
			[
				-79.44986,
				43.68456
			],
			[
				-79.44981,
				43.68457
			],
			[
				-79.45047,
				43.68443
			],
			[
				-79.45055,
				43.68453
			],
			[
				-79.45083,
				43.68435
			],
			[
				-79.45135,
				43.68424
			],
			[
				-79.45056,
				43.68441
			],
			[
				-79.4504,
				43.68423
			],
			[
				-79.4501,
				43.68352
			],
			[
				-79.4502,
				43.68343
			],
			[
				-79.45124,
				43.68319
			],
			[
				-79.45136,
				43.68339
			]
		],
		"dupe": false
	},
	{
		"id": 457683682,
		"name": "Evening Ride",
		"distance": 2148.4,
		"moving_time": 413,
		"elapsed_time": 416,
		"total_elevation_gain": 0,
		"start_date_local": "2015-12-27T19:42:09Z",
		"average_speed": 5.202,
		"max_speed": 10,
		"calories": 45.6,
		"points": [
			[
				-79.3997,
				43.65793
			],
			[
				-79.3998,
				43.65801
			],
			[
				-79.40028,
				43.65798
			],
			[
				-79.4032,
				43.65743
			],
			[
				-79.40451,
				43.65727
			],
			[
				-79.4049,
				43.65711
			],
			[
				-79.4075,
				43.65669
			],
			[
				-79.40933,
				43.65618
			],
			[
				-79.41002,
				43.65605
			],
			[
				-79.41043,
				43.65606
			],
			[
				-79.41337,
				43.65536
			],
			[
				-79.41549,
				43.65504
			],
			[
				-79.41569,
				43.65492
			],
			[
				-79.41585,
				43.65508
			],
			[
				-79.41711,
				43.65526
			],
			[
				-79.41784,
				43.65532
			],
			[
				-79.419,
				43.65522
			],
			[
				-79.42262,
				43.65449
			],
			[
				-79.42266,
				43.65414
			],
			[
				-79.42249,
				43.65364
			],
			[
				-79.42259,
				43.65346
			],
			[
				-79.42341,
				43.65329
			]
		],
		"dupe": false
	}
]