module.exports={
	"title": "Miscellaneous",
	"text": "Everything else. Many stats here are not even numbers.",
	"sources": ["Instagram", "Twitter"],
	"stats": [
		{
			"title": "First place in 2015",
			"value": "Lakeview Restaurant",
			"secondary_value": "2015-01-01T05:00:00.000Z"
		},
		{
			"title": "Most fun",
			"value": "Dan Deacon",
			"secondary_value": "@ XOXO in Portland, OR"
		},
		{
			"title": "Least fun",
			"value": "Walking in Las Vegas",
			"secondary_value": "38.6 Walk Score…"
		},
		{
			"title": "Days with a moustache",
			"value": 42
		},
		{
			"title": "Pants ripped",
			"value": 4,
			"secondary_value": "50% decrease from 2014"
		},
		{
			"title": "X-Ray Sessions",
			"value": 4
		},
		{
			"title": "Times in a car",
			"value": 26
		},
		{
			"title": "Times on a plane",
			"value": 8
		},/*
		{
			"title": "Times in an airport",
			"value": 14
		},*/
		{
			"title": "Times on a boat",
			"value": 6
		},
		{
			"title": "Instagram Posts",
			"value": 88
		},
		{
			"title": "Tweets",
			"value": 1331
		},
		{
			"title": "Last place in 2015",
			"value": "The Banwatts'",
			"secondary_value": "2015-12-31T05:00:00.000Z"
		}
	]
}