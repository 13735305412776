module.exports={
	"title": "Food & Liquor",
	"text": "The numbers show that, more often than not, I don't eat at home. A small part of the reason is that it's simply easier to not have to manage groceries when living alone. But mostly it's because of the countless amazing food options near where I live and work.",
	"sources": ["Untappd", "Foursquare"],
	"stats": [
		{
			"title": "Food checkins",
			"value": 493,
			"secondary_value": "Restaurants, Diners, Takeout, Fast Food"
		},
		{
			"title": "Coffee checkins",
			"value": 220,
			"secondary_value": "Cafés, Coffee Shops"
		},
		{
			"title": "Liquor checkins",
			"value": 58,
			"secondary_value": "Bars, Pubs, Breweries, Liquor Stores"
		},
		{
			"title": "Most checkins at a venue",
			"value": 39,
			"secondary_value": "The Tenant of Parkdale"
		},
		{
			"title": "Avg. coffee per day",
			"value": 427.39726026,
			"value_type": "mL"
		},
		{
			"title": "Avg. beer per day",
			"value": 0.9417490494,
			"value_type": "pints"
		},
		{
			"title": "Bellwoods Brewery Brews*",
			"value": 22,
			"secondary_value": "*unique"
		},
		{
			"title": "Times at Food & Liquor",
			"value": 3
		}
	]
}