module.exports=[
	{
		"id": "54296b08498e550c05c02d28",
		"name": "The Tenant of Parkdale",
		"categories": [
			"Café"
		],
		"date": "2015-01-15T18:55:50.000Z",
		"point": [
			-79.431438,
			43.641656
		],
		"times": 39
	},
	{
		"id": "4ad4c05df964a52035f620e3",
		"name": "The Beaver Café",
		"categories": [
			"Café"
		],
		"date": "2015-01-14T19:38:03.000Z",
		"point": [
			-79.42650699432001,
			43.64277043902459
		],
		"times": 32
	},
	{
		"id": "553e3d0d498e9ba5848b15e0",
		"name": "Tucana Coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-05-24T19:01:51.000Z",
		"point": [
			-79.42911744813918,
			43.64947561117568
		],
		"times": 26
	},
	{
		"id": "4ae079d0f964a520807f21e3",
		"name": "Luna Cafe",
		"categories": [
			"Café"
		],
		"date": "2015-02-12T17:01:42.000Z",
		"point": [
			-79.4233688561736,
			43.64620547368371
		],
		"times": 25
	},
	{
		"id": "4f6c75eae4b0541c6070af0f",
		"name": "Mabel's Bakery",
		"categories": [
			"Bakery"
		],
		"date": "2015-02-04T00:23:38.000Z",
		"point": [
			-79.42494068653379,
			43.64296430099032
		],
		"times": 24
	},
	{
		"id": "4fbec1d0e4b0425aaab732c2",
		"name": "Fat Bastard Burrito Co.",
		"categories": [
			"Burrito Place"
		],
		"date": "2015-01-16T20:00:16.000Z",
		"point": [
			-79.42324794503577,
			43.64323085599093
		],
		"times": 22
	},
	{
		"id": "4b366216f964a520283425e3",
		"name": "Alexandria Falafel",
		"categories": [
			"Falafel Restaurant"
		],
		"date": "2015-01-02T00:02:12.000Z",
		"point": [
			-79.43017,
			43.641972
		],
		"times": 22
	},
	{
		"id": "5092d0fcfe705121acc516cd",
		"name": "The Hogtown Cure",
		"categories": [
			"Restaurant"
		],
		"date": "2015-01-04T19:26:59.000Z",
		"point": [
			-79.43145275115967,
			43.649677652720506
		],
		"times": 21
	},
	{
		"id": "4f7891c7e4b0b9643b73e08d",
		"name": "Bellwoods Brewery",
		"categories": [
			"Brewery"
		],
		"date": "2015-03-08T22:57:43.000Z",
		"point": [
			-79.41993381893113,
			43.64707222155943
		],
		"times": 20
	},
	{
		"id": "541856a8498ebf6212ae6334",
		"name": "The Tempered Room",
		"categories": [
			"Bakery"
		],
		"date": "2015-05-20T16:39:30.000Z",
		"point": [
			-79.43286538091901,
			43.64131155648978
		],
		"times": 19
	},
	{
		"id": "4aeba3a5f964a52013c421e3",
		"name": "Poutini's House of Poutine",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-01-20T18:33:08.000Z",
		"point": [
			-79.42311604179545,
			43.64332623744755
		],
		"times": 18
	},
	{
		"id": "4ad4c05cf964a520c0f520e3",
		"name": "Gladstone Hotel",
		"categories": [
			"Hotel"
		],
		"date": "2015-01-16T19:56:29.000Z",
		"point": [
			-79.42695200443268,
			43.64274480148588
		],
		"times": 18
	},
	{
		"id": "5262bcec498edeb98e570618",
		"name": "Bolt Fresh Bar",
		"categories": [
			"Juice Bar"
		],
		"date": "2015-02-26T18:26:25.000Z",
		"point": [
			-79.42541882541744,
			43.64279828757917
		],
		"times": 17
	},
	{
		"id": "4ae1d132f964a520ab8721e3",
		"name": "Rustic Cosmo",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-01-12T16:41:42.000Z",
		"point": [
			-79.42987034423598,
			43.642005933967845
		],
		"times": 17
	},
	{
		"id": "4ecee6d1be7b9eecde3a4aff",
		"name": "Grand Electric",
		"categories": [
			"Mexican Restaurant"
		],
		"date": "2015-01-15T18:15:10.000Z",
		"point": [
			-79.43159593689458,
			43.64171876523376
		],
		"times": 16
	},
	{
		"id": "4c9d5a3e46978cfac315977f",
		"name": "Just 4 Fun Sporting Club",
		"categories": [
			"Hockey Arena"
		],
		"date": "2015-09-05T18:06:11.000Z",
		"point": [
			-79.44582939147949,
			43.65523577741562
		],
		"times": 11
	},
	{
		"id": "4c12a172a5eb76b05a88beb7",
		"name": "Crows Nest Barbershop",
		"categories": [
			"Salon / Barbershop"
		],
		"date": "2015-02-09T18:01:49.000Z",
		"point": [
			-79.40005755694668,
			43.65297194329807
		],
		"times": 11
	},
	{
		"id": "4e0cd8d8aeb7af850d6cce50",
		"name": "The Boreal Gelato Company",
		"categories": [
			"Ice Cream Shop"
		],
		"date": "2015-02-03T18:23:21.000Z",
		"point": [
			-79.43096945157768,
			43.6418004489661
		],
		"times": 10
	},
	{
		"id": "54838585498e1787e915457d",
		"name": "Loga's Corner",
		"categories": [
			"Tibetan Restaurant"
		],
		"date": "2015-05-25T18:03:15.000Z",
		"point": [
			-79.43574492068005,
			43.640625857678685
		],
		"times": 10
	},
	{
		"id": "4b22e814f964a520175024e3",
		"name": "The Lakeview Restaurant",
		"categories": [
			"Diner"
		],
		"date": "2015-06-04T03:56:07.000Z",
		"point": [
			-79.42031854946579,
			43.6494337752087
		],
		"times": 9
	},
	{
		"id": "54e000c9498e4adcc4e449be",
		"name": "La Cubana",
		"categories": [
			"Cuban Restaurant"
		],
		"date": "2015-04-11T01:26:39.000Z",
		"point": [
			-79.419634,
			43.646288
		],
		"times": 9
	},
	{
		"id": "5154e54de4b0e042cc483378",
		"name": "Matter and Form",
		"categories": [
			"Tech Startup"
		],
		"date": "2015-09-29T20:59:35.000Z",
		"point": [
			-79.42941427230835,
			43.643932681623255
		],
		"times": 8
	},
	{
		"id": "550338b3498e1d83356595ef",
		"name": "MetroCycle",
		"categories": [
			"Bike Shop"
		],
		"date": "2015-03-13T20:12:28.000Z",
		"point": [
			-79.42967250352186,
			43.64432578972892
		],
		"times": 7
	},
	{
		"id": "4aec5216f964a52009c621e3",
		"name": "Gayley's Cafe",
		"categories": [
			"Café"
		],
		"date": "2015-01-13T13:33:00.000Z",
		"point": [
			-79.429428,
			43.649577
		],
		"times": 6
	},
	{
		"id": "4b491d0df964a520686626e3",
		"name": "El Almacen Yerba Mate Cafe + Gallery",
		"categories": [
			"Café"
		],
		"date": "2015-01-28T18:11:06.000Z",
		"point": [
			-79.42169257398645,
			43.643552249828474
		],
		"times": 6
	},
	{
		"id": "54dd75d2498e9d19fc00ab0d",
		"name": "Revolution Hall",
		"categories": [
			"Concert Hall"
		],
		"date": "2015-09-11T03:01:55.000Z",
		"point": [
			-122.65241143916933,
			45.51895765342136
		],
		"times": 6
	},
	{
		"id": "4b8ff652f964a5208b6c33e3",
		"name": "RaviSoups",
		"categories": [
			"Soup Place"
		],
		"date": "2015-01-28T18:07:44.000Z",
		"point": [
			-79.42387448284995,
			43.6431306112953
		],
		"times": 6
	},
	{
		"id": "50d47515e41268329c507a83",
		"name": "Tim Hortons",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-05-07T16:09:49.000Z",
		"point": [
			-79.41907167434691,
			43.644025847699496
		],
		"times": 5
	},
	{
		"id": "4cd57e5cab19a09300813feb",
		"name": "Capital Espresso And Pastries",
		"categories": [
			"Café"
		],
		"date": "2015-06-03T17:25:34.000Z",
		"point": [
			-79.434554,
			43.641024
		],
		"times": 5
	},
	{
		"id": "51aa63f1498e59e83d4f9319",
		"name": "The Burger's Priest",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-03-25T00:16:16.000Z",
		"point": [
			-79.3973253358694,
			43.648556908625196
		],
		"times": 5
	},
	{
		"id": "535feea2498ec5c2d57c5e64",
		"name": "Community Coffee Parkdale",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-03-30T17:25:42.000Z",
		"point": [
			-79.43144755917828,
			43.64164505483041
		],
		"times": 5
	},
	{
		"id": "5038fb3ae4b0916c78b0b953",
		"name": "Glory Hole Doughnuts",
		"categories": [
			"Donut Shop"
		],
		"date": "2015-02-12T20:36:43.000Z",
		"point": [
			-79.44134437849533,
			43.63965097270744
		],
		"times": 5
	},
	{
		"id": "534be768498e3a4e7b6e5795",
		"name": "Sam James Coffee Bar",
		"categories": [
			"Café"
		],
		"date": "2015-02-17T13:59:34.000Z",
		"point": [
			-79.418814,
			43.644397
		],
		"times": 5
	},
	{
		"id": "4af2fb96f964a52086e921e3",
		"name": "Toronto Western Hospital",
		"categories": [
			"Hospital"
		],
		"date": "2015-07-26T23:18:48.000Z",
		"point": [
			-79.40564269111441,
			43.653417771966126
		],
		"times": 4
	},
	{
		"id": "4b895d78f964a520402f32e3",
		"name": "La Merceria",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-03-21T19:29:39.000Z",
		"point": [
			-79.400671,
			43.645684
		],
		"times": 4
	},
	{
		"id": "4af749b7f964a520020822e3",
		"name": "BQM Ossington",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-01-30T17:38:14.000Z",
		"point": [
			-79.420682,
			43.648912
		],
		"times": 4
	},
	{
		"id": "553bc00e498ed19e786a5aad",
		"name": "Tokyo Smoke",
		"categories": [
			"Café"
		],
		"date": "2015-08-01T20:26:02.000Z",
		"point": [
			-79.41162874436509,
			43.64357904565416
		],
		"times": 4
	},
	{
		"id": "4ad7aa49f964a5207b0d21e3",
		"name": "Scotiabank Theatre",
		"categories": [
			"Movie Theater"
		],
		"date": "2015-05-31T19:39:54.000Z",
		"point": [
			-79.39094066619873,
			43.64871502020936
		],
		"times": 4
	},
	{
		"id": "5106ac3ae4b05d0d41a1f974",
		"name": "Smoke's Poutinerie",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-01-13T01:02:37.000Z",
		"point": [
			-79.4203954953801,
			43.65480203783929
		],
		"times": 4
	},
	{
		"id": "4adf720df964a520a17a21e3",
		"name": "Grain Curd Bean",
		"categories": [
			"Bakery"
		],
		"date": "2015-11-05T15:50:50.000Z",
		"point": [
			-79.429297,
			43.649581
		],
		"times": 4
	},
	{
		"id": "4acbafc4f964a520fbc420e3",
		"name": "Toronto Pearson International Airport (YYZ)",
		"categories": [
			"Airport"
		],
		"date": "2015-01-04T23:59:46.000Z",
		"point": [
			-79.61208343505858,
			43.680659965522246
		],
		"times": 4
	},
	{
		"id": "53ed0fd4498e33680fe69aef",
		"name": "A+ Sushi & Bibim",
		"categories": [
			"Korean Restaurant"
		],
		"date": "2015-06-03T17:11:06.000Z",
		"point": [
			-79.4341677793305,
			43.64106667983748
		],
		"times": 3
	},
	{
		"id": "4c35332a452620a1fe7d260f",
		"name": "Unlovable",
		"categories": [
			"Bar"
		],
		"date": "2015-03-28T01:57:08.000Z",
		"point": [
			-79.42926033400285,
			43.64952961769185
		],
		"times": 3
	},
	{
		"id": "53f95be4498e70ab364a23e0",
		"name": "Duggan's Brewery",
		"categories": [
			"Brewery"
		],
		"date": "2015-06-09T23:11:52.000Z",
		"point": [
			-79.43213998647603,
			43.64162257798886
		],
		"times": 3
	},
	{
		"id": "4ba6e1f8f964a520537539e3",
		"name": "Om Restaurant",
		"categories": [
			"Indian Restaurant"
		],
		"date": "2015-02-05T18:49:56.000Z",
		"point": [
			-79.43777812924469,
			43.640245657871155
		],
		"times": 3
	},
	{
		"id": "4f6501a4e4b036e3e889b918",
		"name": "This End Up",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-01-27T00:49:37.000Z",
		"point": [
			-79.43054629680361,
			43.649655781690605
		],
		"times": 3
	},
	{
		"id": "5406814a498eef2f7025c13b",
		"name": "Nuit Social",
		"categories": [
			"Lounge"
		],
		"date": "2015-04-01T16:23:11.000Z",
		"point": [
			-79.42519349590772,
			43.64293846824452
		],
		"times": 3
	},
	{
		"id": "5275722f498ed8a90d2c1235",
		"name": "Empire Espresso",
		"categories": [
			"Café"
		],
		"date": "2015-03-14T20:09:14.000Z",
		"point": [
			-79.41651821136475,
			43.655189203088945
		],
		"times": 3
	},
	{
		"id": "4c8542d074d7b60c1e0d9ad8",
		"name": "KFC",
		"categories": [
			"Fried Chicken Joint"
		],
		"date": "2015-07-12T00:38:02.000Z",
		"point": [
			-79.423147,
			43.64929427392781
		],
		"times": 3
	},
	{
		"id": "4bfaaa7e5317a593d0fc027f",
		"name": "Gladstone Cafe",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-01-03T22:44:21.000Z",
		"point": [
			-79.42674815654755,
			43.642333308117706
		],
		"times": 3
	},
	{
		"id": "4ae7bc29f964a52084ad21e3",
		"name": "Ezra's Pound",
		"categories": [
			"Café"
		],
		"date": "2015-01-18T22:23:24.000Z",
		"point": [
			-79.405959,
			43.675068
		],
		"times": 3
	},
	{
		"id": "54b47574498e6b087d957fa2",
		"name": "Eulalie's Corner Store",
		"categories": [
			"Bar"
		],
		"date": "2015-08-08T02:43:41.000Z",
		"point": [
			-79.32216592361095,
			43.67234917061714
		],
		"times": 3
	},
	{
		"id": "53dd7998498e33be78534322",
		"name": "The Burger's Priest",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-05-23T21:48:13.000Z",
		"point": [
			-79.5824760816117,
			43.55439685740902
		],
		"times": 3
	},
	{
		"id": "537bd61f498e0dae73728800",
		"name": "Bang Bang Ice Cream & Bakery",
		"categories": [
			"Ice Cream Shop"
		],
		"date": "2015-05-22T18:03:29.000Z",
		"point": [
			-79.41949962111416,
			43.64629272252542
		],
		"times": 3
	},
	{
		"id": "51e341c5498e5e2035539b66",
		"name": "Food & Liquor",
		"categories": [
			"Restaurant"
		],
		"date": "2015-06-15T23:20:13.000Z",
		"point": [
			-79.441839,
			43.639601
		],
		"times": 3
	},
	{
		"id": "52348da911d2fa119be56f12",
		"name": "La Cubana",
		"categories": [
			"Cuban Restaurant"
		],
		"date": "2015-06-14T16:03:01.000Z",
		"point": [
			-79.450873,
			43.650913
		],
		"times": 3
	},
	{
		"id": "5005ece0e4b0d23ce4803e7c",
		"name": "Bivy",
		"categories": [
			"Café"
		],
		"date": "2015-04-24T18:21:25.000Z",
		"point": [
			-79.4350799967846,
			43.649885951782885
		],
		"times": 3
	},
	{
		"id": "4bf95e8f5efe2d7ffa126c34",
		"name": "Drake Cafe",
		"categories": [
			"Restaurant"
		],
		"date": "2015-04-17T20:21:28.000Z",
		"point": [
			-79.42477,
			43.6430703
		],
		"times": 3
	},
	{
		"id": "4ad7998df964a5209e0c21e3",
		"name": "The Rhino",
		"categories": [
			"Bar"
		],
		"date": "2015-04-14T23:31:22.000Z",
		"point": [
			-79.4306397207521,
			43.64170884139754
		],
		"times": 3
	},
	{
		"id": "4c94f2a072dd224bc1c49e91",
		"name": "Full Of Beans",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-01-30T19:25:28.000Z",
		"point": [
			-79.42728189464528,
			43.649621143070924
		],
		"times": 3
	},
	{
		"id": "4ad4c05cf964a5200ef620e3",
		"name": "Cafe Diplomatico",
		"categories": [
			"Italian Restaurant"
		],
		"date": "2015-04-10T17:19:35.000Z",
		"point": [
			-79.413951,
			43.65528
		],
		"times": 3
	},
	{
		"id": "4459d2f1f964a520d7321fe3",
		"name": "Newark Liberty International Airport (EWR)",
		"categories": [
			"Airport"
		],
		"date": "2015-04-08T22:03:01.000Z",
		"point": [
			-74.17938709259033,
			40.68968493541091
		],
		"times": 3
	},
	{
		"id": "4ad8df54f964a520881521e3",
		"name": "Billy Bishop Toronto City Airport (YTZ)",
		"categories": [
			"Airport"
		],
		"date": "2015-04-09T00:17:14.000Z",
		"point": [
			-79.39615488052368,
			43.631602429512775
		],
		"times": 3
	},
	{
		"id": "4b8a1959f964a520f15f32e3",
		"name": "College Falafel",
		"categories": [
			"Falafel Restaurant"
		],
		"date": "2015-03-11T21:25:36.000Z",
		"point": [
			-79.422788,
			43.654524
		],
		"times": 2
	},
	{
		"id": "4ad7cc53f964a520dc0e21e3",
		"name": "Crooked Star",
		"categories": [
			"Bar"
		],
		"date": "2015-07-08T03:36:01.000Z",
		"point": [
			-79.420694,
			43.648916
		],
		"times": 2
	},
	{
		"id": "4c7be89283a7bfb7637d8ff8",
		"name": "Snakes & Lattes",
		"categories": [
			"Café"
		],
		"date": "2015-06-22T22:45:29.000Z",
		"point": [
			-79.4131864,
			43.6648372
		],
		"times": 2
	},
	{
		"id": "4ae74cfaf964a5202eaa21e3",
		"name": "King's Noodle House 富豪麵家",
		"categories": [
			"Chinese Restaurant"
		],
		"date": "2015-08-02T01:44:00.000Z",
		"point": [
			-79.3983006477356,
			43.65310884632263
		],
		"times": 2
	},
	{
		"id": "4fe54620e4b067dfbe1dc838",
		"name": "Get Well",
		"categories": [
			"Bar"
		],
		"date": "2015-06-20T03:46:31.000Z",
		"point": [
			-79.421943,
			43.649291
		],
		"times": 2
	},
	{
		"id": "560e0a6a498ec4c16375c048",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-12-03T18:03:47.000Z",
		"point": [
			-79.4350799967846,
			43.6409472626158
		],
		"times": 2
	},
	{
		"id": "4baa029cf964a52085433ae3",
		"name": "Woodbine Park",
		"categories": [
			"Park"
		],
		"date": "2015-06-20T00:02:44.000Z",
		"point": [
			-79.31510925292969,
			43.66486036311473
		],
		"times": 2
	},
	{
		"id": "4c00610d9cf52d7f9db313e7",
		"name": "Centre for Social Innovation",
		"categories": [
			"Coworking Space"
		],
		"date": "2015-03-21T00:15:06.000Z",
		"point": [
			-79.4106613277101,
			43.66323117548665
		],
		"times": 2
	},
	{
		"id": "4e78fc12d4c01c5f77ef2d63",
		"name": "Princeview Dental Group",
		"categories": [
			"Dentist's Office"
		],
		"date": "2015-06-16T18:26:56.000Z",
		"point": [
			-79.5063829421997,
			43.6483113310164
		],
		"times": 2
	},
	{
		"id": "4cbf015897bc721e6fe08267",
		"name": "Fanny Chadwick's",
		"categories": [
			"Restaurant"
		],
		"date": "2015-01-25T00:40:40.000Z",
		"point": [
			-79.412037,
			43.673606
		],
		"times": 2
	},
	{
		"id": "544fc36c498ea63958331891",
		"name": "Wilbur Mexicana",
		"categories": [
			"Mexican Restaurant"
		],
		"date": "2015-03-21T19:55:58.000Z",
		"point": [
			-79.39856776432038,
			43.644883406826594
		],
		"times": 2
	},
	{
		"id": "4ad4c05cf964a520b8f520e3",
		"name": "Drake Hotel",
		"categories": [
			"Hotel"
		],
		"date": "2015-09-03T03:02:02.000Z",
		"point": [
			-79.42462600487526,
			43.643113012062386
		],
		"times": 2
	},
	{
		"id": "559f1e2f498e22a20c98cedf",
		"name": "Miss Thing's",
		"categories": [
			"Hawaiian Restaurant"
		],
		"date": "2015-09-03T02:01:49.000Z",
		"point": [
			-79.431974,
			43.641599
		],
		"times": 2
	},
	{
		"id": "4bc9de2b0687ef3b719fdacc",
		"name": "Queen Star",
		"categories": [
			"Breakfast Spot"
		],
		"date": "2015-06-12T17:07:55.000Z",
		"point": [
			-79.423127,
			43.643303
		],
		"times": 2
	},
	{
		"id": "4adb2666f964a5208d2421e3",
		"name": "White Squirrel",
		"categories": [
			"Café"
		],
		"date": "2015-03-23T13:47:46.000Z",
		"point": [
			-79.412445,
			43.645519
		],
		"times": 2
	},
	{
		"id": "4b2274c1f964a5200f4724e3",
		"name": "Jimmy's Coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-08-16T18:10:32.000Z",
		"point": [
			-79.40032692648134,
			43.645317121789304
		],
		"times": 2
	},
	{
		"id": "4fe27e12e4b0311209dda935",
		"name": "Barton Snacks",
		"categories": [
			"Snack Place"
		],
		"date": "2015-01-29T22:53:30.000Z",
		"point": [
			-79.42339083999981,
			43.64318913791259
		],
		"times": 2
	},
	{
		"id": "4ada1e82f964a520a11e21e3",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-06-10T23:07:35.000Z",
		"point": [
			-79.42364365362435,
			43.649493095611234
		],
		"times": 2
	},
	{
		"id": "4c8e727b58668cfa1925d2ec",
		"name": "Le Gourmand Café",
		"categories": [
			"Café"
		],
		"date": "2015-03-29T17:17:32.000Z",
		"point": [
			-79.3965196609497,
			43.64814053861799
		],
		"times": 2
	},
	{
		"id": "4cfa98602d80a143a6d245d8",
		"name": "Telegramme Prints and Custom Framing",
		"categories": [
			"Photography Lab"
		],
		"date": "2015-11-28T21:44:01.000Z",
		"point": [
			-79.42053839476495,
			43.648511927096074
		],
		"times": 2
	},
	{
		"id": "4b942986f964a520046c34e3",
		"name": "Burrito Boyz",
		"categories": [
			"Burrito Place"
		],
		"date": "2015-11-22T22:56:58.000Z",
		"point": [
			-79.413036,
			43.655354
		],
		"times": 2
	},
	{
		"id": "55175269498ef06e96eeeb98",
		"name": "Trinity Common",
		"categories": [
			"Bar"
		],
		"date": "2015-06-23T23:22:43.000Z",
		"point": [
			-79.40276221132652,
			43.656502996471794
		],
		"times": 2
	},
	{
		"id": "4f4f91d2e4b02966d481f187",
		"name": "The Federal",
		"categories": [
			"Restaurant"
		],
		"date": "2015-10-21T22:13:09.000Z",
		"point": [
			-79.430034,
			43.649717
		],
		"times": 2
	},
	{
		"id": "4bbea45e006dc9b6a092fb3f",
		"name": "Carnegie Deli",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-01-07T18:17:10.000Z",
		"point": [
			-115.17405079538965,
			36.12068709261332
		],
		"times": 2
	},
	{
		"id": "4a677f82f964a52091c91fe3",
		"name": "McCarran International Airport",
		"categories": [
			"Airport"
		],
		"date": "2015-01-05T08:06:51.000Z",
		"point": [
			-115.14985084533691,
			36.08365019548198
		],
		"times": 2
	},
	{
		"id": "4a7358b8f964a5203cdc1fe3",
		"name": "In-N-Out Burger",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-01-08T21:30:21.000Z",
		"point": [
			-115.18237799406052,
			36.10131671394321
		],
		"times": 2
	},
	{
		"id": "51328f1ee4b08f949cce54a0",
		"name": "Electric Mud BBQ",
		"categories": [
			"BBQ Joint"
		],
		"date": "2015-05-28T00:14:55.000Z",
		"point": [
			-79.43237628983867,
			43.641932943320995
		],
		"times": 2
	},
	{
		"id": "4b76dcb6f964a52019652ee3",
		"name": "Peppermill Restaurant",
		"categories": [
			"Diner"
		],
		"date": "2015-01-09T05:28:49.000Z",
		"point": [
			-115.16329139471054,
			36.13364844085978
		],
		"times": 2
	},
	{
		"id": "4b266f05f964a520657b24e3",
		"name": "Gold Stone Noodle Restaurant 金石",
		"categories": [
			"Chinese Restaurant"
		],
		"date": "2015-08-14T00:26:15.000Z",
		"point": [
			-79.39791356735041,
			43.65224584681548
		],
		"times": 2
	},
	{
		"id": "5407178a498e90c0cad2f050",
		"name": "Hula Girl",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-07-22T17:04:13.000Z",
		"point": [
			-79.42277529088447,
			43.65481842029156
		],
		"times": 2
	},
	{
		"id": "4d23861e71276a31fff89e99",
		"name": "Porchetta & Co.",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-07-21T18:10:36.000Z",
		"point": [
			-79.4090023,
			43.6515867
		],
		"times": 2
	},
	{
		"id": "3fd66200f964a520b8ea1ee3",
		"name": "Veselka",
		"categories": [
			"Ukrainian Restaurant"
		],
		"date": "2015-04-05T17:35:36.000Z",
		"point": [
			-73.9869715129134,
			40.729136234409154
		],
		"times": 2
	},
	{
		"id": "4ae5d93ef964a52083a221e3",
		"name": "Pho Asia 21",
		"categories": [
			"Vietnamese Restaurant"
		],
		"date": "2015-05-21T22:36:31.000Z",
		"point": [
			-79.42769407301456,
			43.63890221942408
		],
		"times": 2
	},
	{
		"id": "4ad91f6af964a520531821e3",
		"name": "The Ceili Cottage",
		"categories": [
			"Pub"
		],
		"date": "2015-07-17T01:26:26.000Z",
		"point": [
			-79.328053,
			43.6636
		],
		"times": 2
	},
	{
		"id": "5421d7b7498e4a7bb12df7ba",
		"name": "Momofuku Milk Bar",
		"categories": [
			"Dessert Shop"
		],
		"date": "2015-05-16T20:54:49.000Z",
		"point": [
			-74.00169155325936,
			40.72371584529214
		],
		"times": 2
	},
	{
		"id": "5550e0c9498ea5830a839f06",
		"name": "Reunion Island Coffee Bar",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-05-11T17:04:43.000Z",
		"point": [
			-79.45052037822136,
			43.6504867822684
		],
		"times": 2
	},
	{
		"id": "4addf4e4f964a520bd6621e3",
		"name": "Mitzi's Cafe",
		"categories": [
			"Café"
		],
		"date": "2015-05-08T17:02:31.000Z",
		"point": [
			-79.44217410233996,
			43.64284692013725
		],
		"times": 2
	},
	{
		"id": "4b0073a7f964a520643e22e3",
		"name": "Cineplex Odeon Queensway Cinemas",
		"categories": [
			"Movie Theater"
		],
		"date": "2015-09-20T23:42:29.000Z",
		"point": [
			-79.51543807983398,
			43.62104050448816
		],
		"times": 2
	},
	{
		"id": "54c9012f498e51274c4af8c3",
		"name": "Buster Rhino's BBQ",
		"categories": [
			"BBQ Joint"
		],
		"date": "2015-04-25T02:43:26.000Z",
		"point": [
			-79.42297697067261,
			43.65442848397301
		],
		"times": 2
	},
	{
		"id": "4d3e1a66457cb60c7ad5fda4",
		"name": "416 Snack Bar",
		"categories": [
			"Speakeasy"
		],
		"date": "2015-07-15T23:03:42.000Z",
		"point": [
			-79.404024,
			43.647421
		],
		"times": 2
	},
	{
		"id": "4bb0e1dbf964a52060663ce3",
		"name": "R. C. Harris Water Treatment Plant",
		"categories": [
			"Building"
		],
		"date": "2015-04-18T20:59:55.000Z",
		"point": [
			-79.27997283861565,
			43.67299251944745
		],
		"times": 2
	},
	{
		"id": "41326e00f964a5209f141fe3",
		"name": "The Mirage Hotel & Casino",
		"categories": [
			"Casino"
		],
		"date": "2015-01-05T08:54:21.000Z",
		"point": [
			-115.17517089843749,
			36.121566227189106
		],
		"times": 2
	},
	{
		"id": "4c1169396e5dc9b61b10b02d",
		"name": "The Burger's Priest",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-07-12T23:44:43.000Z",
		"point": [
			-79.315493,
			43.66658597106732
		],
		"times": 2
	},
	{
		"id": "4ae503e0f964a52004a021e3",
		"name": "Comedy Bar",
		"categories": [
			"Comedy Club"
		],
		"date": "2015-09-08T23:53:58.000Z",
		"point": [
			-79.427932,
			43.661476
		],
		"times": 2
	},
	{
		"id": "4ad4c062f964a520fef720e3",
		"name": "MEC Toronto",
		"categories": [
			"Sporting Goods Shop"
		],
		"date": "2015-07-10T00:08:08.000Z",
		"point": [
			-79.39298987388611,
			43.646059937682246
		],
		"times": 2
	},
	{
		"id": "4ad4c05df964a52067f620e3",
		"name": "Dakota Tavern",
		"categories": [
			"Bar"
		],
		"date": "2015-07-08T05:37:44.000Z",
		"point": [
			-79.42085167341067,
			43.64963786574688
		],
		"times": 2
	},
	{
		"id": "4ad4c062f964a520f2f720e3",
		"name": "The Danforth Music Hall",
		"categories": [
			"Concert Hall"
		],
		"date": "2015-08-07T00:00:02.000Z",
		"point": [
			-79.35703082845167,
			43.6762867757549
		],
		"times": 2
	},
	{
		"id": "53750967498e5821a3dbc347",
		"name": "Shopify Toronto",
		"categories": [
			"Tech Startup"
		],
		"date": "2015-04-10T01:39:31.000Z",
		"point": [
			-79.395935,
			43.646163
		],
		"times": 2
	},
	{
		"id": "4ad4c063f964a5202cf820e3",
		"name": "Soundscapes",
		"categories": [
			"Record Shop"
		],
		"date": "2015-04-10T17:19:16.000Z",
		"point": [
			-79.4128285,
			43.6554202
		],
		"times": 2
	},
	{
		"id": "4f903e56e4b0c95a359fce0e",
		"name": "The Chickery",
		"categories": [
			"Fried Chicken Joint"
		],
		"date": "2015-08-03T17:19:15.000Z",
		"point": [
			-79.39619833986576,
			43.64728615083334
		],
		"times": 1
	},
	{
		"id": "4ada7db7f964a520042321e3",
		"name": "Bar Wellington",
		"categories": [
			"Pub"
		],
		"date": "2015-04-10T03:52:49.000Z",
		"point": [
			-79.399507,
			43.643298
		],
		"times": 1
	},
	{
		"id": "4bd5e616637ba593cb3ef770",
		"name": "Firkin on King",
		"categories": [
			"Pub"
		],
		"date": "2015-04-10T02:47:12.000Z",
		"point": [
			-79.39639131250001,
			43.645179
		],
		"times": 1
	},
	{
		"id": "44c91345f964a520f3351fe3",
		"name": "Max Brenner",
		"categories": [
			"Dessert Shop"
		],
		"date": "2015-04-08T19:49:16.000Z",
		"point": [
			-73.99091992312634,
			40.73432951142438
		],
		"times": 1
	},
	{
		"id": "3fd66200f964a520c4f11ee3",
		"name": "Mudspot",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-04-08T19:31:38.000Z",
		"point": [
			-73.98681104426099,
			40.7290704479652
		],
		"times": 1
	},
	{
		"id": "4ad4c05df964a5207df620e3",
		"name": "Virgin Mobile Mod Club",
		"categories": [
			"Rock Club"
		],
		"date": "2015-04-11T03:10:48.000Z",
		"point": [
			-79.41923580372067,
			43.655336996134366
		],
		"times": 1
	},
	{
		"id": "5260065111d25cf087b876fe",
		"name": "Cut the Cheese",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-04-12T01:18:45.000Z",
		"point": [
			-79.46599662064497,
			43.66540837727648
		],
		"times": 1
	},
	{
		"id": "4baebdabf964a520ddd33be3",
		"name": "Hole in the Wall",
		"categories": [
			"Bar"
		],
		"date": "2015-04-12T01:51:57.000Z",
		"point": [
			-79.46512316416101,
			43.6653752438719
		],
		"times": 1
	},
	{
		"id": "5498c343498ed095cce0e874",
		"name": "Five Guys",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-04-12T19:28:21.000Z",
		"point": [
			-79.4695343090567,
			43.67400259721054
		],
		"times": 1
	},
	{
		"id": "4c2e1fc7e116e21eb5bc8f60",
		"name": "Sugar Beach",
		"categories": [
			"Beach"
		],
		"date": "2015-04-12T22:44:20.000Z",
		"point": [
			-79.36740855929415,
			43.64319235571616
		],
		"times": 1
	},
	{
		"id": "4eee5e0693add02fcd39aa10",
		"name": "Xe Máy Sandwich Shop",
		"categories": [
			"Vietnamese Restaurant"
		],
		"date": "2015-04-08T19:05:59.000Z",
		"point": [
			-73.98485380013902,
			40.727371294549435
		],
		"times": 1
	},
	{
		"id": "5192473b498e9546593d554b",
		"name": "Fruitman Kates",
		"categories": [
			"Financial or Legal Service"
		],
		"date": "2015-04-17T14:19:14.000Z",
		"point": [
			-79.433252,
			43.699228
		],
		"times": 1
	},
	{
		"id": "514b665bf31c2c1960f7a8aa",
		"name": "The Burgernator",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-04-17T15:41:21.000Z",
		"point": [
			-79.40241515636444,
			43.655484173214575
		],
		"times": 1
	},
	{
		"id": "4dc34fc7ae608779d1064ae9",
		"name": "Bathtub Gin",
		"categories": [
			"Speakeasy"
		],
		"date": "2015-04-07T23:31:55.000Z",
		"point": [
			-74.00334999764773,
			40.74359105994247
		],
		"times": 1
	},
	{
		"id": "4cdc69dcd4ecb1f7173f7f48",
		"name": "The Slow Room",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-04-18T17:14:29.000Z",
		"point": [
			-79.424537,
			43.654038
		],
		"times": 1
	},
	{
		"id": "4e74bd6ea809582dd59bc613",
		"name": "Zucker Bakery",
		"categories": [
			"Café"
		],
		"date": "2015-04-07T20:46:44.000Z",
		"point": [
			-73.9833124,
			40.7277147
		],
		"times": 1
	},
	{
		"id": "4c50ee5ad2a7c9b6c4025710",
		"name": "Pour Boy - 666 Manning TORONTO",
		"categories": [
			"Pub"
		],
		"date": "2015-04-23T23:40:50.000Z",
		"point": [
			-79.41618536030433,
			43.66431826922406
		],
		"times": 1
	},
	{
		"id": "4df10b413151de684c183380",
		"name": "Smoke's Poutinerie",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-04-24T03:37:36.000Z",
		"point": [
			-79.40969390091632,
			43.665453844642016
		],
		"times": 1
	},
	{
		"id": "50fdbf2ce88928976cc8733c",
		"name": "Birch Coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-04-07T19:12:49.000Z",
		"point": [
			-74.00018334388733,
			40.73828290651089
		],
		"times": 1
	},
	{
		"id": "3fd66200f964a52084e51ee3",
		"name": "Eisenberg's Sandwich Shop",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-04-07T17:41:37.000Z",
		"point": [
			-73.99004459381104,
			40.7411036869278
		],
		"times": 1
	},
	{
		"id": "5064c3dde4b07c5a18986283",
		"name": "Five Guys",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-04-29T23:33:17.000Z",
		"point": [
			-79.38085556030273,
			43.656881380438804
		],
		"times": 1
	},
	{
		"id": "4ad4c061f964a520b3f720e3",
		"name": "Massey Hall",
		"categories": [
			"Concert Hall"
		],
		"date": "2015-04-30T00:02:01.000Z",
		"point": [
			-79.378996,
			43.654122
		],
		"times": 1
	},
	{
		"id": "545d6eeb498ea42297425c1e",
		"name": "Bar Fancy",
		"categories": [
			"Bar"
		],
		"date": "2015-05-01T23:28:26.000Z",
		"point": [
			-79.42131334526438,
			43.64377818683114
		],
		"times": 1
	},
	{
		"id": "4b8168c7f964a52055a430e3",
		"name": "Stella",
		"categories": [
			"Bar"
		],
		"date": "2015-05-02T02:37:07.000Z",
		"point": [
			-79.44160263771253,
			43.65859804556532
		],
		"times": 1
	},
	{
		"id": "4ed7b465d3e37b38b8c39e87",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-05-02T18:31:48.000Z",
		"point": [
			-79.41197503308977,
			43.655517163870805
		],
		"times": 1
	},
	{
		"id": "4c059371d3842d7f0966be41",
		"name": "Smiley Guy Studios",
		"categories": [
			"Office"
		],
		"date": "2015-05-02T19:20:37.000Z",
		"point": [
			-79.40742920309789,
			43.65510193370249
		],
		"times": 1
	},
	{
		"id": "4ded40467d8bb2167420594f",
		"name": "The Beer Store Boutique",
		"categories": [
			"Beer Store"
		],
		"date": "2015-05-03T18:06:16.000Z",
		"point": [
			-79.41771984100342,
			43.639227606885946
		],
		"times": 1
	},
	{
		"id": "4fcea172e4b071a2c8809ed2",
		"name": "Chop Shop",
		"categories": [
			"Chinese Restaurant"
		],
		"date": "2015-04-07T00:42:37.000Z",
		"point": [
			-74.00340164447411,
			40.74884256380788
		],
		"times": 1
	},
	{
		"id": "4eeb4c51775b1d4812e0261c",
		"name": "Shake Shack",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-04-06T20:17:58.000Z",
		"point": [
			-73.98874486417706,
			40.69220941641377
		],
		"times": 1
	},
	{
		"id": "4af5879cf964a5209ff921e3",
		"name": "Boom Breakfast & Co.",
		"categories": [
			"Breakfast Spot"
		],
		"date": "2015-05-10T19:16:50.000Z",
		"point": [
			-79.421793,
			43.654602
		],
		"times": 1
	},
	{
		"id": "51a931d12fc6bd6c3f9bd028",
		"name": "Freehold",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-04-06T19:23:26.000Z",
		"point": [
			-73.9660656452179,
			40.71370372777467
		],
		"times": 1
	},
	{
		"id": "4bd1ea4d046076b052cb7271",
		"name": "Parts & Labour",
		"categories": [
			"Gastropub"
		],
		"date": "2015-05-12T23:36:43.000Z",
		"point": [
			-79.44043771805774,
			43.63998685839831
		],
		"times": 1
	},
	{
		"id": "4d5301fa9d49370431f6de39",
		"name": "Roti Lady",
		"categories": [
			"Caribbean Restaurant"
		],
		"date": "2015-05-13T17:15:59.000Z",
		"point": [
			-79.434466,
			43.640926
		],
		"times": 1
	},
	{
		"id": "4dcb3ddefa76d745f0514d24",
		"name": "WVRST",
		"categories": [
			"Bar"
		],
		"date": "2015-05-15T16:38:44.000Z",
		"point": [
			-79.40069524681913,
			43.644158758915665
		],
		"times": 1
	},
	{
		"id": "49f220a3f964a520ee691fe3",
		"name": "Nom Wah Tea Parlor",
		"categories": [
			"Dim Sum Restaurant"
		],
		"date": "2015-05-16T17:38:47.000Z",
		"point": [
			-73.99800734512525,
			40.71448981906862
		],
		"times": 1
	},
	{
		"id": "4e0cb1c918509581f763fea2",
		"name": "Financier Patisserie",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-05-16T19:21:23.000Z",
		"point": [
			-74.00769889354706,
			40.71014985041449
		],
		"times": 1
	},
	{
		"id": "51b70eb9454a121ab89e5c42",
		"name": "Pennylane Coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-04-06T17:06:20.000Z",
		"point": [
			-73.9706039428711,
			40.75140217776116
		],
		"times": 1
	},
	{
		"id": "51fd5693498ebbf735fee35b",
		"name": "Five Guys",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-05-17T00:59:42.000Z",
		"point": [
			-73.99622260200258,
			40.737137713058644
		],
		"times": 1
	},
	{
		"id": "4e302cb1c65b80dfd84807cc",
		"name": "Café de La Esquina",
		"categories": [
			"Taco Place"
		],
		"date": "2015-05-17T20:41:30.000Z",
		"point": [
			-73.96292922422238,
			40.71705289740293
		],
		"times": 1
	},
	{
		"id": "3fd66200f964a5202ce41ee3",
		"name": "Burp Castle",
		"categories": [
			"Pub"
		],
		"date": "2015-05-17T21:00:43.000Z",
		"point": [
			-73.98864730158843,
			40.72821009204639
		],
		"times": 1
	},
	{
		"id": "3fd66200f964a520dbea1ee3",
		"name": "The Blind Tiger",
		"categories": [
			"Bar"
		],
		"date": "2015-05-17T22:41:16.000Z",
		"point": [
			-74.00334999764773,
			40.73185406724251
		],
		"times": 1
	},
	{
		"id": "3fd66200f964a52051e61ee3",
		"name": "Comedy Cellar",
		"categories": [
			"Comedy Club"
		],
		"date": "2015-05-18T01:47:43.000Z",
		"point": [
			-74.0003774123832,
			40.7301270100377
		],
		"times": 1
	},
	{
		"id": "4a9c1586f964a520ef3520e3",
		"name": "Ben's Pizzeria",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-05-18T01:50:39.000Z",
		"point": [
			-74.00017261505127,
			40.73054343648237
		],
		"times": 1
	},
	{
		"id": "501723e0e4b0781bc1053599",
		"name": "Isola Trattoria & Crudo Bar",
		"categories": [
			"Italian Restaurant"
		],
		"date": "2015-05-18T16:14:14.000Z",
		"point": [
			-73.99996423085565,
			40.71977840775298
		],
		"times": 1
	},
	{
		"id": "4a9eb0e7f964a520e43a20e3",
		"name": "Saturdays Surf NYC",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-05-18T17:27:09.000Z",
		"point": [
			-73.99924116103759,
			40.720763668225736
		],
		"times": 1
	},
	{
		"id": "3fd66200f964a52074e71ee3",
		"name": "Grand Central Oyster Bar",
		"categories": [
			"Seafood Restaurant"
		],
		"date": "2015-05-18T22:07:36.000Z",
		"point": [
			-73.97758003761531,
			40.75250498676928
		],
		"times": 1
	},
	{
		"id": "4283ee00f964a520ce221fe3",
		"name": "FAO Schwarz",
		"categories": [
			"Toy / Game Store"
		],
		"date": "2015-05-18T23:58:18.000Z",
		"point": [
			-73.9726209640503,
			40.76345028135923
		],
		"times": 1
	},
	{
		"id": "43b9adf1f964a520e22c1fe3",
		"name": "John's of Bleecker Street",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-05-19T01:22:21.000Z",
		"point": [
			-74.00324670395317,
			40.73172115027891
		],
		"times": 1
	},
	{
		"id": "42911d00f964a520f5231fe3",
		"name": "New York Penn Station",
		"categories": [
			"Train Station"
		],
		"date": "2015-05-19T12:26:15.000Z",
		"point": [
			-73.99330615997314,
			40.75039434535128
		],
		"times": 1
	},
	{
		"id": "49c406a8f964a5208d561fe3",
		"name": "Brooklyn Bagel & Coffee Co.",
		"categories": [
			"Bagel Shop"
		],
		"date": "2015-04-06T15:58:26.000Z",
		"point": [
			-73.9976859778158,
			40.74628633485494
		],
		"times": 1
	},
	{
		"id": "4a0382bef964a520c3711fe3",
		"name": "Rocco's Pizza Joint",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-04-05T23:43:14.000Z",
		"point": [
			-73.99716949351073,
			40.741935184406486
		],
		"times": 1
	},
	{
		"id": "4d960605daec224beab30c3e",
		"name": "Coppelia",
		"categories": [
			"Cuban Restaurant"
		],
		"date": "2015-04-05T00:39:26.000Z",
		"point": [
			-74.00000440132106,
			40.73872167848544
		],
		"times": 1
	},
	{
		"id": "4ce94857e1eeb60cfa23a8ae",
		"name": "Club Monaco",
		"categories": [
			"Clothing Store"
		],
		"date": "2015-05-23T20:49:26.000Z",
		"point": [
			-79.55809123605947,
			43.611336381519735
		],
		"times": 1
	},
	{
		"id": "4a88147ef964a5201e0520e3",
		"name": "Chelsea Waterside Park Dog Run",
		"categories": [
			"Dog Run"
		],
		"date": "2015-04-04T23:24:10.000Z",
		"point": [
			-74.00744901765106,
			40.748669
		],
		"times": 1
	},
	{
		"id": "51b37cce498e15fcf838bc08",
		"name": "Brockton Haunt",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-05-24T17:14:56.000Z",
		"point": [
			-79.433947,
			43.652212
		],
		"times": 1
	},
	{
		"id": "4ad4c061f964a520a8f720e3",
		"name": "Nova Era Bakery",
		"categories": [
			"Bakery"
		],
		"date": "2015-05-24T17:51:23.000Z",
		"point": [
			-79.42043423652649,
			43.654933043646444
		],
		"times": 1
	},
	{
		"id": "4c225ae47e85c9283ee5bb21",
		"name": "Trader Joe's",
		"categories": [
			"Grocery Store"
		],
		"date": "2015-04-04T21:17:12.000Z",
		"point": [
			-73.99399,
			40.74212
		],
		"times": 1
	},
	{
		"id": "44522a64f964a520a8321fe3",
		"name": "Dirty Bird To Go",
		"categories": [
			"Fried Chicken Joint"
		],
		"date": "2015-04-04T18:36:41.000Z",
		"point": [
			-74.0003429806233,
			40.73891709652379
		],
		"times": 1
	},
	{
		"id": "4f05dd6eb8f7dcbb4042ce46",
		"name": "Tim Hortons",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-05-27T00:29:30.000Z",
		"point": [
			-79.33560074652158,
			43.713990139125464
		],
		"times": 1
	},
	{
		"id": "4aea405ef964a52082ba21e3",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-05-27T02:06:55.000Z",
		"point": [
			-79.39066171646118,
			43.68097032862214
		],
		"times": 1
	},
	{
		"id": "4be59d76910020a19530d314",
		"name": "Citipups Chelsea",
		"categories": [
			"Pet Store"
		],
		"date": "2015-04-04T17:39:59.000Z",
		"point": [
			-74.00109529495239,
			40.74176212277341
		],
		"times": 1
	},
	{
		"id": "4388f700f964a520182b1fe3",
		"name": "Joe",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-04-04T16:46:40.000Z",
		"point": [
			-74.00069877517724,
			40.73328093633948
		],
		"times": 1
	},
	{
		"id": "4ad4c061f964a52098f720e3",
		"name": "California Sandwiches",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-06-02T00:39:56.000Z",
		"point": [
			-79.412454,
			43.652606
		],
		"times": 1
	},
	{
		"id": "49eb24a3f964a520a7661fe3",
		"name": "Bagel Bob's",
		"categories": [
			"Bagel Shop"
		],
		"date": "2015-04-04T16:33:14.000Z",
		"point": [
			-73.99420255115069,
			40.732492170536126
		],
		"times": 1
	},
	{
		"id": "4b3cd297f964a520cb8725e3",
		"name": "US Customs and Immigration",
		"categories": [
			"Government Building"
		],
		"date": "2015-04-03T16:34:53.000Z",
		"point": [
			-79.036143,
			43.15187
		],
		"times": 1
	},
	{
		"id": "4adc08c7f964a520672b21e3",
		"name": "Apache Burger",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-03-30T02:21:52.000Z",
		"point": [
			-79.53782379627228,
			43.63929360470661
		],
		"times": 1
	},
	{
		"id": "4b1d3e39f964a520850d24e3",
		"name": "Sky Blue Sky Sandwich Company",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-06-05T00:05:31.000Z",
		"point": [
			-79.413055,
			43.664778
		],
		"times": 1
	},
	{
		"id": "4ad4c05cf964a520d0f520e3",
		"name": "Lee's Palace & The Dance Cave",
		"categories": [
			"Music Venue"
		],
		"date": "2015-06-05T00:29:35.000Z",
		"point": [
			-79.40951094031334,
			43.665371626071064
		],
		"times": 1
	},
	{
		"id": "4ad90125f964a520071721e3",
		"name": "Duke of York",
		"categories": [
			"Pub"
		],
		"date": "2015-06-06T01:03:51.000Z",
		"point": [
			-79.397371,
			43.669035
		],
		"times": 1
	},
	{
		"id": "4c6da40ea437224b65aa2db1",
		"name": "Earth to Table Bread Bar",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-06-06T12:30:32.000Z",
		"point": [
			-79.88688804415465,
			43.252807745700466
		],
		"times": 1
	},
	{
		"id": "4b05b7f8f964a5208ee122e3",
		"name": "Whole Foods Market",
		"categories": [
			"Grocery Store"
		],
		"date": "2015-06-06T15:30:35.000Z",
		"point": [
			-79.67902064323425,
			43.45651317426745
		],
		"times": 1
	},
	{
		"id": "4c07a2532e80a593fedb75f9",
		"name": "Little Nicky's",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-06-06T20:16:55.000Z",
		"point": [
			-79.39343301829051,
			43.64891025766937
		],
		"times": 1
	},
	{
		"id": "4b465300f964a5209d1d26e3",
		"name": "Bowlerama",
		"categories": [
			"Bowling Alley"
		],
		"date": "2015-03-29T23:56:55.000Z",
		"point": [
			-79.54190056272007,
			43.63386370452335
		],
		"times": 1
	},
	{
		"id": "4ad4c061f964a52096f720e3",
		"name": "Utopia Cafe & Grill",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-03-29T00:30:31.000Z",
		"point": [
			-79.413526,
			43.655521
		],
		"times": 1
	},
	{
		"id": "4adf75b3f964a520c87a21e3",
		"name": "Tom's Dairy Freeze",
		"categories": [
			"Ice Cream Shop"
		],
		"date": "2015-06-12T01:28:50.000Z",
		"point": [
			-79.49644,
			43.627847
		],
		"times": 1
	},
	{
		"id": "50f89aeae4b0267991349c6c",
		"name": "Dumpling King",
		"categories": [
			"Dumpling Restaurant"
		],
		"date": "2015-03-22T22:38:41.000Z",
		"point": [
			-79.39572554720752,
			43.65333521014211
		],
		"times": 1
	},
	{
		"id": "4adb5d13f964a520722621e3",
		"name": "Big Smoke Burger",
		"categories": [
			"Burger Joint"
		],
		"date": "2015-03-22T17:24:50.000Z",
		"point": [
			-79.3996392,
			43.6445067
		],
		"times": 1
	},
	{
		"id": "4ad4c062f964a520b9f720e3",
		"name": "The Bloor Hot Docs Cinema",
		"categories": [
			"Indie Movie Theater"
		],
		"date": "2015-06-15T00:30:58.000Z",
		"point": [
			-79.41042423248291,
			43.66543371463452
		],
		"times": 1
	},
	{
		"id": "4ad4c05ff964a5203df720e3",
		"name": "New Generation Sushi",
		"categories": [
			"Sushi Restaurant"
		],
		"date": "2015-06-15T03:18:29.000Z",
		"point": [
			-79.4078733,
			43.6656923
		],
		"times": 1
	},
	{
		"id": "5395e558498efde5f32715c0",
		"name": "Nam Sandwich Shop",
		"categories": [
			"Vietnamese Restaurant"
		],
		"date": "2015-06-15T17:46:47.000Z",
		"point": [
			-79.414424,
			43.645188
		],
		"times": 1
	},
	{
		"id": "4cceb54654f0b1f78ca623ca",
		"name": "Sense Appeal",
		"categories": [
			"Café"
		],
		"date": "2015-03-22T17:24:32.000Z",
		"point": [
			-79.3952637473932,
			43.646557634224116
		],
		"times": 1
	},
	{
		"id": "4f2c221be4b0e0a3476e8861",
		"name": "Come and Get It",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-03-21T03:38:29.000Z",
		"point": [
			-79.4071626663208,
			43.646541277142155
		],
		"times": 1
	},
	{
		"id": "4d77dcbe2b4b6ea8f13148fe",
		"name": "Cardinal Rule",
		"categories": [
			"Bar"
		],
		"date": "2015-06-17T17:15:35.000Z",
		"point": [
			-79.44604396820068,
			43.639072317610164
		],
		"times": 1
	},
	{
		"id": "50709b5ce4b0cc964174a57c",
		"name": "West-End Food Co-op",
		"categories": [
			"Grocery Store"
		],
		"date": "2015-03-18T19:52:49.000Z",
		"point": [
			-79.42929330763222,
			43.642107174423884
		],
		"times": 1
	},
	{
		"id": "4ad4c05cf964a52008f620e3",
		"name": "Wanda's Pie in the Sky",
		"categories": [
			"Bakery"
		],
		"date": "2015-03-14T19:49:10.000Z",
		"point": [
			-79.4025707244873,
			43.656221592189716
		],
		"times": 1
	},
	{
		"id": "52d1bc8b11d2b5ffd4dd5aec",
		"name": "Snakes & Lagers",
		"categories": [
			"Bar"
		],
		"date": "2015-06-20T21:40:56.000Z",
		"point": [
			-79.40957641601562,
			43.65611267089844
		],
		"times": 1
	},
	{
		"id": "4bc4dcb874a9a5930177d6f6",
		"name": "Pancho's Bakery",
		"categories": [
			"Bakery"
		],
		"date": "2015-06-21T00:34:22.000Z",
		"point": [
			-79.402073,
			43.654751
		],
		"times": 1
	},
	{
		"id": "4c4a4fa7f7cc1b8dc4c8323e",
		"name": "Fountain Enterprises",
		"categories": [
			"Bar"
		],
		"date": "2015-03-13T02:13:18.000Z",
		"point": [
			-79.42436911495847,
			43.64939906370907
		],
		"times": 1
	},
	{
		"id": "4b929d35f964a520ee0934e3",
		"name": "Lit Espresso Bar",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-01-03T17:53:08.000Z",
		"point": [
			-79.42193440018319,
			43.654582575580584
		],
		"times": 1
	},
	{
		"id": "4ae2620df964a520da8d21e3",
		"name": "The Embassy Bar",
		"categories": [
			"Bar"
		],
		"date": "2015-06-24T01:55:22.000Z",
		"point": [
			-79.40180569626523,
			43.654318585404184
		],
		"times": 1
	},
	{
		"id": "4b51ca67f964a520af5527e3",
		"name": "Skyline Restaurant",
		"categories": [
			"Diner"
		],
		"date": "2015-06-24T17:42:19.000Z",
		"point": [
			-79.434955,
			43.641002
		],
		"times": 1
	},
	{
		"id": "4fb670f7e4b094ed55ce282a",
		"name": "Cossette",
		"categories": [
			"Coworking Space"
		],
		"date": "2015-06-26T12:54:17.000Z",
		"point": [
			-79.42047244123025,
			43.63729426261173
		],
		"times": 1
	},
	{
		"id": "4f84ec76e4b07583083f7bf8",
		"name": "Hawker Bar",
		"categories": [
			"Bar"
		],
		"date": "2015-06-27T23:22:35.000Z",
		"point": [
			-79.42020862661967,
			43.64797902284739
		],
		"times": 1
	},
	{
		"id": "52ec057a11d23d4bcd3e74e7",
		"name": "Left Field Brewery",
		"categories": [
			"Brewery"
		],
		"date": "2015-07-01T20:23:33.000Z",
		"point": [
			-79.33023691177368,
			43.67375397127686
		],
		"times": 1
	},
	{
		"id": "5591932e498e512b34c531d5",
		"name": "The Pie Commission",
		"categories": [
			"Comfort Food Restaurant"
		],
		"date": "2015-07-02T18:23:46.000Z",
		"point": [
			-79.41158477110073,
			43.65112188925648
		],
		"times": 1
	},
	{
		"id": "4aea348cf964a5202eba21e3",
		"name": "Saigon Flower",
		"categories": [
			"Chinese Restaurant"
		],
		"date": "2015-07-02T22:37:46.000Z",
		"point": [
			-79.424138,
			43.643054
		],
		"times": 1
	},
	{
		"id": "4bead282415e20a1407ae5bb",
		"name": "Shell",
		"categories": [
			"Gas Station / Garage"
		],
		"date": "2015-07-05T19:15:06.000Z",
		"point": [
			-79.64605629444122,
			43.55510305919418
		],
		"times": 1
	},
	{
		"id": "53617768498ec60189452136",
		"name": "Smoque N' Bones",
		"categories": [
			"BBQ Joint"
		],
		"date": "2015-07-05T23:31:20.000Z",
		"point": [
			-79.410737,
			43.645787
		],
		"times": 1
	},
	{
		"id": "55549f8c498e2fc8733c6d14",
		"name": "The Craft - Brasserie & Grille",
		"categories": [
			"Pub"
		],
		"date": "2015-07-06T17:01:54.000Z",
		"point": [
			-79.4207142706269,
			43.63950706335631
		],
		"times": 1
	},
	{
		"id": "4b660250f964a520190e2be3",
		"name": "Booster Juice",
		"categories": [
			"Smoothie Shop"
		],
		"date": "2015-07-06T18:31:03.000Z",
		"point": [
			-79.42136,
			43.63984
		],
		"times": 1
	},
	{
		"id": "4c2395027e85c9284dcdbd21",
		"name": "Liberty St. Cyclery",
		"categories": [
			"Bike Shop"
		],
		"date": "2015-07-07T16:24:47.000Z",
		"point": [
			-79.41853523254395,
			43.63801633989122
		],
		"times": 1
	},
	{
		"id": "50a6c091067dc58023a42c28",
		"name": "Smoke's Poutinerie",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-07-07T16:31:44.000Z",
		"point": [
			-79.4212818145752,
			43.63961582831943
		],
		"times": 1
	},
	{
		"id": "4ade77f6f964a5200c7621e3",
		"name": "Terroni",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-07-08T01:41:16.000Z",
		"point": [
			-79.40913322847507,
			43.64620113005424
		],
		"times": 1
	},
	{
		"id": "5500b3b2498e4f463c0cdef1",
		"name": "Anti Vice Juicery",
		"categories": [
			"Juice Bar"
		],
		"date": "2015-03-11T21:31:18.000Z",
		"point": [
			-79.423468,
			43.654239
		],
		"times": 1
	},
	{
		"id": "4adfd8c0f964a520bf7d21e3",
		"name": "The Communist's Daughter",
		"categories": [
			"Bar"
		],
		"date": "2015-07-08T04:00:48.000Z",
		"point": [
			-79.42099868,
			43.64937934
		],
		"times": 1
	},
	{
		"id": "4f84799ae4b077f839341fe8",
		"name": "3030 Dundas",
		"categories": [
			"Bar"
		],
		"date": "2015-03-07T01:38:33.000Z",
		"point": [
			-79.47040772355565,
			43.665730613694585
		],
		"times": 1
	},
	{
		"id": "4ae86cb3f964a5203eaf21e3",
		"name": "Pho Rua Vang (Golden Turtle)",
		"categories": [
			"Vietnamese Restaurant"
		],
		"date": "2015-07-08T17:51:31.000Z",
		"point": [
			-79.41972496454015,
			43.64694262586673
		],
		"times": 1
	},
	{
		"id": "5374b6f4498e371df1066313",
		"name": "Louie Craft Coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-02-27T19:49:12.000Z",
		"point": [
			-79.42555622118957,
			43.63926020547419
		],
		"times": 1
	},
	{
		"id": "4adbec96f964a5201a2b21e3",
		"name": "Burrito Bandidos",
		"categories": [
			"Burrito Place"
		],
		"date": "2015-07-10T16:45:03.000Z",
		"point": [
			-79.39318,
			43.647988
		],
		"times": 1
	},
	{
		"id": "4f314f2f121d33e09978533b",
		"name": "Lazy Tulip Cafe",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-07-11T13:08:59.000Z",
		"point": [
			-79.69083309173584,
			44.388424264529085
		],
		"times": 1
	},
	{
		"id": "4c21ea0f13c00f47f19486de",
		"name": "Esso",
		"categories": [],
		"date": "2015-07-11T16:02:22.000Z",
		"point": [
			-79.589967,
			44.215028
		],
		"times": 1
	},
	{
		"id": "4ad7993af964a520980c21e3",
		"name": "Island Foods",
		"categories": [
			"Caribbean Restaurant"
		],
		"date": "2015-02-27T19:11:23.000Z",
		"point": [
			-79.42681789398193,
			43.63918878460463
		],
		"times": 1
	},
	{
		"id": "4aeb719af964a52020c221e3",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-07-12T20:28:38.000Z",
		"point": [
			-79.372218,
			43.643178
		],
		"times": 1
	},
	{
		"id": "4bfab61c5efe2d7f0d006e34",
		"name": "Cherry Beach",
		"categories": [
			"Beach"
		],
		"date": "2015-07-12T21:23:16.000Z",
		"point": [
			-79.34426468323797,
			43.63763125837777
		],
		"times": 1
	},
	{
		"id": "4ad4c05df964a5206ef620e3",
		"name": "Cadillac Lounge",
		"categories": [
			"Lounge"
		],
		"date": "2015-02-27T00:39:19.000Z",
		"point": [
			-79.4307017326355,
			43.64200721703174
		],
		"times": 1
	},
	{
		"id": "51105e69e4b08c5068e7e4c9",
		"name": "Hot Shawarma",
		"categories": [
			"Middle Eastern Restaurant"
		],
		"date": "2015-07-13T22:33:09.000Z",
		"point": [
			-79.42658943083455,
			43.642513368572374
		],
		"times": 1
	},
	{
		"id": "4adb4489f964a520642521e3",
		"name": "Three Speed",
		"categories": [
			"Bar"
		],
		"date": "2015-02-21T01:41:43.000Z",
		"point": [
			-79.43812072277069,
			43.6592138678362
		],
		"times": 1
	},
	{
		"id": "50abfac3e4b05922967ebe89",
		"name": "Rose & Sons",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-02-15T18:17:28.000Z",
		"point": [
			-79.40354280823314,
			43.67568555898306
		],
		"times": 1
	},
	{
		"id": "4ad4c05ef964a520bdf620e3",
		"name": "Trinity Bellwoods Park",
		"categories": [
			"Park"
		],
		"date": "2015-07-18T01:05:24.000Z",
		"point": [
			-79.41424369812012,
			43.64796974573396
		],
		"times": 1
	},
	{
		"id": "4ba2287ff964a5200ae037e3",
		"name": "Booster Juice",
		"categories": [
			"Smoothie Shop"
		],
		"date": "2015-07-18T21:48:00.000Z",
		"point": [
			-79.3849413,
			43.66501882
		],
		"times": 1
	},
	{
		"id": "4ca0c31346978cfabf66b67f",
		"name": "The Saint Tavern",
		"categories": [
			"Gastropub"
		],
		"date": "2015-07-19T00:59:58.000Z",
		"point": [
			-79.42063182885839,
			43.64902220549251
		],
		"times": 1
	},
	{
		"id": "4b7d1f1af964a52049af2fe3",
		"name": "Denny's",
		"categories": [
			"Diner"
		],
		"date": "2015-07-19T13:22:12.000Z",
		"point": [
			-79.686588,
			43.455242
		],
		"times": 1
	},
	{
		"id": "4ade455bf964a520837421e3",
		"name": "Harbord House",
		"categories": [
			"Bar"
		],
		"date": "2015-07-19T20:19:44.000Z",
		"point": [
			-79.405456,
			43.662483
		],
		"times": 1
	},
	{
		"id": "4b0d3f6af964a520214523e3",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-07-20T22:23:10.000Z",
		"point": [
			-79.39472794532776,
			43.64566011245642
		],
		"times": 1
	},
	{
		"id": "546a8ec9498e76930e04df70",
		"name": "Mr.flamingo",
		"categories": [
			"Bar"
		],
		"date": "2015-02-15T00:31:36.000Z",
		"point": [
			-79.424724,
			43.649283
		],
		"times": 1
	},
	{
		"id": "52901bed11d2a0ab7f76af8d",
		"name": "Pizzaville",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-07-22T16:57:49.000Z",
		"point": [
			-79.421652,
			43.654698
		],
		"times": 1
	},
	{
		"id": "4ad87931f964a520a01121e3",
		"name": "Sadie's Diner",
		"categories": [
			"Vegetarian / Vegan Restaurant"
		],
		"date": "2015-02-14T15:42:31.000Z",
		"point": [
			-79.400626,
			43.645635
		],
		"times": 1
	},
	{
		"id": "4b0ff2a5f964a520a16623e3",
		"name": "Murphy's Law",
		"categories": [
			"Pub"
		],
		"date": "2015-07-23T01:51:31.000Z",
		"point": [
			-79.3127625954956,
			43.667395156617026
		],
		"times": 1
	},
	{
		"id": "4b454fdcf964a520640a26e3",
		"name": "Tacos El Asador",
		"categories": [
			"Taco Place"
		],
		"date": "2015-07-23T22:35:44.000Z",
		"point": [
			-79.417195,
			43.66393
		],
		"times": 1
	},
	{
		"id": "4b745a9df964a52043d72de3",
		"name": "918 Bathurst: Culture, Arts, Media & Education Centre",
		"categories": [
			"Event Space"
		],
		"date": "2015-07-23T23:19:43.000Z",
		"point": [
			-79.41285998726114,
			43.66860851570549
		],
		"times": 1
	},
	{
		"id": "4ba00de2f964a520205637e3",
		"name": "La Mexicanada Restaurant",
		"categories": [
			"Mexican Restaurant"
		],
		"date": "2015-07-24T16:58:06.000Z",
		"point": [
			-79.563714,
			44.11439895629883
		],
		"times": 1
	},
	{
		"id": "4bf1cab2324cc9b62076cc92",
		"name": "Centennial Park",
		"categories": [
			"Beach"
		],
		"date": "2015-07-24T20:13:31.000Z",
		"point": [
			-79.68937397003174,
			44.37859437514968
		],
		"times": 1
	},
	{
		"id": "4d3214a02c76a14334da6cc7",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-07-24T21:00:28.000Z",
		"point": [
			-79.69257116317749,
			44.389098954588434
		],
		"times": 1
	},
	{
		"id": "55b26645498ec1a2f32ef4b2",
		"name": "Wayhome Festival",
		"categories": [
			"Music Festival"
		],
		"date": "2015-07-24T22:37:13.000Z",
		"point": [
			-79.51766954937148,
			44.480448676296966
		],
		"times": 1
	},
	{
		"id": "51801c3ce4b029dcc8e63ef1",
		"name": "North Standard Trading Post",
		"categories": [
			"Clothing Store"
		],
		"date": "2015-02-12T20:33:12.000Z",
		"point": [
			-79.44342142145791,
			43.63931572993182
		],
		"times": 1
	},
	{
		"id": "4afce47df964a520702622e3",
		"name": "Tim Hortons",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-07-27T02:59:50.000Z",
		"point": [
			-79.406099,
			43.652314
		],
		"times": 1
	},
	{
		"id": "4bacf99ff964a52019203be3",
		"name": "The Tampered Press",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-07-28T18:37:31.000Z",
		"point": [
			-79.4174110460886,
			43.65003333893865
		],
		"times": 1
	},
	{
		"id": "520b7520498e5dc2d41b44f4",
		"name": "Super Jet International Coffee Shop",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-01-24T20:04:11.000Z",
		"point": [
			-79.40938608117403,
			43.674461908257676
		],
		"times": 1
	},
	{
		"id": "5308d70d498e088bde5a677d",
		"name": "Starving Artist",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-01-24T18:32:07.000Z",
		"point": [
			-79.43960248356804,
			43.678807107467925
		],
		"times": 1
	},
	{
		"id": "53877b04498e0c2dc2cc0ea3",
		"name": "Portland Variety",
		"categories": [
			"Restaurant"
		],
		"date": "2015-08-02T13:43:45.000Z",
		"point": [
			-79.40001907552049,
			43.644241839334185
		],
		"times": 1
	},
	{
		"id": "50b2c0e4e4b03b44987d3f11",
		"name": "Skin + Bones",
		"categories": [
			"Restaurant"
		],
		"date": "2015-08-02T23:15:45.000Z",
		"point": [
			-79.33973203227202,
			43.66128960941768
		],
		"times": 1
	},
	{
		"id": "510f2670e4b0898fbd822b0b",
		"name": "McQueens Pub",
		"categories": [
			"Gastropub"
		],
		"date": "2015-08-03T00:48:16.000Z",
		"point": [
			-79.338095,
			43.661469
		],
		"times": 1
	},
	{
		"id": "51089878e4b001b566e6be4d",
		"name": "Hitch Bar",
		"categories": [
			"Bar"
		],
		"date": "2015-08-03T01:40:32.000Z",
		"point": [
			-79.330613,
			43.663228
		],
		"times": 1
	},
	{
		"id": "5545725c498e23bf3910262c",
		"name": "The Shark Reef",
		"categories": [
			"Home (private)"
		],
		"date": "2015-08-03T03:52:48.000Z",
		"point": [
			-79.32965654416498,
			43.66795332789153
		],
		"times": 1
	},
	{
		"id": "4df14d9d1f6e818dadf74cc6",
		"name": "Smoke's Poutinerie",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-04-10T05:20:59.000Z",
		"point": [
			-79.4036854,
			43.6472757
		],
		"times": 1
	},
	{
		"id": "4f560281e4b05b4bc3d4a8e0",
		"name": "The Bagel Stop",
		"categories": [
			"Bagel Shop"
		],
		"date": "2015-08-04T13:30:56.000Z",
		"point": [
			-79.40492257958864,
			43.65362171329569
		],
		"times": 1
	},
	{
		"id": "4c8b9c492e333704ab6fce41",
		"name": "Booster Juice",
		"categories": [
			"Smoothie Shop"
		],
		"date": "2015-08-04T14:27:32.000Z",
		"point": [
			-79.40613631,
			43.65365721
		],
		"times": 1
	},
	{
		"id": "4d825516bede5481e72710d1",
		"name": "Ali Baba's",
		"categories": [
			"Falafel Restaurant"
		],
		"date": "2015-08-06T23:52:41.000Z",
		"point": [
			-79.358123,
			43.676396
		],
		"times": 1
	},
	{
		"id": "4c59f8e204f9be9ae2b4f060",
		"name": "Drift Bar",
		"categories": [
			"Bar"
		],
		"date": "2015-01-24T00:23:33.000Z",
		"point": [
			-79.432498,
			43.660464
		],
		"times": 1
	},
	{
		"id": "4ae29a5cf964a5202f8f21e3",
		"name": "Dora Keogh",
		"categories": [
			"Pub"
		],
		"date": "2015-08-07T03:47:19.000Z",
		"point": [
			-79.35736082186166,
			43.676437639779266
		],
		"times": 1
	},
	{
		"id": "4e5e9c3ab993f2a09a978ff5",
		"name": "Goods And Provisions",
		"categories": [
			"Diner"
		],
		"date": "2015-08-08T00:44:56.000Z",
		"point": [
			-79.335187,
			43.662205
		],
		"times": 1
	},
	{
		"id": "505125b2e4b0253ca4e47351",
		"name": "Momofuku Noodle Bar",
		"categories": [
			"Noodle House"
		],
		"date": "2015-01-18T01:46:12.000Z",
		"point": [
			-79.38600527411525,
			43.649501113078166
		],
		"times": 1
	},
	{
		"id": "4b73520bf964a5206fa82de3",
		"name": "McDonald's",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-08-09T02:54:38.000Z",
		"point": [
			-79.35852885246277,
			43.67638261052803
		],
		"times": 1
	},
	{
		"id": "4edeae9d61af80fe89aa3f54",
		"name": "Banh Mi Boys",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-08-11T23:49:10.000Z",
		"point": [
			-79.39702846996235,
			43.64859090148248
		],
		"times": 1
	},
	{
		"id": "4bcf714ab221c9b67f0ad2d0",
		"name": "TIFF Bell Lightbox",
		"categories": [
			"Movie Theater"
		],
		"date": "2015-01-17T21:53:34.000Z",
		"point": [
			-79.39016819000244,
			43.646665493150884
		],
		"times": 1
	},
	{
		"id": "4ad4c05ef964a5208ff620e3",
		"name": "Toronto City Hall",
		"categories": [
			"City Hall"
		],
		"date": "2015-08-14T15:41:46.000Z",
		"point": [
			-79.38396692276001,
			43.65313989695342
		],
		"times": 1
	},
	{
		"id": "55576ce5498ebd33df54600c",
		"name": "Barque Butcher Bar",
		"categories": [
			"Butcher"
		],
		"date": "2015-08-15T23:34:59.000Z",
		"point": [
			-79.4492786497893,
			43.64765452734842
		],
		"times": 1
	},
	{
		"id": "5337166e498e7ec9227d01a4",
		"name": "Ed's Real Scoop",
		"categories": [
			"Ice Cream Shop"
		],
		"date": "2015-08-15T23:47:50.000Z",
		"point": [
			-79.44841052880085,
			43.64502497607555
		],
		"times": 1
	},
	{
		"id": "4bbdeb7df57ba593bd9daeb9",
		"name": "Coffee Time",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-01-12T07:24:28.000Z",
		"point": [
			-79.4463335984934,
			43.66678743457951
		],
		"times": 1
	},
	{
		"id": "4ae9f27af964a520e5b721e3",
		"name": "Swan & Firkin",
		"categories": [
			"Pub"
		],
		"date": "2015-08-23T05:13:13.000Z",
		"point": [
			-79.475815,
			43.651102
		],
		"times": 1
	},
	{
		"id": "55820db4498e4abb523622bf",
		"name": "Otto's Berlin Döner",
		"categories": [
			"German Restaurant"
		],
		"date": "2015-08-23T19:46:51.000Z",
		"point": [
			-79.40278420006204,
			43.65641034009208
		],
		"times": 1
	},
	{
		"id": "4ad4c063f964a5205cf820e3",
		"name": "Get Outside",
		"categories": [
			"Shoe Store"
		],
		"date": "2015-08-23T20:23:41.000Z",
		"point": [
			-79.39558810702768,
			43.648898187360814
		],
		"times": 1
	},
	{
		"id": "4af302d7f964a520c4e921e3",
		"name": "i deal coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-08-27T14:55:26.000Z",
		"point": [
			-79.42043187,
			43.647801
		],
		"times": 1
	},
	{
		"id": "4adb9db5f964a520682921e3",
		"name": "Big Fat Burrito",
		"categories": [
			"Burrito Place"
		],
		"date": "2015-08-28T01:08:14.000Z",
		"point": [
			-79.40243929624557,
			43.65600812973345
		],
		"times": 1
	},
	{
		"id": "4dfcd1bf1f6e05048d872c36",
		"name": "Harvey's",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-08-28T17:58:16.000Z",
		"point": [
			-79.41894292831421,
			43.63989631674427
		],
		"times": 1
	},
	{
		"id": "4aedc903f964a520e0ce21e3",
		"name": "LCBO",
		"categories": [
			"Liquor Store"
		],
		"date": "2015-08-28T18:23:51.000Z",
		"point": [
			-79.41971272230148,
			43.63981187919012
		],
		"times": 1
	},
	{
		"id": "4ad7f7cbf964a520591021e3",
		"name": "Tim Hortons / Wendy's",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-08-29T20:18:35.000Z",
		"point": [
			-79.32933568954468,
			43.659722268203325
		],
		"times": 1
	},
	{
		"id": "540e5eb8498e18768119f05d",
		"name": "Pantry",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-01-11T19:17:07.000Z",
		"point": [
			-115.17511613059183,
			36.121334741051726
		],
		"times": 1
	},
	{
		"id": "4a9a20f6f964a520953120e3",
		"name": "Frankie's Tiki Room",
		"categories": [
			"Cocktail Bar"
		],
		"date": "2015-01-11T02:11:54.000Z",
		"point": [
			-115.16467684987879,
			36.15909863897869
		],
		"times": 1
	},
	{
		"id": "4ad8cc7bf964a520c21421e3",
		"name": "Venezia Bakery",
		"categories": [
			"Bakery"
		],
		"date": "2015-09-03T14:52:51.000Z",
		"point": [
			-79.419845,
			43.646857
		],
		"times": 1
	},
	{
		"id": "4bf05d7024f19c745638f983",
		"name": "Du-par's Restaurant & Bakery",
		"categories": [
			"Diner"
		],
		"date": "2015-01-10T23:40:35.000Z",
		"point": [
			-115.1462996006012,
			36.17143423834437
		],
		"times": 1
	},
	{
		"id": "4ad4c05cf964a52007f620e3",
		"name": "Horseshoe Tavern",
		"categories": [
			"Music Venue"
		],
		"date": "2015-09-06T01:39:47.000Z",
		"point": [
			-79.39589738845825,
			43.64901002213456
		],
		"times": 1
	},
	{
		"id": "4b116db0f964a520617c23e3",
		"name": "Club Monaco",
		"categories": [
			"Clothing Store"
		],
		"date": "2015-09-06T20:34:43.000Z",
		"point": [
			-79.38076535944515,
			43.653961904841594
		],
		"times": 1
	},
	{
		"id": "4b116f66f964a520817c23e3",
		"name": "Town Shoes",
		"categories": [
			"Shoe Store"
		],
		"date": "2015-09-06T21:03:53.000Z",
		"point": [
			-79.380194,
			43.652685
		],
		"times": 1
	},
	{
		"id": "4bb6320246d4a5936fd7c5c0",
		"name": "Hanlan's Point Beach",
		"categories": [
			"Beach"
		],
		"date": "2015-09-07T19:13:25.000Z",
		"point": [
			-79.39247488975525,
			43.618104581039596
		],
		"times": 1
	},
	{
		"id": "4f32b7d7e4b0d8fc0b94b429",
		"name": "KOJA",
		"categories": [
			"Japanese Restaurant"
		],
		"date": "2015-09-08T00:10:15.000Z",
		"point": [
			-79.42107151743882,
			43.639745915152986
		],
		"times": 1
	},
	{
		"id": "55ef0e9b498e6fb41ea85ebd",
		"name": "Ali Baba's",
		"categories": [
			"Middle Eastern Restaurant"
		],
		"date": "2015-09-08T16:37:24.000Z",
		"point": [
			-79.435061,
			43.640949
		],
		"times": 1
	},
	{
		"id": "537e32ba498e08214a8c25e9",
		"name": "Fancy Franks",
		"categories": [
			"Hot Dog Joint"
		],
		"date": "2015-09-08T23:26:53.000Z",
		"point": [
			-79.427745,
			43.66143
		],
		"times": 1
	},
	{
		"id": "473ed65af964a520694c1fe3",
		"name": "Planet Hollywood Resort & Casino",
		"categories": [
			"Casino"
		],
		"date": "2015-01-10T08:01:31.000Z",
		"point": [
			-115.17182350158691,
			36.11024707860085
		],
		"times": 1
	},
	{
		"id": "4bc7da668b7c9c74225837cf",
		"name": "Minneapolis-St. Paul International Airport (MSP)",
		"categories": [
			"Airport"
		],
		"date": "2015-09-10T15:48:47.000Z",
		"point": [
			-93.21251392364502,
			44.8806877852748
		],
		"times": 1
	},
	{
		"id": "45f412e6f964a520f6431fe3",
		"name": "Portland International Airport (PDX)",
		"categories": [
			"Airport"
		],
		"date": "2015-09-10T19:49:09.000Z",
		"point": [
			-122.59379625320435,
			45.58916884526295
		],
		"times": 1
	},
	{
		"id": "4ace447bf964a5207dcf20e3",
		"name": "Rogue Ales Public House",
		"categories": [
			"Pub"
		],
		"date": "2015-09-10T20:15:25.000Z",
		"point": [
			-122.59453654289246,
			45.59089566380916
		],
		"times": 1
	},
	{
		"id": "4a42da6bf964a5204fa61fe3",
		"name": "Bipartisan Cafe",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-09-10T23:31:26.000Z",
		"point": [
			-122.58231946,
			45.519242
		],
		"times": 1
	},
	{
		"id": "41326e00f964a520c9141fe3",
		"name": "Paris Hotel & Casino",
		"categories": [
			"Casino"
		],
		"date": "2015-01-10T08:01:05.000Z",
		"point": [
			-115.17139434814453,
			36.112465968297066
		],
		"times": 1
	},
	{
		"id": "50ec780be4b0e0d0a432ad3c",
		"name": "Redwood",
		"categories": [
			"Diner"
		],
		"date": "2015-09-11T18:09:22.000Z",
		"point": [
			-122.58208403081782,
			45.51922531308135
		],
		"times": 1
	},
	{
		"id": "4b11626ef964a5205e7b23e3",
		"name": "Floyd's Coffee Shop",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-09-11T19:27:54.000Z",
		"point": [
			-122.651279,
			45.517137
		],
		"times": 1
	},
	{
		"id": "49c2e861f964a5203f561fe3",
		"name": "Stumptown Coffee Roasters",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-09-11T20:16:50.000Z",
		"point": [
			-122.68169791638714,
			45.522254675053595
		],
		"times": 1
	},
	{
		"id": "4a06431ff964a520dc721fe3",
		"name": "Powell's City of Books",
		"categories": [
			"Bookstore"
		],
		"date": "2015-09-11T20:40:33.000Z",
		"point": [
			-122.68161177635193,
			45.523311177161325
		],
		"times": 1
	},
	{
		"id": "4b6b21aff964a520e5f42be3",
		"name": "Panic, Inc.",
		"categories": [
			"Tech Startup"
		],
		"date": "2015-09-11T20:58:12.000Z",
		"point": [
			-122.682241,
			45.522943
		],
		"times": 1
	},
	{
		"id": "4e332a7bc65bdb4c79fa53cf",
		"name": "Instrument Outpost",
		"categories": [
			"Office"
		],
		"date": "2015-09-11T23:07:58.000Z",
		"point": [
			-122.6559,
			45.526029
		],
		"times": 1
	},
	{
		"id": "4a496e49f964a52053ab1fe3",
		"name": "Biwa",
		"categories": [
			"Japanese Restaurant"
		],
		"date": "2015-09-12T00:41:25.000Z",
		"point": [
			-122.656767,
			45.521453
		],
		"times": 1
	},
	{
		"id": "4dcd85efe4cd130e16500d58",
		"name": "Yale Union",
		"categories": [
			"Art Gallery"
		],
		"date": "2015-09-12T02:27:42.000Z",
		"point": [
			-122.65534825840504,
			45.516979450877336
		],
		"times": 1
	},
	{
		"id": "4b79e69ff964a5204d192fe3",
		"name": "Plaid Pantry",
		"categories": [
			"Convenience Store"
		],
		"date": "2015-09-12T07:43:57.000Z",
		"point": [
			-122.66050451666666,
			45.52277863025665
		],
		"times": 1
	},
	{
		"id": "4a8625f7f964a520950020e3",
		"name": "Country Cat Dinnerhouse & Bar",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-09-12T18:18:49.000Z",
		"point": [
			-122.581689,
			45.519203
		],
		"times": 1
	},
	{
		"id": "4a60f584f964a5208dc11fe3",
		"name": "Cool Moon Ice Cream",
		"categories": [
			"Ice Cream Shop"
		],
		"date": "2015-09-12T21:03:36.000Z",
		"point": [
			-122.68234868473273,
			45.528692307889564
		],
		"times": 1
	},
	{
		"id": "54fdfe85498e07def4d3d64e",
		"name": "Smokehouse Tavern",
		"categories": [
			"BBQ Joint"
		],
		"date": "2015-09-13T01:26:02.000Z",
		"point": [
			-122.650977,
			45.5177
		],
		"times": 1
	},
	{
		"id": "4c9d5d1954c8a1cd455a834b",
		"name": "Roost",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-09-13T18:18:25.000Z",
		"point": [
			-122.65149888862243,
			45.51658291028562
		],
		"times": 1
	},
	{
		"id": "54aecca3498e150edc5e61fb",
		"name": "Good Coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-09-13T19:34:30.000Z",
		"point": [
			-122.65362829752648,
			45.51448464391676
		],
		"times": 1
	},
	{
		"id": "446740a2f964a52045331fe3",
		"name": "Crown Point Vista House",
		"categories": [
			"Scenic Lookout"
		],
		"date": "2015-09-13T21:07:35.000Z",
		"point": [
			-122.24440772082816,
			45.53943973413516
		],
		"times": 1
	},
	{
		"id": "4b8316eff964a520eef630e3",
		"name": "Latourell Falls",
		"categories": [
			"Trail"
		],
		"date": "2015-09-13T21:49:01.000Z",
		"point": [
			-122.21792221069336,
			45.53872983339533
		],
		"times": 1
	},
	{
		"id": "4bb7a14a1261d13abd9ae798",
		"name": "Horsetail Falls",
		"categories": [
			"Scenic Lookout"
		],
		"date": "2015-09-13T22:50:45.000Z",
		"point": [
			-122.0859146118164,
			45.585452251227146
		],
		"times": 1
	},
	{
		"id": "4a56da31f964a520d2b51fe3",
		"name": "Pok Pok",
		"categories": [
			"Asian Restaurant"
		],
		"date": "2015-09-14T01:38:43.000Z",
		"point": [
			-122.63215988874435,
			45.50462124282247
		],
		"times": 1
	},
	{
		"id": "55f23a16498edb125e29ef0e",
		"name": "Martha's at Revolution Hall",
		"categories": [
			"Bar"
		],
		"date": "2015-09-14T07:06:35.000Z",
		"point": [
			-122.65283249675967,
			45.518960693469815
		],
		"times": 1
	},
	{
		"id": "53bf441f498e7666ec0c103e",
		"name": "There Be Monsters",
		"categories": [
			"Bar"
		],
		"date": "2015-09-14T08:24:10.000Z",
		"point": [
			-122.652423,
			45.517111
		],
		"times": 1
	},
	{
		"id": "51b00ce6498e768c2d795e49",
		"name": "Hungry Heart Cupcakes",
		"categories": [
			"Food Truck"
		],
		"date": "2015-09-14T18:37:18.000Z",
		"point": [
			-122.58109745554296,
			45.51930218907556
		],
		"times": 1
	},
	{
		"id": "5273f78611d2dc366443269e",
		"name": "Bröder Nord",
		"categories": [
			"Scandinavian Restaurant"
		],
		"date": "2015-09-14T21:11:39.000Z",
		"point": [
			-122.67477416992188,
			45.53936004638672
		],
		"times": 1
	},
	{
		"id": "4b37eb3ff964a5209f4825e3",
		"name": "Haystack Rock",
		"categories": [
			"Scenic Lookout"
		],
		"date": "2015-09-15T01:31:51.000Z",
		"point": [
			-123.9683747291565,
			45.88439218105721
		],
		"times": 1
	},
	{
		"id": "4a875ab0f964a5200f0420e3",
		"name": "Pizza a Fetta",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-09-15T02:06:16.000Z",
		"point": [
			-123.9607629273827,
			45.898783642460955
		],
		"times": 1
	},
	{
		"id": "4c38a007dfb0e21efbdeaea8",
		"name": "Pine State Biscuits",
		"categories": [
			"Southern / Soul Food Restaurant"
		],
		"date": "2015-09-15T18:53:28.000Z",
		"point": [
			-122.642713,
			45.559028
		],
		"times": 1
	},
	{
		"id": "4ad23869f964a5202be020e3",
		"name": "Petite Provence of Alberta",
		"categories": [
			"Bakery"
		],
		"date": "2015-09-15T19:49:27.000Z",
		"point": [
			-122.64621198177338,
			45.55894655473891
		],
		"times": 1
	},
	{
		"id": "4b76ebeef964a520bf6a2ee3",
		"name": "Centralia Factory Outlets",
		"categories": [
			"Mall"
		],
		"date": "2015-09-15T21:46:52.000Z",
		"point": [
			-122.97727300487392,
			46.7306883787155
		],
		"times": 1
	},
	{
		"id": "4b43f01df964a5208aee25e3",
		"name": "Safeway",
		"categories": [
			"Supermarket"
		],
		"date": "2015-09-15T23:02:53.000Z",
		"point": [
			-122.9808517410802,
			46.72812070108749
		],
		"times": 1
	},
	{
		"id": "440daad8f964a52096301fe3",
		"name": "Starbucks",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-09-16T01:23:28.000Z",
		"point": [
			-122.342591,
			47.610047
		],
		"times": 1
	},
	{
		"id": "55f8eeb9498e00e1143ebd4b",
		"name": "Sushi Mori",
		"categories": [
			"Sushi Restaurant"
		],
		"date": "2015-09-16T04:23:26.000Z",
		"point": [
			-122.7971616650159,
			49.045542043966066
		],
		"times": 1
	},
	{
		"id": "4aa748f7f964a520994c20e3",
		"name": "Vancouver International Airport (YVR)",
		"categories": [
			"Airport"
		],
		"date": "2015-09-17T05:25:18.000Z",
		"point": [
			-123.17948341369629,
			49.19488613862445
		],
		"times": 1
	},
	{
		"id": "4ecee0cdd5fb788de3cd02c1",
		"name": "The Ace",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-09-18T17:19:13.000Z",
		"point": [
			-79.44885358214378,
			43.64611913488028
		],
		"times": 1
	},
	{
		"id": "4adb5deef964a5207d2621e3",
		"name": "Tommy Thompson Park",
		"categories": [
			"Park"
		],
		"date": "2015-09-20T20:51:38.000Z",
		"point": [
			-79.32367086410522,
			43.6341804999836
		],
		"times": 1
	},
	{
		"id": "49dea6abf964a520aa601fe3",
		"name": "Mesa Grill",
		"categories": [
			"New American Restaurant"
		],
		"date": "2015-01-10T04:15:44.000Z",
		"point": [
			-115.17467737197876,
			36.11748424149475
		],
		"times": 1
	},
	{
		"id": "50b121cbe4b0602cfc4230b6",
		"name": "gravitypope",
		"categories": [
			"Clothing Store"
		],
		"date": "2015-09-22T17:51:07.000Z",
		"point": [
			-79.41909839852934,
			43.644092822809746
		],
		"times": 1
	},
	{
		"id": "4b22f410f964a520005124e3",
		"name": "Jacobs & Co.",
		"categories": [
			"Steakhouse"
		],
		"date": "2015-09-22T23:48:37.000Z",
		"point": [
			-79.39799,
			43.645346
		],
		"times": 1
	},
	{
		"id": "50c16f85e4b0f26f55dd68b4",
		"name": "Smoke Bourbon Bar-B-Q House",
		"categories": [
			"BBQ Joint"
		],
		"date": "2015-09-26T01:55:19.000Z",
		"point": [
			-79.41490469363602,
			43.66046157330481
		],
		"times": 1
	},
	{
		"id": "4e8673ded66a9b178e90e065",
		"name": "Petro-Canada",
		"categories": [
			"Gas Station / Garage"
		],
		"date": "2015-09-26T20:01:48.000Z",
		"point": [
			-79.66729995324633,
			43.53529386126335
		],
		"times": 1
	},
	{
		"id": "4d2f8cd04db137041f68f25d",
		"name": "Popeyes Louisiana Kitchen",
		"categories": [
			"Fried Chicken Joint"
		],
		"date": "2015-09-26T23:21:15.000Z",
		"point": [
			-80.22257045674874,
			43.524746527989244
		],
		"times": 1
	},
	{
		"id": "4b4fdfe1f964a5204e1827e3",
		"name": "Woolwich Arrow",
		"categories": [
			"Pub"
		],
		"date": "2015-09-27T01:29:20.000Z",
		"point": [
			-80.2526398,
			43.5480984
		],
		"times": 1
	},
	{
		"id": "4b32e59af964a520b91525e3",
		"name": "Atmosphere Cafe",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-09-27T03:50:10.000Z",
		"point": [
			-80.24881333044016,
			43.543740277982614
		],
		"times": 1
	},
	{
		"id": "4cf6907dd3a8a1cd2409d243",
		"name": "Milestones",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-09-27T16:59:14.000Z",
		"point": [
			-80.23263931274414,
			43.52201000591619
		],
		"times": 1
	},
	{
		"id": "4b01452bf964a520f24122e3",
		"name": "Aldershot VIA/GO Station",
		"categories": [
			"Train Station"
		],
		"date": "2015-09-27T21:48:36.000Z",
		"point": [
			-79.85525700386552,
			43.313247275579116
		],
		"times": 1
	},
	{
		"id": "4b02ec72f964a5200c4b22e3",
		"name": "Pizza Pizza",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-09-28T00:08:17.000Z",
		"point": [
			-79.39804315566786,
			43.644994
		],
		"times": 1
	},
	{
		"id": "537a1b49498ecc7171c3c32d",
		"name": "FAME",
		"categories": [
			"Asian Restaurant"
		],
		"date": "2015-01-08T03:35:56.000Z",
		"point": [
			-115.1705073722044,
			36.11755577712195
		],
		"times": 1
	},
	{
		"id": "54e0dc87498ecbdb39a0fade",
		"name": "Bar Raval",
		"categories": [
			"Tapas Restaurant"
		],
		"date": "2015-09-30T01:09:06.000Z",
		"point": [
			-79.410084,
			43.655898
		],
		"times": 1
	},
	{
		"id": "4af974f0f964a520e01122e3",
		"name": "Golden Wheat Bakery & Pastry",
		"categories": [
			"Bakery"
		],
		"date": "2015-09-30T01:20:14.000Z",
		"point": [
			-79.416266,
			43.65507
		],
		"times": 1
	},
	{
		"id": "4b08386df964a520910623e3",
		"name": "Arepa Cafe",
		"categories": [
			"Arepa Restaurant"
		],
		"date": "2015-10-05T17:12:08.000Z",
		"point": [
			-79.4009096415636,
			43.647863580488355
		],
		"times": 1
	},
	{
		"id": "4ad788c8f964a520e40b21e3",
		"name": "Apple Store, Eaton Centre",
		"categories": [
			"Electronics Store"
		],
		"date": "2015-10-05T17:49:57.000Z",
		"point": [
			-79.380615,
			43.652823
		],
		"times": 1
	},
	{
		"id": "4b8ef6fcf964a520954133e3",
		"name": "Harvey's",
		"categories": [
			"Fast Food Restaurant"
		],
		"date": "2015-10-06T22:52:45.000Z",
		"point": [
			-79.507425,
			43.625069949935686
		],
		"times": 1
	},
	{
		"id": "4e20e9d9e4cdf685918068b5",
		"name": "The Mugshot Tavern",
		"categories": [
			"Bar"
		],
		"date": "2015-10-07T23:38:40.000Z",
		"point": [
			-79.45947647094727,
			43.654645833275175
		],
		"times": 1
	},
	{
		"id": "4b9a9de7f964a520c2c635e3",
		"name": "Tim Hortons",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-10-10T14:58:44.000Z",
		"point": [
			-79.4398856,
			43.650984
		],
		"times": 1
	},
	{
		"id": "51f7efd4498ed61c0dd98f55",
		"name": "Brock Sandwich",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-10-10T18:07:39.000Z",
		"point": [
			-79.440521,
			43.658879
		],
		"times": 1
	},
	{
		"id": "55f31baf498ebbeb5a5f786e",
		"name": "Sweet Jesus",
		"categories": [
			"Ice Cream Shop"
		],
		"date": "2015-10-11T18:27:51.000Z",
		"point": [
			-79.38994188787017,
			43.64736686993273
		],
		"times": 1
	},
	{
		"id": "4ed00b8fb8f7971d6a344e5f",
		"name": "Loblaws",
		"categories": [
			"Supermarket"
		],
		"date": "2015-10-11T23:38:59.000Z",
		"point": [
			-79.4016187898668,
			43.64755493546125
		],
		"times": 1
	},
	{
		"id": "5480b4ad498e73897b755aac",
		"name": "Mean Bao",
		"categories": [
			"Asian Restaurant"
		],
		"date": "2015-10-13T16:53:44.000Z",
		"point": [
			-79.40368023658314,
			43.6468365929438
		],
		"times": 1
	},
	{
		"id": "4f777d6ce4b0e91fa702b618",
		"name": "STACK",
		"categories": [
			"BBQ Joint"
		],
		"date": "2015-10-16T01:54:12.000Z",
		"point": [
			-79.40314151628102,
			43.72917677564357
		],
		"times": 1
	},
	{
		"id": "55c3e77e498eef55fabb5816",
		"name": "The Uptown Pub House",
		"categories": [
			"Gastropub"
		],
		"date": "2015-10-16T03:14:55.000Z",
		"point": [
			-79.40276221132652,
			43.72768225344563
		],
		"times": 1
	},
	{
		"id": "4ad4c064f964a52067f820e3",
		"name": "Enercare Centre",
		"categories": [
			"Convention Center"
		],
		"date": "2015-10-18T02:35:59.000Z",
		"point": [
			-79.4133436853334,
			43.63491793684608
		],
		"times": 1
	},
	{
		"id": "4af251edf964a5205de721e3",
		"name": "Marben Restaurant",
		"categories": [
			"New American Restaurant"
		],
		"date": "2015-10-18T16:57:44.000Z",
		"point": [
			-79.39834236924852,
			43.64346044746947
		],
		"times": 1
	},
	{
		"id": "4adbbbb0f964a520452a21e3",
		"name": "Cineplex Odeon Varsity & VIP Cinemas",
		"categories": [
			"Multiplex"
		],
		"date": "2015-10-19T01:41:12.000Z",
		"point": [
			-79.38900723216936,
			43.66897294941648
		],
		"times": 1
	},
	{
		"id": "4abfc91df964a5202a9220e3",
		"name": "Metro Toronto Convention Centre",
		"categories": [
			"Convention Center"
		],
		"date": "2015-10-19T14:17:27.000Z",
		"point": [
			-79.38705507144164,
			43.644168172889685
		],
		"times": 1
	},
	{
		"id": "4e73ae6f88778d253b3e67ec",
		"name": "Hey Meatball!",
		"categories": [
			"Italian Restaurant"
		],
		"date": "2015-10-20T19:47:07.000Z",
		"point": [
			-79.41852129449036,
			43.655162094960964
		],
		"times": 1
	},
	{
		"id": "4acd49b7f964a520b8cb20e3",
		"name": "Chipotle Mexican Grill",
		"categories": [
			"Mexican Restaurant"
		],
		"date": "2015-01-07T07:08:11.000Z",
		"point": [
			-115.17215877771378,
			36.12017958918309
		],
		"times": 1
	},
	{
		"id": "51b4a26c498e781d108360d0",
		"name": "Wallace and Co.",
		"categories": [
			"Breakfast Spot"
		],
		"date": "2015-10-24T18:10:56.000Z",
		"point": [
			-79.448318,
			43.661362
		],
		"times": 1
	},
	{
		"id": "562bdd25498ee3e1c971ed91",
		"name": "Urban Acorn Market",
		"categories": [
			"Gourmet Shop"
		],
		"date": "2015-10-24T19:34:09.000Z",
		"point": [
			-79.453132,
			43.665406
		],
		"times": 1
	},
	{
		"id": "4ad9d993f964a520431b21e3",
		"name": "Evergreen Brick Works",
		"categories": [
			"Historic Site"
		],
		"date": "2015-10-25T18:44:42.000Z",
		"point": [
			-79.36462038000906,
			43.684057399110685
		],
		"times": 1
	},
	{
		"id": "4b07324af964a52064f922e3",
		"name": "The Public Butter",
		"categories": [
			"Thrift / Vintage Store"
		],
		"date": "2015-10-27T16:46:44.000Z",
		"point": [
			-79.430194,
			43.641884
		],
		"times": 1
	},
	{
		"id": "52a8ea4e11d24d53f10d4e55",
		"name": "Curry's Art Supplies",
		"categories": [
			"Arts & Crafts Store"
		],
		"date": "2015-10-28T17:57:21.000Z",
		"point": [
			-79.42481994628906,
			43.64287567138672
		],
		"times": 1
	},
	{
		"id": "4e1353ccc65b4a49f31e02a9",
		"name": "Cafe Neon",
		"categories": [
			"Café"
		],
		"date": "2015-10-31T18:07:09.000Z",
		"point": [
			-79.44634437561035,
			43.661848965053835
		],
		"times": 1
	},
	{
		"id": "4af332d9f964a520aeeb21e3",
		"name": "Nova Era Bakery",
		"categories": [
			"Bakery"
		],
		"date": "2015-11-02T14:39:16.000Z",
		"point": [
			-79.422144,
			43.649404
		],
		"times": 1
	},
	{
		"id": "45aa45b2f964a52030411fe3",
		"name": "Wynn Las Vegas",
		"categories": [
			"Casino"
		],
		"date": "2015-01-07T00:47:17.000Z",
		"point": [
			-115.16668438911438,
			36.12635860619657
		],
		"times": 1
	},
	{
		"id": "4b71899cf964a520e24b2de3",
		"name": "Etobicoke School Of The Arts",
		"categories": [
			"High School"
		],
		"date": "2015-11-07T00:14:21.000Z",
		"point": [
			-79.50370662184802,
			43.630541375321414
		],
		"times": 1
	},
	{
		"id": "4f7dd9a6e4b09d309a47f7fe",
		"name": "What A Bagel",
		"categories": [
			"Breakfast Spot"
		],
		"date": "2015-11-07T15:30:00.000Z",
		"point": [
			-79.39586298718302,
			43.64736399571039
		],
		"times": 1
	},
	{
		"id": "4fa3e5dee4b017a5dec512ce",
		"name": "Farmhouse Tavern",
		"categories": [
			"Gastropub"
		],
		"date": "2015-11-07T19:04:51.000Z",
		"point": [
			-79.45554206459016,
			43.66483563892365
		],
		"times": 1
	},
	{
		"id": "50574bbce4b0c7508c71f12a",
		"name": "Thai Express",
		"categories": [
			"Thai Restaurant"
		],
		"date": "2015-11-08T01:04:41.000Z",
		"point": [
			-79.403647,
			43.647187
		],
		"times": 1
	},
	{
		"id": "502652fee4b0e6861eab1f29",
		"name": "Merchants of Green Coffee",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-11-08T20:16:03.000Z",
		"point": [
			-79.354126835861,
			43.65988016902696
		],
		"times": 1
	},
	{
		"id": "4ad4c05cf964a5200ff620e3",
		"name": "Fresh On Spadina",
		"categories": [
			"Vegetarian / Vegan Restaurant"
		],
		"date": "2015-11-08T23:25:46.000Z",
		"point": [
			-79.39599492936729,
			43.648020061218006
		],
		"times": 1
	},
	{
		"id": "563e4536cd10f1fc36df18a2",
		"name": "Royale's Luncheonette",
		"categories": [
			"Sandwich Place"
		],
		"date": "2015-11-11T18:16:00.000Z",
		"point": [
			-79.42933727245304,
			43.64960953299312
		],
		"times": 1
	},
	{
		"id": "534733ef498e0de8391a4cc2",
		"name": "The Black Cat",
		"categories": [
			"Art Gallery"
		],
		"date": "2015-11-21T00:40:25.000Z",
		"point": [
			-79.45104233870589,
			43.65351614425536
		],
		"times": 1
	},
	{
		"id": "4b5df38ef964a520aa7529e3",
		"name": "Café Polonez",
		"categories": [
			"Eastern European Restaurant"
		],
		"date": "2015-11-21T19:26:16.000Z",
		"point": [
			-79.44844990968704,
			43.64512442011844
		],
		"times": 1
	},
	{
		"id": "4b7dc056f964a5205ed22fe3",
		"name": "Sands Expo Convention Center",
		"categories": [
			"Convention Center"
		],
		"date": "2015-01-06T23:17:16.000Z",
		"point": [
			-115.16641964876199,
			36.12222510008205
		],
		"times": 1
	},
	{
		"id": "51fe66b1498ebf135615465d",
		"name": "Queen Star All Day Breakfast",
		"categories": [
			"Breakfast Spot"
		],
		"date": "2015-11-23T20:20:59.000Z",
		"point": [
			-79.42305009010452,
			43.643300475678174
		],
		"times": 1
	},
	{
		"id": "55e394e7498e302ff8fd7de3",
		"name": "Little Triple A Bar (AAA)",
		"categories": [
			"BBQ Joint"
		],
		"date": "2015-11-25T00:31:21.000Z",
		"point": [
			-79.3291366490884,
			43.66369743650117
		],
		"times": 1
	},
	{
		"id": "51fd3488498e5101d539b69d",
		"name": "Me & Mine",
		"categories": [
			"Restaurant"
		],
		"date": "2015-11-28T19:13:53.000Z",
		"point": [
			-79.43379409728234,
			43.65234359530321
		],
		"times": 1
	},
	{
		"id": "4b099347f964a520ba1923e3",
		"name": "The Beer Store",
		"categories": [
			"Beer Store"
		],
		"date": "2015-11-28T21:20:23.000Z",
		"point": [
			-79.422578,
			43.6496
		],
		"times": 1
	},
	{
		"id": "4cd401a94944721ea3a5efa6",
		"name": "Hall A - Sands Expo Convention Center",
		"categories": [
			"Event Space"
		],
		"date": "2015-01-05T21:58:42.000Z",
		"point": [
			-115.16466526978795,
			36.121356546054926
		],
		"times": 1
	},
	{
		"id": "550c41b3498e7aa1df3018aa",
		"name": "Fancy Franks",
		"categories": [
			"Hot Dog Joint"
		],
		"date": "2015-11-29T20:30:27.000Z",
		"point": [
			-79.39727585828442,
			43.648529101060845
		],
		"times": 1
	},
	{
		"id": "4b686195f964a5200d752be3",
		"name": "CAMH",
		"categories": [
			"Building"
		],
		"date": "2015-12-01T18:28:33.000Z",
		"point": [
			-79.41865320430334,
			43.64395052707593
		],
		"times": 1
	},
	{
		"id": "563d33b5cd109ebcf66b55be",
		"name": "Saucy Pierogi",
		"categories": [
			"Ukrainian Restaurant"
		],
		"date": "2015-12-01T23:45:24.000Z",
		"point": [
			-79.42538585040164,
			43.649596905588176
		],
		"times": 1
	},
	{
		"id": "5398851211d2d84ef2ff020b",
		"name": "Johnny Rockets",
		"categories": [
			"American Restaurant"
		],
		"date": "2015-01-05T09:49:52.000Z",
		"point": [
			-115.17197801341364,
			36.116234846596775
		],
		"times": 1
	},
	{
		"id": "54d1511a498e0bdbddef0f46",
		"name": "Black Cat Espresso Bar",
		"categories": [
			"Coffee Shop"
		],
		"date": "2015-12-12T21:01:26.000Z",
		"point": [
			-79.43251917064754,
			43.65260484489056
		],
		"times": 1
	},
	{
		"id": "549a5a35498efecb7e666506",
		"name": "Gaslight",
		"categories": [
			"Pub"
		],
		"date": "2015-12-13T01:42:12.000Z",
		"point": [
			-79.44809,
			43.6573
		],
		"times": 1
	},
	{
		"id": "4ade61b9f964a520797521e3",
		"name": "Tequila Bookworm",
		"categories": [
			"Bar"
		],
		"date": "2015-12-15T00:02:43.000Z",
		"point": [
			-79.401582,
			43.647746
		],
		"times": 1
	},
	{
		"id": "562318b4498e3ed4cabe8736",
		"name": "Pursuit OCR",
		"categories": [
			"Athletics & Sports"
		],
		"date": "2015-12-16T22:43:16.000Z",
		"point": [
			-79.42983187524152,
			43.64464449741538
		],
		"times": 1
	},
	{
		"id": "4b4b6844f964a520749a26e3",
		"name": "CJ Lunchbox",
		"categories": [
			"Sushi Restaurant"
		],
		"date": "2015-12-18T17:16:55.000Z",
		"point": [
			-79.39591246552419,
			43.64793662155238
		],
		"times": 1
	},
	{
		"id": "4ad4c062f964a52002f820e3",
		"name": "Yorkdale Shopping Centre",
		"categories": [
			"Mall"
		],
		"date": "2015-12-19T20:05:11.000Z",
		"point": [
			-79.45174011282953,
			43.72596034111189
		],
		"times": 1
	},
	{
		"id": "505ca59ae4b04d802a9dd562",
		"name": "Google Toronto",
		"categories": [
			"Office"
		],
		"date": "2015-12-22T17:41:30.000Z",
		"point": [
			-79.38368501270948,
			43.650300386034615
		],
		"times": 1
	},
	{
		"id": "56511c81498eec7fad08c330",
		"name": "Shameful Tiki Room",
		"categories": [
			"Beach Bar"
		],
		"date": "2015-12-23T02:53:05.000Z",
		"point": [
			-79.4331951037771,
			43.641535864856095
		],
		"times": 1
	},
	{
		"id": "4ad4c05cf964a520cdf520e3",
		"name": "Insomnia Restaurant and Lounge",
		"categories": [
			"Restaurant"
		],
		"date": "2015-12-23T18:26:28.000Z",
		"point": [
			-79.41093066632001,
			43.665179579990216
		],
		"times": 1
	},
	{
		"id": "4b7716a9f964a520297c2ee3",
		"name": "Mother's Dumplings",
		"categories": [
			"Dumpling Restaurant"
		],
		"date": "2015-12-27T23:16:55.000Z",
		"point": [
			-79.3994255,
			43.6570939
		],
		"times": 1
	},
	{
		"id": "53965352498eb0e46d0afb04",
		"name": "Sweet Olenka's",
		"categories": [
			"Dessert Shop"
		],
		"date": "2015-12-28T21:46:22.000Z",
		"point": [
			-79.42060984437433,
			43.64386856258628
		],
		"times": 1
	},
	{
		"id": "564625e8498ed0ce5e3ba2f7",
		"name": "Cafe Neon",
		"categories": [
			"Café"
		],
		"date": "2015-12-29T14:58:48.000Z",
		"point": [
			-79.41957656771177,
			43.64399267518348
		],
		"times": 1
	},
	{
		"id": "5644dbaa498e7f7534154326",
		"name": "Maker Pizza",
		"categories": [
			"Pizza Place"
		],
		"date": "2015-12-29T18:25:15.000Z",
		"point": [
			-79.39801252181117,
			43.650413148628374
		],
		"times": 1
	}
]