module.exports=[
	{
		"points": [
			[
				-79.425618,
				43.662329
			],
			[
				-79.426045,
				43.662232
			],
			[
				-79.425684,
				43.661958
			],
			[
				-79.425108,
				43.660725
			],
			[
				-79.422858,
				43.654526
			],
			[
				-79.423358,
				43.654162
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.40488,
				43.668735
			],
			[
				-79.40416,
				43.66724
			],
			[
				-79.404545,
				43.667137
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.453215,
				43.72438
			],
			[
				-79.450593,
				43.725148
			],
			[
				-79.448562,
				43.725273
			],
			[
				-79.448341,
				43.725105
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.454998,
				43.726317
			],
			[
				-79.455154,
				43.726352
			],
			[
				-79.454407,
				43.725674
			],
			[
				-79.454456,
				43.724736
			],
			[
				-79.454263,
				43.724495
			],
			[
				-79.453215,
				43.72438
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.452942,
				43.724433
			],
			[
				-79.454192,
				43.724514
			],
			[
				-79.454998,
				43.726317
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.451802,
				43.726611
			],
			[
				-79.451571,
				43.726917
			],
			[
				-79.451139,
				43.725661
			],
			[
				-79.451638,
				43.724908
			],
			[
				-79.452942,
				43.724433
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.452018,
				43.726355
			],
			[
				-79.453307,
				43.726197
			],
			[
				-79.454533,
				43.725695
			],
			[
				-79.455289,
				43.725722
			],
			[
				-79.451802,
				43.726611
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.452417,
				43.726146
			],
			[
				-79.453495,
				43.726144
			],
			[
				-79.45431,
				43.725174
			],
			[
				-79.454089,
				43.724835
			],
			[
				-79.452741,
				43.725203
			],
			[
				-79.452155,
				43.725753
			],
			[
				-79.452018,
				43.726355
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.454416,
				43.725617
			],
			[
				-79.454195,
				43.724768
			],
			[
				-79.452117,
				43.725457
			],
			[
				-79.452417,
				43.726146
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.447624,
				43.724692
			],
			[
				-79.447927,
				43.72513
			],
			[
				-79.44779,
				43.725434
			],
			[
				-79.448094,
				43.725458
			],
			[
				-79.451233,
				43.725171
			],
			[
				-79.452696,
				43.724483
			],
			[
				-79.454153,
				43.724456
			],
			[
				-79.454416,
				43.725617
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.390941,
				43.648715
			],
			[
				-79.391749,
				43.649132
			],
			[
				-79.393503,
				43.648516
			],
			[
				-79.393879,
				43.648639
			],
			[
				-79.395962,
				43.647819
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.395659,
				43.647863
			],
			[
				-79.391606,
				43.648901
			],
			[
				-79.391847,
				43.648578
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.507731,
				43.648277
			],
			[
				-79.507995,
				43.647999
			],
			[
				-79.508935,
				43.648007
			],
			[
				-79.511129,
				43.647359
			],
			[
				-79.511473,
				43.647671
			],
			[
				-79.511144,
				43.64804
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.507129,
				43.648577
			],
			[
				-79.508042,
				43.648555
			],
			[
				-79.507731,
				43.648277
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.401252,
				43.647693
			],
			[
				-79.40361,
				43.647286
			],
			[
				-79.404024,
				43.647421
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.42262,
				43.653441
			],
			[
				-79.422204,
				43.652909
			],
			[
				-79.420698,
				43.649366
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.44135,
				43.639687
			],
			[
				-79.440516,
				43.639862
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.434568,
				43.641021
			],
			[
				-79.434741,
				43.640953
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.432421,
				43.652642
			],
			[
				-79.425951,
				43.653605
			],
			[
				-79.42355,
				43.654226
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.42366,
				43.654268
			],
			[
				-79.429821,
				43.652966
			],
			[
				-79.431503,
				43.653121
			],
			[
				-79.432381,
				43.652565
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4458293915,
				43.6552357774
			],
			[
				-79.4453588616,
				43.6552525236
			],
			[
				-79.4449429986,
				43.6547124677
			],
			[
				-79.4438243224,
				43.6518387059
			],
			[
				-79.4420374513,
				43.6506964473
			],
			[
				-79.4408822337,
				43.6504351583
			],
			[
				-79.441129475,
				43.6501888568
			],
			[
				-79.4350799968,
				43.6498859518
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4331456454,
				43.6413082913
			],
			[
				-79.4348627186,
				43.6409638022
			],
			[
				-79.434554,
				43.641024
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4200063588,
				43.6428546091
			],
			[
				-79.4195051934,
				43.6424408258
			],
			[
				-79.4193992974,
				43.6427225605
			],
			[
				-79.4185474428,
				43.6430403251
			],
			[
				-79.4186602002,
				43.6427772133
			],
			[
				-79.4196373296,
				43.6427143282
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.421943,
				43.649291
			],
			[
				-79.4221211862,
				43.6494041954
			],
			[
				-79.4209901609,
				43.6492498506
			],
			[
				-79.420682,
				43.648912
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4458293915,
				43.6552357774
			],
			[
				-79.4450144121,
				43.6550037769
			],
			[
				-79.4448623235,
				43.6544612028
			],
			[
				-79.4450574439,
				43.6545839649
			],
			[
				-79.443670961,
				43.6516665404
			],
			[
				-79.4428192114,
				43.6507267164
			],
			[
				-79.4420528173,
				43.6505041157
			],
			[
				-79.44044883,
				43.6503984558
			],
			[
				-79.4406575325,
				43.6507649851
			],
			[
				-79.4403885311,
				43.6509348253
			],
			[
				-79.4365571151,
				43.6519373544
			],
			[
				-79.433947,
				43.652212
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4289632426,
				43.6530428764
			],
			[
				-79.4274694753,
				43.6533002667
			],
			[
				-79.4266039365,
				43.6532417678
			],
			[
				-79.4255941427,
				43.6528217357
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.450632,
				43.65046
			],
			[
				-79.45077082,
				43.6514252233
			],
			[
				-79.4485058797,
				43.6520991263
			],
			[
				-79.4450234598,
				43.6510976768
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4486116238,
				43.6451451589
			],
			[
				-79.4485680728,
				43.6455198117
			],
			[
				-79.450632,
				43.65046
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4458293915,
				43.6552357774
			],
			[
				-79.4450551227,
				43.6551209818
			],
			[
				-79.443851089,
				43.6519566979
			],
			[
				-79.4430654708,
				43.650869978
			],
			[
				-79.4448809213,
				43.651058691
			],
			[
				-79.4451326728,
				43.6508730133
			],
			[
				-79.4437582499,
				43.6469271524
			],
			[
				-79.4439089766,
				43.6464295427
			],
			[
				-79.4482266608,
				43.6456162588
			],
			[
				-79.4486116238,
				43.6451451589
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4458293915,
				43.6552357774
			],
			[
				-79.4456463181,
				43.6551598981
			],
			[
				-79.4461150859,
				43.6553044318
			],
			[
				-79.4480099776,
				43.6571446813
			],
			[
				-79.4497555374,
				43.6570863949
			],
			[
				-79.4503372979,
				43.6574043325
			],
			[
				-79.4514898589,
				43.658842396
			],
			[
				-79.4523564009,
				43.6593173562
			],
			[
				-79.453540797,
				43.6609674591
			],
			[
				-79.4552419864,
				43.6622701972
			],
			[
				-79.4555073326,
				43.6629784484
			],
			[
				-79.4567526179,
				43.6634473301
			],
			[
				-79.4569038071,
				43.6638921027
			],
			[
				-79.4567341129,
				43.6644276227
			],
			[
				-79.4554434715,
				43.6648091136
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4291143323,
				43.6437191002
			],
			[
				-79.4292306817,
				43.6435498289
			],
			[
				-79.4287036373,
				43.6421281262
			],
			[
				-79.4362221304,
				43.6407282543
			],
			[
				-79.4323631896,
				43.6412359249
			],
			[
				-79.4324744216,
				43.6415070094
			],
			[
				-79.4299104687,
				43.641948731
			],
			[
				-79.4295869935,
				43.642110407
			],
			[
				-79.4296125452,
				43.6423287832
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4296496467,
				43.6443763647
			],
			[
				-79.4275225241,
				43.6453185003
			],
			[
				-79.4238720945,
				43.6460958733
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4241909286,
				43.6402944936
			],
			[
				-79.424174535,
				43.6401244421
			],
			[
				-79.4241994431,
				43.6405368572
			],
			[
				-79.4236577785,
				43.6401906403
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4350831601,
				43.6408734321
			],
			[
				-79.4357030876,
				43.6407095777
			],
			[
				-79.4354740605,
				43.6407301898
			],
			[
				-79.4357449901,
				43.639481054
			],
			[
				-79.4365874084,
				43.6388947774
			],
			[
				-79.436479265,
				43.6383187726
			],
			[
				-79.4368159628,
				43.6386340999
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4368159628,
				43.6386340999
			],
			[
				-79.4365261256,
				43.6383625567
			],
			[
				-79.4370519296,
				43.6401008045
			],
			[
				-79.4364456661,
				43.6406577918
			],
			[
				-79.4282276535,
				43.6421245915
			],
			[
				-79.4258031957,
				43.6427574962
			],
			[
				-79.4246856076,
				43.6427728229
			],
			[
				-79.4223927312,
				43.6435054982
			],
			[
				-79.4234705015,
				43.646308447
			],
			[
				-79.4237810666,
				43.6465741
			],
			[
				-79.4243694371,
				43.6487712064
			],
			[
				-79.4259426591,
				43.6525093488
			],
			[
				-79.4259465674,
				43.6527256
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4357449207,
				43.6406258577
			],
			[
				-79.4356520402,
				43.6397042818
			],
			[
				-79.436635463,
				43.6389108047
			],
			[
				-79.4365181358,
				43.638252481
			],
			[
				-79.4368159628,
				43.6386340999
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3850108426,
				43.647993034
			],
			[
				-79.3844474976,
				43.6475226315
			],
			[
				-79.38495293,
				43.6478116461
			],
			[
				-79.3869207638,
				43.6470385729
			],
			[
				-79.3902480705,
				43.6464415376
			],
			[
				-79.3907680552,
				43.6460165128
			],
			[
				-79.3911519895,
				43.6463670499
			],
			[
				-79.3938281255,
				43.645909883
			],
			[
				-79.3966750677,
				43.6450029131
			],
			[
				-79.3970913207,
				43.6451860409
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3644223996,
				43.6842494894
			],
			[
				-79.3655969043,
				43.6836257524
			],
			[
				-79.3654205025,
				43.6836650888
			],
			[
				-79.3675663408,
				43.6820609534
			],
			[
				-79.3688588136,
				43.6798175605
			],
			[
				-79.37080438,
				43.6787041256
			],
			[
				-79.3718611052,
				43.6792953235
			],
			[
				-79.3735424553,
				43.677821669
			],
			[
				-79.3728485974,
				43.6780505928
			],
			[
				-79.3731374485,
				43.6781960207
			],
			[
				-79.3740122169,
				43.6774475687
			],
			[
				-79.3751207711,
				43.6775331076
			],
			[
				-79.3751773261,
				43.6759867751
			],
			[
				-79.3748366561,
				43.675552487
			],
			[
				-79.375038442,
				43.6751023122
			],
			[
				-79.3774550125,
				43.6750742589
			],
			[
				-79.3773025325,
				43.6730597323
			],
			[
				-79.3769283074,
				43.6722268964
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3654983178,
				43.6836118155
			],
			[
				-79.3629377021,
				43.6845672922
			],
			[
				-79.3644223996,
				43.6842494894
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.448318,
				43.661362
			],
			[
				-79.4493700792,
				43.6614889346
			],
			[
				-79.4510162557,
				43.6655684359
			],
			[
				-79.4531968196,
				43.6652885472
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4458293915,
				43.6552357774
			],
			[
				-79.4454224821,
				43.6552455054
			],
			[
				-79.4461368396,
				43.6552652934
			],
			[
				-79.4465965751,
				43.6560120787
			],
			[
				-79.4477897553,
				43.657045343
			],
			[
				-79.4475849534,
				43.6574546423
			],
			[
				-79.4492119806,
				43.6611378406
			],
			[
				-79.448318,
				43.661362
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4297615507,
				43.6454120625
			],
			[
				-79.4314037161,
				43.6495835213
			],
			[
				-79.4260960013,
				43.6495165419
			],
			[
				-79.4251037559,
				43.6499504326
			],
			[
				-79.4240996852,
				43.6494231247
			],
			[
				-79.4211208459,
				43.6493036815
			],
			[
				-79.4205384855,
				43.6487171916
			],
			[
				-79.4203050114,
				43.6479564806
			],
			[
				-79.420505239,
				43.6478146993
			],
			[
				-79.4200401367,
				43.6473824886
			],
			[
				-79.419634,
				43.646288
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.422995843,
				43.6462797572
			],
			[
				-79.4199312271,
				43.6469019902
			],
			[
				-79.4200913651,
				43.6472325131
			],
			[
				-79.4198764857,
				43.6472671885
			],
			[
				-79.4214297178,
				43.651021038
			],
			[
				-79.421923731,
				43.6517267498
			],
			[
				-79.4222790646,
				43.6532372225
			],
			[
				-79.4225913638,
				43.6534981609
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3889742441,
				43.6689318169
			],
			[
				-79.388899735,
				43.6692362066
			],
			[
				-79.3882327922,
				43.6689447388
			],
			[
				-79.3874698707,
				43.6690011269
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4186830717,
				43.6358372065
			],
			[
				-79.4192373774,
				43.6358879983
			],
			[
				-79.4196263738,
				43.6362161198
			],
			[
				-79.4200206346,
				43.6374281812
			],
			[
				-79.4196840021,
				43.6381277201
			],
			[
				-79.4204934849,
				43.6385207669
			],
			[
				-79.4209223151,
				43.6397497391
			],
			[
				-79.4215598857,
				43.640225566
			],
			[
				-79.4211084757,
				43.6401108689
			],
			[
				-79.4175298906,
				43.6409230255
			],
			[
				-79.4180734033,
				43.6408129218
			],
			[
				-79.4171464316,
				43.6408283069
			],
			[
				-79.4166428359,
				43.641186203
			],
			[
				-79.4169203144,
				43.6416084312
			],
			[
				-79.4154200036,
				43.6422680919
			],
			[
				-79.4159339632,
				43.64359463
			],
			[
				-79.4167836153,
				43.6441418967
			],
			[
				-79.4178825773,
				43.6443448825
			],
			[
				-79.4189614249,
				43.6439829117
			],
			[
				-79.4202303393,
				43.6481117603
			],
			[
				-79.4222102663,
				43.6531217249
			],
			[
				-79.4224899193,
				43.6535018287
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.414914464,
				43.6347743905
			],
			[
				-79.414632771,
				43.6348032848
			],
			[
				-79.4158442273,
				43.634855833
			],
			[
				-79.4171348178,
				43.6358762
			],
			[
				-79.418017856,
				43.6355576133
			],
			[
				-79.4186830366,
				43.635837196
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4121618335,
				43.6343516797
			],
			[
				-79.4131443664,
				43.633868034
			],
			[
				-79.4143792108,
				43.633669526
			],
			[
				-79.4157185392,
				43.634519002
			],
			[
				-79.4156714316,
				43.6347045542
			],
			[
				-79.414914464,
				43.6347743905
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4026986816,
				43.727522523
			],
			[
				-79.4023505721,
				43.7256035997
			],
			[
				-79.4021205853,
				43.7252549342
			],
			[
				-79.4019801312,
				43.7253955574
			],
			[
				-79.4016641392,
				43.723431332
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4030526955,
				43.7294310684
			],
			[
				-79.4030370783,
				43.728176081
			],
			[
				-79.4026986816,
				43.727522523
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.403043037,
				43.7276592154
			],
			[
				-79.4032065634,
				43.7293339644
			],
			[
				-79.4030526955,
				43.7294310684
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3833704789,
				43.6612923823
			],
			[
				-79.382930623,
				43.6614951346
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.403647,
				43.647187
			],
			[
				-79.4037514447,
				43.6467224747
			],
			[
				-79.4030679632,
				43.6452886582
			],
			[
				-79.4004990025,
				43.6456052565
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3922317571,
				43.646054472
			],
			[
				-79.3900828794,
				43.6461721766
			],
			[
				-79.389920935,
				43.6462793347
			],
			[
				-79.390114463,
				43.6469406936
			],
			[
				-79.3899832605,
				43.6474062492
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.440521,
				43.658879
			],
			[
				-79.4421918707,
				43.6583292317
			],
			[
				-79.4422077072,
				43.657816431
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4426851206,
				43.6583166502
			],
			[
				-79.440521,
				43.658879
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4458293915,
				43.6552357774
			],
			[
				-79.4451941159,
				43.6552536735
			],
			[
				-79.4448297846,
				43.6548774464
			],
			[
				-79.4446201228,
				43.6536605526
			],
			[
				-79.4439308864,
				43.6524034627
			],
			[
				-79.4438089755,
				43.6517121499
			],
			[
				-79.4435087437,
				43.6515824783
			],
			[
				-79.4431962109,
				43.6508929674
			],
			[
				-79.4489300078,
				43.6520522366
			],
			[
				-79.4509295196,
				43.6514675389
			],
			[
				-79.4509214639,
				43.6509987116
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4236538157,
				43.654091203
			],
			[
				-79.4233539421,
				43.6542542458
			],
			[
				-79.4188357043,
				43.6551624639
			],
			[
				-79.4148806967,
				43.6550425528
			],
			[
				-79.4097763525,
				43.6558973145
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4170220423,
				43.6364259619
			],
			[
				-79.4180743471,
				43.6362255702
			],
			[
				-79.4186602815,
				43.635823085
			],
			[
				-79.4196837964,
				43.6360762331
			],
			[
				-79.4198440549,
				43.6363262018
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.8556705577,
				43.3133921757
			],
			[
				-79.8543386018,
				43.3131402783
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.2218344988,
				43.5354429894
			],
			[
				-80.2217596991,
				43.5351474012
			],
			[
				-80.2220761248,
				43.5351579078
			],
			[
				-80.2227449691,
				43.5345432247
			],
			[
				-80.2229266535,
				43.5338896285
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.2329896391,
				43.5217197552
			],
			[
				-80.2327254954,
				43.5218798513
			],
			[
				-80.2333339903,
				43.5220086519
			],
			[
				-80.2334182149,
				43.5224287855
			],
			[
				-80.231518479,
				43.5235182522
			],
			[
				-80.2317748715,
				43.523745657
			],
			[
				-80.2313146798,
				43.5237446754
			],
			[
				-80.2304183877,
				43.525746759
			],
			[
				-80.2307760079,
				43.5257968913
			],
			[
				-80.2305531616,
				43.5260828246
			],
			[
				-80.2286356729,
				43.5264301464
			],
			[
				-80.2288669939,
				43.5276784985
			],
			[
				-80.2302157596,
				43.5280751945
			],
			[
				-80.2288291674,
				43.5294517833
			],
			[
				-80.2278594958,
				43.5299267459
			],
			[
				-80.2284537166,
				43.5301779889
			],
			[
				-80.2283185837,
				43.530332896
			],
			[
				-80.2259476498,
				43.5313157714
			],
			[
				-80.2259698893,
				43.532034022
			],
			[
				-80.2244044306,
				43.5323114572
			],
			[
				-80.2234826684,
				43.5330314254
			],
			[
				-80.2217209517,
				43.5319215353
			],
			[
				-80.2219315812,
				43.5341665991
			],
			[
				-80.2216105737,
				43.5350157367
			],
			[
				-80.2218344988,
				43.5354429894
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.1742718306,
				43.5155543569
			],
			[
				-80.1749362346,
				43.5154831741
			],
			[
				-80.175926282,
				43.5148704908
			],
			[
				-80.1786630264,
				43.5161819427
			],
			[
				-80.1789486596,
				43.5160903085
			],
			[
				-80.1790484169,
				43.5152543854
			],
			[
				-80.1788624923,
				43.5153878892
			],
			[
				-80.1789871042,
				43.5155684112
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.2488880341,
				43.5438047748
			],
			[
				-80.2482431708,
				43.5442677375
			],
			[
				-80.2481010068,
				43.5447094867
			],
			[
				-80.2473330272,
				43.5451613091
			],
			[
				-80.2455817478,
				43.5456282559
			],
			[
				-80.2467403621,
				43.544639344
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.2476233184,
				43.5451215764
			],
			[
				-80.2488880341,
				43.5438047748
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.2527268765,
				43.5480848715
			],
			[
				-80.2516904922,
				43.5482030682
			],
			[
				-80.2508489102,
				43.5478991604
			],
			[
				-80.2507086016,
				43.5473495415
			],
			[
				-80.2482392645,
				43.5449215506
			],
			[
				-80.2477809341,
				43.5448023828
			],
			[
				-80.2469998754,
				43.5453395778
			],
			[
				-80.2476233184,
				43.5451215764
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.2218344988,
				43.5354429894
			],
			[
				-80.2259523991,
				43.5342328572
			],
			[
				-80.2286883802,
				43.532627222
			],
			[
				-80.2322619506,
				43.5339930611
			],
			[
				-80.2356639385,
				43.5344176504
			],
			[
				-80.2444837285,
				43.5407743725
			],
			[
				-80.248046745,
				43.5420102283
			],
			[
				-80.2500482983,
				43.5437030665
			],
			[
				-80.2502318449,
				43.5436254619
			],
			[
				-80.2520040857,
				43.5453160933
			],
			[
				-80.2516425603,
				43.5453853848
			],
			[
				-80.2527268765,
				43.5480848715
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-80.226240256,
				43.5278402825
			],
			[
				-80.2269692567,
				43.5275760244
			],
			[
				-80.224424525,
				43.528883408
			],
			[
				-80.2241418121,
				43.5292660745
			],
			[
				-80.2262429756,
				43.5311680477
			],
			[
				-80.2255345573,
				43.5318692297
			],
			[
				-80.2237625455,
				43.5326690674
			],
			[
				-80.2231100335,
				43.5333433662
			],
			[
				-80.2222992527,
				43.5325178407
			],
			[
				-80.221265779,
				43.5349440436
			],
			[
				-80.2218344988,
				43.5354429894
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4149101902,
				43.6604795087
			],
			[
				-79.4140234567,
				43.6606532937
			],
			[
				-79.4240808766,
				43.6585655069
			],
			[
				-79.4240172905,
				43.6577138778
			],
			[
				-79.4228177695,
				43.654783891
			],
			[
				-79.4229686957,
				43.6544150444
			],
			[
				-79.4235584818,
				43.6540926503
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4235730038,
				43.6542515203
			],
			[
				-79.4228826453,
				43.6545842581
			],
			[
				-79.4240066937,
				43.6583938648
			],
			[
				-79.4221850737,
				43.6589933118
			],
			[
				-79.4149101902,
				43.6604795087
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4190716743,
				43.6440258477
			],
			[
				-79.4201889225,
				43.6439782241
			],
			[
				-79.421692574,
				43.6435522498
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3232456989,
				43.6338457428
			],
			[
				-79.3241381241,
				43.6336491834
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3433677143,
				43.6129948468
			],
			[
				-79.3420649813,
				43.614750149
			],
			[
				-79.341792967,
				43.6147993923
			],
			[
				-79.3419511626,
				43.6147266634
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6161557345,
				43.6817768852
			],
			[
				-79.6157199744,
				43.6822921871
			],
			[
				-79.6145292986,
				43.6813884762
			],
			[
				-79.6126629469,
				43.6821923605
			],
			[
				-79.613161249,
				43.6826730222
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-123.1808157008,
				49.1940082584
			],
			[
				-123.1813094939,
				49.1944051382
			],
			[
				-123.1804299171,
				49.1952567963
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.7972301312,
				49.0458293152
			],
			[
				-122.796783408,
				49.0457886096
			],
			[
				-122.7964344116,
				49.0460690498
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-123.9647050388,
				45.8975637084
			],
			[
				-123.9652806118,
				45.8951856373
			],
			[
				-123.9656793525,
				45.8948635034
			],
			[
				-123.9650926672,
				45.8935961223
			],
			[
				-123.965828736,
				45.8932476934
			],
			[
				-123.9662644853,
				45.8917496881
			],
			[
				-123.965639066,
				45.8910567158
			],
			[
				-123.9660454259,
				45.891112776
			],
			[
				-123.9645869306,
				45.8913957602
			],
			[
				-123.9637181622,
				45.8920454032
			],
			[
				-123.9648796292,
				45.8903492779
			],
			[
				-123.9660378442,
				45.8911368229
			],
			[
				-123.9646913426,
				45.8916612616
			],
			[
				-123.9650523636,
				45.8924828284
			],
			[
				-123.9657259581,
				45.893192863
			],
			[
				-123.9649600384,
				45.8951009381
			],
			[
				-123.9650313912,
				45.8959360526
			],
			[
				-123.9636905819,
				45.8968963226
			],
			[
				-123.9635651551,
				45.8970626564
			],
			[
				-123.9641529193,
				45.897191028
			],
			[
				-123.9627839112,
				45.8978820045
			],
			[
				-123.9626323001,
				45.8982331863
			],
			[
				-123.9628295729,
				45.8983028242
			],
			[
				-123.9620978744,
				45.8983286456
			],
			[
				-123.9607876958,
				45.8988434652
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6737227358,
				45.5404120258
			],
			[
				-122.6739893276,
				45.5397743985
			],
			[
				-122.6749640481,
				45.5393937153
			],
			[
				-122.674788024,
				45.5393699767
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6779881818,
				45.5188500109
			],
			[
				-122.6779907942,
				45.5191053555
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.581689,
				45.519203
			],
			[
				-122.5815196527,
				45.5192625345
			],
			[
				-122.5815216118,
				45.5150778443
			],
			[
				-122.5824807484,
				45.5148038254
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5824807484,
				45.5148038254
			],
			[
				-122.5827337294,
				45.5145530535
			],
			[
				-122.5815823935,
				45.5147478365
			],
			[
				-122.5811973592,
				45.5150341239
			],
			[
				-122.5812463191,
				45.51604497
			],
			[
				-122.5815903551,
				45.5164623976
			],
			[
				-122.5813701696,
				45.5173504553
			],
			[
				-122.5814253188,
				45.5187433865
			],
			[
				-122.581689,
				45.519203
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6533937468,
				45.5184726657
			],
			[
				-122.653445457,
				45.5173457329
			],
			[
				-122.6522160453,
				45.5171596304
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6522653358,
				45.5177014802
			],
			[
				-122.6534822935,
				45.5170562102
			],
			[
				-122.6536958634,
				45.5165667416
			],
			[
				-122.6536272691,
				45.5127792843
			],
			[
				-122.653394757,
				45.5120829172
			],
			[
				-122.6537029978,
				45.5116323663
			],
			[
				-122.6536077274,
				45.5099443259
			],
			[
				-122.653918319,
				45.5088797834
			],
			[
				-122.6535762413,
				45.507316558
			],
			[
				-122.6539662692,
				45.5063979005
			],
			[
				-122.6536196523,
				45.5048767162
			],
			[
				-122.6533090108,
				45.5047234804
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6513366439,
				45.5237211359
			],
			[
				-122.6515805184,
				45.5232599183
			],
			[
				-122.6514744118,
				45.522630463
			],
			[
				-122.6518525553,
				45.5194976237
			],
			[
				-122.6529049432,
				45.5190324372
			],
			[
				-122.652631852,
				45.5191001447
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5815218781,
				45.5147613348
			],
			[
				-122.5790267378,
				45.5148566487
			],
			[
				-122.5790077434,
				45.5188672322
			],
			[
				-122.5792841623,
				45.5198688157
			],
			[
				-122.5793411602,
				45.5218618957
			],
			[
				-122.5792054521,
				45.5227059824
			],
			[
				-122.5822835439,
				45.5228571676
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5852043788,
				45.5104673138
			],
			[
				-122.5851807603,
				45.5138616186
			],
			[
				-122.5815218781,
				45.5147613348
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5853015959,
				45.5054241484
			],
			[
				-122.5851774149,
				45.5073423854
			],
			[
				-122.5854263273,
				45.5082747682
			],
			[
				-122.5851742737,
				45.5090108067
			],
			[
				-122.5853626199,
				45.5098666106
			],
			[
				-122.5852043788,
				45.5104673138
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6530681834,
				45.5188287366
			],
			[
				-122.6526180846,
				45.5190832065
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6539798371,
				45.5088540955
			],
			[
				-122.6536310074,
				45.5115632521
			],
			[
				-122.653876161,
				45.5140302877
			],
			[
				-122.6535770991,
				45.5149996313
			],
			[
				-122.6537310639,
				45.5170659118
			],
			[
				-122.6533360685,
				45.5177513164
			],
			[
				-122.6531998477,
				45.5187795425
			],
			[
				-122.6536270121,
				45.5189456724
			],
			[
				-122.652631852,
				45.5191001447
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.0686615863,
				45.5900473696
			],
			[
				-122.06887783,
				45.5900350169
			],
			[
				-122.0685067609,
				45.590026187
			],
			[
				-122.0688114254,
				45.590272789
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.2202487528,
				45.5305389885
			],
			[
				-122.2205377341,
				45.5304607468
			],
			[
				-122.219167836,
				45.533581005
			],
			[
				-122.2186214119,
				45.5335157185
			],
			[
				-122.2184474672,
				45.5339577994
			],
			[
				-122.21850282,
				45.5359848562
			],
			[
				-122.2167622314,
				45.5377368804
			],
			[
				-122.2173727771,
				45.5379535992
			],
			[
				-122.2179143023,
				45.5388407055
			],
			[
				-122.2176123816,
				45.5389144411
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.2187297004,
				45.5336364413
			],
			[
				-122.2202487528,
				45.5305389885
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.2178699691,
				45.5369192825
			],
			[
				-122.2188294833,
				45.5355674938
			],
			[
				-122.2196546997,
				45.5333099734
			],
			[
				-122.2184845634,
				45.5337237389
			],
			[
				-122.2187297004,
				45.5336364413
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.2179184411,
				45.538644872
			],
			[
				-122.2167855223,
				45.5373140912
			],
			[
				-122.2171116741,
				45.5369056962
			],
			[
				-122.2178699691,
				45.5369192825
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6521794318,
				45.519232885
			],
			[
				-122.6517640836,
				45.5190595306
			],
			[
				-122.6521586376,
				45.5184734943
			],
			[
				-122.6517362656,
				45.5169747007
			],
			[
				-122.6519165002,
				45.5150848959
			],
			[
				-122.651625713,
				45.5133403731
			],
			[
				-122.6517960463,
				45.5129935985
			],
			[
				-122.6535944182,
				45.5128623776
			],
			[
				-122.6535317388,
				45.5105774709
			],
			[
				-122.6539782344,
				45.5089918931
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6537499923,
				45.5145975561
			],
			[
				-122.6535959517,
				45.51698281
			],
			[
				-122.6530065007,
				45.5189408364
			],
			[
				-122.6534731452,
				45.5191854639
			],
			[
				-122.652631852,
				45.5191001447
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6535095314,
				45.5187165982
			],
			[
				-122.6537499923,
				45.5145975561
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6514988886,
				45.5165829103
			],
			[
				-122.6519719359,
				45.5179502303
			],
			[
				-122.6515550707,
				45.5188987046
			],
			[
				-122.651839353,
				45.5191818546
			],
			[
				-122.6524692272,
				45.5192846833
			],
			[
				-122.652631852,
				45.5191001447
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6520843332,
				45.5185843501
			],
			[
				-122.6517597206,
				45.5186017375
			],
			[
				-122.6520923257,
				45.5178929864
			],
			[
				-122.6515270114,
				45.516584054
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5784515995,
				45.5182787041
			],
			[
				-122.5791658585,
				45.5186382614
			],
			[
				-122.5807935408,
				45.5185353487
			],
			[
				-122.5813639991,
				45.5179112497
			],
			[
				-122.5815459882,
				45.5161530016
			],
			[
				-122.5813824839,
				45.5150587155
			],
			[
				-122.5817701365,
				45.5146870969
			],
			[
				-122.5822431754,
				45.5146083742
			],
			[
				-122.5824807484,
				45.5148038254
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6525254541,
				45.5189938641
			],
			[
				-122.6526457638,
				45.5192715718
			],
			[
				-122.6512115531,
				45.5192938848
			],
			[
				-122.6508084848,
				45.5191213744
			],
			[
				-122.6507133488,
				45.5178893124
			],
			[
				-122.651037942,
				45.5173689106
			],
			[
				-122.6506561735,
				45.5164312987
			],
			[
				-122.6494491441,
				45.5165254454
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6509240635,
				45.5176761064
			],
			[
				-122.6525278544,
				45.5188562001
			],
			[
				-122.6523722432,
				45.5191266387
			],
			[
				-122.652631852,
				45.5191001447
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.6509240635,
				45.5176761064
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6611518128,
				45.519262652
			],
			[
				-122.6558286822,
				45.5194232021
			],
			[
				-122.6528791985,
				45.5190419949
			],
			[
				-122.653037709,
				45.5189782876
			],
			[
				-122.652631852,
				45.5191001447
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6810510556,
				45.5230324835
			],
			[
				-122.6812766296,
				45.5235325719
			],
			[
				-122.6810822274,
				45.5247185455
			],
			[
				-122.6820980381,
				45.5252201367
			],
			[
				-122.6822121114,
				45.5255022025
			],
			[
				-122.6820856226,
				45.5266400293
			],
			[
				-122.6823141837,
				45.5270910517
			],
			[
				-122.6823486847,
				45.5286923079
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5819071337,
				45.5191608113
			],
			[
				-122.5815224414,
				45.5192090901
			],
			[
				-122.5814251605,
				45.5195042348
			],
			[
				-122.5814086625,
				45.5206905176
			],
			[
				-122.5817839957,
				45.5214417655
			],
			[
				-122.5814350236,
				45.5223078366
			],
			[
				-122.5816462737,
				45.5228989971
			],
			[
				-122.582274327,
				45.5227824655
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5824807484,
				45.5148038254
			],
			[
				-122.5822732769,
				45.514608729
			],
			[
				-122.5816080609,
				45.5146335482
			],
			[
				-122.5813440402,
				45.5151606078
			],
			[
				-122.5815546477,
				45.5168103424
			],
			[
				-122.5814452135,
				45.5185806373
			],
			[
				-122.5819071337,
				45.5191608113
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.581689,
				45.519203
			],
			[
				-122.5814733332,
				45.5181203509
			],
			[
				-122.5816483108,
				45.5157769472
			],
			[
				-122.5813339301,
				45.5148221061
			],
			[
				-122.5819684624,
				45.5146474255
			],
			[
				-122.5824807484,
				45.5148038254
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5824807484,
				45.5148038254
			],
			[
				-122.5824807904,
				45.5145307636
			],
			[
				-122.5821641802,
				45.5145270764
			],
			[
				-122.5812287653,
				45.514950125
			],
			[
				-122.5815745714,
				45.5159703795
			],
			[
				-122.581378929,
				45.5172630333
			],
			[
				-122.581689,
				45.519203
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6605045167,
				45.5227786303
			],
			[
				-122.6572929789,
				45.5229389508
			],
			[
				-122.6571174542,
				45.5226159047
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.652631852,
				45.5191001447
			],
			[
				-122.652715202,
				45.5227898681
			],
			[
				-122.6533858789,
				45.5229407816
			],
			[
				-122.6545419399,
				45.5227713918
			],
			[
				-122.6553648599,
				45.5231021577
			],
			[
				-122.6570965708,
				45.5228138936
			],
			[
				-122.6595483376,
				45.5229843475
			],
			[
				-122.6605045167,
				45.5227786303
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6553482584,
				45.5169794509
			],
			[
				-122.6547128871,
				45.5172045999
			],
			[
				-122.6531681172,
				45.5170976464
			],
			[
				-122.6522607239,
				45.5175595677
			],
			[
				-122.6522254485,
				45.517896117
			],
			[
				-122.6528153589,
				45.5187384015
			],
			[
				-122.652631852,
				45.5191001447
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.656767,
				45.521453
			],
			[
				-122.6558860692,
				45.5214435058
			],
			[
				-122.655661618,
				45.521177131
			],
			[
				-122.6556408631,
				45.5196462401
			],
			[
				-122.6548027528,
				45.5194036205
			],
			[
				-122.6556817469,
				45.5194596231
			],
			[
				-122.6558237242,
				45.5192525749
			],
			[
				-122.655840044,
				45.518396957
			],
			[
				-122.6553482584,
				45.5169794509
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6559,
				45.526029
			],
			[
				-122.6536956709,
				45.5251842447
			],
			[
				-122.6538699224,
				45.5241156198
			],
			[
				-122.6534994426,
				45.5236908574
			],
			[
				-122.6540941982,
				45.5225944498
			],
			[
				-122.6558393735,
				45.5221302868
			],
			[
				-122.6565368251,
				45.5221748403
			],
			[
				-122.6563938908,
				45.521577873
			],
			[
				-122.656767,
				45.521453
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6512626717,
				45.5170609696
			],
			[
				-122.6518292822,
				45.5180272869
			],
			[
				-122.6515580507,
				45.5191817023
			],
			[
				-122.6517059331,
				45.5204217094
			],
			[
				-122.6515019024,
				45.5212696506
			],
			[
				-122.6517599867,
				45.5224895285
			],
			[
				-122.651433904,
				45.5237555785
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6540035746,
				45.5173252396
			],
			[
				-122.6512626717,
				45.5170609696
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5824807484,
				45.5148038254
			],
			[
				-122.5818183841,
				45.5144939186
			],
			[
				-122.5811296843,
				45.515047142
			],
			[
				-122.5815625571,
				45.516075159
			],
			[
				-122.5813791421,
				45.5174976522
			],
			[
				-122.58231946,
				45.519242
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5825015983,
				45.5145221099
			],
			[
				-122.5824807484,
				45.5148038254
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.6529172481,
				45.5190710925
			],
			[
				-122.6528256491,
				45.5193231016
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.582274051,
				45.5192286201
			],
			[
				-122.5814225748,
				45.518725296
			],
			[
				-122.5815977315,
				45.5165929928
			],
			[
				-122.5813805126,
				45.5151159953
			],
			[
				-122.5819699585,
				45.5146143144
			],
			[
				-122.5824807484,
				45.5148038254
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5824807484,
				45.5148038254
			],
			[
				-122.5825650425,
				45.5145629373
			],
			[
				-122.5823987072,
				45.5145326854
			],
			[
				-122.5815634566,
				45.5146673181
			],
			[
				-122.5813104632,
				45.5154497202
			],
			[
				-122.581548412,
				45.5189751383
			],
			[
				-122.582274051,
				45.5192286201
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5633187089,
				45.5305028719
			],
			[
				-122.5632257722,
				45.530085119
			],
			[
				-122.5626246431,
				45.5295245605
			],
			[
				-122.5620204377,
				45.5295138111
			],
			[
				-122.5619595702,
				45.5284693893
			],
			[
				-122.5621017659,
				45.526216708
			],
			[
				-122.5624182023,
				45.5250631621
			],
			[
				-122.5622619261,
				45.5243179212
			],
			[
				-122.5625129462,
				45.5242071809
			],
			[
				-122.5621303317,
				45.5229147306
			],
			[
				-122.5623798168,
				45.5205140327
			],
			[
				-122.5622413296,
				45.518053986
			],
			[
				-122.5637928477,
				45.5159275146
			],
			[
				-122.5627324357,
				45.5160460988
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5944544527,
				45.5907938739
			],
			[
				-122.5930878547,
				45.5899513274
			],
			[
				-122.5927218655,
				45.5884496414
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-122.5953411845,
				45.5919518008
			],
			[
				-122.5953035478,
				45.5912683067
			],
			[
				-122.5944544527,
				45.5907938739
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-93.203693905,
				44.8827892999
			],
			[
				-93.2047791454,
				44.8823077542
			],
			[
				-93.2064513104,
				44.8798092069
			],
			[
				-93.2063689165,
				44.8803609976
			],
			[
				-93.2064378563,
				44.8799398509
			],
			[
				-93.2081418764,
				44.8800308197
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6210295257,
				43.6865623897
			],
			[
				-79.6218174772,
				43.6856983947
			],
			[
				-79.6238559585,
				43.6852903102
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6211503491,
				43.6870710324
			],
			[
				-79.6205695805,
				43.686738634
			],
			[
				-79.6201957907,
				43.6889882507
			],
			[
				-79.6215651257,
				43.6878928852
			],
			[
				-79.6210522524,
				43.687555525
			],
			[
				-79.6210112508,
				43.6874074813
			],
			[
				-79.6215717405,
				43.687665196
			],
			[
				-79.6204308566,
				43.6863618364
			],
			[
				-79.6210295257,
				43.6865623897
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4277904684,
				43.6615282962
			],
			[
				-79.426930725,
				43.6614890959
			],
			[
				-79.4260722077,
				43.6594709702
			],
			[
				-79.4253066853,
				43.6586255728
			],
			[
				-79.4254263,
				43.6579396478
			],
			[
				-79.4246398597,
				43.656742879
			],
			[
				-79.4243568804,
				43.6551922052
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4245758622,
				43.6555110907
			],
			[
				-79.4248969257,
				43.656871726
			],
			[
				-79.4257934796,
				43.6587213566
			],
			[
				-79.4266342387,
				43.6612733286
			],
			[
				-79.426945275,
				43.6616601396
			],
			[
				-79.4277904684,
				43.6615282962
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3936603764,
				43.61925122
			],
			[
				-79.3934996328,
				43.6200115995
			],
			[
				-79.392612416,
				43.6215491553
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3920677947,
				43.6206695868
			],
			[
				-79.3935586093,
				43.6197335397
			],
			[
				-79.3936603764,
				43.61925122
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4236739856,
				43.6540036935
			],
			[
				-79.4233507426,
				43.6542308189
			],
			[
				-79.4213113577,
				43.6548818654
			],
			[
				-79.4173615031,
				43.6553483924
			],
			[
				-79.4211556261,
				43.6548831881
			],
			[
				-79.4234021754,
				43.6541903264
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4223014174,
				43.6434272437
			],
			[
				-79.418940307,
				43.6442789317
			],
			[
				-79.4202092885,
				43.6482779264
			],
			[
				-79.4205918446,
				43.6488850678
			],
			[
				-79.4206458491,
				43.6492738077
			],
			[
				-79.4203185495,
				43.6494337752
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4413443785,
				43.6396509727
			],
			[
				-79.4417487432,
				43.6396278461
			],
			[
				-79.439280974,
				43.6400077642
			],
			[
				-79.4392065181,
				43.64019555
			],
			[
				-79.4377401045,
				43.6402247957
			],
			[
				-79.4329878417,
				43.64149139
			],
			[
				-79.4306397208,
				43.6417088414
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.328053,
				43.6636
			],
			[
				-79.3270527151,
				43.6640173582
			],
			[
				-79.3254790676,
				43.6642620998
			],
			[
				-79.325352496,
				43.6647571627
			],
			[
				-79.3258409358,
				43.6661625599
			],
			[
				-79.3243139462,
				43.6668321016
			],
			[
				-79.3241905461,
				43.6674681028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3241905461,
				43.6674681028
			],
			[
				-79.3244792887,
				43.666863834
			],
			[
				-79.3259642098,
				43.6661936284
			],
			[
				-79.3254214745,
				43.6644283283
			],
			[
				-79.328053,
				43.6636
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4205325756,
				43.6396318154
			],
			[
				-79.4190213646,
				43.639716578
			],
			[
				-79.4199545619,
				43.6396522392
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4097763525,
				43.6558973145
			],
			[
				-79.4104098118,
				43.6559444728
			],
			[
				-79.4147588764,
				43.6550607666
			],
			[
				-79.4192067165,
				43.6551136618
			],
			[
				-79.423247404,
				43.6541932286
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.402978,
				43.656283
			],
			[
				-79.4027056609,
				43.6561692974
			],
			[
				-79.4031717349,
				43.657252869
			],
			[
				-79.4038988603,
				43.6573279475
			],
			[
				-79.4066516755,
				43.6568539696
			],
			[
				-79.4080156379,
				43.6563880479
			],
			[
				-79.4097117637,
				43.6563206486
			],
			[
				-79.4099569417,
				43.6561562175
			],
			[
				-79.4097763525,
				43.6558973145
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4095194393,
				43.6630365643
			],
			[
				-79.4088847465,
				43.6627784686
			],
			[
				-79.4091260311,
				43.6619303916
			],
			[
				-79.4083724958,
				43.6618609215
			],
			[
				-79.4068555276,
				43.6623574574
			],
			[
				-79.4059049314,
				43.6621295381
			],
			[
				-79.4044192385,
				43.657994109
			],
			[
				-79.403051662,
				43.6571793047
			],
			[
				-79.402978,
				43.656283
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.41336,
				43.66301
			],
			[
				-79.4123125552,
				43.6623093683
			],
			[
				-79.4103749875,
				43.6626856356
			],
			[
				-79.4100046339,
				43.6632163626
			],
			[
				-79.4099711829,
				43.6628289017
			],
			[
				-79.4095194393,
				43.6630365643
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.423262077,
				43.6534130495
			],
			[
				-79.4257913142,
				43.6529171187
			],
			[
				-79.4261767177,
				43.6516851548
			],
			[
				-79.4272517228,
				43.6512816728
			],
			[
				-79.4271330758,
				43.6501587689
			],
			[
				-79.4274009477,
				43.6496359007
			],
			[
				-79.4290903275,
				43.6496894306
			],
			[
				-79.4295634528,
				43.6494130762
			],
			[
				-79.4291166078,
				43.647146184
			],
			[
				-79.4301030144,
				43.6467908087
			],
			[
				-79.4301836155,
				43.6465303584
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4001353927,
				43.6583042827
			],
			[
				-79.4004361543,
				43.6578725649
			],
			[
				-79.4024049086,
				43.6574567204
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3979700211,
				43.6583646422
			],
			[
				-79.3973969534,
				43.6583808902
			],
			[
				-79.3996238975,
				43.6579307488
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4027622113,
				43.6565029965
			],
			[
				-79.4023480703,
				43.6561754087
			],
			[
				-79.3997322147,
				43.6566412656
			],
			[
				-79.398908862,
				43.654858521
			],
			[
				-79.398440726,
				43.654477412
			],
			[
				-79.3985678835,
				43.6543501077
			],
			[
				-79.3978819385,
				43.6529862645
			],
			[
				-79.3967224348,
				43.6493960487
			],
			[
				-79.3961756153,
				43.6487353984
			],
			[
				-79.3969515046,
				43.6486779828
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.423362755,
				43.6541843789
			],
			[
				-79.4227309786,
				43.6543758167
			],
			[
				-79.4258575981,
				43.662252927
			],
			[
				-79.4228925909,
				43.6548978968
			],
			[
				-79.4228319607,
				43.6544914182
			],
			[
				-79.4235098728,
				43.6540677823
			],
			[
				-79.4233701233,
				43.6536585763
			],
			[
				-79.4237190789,
				43.6533525145
			],
			[
				-79.4229390612,
				43.6540134312
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4259228462,
				43.6618890343
			],
			[
				-79.4255363868,
				43.6617879125
			],
			[
				-79.4250367873,
				43.6606993888
			],
			[
				-79.4244579902,
				43.6584868484
			],
			[
				-79.4228261402,
				43.654570219
			],
			[
				-79.4234513972,
				43.6542005357
			],
			[
				-79.4233186587,
				43.6535830192
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4935714383,
				43.6515380943
			],
			[
				-79.4933353799,
				43.6511502401
			],
			[
				-79.4928514281,
				43.6511312268
			],
			[
				-79.4899014253,
				43.6514752532
			],
			[
				-79.4893817085,
				43.6500497436
			],
			[
				-79.4887224349,
				43.6493422382
			],
			[
				-79.4872196621,
				43.6483821221
			],
			[
				-79.486285005,
				43.6481396714
			],
			[
				-79.4844091014,
				43.649419816
			],
			[
				-79.4780287773,
				43.6507657506
			],
			[
				-79.4774181438,
				43.6511230994
			],
			[
				-79.4758603849,
				43.6511998606
			],
			[
				-79.4759774208,
				43.6513855111
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4836525707,
				43.6496810221
			],
			[
				-79.484263949,
				43.6493339474
			],
			[
				-79.484525168,
				43.649454654
			],
			[
				-79.4855475322,
				43.6485056024
			],
			[
				-79.4864759168,
				43.6482037217
			],
			[
				-79.4870491208,
				43.6483102723
			],
			[
				-79.4888571645,
				43.6494304184
			],
			[
				-79.4895655438,
				43.6510500766
			],
			[
				-79.4901207103,
				43.6516114334
			],
			[
				-79.4939066809,
				43.6510827873
			],
			[
				-79.4937870592,
				43.651250342
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4527766642,
				43.6567400828
			],
			[
				-79.4579823249,
				43.655727893
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4413443785,
				43.6396509727
			],
			[
				-79.4447825968,
				43.6389803677
			],
			[
				-79.4462875718,
				43.6391661296
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.422662449,
				43.6534199632
			],
			[
				-79.4220601542,
				43.6527828663
			],
			[
				-79.4201921366,
				43.647830854
			],
			[
				-79.4198009349,
				43.6461110729
			],
			[
				-79.4228155143,
				43.6456138918
			],
			[
				-79.4231077301,
				43.6452223022
			],
			[
				-79.4228095466,
				43.6439860903
			],
			[
				-79.4247710109,
				43.6430062994
			],
			[
				-79.4371278382,
				43.6406606102
			],
			[
				-79.4413443785,
				43.6396509727
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.413951,
				43.65528
			],
			[
				-79.4170208891,
				43.6634073748
			],
			[
				-79.4173949149,
				43.6637255447
			],
			[
				-79.4184294973,
				43.6638100477
			],
			[
				-79.4185308143,
				43.6640357474
			],
			[
				-79.4189272111,
				43.663668674
			],
			[
				-79.4232923994,
				43.6623802373
			],
			[
				-79.422957702,
				43.6622459688
			],
			[
				-79.4219024464,
				43.6590592289
			],
			[
				-79.4238448604,
				43.6587048603
			],
			[
				-79.424083015,
				43.6583172224
			],
			[
				-79.4228894541,
				43.6547927063
			],
			[
				-79.4229105205,
				43.6544704116
			],
			[
				-79.423718701,
				43.6541282061
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4197186883,
				43.6549672805
			],
			[
				-79.4173485492,
				43.6551689961
			],
			[
				-79.4153600441,
				43.654899408
			],
			[
				-79.413765041,
				43.6552564855
			],
			[
				-79.413951,
				43.65528
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4288954157,
				43.6429478263
			],
			[
				-79.4285553014,
				43.6420103623
			],
			[
				-79.4297894382,
				43.6419896274
			],
			[
				-79.4344126549,
				43.6411565906
			],
			[
				-79.4358354092,
				43.6405471174
			],
			[
				-79.434946085,
				43.6409366055
			],
			[
				-79.4290656655,
				43.6421800526
			],
			[
				-79.4289219286,
				43.6427414536
			],
			[
				-79.4294375091,
				43.6441522201
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.420682,
				43.648912
			],
			[
				-79.4202893319,
				43.6493584529
			],
			[
				-79.4206185565,
				43.649359512
			],
			[
				-79.420747572,
				43.6490802305
			],
			[
				-79.4195594687,
				43.6466200827
			],
			[
				-79.4189690515,
				43.6446970905
			],
			[
				-79.4189796744,
				43.6441229073
			],
			[
				-79.4194630183,
				43.644047763
			],
			[
				-79.4190716743,
				43.6440258477
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3985366821,
				43.6465412771
			],
			[
				-79.3975389319,
				43.6457338992
			],
			[
				-79.3977347639,
				43.6456124548
			],
			[
				-79.3982197977,
				43.6460486506
			],
			[
				-79.3992200382,
				43.6461155379
			],
			[
				-79.4031614028,
				43.6453463177
			],
			[
				-79.4044460581,
				43.6481815487
			],
			[
				-79.404888196,
				43.650070868
			],
			[
				-79.406228328,
				43.6522089147
			],
			[
				-79.420383299,
				43.6494505811
			],
			[
				-79.4210003363,
				43.6499181813
			],
			[
				-79.4217065891,
				43.6522705041
			],
			[
				-79.4221818857,
				43.6530132413
			],
			[
				-79.4227275818,
				43.6534623359
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4483334702,
				43.6449465052
			],
			[
				-79.4477811967,
				43.6446839901
			],
			[
				-79.4462031991,
				43.6450485281
			],
			[
				-79.4453037577,
				43.6455271612
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4494447297,
				43.6474911531
			],
			[
				-79.4485380644,
				43.6449950553
			],
			[
				-79.4483334702,
				43.6449465052
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4498056807,
				43.6484216388
			],
			[
				-79.4494447297,
				43.6474911531
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3830405615,
				43.6540185657
			],
			[
				-79.3830783354,
				43.6522387051
			],
			[
				-79.3817833768,
				43.6519171475
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.382380369,
				43.6520832635
			],
			[
				-79.3821536212,
				43.6520204026
			],
			[
				-79.3824738147,
				43.6520771334
			],
			[
				-79.3830405615,
				43.6540185657
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4124188505,
				43.6454380227
			],
			[
				-79.4220166285,
				43.6435174376
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3977551519,
				43.6456185887
			],
			[
				-79.3979184743,
				43.6457229317
			],
			[
				-79.3982275907,
				43.6468873355
			],
			[
				-79.3986702602,
				43.6474344263
			],
			[
				-79.4091797755,
				43.6454097644
			],
			[
				-79.4099350339,
				43.6454179684
			],
			[
				-79.4101190935,
				43.6457898991
			],
			[
				-79.4103767174,
				43.6458185151
			],
			[
				-79.4124188505,
				43.6454380227
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3872503225,
				43.6658671095
			],
			[
				-79.3874564917,
				43.6654897683
			],
			[
				-79.3870007067,
				43.6652221754
			],
			[
				-79.3873110974,
				43.6648669417
			],
			[
				-79.386958082,
				43.664672358
			],
			[
				-79.3871539954,
				43.6640681076
			],
			[
				-79.3859533712,
				43.6617387276
			],
			[
				-79.386235058,
				43.6610249293
			],
			[
				-79.3848906931,
				43.6586726055
			],
			[
				-79.3842259422,
				43.6562316365
			],
			[
				-79.3837235253,
				43.6557823252
			],
			[
				-79.3868696523,
				43.655166935
			],
			[
				-79.3873284835,
				43.6548569547
			],
			[
				-79.3882679074,
				43.655017161
			],
			[
				-79.398270394,
				43.6529792048
			],
			[
				-79.398081,
				43.652772
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3834286439,
				43.6646224574
			],
			[
				-79.3837384014,
				43.6650381312
			],
			[
				-79.3861616052,
				43.664504308
			],
			[
				-79.3864856199,
				43.664851482
			],
			[
				-79.3865267199,
				43.6654541268
			],
			[
				-79.3872503225,
				43.6658671095
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4116287444,
				43.6435790457
			],
			[
				-79.4141274583,
				43.6429802563
			],
			[
				-79.4153947059,
				43.6429587188
			],
			[
				-79.4165130706,
				43.6442029513
			],
			[
				-79.4172733143,
				43.6443417416
			],
			[
				-79.4262224349,
				43.6427388993
			],
			[
				-79.426970104,
				43.6427737075
			],
			[
				-79.4277523253,
				43.6441359624
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3978432259,
				43.6456022329
			],
			[
				-79.3982261316,
				43.6460843538
			],
			[
				-79.39959329,
				43.6461163617
			],
			[
				-79.4008505616,
				43.6455972289
			],
			[
				-79.4028486451,
				43.6453486558
			],
			[
				-79.4043658846,
				43.6447284948
			],
			[
				-79.4059628072,
				43.6447264457
			],
			[
				-79.4116287444,
				43.6435790457
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4235401929,
				43.6531990698
			],
			[
				-79.4241885863,
				43.653271446
			],
			[
				-79.4259176204,
				43.6526712861
			],
			[
				-79.4256799038,
				43.6520903619
			],
			[
				-79.4258236337,
				43.6515901937
			],
			[
				-79.42499854,
				43.6502078658
			],
			[
				-79.4249251996,
				43.649541047
			],
			[
				-79.4290601285,
				43.6495979817
			],
			[
				-79.4296540839,
				43.6494340359
			],
			[
				-79.4284060582,
				43.6457781998
			],
			[
				-79.4294822081,
				43.6455294562
			],
			[
				-79.4296215323,
				43.6452482437
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3909406662,
				43.6487150202
			],
			[
				-79.3920365787,
				43.6487821572
			],
			[
				-79.395852644,
				43.6478241448
			],
			[
				-79.3996136939,
				43.6474518033
			],
			[
				-79.4031873865,
				43.6465562514
			],
			[
				-79.4040593498,
				43.6470721635
			],
			[
				-79.4059552542,
				43.6469031104
			],
			[
				-79.4148147381,
				43.6449126348
			],
			[
				-79.4146438365,
				43.6450066038
			],
			[
				-79.418677003,
				43.6442918884
			],
			[
				-79.4222026167,
				43.6530427546
			],
			[
				-79.422502105,
				43.6535290718
			],
			[
				-79.4234153435,
				43.6533353825
			],
			[
				-79.4233402243,
				43.6535368484
			],
			[
				-79.4226370263,
				43.6536925189
			],
			[
				-79.4226908517,
				43.654200065
			],
			[
				-79.4240288472,
				43.6541380626
			],
			[
				-79.4187531887,
				43.6552799176
			],
			[
				-79.415762272,
				43.6549899732
			],
			[
				-79.413951,
				43.65528
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3967920761,
				43.6486888196
			],
			[
				-79.3961660785,
				43.6488498096
			],
			[
				-79.3961102266,
				43.648264962
			],
			[
				-79.3957263435,
				43.647953865
			],
			[
				-79.3932050575,
				43.6482751599
			],
			[
				-79.3916566088,
				43.6489062595
			],
			[
				-79.3909406662,
				43.6487150202
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.410737,
				43.645787
			],
			[
				-79.3967920761,
				43.6486888196
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4293559272,
				43.6441069032
			],
			[
				-79.4278415111,
				43.6437643254
			],
			[
				-79.4269203718,
				43.6426702351
			],
			[
				-79.4258966033,
				43.6425985922
			],
			[
				-79.4249282476,
				43.6427406439
			],
			[
				-79.4236862977,
				43.6433022173
			],
			[
				-79.4191601528,
				43.6439968784
			],
			[
				-79.4120393006,
				43.6456837847
			],
			[
				-79.410737,
				43.645787
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4294052824,
				43.644348698
			],
			[
				-79.4282017106,
				43.6449205946
			],
			[
				-79.4284772813,
				43.6452520674
			],
			[
				-79.4233688562,
				43.6462054737
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4224385474,
				43.6535000024
			],
			[
				-79.4214982059,
				43.6514451069
			],
			[
				-79.4200013339,
				43.6470772318
			],
			[
				-79.4196038084,
				43.6467956666
			],
			[
				-79.4191721091,
				43.645089051
			],
			[
				-79.4189129497,
				43.6445574762
			],
			[
				-79.4183627878,
				43.6442492311
			],
			[
				-79.4115823359,
				43.6456755779
			],
			[
				-79.410062098,
				43.6458315346
			],
			[
				-79.4096252195,
				43.6452454324
			],
			[
				-79.4042575936,
				43.6463256676
			],
			[
				-79.4036759296,
				43.6461084011
			],
			[
				-79.4030649495,
				43.6449816276
			],
			[
				-79.4022320601,
				43.6454271403
			],
			[
				-79.398214166,
				43.646022186
			],
			[
				-79.3976971935,
				43.6455307274
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4258613854,
				43.6617259227
			],
			[
				-79.4253115576,
				43.6611804948
			],
			[
				-79.4228670663,
				43.6548199837
			],
			[
				-79.4228996042,
				43.6544624654
			],
			[
				-79.4235650596,
				43.654118054
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4832504613,
				43.6501793778
			],
			[
				-79.4844237685,
				43.6498838791
			],
			[
				-79.4848772212,
				43.6490866571
			],
			[
				-79.4858432407,
				43.6483405606
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3570308285,
				43.6762867758
			],
			[
				-79.3579567239,
				43.6754996757
			],
			[
				-79.3584799549,
				43.6758931951
			],
			[
				-79.3588388708,
				43.6766617276
			],
			[
				-79.3603253209,
				43.677159322
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3594819378,
				43.6764130452
			],
			[
				-79.3585480433,
				43.6767418241
			],
			[
				-79.3587811054,
				43.6763441463
			],
			[
				-79.3570308285,
				43.6762867758
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4226194265,
				43.6539790252
			],
			[
				-79.4225375648,
				43.6543689567
			],
			[
				-79.4236935838,
				43.6566385735
			],
			[
				-79.4235120852,
				43.6568330542
			],
			[
				-79.4239541888,
				43.6575967797
			],
			[
				-79.4241518603,
				43.6586039787
			],
			[
				-79.4260820748,
				43.6624932868
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3241905461,
				43.6674681028
			],
			[
				-79.3269968683,
				43.6679583775
			],
			[
				-79.3277234428,
				43.6707018993
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3242200266,
				43.6717677294
			],
			[
				-79.3249852334,
				43.6714411218
			],
			[
				-79.3237877893,
				43.6684369437
			],
			[
				-79.3239870855,
				43.6677698545
			],
			[
				-79.3243720611,
				43.6676974608
			],
			[
				-79.3241905461,
				43.6674681028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3218415421,
				43.6724437889
			],
			[
				-79.3242198795,
				43.6717677653
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3323129674,
				43.6628144827
			],
			[
				-79.330445559,
				43.6633605737
			],
			[
				-79.33132031,
				43.6665743629
			],
			[
				-79.3300539975,
				43.6670124586
			],
			[
				-79.3280235711,
				43.6681707564
			],
			[
				-79.3283258525,
				43.6689712263
			],
			[
				-79.3277952773,
				43.6696618623
			],
			[
				-79.3280139639,
				43.6709596104
			],
			[
				-79.3218415421,
				43.6724437889
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3351907475,
				43.6622358139
			],
			[
				-79.3323129674,
				43.6628144827
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3241905461,
				43.6674681028
			],
			[
				-79.3245229466,
				43.6666914048
			],
			[
				-79.3264245553,
				43.66557316
			],
			[
				-79.3263562545,
				43.6645469028
			],
			[
				-79.3266256619,
				43.6642677243
			],
			[
				-79.3351907475,
				43.6622358139
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4115184314,
				43.6650647059
			],
			[
				-79.4135721859,
				43.6646312985
			],
			[
				-79.4123856342,
				43.6617168447
			],
			[
				-79.4124465959,
				43.6610860034
			],
			[
				-79.4241300109,
				43.6585044061
			],
			[
				-79.4240736192,
				43.6577591403
			],
			[
				-79.4226287396,
				43.6541976159
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.359114736,
				43.6753465344
			],
			[
				-79.3585321854,
				43.6758713253
			],
			[
				-79.3584758692,
				43.6766536278
			],
			[
				-79.3585323957,
				43.6763668383
			],
			[
				-79.3570308285,
				43.6762867758
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4263105683,
				43.6623804829
			],
			[
				-79.4254477878,
				43.6625531895
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4056426911,
				43.653417772
			],
			[
				-79.4060180571,
				43.6533716933
			],
			[
				-79.4061193424,
				43.6528021559
			],
			[
				-79.4058316972,
				43.6525158306
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4234361063,
				43.6541650307
			],
			[
				-79.4217810116,
				43.6546064149
			],
			[
				-79.4185057763,
				43.6551654995
			],
			[
				-79.4148764606,
				43.6550756251
			],
			[
				-79.4078783692,
				43.6562649108
			],
			[
				-79.4075443502,
				43.6559248252
			],
			[
				-79.4070426655,
				43.6544164642
			],
			[
				-79.4056426911,
				43.653417772
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3979686812,
				43.645511281
			],
			[
				-79.3977337836,
				43.6458225207
			],
			[
				-79.3981419432,
				43.6461875975
			],
			[
				-79.3986766107,
				43.6462379955
			],
			[
				-79.4005235613,
				43.6456312626
			],
			[
				-79.4010015813,
				43.6465772547
			],
			[
				-79.4017043349,
				43.6468247849
			],
			[
				-79.4085813842,
				43.6455288601
			],
			[
				-79.409283594,
				43.645545459
			],
			[
				-79.4102077537,
				43.6459880271
			],
			[
				-79.4121449841,
				43.6457493235
			],
			[
				-79.4144909468,
				43.6467678239
			],
			[
				-79.4170626812,
				43.6471309077
			],
			[
				-79.4180530829,
				43.6488046687
			],
			[
				-79.417982149,
				43.649565428
			],
			[
				-79.4184500189,
				43.6497416844
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4116287444,
				43.6435790457
			],
			[
				-79.4154844778,
				43.6429139829
			],
			[
				-79.4164827289,
				43.644052407
			],
			[
				-79.4179941727,
				43.6443074686
			],
			[
				-79.4265770173,
				43.6426641516
			],
			[
				-79.4269021616,
				43.6426898319
			],
			[
				-79.4275000908,
				43.6433247867
			],
			[
				-79.4276973176,
				43.6440186039
			],
			[
				-79.4288264399,
				43.643855911
			],
			[
				-79.4293501422,
				43.6443218362
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.397955293,
				43.6457964356
			],
			[
				-79.3981524661,
				43.6461285609
			],
			[
				-79.3992782796,
				43.6461804476
			],
			[
				-79.4039554685,
				43.6448862924
			],
			[
				-79.4074784224,
				43.6444435565
			],
			[
				-79.4116287444,
				43.6435790457
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3241905461,
				43.6674681028
			],
			[
				-79.3244019725,
				43.6676160446
			],
			[
				-79.324340487,
				43.6669696593
			],
			[
				-79.3245612196,
				43.6667030596
			],
			[
				-79.3264260851,
				43.6661133841
			],
			[
				-79.3265972054,
				43.6658100585
			],
			[
				-79.3264441036,
				43.664392593
			],
			[
				-79.328053,
				43.6636
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.330667667,
				43.663280764
			],
			[
				-79.3266442935,
				43.6641074612
			],
			[
				-79.3263640634,
				43.6643089247
			],
			[
				-79.3265983083,
				43.6654259946
			],
			[
				-79.3264608746,
				43.665903778
			],
			[
				-79.3259265921,
				43.6663932615
			],
			[
				-79.324605862,
				43.666750263
			],
			[
				-79.3246974284,
				43.6672730161
			],
			[
				-79.3241905461,
				43.6674681028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.330667667,
				43.663280764
			],
			[
				-79.3396767986,
				43.661152356
			],
			[
				-79.3338962315,
				43.6627378979
			],
			[
				-79.3339698355,
				43.6625580357
			],
			[
				-79.3335732607,
				43.6625763033
			],
			[
				-79.330667667,
				43.663280764
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3379380429,
				43.661455813
			],
			[
				-79.3372100583,
				43.6618083769
			],
			[
				-79.330667667,
				43.663280764
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3396974237,
				43.6611562767
			],
			[
				-79.339192063,
				43.6614209698
			],
			[
				-79.3379380429,
				43.661455813
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4115868468,
				43.6435477302
			],
			[
				-79.4045494458,
				43.6448216154
			],
			[
				-79.4044334418,
				43.6452458328
			],
			[
				-79.4038325993,
				43.6453488059
			],
			[
				-79.4031398033,
				43.6450613474
			],
			[
				-79.3983644939,
				43.6462485516
			],
			[
				-79.3980208683,
				43.6462078977
			],
			[
				-79.3979047999,
				43.6456319212
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.422484241,
				43.6534769675
			],
			[
				-79.4220244572,
				43.652732874
			],
			[
				-79.4187351906,
				43.6441318838
			],
			[
				-79.4173323793,
				43.6443546468
			],
			[
				-79.4170489646,
				43.644632852
			],
			[
				-79.4162621308,
				43.6445276803
			],
			[
				-79.4156729721,
				43.6432959117
			],
			[
				-79.4149613011,
				43.6429021176
			],
			[
				-79.4119410755,
				43.6432198961
			],
			[
				-79.4115868468,
				43.6435477302
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4254558687,
				43.6529818724
			],
			[
				-79.4259517145,
				43.6525704979
			],
			[
				-79.4249095978,
				43.6495720485
			],
			[
				-79.4297194959,
				43.6495514608
			],
			[
				-79.4290219144,
				43.6468561938
			],
			[
				-79.4282407257,
				43.6451356135
			],
			[
				-79.4294199545,
				43.6444719258
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4509214639,
				43.6509987116
			],
			[
				-79.4499043378,
				43.6490368302
			],
			[
				-79.4485983324,
				43.6450918269
			],
			[
				-79.4477684172,
				43.6446659972
			],
			[
				-79.4458699518,
				43.6451517806
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4511717103,
				43.653517235
			],
			[
				-79.4516644416,
				43.6536219228
			],
			[
				-79.4516292266,
				43.6527499365
			],
			[
				-79.4509214639,
				43.6509987116
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4259657851,
				43.6527078107
			],
			[
				-79.4249026384,
				43.6495866048
			],
			[
				-79.4297301473,
				43.6495132952
			],
			[
				-79.4291515046,
				43.6470555971
			],
			[
				-79.4299455725,
				43.6470361372
			],
			[
				-79.4301777673,
				43.6467052952
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3528413842,
				43.6654905938
			],
			[
				-79.3516128074,
				43.6631561923
			],
			[
				-79.3516009414,
				43.6624909645
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4296577802,
				43.6446432255
			],
			[
				-79.4283842622,
				43.6448280891
			],
			[
				-79.4279111015,
				43.645226698
			],
			[
				-79.4234723401,
				43.6463570224
			],
			[
				-79.4246315489,
				43.6488798622
			],
			[
				-79.4252433695,
				43.6510892019
			],
			[
				-79.4257196257,
				43.651690134
			],
			[
				-79.4259342734,
				43.6527181088
			],
			[
				-79.4233897632,
				43.6532376406
			],
			[
				-79.41939,
				43.654712
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4176705004,
				43.6500043944
			],
			[
				-79.4174200945,
				43.6500672013
			],
			[
				-79.4203529652,
				43.6493622662
			],
			[
				-79.4206304621,
				43.6492451471
			],
			[
				-79.420682,
				43.648912
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4056426911,
				43.653417772
			],
			[
				-79.4061555415,
				43.6532725163
			],
			[
				-79.405995885,
				43.6523957937
			],
			[
				-79.4065700773,
				43.65217239
			],
			[
				-79.4176705004,
				43.6500043944
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.42228341,
				43.6532543531
			],
			[
				-79.4214379814,
				43.6508825752
			],
			[
				-79.4178904077,
				43.6515676122
			],
			[
				-79.4172221615,
				43.6504369562
			],
			[
				-79.4167132102,
				43.6501696777
			],
			[
				-79.4066572107,
				43.6521698885
			],
			[
				-79.4061954659,
				43.652413182
			],
			[
				-79.4061139124,
				43.6529239192
			],
			[
				-79.4057009849,
				43.6530621345
			],
			[
				-79.4056426911,
				43.653417772
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4056426911,
				43.653417772
			],
			[
				-79.4065822379,
				43.6523114974
			],
			[
				-79.407189524,
				43.6521124971
			],
			[
				-79.4181773677,
				43.6499070995
			],
			[
				-79.4184263231,
				43.6500092953
			],
			[
				-79.4188002291,
				43.6513319026
			],
			[
				-79.4212688722,
				43.6511920815
			],
			[
				-79.422382334,
				43.6532968568
			],
			[
				-79.4234547594,
				43.653544998
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.413526,
				43.655521
			],
			[
				-79.4124689393,
				43.6554366785
			],
			[
				-79.4117951361,
				43.6557489519
			],
			[
				-79.4088530829,
				43.6562613922
			],
			[
				-79.4079036969,
				43.6561177523
			],
			[
				-79.4071455261,
				43.6550120458
			],
			[
				-79.4069450316,
				43.6542242341
			],
			[
				-79.4053451638,
				43.6532164119
			],
			[
				-79.4059803213,
				43.6531433137
			],
			[
				-79.405352621,
				43.6527017471
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3828557007,
				43.6448484087
			],
			[
				-79.3834958454,
				43.6449549637
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5222416902,
				44.473346646
			],
			[
				-79.5227713197,
				44.4740380709
			],
			[
				-79.5232003031,
				44.4740104156
			],
			[
				-79.5280621719,
				44.4715825269
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5203699051,
				44.4708625379
			],
			[
				-79.5202903006,
				44.4712203939
			],
			[
				-79.5222416902,
				44.473346646
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5212005061,
				44.479940661
			],
			[
				-79.5201181506,
				44.4790492674
			],
			[
				-79.5197514031,
				44.478286939
			],
			[
				-79.5189123124,
				44.4775464052
			],
			[
				-79.5205089078,
				44.4776680495
			],
			[
				-79.5216083725,
				44.475409381
			],
			[
				-79.5226801742,
				44.4742101768
			],
			[
				-79.5205758481,
				44.4714831057
			],
			[
				-79.5203699051,
				44.4708625379
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.520407781,
				44.4774560474
			],
			[
				-79.5210102857,
				44.4787171393
			],
			[
				-79.5209539198,
				44.4799008163
			],
			[
				-79.5212005061,
				44.479940661
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5212800163,
				44.4750055615
			],
			[
				-79.5218907584,
				44.4750038667
			],
			[
				-79.5208124602,
				44.4771207127
			],
			[
				-79.520473506,
				44.4773987739
			],
			[
				-79.5195500471,
				44.4773920651
			],
			[
				-79.5200089316,
				44.479062324
			],
			[
				-79.5205196745,
				44.4794921654
			],
			[
				-79.5198058006,
				44.478167497
			],
			[
				-79.520407781,
				44.4774560474
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5217228055,
				44.480792435
			],
			[
				-79.5211497868,
				44.479205815
			],
			[
				-79.5225830938,
				44.4781723902
			],
			[
				-79.5188492928,
				44.4778229602
			],
			[
				-79.5187750482,
				44.4774726498
			],
			[
				-79.5211776665,
				44.4762499758
			],
			[
				-79.5217940509,
				44.4748265998
			],
			[
				-79.5219179893,
				44.4749530548
			],
			[
				-79.5212800163,
				44.4750055615
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5200609667,
				44.4771979197
			],
			[
				-79.5217193706,
				44.4779702599
			],
			[
				-79.5220087207,
				44.4778475924
			],
			[
				-79.5217228055,
				44.480792435
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5201420459,
				44.4776972692
			],
			[
				-79.5205046009,
				44.4775078234
			],
			[
				-79.5199803148,
				44.4774628312
			],
			[
				-79.52034579,
				44.4771728697
			],
			[
				-79.5210240336,
				44.4752863059
			],
			[
				-79.5224384066,
				44.473746819
			],
			[
				-79.5207364012,
				44.4716809547
			],
			[
				-79.5203699051,
				44.4708625379
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5203699051,
				44.4708625379
			],
			[
				-79.5202723237,
				44.4710661836
			],
			[
				-79.5222179256,
				44.4735285712
			],
			[
				-79.5200637427,
				44.4785541362
			],
			[
				-79.5202300304,
				44.4787976357
			],
			[
				-79.5219681679,
				44.4786703498
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5201420459,
				44.4776972692
			],
			[
				-79.5196177927,
				44.4772849524
			],
			[
				-79.5202660117,
				44.4768942258
			],
			[
				-79.5213034458,
				44.4755328108
			],
			[
				-79.5211580799,
				44.4753680576
			],
			[
				-79.521399858,
				44.4749240957
			],
			[
				-79.5219244817,
				44.474642903
			],
			[
				-79.5217161808,
				44.4728007836
			],
			[
				-79.5203699051,
				44.4708625379
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5158297276,
				44.4795687888
			],
			[
				-79.5165652252,
				44.4793902138
			],
			[
				-79.516155509,
				44.4795465825
			],
			[
				-79.5179385686,
				44.478482036
			],
			[
				-79.5201420459,
				44.4776972692
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5215891148,
				44.4771343299
			],
			[
				-79.5158297276,
				44.4795687888
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5217228055,
				44.480792435
			],
			[
				-79.5219340311,
				44.4804602676
			],
			[
				-79.5198294486,
				44.4785938887
			],
			[
				-79.5215891148,
				44.4771343299
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5203699051,
				44.4708625379
			],
			[
				-79.5204026344,
				44.4712318365
			],
			[
				-79.5221560176,
				44.4733661477
			],
			[
				-79.5204626938,
				44.4765704236
			],
			[
				-79.5199066249,
				44.4772586452
			],
			[
				-79.5195003396,
				44.4773760456
			],
			[
				-79.5209949559,
				44.4800952236
			],
			[
				-79.5217228055,
				44.480792435
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5206284482,
				44.47659484
			],
			[
				-79.5186536903,
				44.4777145395
			],
			[
				-79.521008891,
				44.4764829523
			],
			[
				-79.5225738806,
				44.4739166882
			],
			[
				-79.5203699051,
				44.4708625379
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5203699051,
				44.4708625379
			],
			[
				-79.5204843063,
				44.4714445178
			],
			[
				-79.5220217122,
				44.4733024629
			],
			[
				-79.5220583001,
				44.4736648026
			],
			[
				-79.5210396133,
				44.4751496958
			],
			[
				-79.5211679516,
				44.4763451071
			],
			[
				-79.5206284482,
				44.47659484
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5219397796,
				44.4729986201
			],
			[
				-79.5211986721,
				44.4723396999
			],
			[
				-79.5203699051,
				44.4708625379
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5695045944,
				44.1127890241
			],
			[
				-79.5690365479,
				44.1132079546
			],
			[
				-79.5678003657,
				44.1136042435
			],
			[
				-79.5640785349,
				44.1144476572
			],
			[
				-79.5637751144,
				44.1142290863
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5737847542,
				44.1127579903
			],
			[
				-79.5731166236,
				44.1123784433
			],
			[
				-79.5702487794,
				44.1129314374
			],
			[
				-79.5695045944,
				44.1127890241
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.550456328,
				44.1145532871
			],
			[
				-79.5499952629,
				44.1143933127
			],
			[
				-79.5552319049,
				44.1156565545
			],
			[
				-79.5593311727,
				44.115942026
			],
			[
				-79.5594313881,
				44.1156527727
			],
			[
				-79.5603644934,
				44.115299576
			],
			[
				-79.5648348337,
				44.114393386
			],
			[
				-79.5695283106,
				44.11310573
			],
			[
				-79.5707728603,
				44.1130570489
			],
			[
				-79.5730960759,
				44.112408191
			],
			[
				-79.5740136198,
				44.112740109
			],
			[
				-79.5737847542,
				44.1127579903
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5467306195,
				44.1135977916
			],
			[
				-79.550456328,
				44.1145532871
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3127205477,
				43.6672768352
			],
			[
				-79.3130638082,
				43.6681141551
			],
			[
				-79.3139174089,
				43.6683185604
			],
			[
				-79.3145289544,
				43.6689433999
			],
			[
				-79.3156384913,
				43.6691192619
			],
			[
				-79.3205770802,
				43.6683095194
			],
			[
				-79.3219586503,
				43.6677551495
			],
			[
				-79.3238937974,
				43.6677139677
			],
			[
				-79.3241905461,
				43.6674681028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3251239407,
				43.6676289129
			],
			[
				-79.3241176573,
				43.6673810963
			],
			[
				-79.3235139317,
				43.6676774076
			],
			[
				-79.3191277079,
				43.6684664213
			],
			[
				-79.318325845,
				43.6676633804
			],
			[
				-79.3187234606,
				43.6684239441
			],
			[
				-79.3196503761,
				43.6684324568
			],
			[
				-79.3241905461,
				43.6674681028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4087175429,
				43.6518483635
			],
			[
				-79.4071676734,
				43.6521461529
			],
			[
				-79.408601963,
				43.6517851463
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4170640046,
				43.6364356326
			],
			[
				-79.4179025305,
				43.6362776103
			],
			[
				-79.4185938861,
				43.6357738711
			],
			[
				-79.4196217062,
				43.6362808009
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6666013265,
				43.4472663432
			],
			[
				-79.6664886083,
				43.4474847701
			],
			[
				-79.6672645579,
				43.4477016849
			],
			[
				-79.6692371389,
				43.4490274282
			],
			[
				-79.669336788,
				43.4493351769
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.420682,
				43.648912
			],
			[
				-79.4208010015,
				43.6492473815
			],
			[
				-79.4205912368,
				43.6495751593
			],
			[
				-79.4196599052,
				43.6498485732
			],
			[
				-79.4192231172,
				43.6504125607
			],
			[
				-79.4194231627,
				43.6511138984
			],
			[
				-79.4191695851,
				43.6515490274
			],
			[
				-79.4192373744,
				43.652126486
			],
			[
				-79.4198089143,
				43.6543334343
			],
			[
				-79.41939,
				43.654712
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4224910277,
				43.6534292455
			],
			[
				-79.4205924263,
				43.6494013455
			],
			[
				-79.420682,
				43.648912
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3846553308,
				43.6651640845
			],
			[
				-79.3850878034,
				43.6647504662
			],
			[
				-79.3855509267,
				43.6646774445
			],
			[
				-79.3855664745,
				43.6651535625
			],
			[
				-79.3869965927,
				43.665517907
			],
			[
				-79.3872503225,
				43.6658671095
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3828376604,
				43.6614263212
			],
			[
				-79.3833335379,
				43.6617575238
			],
			[
				-79.3836251407,
				43.6630938609
			],
			[
				-79.3838346768,
				43.663159148
			],
			[
				-79.3846553308,
				43.6651640845
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3846553308,
				43.6651640845
			],
			[
				-79.3836441539,
				43.6641145309
			],
			[
				-79.3839568402,
				43.6637563785
			],
			[
				-79.383637298,
				43.6625798026
			],
			[
				-79.3829718619,
				43.6613699835
			],
			[
				-79.3826554613,
				43.6613767804
			],
			[
				-79.3828376604,
				43.6614263212
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3872592118,
				43.6657458757
			],
			[
				-79.3852911617,
				43.6660784884
			],
			[
				-79.3846553308,
				43.6651640845
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.429260334,
				43.6495296177
			],
			[
				-79.4206801748,
				43.6492608552
			],
			[
				-79.4184081321,
				43.6497686038
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4184500189,
				43.6497416844
			],
			[
				-79.4207842735,
				43.6492982471
			],
			[
				-79.4337862198,
				43.6498563786
			],
			[
				-79.4354348723,
				43.6498104579
			],
			[
				-79.435587902,
				43.6494888815
			],
			[
				-79.4349153347,
				43.6482920207
			],
			[
				-79.4354483652,
				43.6495310696
			],
			[
				-79.4353836308,
				43.64983727
			],
			[
				-79.429260334,
				43.6495296177
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4138363354,
				43.6490896356
			],
			[
				-79.4163037553,
				43.6501616695
			],
			[
				-79.4184500189,
				43.6497416844
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4184500189,
				43.6497416844
			],
			[
				-79.4166355362,
				43.6499891116
			],
			[
				-79.4162494363,
				43.6497036098
			],
			[
				-79.4137106309,
				43.6490529372
			],
			[
				-79.413664341,
				43.6485287025
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.423147,
				43.6492942739
			],
			[
				-79.4226758313,
				43.6495699988
			],
			[
				-79.4206528852,
				43.6493493551
			],
			[
				-79.4184500189,
				43.6497416844
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4227368375,
				43.6534645315
			],
			[
				-79.4223180765,
				43.653196183
			],
			[
				-79.4209837898,
				43.649711825
			],
			[
				-79.4213015813,
				43.6493994817
			],
			[
				-79.4220744272,
				43.6492686561
			],
			[
				-79.4235483808,
				43.6494482682
			],
			[
				-79.423147,
				43.6492942739
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3279509686,
				43.6636928509
			],
			[
				-79.3263650831,
				43.6642460377
			],
			[
				-79.3265680561,
				43.6656830968
			],
			[
				-79.325700505,
				43.6663224614
			],
			[
				-79.3243207727,
				43.6667853535
			],
			[
				-79.3241905461,
				43.6674681028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3259017164,
				43.6658529443
			],
			[
				-79.3256114778,
				43.665509042
			],
			[
				-79.3257557875,
				43.6645059676
			],
			[
				-79.3279509686,
				43.6636928509
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4002774505,
				43.6446209037
			],
			[
				-79.3998889838,
				43.6449260787
			],
			[
				-79.4002360544,
				43.6450275861
			],
			[
				-79.4003768601,
				43.645695714
			],
			[
				-79.4013391326,
				43.6469435993
			],
			[
				-79.4012127289,
				43.6472884412
			],
			[
				-79.4019546183,
				43.6475949861
			],
			[
				-79.404367909,
				43.6472343388
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.404024,
				43.647421
			],
			[
				-79.4037744664,
				43.6472136263
			],
			[
				-79.4013522316,
				43.6476545473
			],
			[
				-79.4012741106,
				43.6468373639
			],
			[
				-79.400546335,
				43.6457703499
			],
			[
				-79.4002774505,
				43.6446209037
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.403647,
				43.647187
			],
			[
				-79.4060113125,
				43.6468111862
			],
			[
				-79.404024,
				43.647421
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4294617339,
				43.6441933855
			],
			[
				-79.429120432,
				43.6438934845
			],
			[
				-79.4279074373,
				43.6440952182
			],
			[
				-79.4280818223,
				43.6450926312
			],
			[
				-79.4278668327,
				43.6452588233
			],
			[
				-79.4233688562,
				43.6462054737
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.315493,
				43.6665859711
			],
			[
				-79.315283014,
				43.6664773313
			],
			[
				-79.3153193089,
				43.6655507433
			],
			[
				-79.3153951149,
				43.665727836
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3461143228,
				43.6350418077
			],
			[
				-79.3454885754,
				43.6362204404
			],
			[
				-79.3442903873,
				43.636877364
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.344047283,
				43.6373143426
			],
			[
				-79.3450974464,
				43.6366472558
			],
			[
				-79.3461143228,
				43.6350418077
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3728656559,
				43.642623181
			],
			[
				-79.3720725102,
				43.6431480694
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4236436536,
				43.6494930956
			],
			[
				-79.4229834368,
				43.6489431312
			],
			[
				-79.4226720216,
				43.6481616437
			],
			[
				-79.422597951,
				43.6475261981
			],
			[
				-79.4235393195,
				43.6468856965
			],
			[
				-79.4232806887,
				43.6464769629
			],
			[
				-79.4231502991,
				43.6453343442
			],
			[
				-79.4223687333,
				43.6437597444
			],
			[
				-79.4227421774,
				43.6434256119
			],
			[
				-79.4241889621,
				43.643015857
			],
			[
				-79.4269631421,
				43.6426363545
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4236901142,
				43.6535900294
			],
			[
				-79.4234057961,
				43.6533424198
			],
			[
				-79.4225298246,
				43.6533830763
			],
			[
				-79.42223966,
				43.6530337175
			],
			[
				-79.4208475499,
				43.649340759
			],
			[
				-79.4236436536,
				43.6494930956
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6921913344,
				44.3891918091
			],
			[
				-79.690928673,
				44.3886186561
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4225358401,
				43.6533967359
			],
			[
				-79.42222884,
				43.6530191035
			],
			[
				-79.4199435797,
				43.6472037681
			],
			[
				-79.4208963639,
				43.6475158273
			],
			[
				-79.4237266543,
				43.6470965075
			],
			[
				-79.4243857423,
				43.6478113601
			],
			[
				-79.4268405711,
				43.6489389133
			],
			[
				-79.4268368542,
				43.6487478282
			],
			[
				-79.4273563242,
				43.6486244683
			],
			[
				-79.4281312113,
				43.6489685388
			],
			[
				-79.4291685223,
				43.6488330671
			],
			[
				-79.4299604161,
				43.6491251683
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3929898739,
				43.6460599377
			],
			[
				-79.3926642176,
				43.6460345069
			],
			[
				-79.392545491,
				43.646892596
			],
			[
				-79.39318,
				43.647988
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4226330902,
				43.6534035362
			],
			[
				-79.4222270363,
				43.6530693947
			],
			[
				-79.419634,
				43.646288
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4202968455,
				43.6468278626
			],
			[
				-79.4199345219,
				43.6469430679
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42164,
				43.643725
			],
			[
				-79.4201911143,
				43.644334041
			],
			[
				-79.4205141823,
				43.6457535097
			],
			[
				-79.4196301236,
				43.6462575699
			],
			[
				-79.4199484,
				43.6475516847
			],
			[
				-79.4206761859,
				43.6490577786
			],
			[
				-79.4203185495,
				43.6494337752
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.423247945,
				43.643230856
			],
			[
				-79.423453111,
				43.6432242352
			],
			[
				-79.4223751093,
				43.6435031466
			],
			[
				-79.4236871138,
				43.6443244895
			],
			[
				-79.42164,
				43.643725
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.421943,
				43.649291
			],
			[
				-79.4212224411,
				43.6493640202
			],
			[
				-79.4208885076,
				43.6496287997
			],
			[
				-79.4208577897,
				43.6494278055
			],
			[
				-79.4204825005,
				43.6493614835
			],
			[
				-79.4208234154,
				43.6491849334
			],
			[
				-79.4199026135,
				43.6469958812
			],
			[
				-79.4198368709,
				43.6462191161
			],
			[
				-79.422632351,
				43.6452550721
			],
			[
				-79.4229043156,
				43.644703181
			],
			[
				-79.4227280089,
				43.6435609738
			],
			[
				-79.423247945,
				43.643230856
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4246494053,
				43.6494957011
			],
			[
				-79.423067534,
				43.6493728706
			],
			[
				-79.4245250394,
				43.6492440585
			],
			[
				-79.421943,
				43.649291
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4177603638,
				43.6482365554
			],
			[
				-79.4179655709,
				43.6486604733
			],
			[
				-79.4182456536,
				43.6487176957
			],
			[
				-79.4201425189,
				43.6484514433
			],
			[
				-79.4208516734,
				43.6496378657
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.415240627,
				43.6451729374
			],
			[
				-79.4163971673,
				43.6453969329
			],
			[
				-79.4177603638,
				43.6482365554
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.409223972,
				43.6463222448
			],
			[
				-79.4147894967,
				43.6450703334
			],
			[
				-79.415240627,
				43.6451729374
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4225106626,
				43.6534647684
			],
			[
				-79.421698032,
				43.6516255723
			],
			[
				-79.4212104395,
				43.6510327161
			],
			[
				-79.4190793342,
				43.6513426786
			],
			[
				-79.4183052078,
				43.649924248
			],
			[
				-79.4128558637,
				43.6507856614
			],
			[
				-79.4108943986,
				43.6460733473
			],
			[
				-79.4103502637,
				43.6459807622
			],
			[
				-79.409223972,
				43.6463222448
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4293922376,
				43.6441580811
			],
			[
				-79.4284597886,
				43.6417877181
			],
			[
				-79.4243919835,
				43.6407409132
			],
			[
				-79.4236383871,
				43.6400517342
			],
			[
				-79.4236596287,
				43.6396974043
			],
			[
				-79.4213279216,
				43.6399979461
			],
			[
				-79.4209809656,
				43.63957528
			],
			[
				-79.4208901396,
				43.638903553
			],
			[
				-79.4200118285,
				43.6380751545
			],
			[
				-79.4200218518,
				43.6375535104
			],
			[
				-79.4197044998,
				43.6381078382
			],
			[
				-79.4207142706,
				43.6395070634
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4212818146,
				43.6396158283
			],
			[
				-79.4213459856,
				43.640291614
			],
			[
				-79.4232675606,
				43.6397195325
			],
			[
				-79.4241971236,
				43.6408167385
			],
			[
				-79.4263058412,
				43.641143139
			],
			[
				-79.4282756626,
				43.641941712
			],
			[
				-79.4274678956,
				43.6426202651
			],
			[
				-79.4272386537,
				43.6424775498
			],
			[
				-79.4265069943,
				43.642770439
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.420282052,
				43.6374240298
			],
			[
				-79.4203565891,
				43.6379039415
			],
			[
				-79.4200310787,
				43.6382224537
			],
			[
				-79.4205586463,
				43.6385525383
			],
			[
				-79.4207950376,
				43.6394092938
			],
			[
				-79.4212818146,
				43.6396158283
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.419634,
				43.646288
			],
			[
				-79.4187009106,
				43.6440849107
			],
			[
				-79.416555824,
				43.6443819364
			],
			[
				-79.4158158798,
				43.6433414984
			],
			[
				-79.4152318989,
				43.6418229204
			],
			[
				-79.4131232342,
				43.6407933844
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.41047,
				43.64592
			],
			[
				-79.4112442951,
				43.6456712405
			],
			[
				-79.4134007075,
				43.6462976966
			],
			[
				-79.4143410935,
				43.6468724636
			],
			[
				-79.4162414707,
				43.6471547922
			],
			[
				-79.4168502798,
				43.6470084766
			],
			[
				-79.4176672957,
				43.6473381257
			],
			[
				-79.4184352058,
				43.6473144794
			],
			[
				-79.419634,
				43.646288
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4131232342,
				43.6407933844
			],
			[
				-79.413484327,
				43.6409621336
			],
			[
				-79.4128729129,
				43.6413142784
			],
			[
				-79.4128106465,
				43.6417819711
			],
			[
				-79.4120033305,
				43.6419295391
			],
			[
				-79.4121305536,
				43.642727794
			],
			[
				-79.4131874365,
				43.6451417618
			],
			[
				-79.41047,
				43.64592
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5799877442,
				43.5537619101
			],
			[
				-79.5804418243,
				43.5544795189
			],
			[
				-79.581568538,
				43.5552283772
			],
			[
				-79.5821254945,
				43.5546854236
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5824760816,
				43.5543968574
			],
			[
				-79.5819220283,
				43.5550617002
			],
			[
				-79.5812878132,
				43.5548370148
			],
			[
				-79.5799877442,
				43.5537619101
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6511344129,
				43.5358382775
			],
			[
				-79.6493565954,
				43.5350834976
			],
			[
				-79.6485353716,
				43.5350821517
			],
			[
				-79.6475696331,
				43.5354559305
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4284099653,
				43.641989964
			],
			[
				-79.4272895365,
				43.6425160096
			],
			[
				-79.4190716743,
				43.6440258477
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4355765241,
				43.6407683426
			],
			[
				-79.4358497297,
				43.6403981526
			],
			[
				-79.4355600655,
				43.6408303593
			],
			[
				-79.4334035659,
				43.641258712
			],
			[
				-79.4359347061,
				43.6406692839
			],
			[
				-79.4357449207,
				43.6406258577
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4202622806,
				43.6479851334
			],
			[
				-79.420787409,
				43.6488880192
			],
			[
				-79.4207882404,
				43.6491777346
			],
			[
				-79.4203185495,
				43.6494337752
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4229742345,
				43.6533591325
			],
			[
				-79.4224323955,
				43.6528859129
			],
			[
				-79.4215592473,
				43.6514127456
			],
			[
				-79.4202622806,
				43.6479851334
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4225084315,
				43.6534476836
			],
			[
				-79.4223043073,
				43.6532096804
			],
			[
				-79.421760564,
				43.65217894
			],
			[
				-79.4208940908,
				43.6493853524
			],
			[
				-79.4216363771,
				43.649327943
			],
			[
				-79.4208491436,
				43.6490511731
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.421943,
				43.649291
			],
			[
				-79.4209418769,
				43.6494507588
			],
			[
				-79.4209125353,
				43.6496205456
			],
			[
				-79.4214089292,
				43.6503390981
			],
			[
				-79.4215153424,
				43.6516110125
			],
			[
				-79.4221470981,
				43.6527297346
			],
			[
				-79.422394023,
				43.6542028431
			],
			[
				-79.423175534,
				43.6551858796
			],
			[
				-79.4254317802,
				43.6618406936
			],
			[
				-79.4246064017,
				43.658899176
			],
			[
				-79.423266265,
				43.6559408062
			],
			[
				-79.4224817927,
				43.6549189569
			],
			[
				-79.423057479,
				43.6542665
			],
			[
				-79.4237271331,
				43.6541845569
			],
			[
				-79.4233798113,
				43.6537523976
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4203185495,
				43.6494337752
			],
			[
				-79.4217271479,
				43.6495100007
			],
			[
				-79.421943,
				43.649291
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.402073,
				43.654751
			],
			[
				-79.4019998305,
				43.6538319528
			],
			[
				-79.4024980953,
				43.6532063269
			],
			[
				-79.4203185495,
				43.6494337752
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.402698383,
				43.656533263
			],
			[
				-79.401866496,
				43.6541518091
			],
			[
				-79.402073,
				43.654751
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065883021,
				43.6538562331
			],
			[
				-79.4077326458,
				43.6562054434
			],
			[
				-79.4088396955,
				43.6561422787
			],
			[
				-79.4080399629,
				43.6556768271
			],
			[
				-79.4097728689,
				43.6562006455
			],
			[
				-79.4101543407,
				43.6560977679
			],
			[
				-79.4103798346,
				43.6564404213
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4022059441,
				43.6547855574
			],
			[
				-79.4023355662,
				43.6534811338
			],
			[
				-79.4030413784,
				43.6537271159
			],
			[
				-79.4036521889,
				43.6548951372
			],
			[
				-79.404735699,
				43.654760169
			],
			[
				-79.4048768124,
				43.654485273
			],
			[
				-79.4044206467,
				43.6540334074
			],
			[
				-79.4044474274,
				43.6532121157
			],
			[
				-79.405139774,
				43.6526012385
			],
			[
				-79.4060785424,
				43.6527800901
			],
			[
				-79.4063942,
				43.6538552663
			],
			[
				-79.4065883021,
				43.6538562331
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4097763525,
				43.6558973145
			],
			[
				-79.409635504,
				43.656219382
			],
			[
				-79.4077342817,
				43.6563321076
			],
			[
				-79.40381721,
				43.6570559883
			],
			[
				-79.4033040012,
				43.6569797982
			],
			[
				-79.403000316,
				43.6566612587
			],
			[
				-79.40203127,
				43.655124798
			],
			[
				-79.4022059441,
				43.6547855574
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4142989881,
				43.6561386727
			],
			[
				-79.4140841169,
				43.6554855538
			],
			[
				-79.4137343759,
				43.655325372
			],
			[
				-79.4118538993,
				43.6555011613
			],
			[
				-79.4097975618,
				43.6560669048
			],
			[
				-79.4097763525,
				43.6558973145
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4219487556,
				43.6493462884
			],
			[
				-79.4203185495,
				43.6494337752
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3143197399,
				43.664059701
			],
			[
				-79.3159960266,
				43.6656161902
			],
			[
				-79.3164614282,
				43.6665400015
			],
			[
				-79.317551347,
				43.666342125
			],
			[
				-79.3187806686,
				43.6684374825
			],
			[
				-79.3239513583,
				43.6676731963
			],
			[
				-79.3241905461,
				43.6674681028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4460511345,
				43.6390541764
			],
			[
				-79.4454823908,
				43.6389506869
			],
			[
				-79.4413443785,
				43.6396509727
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4082427417,
				43.6657810776
			],
			[
				-79.4102867108,
				43.6654950546
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4102954865,
				43.6654395354
			],
			[
				-79.4093285542,
				43.6654356231
			],
			[
				-79.4079632492,
				43.6658025361
			],
			[
				-79.4082427417,
				43.6657810776
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.394029146,
				43.6483477088
			],
			[
				-79.3935662351,
				43.6476064208
			],
			[
				-79.3939662788,
				43.6469480268
			],
			[
				-79.393806187,
				43.6462204892
			],
			[
				-79.3936875297,
				43.6459901181
			],
			[
				-79.3929898739,
				43.6460599377
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.8868776709,
				43.2528213485
			],
			[
				-79.8856892827,
				43.2553316553
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.7957887561,
				43.2996772137
			],
			[
				-79.7948179869,
				43.2981444391
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4357449207,
				43.6406258577
			],
			[
				-79.4357378579,
				43.6408815042
			],
			[
				-79.4331456454,
				43.6413082913
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4148237428,
				43.6498096854
			],
			[
				-79.4167777866,
				43.6500810046
			],
			[
				-79.4203185495,
				43.6494337752
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.413664341,
				43.6485287025
			],
			[
				-79.4148237428,
				43.6498096854
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4197249645,
				43.6469426259
			],
			[
				-79.4197288517,
				43.6473015289
			],
			[
				-79.4175811001,
				43.6478806371
			],
			[
				-79.417803101,
				43.6489935812
			],
			[
				-79.417535595,
				43.6494463795
			],
			[
				-79.4166015009,
				43.6493263498
			],
			[
				-79.4158701619,
				43.6498592667
			],
			[
				-79.414994648,
				43.6493087906
			],
			[
				-79.4135313645,
				43.6490059269
			],
			[
				-79.413664341,
				43.6485287025
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3909406662,
				43.6487150202
			],
			[
				-79.3913311293,
				43.6487597986
			],
			[
				-79.3912941957,
				43.6485996544
			],
			[
				-79.3909051689,
				43.6486486415
			],
			[
				-79.3913766326,
				43.6489870792
			],
			[
				-79.3931851383,
				43.6485508561
			],
			[
				-79.3935215659,
				43.6487278436
			],
			[
				-79.3938063333,
				43.6482161273
			],
			[
				-79.3961067301,
				43.6477288031
			],
			[
				-79.3974089228,
				43.6510259967
			],
			[
				-79.397824656,
				43.6526923109
			],
			[
				-79.3984843474,
				43.6537943289
			],
			[
				-79.3988684555,
				43.6542060414
			],
			[
				-79.4002920494,
				43.6542783086
			],
			[
				-79.4006023531,
				43.654663984
			],
			[
				-79.4018534921,
				43.6546745261
			],
			[
				-79.4032820103,
				43.6572246653
			],
			[
				-79.4054197236,
				43.6569751504
			],
			[
				-79.4099353804,
				43.655889361
			],
			[
				-79.4133888698,
				43.6554956763
			],
			[
				-79.4139703186,
				43.6551883558
			],
			[
				-79.416265359,
				43.6550795365
			],
			[
				-79.4171891253,
				43.6553880967
			],
			[
				-79.4211330506,
				43.6548401292
			],
			[
				-79.4232967049,
				43.6541620311
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4106613277,
				43.6632311755
			],
			[
				-79.4102299331,
				43.6627566628
			],
			[
				-79.4060546624,
				43.6522022021
			],
			[
				-79.4038127616,
				43.647275344
			],
			[
				-79.4020271753,
				43.6475811843
			],
			[
				-79.4008738651,
				43.6471017373
			],
			[
				-79.400179778,
				43.6471459978
			],
			[
				-79.3995617078,
				43.6461311929
			],
			[
				-79.3982917487,
				43.6462022041
			],
			[
				-79.3980057714,
				43.6460520677
			],
			[
				-79.3979231257,
				43.6455061733
			],
			[
				-79.3977096467,
				43.6454838734
			],
			[
				-79.397307849,
				43.6457715722
			],
			[
				-79.3974744641,
				43.6465310643
			],
			[
				-79.3934405455,
				43.6469954773
			],
			[
				-79.3930870419,
				43.647450833
			],
			[
				-79.3932925827,
				43.6479143133
			],
			[
				-79.393191332,
				43.6484506731
			],
			[
				-79.3914784642,
				43.6490571747
			],
			[
				-79.3909406662,
				43.6487150202
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4235088288,
				43.654082898
			],
			[
				-79.4226690205,
				43.6543252321
			],
			[
				-79.4239405089,
				43.6576681687
			],
			[
				-79.4238576745,
				43.6585813563
			],
			[
				-79.4103100393,
				43.6614104037
			],
			[
				-79.4098887361,
				43.6617883678
			],
			[
				-79.4102227547,
				43.6628262769
			],
			[
				-79.4106613277,
				43.6632311755
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3715823374,
				43.6786822005
			],
			[
				-79.3728897013,
				43.6784226714
			],
			[
				-79.3739021883,
				43.6775583121
			],
			[
				-79.374491801,
				43.6776205214
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3394501671,
				43.7136396598
			],
			[
				-79.3366773317,
				43.7142938329
			],
			[
				-79.3358130251,
				43.7138014909
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4357449207,
				43.6406258577
			],
			[
				-79.4358028467,
				43.6408803213
			],
			[
				-79.435613665,
				43.6409376696
			],
			[
				-79.4326510604,
				43.6414697888
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4259009883,
				43.6525997515
			],
			[
				-79.4258168061,
				43.6518134159
			],
			[
				-79.425334713,
				43.651187094
			],
			[
				-79.4248403275,
				43.6496717429
			],
			[
				-79.4270808442,
				43.6494823252
			],
			[
				-79.4314822539,
				43.649607483
			],
			[
				-79.4296306987,
				43.6451417882
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4236031027,
				43.6541504662
			],
			[
				-79.4240135097,
				43.6541767079
			],
			[
				-79.433947,
				43.652212
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5799877442,
				43.5537619101
			],
			[
				-79.5797666007,
				43.5540156875
			],
			[
				-79.5799546596,
				43.5542644218
			],
			[
				-79.5814623332,
				43.5548529355
			],
			[
				-79.5823151832,
				43.5546625034
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5824432848,
				43.5543237714
			],
			[
				-79.5822751128,
				43.5546946393
			],
			[
				-79.581308575,
				43.5547974702
			],
			[
				-79.5800165326,
				43.5541582644
			],
			[
				-79.5799877442,
				43.5537619101
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5596859365,
				43.6116461943
			],
			[
				-79.5593392037,
				43.6115629663
			],
			[
				-79.5588537784,
				43.6118422812
			],
			[
				-79.5590933012,
				43.6115918933
			],
			[
				-79.559458613,
				43.6117767185
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5595957455,
				43.6108347539
			],
			[
				-79.5590123811,
				43.6115923502
			],
			[
				-79.5598060393,
				43.6106933697
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.5560088874,
				43.6130370497
			],
			[
				-79.5560627506,
				43.6127997339
			],
			[
				-79.5560051342,
				43.6129851958
			],
			[
				-79.5565401561,
				43.613214948
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.1906705001,
				40.705089409
			],
			[
				-74.1908130677,
				40.7042206123
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9993303487,
				40.7435530886
			],
			[
				-73.9994172962,
				40.7438984901
			],
			[
				-73.9977548074,
				40.7459830638
			],
			[
				-73.9978483971,
				40.7464024404
			],
			[
				-73.996470105,
				40.7487744908
			],
			[
				-73.9964734677,
				40.7490987171
			],
			[
				-73.9982176599,
				40.7499184469
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9974994818,
				40.7410548598
			],
			[
				-73.9972483017,
				40.7414686415
			],
			[
				-73.9975554929,
				40.7414397494
			],
			[
				-73.9978764765,
				40.74187475
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.003246704,
				40.7317211503
			],
			[
				-74.0034816267,
				40.7320924037
			],
			[
				-74.0029154152,
				40.7334117678
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9999296247,
				40.7347909103
			],
			[
				-74.0001177009,
				40.7328008995
			],
			[
				-74.000749665,
				40.7320316391
			],
			[
				-74.0015858731,
				40.7314026008
			],
			[
				-74.0033499976,
				40.7318540672
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9865370975,
				40.7633124166
			],
			[
				-73.9856979391,
				40.7629877009
			],
			[
				-73.9861291234,
				40.7624262511
			],
			[
				-73.9864208558,
				40.7624173
			],
			[
				-73.9863503529,
				40.7621632684
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9827178754,
				40.7618613901
			],
			[
				-73.9838939934,
				40.7621172091
			],
			[
				-73.9842606636,
				40.7627270504
			],
			[
				-73.9865370975,
				40.7633124166
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.97301,
				40.7638225373
			],
			[
				-73.9733248507,
				40.7637829483
			],
			[
				-73.9741479986,
				40.7629310882
			],
			[
				-73.974441953,
				40.7619940236
			],
			[
				-73.9753396624,
				40.7610669089
			],
			[
				-73.9756993755,
				40.7601187355
			],
			[
				-73.9764943563,
				40.7599138592
			],
			[
				-73.9769651505,
				40.7589545687
			],
			[
				-73.9773413476,
				40.7593575581
			],
			[
				-73.9800110824,
				40.7604198751
			],
			[
				-73.982568444,
				40.7616843798
			],
			[
				-73.9824273623,
				40.7617513994
			],
			[
				-73.9828134128,
				40.761733968
			],
			[
				-73.9827178754,
				40.7618613901
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9775800376,
				40.7525049868
			],
			[
				-73.9775589386,
				40.7530840374
			],
			[
				-73.9765838486,
				40.7538586228
			],
			[
				-73.9776377386,
				40.7526761993
			],
			[
				-73.9770399835,
				40.7526142323
			],
			[
				-73.9767445065,
				40.7541580975
			],
			[
				-73.9769423846,
				40.7545278604
			],
			[
				-73.9766568482,
				40.7550074565
			],
			[
				-73.9754378499,
				40.7552965882
			],
			[
				-73.9746589631,
				40.7562982981
			],
			[
				-73.9743821155,
				40.7562159449
			],
			[
				-73.9742478874,
				40.7569299294
			],
			[
				-73.9739020525,
				40.756859888
			],
			[
				-73.97415666,
				40.7570304443
			],
			[
				-73.9737187345,
				40.7574965223
			],
			[
				-73.9737490071,
				40.7579074731
			],
			[
				-73.9741327477,
				40.7579203339
			],
			[
				-73.9735839354,
				40.7578924059
			],
			[
				-73.9723486592,
				40.7584153413
			],
			[
				-73.973649532,
				40.7589096939
			],
			[
				-73.972702732,
				40.758844819
			],
			[
				-73.9724303392,
				40.7592678127
			],
			[
				-73.9740334612,
				40.7599008716
			],
			[
				-73.9738432665,
				40.760405692
			],
			[
				-73.9752747265,
				40.7610470865
			],
			[
				-73.9736951538,
				40.7633480926
			],
			[
				-73.97301,
				40.7638225373
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9944463757,
				40.7452163611
			],
			[
				-73.9942861748,
				40.7459149654
			],
			[
				-73.9937345315,
				40.7465545246
			],
			[
				-73.9942405134,
				40.7452452005
			],
			[
				-73.9933904929,
				40.7473619482
			],
			[
				-73.9920753157,
				40.7491566583
			],
			[
				-73.9905508607,
				40.7486242336
			],
			[
				-73.9920306244,
				40.7491416965
			],
			[
				-73.9895153681,
				40.7478927451
			],
			[
				-73.9870029723,
				40.7472560222
			],
			[
				-73.9860957193,
				40.7466343483
			],
			[
				-73.9853422601,
				40.7470960768
			],
			[
				-73.9836699823,
				40.7463460346
			],
			[
				-73.982271106,
				40.7461349403
			],
			[
				-73.9779763547,
				40.7516516047
			],
			[
				-73.9777289594,
				40.7527909904
			],
			[
				-73.977175355,
				40.7528062091
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.998827289,
				40.7420841284
			],
			[
				-73.9975341853,
				40.7416780058
			],
			[
				-73.9970343863,
				40.741986468
			],
			[
				-73.9956396327,
				40.7443443405
			],
			[
				-73.9947256648,
				40.745220513
			],
			[
				-73.9944463757,
				40.7452163611
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0074485998,
				40.7431033435
			],
			[
				-74.006642591,
				40.7436296129
			],
			[
				-74.0067916451,
				40.743902091
			],
			[
				-74.0063152125,
				40.7452075233
			],
			[
				-74.0064912125,
				40.7453527656
			],
			[
				-74.0056989514,
				40.7459252943
			],
			[
				-74.0030419096,
				40.7448356059
			],
			[
				-74.0029011999,
				40.7445617497
			],
			[
				-74.0031119032,
				40.743968355
			],
			[
				-74.0011974788,
				40.7433319862
			],
			[
				-74.0001770717,
				40.7427079204
			],
			[
				-73.999249193,
				40.7425774975
			],
			[
				-73.9989792817,
				40.7421363397
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.001880219,
				40.7244998199
			],
			[
				-74.0000062323,
				40.7269166223
			],
			[
				-74.0025038071,
				40.7283327248
			],
			[
				-74.0020977835,
				40.7297913012
			],
			[
				-74.0026634533,
				40.7310399747
			],
			[
				-74.0031494678,
				40.7314823827
			],
			[
				-74.0044648726,
				40.7337362787
			],
			[
				-74.0047533942,
				40.7352577698
			],
			[
				-74.0053311793,
				40.7358942513
			],
			[
				-74.0073002208,
				40.7357894208
			],
			[
				-74.0082989297,
				40.7354954465
			],
			[
				-74.0081538551,
				40.740144986
			],
			[
				-74.0078170132,
				40.7408431591
			],
			[
				-74.0077974189,
				40.742165135
			],
			[
				-74.0074485998,
				40.7431033435
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9994342871,
				40.7208318059
			],
			[
				-73.999342827,
				40.7215151526
			],
			[
				-74.0016466285,
				40.7224428317
			],
			[
				-74.0019381498,
				40.722873796
			],
			[
				-74.001880219,
				40.7244998199
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9998517549,
				40.7197468988
			],
			[
				-73.9998678912,
				40.7202262663
			],
			[
				-73.9994342871,
				40.7208318059
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0064439706,
				40.7233635976
			],
			[
				-74.0066074572,
				40.7230244391
			],
			[
				-74.0051232385,
				40.7215836244
			],
			[
				-74.0044691554,
				40.721346237
			],
			[
				-74.0029037247,
				40.7201687831
			],
			[
				-73.9998517549,
				40.7197468988
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9999853427,
				40.7427946642
			],
			[
				-73.9998865861,
				40.7434610711
			],
			[
				-74.0000688547,
				40.7434972074
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-74.0001777071,
				40.7426889844
			],
			[
				-74.0005728048,
				40.7422682411
			],
			[
				-73.9987244743,
				40.7414075525
			],
			[
				-73.9984801498,
				40.7415422104
			],
			[
				-73.9984928443,
				40.7411932601
			],
			[
				-73.997515595,
				40.7415782122
			],
			[
				-73.9989569912,
				40.7421632029
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9970178049,
				40.7423489021
			],
			[
				-73.9975449885,
				40.7418056857
			],
			[
				-73.9988586714,
				40.742121444
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0002,
				40.73023
			],
			[
				-74.000193915,
				40.7305012671
			],
			[
				-74.0011507833,
				40.7309953329
			],
			[
				-74.0007236299,
				40.7310285516
			],
			[
				-73.9998772852,
				40.7304217523
			],
			[
				-74.0008275664,
				40.7317004585
			],
			[
				-74.0003288224,
				40.7324514111
			],
			[
				-74.0012105638,
				40.7326354458
			],
			[
				-74.0006101743,
				40.7323004155
			],
			[
				-74.0006619474,
				40.7321314002
			],
			[
				-74.0019277387,
				40.7305542751
			],
			[
				-74.0014068693,
				40.730901386
			],
			[
				-73.9976784738,
				40.735817025
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0032122727,
				40.7315908907
			],
			[
				-74.0025163001,
				40.7303758315
			],
			[
				-74.0016843154,
				40.7305146122
			],
			[
				-74.0012878355,
				40.7312181312
			],
			[
				-73.9999854314,
				40.7301789657
			],
			[
				-73.9994334955,
				40.7301806962
			],
			[
				-74.0008491071,
				40.7308334764
			],
			[
				-74.0002972454,
				40.7305567757
			],
			[
				-74.0002,
				40.73023
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0023557083,
				40.730410003
			],
			[
				-74.0028885813,
				40.7308192166
			],
			[
				-74.0033527462,
				40.7317367772
			],
			[
				-74.0032122727,
				40.7315908907
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9896234307,
				40.7257023721
			],
			[
				-73.9896360485,
				40.7263172286
			],
			[
				-73.9882482802,
				40.7279602114
			],
			[
				-73.9883850485,
				40.7282753273
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9651213657,
				40.7201225656
			],
			[
				-73.9633389263,
				40.7203888686
			],
			[
				-73.9624573072,
				40.7200251986
			],
			[
				-73.9623856642,
				40.7198528077
			],
			[
				-73.9637342688,
				40.7186380444
			],
			[
				-73.9638660184,
				40.718187483
			],
			[
				-73.963013144,
				40.7170627202
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9926557967,
				40.7044679562
			],
			[
				-73.9941624795,
				40.7037751305
			],
			[
				-73.9939759329,
				40.704046282
			],
			[
				-73.9939292011,
				40.7033924873
			],
			[
				-73.9937101007,
				40.703293459
			],
			[
				-73.994514499,
				40.7030397186
			],
			[
				-73.9956817553,
				40.7032104964
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.990904,
				40.702695
			],
			[
				-73.9905269214,
				40.7030240552
			],
			[
				-73.9908731339,
				40.7036793425
			],
			[
				-73.9908742097,
				40.7044307847
			],
			[
				-73.9920898471,
				40.7042099522
			],
			[
				-73.9926557967,
				40.7044679562
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0031889243,
				40.7138748643
			],
			[
				-74.004293176,
				40.7125099318
			],
			[
				-74.004608877,
				40.7126201405
			],
			[
				-74.0052206298,
				40.7120351844
			],
			[
				-74.0035612627,
				40.7112889994
			],
			[
				-74.0012014184,
				40.7094889846
			],
			[
				-74.0004431307,
				40.7086284721
			],
			[
				-73.9999654172,
				40.7084499416
			],
			[
				-74.0001009093,
				40.708091417
			],
			[
				-73.9982765585,
				40.7073879728
			],
			[
				-73.9983160213,
				40.7071488344
			],
			[
				-73.9979037904,
				40.7073654781
			],
			[
				-73.9948250633,
				40.7043883517
			],
			[
				-73.9934447214,
				40.7035756966
			],
			[
				-73.9935572281,
				40.7032169043
			],
			[
				-73.9931740634,
				40.7030718753
			],
			[
				-73.9937329092,
				40.7028773934
			],
			[
				-73.9938133692,
				40.7033231515
			],
			[
				-73.9929001597,
				40.7028760183
			],
			[
				-73.990197549,
				40.7007807166
			],
			[
				-73.9895370989,
				40.7007432483
			],
			[
				-73.9897677238,
				40.7017372993
			],
			[
				-73.9896634752,
				40.7024973166
			],
			[
				-73.990904,
				40.702695
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9869715129,
				40.7291362344
			],
			[
				-73.9875177456,
				40.72854412
			],
			[
				-73.9898372292,
				40.7296445332
			],
			[
				-73.991171169,
				40.7300134345
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9923445686,
				40.739569684
			],
			[
				-73.9919692068,
				40.7396107438
			],
			[
				-73.9916174133,
				40.7391899198
			],
			[
				-73.9922224589,
				40.7375869884
			],
			[
				-73.9921528264,
				40.7371890364
			],
			[
				-73.9923565921,
				40.7373106175
			],
			[
				-73.9920827152,
				40.7370840282
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9986501749,
				40.7420036783
			],
			[
				-73.998159555,
				40.742010973
			],
			[
				-73.9957551132,
				40.7408140445
			],
			[
				-73.9949215839,
				40.7407183419
			],
			[
				-73.9946409166,
				40.7403373927
			],
			[
				-73.9923445686,
				40.739569684
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0080055281,
				40.7469754311
			],
			[
				-74.0055268514,
				40.7456054724
			],
			[
				-74.0032342601,
				40.7448476996
			],
			[
				-74.0029008087,
				40.7446190179
			],
			[
				-74.0029496039,
				40.7439121783
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9939507914,
				40.767124396
			],
			[
				-73.9940689315,
				40.7674674635
			],
			[
				-73.9960218127,
				40.7680226954
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9903286526,
				40.771453196
			],
			[
				-73.9919724026,
				40.7698415243
			],
			[
				-73.9928163357,
				40.7679034251
			],
			[
				-73.9939507914,
				40.767124396
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-74.0077136696,
				40.7460267903
			],
			[
				-74.0078515725,
				40.7472688941
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9963025227,
				40.7370962
			],
			[
				-73.9979114156,
				40.7380133054
			],
			[
				-73.9980421278,
				40.7383267739
			],
			[
				-73.9986937524,
				40.7382262375
			],
			[
				-73.9994776294,
				40.738565789
			],
			[
				-73.9987616125,
				40.7400887984
			],
			[
				-73.9976245619,
				40.7415483719
			],
			[
				-73.9989148087,
				40.7420934363
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9996566721,
				40.7425621203
			],
			[
				-74.0003643463,
				40.7426321718
			],
			[
				-74.0010459142,
				40.7415639208
			],
			[
				-74.0017183146,
				40.7413631601
			],
			[
				-74.0014978543,
				40.741053386
			],
			[
				-73.9960905103,
				40.7386730881
			],
			[
				-73.9968193177,
				40.7373053688
			],
			[
				-73.9963025227,
				40.7370962
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0055146801,
				40.7368889159
			],
			[
				-74.0051176886,
				40.7374408346
			],
			[
				-74.0028992975,
				40.7390799065
			],
			[
				-74.0020168285,
				40.7404924663
			],
			[
				-74.0019716686,
				40.7411418866
			],
			[
				-74.0010532251,
				40.7417101855
			],
			[
				-74.0001920424,
				40.7427471911
			],
			[
				-73.9989049666,
				40.7421410428
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0028021353,
				40.7282832827
			],
			[
				-74.0027714227,
				40.7290886958
			],
			[
				-74.0012511629,
				40.7311117703
			],
			[
				-74.0013145645,
				40.7316254761
			],
			[
				-74.0006414923,
				40.7324209473
			],
			[
				-74.0023715425,
				40.7332417359
			],
			[
				-74.0032235639,
				40.7349790549
			],
			[
				-74.004352602,
				40.7348046938
			],
			[
				-74.0048194918,
				40.735084862
			],
			[
				-74.0052606231,
				40.735732405
			],
			[
				-74.0050634127,
				40.7359239502
			],
			[
				-74.0055146801,
				40.7368889159
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.997882378,
				40.7242638892
			],
			[
				-74.0032718236,
				40.7270045822
			],
			[
				-74.0028021353,
				40.7282832827
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0108408611,
				40.7162572002
			],
			[
				-74.0097541897,
				40.7159025977
			],
			[
				-74.0089444327,
				40.7159167572
			],
			[
				-74.0058252329,
				40.7199237686
			],
			[
				-74.0051349899,
				40.7205007296
			],
			[
				-74.0047959623,
				40.7212226512
			],
			[
				-74.0035738479,
				40.7213614615
			],
			[
				-74.0031477512,
				40.7220103632
			],
			[
				-74.0033042955,
				40.7219564781
			],
			[
				-74.0028213061,
				40.7220698375
			],
			[
				-74.0022368881,
				40.7230367604
			],
			[
				-74.0018533895,
				40.723113591
			],
			[
				-74.0002348798,
				40.7252687858
			],
			[
				-73.997882378,
				40.7242638892
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0129506636,
				40.7119342402
			],
			[
				-74.0131009753,
				40.7115583437
			],
			[
				-74.0136686169,
				40.712734182
			],
			[
				-74.012833372,
				40.7130429339
			],
			[
				-74.0135971603,
				40.7133027403
			],
			[
				-74.0136976306,
				40.7145071728
			],
			[
				-74.0143617724,
				40.7149593459
			],
			[
				-74.0131452218,
				40.7152722016
			],
			[
				-74.0113124629,
				40.7149202532
			],
			[
				-74.0108408611,
				40.7162572002
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.00769711,
				40.7103418483
			],
			[
				-74.0101128294,
				40.711519691
			],
			[
				-74.0104533939,
				40.7114479054
			],
			[
				-74.0103150697,
				40.7120210558
			],
			[
				-74.0114945167,
				40.7121670866
			],
			[
				-74.0118028738,
				40.7131238417
			],
			[
				-74.0127478736,
				40.7130530059
			],
			[
				-74.0132672154,
				40.7132974638
			],
			[
				-74.0142561189,
				40.7127346976
			],
			[
				-74.0141488448,
				40.7129104278
			],
			[
				-74.0129506636,
				40.7119342402
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9993874387,
				40.7081740901
			],
			[
				-73.9997827197,
				40.7077649802
			],
			[
				-74.0027640576,
				40.7066467654
			],
			[
				-74.0029550015,
				40.7062730962
			],
			[
				-74.0041135276,
				40.7072736373
			],
			[
				-74.0052832588,
				40.7091916908
			],
			[
				-74.0060083013,
				40.7096199692
			],
			[
				-74.0069356553,
				40.7097539652
			],
			[
				-74.00769711,
				40.7103418483
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9980428981,
				40.7145373012
			],
			[
				-73.9968462547,
				40.7139283211
			],
			[
				-73.9957999101,
				40.7142104791
			],
			[
				-73.9944932642,
				40.7132934674
			],
			[
				-73.9940492995,
				40.7113036464
			],
			[
				-73.9943381443,
				40.7108156747
			],
			[
				-73.994316957,
				40.7100446308
			],
			[
				-73.9939284337,
				40.7089395891
			],
			[
				-73.9941517446,
				40.7092202396
			],
			[
				-73.9960537361,
				40.7092188205
			],
			[
				-73.9962341413,
				40.7089146562
			],
			[
				-73.9965518534,
				40.7090879189
			],
			[
				-73.9981797627,
				40.708688092
			],
			[
				-73.9991428926,
				40.7081513624
			],
			[
				-73.9988013481,
				40.707881124
			],
			[
				-73.9993874387,
				40.7081740901
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0045145048,
				40.7195914494
			],
			[
				-73.9995945898,
				40.7173440987
			],
			[
				-73.999028985,
				40.7173348972
			],
			[
				-73.9979686824,
				40.7168462354
			],
			[
				-73.9987926076,
				40.7151469273
			],
			[
				-73.9980428981,
				40.7145373012
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0049525881,
				40.7209605265
			],
			[
				-74.0049539331,
				40.7196124638
			],
			[
				-74.0045145048,
				40.7195914494
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9987032376,
				40.7420784378
			],
			[
				-73.9980599471,
				40.7422128013
			],
			[
				-73.9976336084,
				40.7417197723
			],
			[
				-73.9987381486,
				40.7401759588
			],
			[
				-73.9993963178,
				40.7401529233
			],
			[
				-74.0015286599,
				40.7410465428
			],
			[
				-74.0024150679,
				40.7400918561
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9976511716,
				40.7412296793
			],
			[
				-73.9977394538,
				40.7411047401
			],
			[
				-73.9978251976,
				40.7417803383
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9940936851,
				40.7503548404
			],
			[
				-73.9931340153,
				40.7503283124
			],
			[
				-73.9920355646,
				40.749890328
			],
			[
				-73.9912063621,
				40.7507817033
			],
			[
				-73.99133,
				40.75065
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.1904342088,
				40.7041614004
			],
			[
				-74.1903740125,
				40.7046919015
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.1756801731,
				40.6898404142
			],
			[
				-74.1764042266,
				40.6917616639
			],
			[
				-74.1768558061,
				40.6912856394
			],
			[
				-74.17677,
				40.69071
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3970775604,
				43.6332020887
			],
			[
				-79.3962005759,
				43.6316800854
			],
			[
				-79.394983373,
				43.6314027762
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4033561513,
				43.6436926335
			],
			[
				-79.4030945831,
				43.6438935768
			],
			[
				-79.4006952468,
				43.6441587589
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4212818146,
				43.6396158283
			],
			[
				-79.4214037812,
				43.6400558987
			],
			[
				-79.4223732019,
				43.6400912643
			],
			[
				-79.4247162621,
				43.6394137185
			],
			[
				-79.4272817252,
				43.6391909478
			],
			[
				-79.4279158255,
				43.6399623593
			],
			[
				-79.4279678315,
				43.6406900977
			],
			[
				-79.4286580383,
				43.6421479909
			],
			[
				-79.4283763021,
				43.6424081893
			],
			[
				-79.4293766501,
				43.6440209994
			],
			[
				-79.4296337278,
				43.6440782561
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4178723697,
				43.63919632
			],
			[
				-79.4183651527,
				43.6396290463
			],
			[
				-79.4193847995,
				43.6398446205
			],
			[
				-79.419592416,
				43.6395755691
			],
			[
				-79.4200689923,
				43.6395908313
			],
			[
				-79.4206837637,
				43.6401085972
			],
			[
				-79.4212818146,
				43.6396158283
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3812382286,
				43.6565022636
			],
			[
				-79.3814783186,
				43.6567269316
			],
			[
				-79.3807834154,
				43.6560951203
			],
			[
				-79.3806400202,
				43.6558312459
			],
			[
				-79.3808096024,
				43.6553022226
			],
			[
				-79.3799809589,
				43.6542683534
			],
			[
				-79.378697056,
				43.6540817754
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.382386786,
				43.659462484
			],
			[
				-79.3817388009,
				43.6579185718
			],
			[
				-79.381194876,
				43.6574534868
			],
			[
				-79.3812382286,
				43.6565022636
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4357009589,
				43.6499596047
			],
			[
				-79.4316104645,
				43.6497198755
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4161624971,
				43.6641876612
			],
			[
				-79.415301807,
				43.664198948
			],
			[
				-79.4096939009,
				43.6654538446
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4293103377,
				43.644063739
			],
			[
				-79.4286899391,
				43.6437575734
			],
			[
				-79.4277716572,
				43.644085217
			],
			[
				-79.4274772446,
				43.6439040873
			],
			[
				-79.4270538525,
				43.6427751085
			],
			[
				-79.4267007419,
				43.6426090155
			],
			[
				-79.4188422579,
				43.6442153071
			],
			[
				-79.4202152597,
				43.6481638522
			],
			[
				-79.420682,
				43.648912
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4022960207,
				43.655535078
			],
			[
				-79.4023994813,
				43.6553128665
			],
			[
				-79.4018847679,
				43.6546573221
			],
			[
				-79.4006404071,
				43.6545357751
			],
			[
				-79.4000575569,
				43.6529719433
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.419634,
				43.646288
			],
			[
				-79.4189566245,
				43.6444831836
			],
			[
				-79.4194414282,
				43.6440388262
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4294884531,
				43.6443384166
			],
			[
				-79.4287578951,
				43.6438210629
			],
			[
				-79.4276160679,
				43.644161548
			],
			[
				-79.4271686528,
				43.6428260597
			],
			[
				-79.4266145766,
				43.6426503256
			],
			[
				-79.4197464707,
				43.6439029042
			],
			[
				-79.4189260106,
				43.6442692783
			],
			[
				-79.419634,
				43.646288
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3757975465,
				43.6413459256
			],
			[
				-79.3760039453,
				43.6406955351
			],
			[
				-79.3760689804,
				43.6411272138
			],
			[
				-79.3760477768,
				43.6406505495
			],
			[
				-79.3759209077,
				43.6409438844
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4653813566,
				43.6655228446
			],
			[
				-79.465081706,
				43.6653277203
			],
			[
				-79.4700855317,
				43.6654473545
			],
			[
				-79.4702551651,
				43.6652221959
			],
			[
				-79.4700780887,
				43.6644867653
			],
			[
				-79.4690558989,
				43.6627741212
			],
			[
				-79.4689434521,
				43.6618460656
			],
			[
				-79.4683555239,
				43.6608914347
			],
			[
				-79.4687444406,
				43.6603762575
			],
			[
				-79.4697473334,
				43.660093464
			],
			[
				-79.4701366772,
				43.6593155156
			],
			[
				-79.471092661,
				43.6587717185
			],
			[
				-79.4714761385,
				43.6594827308
			],
			[
				-79.4731855014,
				43.6600185357
			],
			[
				-79.4734340522,
				43.6598750262
			],
			[
				-79.473710706,
				43.6600947301
			],
			[
				-79.4734371397,
				43.660477274
			],
			[
				-79.4739487682,
				43.6603870211
			],
			[
				-79.4745131234,
				43.6600915552
			],
			[
				-79.4746979619,
				43.6588461957
			],
			[
				-79.4754705718,
				43.6583961359
			],
			[
				-79.4752862558,
				43.6573896713
			],
			[
				-79.476424605,
				43.6558125026
			],
			[
				-79.4765168788,
				43.6553157947
			],
			[
				-79.479268312,
				43.6543650443
			],
			[
				-79.4838067741,
				43.6535619676
			],
			[
				-79.4842103352,
				43.6533223806
			],
			[
				-79.4839268604,
				43.6530925247
			],
			[
				-79.4841464853,
				43.6532550433
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4842480538,
				43.6532836315
			],
			[
				-79.4770344868,
				43.6549122256
			],
			[
				-79.4760160006,
				43.6555823105
			],
			[
				-79.4746425935,
				43.6558786205
			],
			[
				-79.4740918011,
				43.6567175993
			],
			[
				-79.4728128219,
				43.6578090481
			],
			[
				-79.4704577399,
				43.6588294705
			],
			[
				-79.4695283587,
				43.6596262971
			],
			[
				-79.4693582751,
				43.6601329116
			],
			[
				-79.4683881846,
				43.6607665877
			],
			[
				-79.4690521311,
				43.6620350528
			],
			[
				-79.4691735052,
				43.6631573462
			],
			[
				-79.4701106007,
				43.6645507812
			],
			[
				-79.4702231641,
				43.6653072205
			],
			[
				-79.4697980428,
				43.6654819683
			],
			[
				-79.4653813566,
				43.6655228446
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4184500189,
				43.6497416844
			],
			[
				-79.4200976061,
				43.6547646007
			],
			[
				-79.4191964126,
				43.6552491017
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4197854224,
				43.6466654516
			],
			[
				-79.4201110834,
				43.6477460018
			],
			[
				-79.4203854446,
				43.6479631595
			],
			[
				-79.4204781475,
				43.6490756079
			],
			[
				-79.4198701707,
				43.6494748516
			],
			[
				-79.4184500189,
				43.6497416844
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4224115387,
				43.6533628035
			],
			[
				-79.4212239979,
				43.6512182045
			],
			[
				-79.4215652036,
				43.6509927461
			],
			[
				-79.4208326197,
				43.6501495241
			],
			[
				-79.4209450129,
				43.6494350779
			],
			[
				-79.419729668,
				43.6464851883
			],
			[
				-79.4197854224,
				43.6466654516
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.413336785,
				43.6538451508
			],
			[
				-79.4137664698,
				43.6549122791
			],
			[
				-79.4134352029,
				43.6552723997
			],
			[
				-79.4126579521,
				43.6554412248
			],
			[
				-79.415693969,
				43.6550021189
			],
			[
				-79.4139541374,
				43.6551767861
			],
			[
				-79.413526,
				43.655521
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.399507,
				43.643298
			],
			[
				-79.4000385873,
				43.6438418255
			],
			[
				-79.3997112957,
				43.6444899602
			],
			[
				-79.3978225475,
				43.6450510959
			],
			[
				-79.3957491306,
				43.6453446781
			],
			[
				-79.3949468829,
				43.6458364391
			],
			[
				-79.3954374013,
				43.6466468742
			],
			[
				-79.3978485244,
				43.6461556667
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3995400273,
				43.6362648662
			],
			[
				-79.4001383084,
				43.6364425338
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3970775604,
				43.6332020887
			],
			[
				-79.3976233653,
				43.6339895724
			],
			[
				-79.3975075866,
				43.6350728506
			],
			[
				-79.3973464259,
				43.6347782717
			],
			[
				-79.3983924783,
				43.6359499845
			],
			[
				-79.3995400273,
				43.6362648662
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.394983373,
				43.6314027762
			],
			[
				-79.3961254657,
				43.6316954955
			],
			[
				-79.3970775604,
				43.6332020887
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.1771662857,
				40.6905274005
			],
			[
				-74.1756801731,
				40.6898404142
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9988959283,
				40.7419735965
			],
			[
				-73.9994993314,
				40.7424926653
			],
			[
				-74.0002005338,
				40.7427054031
			],
			[
				-73.9999429031,
				40.7434703412
			],
			[
				-73.9970548965,
				40.7470220673
			],
			[
				-73.9970186947,
				40.747362702
			],
			[
				-73.9950908594,
				40.7496948795
			],
			[
				-73.9950692295,
				40.7499767883
			],
			[
				-73.9943649744,
				40.7508288706
			],
			[
				-73.9940060368,
				40.7509449928
			],
			[
				-73.9938820838,
				40.7514735029
			],
			[
				-73.9944432303,
				40.7512422464
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9910852909,
				40.7348522861
			],
			[
				-73.9907861909,
				40.734645577
			],
			[
				-73.9908473711,
				40.7352478062
			],
			[
				-73.9903625456,
				40.7364243904
			],
			[
				-73.9925252956,
				40.7375058486
			],
			[
				-73.9930239613,
				40.7375218033
			],
			[
				-73.9951604424,
				40.7384506606
			],
			[
				-73.9955533682,
				40.7387912302
			],
			[
				-73.9952181636,
				40.7397613896
			],
			[
				-73.995447523,
				40.7400037972
			],
			[
				-73.997178512,
				40.7409923608
			],
			[
				-73.9975845973,
				40.7416986497
			],
			[
				-73.9989829845,
				40.74212882
			],
			[
				-73.9988959283,
				40.7419735965
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9844338968,
				40.7273398138
			],
			[
				-73.9870502406,
				40.7293460309
			],
			[
				-73.9867761781,
				40.72969835
			],
			[
				-73.9869109015,
				40.7298839253
			],
			[
				-73.9902251204,
				40.7308813652
			],
			[
				-73.9905187784,
				40.731410924
			],
			[
				-73.99093897,
				40.7314275243
			],
			[
				-73.991228598,
				40.7317153674
			],
			[
				-73.9912543776,
				40.7330627936
			],
			[
				-73.9909131882,
				40.7342915716
			],
			[
				-73.9910852909,
				40.7348522861
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9876487042,
				40.7284383727
			],
			[
				-73.9861587699,
				40.72818455
			],
			[
				-73.9854516304,
				40.7276591393
			],
			[
				-73.9844338968,
				40.7273398138
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9983321051,
				40.7405832238
			],
			[
				-73.9996544371,
				40.7386936422
			],
			[
				-73.9937688553,
				40.7359978111
			],
			[
				-73.9938149114,
				40.7354785762
			],
			[
				-73.9942416874,
				40.7352861695
			],
			[
				-73.9950627336,
				40.7339909694
			],
			[
				-73.9961136159,
				40.7329393042
			],
			[
				-73.9960563881,
				40.732233695
			],
			[
				-73.9929873021,
				40.7307541592
			],
			[
				-73.9915539492,
				40.7303749293
			],
			[
				-73.9910743098,
				40.7299505835
			],
			[
				-73.9897793766,
				40.7296117304
			],
			[
				-73.9876487042,
				40.7284383727
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0033499976,
				40.7435910599
			],
			[
				-74.002848676,
				40.7438890125
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9990687787,
				40.7422187344
			],
			[
				-74.0001721199,
				40.74291691
			],
			[
				-74.0024552331,
				40.7437732848
			],
			[
				-74.0034666909,
				40.7435703873
			],
			[
				-74.0032555556,
				40.7436534046
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9998027205,
				40.7425716221
			],
			[
				-74.0001300287,
				40.7428318293
			],
			[
				-73.9999266743,
				40.7432870779
			],
			[
				-74.0001036077,
				40.7434714141
			],
			[
				-74.0020108001,
				40.7443127804
			],
			[
				-74.0028514707,
				40.7443262541
			],
			[
				-74.003421626,
				40.7436694357
			],
			[
				-74.0026002096,
				40.7438085677
			],
			[
				-73.9993364862,
				40.7424079875
			],
			[
				-73.9991223304,
				40.7420904066
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9815500824,
				40.7323983378
			],
			[
				-73.9821497196,
				40.7315101168
			],
			[
				-73.9806412014,
				40.7305244102
			],
			[
				-73.980353192,
				40.7299035553
			],
			[
				-73.9806840221,
				40.7296578804
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9824248998,
				40.7275716747
			],
			[
				-73.9840610109,
				40.7279033253
			],
			[
				-73.984556771,
				40.7282775558
			],
			[
				-73.9845324084,
				40.7286393623
			],
			[
				-73.982684271,
				40.7314765751
			],
			[
				-73.9815500824,
				40.7323983378
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.010311691,
				40.7089297651
			],
			[
				-74.0104447791,
				40.7085492505
			],
			[
				-74.0110619599,
				40.7086922229
			],
			[
				-74.0116971384,
				40.7077828398
			],
			[
				-74.0111538573,
				40.7074057978
			],
			[
				-74.0107403409,
				40.7066352637
			],
			[
				-74.0116856166,
				40.7047107399
			],
			[
				-74.0114798611,
				40.7040840364
			],
			[
				-74.0109008711,
				40.70396807
			],
			[
				-74.0112722046,
				40.7039014858
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0130322745,
				40.71759079
			],
			[
				-74.0139499532,
				40.7136618028
			],
			[
				-74.0124951251,
				40.7134684544
			],
			[
				-74.0122048798,
				40.7128931786
			],
			[
				-74.0115424752,
				40.7126741455
			],
			[
				-74.0111601254,
				40.7122507978
			],
			[
				-74.0103400962,
				40.7121380742
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9997315653,
				40.7382733333
			],
			[
				-74.0008448469,
				40.7373247276
			],
			[
				-74.0022055027,
				40.7346273124
			],
			[
				-74.0027499449,
				40.7342175607
			],
			[
				-74.002796746,
				40.7335947659
			],
			[
				-74.0048853737,
				40.7299703847
			],
			[
				-74.0049332292,
				40.7297805805
			],
			[
				-74.0046258041,
				40.7297241544
			],
			[
				-74.0052817548,
				40.7291535847
			],
			[
				-74.005377903,
				40.728674717
			],
			[
				-74.0069148624,
				40.728898924
			],
			[
				-74.0070729732,
				40.7286560964
			],
			[
				-74.0075983616,
				40.7288673459
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9987190876,
				40.7420422517
			],
			[
				-73.9976871305,
				40.7414628487
			],
			[
				-73.9996911825,
				40.738664323
			],
			[
				-73.9997315653,
				40.7382733333
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-74.0000743159,
				40.7429167025
			],
			[
				-73.9997148275,
				40.7431810165
			],
			[
				-73.9983836073,
				40.7428849949
			],
			[
				-73.9972300234,
				40.7421097692
			],
			[
				-73.9976296293,
				40.7418593756
			],
			[
				-73.9988105276,
				40.742261276
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9899755683,
				40.741028498
			],
			[
				-73.9912575116,
				40.7413239001
			],
			[
				-73.9957301501,
				40.7433657345
			],
			[
				-73.9963961553,
				40.7430136426
			],
			[
				-73.9975990776,
				40.74167418
			],
			[
				-73.9981230441,
				40.7419993424
			],
			[
				-73.9988383014,
				40.7420689714
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.997803582,
				40.7416535126
			],
			[
				-73.9969896147,
				40.741813794
			],
			[
				-73.9968611007,
				40.742383226
			],
			[
				-73.9963021584,
				40.7430924503
			],
			[
				-73.9955409706,
				40.7432185474
			],
			[
				-73.9947737374,
				40.7430189943
			],
			[
				-73.9942902129,
				40.742506379
			],
			[
				-73.9913405304,
				40.7415940259
			],
			[
				-73.9905330425,
				40.7407901636
			],
			[
				-73.9899755683,
				40.741028498
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.003516006,
				40.7488920685
			],
			[
				-74.0036101831,
				40.7483575063
			],
			[
				-74.001367853,
				40.7474011494
			],
			[
				-74.0011160001,
				40.7469309649
			],
			[
				-74.0016109491,
				40.7459339832
			],
			[
				-74.0013186823,
				40.7454244936
			],
			[
				-74.0000002507,
				40.7450770321
			],
			[
				-73.9993035227,
				40.7445180194
			],
			[
				-73.9994830105,
				40.7442262397
			],
			[
				-73.9993247769,
				40.7440689114
			],
			[
				-74.000119962,
				40.7428528758
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9990592634,
				40.7421911782
			],
			[
				-74.000014164,
				40.7428250791
			],
			[
				-73.9997493797,
				40.743429286
			],
			[
				-73.9999129438,
				40.743475563
			],
			[
				-73.9986665819,
				40.7454193242
			],
			[
				-74.001048655,
				40.746699007
			],
			[
				-74.003590227,
				40.7475351002
			],
			[
				-74.0040289472,
				40.7480041094
			],
			[
				-74.0033713824,
				40.7489389588
			],
			[
				-74.003516006,
				40.7488920685
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9884810014,
				40.7423375201
			],
			[
				-73.9878981403,
				40.7425796392
			],
			[
				-73.985711649,
				40.7415645887
			],
			[
				-73.9866819548,
				40.7397397973
			],
			[
				-73.9888078574,
				40.740397946
			],
			[
				-73.9894823943,
				40.7409922024
			],
			[
				-73.9907002169,
				40.7404186507
			],
			[
				-73.9921628983,
				40.7407747029
			],
			[
				-73.9936690703,
				40.7414790725
			],
			[
				-73.9946677415,
				40.7406050095
			],
			[
				-73.9949920111,
				40.7406035327
			],
			[
				-73.9971168345,
				40.7416227985
			],
			[
				-73.9988398636,
				40.7420701001
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9987686573,
				40.7419847341
			],
			[
				-73.9976046204,
				40.7417691682
			],
			[
				-73.9968229035,
				40.7422035308
			],
			[
				-73.9945953598,
				40.7410575558
			],
			[
				-73.993453513,
				40.7414591158
			],
			[
				-73.9905324291,
				40.7403268817
			],
			[
				-73.9894774207,
				40.741440127
			],
			[
				-73.9895955698,
				40.7415569012
			],
			[
				-73.9887555006,
				40.7417139689
			],
			[
				-73.988369442,
				40.7420815572
			],
			[
				-73.9884810014,
				40.7423375201
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9865263389,
				40.7159327431
			],
			[
				-73.9869738093,
				40.7157822626
			],
			[
				-73.9867442733,
				40.7161325642
			],
			[
				-73.9882584783,
				40.716522267
			],
			[
				-73.9880515307,
				40.717122562
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9884185636,
				40.6922451414
			],
			[
				-73.9863316191,
				40.6922399964
			],
			[
				-73.9862089571,
				40.6925429993
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9817712124,
				40.6930019272
			],
			[
				-73.9843014197,
				40.6923603601
			],
			[
				-73.9847090583,
				40.6919711419
			],
			[
				-73.9884185636,
				40.6922451414
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9626727838,
				40.7125753059
			],
			[
				-73.9658738054,
				40.7134294469
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9704660932,
				40.7516158028
			],
			[
				-73.9685341159,
				40.7506727962
			],
			[
				-73.9686961248,
				40.7507084255
			],
			[
				-73.9689632035,
				40.7499350184
			],
			[
				-73.9697867791,
				40.7490025475
			],
			[
				-73.970520964,
				40.7489582142
			],
			[
				-73.9711628207,
				40.7494661071
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9676682743,
				40.7558264073
			],
			[
				-73.968554529,
				40.7548656076
			],
			[
				-73.9690002184,
				40.7539427003
			],
			[
				-73.9699199476,
				40.7531304735
			],
			[
				-73.9698197385,
				40.7527763141
			],
			[
				-73.9702260409,
				40.7529785163
			],
			[
				-73.9702576707,
				40.7525478201
			],
			[
				-73.9707413404,
				40.7520529679
			],
			[
				-73.9704660932,
				40.7516158028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9677229809,
				40.7559613828
			],
			[
				-73.9675477296,
				40.756366098
			],
			[
				-73.9676682743,
				40.7558264073
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9979447621,
				40.7463396499
			],
			[
				-73.9980485855,
				40.7464636491
			],
			[
				-73.9964958667,
				40.7474861175
			],
			[
				-73.9965837204,
				40.7472426327
			],
			[
				-73.9965832127,
				40.7475775818
			],
			[
				-73.9960018278,
				40.7475666962
			],
			[
				-73.9948112168,
				40.7469145086
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9995094271,
				40.7424249497
			],
			[
				-73.9998453334,
				40.7422795359
			],
			[
				-74.0000277451,
				40.7430506707
			],
			[
				-73.9984910864,
				40.745253712
			],
			[
				-73.9985594785,
				40.7458735437
			],
			[
				-73.9979447621,
				40.7463396499
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9977566985,
				40.7415819716
			],
			[
				-73.997797197,
				40.7413133234
			],
			[
				-73.9986781807,
				40.7413261009
			],
			[
				-74.0003566521,
				40.7423333843
			],
			[
				-74.0000238734,
				40.7427308977
			],
			[
				-73.9990515451,
				40.7420943436
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-74.0000894802,
				40.7428756516
			],
			[
				-74.0004404826,
				40.7424663382
			],
			[
				-74.0002647635,
				40.7420892716
			],
			[
				-73.9989438159,
				40.7416960455
			],
			[
				-73.9985966337,
				40.7413031985
			],
			[
				-73.9979801953,
				40.7417511694
			],
			[
				-73.9988874324,
				40.7421435293
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9949688922,
				40.7496198365
			],
			[
				-73.9982090353,
				40.7507046054
			],
			[
				-73.9988990926,
				40.7504621552
			],
			[
				-73.9987274548,
				40.7501763349
			],
			[
				-74.0007090808,
				40.7476497237
			],
			[
				-74.0007817255,
				40.7472285476
			],
			[
				-74.0003699202,
				40.7465159531
			],
			[
				-73.9965135909,
				40.7453817235
			],
			[
				-73.9955586115,
				40.744930276
			],
			[
				-73.9954398273,
				40.7446409515
			],
			[
				-73.9969299625,
				40.7423024313
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-74.000174011,
				40.7429300899
			],
			[
				-73.9995823542,
				40.7434787266
			],
			[
				-73.9989737506,
				40.7446930483
			],
			[
				-73.9981133693,
				40.7454616159
			],
			[
				-73.9980134196,
				40.7465029483
			],
			[
				-73.9974115609,
				40.7466461234
			],
			[
				-73.9961557992,
				40.7484618965
			],
			[
				-73.9949688922,
				40.7496198365
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9991653215,
				40.7422186269
			],
			[
				-74.0004497021,
				40.7430030069
			],
			[
				-74.0057756347,
				40.7452235542
			],
			[
				-74.0060199136,
				40.7452204836
			],
			[
				-74.0062858965,
				40.7446681163
			],
			[
				-74.0070913893,
				40.744439535
			],
			[
				-74.0070213346,
				40.7435313611
			],
			[
				-74.0077761278,
				40.7427419212
			],
			[
				-74.007947084,
				40.7421815055
			],
			[
				-74.0054963854,
				40.7409563897
			],
			[
				-74.0042944522,
				40.742027955
			],
			[
				-74.0016130482,
				40.7413096264
			],
			[
				-74.0002187287,
				40.742779787
			],
			[
				-73.9989646926,
				40.7420956197
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9869715129,
				40.7291362344
			],
			[
				-73.9869634405,
				40.729607468
			],
			[
				-73.9856661955,
				40.7314014711
			],
			[
				-73.9858960394,
				40.7320890016
			],
			[
				-73.9900666872,
				40.7335840201
			],
			[
				-73.991486591,
				40.7343770611
			],
			[
				-73.9942391651,
				40.7353332129
			],
			[
				-73.999849385,
				40.7378328367
			],
			[
				-73.9997853881,
				40.7384621249
			],
			[
				-73.9994964107,
				40.7387950787
			],
			[
				-73.9991968559,
				40.7387878918
			],
			[
				-73.9988042619,
				40.7398188934
			],
			[
				-73.9978457014,
				40.7407533304
			],
			[
				-73.9976233621,
				40.7416819721
			],
			[
				-73.9989385671,
				40.742033389
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9988441112,
				40.7421388732
			],
			[
				-73.9979416159,
				40.7420613544
			],
			[
				-73.9976243461,
				40.7414070818
			],
			[
				-73.9989956593,
				40.7400177777
			],
			[
				-73.9993061724,
				40.739184888
			],
			[
				-73.9991877023,
				40.7389371108
			],
			[
				-74.0010584003,
				40.7370307425
			],
			[
				-74.0007188508,
				40.7358786898
			],
			[
				-73.9997319931,
				40.7343547684
			],
			[
				-73.9991180351,
				40.7339019399
			],
			[
				-73.9993054019,
				40.7339168686
			],
			[
				-73.9984924713,
				40.7339462031
			],
			[
				-73.9965928565,
				40.7333095414
			],
			[
				-73.9943348386,
				40.7320047528
			],
			[
				-73.9933254995,
				40.7318427028
			],
			[
				-73.9905966502,
				40.7304375093
			],
			[
				-73.9896608614,
				40.7303892735
			],
			[
				-73.9895952147,
				40.7301740357
			],
			[
				-73.9874418839,
				40.7291368117
			],
			[
				-73.9869715129,
				40.7291362344
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9900805214,
				40.7371754176
			],
			[
				-73.9902546772,
				40.7369060535
			],
			[
				-73.9903918999,
				40.7372673055
			],
			[
				-73.9900976391,
				40.7386073718
			],
			[
				-73.9988346333,
				40.7420813475
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9910101131,
				40.735565513
			],
			[
				-73.9901541371,
				40.7368374556
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9935486929,
				40.73600522
			],
			[
				-73.9933693427,
				40.7357329831
			],
			[
				-73.9918848834,
				40.7353679722
			],
			[
				-73.9910604985,
				40.735394171
			],
			[
				-73.9910101131,
				40.735565513
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9986581252,
				40.7420548426
			],
			[
				-73.9957852687,
				40.7410882886
			],
			[
				-73.9948509258,
				40.7404918067
			],
			[
				-73.993159906,
				40.7400241346
			],
			[
				-73.9918204922,
				40.7392250341
			],
			[
				-73.9916969447,
				40.739025515
			],
			[
				-73.9921453193,
				40.7379105181
			],
			[
				-73.9924066397,
				40.7378822844
			],
			[
				-73.9935486929,
				40.73600522
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0003429806,
				40.7389170965
			],
			[
				-73.9996637054,
				40.7387793365
			],
			[
				-73.9979634455,
				40.7406854393
			],
			[
				-73.9977302778,
				40.7417249762
			],
			[
				-73.9984781715,
				40.7422602498
			],
			[
				-73.9988669801,
				40.7421156743
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9985538685,
				40.7420026252
			],
			[
				-73.9979661014,
				40.7418893922
			],
			[
				-73.9977537642,
				40.7413983486
			],
			[
				-73.9995159123,
				40.7388007583
			],
			[
				-74.0003429806,
				40.7389170965
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0042566844,
				40.7476465286
			],
			[
				-74.0045204867,
				40.7475601044
			],
			[
				-74.0048016458,
				40.7462881452
			],
			[
				-74.002865313,
				40.7451816134
			],
			[
				-74.0026229313,
				40.7437871582
			],
			[
				-73.999095067,
				40.7422115003
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0074490177,
				40.748669
			],
			[
				-74.0073506277,
				40.7480743254
			],
			[
				-74.0065629294,
				40.747735439
			],
			[
				-74.0048504376,
				40.7471908466
			],
			[
				-74.0042566844,
				40.7476465286
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9990482055,
				40.7421995406
			],
			[
				-74.0026788536,
				40.7439475016
			],
			[
				-74.003264018,
				40.7439949938
			],
			[
				-74.0051631774,
				40.7449184262
			],
			[
				-74.0056878751,
				40.7454577227
			],
			[
				-74.0064187719,
				40.7453616734
			],
			[
				-74.0076027524,
				40.7459736456
			],
			[
				-74.0076406369,
				40.746712802
			],
			[
				-74.0080402492,
				40.7470354044
			],
			[
				-74.0074603827,
				40.7486390802
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9934220713,
				40.7419771417
			],
			[
				-73.9942451756,
				40.7418980415
			],
			[
				-73.9953546402,
				40.7424810521
			],
			[
				-73.9963071797,
				40.742676327
			],
			[
				-73.9977018638,
				40.7417947579
			],
			[
				-73.9988438518,
				40.7421054332
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9987746253,
				40.7421261862
			],
			[
				-73.9978060226,
				40.7419181256
			],
			[
				-73.997073532,
				40.7421740968
			],
			[
				-73.9965655397,
				40.742783768
			],
			[
				-73.9945481449,
				40.741971109
			],
			[
				-73.9934220713,
				40.7419771417
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.0001797891,
				40.738927334
			],
			[
				-73.9996971722,
				40.7386651411
			],
			[
				-73.9979921518,
				40.7408081946
			],
			[
				-73.9977976485,
				40.7417272579
			],
			[
				-73.9987977541,
				40.7420836562
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9978905861,
				40.7411891662
			],
			[
				-73.9988652163,
				40.7398641402
			],
			[
				-73.9992815257,
				40.7389957628
			],
			[
				-73.9991739347,
				40.7385966496
			],
			[
				-74.0001797891,
				40.738927334
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-74.001209584,
				40.7419275021
			],
			[
				-74.0001222743,
				40.7426585933
			],
			[
				-73.9990185488,
				40.7421361778
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.996094005,
				40.7303923511
			],
			[
				-73.996281148,
				40.7307824158
			],
			[
				-73.9970882854,
				40.7312132794
			],
			[
				-73.9972258766,
				40.7316387767
			],
			[
				-73.9979188646,
				40.7317885918
			],
			[
				-74.0000540177,
				40.7330824978
			],
			[
				-74.0014729059,
				40.7337689608
			],
			[
				-74.0020380652,
				40.7334876169
			],
			[
				-74.0027506359,
				40.733703462
			],
			[
				-74.0011060033,
				40.7368637355
			],
			[
				-74.0026033565,
				40.739175831
			],
			[
				-74.0023051206,
				40.7400501036
			],
			[
				-74.000889289,
				40.7416765438
			],
			[
				-74.001209584,
				40.7419275021
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9943812014,
				40.7323812047
			],
			[
				-73.9962374064,
				40.7306556555
			],
			[
				-73.996094005,
				40.7303923511
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9921256257,
				40.7328231849
			],
			[
				-73.9919518386,
				40.7326167951
			],
			[
				-73.9924364531,
				40.7330137294
			],
			[
				-73.993265032,
				40.7332385033
			],
			[
				-73.9943812014,
				40.7323812047
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-73.9986596297,
				40.7422323176
			],
			[
				-73.9991687646,
				40.742279871
			],
			[
				-74.0001262374,
				40.742953628
			],
			[
				-73.99873516,
				40.7448573285
			],
			[
				-73.9978754536,
				40.7449975873
			],
			[
				-73.995968055,
				40.7442066723
			],
			[
				-73.996140253,
				40.7434358864
			],
			[
				-73.9973484516,
				40.7419974286
			],
			[
				-73.9989117621,
				40.7419639226
			],
			[
				-73.9986596297,
				40.7422323176
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-77.227151654,
				42.9773841194
			],
			[
				-77.2266240262,
				42.9774093558
			],
			[
				-77.2274163353,
				42.9771341667
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-78.9720689773,
				43.0889451091
			],
			[
				-78.9721529717,
				43.0890173028
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3975575751,
				43.6456633197
			],
			[
				-79.3978990878,
				43.6458036091
			],
			[
				-79.3985945586,
				43.6472877997
			],
			[
				-79.3991588331,
				43.6474881926
			],
			[
				-79.398401037,
				43.6469907193
			],
			[
				-79.3979072621,
				43.6456514827
			],
			[
				-79.3974324472,
				43.6458194363
			],
			[
				-79.3976863327,
				43.6457367863
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39606,
				43.64809
			],
			[
				-79.3984623944,
				43.647218314
			],
			[
				-79.3977690217,
				43.6455314382
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3977045291,
				43.6455466402
			],
			[
				-79.3956093814,
				43.6466944722
			],
			[
				-79.395912992,
				43.6466961562
			],
			[
				-79.3956668401,
				43.6471321428
			],
			[
				-79.39606,
				43.64809
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3977031892,
				43.6455361489
			],
			[
				-79.3979629189,
				43.6456413494
			],
			[
				-79.3985366821,
				43.6465412771
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.413526,
				43.655521
			],
			[
				-79.4124189217,
				43.6554235839
			],
			[
				-79.4102382219,
				43.6557215941
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.41336,
				43.66301
			],
			[
				-79.4130446226,
				43.6628991389
			],
			[
				-79.4107733301,
				43.6573267716
			],
			[
				-79.4109187914,
				43.6568923849
			],
			[
				-79.4123535267,
				43.6554694404
			],
			[
				-79.413526,
				43.655521
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4149029351,
				43.6643560295
			],
			[
				-79.4141128438,
				43.6634193639
			],
			[
				-79.41336,
				43.66301
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3979099911,
				43.6454238849
			],
			[
				-79.398028293,
				43.6459398984
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3978831679,
				43.6455367407
			],
			[
				-79.39902,
				43.6474
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3979519362,
				43.6457619909
			],
			[
				-79.3984728102,
				43.6464085532
			],
			[
				-79.3991142076,
				43.6465244852
			],
			[
				-79.3985366821,
				43.6465412771
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3977412651,
				43.6456390297
			],
			[
				-79.3982334326,
				43.6462087445
			],
			[
				-79.3987324145,
				43.6463562303
			],
			[
				-79.3985366821,
				43.6465412771
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.397771334,
				43.6455369842
			],
			[
				-79.3985112827,
				43.6471315798
			],
			[
				-79.3988133101,
				43.6472942282
			],
			[
				-79.398679311,
				43.6473984466
			],
			[
				-79.3983197237,
				43.6464708309
			],
			[
				-79.3976123671,
				43.645705566
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3973253359,
				43.6485569086
			],
			[
				-79.3978347835,
				43.6482756192
			],
			[
				-79.3982268795,
				43.647413345
			],
			[
				-79.3985858364,
				43.6474263169
			],
			[
				-79.3979348226,
				43.6456063806
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3961376194,
				43.6476370363
			],
			[
				-79.3964155605,
				43.648337853
			],
			[
				-79.3973253359,
				43.6485569086
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4296125452,
				43.6423287832
			],
			[
				-79.4290480246,
				43.6438840639
			],
			[
				-79.4278414811,
				43.6438019938
			],
			[
				-79.4270653669,
				43.6425854678
			],
			[
				-79.4169455493,
				43.6444691194
			],
			[
				-79.4138567976,
				43.6453256384
			],
			[
				-79.414228296,
				43.6455967466
			],
			[
				-79.4135506055,
				43.6453152078
			],
			[
				-79.4117934377,
				43.6457480716
			],
			[
				-79.408383558,
				43.6460627545
			],
			[
				-79.4044344692,
				43.6472501877
			],
			[
				-79.4017756766,
				43.6476183146
			],
			[
				-79.401368703,
				43.6474538932
			],
			[
				-79.4011166657,
				43.6469139503
			],
			[
				-79.3994845235,
				43.6473790006
			],
			[
				-79.3986544158,
				43.6473621762
			],
			[
				-79.39808198,
				43.6464341356
			],
			[
				-79.3982503436,
				43.6462778776
			],
			[
				-79.3978937258,
				43.6455394839
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3976631044,
				43.6457473831
			],
			[
				-79.3990519788,
				43.6461834428
			],
			[
				-79.4004856189,
				43.6458828043
			],
			[
				-79.4011237421,
				43.6466354943
			],
			[
				-79.4018025582,
				43.6468464177
			],
			[
				-79.4065543921,
				43.646067601
			],
			[
				-79.4067296054,
				43.6464751767
			],
			[
				-79.4072790067,
				43.6465376147
			],
			[
				-79.4100807538,
				43.6456869656
			],
			[
				-79.4135262808,
				43.6453583128
			],
			[
				-79.4168938124,
				43.6443839841
			],
			[
				-79.4208065375,
				43.6439003713
			],
			[
				-79.4255087099,
				43.6428893457
			],
			[
				-79.4273845608,
				43.6431617808
			],
			[
				-79.4278669691,
				43.6438292525
			],
			[
				-79.4289535745,
				43.6438759865
			],
			[
				-79.4297194626,
				43.6442552645
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4004949589,
				43.6431122766
			],
			[
				-79.4006525124,
				43.6430691225
			],
			[
				-79.3998900876,
				43.6431581085
			],
			[
				-79.3997487643,
				43.644430185
			],
			[
				-79.3980444185,
				43.6448617355
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4008043241,
				43.6442581241
			],
			[
				-79.3998621642,
				43.6439809584
			],
			[
				-79.3997362876,
				43.642507795
			],
			[
				-79.4004949589,
				43.6431122766
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3997496744,
				43.6422764175
			],
			[
				-79.3995875799,
				43.6434477913
			],
			[
				-79.4000786174,
				43.644316526
			],
			[
				-79.4019317313,
				43.6439933882
			],
			[
				-79.4026197213,
				43.6442452201
			],
			[
				-79.4036055212,
				43.6463520679
			],
			[
				-79.4038575297,
				43.6463226298
			],
			[
				-79.4039135772,
				43.646813698
			],
			[
				-79.4045709398,
				43.6470577159
			],
			[
				-79.4062958037,
				43.6468404321
			],
			[
				-79.4105345434,
				43.645754131
			],
			[
				-79.4124087134,
				43.6455905176
			],
			[
				-79.4191091455,
				43.6440454384
			],
			[
				-79.4194284803,
				43.6437928443
			],
			[
				-79.4248332771,
				43.642977694
			],
			[
				-79.4261030988,
				43.6428573187
			],
			[
				-79.4267925568,
				43.6430746743
			],
			[
				-79.4277200075,
				43.6438020852
			],
			[
				-79.4291571657,
				43.644043331
			],
			[
				-79.4296125452,
				43.6423287832
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.397971664,
				43.6449994021
			],
			[
				-79.4000839927,
				43.6444132245
			],
			[
				-79.3996252649,
				43.6432097955
			],
			[
				-79.3999049469,
				43.6426152528
			],
			[
				-79.3997496744,
				43.6422764175
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3990365453,
				43.6473538275
			],
			[
				-79.3994922813,
				43.6473604891
			],
			[
				-79.3996857151,
				43.6471090938
			],
			[
				-79.3989793084,
				43.6473767404
			],
			[
				-79.3982362089,
				43.6461827442
			],
			[
				-79.3980103803,
				43.6461662427
			],
			[
				-79.397820069,
				43.645577212
			],
			[
				-79.3963809698,
				43.6464612448
			],
			[
				-79.3955474098,
				43.6466801528
			],
			[
				-79.3957689957,
				43.6479886485
			],
			[
				-79.3972407272,
				43.6501833388
			],
			[
				-79.3968630576,
				43.6504921865
			],
			[
				-79.3975632456,
				43.6516940261
			],
			[
				-79.3978126089,
				43.652701968
			],
			[
				-79.3954312146,
				43.6534736561
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3975221807,
				43.6456442629
			],
			[
				-79.3959875697,
				43.646571677
			],
			[
				-79.3953547191,
				43.6465722745
			],
			[
				-79.3956458975,
				43.6455075381
			],
			[
				-79.3996392,
				43.6445067
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3986506625,
				43.6472186072
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3984498951,
				43.6449736112
			],
			[
				-79.3986437843,
				43.6448206656
			],
			[
				-79.3978673823,
				43.6450514384
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4004990025,
				43.6456052565
			],
			[
				-79.4008558347,
				43.6458077794
			],
			[
				-79.4001130365,
				43.6446833684
			],
			[
				-79.3995408389,
				43.6446001388
			],
			[
				-79.3979986845,
				43.6449594046
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3978610447,
				43.6450871626
			],
			[
				-79.4000943483,
				43.6444826362
			],
			[
				-79.3994734786,
				43.6433950634
			],
			[
				-79.3999452649,
				43.6420348425
			],
			[
				-79.3995591389,
				43.6432770764
			],
			[
				-79.4004990025,
				43.6456052565
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.422431882,
				43.6534254198
			],
			[
				-79.4212543258,
				43.6510948063
			],
			[
				-79.4178442741,
				43.6516639236
			],
			[
				-79.4170749935,
				43.6502632616
			],
			[
				-79.4061486076,
				43.652291596
			],
			[
				-79.4058322509,
				43.6521963078
			],
			[
				-79.4053613321,
				43.650873779
			],
			[
				-79.4031202933,
				43.6501090839
			],
			[
				-79.401989824,
				43.647807293
			],
			[
				-79.4011697003,
				43.6477688515
			],
			[
				-79.3996555849,
				43.6481823371
			],
			[
				-79.3993832503,
				43.6475202203
			],
			[
				-79.3985267509,
				43.6474903694
			],
			[
				-79.3980144473,
				43.6458147617
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4140067791,
				43.6483084868
			],
			[
				-79.4185844105,
				43.6498794662
			],
			[
				-79.4207532696,
				43.649461216
			],
			[
				-79.4225235898,
				43.6535604665
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3838290264,
				43.6419279431
			],
			[
				-79.3967008668,
				43.64573382
			],
			[
				-79.397858102,
				43.6454948219
			],
			[
				-79.3981239457,
				43.6461982729
			],
			[
				-79.4027486299,
				43.645260725
			],
			[
				-79.4030678708,
				43.6453698362
			],
			[
				-79.4034400359,
				43.6465516716
			],
			[
				-79.4038253795,
				43.6466369305
			],
			[
				-79.4045243563,
				43.6462976986
			],
			[
				-79.4094191583,
				43.6453839995
			],
			[
				-79.4099218106,
				43.6458776798
			],
			[
				-79.4113492192,
				43.6456654395
			],
			[
				-79.4142527879,
				43.6467456225
			],
			[
				-79.4153663159,
				43.6487883391
			],
			[
				-79.4166718864,
				43.6491470579
			],
			[
				-79.4171303225,
				43.650005794
			],
			[
				-79.4181017876,
				43.6498426481
			],
			[
				-79.4187714058,
				43.6514686361
			],
			[
				-79.4213194292,
				43.65111041
			],
			[
				-79.4224768514,
				43.6535111847
			],
			[
				-79.4236510652,
				43.653483021
			],
			[
				-79.4225063922,
				43.6535528608
			],
			[
				-79.4204715074,
				43.6494274607
			],
			[
				-79.418454188,
				43.6498712988
			],
			[
				-79.416734996,
				43.6491401987
			],
			[
				-79.4158546819,
				43.6493820872
			],
			[
				-79.4157321656,
				43.6496160547
			],
			[
				-79.4138090632,
				43.6489207736
			],
			[
				-79.4141723612,
				43.6494964142
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3992837708,
				43.646461049
			],
			[
				-79.3981682686,
				43.6462953792
			],
			[
				-79.3978570542,
				43.6455245646
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3976950541,
				43.6455878208
			],
			[
				-79.3985321803,
				43.6464769428
			],
			[
				-79.3992837708,
				43.646461049
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4074292031,
				43.6466064173
			],
			[
				-79.4065714166,
				43.6466695548
			],
			[
				-79.4061558399,
				43.6460081412
			],
			[
				-79.4036724916,
				43.6464099197
			],
			[
				-79.4032169003,
				43.6453530302
			],
			[
				-79.4029223265,
				43.645328504
			],
			[
				-79.3984611884,
				43.6461222927
			],
			[
				-79.3981667784,
				43.6461234035
			],
			[
				-79.3977782653,
				43.6455937096
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3975155688,
				43.6457091967
			],
			[
				-79.3973929829,
				43.6458073633
			],
			[
				-79.3981642424,
				43.6461263987
			],
			[
				-79.4004983157,
				43.6458091868
			],
			[
				-79.4011106264,
				43.6468934435
			],
			[
				-79.403270459,
				43.6465565024
			],
			[
				-79.4036917861,
				43.6466563261
			],
			[
				-79.4036833875,
				43.6471844378
			],
			[
				-79.4047024849,
				43.647249259
			],
			[
				-79.4074292031,
				43.6466064173
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3979203526,
				43.6458655186
			],
			[
				-79.3989476893,
				43.6474300808
			],
			[
				-79.3988538847,
				43.6475744323
			],
			[
				-79.3986553603,
				43.647266969
			],
			[
				-79.398745527,
				43.6465636651
			],
			[
				-79.398243943,
				43.6459929143
			],
			[
				-79.3977018858,
				43.6456709431
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.39800103,
				43.6459592009
			],
			[
				-79.3983890932,
				43.6467541744
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065668586,
				43.673584164
			],
			[
				-79.4042151777,
				43.6678963704
			],
			[
				-79.404309205,
				43.6670461757
			],
			[
				-79.4062691914,
				43.6666272939
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4021995204,
				43.666342276
			],
			[
				-79.4028188296,
				43.666193932
			],
			[
				-79.403608507,
				43.6664612042
			],
			[
				-79.4064677129,
				43.6728913462
			],
			[
				-79.4065668586,
				43.673584164
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4026463782,
				43.6561797316
			],
			[
				-79.4028970351,
				43.6567752755
			],
			[
				-79.403576541,
				43.6572557292
			],
			[
				-79.4133308793,
				43.6552344554
			],
			[
				-79.4168749934,
				43.6551750128
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4000575569,
				43.6529719433
			],
			[
				-79.4005808015,
				43.654677892
			],
			[
				-79.4019556421,
				43.6545973358
			],
			[
				-79.4026463782,
				43.6561797316
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.415624,
				43.654979
			],
			[
				-79.413089161,
				43.6553158261
			],
			[
				-79.4048567214,
				43.6570693074
			],
			[
				-79.4033772334,
				43.6571612086
			],
			[
				-79.4030417081,
				43.6570390694
			],
			[
				-79.4019619228,
				43.6546919918
			],
			[
				-79.400600707,
				43.6542943271
			],
			[
				-79.4000575569,
				43.6529719433
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4293682302,
				43.6447337074
			],
			[
				-79.4287034459,
				43.6447990554
			],
			[
				-79.4281039143,
				43.6452430289
			],
			[
				-79.4236672139,
				43.6461225201
			],
			[
				-79.4235348852,
				43.6463282465
			],
			[
				-79.4258418936,
				43.6523526693
			],
			[
				-79.4253327159,
				43.6528371577
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4246494053,
				43.6494957011
			],
			[
				-79.4258455304,
				43.6518757028
			],
			[
				-79.4257320533,
				43.6525766189
			],
			[
				-79.4259057409,
				43.6527390703
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.425974606,
				43.6526761725
			],
			[
				-79.4245999551,
				43.6493453567
			],
			[
				-79.424408601,
				43.6493478968
			],
			[
				-79.4246494053,
				43.6494957011
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4297064297,
				43.6454051927
			],
			[
				-79.4314527512,
				43.6496776527
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3977012198,
				43.645684189
			],
			[
				-79.3982833036,
				43.6463131158
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4268971932,
				43.6430807685
			],
			[
				-79.4261486624,
				43.6431942957
			],
			[
				-79.4265360571,
				43.6436570385
			],
			[
				-79.4269166123,
				43.6453131297
			],
			[
				-79.4255068132,
				43.645877257
			],
			[
				-79.4234360555,
				43.6462252295
			],
			[
				-79.4258934958,
				43.6522926723
			],
			[
				-79.4254488054,
				43.6527812659
			],
			[
				-79.4239693289,
				43.6530282986
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4257791726,
				43.6526603078
			],
			[
				-79.4259602581,
				43.6525891759
			],
			[
				-79.4257301621,
				43.6516303914
			],
			[
				-79.4248138592,
				43.6498456284
			],
			[
				-79.4236053774,
				43.6464338062
			],
			[
				-79.4238513467,
				43.6461538988
			],
			[
				-79.4274192926,
				43.6453435455
			],
			[
				-79.4279793469,
				43.6448392754
			],
			[
				-79.4292959197,
				43.6446544327
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4381207228,
				43.6592138678
			],
			[
				-79.4354421871,
				43.6599027218
			],
			[
				-79.4353588916,
				43.6602916427
			],
			[
				-79.4359495837,
				43.6604293912
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4354760647,
				43.6601161785
			],
			[
				-79.4381207228,
				43.6592138678
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4296805329,
				43.644404892
			],
			[
				-79.429746068,
				43.6456033578
			],
			[
				-79.4285658454,
				43.6460003935
			],
			[
				-79.4297894243,
				43.6495389328
			],
			[
				-79.4263933929,
				43.6493492873
			],
			[
				-79.4250612467,
				43.6495413956
			],
			[
				-79.4249749082,
				43.6504324042
			],
			[
				-79.4258375195,
				43.6524517246
			],
			[
				-79.4252393326,
				43.6528747085
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.418814,
				43.644397
			],
			[
				-79.4192250906,
				43.6441079657
			],
			[
				-79.4258650436,
				43.6426162585
			],
			[
				-79.4270495002,
				43.6431004857
			],
			[
				-79.4278262633,
				43.643887055
			],
			[
				-79.4286593073,
				43.6436504484
			],
			[
				-79.4290087322,
				43.6438741552
			],
			[
				-79.4290417968,
				43.6442294179
			],
			[
				-79.4295759612,
				43.6443360201
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4052414083,
				43.6662615463
			],
			[
				-79.4043161772,
				43.6666242945
			],
			[
				-79.4040624844,
				43.6672613019
			],
			[
				-79.4068733351,
				43.674179033
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065668586,
				43.673584164
			],
			[
				-79.4041412075,
				43.6671561434
			],
			[
				-79.4041035838,
				43.6668924978
			],
			[
				-79.4052414083,
				43.6662615463
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3950921055,
				43.6455398814
			],
			[
				-79.3961274508,
				43.645172618
			],
			[
				-79.3965337074,
				43.6457798573
			],
			[
				-79.3973609618,
				43.6456010487
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065668586,
				43.673584164
			],
			[
				-79.4064070532,
				43.6737137981
			],
			[
				-79.4066594851,
				43.6739518474
			],
			[
				-79.406749293,
				43.6747792525
			],
			[
				-79.4036865234,
				43.6668006169
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4154387619,
				43.6838823911
			],
			[
				-79.4156125977,
				43.6838724015
			],
			[
				-79.4151437263,
				43.6837543499
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4076899666,
				43.6752744987
			],
			[
				-79.4154424341,
				43.6837991665
			],
			[
				-79.4157985361,
				43.6837890891
			],
			[
				-79.4154387619,
				43.6838823911
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4035768593,
				43.6756686368
			],
			[
				-79.4027031426,
				43.6757620487
			],
			[
				-79.4027470864,
				43.6755409741
			],
			[
				-79.4060467489,
				43.6752115181
			],
			[
				-79.4076217985,
				43.6749029594
			],
			[
				-79.4077260356,
				43.6746866784
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4093493929,
				43.6733115862
			],
			[
				-79.4072894887,
				43.6746889387
			],
			[
				-79.4050525767,
				43.6704471787
			],
			[
				-79.4065668586,
				43.673584164
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4246494053,
				43.6494957011
			],
			[
				-79.4245945735,
				43.6493381621
			],
			[
				-79.4259727786,
				43.652718282
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3983217654,
				43.6464775596
			],
			[
				-79.3985693661,
				43.6472548494
			],
			[
				-79.3988405348,
				43.6473536292
			],
			[
				-79.398596559,
				43.6474843818
			],
			[
				-79.3984689215,
				43.6465660721
			],
			[
				-79.397988522,
				43.6458611461
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4004990025,
				43.6456052565
			],
			[
				-79.3981425056,
				43.646174938
			],
			[
				-79.3976704308,
				43.6455444597
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.397986085,
				43.6457892328
			],
			[
				-79.398867281,
				43.6461233605
			],
			[
				-79.4004990025,
				43.6456052565
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3985366821,
				43.6465412771
			],
			[
				-79.3988156517,
				43.6462438755
			],
			[
				-79.3980108198,
				43.6460889011
			],
			[
				-79.3978233307,
				43.6455861732
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39719,
				43.64598
			],
			[
				-79.3977288166,
				43.6455762697
			],
			[
				-79.3983481163,
				43.6462177735
			],
			[
				-79.3987562817,
				43.6462839501
			],
			[
				-79.3985366821,
				43.6465412771
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4315941794,
				43.6497473565
			],
			[
				-79.4305462968,
				43.6496557817
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4413443785,
				43.6396509727
			],
			[
				-79.4321138354,
				43.6415828083
			],
			[
				-79.4300530008,
				43.6417646015
			],
			[
				-79.4290556378,
				43.6421988082
			],
			[
				-79.4288849623,
				43.6424809296
			],
			[
				-79.4293605718,
				43.6440975921
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4294387286,
				43.6440863518
			],
			[
				-79.4290178348,
				43.6421443154
			],
			[
				-79.434236263,
				43.6412828028
			],
			[
				-79.4388342289,
				43.6401289505
			],
			[
				-79.4431378642,
				43.6393498264
			],
			[
				-79.4413443785,
				43.6396509727
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4012724623,
				43.6471759913
			],
			[
				-79.4015552223,
				43.6473138329
			],
			[
				-79.4014751954,
				43.6470671151
			],
			[
				-79.4000294157,
				43.6471076386
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065668586,
				43.673584164
			],
			[
				-79.4068261808,
				43.674320131
			],
			[
				-79.4074260223,
				43.6747538236
			],
			[
				-79.4118386233,
				43.6736387356
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4118386233,
				43.6736387356
			],
			[
				-79.4070771799,
				43.6747502329
			],
			[
				-79.4065668586,
				43.673584164
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3808556447,
				43.6547850294
			],
			[
				-79.3807632311,
				43.6556736357
			],
			[
				-79.3810666955,
				43.6560309624
			],
			[
				-79.3824366886,
				43.6560500197
			],
			[
				-79.383379536,
				43.6557253609
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3805894075,
				43.6533409524
			],
			[
				-79.3803959848,
				43.6529861847
			],
			[
				-79.3808556447,
				43.6547850294
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3801161772,
				43.6525133849
			],
			[
				-79.3803262686,
				43.6528231261
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4376528977,
				43.6402295273
			],
			[
				-79.4371707393,
				43.6405289709
			],
			[
				-79.4287359698,
				43.6422265725
			],
			[
				-79.428775146,
				43.6428147925
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4291781791,
				43.6438561881
			],
			[
				-79.4291418236,
				43.6432097673
			],
			[
				-79.4298652236,
				43.6420741743
			],
			[
				-79.434049335,
				43.6412905611
			],
			[
				-79.4376528977,
				43.6402295273
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4341008235,
				43.6555330791
			],
			[
				-79.4334352271,
				43.6548691638
			],
			[
				-79.4324738137,
				43.6526707839
			],
			[
				-79.4268249535,
				43.6534337883
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4358017958,
				43.6555510699
			],
			[
				-79.4352320192,
				43.6549884376
			],
			[
				-79.4348029255,
				43.6549559077
			],
			[
				-79.4341436495,
				43.6551315307
			],
			[
				-79.4341008235,
				43.6555330791
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4335428837,
				43.6548928391
			],
			[
				-79.4334171018,
				43.6546602696
			],
			[
				-79.4337998125,
				43.6550161479
			],
			[
				-79.4352400483,
				43.6550198528
			],
			[
				-79.4358017958,
				43.6555510699
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4299467299,
				43.6461888529
			],
			[
				-79.4312829898,
				43.6490459385
			],
			[
				-79.431235843,
				43.6495892592
			],
			[
				-79.4256925117,
				43.649536016
			],
			[
				-79.4251223439,
				43.64991701
			],
			[
				-79.4257523151,
				43.6523903013
			],
			[
				-79.4247732969,
				43.6530250673
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4255308337,
				43.6527868029
			],
			[
				-79.42572626,
				43.6519983575
			],
			[
				-79.4255523938,
				43.6510149055
			],
			[
				-79.425148148,
				43.6503304743
			],
			[
				-79.4252203884,
				43.6494883465
			],
			[
				-79.4258462193,
				43.649370764
			],
			[
				-79.4272818946,
				43.6496211431
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4163309357,
				43.6490108621
			],
			[
				-79.4182612729,
				43.6499117265
			],
			[
				-79.4208622857,
				43.6495143682
			],
			[
				-79.4225264686,
				43.6534637263
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4036553524,
				43.6465318275
			],
			[
				-79.4093947334,
				43.6454293546
			],
			[
				-79.4097754683,
				43.6454833152
			],
			[
				-79.4100584099,
				43.6459111095
			],
			[
				-79.4118702128,
				43.6458508953
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4016187899,
				43.6475549355
			],
			[
				-79.4006165812,
				43.6457976194
			],
			[
				-79.398581681,
				43.6460909778
			],
			[
				-79.3977308575,
				43.6455160469
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4006842522,
				43.6441788956
			],
			[
				-79.3976353519,
				43.6456704689
			],
			[
				-79.3977055209,
				43.6459215245
			],
			[
				-79.3988320022,
				43.6462027138
			],
			[
				-79.4004678408,
				43.6457102435
			],
			[
				-79.4007099615,
				43.6458689691
			],
			[
				-79.4011789135,
				43.647301672
			],
			[
				-79.4016187899,
				43.6475549355
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4297074368,
				43.6454943389
			],
			[
				-79.4284114714,
				43.6458426863
			],
			[
				-79.4292788349,
				43.6477802379
			],
			[
				-79.4295219867,
				43.6492638479
			],
			[
				-79.4271655616,
				43.649597252
			],
			[
				-79.4249724761,
				43.6493831953
			],
			[
				-79.4249947017,
				43.6504685383
			],
			[
				-79.4255951962,
				43.6519364959
			],
			[
				-79.4253736397,
				43.6526646458
			],
			[
				-79.4252594729,
				43.6529021928
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.420682,
				43.648912
			],
			[
				-79.4224766898,
				43.6484573371
			],
			[
				-79.423232033,
				43.6492795093
			],
			[
				-79.4273497805,
				43.6495441995
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4276805131,
				43.6453228894
			],
			[
				-79.4202572862,
				43.6469489631
			],
			[
				-79.4200974161,
				43.6477383432
			],
			[
				-79.420682,
				43.648912
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4251983863,
				43.6528761659
			],
			[
				-79.4258517053,
				43.6524497832
			],
			[
				-79.4235792536,
				43.6462535055
			],
			[
				-79.4279189267,
				43.645357597
			],
			[
				-79.429413457,
				43.6442329228
			],
			[
				-79.4296480338,
				43.644440593
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.43017,
				43.641972
			],
			[
				-79.4296629949,
				43.6443161594
			],
			[
				-79.430185949,
				43.6465374379
			],
			[
				-79.431302234,
				43.6494834442
			],
			[
				-79.4296585882,
				43.6496970228
			],
			[
				-79.4249486382,
				43.6495594592
			],
			[
				-79.4248908036,
				43.6500045701
			],
			[
				-79.425909384,
				43.6522414605
			],
			[
				-79.4258556754,
				43.6526682592
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4339170303,
				43.6604997468
			],
			[
				-79.435326847,
				43.6601003753
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4314338083,
				43.6497582581
			],
			[
				-79.4236436536,
				43.6494930956
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4305462968,
				43.6496557817
			],
			[
				-79.425064527,
				43.6496469452
			],
			[
				-79.4252670547,
				43.6507512258
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4293074408,
				43.6442362623
			],
			[
				-79.4290841777,
				43.6439378511
			],
			[
				-79.4279462084,
				43.6437934716
			],
			[
				-79.4269355601,
				43.642981624
			],
			[
				-79.423247945,
				43.643230856
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4118386233,
				43.6736387356
			],
			[
				-79.4089041876,
				43.6746228781
			],
			[
				-79.4078377953,
				43.6747179524
			],
			[
				-79.4065668586,
				43.673584164
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065668586,
				43.673584164
			],
			[
				-79.4066311815,
				43.6740934584
			],
			[
				-79.4072709502,
				43.6746881956
			],
			[
				-79.4118386233,
				43.6736387356
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42029,
				43.66862
			],
			[
				-79.4203462417,
				43.6682539881
			],
			[
				-79.4201094952,
				43.6681595087
			],
			[
				-79.4160660728,
				43.6687797355
			],
			[
				-79.4130464804,
				43.6695354332
			],
			[
				-79.4127073417,
				43.6701668018
			],
			[
				-79.4092580754,
				43.6710029158
			],
			[
				-79.408469911,
				43.6713885585
			],
			[
				-79.4081378352,
				43.671833068
			],
			[
				-79.4063447592,
				43.6722314081
			],
			[
				-79.4063851382,
				43.6734161426
			],
			[
				-79.4065668586,
				43.673584164
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4093941233,
				43.6745363255
			],
			[
				-79.4125223902,
				43.6735347519
			],
			[
				-79.4209590656,
				43.6718122028
			],
			[
				-79.4212607511,
				43.6713593484
			],
			[
				-79.4204076326,
				43.6682534661
			],
			[
				-79.42029,
				43.66862
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065668586,
				43.673584164
			],
			[
				-79.4066959024,
				43.6743435661
			],
			[
				-79.4070126749,
				43.6747197825
			],
			[
				-79.4093941233,
				43.6745363255
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4294649964,
				43.6441752047
			],
			[
				-79.4280086277,
				43.6437922992
			],
			[
				-79.4272637705,
				43.6432733756
			],
			[
				-79.4269631421,
				43.6426363545
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4352489341,
				43.6409161263
			],
			[
				-79.4285685085,
				43.6422286657
			],
			[
				-79.4286607163,
				43.6436221226
			],
			[
				-79.4291171599,
				43.6433863355
			],
			[
				-79.4295634594,
				43.6442563232
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4292608671,
				43.6440820509
			],
			[
				-79.4287114503,
				43.6422498937
			],
			[
				-79.4294833294,
				43.6421160955
			],
			[
				-79.4298190968,
				43.642448186
			],
			[
				-79.4298184422,
				43.6420587203
			],
			[
				-79.4352489341,
				43.6409161263
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4276118722,
				43.6437607373
			],
			[
				-79.4271140273,
				43.6429119711
			],
			[
				-79.4265777554,
				43.6427146051
			],
			[
				-79.423247945,
				43.643230856
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4239124195,
				43.6532455962
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3982666069,
				43.6531019304
			],
			[
				-79.3963125909,
				43.6481653329
			],
			[
				-79.3976806919,
				43.6476744521
			],
			[
				-79.39719,
				43.64598
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.405959,
				43.675068
			],
			[
				-79.4069206923,
				43.6745758987
			],
			[
				-79.4065668586,
				43.673584164
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4065668586,
				43.673584164
			],
			[
				-79.4068839,
				43.6747457655
			],
			[
				-79.405959,
				43.675068
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.41939,
				43.654712
			],
			[
				-79.4202349283,
				43.6548548462
			],
			[
				-79.4227183907,
				43.6543892224
			],
			[
				-79.4237511775,
				43.6537661753
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4235404912,
				43.6541187421
			],
			[
				-79.4231014292,
				43.6543073664
			],
			[
				-79.4200187872,
				43.6549217919
			],
			[
				-79.41939,
				43.654712
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3913296354,
				43.6465377967
			],
			[
				-79.3912786202,
				43.6463907661
			],
			[
				-79.394943445,
				43.6455084879
			],
			[
				-79.3952809633,
				43.6462890857
			],
			[
				-79.3950845841,
				43.6465475259
			],
			[
				-79.3960886559,
				43.6477393279
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.3859397993,
				43.6493852118
			],
			[
				-79.3857265706,
				43.6489519309
			],
			[
				-79.3862677643,
				43.6483496846
			],
			[
				-79.3860528636,
				43.6472788804
			],
			[
				-79.3887960212,
				43.6465620241
			],
			[
				-79.3909062102,
				43.6463504415
			],
			[
				-79.3913296354,
				43.6465377967
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.39016819,
				43.6466654932
			],
			[
				-79.3899526312,
				43.6464321943
			],
			[
				-79.3881210854,
				43.6468652822
			],
			[
				-79.3866737478,
				43.6468709709
			],
			[
				-79.3860985399,
				43.6474790298
			],
			[
				-79.3857581653,
				43.6490546078
			],
			[
				-79.3859397993,
				43.6493852118
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4231160418,
				43.6433262374
			],
			[
				-79.4237771901,
				43.6428267873
			],
			[
				-79.4259457389,
				43.6428515467
			],
			[
				-79.4272360207,
				43.6431374602
			],
			[
				-79.4278689502,
				43.6438739524
			],
			[
				-79.4294417377,
				43.6441087974
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4269631421,
				43.6426363545
			],
			[
				-79.4231160418,
				43.6433262374
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.41939,
				43.654712
			],
			[
				-79.4200174868,
				43.6545374251
			],
			[
				-79.4205766259,
				43.6548203736
			],
			[
				-79.4225592506,
				43.6544501978
			],
			[
				-79.42292109,
				43.6538059951
			],
			[
				-79.4234082956,
				43.6537047693
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4233618569,
				43.6537848058
			],
			[
				-79.4228839865,
				43.6538327409
			],
			[
				-79.421899238,
				43.6545478093
			],
			[
				-79.420475154,
				43.6547417914
			],
			[
				-79.4199986761,
				43.6543801344
			],
			[
				-79.4196374718,
				43.6543985621
			],
			[
				-79.41939,
				43.654712
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4324657354,
				43.6505998404
			],
			[
				-79.4315911927,
				43.649660411
			],
			[
				-79.4294883543,
				43.6496000684
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4067141183,
				43.6746199922
			],
			[
				-79.4068617014,
				43.6747601038
			],
			[
				-79.4065668586,
				43.673584164
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.391581739,
				43.6597064729
			],
			[
				-79.3908703072,
				43.6600576009
			],
			[
				-79.3905378712,
				43.6598453077
			],
			[
				-79.3900449766,
				43.6600504976
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4236912989,
				43.6538843036
			],
			[
				-79.423389817,
				43.6542313449
			],
			[
				-79.4207707764,
				43.6547804823
			],
			[
				-79.41939,
				43.654712
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4230053952,
				43.6544385171
			],
			[
				-79.4234639638,
				43.6541589281
			],
			[
				-79.4232923005,
				43.6537801947
			],
			[
				-79.42349,
				43.65339
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4491784576,
				43.6708167933
			],
			[
				-79.4489334685,
				43.6708856859
			],
			[
				-79.4492513049,
				43.670539716
			],
			[
				-79.4489842272,
				43.6701286671
			],
			[
				-79.4476860697,
				43.6697657601
			],
			[
				-79.4463166449,
				43.6668875832
			],
			[
				-79.4414845463,
				43.668248414
			],
			[
				-79.4401686767,
				43.6678096982
			],
			[
				-79.4400083927,
				43.6686689043
			],
			[
				-79.4389794668,
				43.6684640395
			],
			[
				-79.4377034676,
				43.6686503642
			],
			[
				-79.4292297032,
				43.6702707043
			],
			[
				-79.4292347067,
				43.6704461683
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6190325486,
				43.6850490146
			],
			[
				-79.619297016,
				43.6858114242
			],
			[
				-79.6202169432,
				43.6864631813
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6211401839,
				43.685206945
			],
			[
				-79.6190325486,
				43.6850490146
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.6209624565,
				43.6843229414
			],
			[
				-79.6208264469,
				43.6844124565
			],
			[
				-79.6162762839,
				43.6807798255
			],
			[
				-79.6168608457,
				43.6818423157
			],
			[
				-79.6169985948,
				43.6817479052
			],
			[
				-79.6164065385,
				43.6809397966
			],
			[
				-79.6168401429,
				43.6824293412
			],
			[
				-79.6192974957,
				43.6842139663
			],
			[
				-79.6211401839,
				43.685206945
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1357289916,
				36.0858530527
			],
			[
				-115.1364425843,
				36.0851715763
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.170716311,
				36.123978129
			],
			[
				-115.1702947235,
				36.1255537051
			],
			[
				-115.1696099257,
				36.1259891302
			],
			[
				-115.1664610949,
				36.1298319166
			],
			[
				-115.1649756712,
				36.1325054334
			],
			[
				-115.1633107727,
				36.1336324459
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1443736952,
				36.1707784485
			],
			[
				-115.1457804712,
				36.1715463514
			],
			[
				-115.1467513317,
				36.1716431668
			],
			[
				-115.1465548443,
				36.1715221357
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1457421392,
				36.1716193298
			],
			[
				-115.1460612406,
				36.1720330927
			],
			[
				-115.1457633664,
				36.1726390267
			],
			[
				-115.1419341131,
				36.1714540495
			],
			[
				-115.1429289329,
				36.1717589826
			],
			[
				-115.1434865237,
				36.1715337051
			],
			[
				-115.1438881585,
				36.1708753744
			],
			[
				-115.1443736952,
				36.1707784485
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1823779941,
				36.1013167139
			],
			[
				-115.1832199854,
				36.1005959318
			],
			[
				-115.1831952469,
				36.1002210823
			],
			[
				-115.1811347721,
				36.1005472832
			],
			[
				-115.1790127196,
				36.1000412811
			],
			[
				-115.1779226804,
				36.1006246765
			],
			[
				-115.1741199443,
				36.1007188218
			],
			[
				-115.1732909006,
				36.101332289
			],
			[
				-115.1730372669,
				36.1031936854
			],
			[
				-115.1732986191,
				36.104779527
			],
			[
				-115.1731184066,
				36.1058033908
			],
			[
				-115.1733678121,
				36.1068658995
			],
			[
				-115.1737646475,
				36.1071333556
			],
			[
				-115.173503632,
				36.1068490911
			],
			[
				-115.1732330897,
				36.1072036647
			],
			[
				-115.1727970492,
				36.1085262284
			],
			[
				-115.173414282,
				36.1090575292
			],
			[
				-115.1731267193,
				36.1093760607
			],
			[
				-115.1729921521,
				36.1105840778
			],
			[
				-115.1731118679,
				36.1140926206
			],
			[
				-115.1735290292,
				36.114451001
			],
			[
				-115.1728945821,
				36.1159957055
			],
			[
				-115.1729635534,
				36.1187018051
			],
			[
				-115.1726751026,
				36.1197282857
			],
			[
				-115.172724077,
				36.120037078
			],
			[
				-115.1732772509,
				36.1202647853
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1733766635,
				36.1214339117
			],
			[
				-115.174068551,
				36.1211129463
			],
			[
				-115.1750510084,
				36.1211732994
			],
			[
				-115.1741599949,
				36.1210115717
			],
			[
				-115.1727598924,
				36.1196722375
			],
			[
				-115.1730411738,
				36.1195024403
			],
			[
				-115.1728135908,
				36.1189753688
			],
			[
				-115.1730510919,
				36.115660404
			],
			[
				-115.1737589453,
				36.1145656732
			],
			[
				-115.1729822605,
				36.1140846522
			],
			[
				-115.1723193173,
				36.1129566902
			],
			[
				-115.1725965461,
				36.1127161065
			],
			[
				-115.1726208226,
				36.1116197988
			],
			[
				-115.1731418829,
				36.1109334011
			],
			[
				-115.1730548391,
				36.1106499666
			],
			[
				-115.1733991882,
				36.1100171588
			],
			[
				-115.1733118579,
				36.1096304432
			],
			[
				-115.1725731248,
				36.1095609181
			],
			[
				-115.1727690261,
				36.1091574497
			],
			[
				-115.1725729683,
				36.1086234241
			],
			[
				-115.1727277968,
				36.1082618487
			],
			[
				-115.1725410688,
				36.1082453335
			],
			[
				-115.1727592371,
				36.1073679827
			],
			[
				-115.1724236097,
				36.1062930074
			],
			[
				-115.1721485573,
				36.1065743826
			],
			[
				-115.172980735,
				36.1062810449
			],
			[
				-115.1728242071,
				36.1050252418
			],
			[
				-115.1724423916,
				36.1043277228
			],
			[
				-115.172869131,
				36.1031765047
			],
			[
				-115.1726362837,
				36.1023364198
			],
			[
				-115.1730761403,
				36.1019476264
			],
			[
				-115.1730757317,
				36.101555378
			],
			[
				-115.1727858957,
				36.1013973225
			],
			[
				-115.1731140506,
				36.1015452979
			],
			[
				-115.1734476799,
				36.1011556096
			],
			[
				-115.1740124439,
				36.1010388821
			],
			[
				-115.1744405694,
				36.1014367773
			],
			[
				-115.1750084207,
				36.1014332717
			],
			[
				-115.1755375991,
				36.1019386069
			],
			[
				-115.1756689167,
				36.1026869361
			],
			[
				-115.1759768182,
				36.1012442549
			],
			[
				-115.1791126778,
				36.1011559444
			],
			[
				-115.1786035865,
				36.1008281695
			],
			[
				-115.1780134104,
				36.1014865049
			],
			[
				-115.1804808662,
				36.1005616292
			],
			[
				-115.1830081835,
				36.1012589462
			],
			[
				-115.1834263796,
				36.1012439762
			],
			[
				-115.1834607878,
				36.1009987105
			],
			[
				-115.1832259166,
				36.1007504415
			],
			[
				-115.183273697,
				36.1009021844
			],
			[
				-115.1823779941,
				36.1013167139
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1724879486,
				36.1124531268
			],
			[
				-115.1727854692,
				36.1122333454
			],
			[
				-115.1727151152,
				36.109647354
			],
			[
				-115.1729786504,
				36.1093865864
			],
			[
				-115.1726773759,
				36.1097735575
			],
			[
				-115.1728282066,
				36.110784555
			],
			[
				-115.1726125284,
				36.1112775361
			],
			[
				-115.1731492032,
				36.1117669077
			],
			[
				-115.1731526204,
				36.1120750274
			],
			[
				-115.1726238885,
				36.1132597294
			],
			[
				-115.1728754857,
				36.1140462131
			],
			[
				-115.1737478963,
				36.1145226413
			],
			[
				-115.1735178977,
				36.1158119934
			],
			[
				-115.1730413881,
				36.1164626712
			],
			[
				-115.1729000173,
				36.1172991177
			],
			[
				-115.1731105419,
				36.1172772282
			],
			[
				-115.1728345199,
				36.1178057588
			],
			[
				-115.1730551696,
				36.1193021296
			],
			[
				-115.1728491163,
				36.1193463671
			],
			[
				-115.1727460089,
				36.1199723221
			],
			[
				-115.1741575227,
				36.1211558415
			],
			[
				-115.1750739297,
				36.1211568003
			],
			[
				-115.1749717126,
				36.1209667333
			],
			[
				-115.1733766635,
				36.1214339117
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1729399833,
				36.1142097443
			],
			[
				-115.1727691462,
				36.1125954236
			],
			[
				-115.1724879486,
				36.1124531268
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1744230967,
				36.1160888018
			],
			[
				-115.1738444227,
				36.1161773937
			],
			[
				-115.174082945,
				36.1162207962
			],
			[
				-115.1738337045,
				36.1160452757
			],
			[
				-115.1739392263,
				36.1158875869
			],
			[
				-115.1742465374,
				36.1161993332
			],
			[
				-115.1735106013,
				36.1148630068
			],
			[
				-115.1737122632,
				36.1143948961
			],
			[
				-115.1729399833,
				36.1142097443
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1745794081,
				36.1173975968
			],
			[
				-115.1749679488,
				36.1174754511
			],
			[
				-115.1753540512,
				36.1166855028
			],
			[
				-115.1737260031,
				36.1152475153
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1633107727,
				36.1336324459
			],
			[
				-115.1632711782,
				36.1342403832
			],
			[
				-115.1640634678,
				36.1326797342
			],
			[
				-115.1678776172,
				36.1274504763
			],
			[
				-115.1674190237,
				36.1277345725
			],
			[
				-115.1682362376,
				36.1270559032
			],
			[
				-115.1683510431,
				36.1267656514
			],
			[
				-115.1674586577,
				36.1276567835
			],
			[
				-115.1686118562,
				36.1255150629
			],
			[
				-115.1701046602,
				36.1239643766
			],
			[
				-115.1709957391,
				36.1223380071
			],
			[
				-115.1713447986,
				36.1223057498
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1645380561,
				36.1321670453
			],
			[
				-115.1633107727,
				36.1336324459
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1743233733,
				36.1211492623
			],
			[
				-115.172898527,
				36.1210939919
			],
			[
				-115.1730811598,
				36.1212336216
			],
			[
				-115.1729668022,
				36.1215826598
			],
			[
				-115.1711716921,
				36.1222311883
			],
			[
				-115.1711451791,
				36.1228885424
			],
			[
				-115.170784586,
				36.122984477
			],
			[
				-115.1703110935,
				36.1238405213
			],
			[
				-115.1698270153,
				36.1255793738
			],
			[
				-115.1685865558,
				36.1262632024
			],
			[
				-115.1676731043,
				36.1272289157
			],
			[
				-115.1669385633,
				36.1286038809
			],
			[
				-115.1658497754,
				36.1294100849
			],
			[
				-115.1656426704,
				36.1303498789
			],
			[
				-115.1646454242,
				36.1316885357
			],
			[
				-115.1645381232,
				36.132166929
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.16557861,
				36.1223935086
			],
			[
				-115.1658993572,
				36.1227444874
			],
			[
				-115.1657153582,
				36.1227606747
			],
			[
				-115.1660167991,
				36.1227905239
			],
			[
				-115.1677780112,
				36.1222854624
			],
			[
				-115.1689538908,
				36.1228529491
			],
			[
				-115.1712215716,
				36.1222798518
			],
			[
				-115.17145092,
				36.1219597822
			],
			[
				-115.1730815821,
				36.1219508467
			],
			[
				-115.173389206,
				36.1215161297
			],
			[
				-115.1743402715,
				36.1210581482
			],
			[
				-115.1751310043,
				36.1211915889
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.16557861,
				36.1223935086
			],
			[
				-115.1661440043,
				36.1228017169
			],
			[
				-115.1672564058,
				36.1223952997
			],
			[
				-115.168169703,
				36.1224386617
			],
			[
				-115.1687100372,
				36.1226763155
			],
			[
				-115.1693957438,
				36.1237030087
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1743233733,
				36.1211492623
			],
			[
				-115.1739773115,
				36.1207845347
			],
			[
				-115.1737194976,
				36.1211171409
			],
			[
				-115.1723029497,
				36.1214314
			],
			[
				-115.1717529062,
				36.121761537
			],
			[
				-115.171789648,
				36.122217532
			],
			[
				-115.1674551163,
				36.1223350854
			],
			[
				-115.1660608143,
				36.1227503399
			],
			[
				-115.1652414757,
				36.1217697935
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1733768601,
				36.1207381121
			],
			[
				-115.1725746255,
				36.1197221528
			],
			[
				-115.1722110175,
				36.1185643639
			],
			[
				-115.1712089418,
				36.1174296829
			],
			[
				-115.1695936029,
				36.1177067051
			],
			[
				-115.1696499971,
				36.1175161291
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1653857637,
				36.1236256921
			],
			[
				-115.1686004992,
				36.1255140332
			],
			[
				-115.1684401135,
				36.1254817712
			],
			[
				-115.1685639904,
				36.1250948189
			],
			[
				-115.1711883444,
				36.1249611752
			],
			[
				-115.1714317122,
				36.1244467408
			],
			[
				-115.17140491,
				36.1221755663
			],
			[
				-115.1712229218,
				36.1221153111
			],
			[
				-115.1722161504,
				36.1201084935
			],
			[
				-115.172764111,
				36.1205925184
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1664332271,
				36.1211421413
			],
			[
				-115.1708962009,
				36.1224391801
			],
			[
				-115.1699370431,
				36.1241563041
			],
			[
				-115.1682635102,
				36.12567528
			],
			[
				-115.1675331918,
				36.1253164528
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1712256843,
				36.1219560507
			],
			[
				-115.1708849981,
				36.1221321725
			],
			[
				-115.170725547,
				36.1230441036
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.172860788,
				36.1215206837
			],
			[
				-115.1736873027,
				36.1209833932
			],
			[
				-115.1720612562,
				36.1216660434
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1719295014,
				36.1208818869
			],
			[
				-115.1733768601,
				36.1207381121
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1722421563,
				36.1224509955
			],
			[
				-115.1724446481,
				36.1197823224
			],
			[
				-115.1719295014,
				36.1208818869
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.165008669,
				36.1208983517
			],
			[
				-115.1722421563,
				36.1224509955
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.170725547,
				36.1230441036
			],
			[
				-115.1677243275,
				36.1216192612
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1739779139,
				36.1213097387
			],
			[
				-115.1736935733,
				36.1214672796
			],
			[
				-115.1737517301,
				36.121706247
			],
			[
				-115.1734071763,
				36.1210197312
			],
			[
				-115.1715221485,
				36.120190044
			],
			[
				-115.1713457064,
				36.1203179805
			],
			[
				-115.1718473797,
				36.1213022757
			],
			[
				-115.170725547,
				36.1230441036
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1728065798,
				36.1175720026
			],
			[
				-115.1727781042,
				36.1168666573
			],
			[
				-115.1729689635,
				36.1198109984
			],
			[
				-115.1739779139,
				36.1213097387
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1733766635,
				36.1214339117
			],
			[
				-115.1728065798,
				36.1175720026
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-115.1369974306,
				36.0853321818
			],
			[
				-115.1394076589,
				36.086930777
			],
			[
				-115.1387225201,
				36.0868759358
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4314527512,
				43.6496776527
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.42349,
				43.65339
			],
			[
				-79.4259227912,
				43.6525460463
			],
			[
				-79.42495196,
				43.6496059447
			],
			[
				-79.4314527512,
				43.6496776527
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4245983762,
				43.649143724
			],
			[
				-79.4235990579,
				43.6463467527
			],
			[
				-79.4270864815,
				43.6454891963
			],
			[
				-79.4292963883,
				43.6446527492
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.43017,
				43.641972
			],
			[
				-79.4288279646,
				43.6423496729
			],
			[
				-79.4294455693,
				43.6441208664
			],
			[
				-79.4294142723,
				43.6439326816
			]
		],
		"dupe": false
	},
	{
		"points": [
			[
				-79.4294142723,
				43.6439326816
			],
			[
				-79.4294816282,
				43.6441403978
			],
			[
				-79.4289287895,
				43.6432860235
			],
			[
				-79.4285909632,
				43.6419816991
			],
			[
				-79.43017,
				43.641972
			]
		]
	},
	{
		"points": [
			[
				-79.4203185495,
				43.6494337752
			],
			[
				-79.4210707967,
				43.6501841122
			],
			[
				-79.422207166,
				43.6533035782
			],
			[
				-79.4224149911,
				43.6535255079
			],
			[
				-79.42349,
				43.65339
			]
		]
	}
]