module.exports={
	"title": "Audio & Video",
	"text": "My year consisted mostly of rewatching The&nbsp;X-Files and listening to Father John Misty on loop at least once a week.",
	"sources": ["iTunes Library", "Last.fm", "Netflix", "The X-Files Skippable Guide", "Scene"],
	"stats": [
		{
			"title": "Top album play count",
			"value": 747,
			"secondary_value": "I Love You, Honeybear"
		},
		{
			"title": "Top track play count",
			"value": 99,
			"secondary_value": "Kill v. Maim",
		},
		{
			"title": "Total listening time",
			"value": 37.30575621,
			"value_type": "days"
		},
		{
			"title": "Movies seen in theatres",
			"value": 8
		},
		/*{
			"title": "Episodes of The Daily Show watched",
			"value": 141
		},*/
		{
			"title": "SCENE points redeemed",
			"value": 19000
		},
		{
			"title": "Episodes of X-Files rewatched",
			"value": 110,
			"secondary_value": "out of 202 episodes"
		}
	]
}